import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { getToken } from "../config";

export const getMemberApplicationById = (
  id: string,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/member-applications/" + id,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const updatePartner = (
  memberApplicationId: string,
  partnerId: string,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/member-applications/update-partner",
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      _id: memberApplicationId,
      partner: partnerId,
    },
  };

  return axios(config);
};

export function updateStatusApplication(id: string, data: any): Promise<any> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/member-applications/status/${id}`,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
      date: new Date(),
    },
  };
  return axios(config);
}

export function updateMemberApplication(id: string, data: any): Promise<any> {
  const token = getToken();

  const config: AxiosRequestConfig = {
    url: `/member-applications/${id}`,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
      date: new Date(),
    },
  };

  return axios(config);
}

export function updateMultipleStatusApplication(data: any): Promise<any> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/member-applications/status-multiple`,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      ...data,
      date: new Date(),
    },
  };
  return axios(config);
}

export const getMailValidateApplication = (
  memberMail: string,
  memberPhone: any,
  memberCountryCode: any,
): Promise<any> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/validate-mail",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      email: memberMail,
      phoneNumber: memberPhone,
      phoneCountryCode: memberCountryCode,
    },
  };
  return axios(config);
};
