import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export function Chip({ text }: any) {
  return (
    <Box
      sx={{
        background: "#74C24A",
        padding: "4px 8px",
        borderRadius: "64px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography>{text}</Typography>
    </Box>
  );
}
