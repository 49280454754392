import { Backdrop, Box, Paper, Typography } from "@mui/material";
import React from "react";

import { styles } from "./styles";

interface IModalVideoProps {
  open: boolean;
  handleClose: () => void;
  url: string;
}

const ModalVideo: React.FC<IModalVideoProps> = ({ open, handleClose, url }) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.modal,
      }}
      open={open}
      onClick={handleClose}
    >
      <Box sx={styles.container}>
        <Typography sx={styles.exitButton}>X</Typography>
        <Paper sx={{ width: "100%", height: "100%" }}>
          <iframe
            width="100%"
            height="100%"
            src={url}
            title="Todas las escenas de Ashley - Resident Evil 4 Remake - En Español - PS5 - Leon y Ashley"
            frameBorder={0}
          />
        </Paper>
      </Box>
    </Backdrop>
  );
};

export default ModalVideo;
