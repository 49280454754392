import CheckIcon from "@mui/icons-material/Check";
import { Box, Card, Grid, List, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import CardHeader from "../../../components/Dashboard/CardHeader";
import { LinkShowMore } from "../../../components/Dashboard/LinkShowMore";
import ModalSeeAll from "../../../components/Dashboard/ModalSeeAll/ModalSeeAll";
import { Action } from "../types";

import ActionItem from "./ActionItem";
import MyActionsStyles from "./style";

interface IMyActionsProps {
  data: Action[];
}

const MyActions: React.FC<IMyActionsProps> = ({ data }) => {
  const [OpenModalSeAll, setOpenModalSeAll] = useState<boolean>(false);

  const truncatedData = data.slice(0, 5);
  return (
    <Box sx={MyActionsStyles}>
      <ModalSeeAll
        title="Mis acciones"
        open={OpenModalSeAll}
        onClose={() => setOpenModalSeAll(false)}
      >
        {data.map((action) => (
          <Grid item xs={12} key={action.id}>
            <ActionItem key={action.id} data={action} />
          </Grid>
        ))}
      </ModalSeeAll>
      <Card className="my-actions-card">
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ height: "100%" }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} className="my-actions-header">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <CardHeader variant="h5">Mis Acciones</CardHeader>
                <CheckIcon />
              </Stack>
            </Grid>
            {truncatedData.length > 0 && (
              <Grid item xs={12}>
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  {truncatedData.map((action) => (
                    <ActionItem key={action.id} data={action} />
                  ))}
                </List>
              </Grid>
            )}
          </Grid>
          {truncatedData.length === 0 && (
            <Box className="noElements">
              <Typography
                fontSize={"20px"}
                textAlign={"center"}
                color={"#515151"}
              >
                No se ha encontrado alguna de tus acciones
              </Typography>
            </Box>
          )}
          {data.length > 5 && (
            <LinkShowMore
              onClick={() => setOpenModalSeAll(true)}
              underline="none"
            >
              Ver más
            </LinkShowMore>
          )}
        </Stack>
      </Card>
    </Box>
  );
};

export default MyActions;
