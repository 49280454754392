import { Stars } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import TodayIcon from "@mui/icons-material/Today";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React from "react";
import { UseFormReturn } from "react-hook-form";

import { DATE_FORMAT } from "../../../../../../../../constants";
import { styles as mainStyles } from "../../../../styles";
import { styles } from "../../styles";
import ButtonEvaluation from "../ButtonEvaluation/ButtonEvaluation";
import { EvaluationForm, TypeEvaluation } from "../consts";

import { stylesInfoEvaluations } from "./styles";

interface Props {
  hook: UseFormReturn<EvaluationForm>;
  typeEvaluation: TypeEvaluation;
  changeEvaluation: (value: TypeEvaluation) => void;
  edit: boolean;
}

const InfoEvaluations: React.FC<Props> = (props) => {
  const { hook, edit, typeEvaluation, changeEvaluation } = props;

  const { createdAt, name, updatedAt } = hook.watch(
    `evaluations.${typeEvaluation}`,
  );

  if (!createdAt && !name && !updatedAt) {
    return null;
  }

  const average =
    (hook.watch("score.partner") + hook.watch("score.analyst")) / 2;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        minWidth: "260px",
        alignItems: "center",
      }}
    >
      <Box>
        <Box sx={stylesInfoEvaluations.container}>
          <Box display={"flex"} alignItems="center">
            <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
              Evaluación realizado por:
            </Typography>
            <ButtonEvaluation
              edit={edit}
              changeEvaluation={changeEvaluation}
              evaluation={typeEvaluation}
            />
          </Box>
        </Box>
        <Box sx={stylesInfoEvaluations.subContainer}>
          <Box sx={stylesInfoEvaluations.wrapper}>
            <Box sx={mainStyles.detailsText}>
              <PersonIcon sx={mainStyles.detailsIcons} />
              Nombre:
              <Typography
                sx={{ ...mainStyles.detailsText, ...styles.nameText }}
              >
                {name}
              </Typography>
            </Box>
            {createdAt && (
              <Box sx={mainStyles.detailsText}>
                <TodayIcon sx={mainStyles.detailsIcons} />
                Realizado el:
                <Typography sx={mainStyles.detailsText.detailsDates}>
                  {dayjs(createdAt).format(DATE_FORMAT)}
                </Typography>
              </Box>
            )}
            {updatedAt && (
              <Box sx={mainStyles.detailsText}>
                <TodayIcon sx={mainStyles.detailsIcons} />
                Actualizado el:
                <Typography sx={mainStyles.detailsText.detailsDates}>
                  {dayjs(updatedAt).format(DATE_FORMAT + " - HH:mm:ss")}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {!edit && (
        <Box sx={{ display: "flex", alignItems: "end" }}>
          <Typography sx={{ ...mainStyles.title }}>
            Evaluación general:
          </Typography>
          <Stars sx={styles.iconStar} />
          <Typography
            style={{
              fontWeight: "900",
              fontSize: "32px",
              color: "#74C24A",
              height: "40px",
            }}
          >
            {average?.toFixed(1)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default InfoEvaluations;
