import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import * as React from "react";

import { splitButtonStyles } from "./styles";
import { IProps } from "./types";

const SplitButton = ({
  title,
  options,
  isMobile,
  icon,
  placement,
  disabled,
  sx,
}: IProps) => {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid sx={splitButtonStyles}>
      <Box>
        <ButtonGroup variant="contained" ref={anchorRef} color="inherit">
          <Button
            sx={sx}
            disabled={disabled}
            disableRipple
            className="leftButton"
          >
            {isMobile ? icon : title}
          </Button>
          <Button disabled={disabled} onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
      </Box>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={placement ? placement : "bottom-start"}
        {...({} as any)}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {options.map((option, index) =>
                    option.visible ? (
                      <MenuItem
                        key={option.label + index}
                        onClick={option.onClick}
                      >
                        {option.label}
                      </MenuItem>
                    ) : null,
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
};

export default SplitButton;
