export enum EMembershipType {
  AMBASSADOR = "AMBASSADOR",
  ALLIES = "ALLIES",
  LEADANGEL = "LEADANGEL",
  CLASSICAL = "CLASSICAL",
  TRIAL = "TRIAL",
}

export const memberShipTypes = [
  {
    name: "Clásica",
    type: EMembershipType.CLASSICAL,
  },
  {
    name: "Embajador",
    type: EMembershipType.AMBASSADOR,
  },
  {
    name: "Aliado",
    type: EMembershipType.ALLIES,
  },
  {
    name: "Lead Angel",
    type: EMembershipType.LEADANGEL,
  },
  {
    name: "Experiencia",
    type: EMembershipType.TRIAL,
  },
];

export interface IProps {
  open: boolean;
  onCancel: () => void;
  memberInfo: any;
  getUserByRole: any;
}

export interface IForm {
  memberShipType: string;
  date: Date;
}
