import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import React from "react";
import { toast, TypeOptions, ToastOptions, Slide } from "react-toastify";

import { paletteColors } from "../../styles/theme";

const displayToast = (
  severity: TypeOptions,
  message: string,
  toastId?: string,
  style?: any,
) => {
  const icons = {
    success: () => <CheckCircleOutlineIcon />,
    error: () => <ErrorOutlineIcon />,
    info: () => <HelpOutlineIcon />,
    warning: () => <WarningAmberIcon />,
    default: () => <HelpOutlineIcon />,
  };

  const options: ToastOptions = {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    progress: undefined,
    draggable: false,
    toastId,
    style: {
      ...style,
      maxWidth: window.screen.width - 24,
      marginLeft: "12px",
      // TODO: Change colors between different types
      backgroundColor:
        severity === "error"
          ? paletteColors.lightRed
          : paletteColors.successToast,
      color: severity === "error" ? "#FFF" : "#405F41",
      fontSize: "0.86rem",
      top: 50,
    },
    icon: icons[severity],
    transition: Slide,
    closeButton: false,
  };

  return (toast as any)[severity](message, options);
};

export { displayToast };
