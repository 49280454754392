import { Card, Grid, useMediaQuery } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { useParams } from "react-router-dom";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import DetailMember from "../../components/DetailsMember/DetailMember";

import styles from "./styles";

const DetailsMember: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:860px)");
  const { id } = useParams() as any;

  return (
    <Grid container justifyContent="left">
      <PageTitle title="Detalle de Inversionista" />
      <Card
        style={
          isMobile
            ? {
                width: "93vw",
                maxWidth: "827px",
                marginTop: "152px",
                marginLeft: "3%",
              }
            : {
                width: "80vw",
                maxWidth: "1592px",
                marginTop: "152px",
                marginLeft: "3%",
              }
        }
      >
        <CardContent sx={styles.cardContent}>
          <DetailMember id={id} />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default DetailsMember;
