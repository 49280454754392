export enum EStatusReleasedOpportunityTabs {
  ALL = "ALL",
  OPEN_TO_INVESTMENT = "OPEN_TO_INVESTMENT",
  IN_REVIEW = "IN_REVIEW",
  CLOSED_TO_INVESTMENT = "CLOSED_TO_INVESTMENT",
  IN_INVESTMENT_PROCESS = "IN_INVESTMENT_PROCESS",
  INVESTED = "INVESTED",
  TO_CLOSE = "TO_CLOSE",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
}

export type PeriodType = "DIA" | "MES" | "TRIMESTRE" | "AÑO" | "";

export const PERIOD_VALUES = ["DIA", "MES", "TRIMESTRE", "AÑO"];
