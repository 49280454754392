import { TabList } from "@mui/lab";
import { Tab } from "@mui/material";

interface IProps {
  handleTabStatusChange: (_: React.SyntheticEvent, newValue: string) => void;
}

export enum ETabValues {
  RECEIVED = "RECEIVED",
  ASSIGNED = "ASSIGNED",
  EVALUATED = "EVALUATED",
  REJECTED = "REJECTED",
  IS_MONITORING = "IS_MONITORING",
}

const Tabs: React.FC<IProps> = ({ handleTabStatusChange }) => {
  const stylesTabs = {
    color: "#0000005D",
    minWidth: "150px",
    minHeight: "48px",
  } as const;
  return (
    <TabList
      sx={{ gap: "8px" }}
      allowScrollButtonsMobile
      scrollButtons
      variant="scrollable"
      onChange={handleTabStatusChange}
    >
      <Tab sx={stylesTabs} label="Todos" value="" />
      <Tab sx={stylesTabs} label="RECIBIDAS" value={ETabValues.RECEIVED} />
      <Tab sx={stylesTabs} label="ASIGNADAS" value={ETabValues.ASSIGNED} />
      <Tab sx={stylesTabs} label="EVALUADAS" value={ETabValues.EVALUATED} />
      <Tab sx={stylesTabs} label="RECHAZADAS" value={ETabValues.REJECTED} />
      <Tab
        sx={stylesTabs}
        label="EN MONITOREO"
        value={ETabValues.IS_MONITORING}
      />
    </TabList>
  );
};

export default Tabs;
