import dayjs from "dayjs";
import fileDownload from "js-file-download";

import {
  downloadFileApplication,
  downloadFileImage,
} from "../../services/application";

import { displayToast } from "./displayToast";

export const handleDownloadFile = async (file: any) => {
  try {
    const { data } = await downloadFileApplication(file._id);
    fileDownload(data, `${file.name}`);
  } catch (error) {
    displayToast("error", "Error al descargar archivo");
  }
};

export const handleDownloadImage = async (file: any, extension: string) => {
  try {
    const { data } = await downloadFileImage(file._id, extension);
    fileDownload(data, `${file.name}`);
  } catch (error) {
    displayToast("error", "Error al descargar archivo");
  }
};

export const handleDownloadReports = async (file: any) => {
  dayjs.locale("es");
  const id = file.file;
  const fileName = `Reporte-${dayjs(file.date).format("LL")}.pdf`;
  try {
    const { data } = await downloadFileApplication(id);
    fileDownload(data, fileName);
  } catch (error) {
    displayToast("error", "Error al descargar archivo");
  }
};
