import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState, KeyboardEvent, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { useLoading } from "../../hooks/useLoading";
import { createMemberPassword } from "../../services/createPassword";
import { getMemberApplicationById } from "../../services/memberApplication";
import { displayToast } from "../../utils/helpers/displayToast";
import { TextInput } from "../Inputs";
import PasswordFieldEye from "../PasswordFieldEye/PasswordFieldEye";
import StandardDialog from "../StandardDialog";

import { AdhesionContract } from "./AdhesionContract";
import { signInMemberSchema } from "./schema";
interface IParams {
  id: string | string[];
  token: string | string[];
}

interface ISignInMemberFormProps {
  params: IParams;
}

type Form = {
  password: string;
  confirmPassword: string;
  email: string;
};

const ResetPasswordRequestForm: React.FC<ISignInMemberFormProps> = (props) => {
  const isMovil = useMediaQuery("(max-width:395px)");
  const { handleClose, handleOpen } = useLoading();
  const { params } = props;
  const history = useHistory();
  const {
    handleSubmit,
    setError,
    control,
    formState: { isValid },
    setValue,
  } = useForm<Form>({
    resolver: yupResolver(signInMemberSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      password: "",
      confirmPassword: "",
      email: "",
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingRenderPage, setLoadingRenderPage] = useState<boolean>(true);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(true);
  const [termsAcceptedCheck, setTermsAcceptedCheck] = useState<boolean>(false);
  const [memberEmail, setMemberEmail] = useState<string>("");

  useEffect(() => {
    const fetchMember = async () => {
      const response = await getMemberApplicationById(params.id as string);
      setMemberEmail(response.data.payload.email);
    };

    fetchMember().finally(() => {
      setLoadingRenderPage(false);
    });
  }, [params.id]);

  const handleResetPassword = async (fields: Form): Promise<void> => {
    const newPassword = fields.confirmPassword;
    setLoading(true);
    try {
      handleOpen();
      await createMemberPassword(
        params.token as string,
        newPassword as unknown as string,
        params.id as string,
      );
      setIsSuccess(true);
    } catch (error: any) {
      const possibleMessages = [
        "Ya existe usuario con ese correo",
        "Ya existe usuario con ese número de teléfono",
        "Ya existe un registro de este usuario",
      ];
      if (possibleMessages.includes(error?.data?.message))
        displayToast(
          "error",
          "Ya existe un usuario con este correo o número de teléfono. Si tiene alguna duda o comentario puede comunicarse directamente con nosotros.",
        );
      else
        setError("confirmPassword", {
          message:
            error?.data?.message ||
            "Ha habido un error al crear su contraseña.",
        });
    } finally {
      handleClose();
      setLoading(false);
    }
  };

  const handleReturnToLogin = (): void => {
    history.push("/login");
  };
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter" && isValid) {
      handleSubmit(handleResetPassword)();
    }
  };

  if (loadingRenderPage) {
    return null;
  }

  if (isSuccess) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        padding={2}
      >
        <Typography
          align="center"
          component="h1"
          variant="h5"
          sx={{
            opacity: 1,
            textAlign: "center",
            color: "#5D5D5D",
            fontSize: "24px",
          }}
        >
          ¡Ya puedes iniciar sesión con tu nueva contraseña!
        </Typography>
        <br />
        <Button
          onClick={handleReturnToLogin}
          variant="contained"
          color="primary"
        >
          Regresar al Inicio de sesión
        </Button>
      </Grid>
    );
  }

  return (
    <>
      <StandardDialog
        PaperProps={{
          sx: {
            maxHeight: "70%",
            minWidth: "80%",
          },
        }}
        open={openDialog}
      >
        <DialogTitle sx={{ paddingX: "34px" }}>
          Contrato de adhesión
        </DialogTitle>
        <DialogContent
          sx={{
            paddingX: "34px",
            marginBottom: {
              xs: "10px",
              xl: "22px",
            },
          }}
        >
          <Box>
            <AdhesionContract />
          </Box>
        </DialogContent>
        <Box sx={{ paddingX: "34px" }}>
          <FormControlLabel
            label="Estoy de acuerdo y deseo continuar con el proceso de registro como Ángel Miembro del Club."
            control={
              <Checkbox
                color="primary"
                value={termsAcceptedCheck}
                onChange={(event) =>
                  setTermsAcceptedCheck(event.target.checked)
                }
              />
            }
          />
        </Box>
        <DialogActions sx={{ marginBottom: "36px", paddingX: "34px" }}>
          <Button
            disabled={!termsAcceptedCheck}
            variant="contained"
            onClick={() => setOpenDialog(false)}
            autoFocus
          >
            CONFIRMAR
          </Button>
        </DialogActions>
      </StandardDialog>
      <Grid container justifyContent="start">
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "500",
            letterSpacing: "0.17px",
            color: "#5D5D5D",
            marginTop: "42px",
          }}
        >
          Crea tu contraseña
        </Typography>
        <Box
          sx={{
            fontSize: "14px",
            textAlign: "start",
            letterSpacing: "0.11px",
            color: "#5D5D5D",
            marginTop: {
              xs: "6px",
              xl: "12px",
            },
            marginBottom: {
              xs: "10px",
              xl: "24px",
            },
          }}
        >
          <Typography>
            Crea una contraseña para tu acceso que contenga:
          </Typography>
          <Typography>- Minimo 8 Caracteres</Typography>
          <Typography>- Mayúsculas</Typography>
          <Typography>- Minúsculas</Typography>
          <Typography>- Números</Typography>
          <Typography>- Caracter especial</Typography>
        </Box>
      </Grid>
      <Grid container spacing={4} direction="column">
        {memberEmail !== "" && (
          <Grid item xs={12}>
            <TextInput value={memberEmail} disabled fullWidth />
          </Grid>
        )}
        <Grid item xs={12} sx={{ minWidth: "328px" }}>
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState }) => (
              <PasswordFieldEye
                {...field}
                onChange={(e) => {
                  setValue("password", e.target.value);
                }}
                disabled={loading}
                label="Contraseña"
                type="password"
                variant="outlined"
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
                onKeyPress={handleKeyPress}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field, fieldState }) => (
              <PasswordFieldEye
                {...field}
                disabled={loading}
                label="Confirmar Contraseña"
                type="password"
                variant="outlined"
                error={!isValid && Boolean(fieldState.error)}
                helperText={
                  !isValid &&
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
                onKeyPress={handleKeyPress}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ paddingTop: "30px !important" }}
          direction="row"
          justifyContent="center"
        >
          <Button
            onClick={handleSubmit(handleResetPassword)}
            variant="contained"
            color="primary"
            sx={{ width: "100%" }}
            disabled={!isValid}
          >
            CREAR CONTRASEÑA
          </Button>
          {/* {loading && (
                  <CircularProgress data-testid="loading-indicator" />
                )} */}
        </Grid>
      </Grid>
      <Divider
        sx={{
          marginTop: {
            xs: "10px",
            xl: "28px",
          },
          marginBottom: {
            xs: "10px",
            xl: "30px",
          },
          fontSize: "0.8rem",
        }}
      >
        {" "}
        O{" "}
      </Divider>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "1.2rem",
        }}
      >
        <Link onClick={() => handleReturnToLogin()} underline="none">
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              "&:hover": {
                cursor: "pointer",
              },
              color: "#69BC41",
            }}
          >
            Regresar al login
          </Typography>
        </Link>
      </Grid>
    </>
  );
};

export default ResetPasswordRequestForm;
