import { SxProps, Theme } from "@mui/material";

const DisclaimerStyles: SxProps<Theme> = (theme: Theme) => ({
  "& .MuiTypography-root.MuiTypography-body1": {
    [theme.breakpoints.up(1444)]: {
      fontSize: "20px",
      paddingBottom: theme.spacing(2.5),
    },
    [theme.breakpoints.down(1444)]: {
      fontSize: "16px",
      paddingBottom: theme.spacing(2),
    },
    fontFamily: "Product Sans Light !important",
    fontWeight: 300,
    lineHeight: "28px",
    letterSpacing: 0,
    color: "#515151",
  },
  "& .MuiTypography-root.MuiTypography-body1:last-child": {
    paddingBottom: "0 !important",
  },
  "& .paragraph-number": {
    fontFamily: "Product Sans Black !important",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "20px",
    color: "#4A7C2F",
    lineHeight: "28px",
  },
  "& .MuiTypography-root.MuiTypography-caption": {
    fontFamily: "Product Sans Light !important",
    fontStyle: "regular",
    fontWeight: 300,
    color: "#909090",
    letterSpacing: "0.015em",
    [theme.breakpoints.up(1444)]: {
      fontSize: "15px",
      lineHeight: "24px",
    },
    [theme.breakpoints.between(1444, 450)]: {
      fontSize: "14px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down(450)]: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  "& .MuiTypography-root.MuiTypography-h4": {
    fontFamily: "Product Sans Black !important",
    fontStyle: "normal",
    fontWeight: 900,
    color: "#515151",
    letterSpacing: 0,
    [theme.breakpoints.up(1444)]: {
      fontSize: "32px",
      // lineHeight: "48px",
    },
    [theme.breakpoints.between(450, 1444)]: {
      fontSize: "32px",
      // lineHeight: "48px",
    },
    [theme.breakpoints.down(450)]: {
      fontSize: " 24px",
      lineHeight: "32px",
    },
  },
});

export default DisclaimerStyles;
