import { ArrowDropDown, Stars, Today } from "@mui/icons-material";
import { Typography, Box, Button, Grid, Menu, MenuItem } from "@mui/material";
import moment from "moment";
import { Dispatch, SetStateAction, useState } from "react";

import { styles as mainStyles } from "../../../styles";
import { styles } from "../styles";

interface IDataBar {
  change: boolean;
  evaluationAnalystName: string;
  evaluationPartnerName: string;
  evaluationAnalystCreatedAt: string;
  evaluationPartnerCreatedAt: string;
  evaluationAnalystUpdatedAt: string;
  evaluationPartnerUpdatedAt: string;
  average: number | undefined;
  setChange: Dispatch<SetStateAction<boolean>>;
  isEditable: boolean;
}

export const InfoBar: React.FC<IDataBar> = ({
  change,
  evaluationAnalystName,
  evaluationPartnerName,
  evaluationAnalystCreatedAt,
  evaluationPartnerCreatedAt,
  evaluationAnalystUpdatedAt,
  evaluationPartnerUpdatedAt,
  average,
  setChange,
  isEditable,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseTrue = () => {
    setAnchorEl(null);
    setChange(true);
  };
  const handleCloseFalse = () => {
    setAnchorEl(null);
    setChange(false);
  };

  return (
    <Grid item xs={12} sx={{ marginTop: "-16px", minWidth: "340px" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          minWidth: "260px",
          alignItems: "center",
        }}
      >
        <Box>
          <Box style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
              Evaluación realizado por:
            </Typography>
            <Button
              sx={{ fontSize: "14px", color: "#4A88C2" }}
              endIcon={<ArrowDropDown fontSize="large" />}
              onClick={handleClick}
              disabled={!isEditable}
            >
              {change ? "ANALISTA" : "PARTNER"}
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              <MenuItem onClick={handleCloseFalse}>PARTNER</MenuItem>
              <MenuItem onClick={handleCloseTrue}>ANALISTA</MenuItem>
            </Menu>
          </Box>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            <Typography sx={{ ...mainStyles.detailsText }}>
              <Today sx={{ ...mainStyles.detailsIcons }} />
              Nombre:
              <Typography
                sx={{
                  ...mainStyles.detailsText,
                  ...styles.nameText,
                }}
              >
                {change ? evaluationAnalystName : evaluationPartnerName}
              </Typography>
            </Typography>
            {isEditable && (
              <>
                <Typography sx={{ ...mainStyles.detailsText }}>
                  <Today sx={{ ...mainStyles.detailsIcons }} />
                  Realizado el:
                  <Typography sx={{ ...mainStyles.detailsText.detailsDates }}>
                    {change
                      ? moment(evaluationAnalystCreatedAt).format("DD/MM/YYYY")
                      : moment(evaluationPartnerCreatedAt).format("DD/MM/YYYY")}
                  </Typography>
                </Typography>
                <Typography sx={{ ...mainStyles.detailsText }}>
                  <Today sx={{ ...mainStyles.detailsIcons }} />
                  Actualizado el:
                  <Typography sx={{ ...mainStyles.detailsText.detailsDates }}>
                    {change
                      ? moment(evaluationAnalystUpdatedAt).format("DD/MM/YYYY")
                      : moment(evaluationPartnerUpdatedAt).format("DD/MM/YYYY")}
                  </Typography>
                </Typography>
              </>
            )}
          </Box>
        </Box>
        {isEditable && (
          <Box sx={{ display: "flex", alignItems: "end" }}>
            <Typography sx={{ ...mainStyles.title }}>
              Evaluación general:
            </Typography>
            <Stars sx={styles.iconStar} />
            <Typography
              style={{
                fontWeight: "900",
                fontSize: "32px",
                color: "#74C24A",
                height: "40px",
              }}
            >
              {average?.toFixed(1)}
            </Typography>
          </Box>
        )}
      </Box>
    </Grid>
  );
};
