import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { changeEdit } from "../../../../../../redux/actions/LoggedUser";
import { useDispatch, useSelector } from "../../../../../../redux/typedHooks";
import { getFinancialInfo } from "../../../../../../services/startup";

import { EditMode } from "./EditMode/EditMode";
import { financialInfoSchema } from "./EditMode/schema";
import { ReadMode } from "./ReadMode/ReadMode";

export type FormType = {
  requiredInvestment: string;
  valuation: string;
  valuationPostMoney: string;
  monthlyRevenue: string;
  burnRate: string;
  runway: string;
  lastUpdate: Date | undefined;
};

interface IOpportunityId {
  id: string;
}
const FinancialInfo = () => {
  const { id } = useParams() as IOpportunityId;
  const [loading, setLoading] = useState<boolean>(true);
  const { edit } = useSelector((store) => store.loggedUser);
  const dispatch = useDispatch();

  const hook = useForm<FormType>({
    resolver: yupResolver(financialInfoSchema),
    mode: "all",
    defaultValues: {
      requiredInvestment: "0",
      valuation: "0",
      valuationPostMoney: "0",
      monthlyRevenue: "0",
      burnRate: "0",
      runway: "0",
      lastUpdate: new Date(),
    },
  });

  useEffect(() => {
    const fetchFinancialInfo = async () => {
      const financialInfo = await getFinancialInfo(id);
      const financialData = financialInfo.data.payload.info;
      hook.setValue("burnRate", financialData.burnRate);
      hook.setValue("monthlyRevenue", financialData.monthlyRevenue);
      hook.setValue("requiredInvestment", financialData.requiredInvestment);
      hook.setValue("runway", financialData.runway);
      hook.setValue("valuation", financialData.valuation);
      hook.setValue("valuationPostMoney", financialData.valuationPostMoney);
      hook.setValue("lastUpdate", financialData.lastUpdate);
    };
    setLoading(true);
    fetchFinancialInfo().finally(() => {
      setLoading(false);
    });
  }, [hook, id, edit]);

  return (
    <>
      {edit && (
        <EditMode hook={hook} changeMode={() => dispatch(changeEdit(!edit))} />
      )}
      {!edit && (
        <ReadMode
          hook={hook}
          changeMode={() => dispatch(changeEdit(!edit))}
          loading={loading}
        />
      )}
    </>
  );
};

export default FinancialInfo;
