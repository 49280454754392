import { combineReducers } from "redux";

import LoggedUser from "./LoggedUser";
import AdminReducer from "./admin";
import ApplicationReducer from "./applications";
import { MemberApplicationReducer } from "./member";
import { ModalReducer } from "./modal";
import { NotificationReducer } from "./notifications";
import OpportunityReducer from "./opportunity";
import OpportunityReleasedReducer from "./opportunityReleased";
import OpportunityReleasedReducerAdmin from "./opportunityReleasedAdmin";
import Register from "./register";
import { UserInvertorEditReducer } from "./userInvertorEdit";

const reducers = combineReducers({
  loggedUser: LoggedUser,
  register: Register,
  modal: ModalReducer,
  memberApplication: MemberApplicationReducer,
  adminReducer: AdminReducer,
  applicationReducer: ApplicationReducer,
  opportunityReducer: OpportunityReducer,
  opportunityReleasedReducer: OpportunityReleasedReducer,
  opportunityReleasedReducerAdmin: OpportunityReleasedReducerAdmin,
  notificationReducer: NotificationReducer,
  userInvertorEditReducer: UserInvertorEditReducer,
});

export type RootState = ReturnType<typeof reducers>;
export default reducers;
