import { Paper } from "@mui/material";
import { useFormContext } from "react-hook-form";

import TabPanel from "../../../../components/TabPanel/TabPanel";
import { EStatusReleasedOpportunity } from "../../../../constants";
import { useSelector } from "../../../../redux/typedHooks";
import { IMainCardForm } from "../../../DetailsOpportunity/components/MainCard/types";
import { ERolesCode } from "../../../routes/role-catalog";

import { useParamTab } from "./Context/useParamTabs";
import AttachedFiles from "./Tabs/AttachedFiles/AttachedFiles";
import { EvaluationsAdmin } from "./Tabs/Evaluations/EvaluationsAdmin";
import FinancialInfo from "./Tabs/FinancialInfo/FinancialInfo";
import InvestRound from "./Tabs/InvestRound/InvestRound";
import Reports from "./Tabs/Reports/Reports";
import { styles } from "./styles";

interface IProps {
  id: string;
  isInvested?: boolean;
}

const TabList = ({ id, isInvested }: IProps) => {
  const { value } = useParamTab();
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  const mainHook = useFormContext<IMainCardForm>();
  const status = mainHook.watch("status");

  const isRoleValid = [
    ERolesCode.SUPER_ADMIN,
    ERolesCode.ADMINISTRATOR,
    ERolesCode.ANALYST,
    ERolesCode.PARTNER,
  ].includes(user.role.code);

  return (
    <Paper square sx={styles.container}>
      <TabPanel value={value} index={"FinancialInfo"}>
        <FinancialInfo />
      </TabPanel>
      <TabPanel value={value} index={"InvestRound"}>
        <InvestRound isInvested={isInvested} />
      </TabPanel>
      <TabPanel value={value} index={"AttachedFiles"}>
        <AttachedFiles />
      </TabPanel>
      {isRoleValid && (
        <TabPanel value={value} index={"Evaluations"}>
          <EvaluationsAdmin
            id={id}
            startupStatus={status as EStatusReleasedOpportunity}
          />
        </TabPanel>
      )}
      <TabPanel value={value} index={"Reports"}>
        <Reports />
      </TabPanel>
    </Paper>
  );
};
export default TabList;
