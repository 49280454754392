import { Typography } from "@mui/material";
import { ReactElement } from "react";

interface IEllipsis {
  value: string | number | undefined | ReactElement;
}
/**
 *Component that allows the working behavior of strings in an ellipsis way.
 * @param {string} value - A string value to show
 * @returns {React.ReactElement} Ellipsis
 */
export default function Ellipsis(props: IEllipsis) {
  const { value } = props;
  return (
    <Typography
      sx={{
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontSize: "16px",
      }}
    >
      {value}
    </Typography>
  );
}
