import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { ERoles } from "../../pages/routes/role-catalog";
import { fetchUserByRole } from "../../services/users";
import { TextInput } from "../Inputs";
import StandardDialog from "../StandardDialog";

interface IProps {
  open: boolean;
  onCancel: () => void;
  onAccept: (id: any) => void;
  isMultiple?: boolean;
}

const DialogChangePartner: React.FC<IProps> = ({
  open,
  onCancel,
  onAccept,
}) => {
  const [Users, setUsers] = useState<any[]>([]);
  const [Partner, setPartner] = useState<string>("");

  useEffect(() => {
    fetchUserByRole(ERoles.RL001).then(({ data }) => {
      const users = data.payload;
      setUsers(users);
    });
  }, []);

  const handleUpdate = () => {
    const partner = Users.find((i) => i._id === Partner);
    onAccept(partner);
  };
  const handleCancel = () => {
    onCancel();
  };

  return (
    <StandardDialog open={open}>
      <DialogTitle>Reasignar</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Seleccione el socio del que desea reasignar esta aplicación miembro
        </DialogContentText>
        <TextInput
          onChange={(e) => setPartner(e.target.value)}
          label="Partner"
          autoFocus
          fullWidth
          required
          select
        >
          {Users.map((i, index) => (
            <MenuItem
              key={index}
              value={i._id}
            >{`${i.firstName} ${i.lastName}`}</MenuItem>
          ))}
        </TextInput>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={handleUpdate}>
          Guardar
        </Button>
      </DialogActions>
    </StandardDialog>
  );
};

export default DialogChangePartner;
