import { TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

import { EStatusReleasedOpportunityTabs } from "./const";

const Tabs: React.FC = () => {
  const history = useHistory();
  const stylesTabs = {
    color: "#0000005D",
    minWidth: "150px",
    minHeight: "48px",
  } as const;

  const handleChangeTab = (_: any, newTab: string) => {
    history.replace(`?tab=${newTab}`);
  };

  return (
    <TabList
      sx={{ gap: "8px" }}
      scrollButtons
      variant="scrollable"
      allowScrollButtonsMobile
      onChange={handleChangeTab}
    >
      <Tab
        sx={stylesTabs}
        label="TODAS"
        value={EStatusReleasedOpportunityTabs.ALL}
      />
      <Tab
        sx={stylesTabs}
        label="ABIERTAS A INVERSIÓN"
        value={EStatusReleasedOpportunityTabs.OPEN_TO_INVESTMENT}
      />
      <Tab
        sx={stylesTabs}
        label="EN REVISIÓN"
        value={EStatusReleasedOpportunityTabs.IN_REVIEW}
      />
      <Tab
        sx={stylesTabs}
        label="CERRADAS"
        value={EStatusReleasedOpportunityTabs.CLOSED_TO_INVESTMENT}
      />
      <Tab
        sx={stylesTabs}
        label="EN PROCESO DE INVERSIÓN"
        value={EStatusReleasedOpportunityTabs.IN_INVESTMENT_PROCESS}
      />
      <Tab
        sx={stylesTabs}
        label="INVERTIDAS"
        value={EStatusReleasedOpportunityTabs.INVESTED}
      />
      <Tab
        sx={stylesTabs}
        label="POR CERRAR"
        value={EStatusReleasedOpportunityTabs.TO_CLOSE}
      />
      <Tab
        sx={stylesTabs}
        label="EXPIRADAS"
        value={EStatusReleasedOpportunityTabs.EXPIRED}
      />
      <Tab
        sx={stylesTabs}
        label="CANCELADAS"
        value={EStatusReleasedOpportunityTabs.CANCELLED}
      />
    </TabList>
  );
};

export default Tabs;
