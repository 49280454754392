import * as yup from "yup";

import { DATE_NO_VALIDA } from "../../utils/text/inputs";

export const extendInvestmentDateSchema = yup.object({
  date: yup
    .date()
    .required(DATE_NO_VALIDA)
    .nullable(false)
    .typeError(DATE_NO_VALIDA),
});
