import { IUser } from "../../models/User";
import {
  RELOAD_REDUCER_USER,
  CHANGE_ARRAY_USERS,
  CHANGE_LIMIT_ADMINS,
  CHANGE_PAGE_ADMINS,
  CLEAR_STATE_ADMINS,
  FETCH_ADMINS,
  FETCH_ADMINS_PAGINATED,
  LOADING_ADMIN,
} from "../actions/admin";

export interface ILoggedUserReducer {
  users: IUser[];
  page: number;
  limit: number;
  loading: boolean;
  totalCount: number;
  reload: boolean;
}

const initialState: ILoggedUserReducer = {
  users: [],
  page: 0,
  limit: 10,
  loading: true,
  totalCount: 100,
  reload: false,
};

export default function AdminReducer(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_ADMINS_PAGINATED:
      return {
        ...state,
        users: action.payload,
      };
    case FETCH_ADMINS:
      return {
        ...state,
        users: action.payload,
      };
    case CHANGE_PAGE_ADMINS:
      return {
        ...state,
        page: parseInt(action.payload),
      };
    case CHANGE_LIMIT_ADMINS:
      return {
        ...state,
        limit: action.payload,
        page: 0,
      };
    case LOADING_ADMIN:
      return {
        ...state,
        loading: action.payload,
      };
    case CLEAR_STATE_ADMINS:
      return {
        ...initialState,
      };
    case CHANGE_ARRAY_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case RELOAD_REDUCER_USER:
      return {
        ...state,
        reload: !state.reload,
      };
    default:
      return state;
  }
}
