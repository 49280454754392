import * as yup from "yup";

import { Evaluations } from "../../../../../../components/DetailsStartup/Forms/schema";
import { CAMPO_REQUERIDO } from "../../../../../../utils/text/inputs";

export interface IEvaluations {
  partner: string;
  analyst: string;
}
export interface IEvaluationResponse {
  comments: [];
  evaluations: IEvaluations;
  score: number;
}

export type FormType = {
  scoreEvaluation: Evaluations[];
  finalComment: IComment[];
};
export interface IComment {
  user: string;
  date: Date | null;
  comment: string;
}

export const editEvaluationSchema = yup.object({
  finalComment: yup.array().of(
    yup.object({
      comment: yup
        .string()
        .max(250, "Campo máximo de 250 caracteres")
        .required(CAMPO_REQUERIDO),
    }),
  ),
  scoreEvaluation: yup.array().of(
    yup.object({
      comment: yup
        .string()
        .max(250, "Campo máximo de 250 caracteres")
        .required(CAMPO_REQUERIDO),
      score: yup.number().required(CAMPO_REQUERIDO),
    }),
  ),
});

export const evaluations = [
  { key: "Calidad de Equipo Fundador", score: undefined, comment: "" },
  { key: "Producto Innovador & Timing", score: undefined, comment: "" },
  { key: "Oportunidad de Mercado", score: undefined, comment: "" },
  { key: "Modelo de Negocio / Go-to-market", score: undefined, comment: "" },
  { key: "Diferenciación & Competencia", score: undefined, comment: "" },
  { key: "Tech Stack", score: undefined, comment: "" },
  { key: "Tracción (KPIs & Unit Economics)", score: undefined, comment: "" },
  { key: "Viabilidad Financiera", score: undefined, comment: "" },
];

export const comments = [
  {
    user: "",
    date: null,
    comment: "",
  },
];
