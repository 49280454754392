import dayjs, { Dayjs } from "dayjs";

import { PeriodType } from "../../components/Toolbars/OpportunityReleasedAdminToolbar/const";

export const getPeriodValues = (
  value: PeriodType,
): { lt: Dayjs; gt: Dayjs } => {
  const now = dayjs().endOf("day");

  const map: Record<PeriodType, Dayjs> = {
    DIA: dayjs().startOf("day"),
    MES: dayjs().startOf("month"),
    TRIMESTRE: dayjs().subtract(3, "months").startOf("month"),
    AÑO: dayjs().startOf("year"),
    "": dayjs(),
  };

  return {
    gt: now,
    lt: map[value],
  };
};

getPeriodValues("");
