import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { useLoading } from "../../../hooks/useLoading";
import { EStatusApplication } from "../../../pages/Applications/constants";
import { updateMultipleStatusApplication } from "../../../services/memberApplication";
import { changeStatusApplicationRequest } from "../../../services/rejects";
import { displayToast } from "../../../utils/helpers/displayToast";
import { TextInput } from "../../Inputs";
import StandardDialog from "../../StandardDialog";

import { rejectedSchema } from "./schema";

interface IProps {
  id: string;
  handleReject: () => void;
  showDialog: boolean;
  ids?: string[];
  isMultiple?: boolean;
}

export default function RejectDialog(props: IProps) {
  const { handleClose, handleOpen } = useLoading();
  const { showDialog, handleReject, id, ids, isMultiple } = props;

  const hook = useForm<{ comments: string }>({
    resolver: yupResolver(rejectedSchema),
    defaultValues: {
      comments: "",
    },
  });

  const handleAccept = async ({ comments }: { comments: string }) => {
    handleOpen();
    isMultiple
      ? await updateMultipleStatusApplication({
          status: EStatusApplication.REJECTED,
          ids,
          comment: comments,
        })
      : await changeStatusApplicationRequest(id, {
          status: EStatusApplication.REJECTED,
          comment: comments,
        });

    isMultiple
      ? displayToast("success", "Se rechazaron las aplicaciones.")
      : displayToast("success", "Guardado con éxito.");
    handleReject();
    hook.reset();
    handleClose();
  };

  const handleCancel = () => {
    handleReject();
    hook.reset();
  };

  return (
    <StandardDialog open={showDialog} maxWidth="sm" fullWidth>
      <DialogTitle>¿Deseas rechazar esta solicitud?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Por favor detalla el motivo del rechazo.
        </DialogContentText>
        <Controller
          control={hook.control}
          name="comments"
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              autoFocus
              margin="dense"
              label="Motivo"
              fullWidth
              multiline
              required
              rows={2}
              error={Boolean(fieldState.error)}
              helperText={fieldState.error?.message && fieldState.error.message}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={hook.handleSubmit(handleAccept)}>
          Rechazar
        </Button>
      </DialogActions>
    </StandardDialog>
  );
}
