import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";

import MainCard from "./components/MainCard/MainCard";
import { FormType, memberSchema } from "./components/MainCard/types";
import TabList from "./components/TabList/TabList";
import styles from "./styles";

const MemberProfile: React.FC = () => {
  const { id } = useParams() as any;
  const hook = useForm<FormType>({
    resolver: yupResolver(memberSchema),
    mode: "all",
    defaultValues: {
      avatar: null,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      phoneCountryCode: "",
      nationalityCountry: "",
      residenceCountry: "",
      residenceCity: "",
      email: "",
      projectInvesting: 0,
      membershipType: "",
      membershipStartDate: "",
      linkedInProfile: "",
      hasExperienceInvesting: false,
      interestToEnter: "",
      aboutMe: "",
      cv: null,
      interestIndustries: [],
      investVentureCapital: false,
      memberStartedDate: new Date(),
      profession: "",
      howFindAngelHub: "",
    },
  });

  return (
    <FormProvider {...hook}>
      <PageTitle title="Perfil de Inversionista" />
      <MainLayout sx={{ flexWrap: "wrap", display: "wrap" }}>
        <Box sx={styles.container}>
          <Box sx={styles.mainCard}>
            <MainCard id={id} />
          </Box>
          <Box sx={styles.tab}>
            <TabList id={id} />
          </Box>
        </Box>
      </MainLayout>
    </FormProvider>
  );
};

export default MemberProfile;
