import { useParams } from "react-router-dom";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import ResourceLibraryDetailComponent from "../../components/ResourceLibraryDetail/ResourceLibraryDetail";

const ResourceLibraryDetail = () => {
  const { id } = useParams() as any;

  return (
    <>
      <PageTitle title="Detalle de Recurso" />
      <MainLayout
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <ResourceLibraryDetailComponent id={id} />
      </MainLayout>
    </>
  );
};

export default ResourceLibraryDetail;
