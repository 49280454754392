import {
  CHANGE_OPPORTUNITY_VALUE,
  CHANGE_OPPORTUNITIES_VALUE,
  CHANGE_LIMIT_OPPORTUNITIES,
  CHANGE_PAGE_OPPORTUNITIES,
  FETCH_OPPORTUNITIES_PAGINATED,
  LOADING_OPPORTUNITY,
  EVALUATE_OPPORTUNITY,
  CLEAR_STATE_LIST_OPPORTUNITIES,
} from "../actions/opportunities";

export interface ILoggedApplicationReducer {
  opportunities: any[];
  page: number;
  limit: number;
  loading: boolean;
  totalCount: number;
  opportunity: string;
}

const initialState: ILoggedApplicationReducer = {
  opportunities: [],
  page: 0,
  limit: 10,
  loading: true,
  totalCount: 100,
  opportunity: "",
};

export default function OpportuniyReducer(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_OPPORTUNITIES_PAGINATED:
      return {
        ...state,
        opportunities: action.payload,
      };
    case CHANGE_PAGE_OPPORTUNITIES:
      return {
        ...state,
        page: action.payload,
      };
    case CHANGE_LIMIT_OPPORTUNITIES:
      return {
        ...state,
        limit: action.payload,
        page: 0,
      };
    case LOADING_OPPORTUNITY:
      return {
        ...state,
        loading: action.payload,
      };
    case CHANGE_OPPORTUNITIES_VALUE:
      return {
        ...state,
        opportunities: action.payload,
      };
    case CHANGE_OPPORTUNITY_VALUE:
      return {
        ...state,
        opportunity: action.payload,
      };
    case EVALUATE_OPPORTUNITY:
      return {
        ...state,
        opportunities: action.payload,
      };
    case CLEAR_STATE_LIST_OPPORTUNITIES:
      return initialState;
    default:
      return state;
  }
}
