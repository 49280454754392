export const styles = {
  buttons: {
    display: "flex",
    paddingLeft: "25px",
  },
  investButtonContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: "0px !important",
    "@media (max-width: 600px)": {
      width: "100%",
      flexWrap: "wrap",
    },
  },
  investButton: {
    backgroundColor: "#74C24A",
    width: "162px",
    color: "white",
    "@media (max-width: 400px)": {
      width: "100%",
    },
  },
  investCardContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  detailsContainer: {
    display: "flex",
    marginBottom: "-20px",
  },
  interestedContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
    alignItems: "center",
    title: {
      fontSize: "20px",
      "@media (max-width: 500px)": {
        fontSize: "15px",
        alignItems: "left",
      },
    },
    buttonTextColor: {
      color: "#4A88C2",
      "@media (max-width: 400px)": {
        width: "12px",
      },
    },
  },
  boxCardContainer: {
    marginBottom: "32px",
    marginTop: "12px",
  },
  boxListContainer: {
    overflow: "hidden",
    maxHeight: "210px",
    fontSize: "16px",
  },
  buttonSeeAllContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  seeAll: {
    color: "#4A88C2",
    fontSize: "12px",
    fontWeight: "700",
    textAlign: "right",
    paddingRight: "0px",
  },
};
