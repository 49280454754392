import { Box, SxProps, Theme } from "@mui/material";
import React from "react";

const styles = {
  layout: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignContent: "center",
    padding: {
      sm: "20px 48px",
      xs: "20px 20px",
    },
    marginTop: "8.5rem",
  },
} as const;

interface IProps {
  sx?: SxProps<Theme>;
}

const MainLayout: React.FC<IProps> = ({ children, sx }) => (
  <Box sx={{ ...styles.layout, ...sx }} className="main-layout">
    {children}
  </Box>
);

export default MainLayout;
