import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import LibraryImg from "../../assets/images/library-resource-image.svg";
import { getNewsFeedById } from "../../services/dashboard";
import { embebedYouTubeURL } from "../../utils/text/youTubeUrlConverter";
import FileAttached from "../ResourceLibraryDetail/Components/FileAttached";

import { NewsFeedStyles } from "./styles";
import { INewsFeed, INewsFeedDetailProps } from "./type";

const NewsFeedDetail: React.FC<INewsFeedDetailProps> = ({ id }) => {
  const [newsFeedDetail, setNewsFeedDetail] = useState<INewsFeed | null>(null);
  const [externalUrl, setExternalUrl] = useState<string>("");

  useEffect(() => {
    getNewsFeedById(id).then((data) => {
      setNewsFeedDetail(data);
      setExternalUrl(data?.urlExterno?.url);
    });
  }, [id]);

  if (!newsFeedDetail) {
    return (
      <Box
        width="100%"
        minHeight={"100vh"}
        display="flex"
        alignItems={"center"}
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper sx={NewsFeedStyles}>
      <Box className="main-container">
        <Grid container xs={12}>
          <Grid
            item
            xs={12}
            md={7}
            className="title-section"
            order={{ xs: 1, md: 0 }}
          >
            <Typography className="title">{newsFeedDetail.titulo}</Typography>
            <Box>
              <Box className="sub-title">
                <Typography sx={{ textTransform: "capitalize" }}>
                  {newsFeedDetail.autor}
                </Typography>
                <Box sx={{ color: "#74C24A", fontSize: "19px" }}>•</Box>
                <Typography className="date">{`Publicado: ${dayjs(
                  newsFeedDetail.publishedAt ?? newsFeedDetail.createdAt,
                ).format("D MMMM YYYY")}`}</Typography>
              </Box>
              <Box className="sub-title_2">
                {newsFeedDetail.file.length > 0 && (
                  <Typography>Archivos en este posteo</Typography>
                )}
                {newsFeedDetail.file.map((i, key) => (
                  <FileAttached key={key} title={i.name} url={i.url} />
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5} order={{ xs: 0, md: 1 }}>
            <img
              src={
                newsFeedDetail.file.length > 0
                  ? newsFeedDetail.file[0].url
                  : LibraryImg
              }
              className="img-header"
              alt="library-resource-img"
            />
          </Grid>
        </Grid>
        <Box
          className="styledContent"
          dangerouslySetInnerHTML={{
            __html: newsFeedDetail.contenidoPrincipal,
          }}
        ></Box>
        {externalUrl && (
          <Box className="video-container">
            {
              <iframe
                title="newsFeedVideo"
                src={embebedYouTubeURL(externalUrl)}
                width="730"
                height="480"
              ></iframe>
            }
          </Box>
        )}
        <Box
          className="styledContent"
          dangerouslySetInnerHTML={{
            __html: newsFeedDetail.contenidoSecundario,
          }}
        ></Box>
      </Box>
    </Paper>
  );
};

export default NewsFeedDetail;
