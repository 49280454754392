import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";

import MemberShipList from "./MemberShipList";

const MemberShip = () => {
  return (
    <>
      <PageTitle title="Lista de Membresías" />
      <MainLayout sx={{ flexDirection: "column", justifyContent: "start" }}>
        <MemberShipList />
      </MainLayout>
    </>
  );
};

export default MemberShip;
