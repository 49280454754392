import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/es";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { EStatusReleasedOpportunity } from "../../constants";
import { changeOpportunitiesReleased } from "../../redux/actions/opportunitiesReleasedAdmin";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import { opportunitiesReleasedChangeStatus } from "../../services/opportunitiesReleased";
import { displayToast } from "../../utils/helpers/displayToast";
import StandardDialog from "../StandardDialog";

import { extendInvestmentDateSchema } from "./schema";
import { IForm, IProps } from "./types";

const DialogExtendInvestment: React.FC<IProps> = ({
  open,
  onCancel,
  opportunities,
}) => {
  const { opportunity } = useSelector(
    (store) => store.opportunityReleasedReducerAdmin,
  );
  const startupId = opportunity;
  const dispatch = useDispatch();

  const hook = useForm<IForm>({
    resolver: yupResolver(extendInvestmentDateSchema),
    defaultValues: {
      date: new Date(),
    },
  });

  useEffect(() => {
    const indexOpportunity = opportunities.findIndex(
      (i) => String(i._id) === startupId,
    );
    const value = opportunities[indexOpportunity]?.expirationDate;
    hook.setValue("date", value);
  }, [hook, opportunities, startupId, open]);

  const handleUpdateExpirationDate = async (values: IForm) => {
    const validateDate = hook.getValues("date")
      ? dayjs().isAfter(dayjs(values.date))
      : false;

    if (validateDate) {
      hook.setError("date", {
        type: "custom",
        message: "La fecha no puede ser anterior a hoy",
      });
      return false;
    }

    try {
      await opportunitiesReleasedChangeStatus({
        startupId,
        status: EStatusReleasedOpportunity.OPEN_TO_INVESTMENT,
        date: values.date,
      });
      const copyOpportunity = [...opportunities];
      const index = copyOpportunity.findIndex(
        (i) => String(i._id) === String(startupId),
      );
      copyOpportunity[index].status =
        EStatusReleasedOpportunity.OPEN_TO_INVESTMENT;
      copyOpportunity[index].expirationDate = values.date;
      dispatch(changeOpportunitiesReleased(copyOpportunity));
      displayToast(
        "success",
        "Se a actualizado correctamente la fecha de cierre",
      );
      onCancel();
      hook.reset();
    } catch (error) {
      displayToast("error", "Hubo un error al actualizar la fecha");
    }
  };

  const handleCancel = () => {
    onCancel();
    hook.reset();
  };

  return (
    <StandardDialog open={open}>
      <DialogTitle>Extender la fecha para invertir</DialogTitle>
      <DialogContent>
        <DialogContentText>Seleccione la nueva fecha</DialogContentText>
        <Controller
          control={hook.control}
          name="date"
          render={({ field, fieldState }) => {
            return (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="es"
              >
                <DatePicker
                  minDate={field.value}
                  label="Fecha"
                  onChange={field.onChange}
                  value={field.value}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        required
                        fullWidth
                        error={Boolean(fieldState.error)}
                        helperText={fieldState?.error?.message}
                      />
                    );
                  }}
                />
              </LocalizationProvider>
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={hook.handleSubmit(handleUpdateExpirationDate)}
        >
          Guardar
        </Button>
      </DialogActions>
    </StandardDialog>
  );
};

export default DialogExtendInvestment;
