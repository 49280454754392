import { SxProps, Theme } from "@mui/material";

const DashboardMemberStyles: SxProps<Theme> = (theme: Theme) => ({
  "& .main-layout": {
    flexDirection: "column",
    justifyContent: "start",
    [theme.breakpoints.up("sm")]: {
      padding: "24px !important",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px !important",
    },
    marginTop: "8rem !important",
  },
  "& .investment-opportunity-table-card": {
    width: "100%",
    height: "430px",
    padding: "0 !important",
  },
  "& .ranking-card": {
    width: "100%",
    height: "455px",
    padding: "0 !important",
  },
});

export default DashboardMemberStyles;
