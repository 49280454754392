import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import "react-toastify/dist/ReactToastify.css";

import Dialog from "./components/Dialog";
import Loading from "./components/Loading/Loading";
import { EEvents, emitter } from "./config/emitter";
import { ProviderLoading } from "./hooks/useLoading";
import Routes from "./pages/routes/_router";
import { logout } from "./redux/actions/LoggedUser";
import reduxStore from "./redux/store";
import { useDispatch } from "./redux/typedHooks";
import { theme } from "./styles/theme";
import { displayToast } from "./utils/helpers/displayToast";

import "moment/locale/es";

import "./styles/globals.scss";

moment.locale("es");
dayjs.locale("es");

const persistor = persistStore(reduxStore);

const options = {
  "client-id": process.env.REACT_APP_CLIENT_PAYPAL_ID as string,
  intent: "capture",
  currency: "MXN",
};

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const handleEvent = () => {
      dispatch(logout());
      displayToast("error", "Sesión expirada");
    };

    emitter.on(EEvents.LOGOUT, handleEvent);
    return () => {
      emitter.off(EEvents.LOGOUT, handleEvent);
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <Routes />
      <Dialog />
    </>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <PayPalScriptProvider options={options}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Provider store={reduxStore}>
            <ProviderLoading>
              <PersistGate loading={null} persistor={persistor}>
                <App />
                <Loading />
              </PersistGate>
            </ProviderLoading>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </PayPalScriptProvider>
  );
};
