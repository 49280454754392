import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { useQuery } from "../../../../../hooks/useQuery";
import { TabParam } from "../types";

import { IReturnValue } from "./interface";

export const ContextParamTabs = React.createContext<IReturnValue>({
  value: "FinancialInfo",
  onChange: (value: TabParam) => null,
});

export const ParamTabProvider: React.FC = ({ children }) => {
  const query = useQuery();
  const url = query.get("Tab") as TabParam;
  const [value, setValue] = useState<TabParam>(url ?? "FinancialInfo");
  const history = useHistory();
  useEffect(() => {
    history.replace(`${history.location.pathname}?Tab=${value}`);
  }, [value]);

  const onChange = (value: TabParam) => {
    setValue(value);
  };

  const valueP = useMemo(() => {
    return {
      value,
      onChange,
    };
  }, [value]);

  return (
    <ContextParamTabs.Provider value={valueP}>
      {children}
    </ContextParamTabs.Provider>
  );
};
