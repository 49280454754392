import { SxProps, Theme } from "@mui/system";

export const NewsFeedStyles: SxProps<Theme> = (theme: Theme) => ({
  ".main-container": {
    display: "flex",

    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      paddingX: "126px",
    },
    [theme.breakpoints.down("lg")]: {
      paddingX: "16px",
    },
  },
  ".title-secction": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  ".title": {
    marginRight: "10px",
    fontSize: "32px",
    fontWeight: "900",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
    },
  },
  ".sub-title": {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    fontWeight: "400",
    ".MuiTypography-root ": {
      fontSize: "16px",
      color: "#909090",
    },
    ".date": {
      color: "#909090",
      fontWeight: "lighter",
    },
  },
  ".sub-title_2": {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "5px",
    ".MuiTypography-root ": {
      fontSize: "14px",
      fontWeight: "400px",
      color: "#909090",
    },
  },
  ".chip-container": {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    flexDirection: "row",
    ".chip": {
      background: "#74C24A",
      marginY: "10px",
    },
    ".MuiChip-root": {
      margin: "0px",
    },
  },
  ".text-container": {
    ".MuiTypography-root": {
      fontSize: "16px",
      color: "#909090",
    },
  },
  ".img-header": {
    width: "100%",
    height: "100%",
    maxHeight: "300px",

    objectFit: "contain",
    objectPosition: "top",
  },
  ".img-container": {
    display: "flex",
    justifyContent: "center",
    paddingY: "18px",
  },
  ".img": {
    width: "100%",
    height: "auto",
  },
  ".resultsNumber": {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "900",
    fontFamily: "Product Sans Black !important",
    color: "#515151",
  },
  ".resultsText": {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "400",
    fontFamily: "Product Sans !important",
    color: "#515151",
  },
});
