import { Theme, SxProps } from "@mui/material";

export const splitButtonStyles: SxProps<Theme> = (theme: Theme) => ({
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  ".leftButton": {
    "&.MuiButtonBase-root:hover": {
      cursor: "auto",
    },
  },
});
