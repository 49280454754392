import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useSelector } from "../../redux/typedHooks";
import { getSignedUrl } from "../../services/files";

const AvatarToolbar = () => {
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const [uri, setUri] = useState("");
  useEffect(() => {
    if (user.avatar?.key && user.avatar?.name) {
      getSignedUrl(user.avatar?.key, user.avatar?.name).then(({ data }) => {
        setUri(data.payload);
      });
    }
  }, [user.avatar?.key, user.avatar?.name]);

  return uri ? (
    <Avatar src={uri} style={{ marginLeft: 10 }} />
  ) : (
    <Avatar style={{ marginLeft: 10 }}>{user?.firstName[0]}</Avatar>
  );
};

export default AvatarToolbar;
