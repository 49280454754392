import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
} from "@mui/x-data-grid";
import { NavLink } from "react-router-dom";

import { CustomSort } from "../../../../../../components/DataGrid";
import Ellipsis from "../../../../../../utils/components/ellipsis";
import { currencyFormat } from "../../../../../../utils/helpers/currencyFormatter";

export const columns = (): GridColDef[] => [
  {
    headerName: "Nombre de la oportunidad",
    field: "name",
    flex: 0.7,
    minWidth: 300,
    hideSortIcons: true,
    align: "left",
    headerAlign: "left",
    renderCell: (data) => {
      const id = data.row.startupId;
      return (
        <Ellipsis
          value={
            <NavLink style={{ color: "#4A88C2" }} to={`/details-startup/${id}`}>
              {data.row.name}
            </NavLink>
          }
        />
      );
    },
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "Valuación post money USD",
    field: "postMoney",
    align: "right",
    headerAlign: "right",
    flex: 0.7,
    hideSortIcons: true,
    minWidth: 200,
    maxWidth: 200,
    renderCell: (data) => `${currencyFormat(data.row.postMoney)}`,
    valueGetter: (data) => parseFloat(data.row.postMoney),
  },
  {
    headerName: "Valuación actual USD",
    field: "valuation",
    align: "right",
    headerAlign: "right",
    flex: 0.7,
    hideSortIcons: true,
    minWidth: 200,
    maxWidth: 200,
    renderCell: (data) => `${currencyFormat(data.row.valuation)}`,
    valueGetter: (data) => parseFloat(data.row.valuation),
  },
  {
    headerName: "Mi inversión USD",
    field: "myInvest",
    hideSortIcons: true,
    minWidth: 175,
    maxWidth: 175,
    flex: 0.7,
    align: "right",
    headerAlign: "right",
    renderCell: (data) => `${currencyFormat(data.row.myInvest)}`,
    valueGetter: (data) => parseFloat(data.row.myInvest),
  },
  {
    headerName: "Rendimiento",
    field: "performance",
    hideSortIcons: true,
    minWidth: 150,
    maxWidth: 150,
    align: "right",
    headerAlign: "right",
    flex: 0.7,
    renderCell: (data) =>
      `${isFinite(data.row.performance) ? data.row.performance + "x" : "-"}`,
    valueGetter: (data) =>
      isFinite(data.row.performance) ? data.row.performance : 0,
    valueFormatter: (data) => {
      return data.value === 0 ? "-" : data.value;
    },
  },
];

export const otherMembercolumns = (
  conditionalColumn: GridColDef,
): GridColDef[] => [
  {
    headerName: "Nombre de la oportunidad",
    field: "name",
    hideSortIcons: true,
    flex: 0.7,
    minWidth: 300,
    align: "left",
    headerAlign: "left",
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  conditionalColumn,
];
