import { EStatusReleasedOpportunity } from "../../constants";

export const VALID_STATUS_TO_SHOW_IN_DIRECTORY = [
  EStatusReleasedOpportunity.OPEN_TO_INVESTMENT,
  EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT,
  EStatusReleasedOpportunity.IN_REVIEW,
  EStatusReleasedOpportunity.IN_INVESTMENT_PROCESS,
  EStatusReleasedOpportunity.INVESTED,
  EStatusReleasedOpportunity.EXPIRED,
];
