import { CategoryScale, Chart, LinearScale } from "chart.js";
import {
  FunnelController,
  TrapezoidElement,
  FunnelChart as FunnelChartComponent,
} from "chartjs-chart-funnel";
import ChartDataLabels from "chartjs-plugin-datalabels";
import React, { useLayoutEffect, useRef, useState } from "react";

import { IFunnelData } from "../types";

Chart.register(FunnelController, TrapezoidElement, LinearScale, CategoryScale);

interface FunnelChartProps {
  values: IFunnelData[];
}

const FunnelChart: React.FC<FunnelChartProps> = ({ values }) => {
  const chartRef = useRef<any>(null);
  const [chartInstance, setChartInstance] = useState<any>(null);

  useLayoutEffect(() => {
    const myChartRef = chartRef?.current.getContext("2d");
    if (chartInstance) {
      chartInstance.destroy();
    }
    const newChartInstance = new FunnelChartComponent(myChartRef, {
      data: {
        labels: values.map((i) => Math.round(i.percentage)),
        datasets: [
          {
            data: [90, 70, 50, 30, 16],
            backgroundColor: [
              "#0E42D2",
              "#165DFF",
              "#4080FF",
              "#6AA1FF",
              "#94BFFF",
            ],
          },
        ],
      },
      options: {
        indexAxis: "y",
        aspectRatio: 2,
        plugins: {
          datalabels: {
            font: {
              size: 14,
            },
            formatter: (value, context) => {
              const labels = context.chart.data.labels;
              const index = context.dataIndex;
              return labels ? labels[index] + "%" : "";
            },
          },
        },
        elements: {
          trapezoid: {
            border: "none",
          },
        },
      },
      plugins: [ChartDataLabels],
    });
    setChartInstance(newChartInstance);

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [values]);

  useLayoutEffect(() => {
    const resizeChart = () => {
      if (chartInstance) {
        chartInstance.resize();
      }
    };
    window.addEventListener("resize", resizeChart);
    return () => window.removeEventListener("resize", resizeChart);
  }, [chartInstance]);

  return (
    <canvas
      width={"100%"}
      height={"100%"}
      ref={chartRef}
      style={{
        maxHeight: "246px",
      }}
    />
  );
};

export default FunnelChart;
