import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import { useSelector } from "../../redux/typedHooks";
import InvestmentList from "../ProfileMember/components/TabList/Tabs/MyPortfolio/InvestmentList";

const Portfolio: React.FC = () => {
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  return (
    <>
      <PageTitle title="Lista de inversiones" />
      <MainLayout sx={{ flexDirection: "column", justifyContent: "start" }}>
        <InvestmentList id={user?.member._id} />
      </MainLayout>
    </>
  );
};

export default Portfolio;
