import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { TabContext } from "@mui/lab";
import { Autocomplete, Badge, Box, Grid, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { GridToolbarDensitySelector } from "@mui/x-data-grid";
import { ChangeEvent, useState, VFC } from "react";

import { IBaseToolbarProps } from "../../../pages/Opportunities/constants";
import { STAGE_OPTIONS } from "../../../pages/StartupRegister/constants";
import DrawerFilter from "../../DrawerFilter/DrawerFilter";
import { TextInput } from "../../Inputs";
import { useStyles } from "../ApplicationToolbar/styles";
import styles from "../OpportunityReleasedAdminToolbar/styles";

interface IPortfolioToolbarProps {
  onSearchChange?: (value: string) => void;
  onSearchClear?: () => void;
  onStageChange?: (value: string) => void;
  resetPage?: () => void;
  showOptions?: boolean;
  hideIcons?: boolean;
}

const BaseToolbar: VFC<IPortfolioToolbarProps> = (props) => {
  const { onSearchChange, onSearchClear, onStageChange, resetPage, hideIcons } =
    props;

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const [Stage, setStage] = useState<any | null>(null);
  const [StageInput, setStageInput] = useState<string>("");

  const [search, setSearch] = useState<string>("");
  const [value, setValue] = useState("");

  const breakpoint = useMediaQuery("(max-width: 1100px)");
  const isMobile = useMediaQuery("(max-width: 480px)");
  const classes = useStyles();

  const badgeNumber = (): number => {
    let number = 0;
    if (Stage) number++;

    return number;
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    onSearchChange?.(event.target.value);
  };

  const handleChangeEtapa = (_: any, newValue: any) => {
    setStage(newValue);
    resetPage && resetPage();
    !openDrawer && onStageChange && onStageChange(newValue);
  };

  const handleSearchClear = () => {
    setSearch("");
    onSearchClear && onSearchClear();
  };

  const handleFilter = () => {
    resetPage && resetPage();
    onStageChange && onStageChange(Stage || "");
    badgeNumber();
    setOpenDrawer(false);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  const filters = (
    <>
      <Autocomplete
        sx={styles.searchField}
        value={Stage}
        onChange={handleChangeEtapa}
        options={STAGE_OPTIONS}
        inputValue={StageInput}
        onInputChange={(_, newInputValue) => {
          setStageInput(newInputValue);
        }}
        renderInput={({ InputLabelProps, InputProps, inputProps, ...rest }) => (
          <TextInput
            {...rest}
            InputProps={{
              ...InputProps,
              style: { height: 48, paddingTop: "6px" },
            }}
            inputProps={inputProps}
            InputLabelProps={{
              ...InputLabelProps,
              style: { top: "-3.5px" },
              classes: { shrink: classes.inputLabel },
            }}
            label="Etapa"
          />
        )}
      />
    </>
  );

  return (
    <TabContext value={value}>
      <Grid container sx={{ ...styles.toolbar, gap: "12px" }}>
        <TextInput
          value={search}
          fullWidth={isMobile}
          onChange={handleSearchChange}
          label="Buscar"
          InputProps={{
            sx: { height: "48px" },
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{
                  visibility: search.length > 0 ? "visible" : "hidden",
                }}
                onClick={handleSearchClear}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          {breakpoint ? (
            <IconButton onClick={() => setOpenDrawer(true)}>
              <Badge badgeContent={badgeNumber()} color="primary">
                <FilterListIcon />
              </Badge>
            </IconButton>
          ) : (
            <Box display={"flex"} alignItems="center" gap="12px">
              {filters}
            </Box>
          )}
          <DrawerFilter
            isOpen={openDrawer}
            onFilter={handleFilter}
            onClose={() => onClose()}
          >
            {filters}
          </DrawerFilter>
          <Box display={"flex"} alignItems="center">
            {!hideIcons && (
              <GridToolbarDensitySelector {...({} as any)} sx={styles.icon} />
            )}
          </Box>
        </Box>
      </Grid>
    </TabContext>
  );
};
export default BaseToolbar;
