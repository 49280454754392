import { Grid, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";

import { styles as mainStyles } from "../../../styles";

interface IItemFinancialInfo {
  Icon: any;
  title: string;
  value: string;
  isUSD?: boolean;
  isMonth?: boolean;
}

const ItemFinancialInfo: React.FC<IItemFinancialInfo> = ({
  Icon,
  title,
  value,
  isUSD,
  isMonth,
}) => {
  return (
    <Grid item xs={12} sm={6} md={6} lg={4} sx={{ ...mainStyles.Info }}>
      <Icon sx={{ ...mainStyles.Icons }} />
      <Box>
        <Typography sx={mainStyles.title}>{title}</Typography>
        <Stack direction={"row"} alignItems={"flex-end"}>
          <Typography sx={mainStyles.amounts}>
            <strong>
              {`${value === "$0" || value === "0" ? "-" : value} `}
            </strong>
          </Typography>
          {isUSD && <Typography sx={mainStyles.Info.span}>USD</Typography>}
          {isMonth && <Typography sx={mainStyles.Info.span}>MESES</Typography>}
        </Stack>
      </Box>
    </Grid>
  );
};

export default ItemFinancialInfo;
