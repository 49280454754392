import { styled } from "@mui/material";
import TextField from "@mui/material/TextField";

export const TextFieldAutoComplete = styled(TextField)(() => ({
  "& .MuiOutlinedInput-root": {
    minHeight: "48px",
    borderRadius: "4px",
    "& input::-ms-reveal": {
      display: "none",
    },
    "& input::-ms-clear": {
      display: "none",
    },
  },
  "& .MuiInputLabel-root": {
    top: "-3.5px",
  },
  "& .MuiInputLabel-root.MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0.75) !important",
  },
  "& .MuiOutlinedInput-input": {
    paddingTop: "12.5px",
    paddingBottom: "12.5px",
  },
  "& .MuiAutocomplete-input": {
    paddingTop: "3.5px !important",
  },
}));
