import { GetApp } from "@mui/icons-material";
import { Box, SelectChangeEvent } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { DataGrid, GridColumns, GridRowIdGetter } from "@mui/x-data-grid";
import React from "react";

import { ISelectOption } from "../Inputs/Select";

import CustomPagination from "./CustomPagination";
import { IPaginationProps } from "./CustomPagination/CustomPagination";
import CustomToolbar from "./CustomToolbar";
import { DEFAULT_ROWS_PER_PAGE } from "./constants";
import styles from "./styles";

const useStyles2 = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "white",
      "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "#E2E2E280",
      },
      "& .MuiDataGrid-row": {
        backgroundColor: "",
      },
      "& .MuiDataGrid-cell": {
        padding: "0.2rem",
      },
    },
  }),
);

interface IToolbarProps {
  onSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  clearSearch?: () => void;
  filterOptions?: ISelectOption[];
  onFilterChange?: (event: SelectChangeEvent<unknown>) => void;
  buttonText: string;
  buttonAction?: () => void;
  hideFilters?: boolean;
  filterLabel?: string;
  filterField?: string;
  hideIcons?: boolean;
  hideButton?: boolean;
}

interface ITableProps {
  columns: GridColumns<any>;
  rows: any[];
  getRowId: GridRowIdGetter<any>;
  loading: boolean;

  toolbarProps?: IToolbarProps;
  paginationProps: IPaginationProps;
}
/**
 * Componente que sirve para mostrar y filtrar datos sobre una tabla interactiva. También se le puede configurar la tabla de herramientas incluida
 * @param {GridColumns} columns Arreglo que indica el tipo de columnas a pintar
 * @param {Array} rows Los datos a insertar en la tabla
 * @param {number[]} rowsPerPageOptions La cantidad de filas por página mostradas
 * @param {IToolbarProps} toolbarProps Las opciones relacionadas a la barra de herramientas
 */

const CustomDataGrid: React.FC<ITableProps> = (props) => {
  const { toolbarProps } = props;
  const classes = useStyles2();

  return (
    <Box sx={styles.DataGridContainer}>
      <DataGrid
        columns={props.columns}
        rows={props.rows}
        getRowId={props.getRowId}
        loading={props.loading}
        rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE}
        className={classes.root}
        sortingOrder={["asc", "desc"]}
        localeText={{
          toolbarExportLabel: "",
          toolbarExport: "",
          toolbarDensity: "",
          toolbarDensityLabel: "",
        }}
        components={{
          Toolbar: CustomToolbar,
          ExportIcon: GetApp,
          Pagination: CustomPagination,
        }}
        componentsProps={{
          toolbar: toolbarProps,
          pagination: {
            ...props.paginationProps,
          },
        }}
      />
    </Box>
  );
};
export default CustomDataGrid;
