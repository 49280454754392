import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import {
  ICreateAdmin,
  ICreateMember,
  ICreateStartup,
} from "../components/Toolbars/UserToolbar/CrudUsers/schemas";
import { getToken } from "../config";
import { IClientsResponse } from "../models/Response";
import { IRole, IUpdateUser } from "../models/User";
import { ERoles, ERolesCode } from "../pages/routes/role-catalog";

export function fetchUserByRole(
  role: ERolesCode | ERoles,
): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/users?role=${role}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function createUser(
  data: ICreateAdmin,
): Promise<AxiosResponse<IClientsResponse>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/users",
    method: "POST",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function createMember(
  data: ICreateMember,
): Promise<AxiosResponse<IClientsResponse>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/users/create-member",
    method: "POST",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function createStartup(
  data: ICreateStartup,
): Promise<AxiosResponse<IClientsResponse>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/users/create-startup",
    method: "POST",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function updateUser(
  id: string,
  data: IUpdateUser,
): Promise<AxiosResponse<IClientsResponse>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/users/${id}`,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function getUserById(id: string): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/users/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
export function getUserByMemberShip(
  id: string,
): Promise<AxiosResponse<IClientsResponse>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/users/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function usersPaginated(limit: number, page: number): Promise<any> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/users/paginated?limit=${limit}&page=${page + 1}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function usersPaginatedWithQuery(
  limit: number,
  page: number,
  query: any,
): Promise<any> {
  const token = getToken();

  let url = `/users/paginated?limit=${limit}&page=${page + 1}`;

  if (query.Role) {
    url += `&role=${query.Role}`;
  }
  if (query.isActive !== null) {
    url += `&isActive=${query.isActive}`;
  }

  const config: AxiosRequestConfig = {
    url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function getUsers(): Promise<any> {
  const token = getToken();

  const config: AxiosRequestConfig = {
    url: `/users`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export interface DTOUpdatePartner {
  _id: string;
  partner: string;
}

export function updatePartner(
  data: DTOUpdatePartner,
): Promise<AxiosResponse<IClientsResponse>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/member-applications/update-partner",
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(config);
}
export function changeValueActivateUser(
  isActive: boolean,
  id: string,
): Promise<AxiosResponse<IClientsResponse>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/users/${id}`,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      isActive,
    },
  };
  return axios(config);
}
export function getRoles(): Promise<AxiosResponse<IRole[]>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/roles",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function getTargetUser() {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/target-user",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function getNotifications() {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/users/notifications",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function getAngelGroup() {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/users/angel-group",
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
