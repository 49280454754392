/* eslint-disable no-constant-condition */
import BallotIcon from "@mui/icons-material/Ballot";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchangeTwoTone";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOnSharp";
import PaymentsIcon from "@mui/icons-material/PaymentsSharp";
import PriceChangeIcon from "@mui/icons-material/PriceChangeOutlined";
import SavingsIcon from "@mui/icons-material/Savings";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import SplitButton from "../../../../../../../components/SplitButton/SplitButton";
import { EStatusReleasedOpportunity } from "../../../../../../../constants";
import { useSelector } from "../../../../../../../redux/typedHooks";
import { currencyFormat } from "../../../../../../../utils/helpers/currencyFormatter";
import ItemFinancialInfo from "../../../../../../DetailsOpportunity/components/TabList/Tabs/FinancialInfo/ItemFinancialInfo/ItemFinancialInfo";
import { ERolesCode } from "../../../../../../routes/role-catalog";
import Layout from "../../../Layout/Layout";
import { styles as mainStyles } from "../../../styles";
import { RESPONSIVE_BUTTON } from "../../../types";
import { VALID_ROLES_TO_TO_EDIT_FINANCIAL_INFO } from "../validation";

interface IFinancialInfoReadMode {
  hook?: any;
  changeMode: () => void;
  loading: boolean;
}

export const ReadMode: FC<IFinancialInfoReadMode> = ({
  changeMode,
  hook,
  loading,
}) => {
  const { id } = useParams() as { id: string };
  const breakpoint = useMediaQuery(RESPONSIVE_BUTTON);
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const mainCardHook = useFormContext<any>();

  const isValidRoleToEditAH = VALID_ROLES_TO_TO_EDIT_FINANCIAL_INFO.includes(
    user.role.code,
  );

  const isStartupOwner =
    user.role.code === ERolesCode.STARTUP &&
    user.startup._id === id &&
    EStatusReleasedOpportunity.INCOMPLETE === mainCardHook.watch("status");

  const lastUpdate =
    hook.watch("lastUpdate") !== undefined
      ? dayjs(hook.watch("lastUpdate")).format("DD/MM/YYYY")
      : "Sin actualización";

  return (
    <Layout>
      <Layout.Button>
        {!breakpoint && (isValidRoleToEditAH || isStartupOwner) && (
          <Button
            variant="contained"
            onClick={changeMode}
            sx={{ ...mainStyles.actionsButtons }}
          >
            Editar
          </Button>
        )}
      </Layout.Button>
      <Layout.Panel loading={loading}>
        <Grid container spacing={3}>
          {(isValidRoleToEditAH || isStartupOwner) && breakpoint && (
            <Grid item xs={12} p={0} sx={{ ...mainStyles.buttonsContainer }}>
              <SplitButton
                title="Acciones"
                isMobile
                icon={<BallotIcon />}
                options={[
                  {
                    label: "Editar",
                    visible: true,
                    onClick: changeMode,
                  },
                ]}
              />
            </Grid>
          )}
          <ItemFinancialInfo
            Icon={PriceChangeIcon}
            title="Inversión requerida:"
            value={currencyFormat(hook.watch("requiredInvestment")) || ""}
            isUSD
          />
          <ItemFinancialInfo
            Icon={PriceChangeIcon}
            title="Valuación post money:"
            value={currencyFormat(hook.watch("valuationPostMoney")) || ""}
            isUSD
          />
          <ItemFinancialInfo
            Icon={PaymentsIcon}
            title="Valuación actual:"
            value={currencyFormat(hook.watch("valuation")) || ""}
            isUSD
          />
          <ItemFinancialInfo
            Icon={CurrencyExchangeIcon}
            title="Revenue mensual:"
            value={currencyFormat(hook.watch("monthlyRevenue")) || ""}
            isUSD
          />
          <ItemFinancialInfo
            Icon={MonetizationOnIcon}
            title="Burn rate:"
            value={currencyFormat(hook.watch("burnRate")) || ""}
            isUSD
          />
          <ItemFinancialInfo
            Icon={SavingsIcon}
            title="Runway:"
            value={hook.watch("runway")?.toString() || ""}
            isMonth
          />

          <Box
            sx={{
              width: "100%",
              height: "59px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Typography fontSize={"16px"}>
              *Fecha de actualización:{" "}
              <span style={{ fontWeight: "bold" }}>{lastUpdate}</span>
            </Typography>
          </Box>
        </Grid>
      </Layout.Panel>
    </Layout>
  );
};
