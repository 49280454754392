import {
  Avatar,
  Box,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import { getSignedUrl } from "../../../services/files";

import { IPlatformActivity } from "./PlatformActivity";
import PlatformActivityStyles from "./style";

interface PlatformActivityItemProps {
  activity: IPlatformActivity;
  hideDivider?: boolean;
}

export enum EEventNameLog {
  PROFILE_EDITED = "PROFILE_EDITED",
  MEMBER_INTERESTED_OPPORTUNITY = "MEMBER_INTERESTED_OPPORTUNITY",
  MEMBER_WANT_TO_INVEST_OPPORTUNITY = "MEMBER_WANT_TO_INVEST_OPPORTUNITY",
  MEMBER_CHANGE_INTENTION_OF_INVEST = "MEMBER_CHANGE_INTENTION_OF_INVEST",
}

const PlatformActivity = {
  PROFILE_EDITED: " edito su perfil.",
  MEMBER_INTERESTED_OPPORTUNITY: " le interesa invertir en la oportunidad ",
  MEMBER_WANT_TO_INVEST_OPPORTUNITY: " le interesa la oportunidad ",
  MEMBER_CHANGE_INTENTION_OF_INVEST: " edito su intención de inversión en ",
  MEMBER_DOES_NOT_WANT_TO_INVEST_OPPORTUNITY: " no le interesa la oportunidad ",
  MEMBER_WITHDREW_INVESTMENT_INTENTION: " retiro su intención de inversión. ",
};

const PlatformActivityItem: React.FC<PlatformActivityItemProps> = ({
  activity,
  hideDivider,
}) => {
  const [imageURL, setImageURL] = useState("");
  const dateHours = dayjs().diff(activity.date, "hours");
  const isOne = dateHours === 1;
  const hoursText = isOne ? "hora" : "horas";

  useEffect(() => {
    if (activity.image) {
      getSignedUrl(activity.image.key, activity.image?.name)
        .then(({ data }) => {
          setImageURL(data.payload);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [activity.image]);

  return (
    <Box sx={PlatformActivityStyles}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt={activity.user} src={imageURL}>
            {!imageURL && activity.user.slice(0, 2).toUpperCase()}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <Typography variant="subtitle1" className="event-log">
                <Typography className="event-user-name">
                  {activity.user}
                </Typography>
                {
                  PlatformActivity[
                    activity.type as keyof typeof PlatformActivity
                  ]
                }
                {activity.startup &&
                  activity.type !== EEventNameLog.PROFILE_EDITED && (
                    <Typography className="event-user-name">
                      {activity.startup}
                    </Typography>
                  )}
              </Typography>
            </>
          }
          secondary={
            <>
              <Typography className="event-time" variant="caption">
                {`Hace ${dateHours} ${hoursText}`}
              </Typography>
            </>
          }
        />
      </ListItem>
      {!hideDivider && <Divider variant="inset" component="li" />}
    </Box>
  );
};

export default PlatformActivityItem;
