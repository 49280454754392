import { Chip } from "@mui/material";
import { Box, Stack } from "@mui/system";

import { ResourceLibraryStyles } from "../styles";

interface IProps {
  title: string;
}
const Tags: React.FC<IProps> = ({ title }) => {
  return (
    <>
      <Box sx={ResourceLibraryStyles}>
        <Stack spacing={1}>
          <Chip label={title} variant="filled" className="chip" />
        </Stack>
      </Box>
    </>
  );
};

export default Tags;
