import { Paper } from "@mui/material";
import { FC } from "react";

import TabPanel from "../../../../components/TabPanel/TabPanel";
import { useParamTab } from "../Context/useParamTabs";

import AttachedFiles from "./Tabs/AttachedFiles/AttachedFiles";
import Evaluations from "./Tabs/Evaluations/Evaluations";
import FinancialInfo from "./Tabs/FinancialInfo/FinancialInfo";
import { styles } from "./styles";
import { ITabListProps } from "./types";

const TabList: FC<ITabListProps> = () => {
  const { value } = useParamTab();

  return (
    <Paper square sx={styles.container}>
      <TabPanel value={value} index={"FinancialInfo"}>
        <FinancialInfo />
      </TabPanel>
      <TabPanel value={value} index={"AttachedFiles"}>
        <AttachedFiles />
      </TabPanel>
      <TabPanel value={value} index={"Evaluations"}>
        <Evaluations />
      </TabPanel>
    </Paper>
  );
};
export default TabList;
