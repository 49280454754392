import { Box, Card, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import { EStatusReleasedOpportunityTabs } from "../../../components/Toolbars/OpportunityReleasedAdminToolbar/const";
import MemberDashboardCardHeader from "../../DashboardMember/components/MemberDashboardCardHeader";
import { ResumenOpportunities } from "../types";

import OpportunitiesSummaryItem from "./OpportunitiesSummaryItem";
import OpportunitiesSummaryStyles from "./style";

interface IOpportunitiesSummaryProps {
  name?: string;
  data: ResumenOpportunities;
}

const OpportunitiesSummary: React.FC<IOpportunitiesSummaryProps> = (props) => {
  const { data } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={OpportunitiesSummaryStyles}>
      <Card className="opportunities-card">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
          columnSpacing={2}
        >
          <Grid item xs={12}>
            <MemberDashboardCardHeader variant="h5">
              Resumen de Oportunidades
            </MemberDashboardCardHeader>
          </Grid>
          <Grid item xs={12} sm={6} lg={2.4}>
            <OpportunitiesSummaryItem
              indicator="Abiertas"
              value={data.open.toString()}
              useDivider={!isMobile}
              useMobileDivider={isMobile}
              url={`/oportunidades-aceptadas-admin?tab=${EStatusReleasedOpportunityTabs.OPEN_TO_INVESTMENT}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2.4}>
            <OpportunitiesSummaryItem
              indicator="Por cerrar"
              value={data.close.toString()}
              useDivider={!isMobile}
              useMobileDivider={isMobile}
              url={`/oportunidades-aceptadas-admin?tab=${EStatusReleasedOpportunityTabs.TO_CLOSE}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2.4}>
            <OpportunitiesSummaryItem
              indicator="En revisión"
              value={data.review.toString()}
              useDivider={!isMobile}
              useMobileDivider={isMobile}
              url={`/oportunidades-aceptadas-admin?tab=${EStatusReleasedOpportunityTabs.IN_REVIEW}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2.4}>
            <OpportunitiesSummaryItem
              indicator="En Inversión"
              value={data.inInvestment.toString()}
              useDivider={!isMobile}
              useMobileDivider={isMobile}
              url={`/oportunidades-aceptadas-admin?tab=${EStatusReleasedOpportunityTabs.IN_INVESTMENT_PROCESS}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2.4}>
            <OpportunitiesSummaryItem
              indicator="Expiradas"
              value={data.expired.toString()}
              useDivider={false}
              url={`/oportunidades-aceptadas-admin?tab=${EStatusReleasedOpportunityTabs.EXPIRED}`}
            />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default OpportunitiesSummary;
