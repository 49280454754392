import * as yup from "yup";

import { CAMPO_REQUERIDO } from "../../../../../../utils/text/inputs";

export interface IRejectForm {
  comment: string;
}

export const rejectSchema = yup.object({
  comment: yup.string().required(CAMPO_REQUERIDO),
});
