import { EStatusReleasedOpportunity } from "../../../../constants";
import { ERolesCode } from "../../../routes/role-catalog";

export const VALID_ROLES_TO_TO_EDIT_MAIN_CARD = [
  ERolesCode.SUPER_ADMIN,
  ERolesCode.ADMINISTRATOR,
  ERolesCode.PARTNER,
  ERolesCode.ANALYST,
];
export const VALID_ROLES_TO_RELEASE = [
  ERolesCode.SUPER_ADMIN,
  ERolesCode.ADMINISTRATOR,
  ERolesCode.PARTNER,
];

export const VALID_STATUS_TO_SHOW_STATUS_AFTER_STATUS_IN_REVIEW = [
  EStatusReleasedOpportunity.INCOMPLETE,
  EStatusReleasedOpportunity.OPEN_TO_INVESTMENT,
  EStatusReleasedOpportunity.CANCELLED,
  EStatusReleasedOpportunity.EXPIRED,
  EStatusReleasedOpportunity.IN_REVIEW,
];

export const VALID_STATUS_TO_SHOW_DECK_AND_PITCH = [
  ERolesCode.ADMINISTRATOR,
  ERolesCode.SUPER_ADMIN,
  ERolesCode.PARTNER,
  ERolesCode.MEMBER,
  ERolesCode.ANALYST,
  ERolesCode.STARTUP,
];

export const VALID_STATUS_TO_CANCEL = [
  EStatusReleasedOpportunity.INCOMPLETE,
  EStatusReleasedOpportunity.OPEN_TO_INVESTMENT,
  EStatusReleasedOpportunity.IN_REVIEW,
  EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT,
  EStatusReleasedOpportunity.IN_INVESTMENT_PROCESS,
  EStatusReleasedOpportunity.INVESTED,
];

export const VALID_STATUS_TO_SWITCH_TO_NORMAL_BUTTON = [
  EStatusReleasedOpportunity.INVESTED,
  EStatusReleasedOpportunity.EXPIRED,
  EStatusReleasedOpportunity.CANCELLED,
];
