import PaymentsIcon from "@mui/icons-material/Payments";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Box, Button, Grid, Typography } from "@mui/material";
import { FC, useState } from "react";

import { currencyFormat } from "../../../../../../../utils/helpers/currencyFormatter";
import { styles as mainStyles } from "../../../styles";
import { Modal } from "../Modal/Modal";
import { styles } from "../styles";

interface IInvestmentRoundCard {
  hook?: any;
  requiredInvestment: number;
}

export const InterestedCard: FC<IInvestmentRoundCard> = ({
  hook,
  requiredInvestment,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const hookValues = hook.getValues();

  const usersInterested = hookValues.usersInterested || [];

  return (
    <Grid item xs={12} md={6}>
      {openModal && (
        <Modal
          openModal={setOpenModal}
          state={openModal}
          interestedOrInvest={true}
          hook={hook}
        />
      )}
      <Box
        sx={{
          ...mainStyles.Info,
          ...styles.boxCardContainer,
        }}
      >
        <PaymentsIcon sx={{ ...mainStyles.Icons }} />
        <Box>
          <Typography sx={{ ...mainStyles.title }}>
            Inversión requerida:
          </Typography>
          <Typography sx={{ ...mainStyles.amounts }}>
            <strong>{currencyFormat(requiredInvestment)}</strong>{" "}
            <span>USD</span>
          </Typography>
        </Box>
      </Box>
      <Box sx={{ ...mainStyles.Info }}>
        <ThumbUpIcon sx={{ ...mainStyles.Icons }} />
        <Box style={{ width: "75%" }}>
          <Typography sx={{ ...mainStyles.title, marginBottom: "8px" }}>
            Miembros interesados:
          </Typography>
          <Box sx={{ ...styles.boxListContainer }}>
            <Box>
              {usersInterested.length >= 1 ? (
                usersInterested.map((item: any, index: number) => {
                  return (
                    <Box key={index}>
                      <Typography sx={{ fontSize: "16px" }}>
                        {item.user.name}
                      </Typography>
                      <hr />
                    </Box>
                  );
                })
              ) : (
                <>
                  <Typography sx={{ fontSize: "16px" }}>
                    Aún no hay más inversionistas interesados{" "}
                  </Typography>
                  <hr />
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ ...styles.buttonSeeAllContainer }}>
            {usersInterested?.length > 5 && (
              <Button
                onClick={() => setOpenModal(!openModal)}
                sx={{ ...styles.seeAll }}
              >
                ver todos
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
