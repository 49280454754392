import { styled } from "@mui/material/styles";

import { paletteColors } from "../../styles/theme";

const styles = {
  container: {
    display: "flex",
    flex: 1,
    minHeight: "100vh",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    width: {
      xs: 0,
      md: "50vw",
    },
    "@media (max-width: 800px)": {
      display: "none",
      height: 0,
    },
    // Media query to handle ipad Pro
    "@media only screen and (width: 1024px) and (height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5)":
      {
        display: "none",
        height: 0,
      },
  },
  cardContainer: {
    display: "flex",
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
    flexDirection: "column",
    alignItems: "center",
    background: "#FFF 0% 0% no-repeat padding-box",
    paddingLeft: {
      xs: "28px",
      md: "0px",
    },
    paddingRight: {
      xs: "28px",
      md: "0px",
    },
    "@media (max-width: 365px)": {
      paddingLeft: "12px",
      paddingRight: "12px",
    },
    "@media only screen and (max-height: 650px) and (max-width: 500)": {
      height: "unset",
    },
  },
  registerContainer: {
    "@media (max-height: 600px) and (max-width: 1280px)": {
      flexDirection: "row",
      flexGrow: 2,
      alignItems: "flex-end",
      justifyContent: "flex-end",
      backgroundColor: "#FAFBFC",
      paddingTop: {
        xs: 0,
        lg: "20px",
      },
      paddingBottom: {
        xs: 0,
        lg: "24px",
      },
    },
  },
  privacyWrapper: {
    marginTop: {
      xs: "25px",
      sm: "40px",
    },
    display: "flex",
  } as const,
  privacyText: {
    letterSpacing: "0.4px",
    color: "#5D5D5D",
    borderBottom: "1px solid #5D5D5D",
    paddingBottom: "2px",
    fontSize: "0.75rem",
  },
  separator: {
    fontSize: "0.75rem",
    color: "#5D5D5D",
    margin: "0px 4px",
  },
  footer: {
    flex: 1,
    "@media (max-height: 800px)": {
      position: "relative",
      right: "unset",
      bottom: "unset",
      marginTop: "15px",
    },
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    width: "100%",
    paddingRight: "15px",
    paddingTop: "22px",
    paddingBottom: "22px",
  },
  footerMobile: {
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  termsContainer:{
    display: "flex",
    gap: "10px"
  },
  footerLargeScreen: {
    "@media (max-height: 1000px)": {
      position: "static",
      right: "unset",
      bottom: "unset",
      marginTop: "15px",
      marginBottom: "12px",
    },
    "@media (max-width: 450px)": {
      marginRight: 0,
      alignSelf: "center",
    },
  },
  text: {
    color: paletteColors.grayTitle,
    fontSize: "0.75rem",
    width: "fit-content",
    margin: "auto"
  },
  // PageTitle styles
  title: {
    color: paletteColors.grayTitle,
    marginLeft: {
      sm: "10px",
      xs: 0,
    },
    alignItems: "center",
  },
  rowWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  childrenHeaderContainer: {
    backgroundColor: "#fff",
    flexDirection: "column",
    marginTop: "64px",
    display: "flex",
    position: "fixed",
    justifyContent: "center",
    alignItems: "flex-start",
    zIndex: 10,
    width: "100%",
    height: "64px",
    paddingLeft: "20px",
  },
  childrenHeaderIconContainer: {
    height: "63px",
    margin: 0,
  },
  childrenHeaderIcon: {
    color: "#000",
    fontSize: "2rem",
  },
  // Toolbar Styles
  appBar: {
    backgroundColor: "#fff",
  },
  toolbarHeaderOpened: {
    display: "flex",
    color: "#000",
    background: "#fff",
    width: {
      xs: 0,
      md: "256px",
    },
    height: "64px",
    justifyContent: "space-between",
    transition: "width .7s",
  },
  toolbarHeaderClosed: {
    display: "flex",
    color: "#000",
    background: "#fff",
    width: {
      xs: 0,
      md: "72px",
    },
    height: "64px",
    justifyContent: "center",
    transition: "width .7s",
  },
  toolbarHeaderLogo: {
    width: "100%",
    height: "100%",
    display: "flex",
    objectFit: "contain",
  },
  toolbarExtendedLogo: {
    padding: "15px 38px 15px 38px",
  },
  toolbarCompressedLogo: {
    padding: "15px",
  },
  toolbarIconButton: {
    color: "#69BC41",
    height: "47px",
    marginTop: "8px",
    left: "19px",
  },
  grow: {
    flexGrow: 1,
  },
  profileDownArrowIcon: {
    margin: "0px 10px",
    padding: "5px",
    color: "#BBBBBB",
  },
  iconButton: {
    fontSize: "1rem",
  },
  buttonWrapper: {
    display: {
      md: "none",
      xs: "flex",
    },
    paddingRight: "10px",
  },
  button: {
    height: "36px",
    minWidth: "138px",
    fontWeight: 400,
    width: {
      sm: "unset",
      xs: "100%",
    },
  },
} as const;

// Interface for the custom image component
interface LogoProps {
  largeScreen?: boolean;
  withOutMarginBottom?: boolean;
}

// Custom implementation for a img component
export const Logo = styled("img", {
  shouldForwardProp: (prop) =>
    !["largeScreen", "withOutMarginBottom"].includes(prop as string),
})<LogoProps>(({ largeScreen, withOutMarginBottom }) => ({
  width: "300px",
  maxHeight: "13vh",
  marginBottom: "33px",
  marginTop: "74px",
}));

export const Banner = styled("img")({
  objectFit: "cover",
  width: "100%",
  height: "100vh",
});

export default styles;
