import { Box, Divider, Grid, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

import HorizontalDivider from "../../../components/Dashboard/HorizontalDivider";
import { useScroll } from "../../../hooks/useScroll";

import { MyPortfolioIndicatorStyles } from "./style";

interface IMyPortfolioProps {
  indicator: string;
  value: string;
  useDivider?: boolean;
  useMobileDivider?: boolean;
  url?: string;
}

const MyPortfolioIndicator: React.FC<IMyPortfolioProps> = ({
  indicator,
  value,
  useDivider = true,
  useMobileDivider = false,
  url,
}) => {
  const { ref } = useScroll();
  const history = useHistory();

  function handleRedirect() {
    if (url) {
      history.push(url);
      console.log(ref);
      setTimeout(() => {
        (ref as any)?.current.scrollTo(0, 1000000);
      }, 500);
    }
  }
  return (
    <Box sx={MyPortfolioIndicatorStyles}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={handleRedirect}
              variant="h4"
            >
              {value}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {indicator === "Rendimiento" ? (
              <Tooltip title="Con base en la valuación de la última ronda. Este valor puede variar en una transacción real de salida debido a la dilución de las inversiones.">
                <Typography variant="subtitle1">Rendimiento *</Typography>
              </Tooltip>
            ) : (
              <Typography variant="subtitle1">{indicator}</Typography>
            )}
          </Grid>
        </Grid>
        {useDivider && <Divider orientation="vertical" flexItem />}
      </Stack>
      {useMobileDivider && <HorizontalDivider sx={{ pb: 2 }} />}
    </Box>
  );
};

export default MyPortfolioIndicator;
