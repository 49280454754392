import { Dispatch } from "redux";

import { getApplications } from "../../services/application";

export const LOADING_APPLICATION = "LOADING_APPLICATION";

export const FETCH_APPLICATIONS_PAGINATED = "FETCH_APPLICATION_PAGINATED";
export const fetchApplication = () => async (dispatch: Dispatch) => {
  dispatch({ type: LOADING_APPLICATION, payload: true });
  try {
    const response = await getApplications();

    const data = response.data.payload as any;

    dispatch({
      type: FETCH_APPLICATIONS_PAGINATED,
      payload: data,
    });
  } catch {
    dispatch({ type: LOADING_APPLICATION, payload: false });
  } finally {
    dispatch({ type: LOADING_APPLICATION, payload: false });
  }
};

export const CHANGE_PAGE_APPLICATIONS = "CHANGE_PAGE_APPLICATIONS";
export const changePageApplications = (value: number) => {
  return {
    type: CHANGE_PAGE_APPLICATIONS,
    payload: value,
  };
};
export const CHANGE_LIMIT_APPLICATIONS = "CHANGE_LIMIT_APPLICATIONS";
export const changeLimitApplications = (value: number) => {
  return {
    type: CHANGE_LIMIT_APPLICATIONS,
    payload: value,
  };
};
export const CHANGE_APPLICATION_VALUE = "CHANGE_APPLICATION_VALUE";
export const changeApplication = (value: string) => {
  return {
    type: CHANGE_APPLICATION_VALUE,
    payload: value,
  };
};
export const CHANGE_APPLICATIONS_VALUE = "CHANGE_APPLICATIONS_VALUE";
export const changeApplications = (value: any) => {
  return {
    type: CHANGE_APPLICATIONS_VALUE,
    payload: value,
  };
};

export const CLEAR_REDUCER_APPLICATIONS = "CLEAR_REDUCER_APPLICATIONS";
export const clearStateApplications = () => {
  return {
    type: CLEAR_REDUCER_APPLICATIONS,
  };
};
