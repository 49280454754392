import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Link,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";

import appleLogo from "../../../src/assets/images/apple-logo.png";
import googleLogo from "../../../src/assets/images/google-logo.png";
import { login, loginSuccessActions } from "../../redux/actions/LoggedUser";
import { useDispatch } from "../../redux/typedHooks";
import { TextInput } from "../Inputs";
import Checkbox from "../Inputs/Checkbox";
import PasswordFieldEye from "../PasswordFieldEye/PasswordFieldEye";

import { loginSchema } from "./schema";
import styles, { GoogleLogo, AppleLogo } from "./styles";

interface ILoginForm {
  googleLogin?: boolean;
  appleLogin?: boolean;
}

type FormType = {
  email: string;
  password: string;
  remindMeSession: boolean;
};

const LoginForm: React.VFC<ILoginForm> = ({ googleLogin, appleLogin }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("callbackPage");

  const [loading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    setError,
    control,
    formState: { isValid },
  } = useForm<FormType>({
    resolver: yupResolver(loginSchema),
    mode: "all",
    defaultValues: {
      email: "",
      password: "",
      remindMeSession: false,
    },
  });
  const handleRegister = async (fields: FormType): Promise<void> => {
    const { email, password, remindMeSession } = fields;
    setLoading(true);
    try {
      dispatch(login());
      const { data } = await axios.post("/login", { email, password });
      dispatch(loginSuccessActions(data.payload, remindMeSession));
      if (activeTab) {
        history.push(`/dashboard?callbackPage=${activeTab}`);
      }
    } catch (error: any) {
      const message = error?.data?.message;
      if (message === "You need to confirm your email to be able to log in") {
        setError("password", {
          message: "Necesitas confirmar tu correo electrónico.",
        });
        setError("email", { message: "" });
      } else {
        setError("password", { message: "Correo o contraseña incorrectos" });
        setError("email", { message: "" });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = (): void => {
    history.push("forgot-password");
  };

  const handleRequestCode = () => {
    history.push("request-code");
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter" && isValid) {
      handleSubmit(handleRegister)();
    }
  };

  return (
    <Grid container direction="column">
      <Typography sx={styles.title}>Inicio de sesión</Typography>
      <Typography sx={styles.subtitle}>
        Bienvenido, inicia sesión con correo electrónico o con código de acceso
      </Typography>
      <Grid item xs={12} sx={styles.topInputWrapper}>
        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              inputRef={field.ref}
              disabled={loading}
              label="Correo"
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error?.message) && fieldState.error?.message
              }
              fullWidth
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sx={styles.inputWrapper}>
        <Controller
          control={control}
          name="password"
          render={({ field, fieldState }) => (
            <PasswordFieldEye
              {...field}
              inputRef={field.ref}
              ref={field.ref}
              disabled={loading}
              label="Contraseña"
              type="password"
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error?.message) && fieldState.error?.message
              }
              onKeyPress={handleKeyPress}
              fullWidth
            />
          )}
        />
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "flex-end",
        }}
      >
        <Controller
          control={control}
          name="remindMeSession"
          render={({ field }) => (
            <Checkbox
              {...field}
              inputRef={field.ref}
              color="primary"
              label="Mantener sesión"
              labelSX={{
                span: {
                  fontSize: "14px !important",
                },
              }}
            />
          )}
        />
        <Link
          onClick={handleForgotPassword}
          underline="none"
          sx={styles.forgotPassword}
        >
          ¿Olvidaste tu contraseña?
        </Link>
      </Box>
      <Grid item xs={12} sx={styles.buttonWrapper}>
        {loading ? (
          <CircularProgress data-testid="loading-indicator" size={36} />
        ) : (
          <Button
            onClick={handleSubmit(handleRegister)}
            variant="contained"
            color="primary"
            sx={styles.loginButton}
          >
            INICIAR SESIÓN
          </Button>
        )}
      </Grid>
      {/* <Typography sx={styles.forgotAccount}>
        ¿No tienes una cuenta?
        <Link underline="none" sx={{ ...styles.signUp, cursor: "text" }}>
          Registrate
        </Link>
      </Typography> */}
      <Divider style={styles.formDivider}> O </Divider>
      <Button
        onClick={handleRequestCode}
        variant="outlined"
        sx={{ marginTop: "15px" }}
      >
        INICIAR CON CÓDIGO
      </Button>
      {(googleLogin || appleLogin) && (
        <Grid item xs={12}>
          {googleLogin && (
            <Grid item xs={12} sx={styles.buttonSeparator}>
              <Button
                variant="outlined"
                sx={styles.socialButton}
                fullWidth
                startIcon={<GoogleLogo src={googleLogo} alt="google" />}
              >
                Login with Google
              </Button>
            </Grid>
          )}
          {appleLogin && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                sx={styles.socialButton}
                fullWidth
                startIcon={<AppleLogo src={appleLogo} alt="apple" />}
              >
                Login with Apple
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default LoginForm;
