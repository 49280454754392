import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";

const UsedToken: React.FC = () => {
  const history = useHistory();
  const isMovil = useMediaQuery("(max-width:321px)");

  const handleReturnToLogin = (): void => {
    history.push("/login");
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        align="center"
        component="h1"
        variant="h5"
        sx={{
          opacity: 1,
          textAlign: "center",
          color: "#5D5D5D",
          fontSize: "24px",
          marginBottom: "20px",
        }}
      >
        Lo sentimos
      </Typography>
      <br />
      <Typography
        align="center"
        component="p"
        variant="inherit"
        sx={{
          fontSize: "16px",
          color: "#5D5D5D",
        }}
      >
        Puede que este proceso ya haya sido completado o el tiempo para
        realizarlo se haya agotado
      </Typography>
      <br />
      <Button
        onClick={handleReturnToLogin}
        variant="contained"
        color="primary"
        sx={isMovil ? { fontSize: "10px" } : {}}
      >
        Regresar al Inicio de sesión
      </Button>
    </Grid>
  );
};

export default UsedToken;
