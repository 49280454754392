import { SxProps, Theme } from "@mui/material";

const RankingInvestmentStyles: SxProps<Theme> = (theme: Theme) => ({
  "& .MuiTableCell-body": {
    fontFamily: "Product Sans Medium !important",
    "& .MuiButtonBase-root": {
      padding: "0 !important",
    },
  },
  "& .MuiTableCell-root": {
    padding: "4px 16px",
  },
  "& .MuiTypography-root.MuiTypography-h5": {
    color: "#515151",
    fontFamily: "Product Sans Medium !important",
    letterSpacing: ".5px",
  },
  "& .MuiTableCell-head": {
    fontWeight: 600,
  },
});

export default RankingInvestmentStyles;
