export enum EUsersTabs {
  ALL = "ALL",
  MEMBERS = "RL004",
  STARTUPS = "RL003",
  ANALYSTS = "RL002",
  PARTNERS = "RL001",
  ADMINS = "RL005",
}

export type PeriodType = "DIA" | "MES" | "TRIMESTRE" | "AÑO" | "";

export const PERIOD_VALUES = ["DIA", "MES", "TRIMESTRE", "AÑO"];
