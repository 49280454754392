import { SxProps, Theme } from "@mui/material";

const DashboardAdminStyles: SxProps<Theme> = (theme: Theme) => ({
  "& .activity-card": {
    [theme.breakpoints.up("sm")]: {
      minHeight: "512px !important",
      height: "auto !important",
    },
  },
});

export default DashboardAdminStyles;
