import * as React from "react";

interface IContext {
  ref: any;
}

const Context = React.createContext<IContext>({
  ref: null,
});

export const ProviderScroll: React.FC = ({ children }) => {
  const ref = React.useRef(null);

  const value = React.useMemo(() => {
    return {
      ref,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useScroll = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error("Debe ir dentro del provider scroll");
  }
  return context;
};
