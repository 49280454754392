import { GetApp } from "@mui/icons-material";
import { Box } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import React from "react";

import CustomPagination from "../DataGrid/CustomPagination";
import {
  DEFAULT_ROWS_PAGE_SIZE,
  DEFAULT_ROWS_PER_PAGE,
} from "../DataGrid/constants";

import { standardColumns } from "./columnsDefinition";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "white",
      "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "#E2E2E280",
      },
      "& .MuiDataGrid-columnHeader:first-child": {
        paddingLeft: "12px",
      },
      "& .MuiDataGrid-cell": {
        padding: "0.2rem",
        paddingRight: "30px",
        fontSize: "16px",
      },
      "& .MuiDataGrid-cell:first-child": {
        padding: "0px",
        paddingLeft: "12px",
        margin: "0px",
      },
    },
  }),
);
type BaseDataGridProps = DataGridProps & { hidePagination?: boolean };

const BaseDataGrid = (props: BaseDataGridProps) => {
  const classes = useStyles();
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <DataGrid
        {...props}
        disableSelectionOnClick
        checkboxSelection={false}
        columns={standardColumns(props.columns)}
        localeText={{
          toolbarExportLabel: "",
          toolbarExport: "",
          toolbarDensity: "",
          toolbarDensityLabel: "",
          footerRowSelected: () => "",
          noRowsLabel: "Aún no hay registros",
        }}
        rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE}
        pageSize={props.pageSize || DEFAULT_ROWS_PAGE_SIZE}
        className={classes.root}
        sortingOrder={["asc", "desc"]}
        components={{
          ...props.components,
          ExportIcon: GetApp,
          ...(!props.hidePagination && { Pagination: CustomPagination }),
        }}
        componentsProps={{
          ...props.componentsProps,
          pagination: {
            limit: props.pageSize,
            page: props.page,
            totalCount: props.rows.length,
            onChangePage: props.onPageChange,
            onChangePageLimit: props.onPageSizeChange,
          },
        }}
      />
    </Box>
  );
};

export default BaseDataGrid;
