import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, Menu, MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { IMainCardForm } from "../../../../../MainCard/types";
import { TypeEvaluation } from "../consts";

interface IProps {
  changeEvaluation: (value: TypeEvaluation) => void;
  evaluation: TypeEvaluation;
  edit: boolean;
}

const ButtonEvaluation: React.FC<IProps> = ({
  changeEvaluation,
  evaluation,
  edit,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const hook = useFormContext<IMainCardForm>();
  const open = Boolean(anchorEl);
  const partnerAssigned = hook.watch("partnerAssigned");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (partnerAssigned) {
      changeEvaluation("partner");
    }
  }, []);

  function handleChangeEvaluation(value: TypeEvaluation) {
    changeEvaluation(value);
    handleClose();
  }

  return (
    <>
      <Button
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disabled={edit}
        sx={{ fontSize: "14px", color: "#4A88C2" }}
        endIcon={<ArrowDropDownIcon fontSize="large" />}
      >
        {evaluation === "analyst" ? "ANALISTA" : "PARTNER"}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {partnerAssigned && (
          <MenuItem onClick={() => handleChangeEvaluation("partner")}>
            PARTNER
          </MenuItem>
        )}
        <MenuItem onClick={() => handleChangeEvaluation("analyst")}>
          ANALISTA
        </MenuItem>
      </Menu>
    </>
  );
};

export default ButtonEvaluation;
