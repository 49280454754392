import { GroupAdd } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { TabContext } from "@mui/lab";
import { Autocomplete, Badge, Box, Grid, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";
import {
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { ReactElement, VFC, useState } from "react";
import { useHistory } from "react-router-dom";

import { ERolesCode } from "../../../pages/routes/role-catalog";
import { useSelector } from "../../../redux/typedHooks";
import DrawerFilter from "../../DrawerFilter/DrawerFilter";
import { TextInput } from "../../Inputs";
import SplitButton from "../../SplitButton/SplitButton";

import CreateAdmin from "./CrudUsers/CreateAdmin";
import CreateAnalyst from "./CrudUsers/CreateAnalyst";
import CreateMembers from "./CrudUsers/CreateMembers";
import CreatePartner from "./CrudUsers/CreatePartner";
import CreateStartup from "./CrudUsers/CreateStartup";
import Tabs from "./Tabs";
import { PERIOD_VALUES, PeriodType } from "./const";
import styles from "./styles";

const useStyles = makeStyles({
  inputLabel: {
    transform: "translate(14px, -3px) scale(0.75) !important",
  },
});

type Active = "Activo" | "Inactivo";

interface IBaseToolbarProps {
  onSearchChange?: (value: string) => void;
  onIsActiveChange?: (value: string | null) => void;
  onSearchClear?: () => void;
  buttonText: string;
  buttonAction?: () => void;
  hideButton?: boolean;
  hideIcons?: boolean;
  children: ReactElement;
  resetPage?: () => void;
  Tab: any;
  Period: PeriodType;
}

const BaseToolbar: VFC<IBaseToolbarProps> = (props) => {
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  const {
    onSearchChange,
    onSearchClear,
    onIsActiveChange,
    buttonText,
    hideButton,
    hideIcons,
    resetPage,
    Tab,
    Period,
  } = props;
  const classes = useStyles();
  const [search, setSearch] = useState<string>("");
  const [modalMember, setModalMember] = useState(false);
  const [modalStartup, setModalStartup] = useState(false);
  const [modalAnalyst, setModalAnalyst] = useState(false);
  const [modalAdmin, setModalAdmin] = useState(false);
  const [modalPartner, setModalPartner] = useState(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const isMobile = useMediaQuery("(max-width:730px)");
  const breakpoint = useMediaQuery("(max-width: 1350px)");

  const [Status, setStatus] = useState<string>("");
  const [InputStatus, setInputStatus] = useState<string>("");

  const history = useHistory();

  const handleCloseMember = () => {
    setModalMember(true);
  };

  const handleCloseStartup = () => {
    setModalStartup(true);
  };

  const handleCloseAnalyst = () => {
    setModalAnalyst(true);
  };

  const handleCloseAdmin = () => {
    setModalAdmin(true);
  };

  const CloseModalMember = () => {
    setModalMember(false);
  };

  const CloseModalStartup = () => {
    setModalStartup(false);
  };

  const CloseModalAnalyst = () => {
    setModalAnalyst(false);
  };

  const CloseModalAdmin = () => {
    setModalAdmin(false);
  };
  const CloseModalPartner = () => {
    setModalPartner(false);
  };

  const handleSearchChange = (event: string) => {
    setSearch(event);
    onSearchChange && onSearchChange(event);
  };

  const handleSearchClear = () => {
    setSearch("");
    onSearchClear && onSearchClear();
  };

  const handleChangeActive = (value: string | null) => {
    !openDrawer && onIsActiveChange && onIsActiveChange(value);
  };

  const handleFilter = () => {
    onIsActiveChange && onIsActiveChange(Status);
    setOpenDrawer(false);
  };

  const handleChangePeriod = (_: any, newValue: any) => {
    resetPage && resetPage();

    let search = "";

    if (Tab) {
      search += `?tab=${Tab}`;
    }

    if (newValue) {
      search += `&period=${newValue}`;
    }

    history.replace({
      pathname: `${history.location.pathname}`,
      search,
    });
  };

  const filter = (
    <>
      <Autocomplete
        sx={styles.searchField}
        value={Status}
        inputValue={InputStatus}
        onInputChange={(_, newInputValue) => {
          setStatus(newInputValue);
          setInputStatus(newInputValue);
        }}
        onChange={(_, newValue) => {
          handleChangeActive(newValue as string);
        }}
        isOptionEqualToValue={(value) => value === Status}
        options={["Activo", "Inactivo"] as Array<Active>}
        getOptionLabel={(option) => option}
        renderInput={({ InputLabelProps, InputProps, inputProps, ...rest }) => (
          <TextInput
            {...rest}
            InputProps={{
              ...InputProps,
              style: { height: 48, paddingTop: "6px" },
            }}
            inputProps={inputProps}
            InputLabelProps={{
              ...InputLabelProps,
              style: { top: "-3.5px" },
              classes: { shrink: classes.inputLabel },
            }}
            label="Estatus"
          />
        )}
      />
      <Autocomplete
        sx={styles.searchField}
        value={Period}
        onChange={handleChangePeriod}
        options={PERIOD_VALUES}
        renderInput={({ InputLabelProps, InputProps, inputProps, ...rest }) => (
          <TextInput
            {...rest}
            InputProps={{
              ...InputProps,
              style: { height: 48, paddingTop: "6px" },
            }}
            inputProps={inputProps}
            InputLabelProps={{
              ...InputLabelProps,
              style: { top: "-3.5px" },
              classes: { shrink: classes.inputLabel },
            }}
            label="Periodo"
          />
        )}
      />
    </>
  );

  const isSupAdmin = user.role.code === ERolesCode.SUPER_ADMIN;
  const isAdmin = user.role.code === ERolesCode.ADMINISTRATOR;
  const isAnalyst = user.role.code === ERolesCode.ANALYST;
  const isPartner = user.role.code === ERolesCode.PARTNER;

  return (
    <TabContext value={Tab}>
      <Grid container sx={{ ...styles.toolbar, gap: "12px" }}>
        <Grid container item xs={12} spacing={2}>
          <Box
            sx={{
              padding: "15px 0px 25px 0px",
              width: "100%",
              overflow: "hide",
            }}
          >
            <Tabs />
          </Box>
        </Grid>
        <Grid container item xs={12} sx={{ gap: "12px" }}>
          <TextInput
            value={search}
            fullWidth={isMobile}
            onChange={(e) => {
              handleSearchChange(String(e.target.value));
            }}
            label="Buscar"
            InputProps={{
              sx: { height: "48px" },
              startAdornment: (
                <SearchIcon fontSize="small" sx={styles.searchIcon} />
              ),
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{
                    visibility: search.length > 0 ? "visible" : "hidden",
                  }}
                  onClick={handleSearchClear}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />

          <Box sx={styles.optionsWrapper}>
            {breakpoint ? (
              <IconButton onClick={() => setOpenDrawer(true)}>
                <Badge badgeContent={Status ? 1 : 0} color="primary">
                  <FilterListIcon />
                </Badge>
              </IconButton>
            ) : (
              <Box sx={{ display: "flex", gap: "12px" }}>{filter}</Box>
            )}
            <DrawerFilter
              isOpen={openDrawer}
              onFilter={handleFilter}
              onClose={() => setOpenDrawer(false)}
            >
              {filter}
            </DrawerFilter>
            <Box display={"flex"} alignItems="center">
              {!hideIcons && (
                <Box sx={styles.iconsWrapper}>
                  <GridToolbarDensitySelector
                    {...({} as any)}
                    sx={styles.icon}
                  />
                  <GridToolbarExport {...({} as any)} sx={styles.icon} />
                </Box>
              )}

              {!hideButton && (
                <SplitButton
                  isMobile={isMobile}
                  icon={<GroupAdd />}
                  title="Crear usuario"
                  options={[
                    {
                      label: "Miembro",
                      visible: isSupAdmin || isAdmin || isAnalyst || isPartner,
                      onClick: handleCloseMember,
                    },
                    {
                      label: "Startup",
                      visible: isSupAdmin || isAdmin || isAnalyst || isPartner,
                      onClick: handleCloseStartup,
                    },
                    {
                      label: "Analista",
                      visible: isSupAdmin || isAdmin || isPartner,
                      onClick: handleCloseAnalyst,
                    },
                    {
                      label: "Admin",
                      visible: isSupAdmin || isPartner,
                      onClick: handleCloseAdmin,
                    },
                    {
                      label: "Partner",
                      visible: isSupAdmin,
                      onClick: () => setModalPartner(true),
                    },
                  ]}
                />
              )}
            </Box>
          </Box>
          <CreateMembers
            handleOpenModal={modalMember}
            handleCloseModal={CloseModalMember}
          />
          <CreateStartup
            handleOpenModal={modalStartup}
            handleCloseModal={CloseModalStartup}
          />
          <CreateAnalyst
            handleOpenModal={modalAnalyst}
            handleCloseModal={CloseModalAnalyst}
          />
          <CreateAdmin
            handleOpenModal={modalAdmin}
            handleCloseModal={CloseModalAdmin}
          />
          <CreatePartner
            handleOpenModal={modalPartner}
            handleCloseModal={CloseModalPartner}
          />
        </Grid>
      </Grid>
    </TabContext>
  );
};
export default BaseToolbar;
