/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { GridSortItem } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import BaseDataGrid from "../../components/BaseDataGrid/BaseDataGrid";
import { ModalMenu } from "../../components/DataGrid";
import { DEFAULT_ROWS_PAGE_SIZE } from "../../components/DataGrid/constants";
import RejectDialog from "../../components/DetailsMember/DetailsForm/RejectDialog";
import DialogChangePartner from "../../components/DialogChangePartner/DialogChangePartner";
import ApplicationToolbar from "../../components/Toolbars/ApplicationToolbar";
import { ETabsApplicationMembers } from "../../components/Toolbars/ApplicationToolbar/Tabs";
import { useLoading } from "../../hooks/useLoading";
import {
  changeApplications,
  fetchApplication,
} from "../../redux/actions/applications";
import { openModal } from "../../redux/actions/modal";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import {
  updateMultipleStatusApplication,
  updatePartner,
  updateStatusApplication,
} from "../../services/memberApplication";
import { displayToast } from "../../utils/helpers/displayToast";

import { columns } from "./columns";
import { EStatusApplication } from "./constants";

const ApplicationList: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { handleClose, handleOpen } = useLoading();

  const { applications, loading } = useSelector(
    (store) => store.applicationReducer,
  );
  const [Search, setSearch] = useState<string>("");
  const [Status, setStatus] = useState<string>("");
  const [Asigned, setAsigned] = useState<string>("");
  const [DateT, setDate] = useState<string>("");
  const [Nacionalty, setNacionalty] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_ROWS_PAGE_SIZE);

  const Tab = queryParams.get("tab") || ETabsApplicationMembers.ALL; //

  const [idReject, setIdReject] = useState<string>("");
  const [dialog, setDialog] = useState<boolean>(false);
  const [RowsChecked, setRowsChecked] = useState<string[]>([]);
  const [IsMultiple, setIsMultiple] = useState<boolean>(false);
  const [Open, setOpen] = useState<boolean>(false);
  const [rowData, setRowData] = useState<any>({});
  const [reload, setReload] = useState(false);

  // Default sorting
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: "dateApplication",
      sort: "desc",
    },
  ]);

  useEffect(() => {
    dispatch(fetchApplication());
  }, [reload]);

  useEffect(() => {
    setPage(0);
    setPageSize(DEFAULT_ROWS_PAGE_SIZE);
  }, [Search, Status, Asigned, Date, Nacionalty]);

  const renderModalMenu = (data: any) => {
    let detailsOptionsList = [
      {
        name: "Ver detalles",
        action: () => handleDetails(data.id),
      },
    ];

    const conditionalDetailsOptions = [
      {
        name: "Aceptar",
        action: () => {
          dispatch(
            openModal({
              description: `¿Estás seguro de que quieres aceptar la aplicación?`,
              title: "Advertencia",
              action: async () => {
                await handleAcceptApplication(data.row);
              },
            }),
          );
        },
      },
      {
        name: "Rechazar",
        action: () => handleRejectOpportunity(data.id),
      },
    ];

    const validStatus = [
      EStatusApplication.REJECTED,
      EStatusApplication.ACCEPTED,
    ];
    const isAssigned = [
      EStatusApplication.ASSIGNED,
      EStatusApplication.RE_ASSIGNED,
    ];

    if (isAssigned.includes(data.row.status)) {
      detailsOptionsList.push({
        name: "Reasignar",
        action: () => {
          dispatch(
            openModal({
              description: `¿Estás seguro de que quieres reasignar?`,
              title: "Advertencia",
              action: async () => {
                setOpen(!Open);
                setRowData(data);
              },
            }),
          );
        },
      });
    }

    if (!validStatus.includes(data.row.status)) {
      detailsOptionsList = [
        ...detailsOptionsList,
        ...conditionalDetailsOptions,
      ];
    }

    return <ModalMenu menuVert items={detailsOptionsList} />;
  };

  const newColumns = () => [
    ...columns,
    {
      headerName: "Acciones",
      field: "name",
      align: "center",
      hideSortIcons: true,
      maxWidth: 100,
      renderCell: (data: any) => renderModalMenu(data),
    },
  ];

  function handleDetails(id: string) {
    history.push(`/details-member/${id}`);
  }

  function handleCloseDialog() {
    const copyApplication = [...applications];
    const applicationIndex = copyApplication.findIndex(
      (i) => String(i._id) === String(idReject),
    );
    copyApplication[applicationIndex].status = EStatusApplication.REJECTED;
    dispatch(changeApplications(copyApplication));
    setDialog(false);
    setIsMultiple(false);
    setRowsChecked([]);
    setIdReject("");
  }

  async function handleUpdatePartner(partner: any) {
    const copyApplication = [...applications];

    const applicationIndex = copyApplication.findIndex(
      (i) => String(i._id) === String(rowData?.row._id),
    );

    const application = copyApplication[applicationIndex];

    copyApplication[applicationIndex].status = EStatusApplication.ASSIGNED;

    handleOpen();

    try {
      await updatePartner(application, partner.id);
      await updateStatusApplication(rowData?.row?._id, {
        status: EStatusApplication.ASSIGNED,
        partnerId: partner._id,
      });

      dispatch(changeApplications(copyApplication));
      displayToast("success", "Se ha reasignado exitosamente");
      setReload(!reload);
    } catch (error) {
      displayToast("error", "Ha ocurrido un error al intentar reasignar");
    } finally {
      setOpen(false);
      handleClose();
    }
  }

  async function handleCancelUpdatePartner() {
    setOpen(false);
    setIsMultiple(false);
  }

  function handleRejectOpportunity(id: string) {
    setIdReject(id);
    setDialog(true);
  }

  async function handleAcceptApplication(application: any) {
    handleOpen();
    await updateStatusApplication(application._id, {
      status: EStatusApplication.ACCEPTED,
    });
    const copyApplication = [...applications];
    const applicationIndex = copyApplication.findIndex(
      (i) => String(i._id) === String(application._id),
    );
    copyApplication[applicationIndex].status = EStatusApplication.ACCEPTED;
    dispatch(changeApplications(copyApplication));
    displayToast("success", "Guardado con éxito.");
    handleClose();
  }

  async function handleChangeMultipleOpportunity(status: EStatusApplication) {
    if (status === EStatusApplication.REJECTED) {
      setIsMultiple(true);
      setDialog(true);
      return;
    }
    await updateMultipleStatusApplication({
      status,
      ids: RowsChecked,
    });
    const copyApplication = [...applications];

    copyApplication.map((i) => {
      if (RowsChecked.includes(i._id)) {
        i.status = status;
      }
      return i;
    });
    setRowsChecked([]);
    displayToast("success", "Se aceptaron las aplicaciones.");
    dispatch(changeApplications(copyApplication));
  }

  function handleFilter(data: any[]) {
    let values = data.filter((application: any) => {
      return (
        `${application?.firstName} ${application.lastName}`
          .toLowerCase()
          .includes(Search.toLowerCase()) ||
        application.email.toLowerCase().includes(Search.toLowerCase()) ||
        !Search
      );
    });

    if (Status) {
      if (
        Status === EStatusApplication.ASSIGNED ||
        Status === EStatusApplication.RE_ASSIGNED
      ) {
        values = values.filter((i) =>
          [
            EStatusApplication.ASSIGNED,
            EStatusApplication.RE_ASSIGNED,
          ].includes(i.status),
        );
      } else {
        values = values.filter((i) => i.status === Status);
      }
    }

    if (Tab !== ETabsApplicationMembers.ALL) {
      if (
        Tab === EStatusApplication.ASSIGNED ||
        Tab === EStatusApplication.RE_ASSIGNED
      ) {
        values = values.filter((i) =>
          [
            EStatusApplication.ASSIGNED,
            EStatusApplication.RE_ASSIGNED,
          ].includes(i.status),
        );
      } else {
        values = values.filter((i) => i.status === Tab);
      }
    }

    if (Asigned) {
      values = values.filter((i) => i?.partner?._id === Asigned);
    }
    if (DateT) {
      values = values.filter((i) => {
        const end = dayjs();
        const start = dayjs(end).subtract(parseInt(DateT), "month");
        const mainDate = dayjs(new Date(i.dateApplication));
        return mainDate.isAfter(start) && mainDate.isBefore(end);
      });
    }
    if (Nacionalty) {
      values = values.filter((i) => i.nationalityCountry === Nacionalty);
    }

    values = values.filter((i) => i.status !== EStatusApplication.ACCEPTED);

    return values;
  }

  return (
    <>
      <DialogChangePartner
        onAccept={handleUpdatePartner}
        onCancel={handleCancelUpdatePartner}
        open={Open}
      />
      <RejectDialog
        id={idReject}
        ids={RowsChecked}
        showDialog={dialog}
        handleReject={handleCloseDialog}
        isMultiple={IsMultiple}
      />
      <BaseDataGrid
        loading={loading}
        checkboxSelection
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        page={page}
        pageSize={pageSize}
        onPageChange={(number) => setPage(number)}
        onPageSizeChange={(number) => setPageSize(number)}
        columns={newColumns() as any}
        getRowId={(row) => row._id}
        selectionModel={RowsChecked}
        rows={handleFilter(applications)}
        onSelectionModelChange={(ids) => setRowsChecked(ids as string[])}
        components={{
          Toolbar: ApplicationToolbar,
        }}
        localeText={{
          toolbarExportLabel: "",
          toolbarExport: "",
          toolbarDensity: "",
          toolbarDensityLabel: "",
          footerRowSelected: () => "",
        }}
        componentsProps={{
          toolbar: {
            Tab,
            showOptions: Boolean(RowsChecked.length),
            onSearchChange: (value: string) => {
              setSearch(value);
            },
            onStatusChange: (value: string) => {
              setStatus(value);
            },
            onAsignedChange: (value: string) => {
              setAsigned(value);
            },
            onDateChange: (value: string) => {
              setDate(value);
            },
            onNacionaltyChange: (value: string) => {
              setNacionalty(value);
            },
            handleAceptApplication: () => {
              handleChangeMultipleOpportunity(EStatusApplication.ACCEPTED);
            },
            handleRejectApplication: () => {
              handleChangeMultipleOpportunity(EStatusApplication.REJECTED);
            },
            handleChangeAssigned: () => {
              setOpen(true);
              setIsMultiple(true);
            },
            onSearchClear: () => {
              setSearch("");
            },
            hideIcons: true,
          },
        }}
      />
    </>
  );
};

export default ApplicationList;
