/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { TextInput } from "../../../../../../components/Inputs";
import { useLoading } from "../../../../../../hooks/useLoading";
import { useSelector } from "../../../../../../redux/typedHooks";
import {
  addPaymentFile,
  deleteMemberFiles,
  getMemberDocuments,
  getUserByMemberId,
} from "../../../../../../services/member";
import {
  addFileStartup,
  deleteFileStartup,
} from "../../../../../../services/startup";
import { displayToast } from "../../../../../../utils/helpers/displayToast";
import {
  IFileObject,
  IFileObjectMember,
} from "../../../../../../utils/types/common";
import Layout from "../../../../../DetailsStartup/components/TabList/Layout/Layout";
import { IValue } from "../../../../../DetailsStartup/components/TabList/Tabs/AttachedFiles/validations";
import { ERolesCode } from "../../../../../routes/role-catalog";
import mainStyles from "../../../../styles";

import ButtonAddFileMember from "./FileCard/ButtonAddFileMember";
import { FileCard } from "./FileCard/FileCard";
import {
  EFileTypes,
  EFileTypesMember,
  EFileTypesMemberAdmin,
  ROLES_ONLY_TO_DOWNLOAD_FILES,
  ROLES_TO_HIDE_DATA,
} from "./validations";

export const ITEM_VALUE_MEMBER = {
  name: "Inversionista",
  value: "Inversionista",
};

const MyDocuments = () => {
  const { id } = useParams() as { id: string };
  const isMobile = useMediaQuery("(max-width:730px)");
  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [startupsLiked, setStartupsLiked] = useState<IValue[]>([
    ITEM_VALUE_MEMBER,
  ]);
  const [startups, setStartups] = useState<IValue[]>([]);
  const [userSelected, setUserSelected] = useState<IValue | null>(
    ITEM_VALUE_MEMBER,
  );
  const [startupSelected, setStartupSelected] = useState<IValue | null>(null);
  const [userSelectedLabel, setUserSelectedLabel] = useState<string>("");
  const [startupSelectedLabel, setStartupSelectedLabel] = useState<string>("");
  const { handleClose, handleOpen } = useLoading();
  const { loggedUser } = useSelector((store) => store.loggedUser);
  const [openAdminFileModal, setOpenAdminFileModal] = useState<boolean>(false);
  const [openMemberFileModal, setOpenMemberFileModal] =
    useState<boolean>(false);
  const [adminFile, setAdminFile] = useState<File | null>(null);
  const [adminFileError, setAdminFileError] = useState<string>("");
  const [memberFile, setMemberFile] = useState<File | null>(null);
  const [memberFileError, setMemberFileError] = useState<string>("");
  const [depositSlips, setDepositSlips] = useState<IFileObject[]>([]);
  const [sisaFiles, setSisaFiles] = useState<IFileObject[]>([]);
  const [accessionAgreementFiles, setAccessionAgreementFiles] = useState<
    IFileObject[]
  >([]);
  const [proofOfAddressFiles, setProofOfAddressFiles] = useState<IFileObject[]>(
    [],
  );
  const [officialIdentificationFiles, setOfficialIdentificationFiles] =
    useState<IFileObject[]>([]);
  const [othersFiles, setOthersFiles] = useState<IFileObject[]>([]);
  const [accountStatementsFiles, setAccountStatementsFiles] = useState<
    IFileObject[]
  >([]);
  const [paymentFiles, setPayments] = useState<IFileObject[]>([]);

  const isRoleToHideData = ROLES_TO_HIDE_DATA.includes(
    loggedUser.user.role.code,
  );
  const isRoleToHideDelete = ROLES_ONLY_TO_DOWNLOAD_FILES.includes(
    loggedUser.user.role.code,
  );
  const existFiles = filterFiles()?.length;

  useEffect(() => {
    setLoading(true);
    handleMemberDocuments().finally(() => {
      setLoading(false);
    });
  }, [id, reload]);

  async function handleMemberDocuments() {
    const myDocuments = await getMemberDocuments(id);
    setStartupsLiked([
      ITEM_VALUE_MEMBER,
      ...myDocuments.data?.startups.map((startup: any) => ({
        name: startup?.name,
        value: startup?._id,
      })),
    ]);
    setStartups(
      myDocuments.data?.startups.map((startup: any) => ({
        name: startup?.name,
        value: startup?._id,
      })),
    );
    setDepositSlips(myDocuments.data?.depositSlip);
    setSisaFiles(myDocuments.data?.sisa);
    setPayments(myDocuments.data?.payments);
    setAccessionAgreementFiles(myDocuments.data?.accessionAgreement);
    setProofOfAddressFiles(myDocuments.data?.proofOfAddress);
    setOfficialIdentificationFiles(myDocuments.data?.officialIdentification);
    setOthersFiles(myDocuments.data?.others);
    setAccountStatementsFiles(myDocuments.data?.accountStatements);
  }

  async function handleChangeFile(data: File) {
    if (data) {
      if (data.size > 5 * 1024 * 1024) {
        if (loggedUser.user.role.code === ERolesCode.MEMBER)
          setMemberFileError(
            "El archivo es demasiado pesado. El peso límite es de 5MB.",
          );

        if (
          loggedUser.user.role.code === ERolesCode.ADMINISTRATOR ||
          loggedUser.user.role.code === ERolesCode.ANALYST ||
          loggedUser.user.role.code === ERolesCode.SUPER_ADMIN ||
          loggedUser.user.role.code === ERolesCode.PARTNER
        )
          setAdminFileError(
            "El archivo es demasiado pesado. El peso límite es de 5MB.",
          );

        return false;
      }

      if (loggedUser.user.role.code === ERolesCode.MEMBER) setMemberFile(data);

      if (
        loggedUser.user.role.code === ERolesCode.ADMINISTRATOR ||
        loggedUser.user.role.code === ERolesCode.ANALYST ||
        loggedUser.user.role.code === ERolesCode.SUPER_ADMIN ||
        loggedUser.user.role.code === ERolesCode.PARTNER
      )
        setAdminFile(data);
    }
  }

  async function handleDeleteFile(
    fileId: string,
    fileType: EFileTypes | EFileTypesMember | EFileTypesMemberAdmin,
    id: string,
  ) {
    handleOpen();

    if (userSelected === ITEM_VALUE_MEMBER || !userSelected) {
      await deleteMemberFiles(id, fileId, fileType);
    } else {
      await deleteFileStartup(id, fileId, fileType);
    }
    setReload(!reload);
    handleClose();
  }

  const handleUploadButton = () => {
    if (loggedUser.user.role.code === ERolesCode.MEMBER)
      setOpenMemberFileModal(true);

    if (
      loggedUser.user.role.code === ERolesCode.ADMINISTRATOR ||
      loggedUser.user.role.code === ERolesCode.ANALYST ||
      loggedUser.user.role.code === ERolesCode.SUPER_ADMIN ||
      loggedUser.user.role.code === ERolesCode.PARTNER
    )
      setOpenAdminFileModal(true);
  };

  const handleCloseModal = () => {
    setOpenAdminFileModal(false);
    setAdminFile(null);
    setAdminFileError("");
    setMemberFileError("");
    setOpenMemberFileModal(false);
    setMemberFile(null);
  };

  const handleAdminUploadFile = async () => {
    if (adminFile && startupSelected) {
      handleOpen();
      const user = await getUserByMemberId(id);
      const form = new FormData();
      form.append("userId", user.data.payload?._id);
      form.append("file", adminFile);
      form.append("date", moment().toString());
      form.append("startupId", startupSelected.value);
      form.append("type", EFileTypes.depositSlip);
      try {
        await addFileStartup(form);
        displayToast("success", "Se subió correctamente");
      } catch (error) {
        displayToast("error", "Hubo un error al subir el archivo.");
      } finally {
        setAdminFile(null);
        setMemberFile(null);
        handleClose();
      }
    }
    setOpenAdminFileModal(false);
    setReload(!reload);
  };

  const handleMemberUploadFile = async () => {
    if (memberFile) {
      handleOpen();
      const form = new FormData();
      form.append("payment", memberFile);
      form.append("memberId", id);
      try {
        await addPaymentFile(form);
        displayToast("success", "Se subió correctamente");
      } catch (error) {
        displayToast("error", "Hubo un error al subir el archivo.");
      } finally {
        setAdminFile(null);
        setMemberFile(null);
        handleClose();
      }
    }
    setOpenMemberFileModal(false);
    setReload(!reload);
  };

  function filterFiles() {
    let data: IFileObjectMember[] = [];

    if (userSelected === ITEM_VALUE_MEMBER || !userSelected) {
      data = paymentFiles.map((filesToShow) => {
        return {
          ...filesToShow,
          fileType: EFileTypesMemberAdmin.payments,
          user: loggedUser.user,
        };
      });
    } else {
      data = [
        ...data,
        ...depositSlips
          .filter((depo) => depo.startupId === userSelected?.value)
          .map((filesToShow) => {
            return {
              ...filesToShow,
              fileType: EFileTypes.depositSlip,
              startupId: userSelected?.value,
            };
          }),
      ];
      data = [
        ...data,
        ...sisaFiles
          .filter((sisa) => sisa.startupId === userSelected?.value)
          .map((file) => {
            return {
              ...file,
              fileType: EFileTypes.sisa,
              startupId: userSelected?.value,
            };
          }),
      ];
    }

    if (accessionAgreementFiles.length > 0) {
      data = [
        ...data,
        ...accessionAgreementFiles.map((file) => {
          return {
            ...file,
            fileType: EFileTypesMember.accessionAgreement,
            memberId: id,
          };
        }),
      ];
    }
    if (proofOfAddressFiles.length > 0) {
      data = [
        ...data,
        ...proofOfAddressFiles.map((file) => {
          return {
            ...file,
            fileType: EFileTypesMember.proofOfAddress,
            memberId: id,
          };
        }),
      ];
    }
    if (officialIdentificationFiles.length > 0) {
      data = [
        ...data,
        ...officialIdentificationFiles.map((file) => {
          return {
            ...file,
            fileType: EFileTypesMember.officialIdentification,
            memberId: id,
          };
        }),
      ];
    }

    if (accountStatementsFiles.length > 0) {
      data = [
        ...data,
        ...accountStatementsFiles.map((file) => {
          return {
            ...file,
            fileType: EFileTypesMemberAdmin.accountStatements,
            memberId: id,
          };
        }),
      ];
    }

    if (othersFiles.length > 0) {
      data = [
        ...data,
        ...othersFiles.map((file) => {
          return {
            ...file,
            fileType: EFileTypesMember.others,
            memberId: id,
          };
        }),
      ];
    }

    return data;
  }
  if (loading) {
    return (
      <Box
        minHeight={"200px"}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Grid container>
      <Grid
        container
        justifyContent={{ xs: "right", sm: "space-between" }}
        gap={2}
        xs={12}
      >
        <Grid item xs={12} display="flex" justifyContent="space-between">
          <Autocomplete
            value={userSelected}
            inputValue={userSelectedLabel}
            onChange={(_, data: any) => {
              setUserSelected(data);
            }}
            onInputChange={(_, value) => setUserSelectedLabel(value)}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            fullWidth
            sx={{ maxWidth: "200px" }}
            options={startupsLiked}
            getOptionLabel={(i) => i.name}
            renderInput={({
              InputLabelProps,
              InputProps,
              inputProps,
              ...rest
            }) => (
              <TextInput
                {...rest}
                InputProps={{
                  ...InputProps,
                  style: { height: 48, paddingTop: "6px" },
                }}
                inputProps={inputProps}
                InputLabelProps={{
                  ...InputLabelProps,
                  style: { top: "-3.5px" },
                }}
                label="Filtrar por"
              />
            )}
          />
          <Layout.Button>
            {!isRoleToHideData && !isMobile && (
              <ButtonAddFileMember
                id={id}
                xs={mainStyles.actionsButtons}
                setReload={() => setReload(!reload)}
                users={[loggedUser]}
                openAdminFileModal={openAdminFileModal}
                handleCloseModal={handleCloseModal}
                startupSelected={startupSelected}
                startupSelectedLabel={startupSelectedLabel}
                setStartupSelected={setStartupSelected}
                setStartupSelectedLabel={setStartupSelectedLabel}
                startups={startups}
                adminFile={adminFile}
                adminFileError={adminFileError}
                handleAdminUploadFile={handleAdminUploadFile}
                openMemberFileModal={openMemberFileModal}
                memberFile={memberFile}
                memberFileError={memberFileError}
                handleMemberUploadFile={handleMemberUploadFile}
                handleUploadButton={handleUploadButton}
                handleChangeFilePayment={handleChangeFile}
              />
            )}
          </Layout.Button>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={{ xs: "center", sm: "left" }}
        sx={{ margin: "40px 0px", gap: "16px" }}
      >
        {existFiles ? (
          filterFiles().map((file, index) => (
            <FileCard
              onlyShowDownload={
                file.fileType === EFileTypes.sisa ||
                (isRoleToHideDelete &&
                  file.fileType === EFileTypes.depositSlip) ||
                (isRoleToHideDelete &&
                  file.fileType === EFileTypesMemberAdmin.accountStatements) ||
                (isRoleToHideDelete &&
                  file.fileType === EFileTypesMemberAdmin.accessionAgreement) ||
                (isRoleToHideDelete &&
                  file.fileType ===
                    EFileTypesMemberAdmin.officialIdentification) ||
                (isRoleToHideDelete &&
                  file.fileType === EFileTypesMemberAdmin.proofOfAddress) ||
                (isRoleToHideDelete &&
                  file.fileType === EFileTypesMemberAdmin.others)
              }
              onDelete={handleDeleteFile}
              fileObject={file}
              key={index}
            />
          ))
        ) : (
          <Box display={"flex"} justifyContent="center" width={"100%"}>
            <Typography component={"h4"} variant="h4" mt={"20px"}>
              No hay archivos disponibles
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default MyDocuments;
