import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import { fetchUserByRole } from "../../services/users";
import { ERolesCode } from "../routes/role-catalog";

import DirectoryPagination from "./DirectoryPagination";
import FiltersDirectoryInvestors from "./FiltersDirectoryInvestors";
import InvestorCard from "./InvestorCard";
import { directoryInvestorsStyles } from "./styles";

const DirectoryInvestors = () => {
  const [users, setUsers] = useState<any>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState(0);
  const [cardsPerPage, setCardsPerPage] = useState<number>(9);

  const [Industry, setIndustry] = useState<any>([]);
  const [City, setCity] = useState<any>([]);
  const [SearchText, setSearchText] = useState<string>("");

  const IsFiltering = Boolean(
    Industry.toString() || City.toString() || SearchText,
  );

  const DIRECTORY_INVESTORS_CITY: any = [];

  users.forEach((user: any) => {
    if (!DIRECTORY_INVESTORS_CITY.includes(user.member.residenceCity)) {
      DIRECTORY_INVESTORS_CITY.push(user.member.residenceCity);
    }
  });

  const start = page * cardsPerPage;

  const end = start + cardsPerPage;

  const filteredUsers = users.filter((user: any) => {
    const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
    const residenceCity = user.member.residenceCity.toString().toLowerCase();
    const usersIndustry = user.member.interestIndustries;

    return fullName.includes(SearchText.toLowerCase()) && City.length > 0
      ? City.some(
          (singleCity: any) => singleCity.toLowerCase() === residenceCity,
        )
      : fullName.includes(SearchText.toLowerCase()) && Industry.length > 0
      ? usersIndustry.some((element: any) => Industry.includes(element))
      : fullName.includes(SearchText.toLowerCase());
  });

  const filterResults = filteredUsers;

  const currentCards = filteredUsers.slice(start, end);

  useEffect(() => {
    fetchUserByRole(ERolesCode.MEMBER)
      .then(({ data }) => {
        setUsers(data.payload);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page]);

  return (
    <>
      <PageTitle title="Directorio de Inversionistas" />
      <MainLayout
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Grid container sx={directoryInvestorsStyles}>
          <Grid item xs={12}>
            <FiltersDirectoryInvestors
              cityOptions={DIRECTORY_INVESTORS_CITY}
              industry={Industry}
              searchText={SearchText}
              city={City}
              setCity={setCity}
              setIndustry={setIndustry}
              setSearchText={setSearchText}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className="totalResult">
              {IsFiltering ? (
                <strong>{filterResults.length} </strong>
              ) : (
                <strong>{users.length} </strong>
              )}
              Resultados
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {currentCards.length === 0 || loading ? (
              <Typography className="noResults">
                No hay ningún registro
              </Typography>
            ) : (
              <Grid container spacing={2}>
                {currentCards.map((user: any, index: number) => {
                  return (
                    <Grid item xs={12} sm={6} lg={4} key={index}>
                      <InvestorCard
                        firstName={user.firstName}
                        lastName={user.lastName}
                        text={user.member.aboutMe}
                        city={user.member.residenceCity}
                        industries={user.member.interestIndustries}
                        avatar={user.avatar}
                        id={user.member._id}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
          {currentCards.length > 0 && (
            <Grid item xs={12}>
              <DirectoryPagination
                count={IsFiltering ? filterResults.length : users.length}
                page={page}
                setPage={setPage}
                setCardsPerPage={setCardsPerPage}
                cardsPerPage={cardsPerPage}
              />
            </Grid>
          )}
        </Grid>
      </MainLayout>
    </>
  );
};

export default DirectoryInvestors;
