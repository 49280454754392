/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

import { FormType } from "../schemas";
import { styles } from "../styles";

interface IEvaluation {
  editable: boolean;
  score: number;
  indicator: number;
  hook: UseFormReturn<FormType>;
  refresh: boolean;
}

export const Score: React.FC<IEvaluation> = ({
  score,
  hook,
  indicator,
  editable,
  refresh,
}) => {
  useEffect(() => {
    hook.setValue(`scoreEvaluation.${indicator}.score`, score);
  }, [score, indicator, refresh]);

  const handleScore = (value: string, indicator: number) => {
    hook.setValue(`scoreEvaluation.${indicator}.score`, Number(value));
  };

  const valueScore = hook.watch("scoreEvaluation")[indicator].score;

  return (
    <Grid container gap={2} xs={12}>
      <Grid item xs={12} sm={4} paddingBottom="10px">
        <ToggleButtonGroup
          value={valueScore?.toString()}
          exclusive
          aria-label="text alignment"
          onChange={(event, value: string) => handleScore(value, indicator)}
          sx={{ width: "300px" }}
        >
          <ToggleButton
            value="0"
            aria-label="left aligned"
            sx={styles.evaluationsButtons}
            disabled={!editable}
          >
            0
          </ToggleButton>
          <ToggleButton
            value="1"
            aria-label="left aligned"
            sx={styles.evaluationsButtons}
            disabled={!editable}
          >
            1
          </ToggleButton>
          <ToggleButton
            value="2"
            aria-label="centered"
            sx={styles.evaluationsButtons}
            disabled={!editable}
          >
            2
          </ToggleButton>
          <ToggleButton
            value="3"
            aria-label="right aligned"
            sx={styles.evaluationsButtons}
            disabled={!editable}
          >
            3
          </ToggleButton>
          <ToggleButton
            value="4"
            aria-label="justified"
            sx={styles.evaluationsButtons}
            disabled={!editable}
          >
            4
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};
