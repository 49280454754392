import * as yup from "yup";

import { IFileObject } from "../../../components/CustomInputFile/CustomInputFile";
import {
  CAMPO_REQUERIDO,
  CORREO_NO_VALIDO,
  NUMERO_NO_VALIDO,
} from "../../../utils/text/inputs";
import { LINKEDIN_URL_VALIDA } from "../../../utils/text/resetForm";
import { VALIDATE_LINKEDIN_PROFILE_REGEX } from "../../MemberFaceliftOnboarding/FormRecapcha/schema";

export const profileAdminSchema = yup.object({
  firstName: yup.string().required(CAMPO_REQUERIDO),
  lastName: yup.string().required(CAMPO_REQUERIDO),
  email: yup.string().email(CORREO_NO_VALIDO).required(CAMPO_REQUERIDO),
  phoneCountryCode: yup.string(),
  phoneNumber: yup
    .string()
    .required(CAMPO_REQUERIDO)
    .matches(/^[0-9]{11}|[0-9]{12}|[0-9]{13}$/, NUMERO_NO_VALIDO),
  position: yup.string().required(CAMPO_REQUERIDO),
  role: yup.string().required(CAMPO_REQUERIDO),
  linkedIn: yup
    .string()
    .matches(VALIDATE_LINKEDIN_PROFILE_REGEX, LINKEDIN_URL_VALIDA)
    .required(CAMPO_REQUERIDO),
});

export interface IAdminUser {
  avatar: IFileObject;
  firstName: string;
  lastName: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
  position: string;
  role: string;
}

export type FormType = {
  avatar: IFileObject | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
  position: string;
  role: string;
  linkedIn: string;
};
