import {
  EFileTypes,
  EFileTypesMember,
  EFileTypesMemberAdmin,
} from "../validations";

export function BuildFileTypeText(type: EFileTypes) {
  if (type === EFileTypes.sisa) return "SISA";

  if (type === EFileTypes.dueDiligence) return "Due Dilligence";

  if (type === EFileTypes.safe) return "SAFE";

  if (type === EFileTypes.depositSlip) return "Comprobantes de depósito";

  if (type === EFileTypes.reports) return "Reporte";
}
export function BuildFileTypeTextMember(type: EFileTypesMember) {
  if (type === EFileTypesMember.payments) return "Comprobante de pago";

  if (type === EFileTypesMember.accessionAgreement)
    return "Acuerdo de adhesión";

  if (type === EFileTypesMember.proofOfAddress)
    return "Comprobante de domicilio";

  if (type === EFileTypesMember.officialIdentification)
    return "Identificación oficial";

  if (type === EFileTypesMember.others) return "Otros";
}

export function BuildFileTypeTextAll(
  type: EFileTypesMember | EFileTypes | EFileTypesMemberAdmin,
) {
  if (type === EFileTypes.sisa) return "SISA";

  if (type === EFileTypes.dueDiligence) return "Due Dilligence";

  if (type === EFileTypes.safe) return "SAFE";

  if (type === EFileTypes.depositSlip) return "Comprobantes de depósito";

  if (type === EFileTypes.reports) return "Reporte";
  if (type === EFileTypesMemberAdmin.accountStatements)
    return "Estado de cuenta";

  if (type === EFileTypesMember.payments) return "Comprobante de pago";

  if (type === EFileTypesMember.accessionAgreement)
    return "Convenio de adhesión";

  if (type === EFileTypesMember.proofOfAddress)
    return "Comprobante de domicilio";

  if (type === EFileTypesMember.officialIdentification)
    return "Identificación oficial";

  if (type === EFileTypesMember.others) return "Otros";
}
