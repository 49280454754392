import { Box, CircularProgress, Grid, Paper } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { getMemberMainCard } from "../../../../services/member";

import { EditMode } from "./EditMode/EditMode";
import { ReadMode } from "./ReadMode/ReadMode";
import { styles } from "./styles";
import { FormType, IMainCardProps } from "./types";

const MainCard: FC<IMainCardProps> = ({ id }) => {
  const [enableEditMode, setEnableEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const hook = useFormContext<FormType>();

  function handleDisableEdit() {
    setEnableEditMode(!enableEditMode);
  }

  function handleGetStartup() {
    setLoading(true);
    getMemberMainCard(id)
      .then((resp: any) => {
        const data = resp.data;
        hook.setValue("cv", data.cv);
        hook.setValue("email", data.email);
        hook.setValue("firstName", data.firstName);
        hook.setValue("lastName", data.lastName);
        hook.setValue("linkedInProfile", data.linkedInProfile);
        hook.setValue("nationalityCountry", data.nationalityCountry);
        hook.setValue("phoneCountryCode", data.phoneCountryCode);
        hook.setValue(
          "phoneNumber",
          data.phoneNumber === undefined ? "" : data.phoneNumber.toString(),
        );
        hook.setValue("projectInvesting", data.projectInvesting);
        hook.setValue("residenceCity", data.residenceCity);
        hook.setValue("residenceCountry", data.residenceCountry);
        hook.setValue("avatar", data.avatar);
        hook.setValue("aboutMe", data.aboutMe);
        hook.setValue("hasExperienceInvesting", data.aboutMe);
        hook.setValue("investVentureCapital", data.investVentureCapital);
        hook.setValue("interestIndustries", data.interestIndustries);
        hook.setValue("memberStartedDate", data.memberStartedDate);
        hook.setValue("hasExperienceInvesting", data.hasExperienceInvesting);
        hook.setValue("howFindAngelHub", data.howFindAngelHub);
        hook.setValue("profileIdentify", data.profileIdentify);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    handleGetStartup();
  }, [id, enableEditMode]);

  return (
    <Paper square sx={styles.container}>
      {loading && (
        <Box
          height="571px"
          width={"100%"}
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <CircularProgress />
        </Box>
      )}
      {enableEditMode && !loading && (
        <Grid container spacing={2}>
          <EditMode
            hook={hook}
            id={id}
            changeMode={() => setEnableEditMode(!enableEditMode)}
            cancelMode={() => handleDisableEdit()}
          />
        </Grid>
      )}
      {!enableEditMode && !loading && (
        <ReadMode
          hook={hook}
          changeMode={() => setEnableEditMode(!enableEditMode)}
        />
      )}
    </Paper>
  );
};

export default MainCard;
