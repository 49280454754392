/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import { checkUserSelf } from "../../redux/actions";
import { ILoggedUserReducer } from "../../redux/reducers/LoggedUser";
import { useDispatch, useSelector } from "../../redux/typedHooks";

import AppLoader from "./app-loader";
import FilterRouter from "./filter-routes";
import NoSessionRoutes from "./no-session-routes";

const AppRouter = () => {
  const dispatch = useDispatch();

  const {
    initialAppLoading: loggedUserLoading,
    loginSuccess,
    loggedUser,
  } = useSelector((store) => store.loggedUser as ILoggedUserReducer);

  /**
   * Main app effect. Use this for any async load before the app starts
   */
  useEffect(() => {
    dispatch(checkUserSelf());
  }, []);

  /**
   * While any of this data is still loading, show loading component
   */
  if (loggedUserLoading) {
    return <AppLoader />;
  }
  /**
   * Main Router App. All data above is loaded, load modules or routers dynamically
   */
  if (loginSuccess && loggedUser?.user) {
    return <FilterRouter />;
  }

  return <NoSessionRoutes />;
};

export default AppRouter;
