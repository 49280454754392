export const styles = {
  screen: {
    width: "100vw",
    height: "97.5vh",
    position: "relative",
    marginTop: {
      xs: "2.5rem",
      md: "1rem"
    }
  },
  centeredComponent: {
    paddingLeft: {
      xs: "4.2%",
      md: "19%",
      xl: "29%"
    },
    paddingRight: {
      xs: "4.2%",
      md: "9.5%",
      xl: "14.5%"
    } 
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingRight: {
      xs: "4.2%",
      lg: "20.5%",
      xl: "29%"
    },
    paddingLeft: {
      xs: "4.2%",
      md: "19%",
      xl: "29%"
    },
    marginTop: {
      xs: "4.6rem",
      md: 0
    }
  },
  title: {
    fontWeight: "900",
    fontSize: {
      xs: "24px",
      md: "32px",
      xl: "40px"
    },
    color: "#515151"
  },
  text: {
    fontWeight: "400",
    fontSize: {
      xs: "16px",
      md: "20px",
      xl: "24px"
    },
    color: "#909090"
  },
  calendlyIframe: {
    width: "100%", 
    height: "80%",
  },
  finalScreenPadding: {
    paddingLeft: {
      xs: "1rem",
      md: "6.75rem",
      xl: "21.5rem"
    },
    paddingRight: {
      xs: "1rem",
      md: 0
    },
    marginTop: {
      xs: "0.4rem",
      md: "0.6rem",
      xl: "1.9rem"
    }
  },
  finalTitle: {
    fontWeight: "900",
    fontSize: {
      xs: "24px",
      md: "32px",
      xl: "40px"
    },
    color: "#515151"
  },
  finalSubtitle: {
    fontWeight: "400",
    fontSize: {
      xs: "16px",
      md: "20px",
      xl: "24px"
    },
    color: "#909090"
  },
  finalSectionTitle: {
    fontWeight: "400",
    fontSize: {
      xs: "16px",
      md: "16px",
      xl: "24px"
    },
    color: "#343434",
    marginTop: {
      xs: "24px",
      md: "26px",
      xl: "27px"
    }
  },
  finalSectionText: {
    fontWeight: {
      md: "300",
      xl: "400"
    },
    fontSize: {
      md: "14px",
      xl: "20px"
    },
    color: "#909090"
  }
} as const;
