import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  IconButton,
  SwipeableDrawer,
  Typography,
} from "@mui/material";

interface IProps {
  onClose: () => void;
  onFilter: () => void;
  isOpen: boolean;
  children: any;
}

const DrawerFilter: React.FC<IProps> = ({
  onFilter,
  onClose,
  isOpen,
  children,
}) => {
  return (
    <SwipeableDrawer
      anchor={"right"}
      open={isOpen}
      onClose={() => onClose()}
      onOpen={() => null}
    >
      <Box
        sx={{
          width: "380px",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "10px",
          }}
        >
          <Typography sx={{ fontSize: "20px" }}>Filtros</Typography>
          <IconButton onClick={() => onClose()}>
            <ClearIcon />
          </IconButton>
        </Box>
        {children}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Button variant="outlined" fullWidth onClick={() => onClose()}>
            CERRAR
          </Button>
          <Button variant="contained" fullWidth onClick={() => onFilter()}>
            FILTRAR
          </Button>
        </Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default DrawerFilter;
