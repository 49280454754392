export enum ACTION_TYPES {
  REGISTER = 'REGISTER',
  REGISTER_FAILED = 'REGISTER_FAILED',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  RESET = 'RESET',
}

export const initialState = {
  loading: false,
  error: false,
  registerSuccess: false,
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    /* REGISTER a new user */
    case ACTION_TYPES.REGISTER:
      return {
        ...state,
        loading: true,
        error: false,
        registerSuccess: false,
      };

    case ACTION_TYPES.REGISTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
        registerSuccess: false,
      };

    case ACTION_TYPES.REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        registerSuccess: true,
      };

    case ACTION_TYPES.RESET:
      return {
        ...state,
        registerSuccess: false,
      };

    default:
      return state;
  }
}
