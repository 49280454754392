import {
  ArrowForward,
  FilterAlt,
  KeyboardArrowDown,
  Search,
} from "@mui/icons-material";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";

import DrawerFilter from "../../components/DrawerFilter/DrawerFilter";
import { TextFieldAutoComplete } from "../../components/Inputs/TextInput/styled";
import styles from "../../pages/ResourceLibrary/FiltersResourceLibrary/styles";

import { DIRECTORY_INVESTORS_INDUSTRY_OPTIONS } from "./options";

interface IFiltersDirectoryInvestorsProps {
  cityOptions: string[];
  city: string[];
  industry: string[];
  searchText: string;

  setCity: (value: string[]) => void;
  setIndustry: (value: string[]) => void;
  setSearchText: (value: string) => void;
}

const FiltersDirectoryInvestors: React.FC<IFiltersDirectoryInvestorsProps> = (
  props,
) => {
  const { industry, setIndustry } = props;
  const { city, setCity } = props;
  const { searchText, setSearchText } = props;
  const { cityOptions } = props;

  const mobile = useMediaQuery("(max-width: 1200px)");

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const handleFilter = () => {
    setOpenDrawer(false);
  };

  const badgeNumber = (): number => {
    let number = 0;
    if (city.length !== 0) number++;
    if (industry.length !== 0) number++;
    return number;
  };

  const handleSearchKeyChange = (event: any) => {
    if (event.key === "Enter") {
      handleFilter();
    }
  };

  const handleChangeCity = (_: any, newValue: any) => {
    setCity(newValue);
  };
  const handleChangeIndustry = (_: any, newValue: any) => {
    setIndustry(newValue);
  };

  const filters = (
    <>
      <Autocomplete
        options={cityOptions.sort((a, b) => {
          a = a.toLowerCase();
          b = b.toLowerCase();
          if (a === b) return 0;
          return a < b ? -1 : 1;
        })}
        onChange={handleChangeCity}
        multiple
        sx={{ minWidth: 200 }}
        renderInput={(rest) => (
          <TextFieldAutoComplete {...rest} label="Ciudad" />
        )}
      />
      <Autocomplete
        multiple
        options={DIRECTORY_INVESTORS_INDUSTRY_OPTIONS}
        onChange={handleChangeIndustry}
        sx={{ minWidth: 200 }}
        renderInput={(rest) => (
          <TextFieldAutoComplete {...rest} label="Industria de interés" />
        )}
      />
    </>
  );
  return (
    <>
      <DrawerFilter
        isOpen={openDrawer}
        onFilter={handleFilter}
        onClose={handleFilter}
      >
        {filters}
      </DrawerFilter>
      <Box
        sx={{
          ...styles.iconsWrapper,
          flexDirection: mobile ? "row-reverse" : "row",
        }}
      >
        {mobile ? (
          <IconButton onClick={() => setOpenDrawer(true)}>
            <Badge badgeContent={badgeNumber()} color="primary">
              <Button endIcon={<KeyboardArrowDown />} variant="contained">
                <FilterAlt />
              </Button>
            </Badge>
          </IconButton>
        ) : (
          <Stack direction={"row"} alignItems="center" gap="12px">
            {filters}
          </Stack>
        )}
        <TextFieldAutoComplete
          value={searchText}
          sx={{ maxWidth: "540px" }}
          onChange={(event) => setSearchText(event.currentTarget.value)}
          label="Buscar"
          placeholder="Ingresa un nombre para buscar..."
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: "#8BB3D8" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearchKeyChange}>
                  <ArrowForward sx={{ color: "#8BB3D8" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default FiltersDirectoryInvestors;
