export interface IPartnerItem {
  _id: string;
  curp: string;
  userId: {
    email: string;
    name: string;
    deleted: string;
    deletedBool: boolean;
  };
  bankId: {
    name: string;
  };
}

export interface IEmployeeItem {
  _id: string;
  userId: {
    name: string;
    deleted: string;
    deletedBool: boolean;
  };
  vehicle: string;
}

export interface IPayrollEmployeeItem {
  _id: string;
  userId: {
    name: string;
    deleted: string;
    deletedBool: boolean;
  };
  jobTitle: {
    name: string;
  };
}

export const TABS_PAYROLL = [
  {
    title: "Empleados",
  },
  { title: "Socios" },
];

export const DEFAULT_PARTNER_SORTING = {
  "userId.name": 1,
};

export enum ETypeInterview {
  INTERVIEW_1 = "INTERVIEW_1",
  INTERVIEW_2 = "INTERVIEW_2",
}
export enum EStatusAppointment {
  CREATED = "CREATED",
  ACCEPTED = "ACCEPTED",
  CANCELED = "CANCELED",
}

export enum EStatusApplication {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  ASSIGNED = "ASSIGNED",
  RE_ASSIGNED = "RE_ASSIGNED",
}

export const FILTER_OPTIONS_APPLICATION_STATUS = [
  {
    name: "Asignado",
    value: EStatusApplication.ASSIGNED,
  },
  /* {
    name: "Aceptado",
    value: EStatusApplication.ACCEPTED,
  }, */
  {
    name: "Rechazado",
    value: EStatusApplication.REJECTED,
  },
];

export const PAGINATION_ROWS = [5, 10, 20];
