import { CalendarToday, Face, FactCheck } from "@mui/icons-material";
import BallotIcon from "@mui/icons-material/Ballot";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import SplitButton from "../../../../../../../components/SplitButton/SplitButton";
import { ETabsMember } from "../../../../../../../constants";
import { useSelector } from "../../../../../../../redux/typedHooks";
import { displayToast } from "../../../../../../../utils/helpers/displayToast";
import { translateMemberShipType } from "../../../../../../../utils/text/translates";
import { ERolesCode } from "../../../../../../routes/role-catalog";
import { styles as mainStyles } from "../../../styles";
import DialogPayment from "../DialogPayment/DialogPayment";

interface IFinancialInfoReadMode {
  hook?: any;
  changeMode: () => void;
  onReload: () => void;
}

const VALID_ROLES_TO_EDIT = [
  ERolesCode.SUPER_ADMIN,
  ERolesCode.ADMINISTRATOR,
  ERolesCode.PARTNER,
  ERolesCode.ANALYST,
];

const VALID_MEMBERSHIP_TYPE_TO_RENEW = [
  ETabsMember.AMBASSADOR,
  ETabsMember.ALLIES,
  ETabsMember.LEADANGEL,
];

export const ReadMode: FC<IFinancialInfoReadMode> = ({
  changeMode,
  hook,
  onReload,
}) => {
  const { id } = useParams() as { id: string };

  const breakpoint = useMediaQuery("(max-width: 600px)");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  const isValidMembershipTypeToRenew = VALID_MEMBERSHIP_TYPE_TO_RENEW.includes(
    user?.member?.membershipType,
  );

  const isValidRoleToEdit = VALID_ROLES_TO_EDIT.includes(user.role.code);
  const isActive = hook.watch("isActive");

  useEffect(() => {
    if (isValidMembershipTypeToRenew && !isActive) {
      displayToast(
        "warning",
        `Hola, ${user.firstName}. Por favor, ponte en contacto con nuestro equipo de soporte para renovar tu suscripción.`,
      );
    }
  }, [isValidMembershipTypeToRenew, isActive, user?.firstName]);

  /* 
  async function extendMembership() {
    handleOpen();
    try {
      await updateMembershipById(id, {
        date: moment(hook.getValues("membershipEndDate"))
          .add(1, "year")
          .toDate(),
        isActive: true,
      });
      displayToast("success", "Se actualizó correctamente");
    } catch (error) {
      displayToast("error", "Hubo un error al actualizar la membresía.");
    } finally {
      handleClose();
      onReload();
    }
  }

  async function deactivateMembership() {
    handleOpen();
    try {
      await updateMembershipById(id, {
        date: moment().subtract(1, "day").toDate(),
        isActive: false,
      });
      displayToast("success", "Se actualizó correctamente");
    } catch (error) {
      displayToast("error", "Hubo un error al actualizar la membresía.");
    } finally {
      handleClose();
      onReload();
    }
  }

  async function handleActivateMembership() {
    handleOpen();
    try {
      await updateMembershipById(id, {
        date: moment().add(1, "year").toDate(),
        isActive: true,
      });
      displayToast("success", "Se actualizó correctamente");
    } catch (error) {
      displayToast("error", "Hubo un error al actualizar la membresía.");
    } finally {
      handleClose();
      onReload();
    }
  } */

  return (
    <>
      <DialogPayment
        open={openModal}
        memberId={id}
        onReload={onReload}
        onCancel={() => setOpenModal(false)}
      />
      <Grid xs={12} sx={{ ...mainStyles.buttonsContainer }}>
        {!isValidMembershipTypeToRenew && (
          <SplitButton
            title="Acciones"
            icon={<BallotIcon />}
            isMobile={breakpoint}
            options={[
              {
                label: "Editar",
                visible: isValidRoleToEdit,
                onClick: changeMode,
              },
              {
                label: "Pagar Membresía",
                visible: !isValidRoleToEdit,
                onClick: () => setOpenModal(true),
              },
              /* {
              label: "Extender",
              visible: isValidRoleToEdit && isActive,
              onClick: extendMembership,
            },
            {
              label: "Desactivar",
              visible: isValidRoleToEdit && isActive,
              onClick: deactivateMembership,
            },
            {
              label: "Activar",
              visible: isValidRoleToEdit && !isActive,
              onClick: handleActivateMembership,
            }, */
            ]}
          />
        )}
      </Grid>

      <Grid item xs={12} sm={6} md={4} sx={{ ...mainStyles.Info }}>
        <FactCheck sx={{ ...mainStyles.Icons }} />
        <Box>
          <Typography sx={{ ...mainStyles.title }}>
            Estatus de la membresía:
          </Typography>
          <Typography sx={{ ...mainStyles.amounts }}>
            <strong>{isActive ? "Activa" : "Inactiva"}</strong>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4} sx={{ ...mainStyles.Info }}>
        <Face sx={{ ...mainStyles.Icons }} />
        <Box>
          <Typography sx={{ ...mainStyles.title }}>
            Tipo de membresía:
          </Typography>
          <Typography sx={{ ...mainStyles.amounts }}>
            <strong>
              {translateMemberShipType(hook.getValues("membershipType"))}
            </strong>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4} sx={{ ...mainStyles.Info }}>
        <CalendarToday sx={{ ...mainStyles.Icons }} />
        <Box>
          <Typography sx={{ ...mainStyles.title }}>
            Fecha de expiración:
          </Typography>
          <Typography sx={{ ...mainStyles.amounts }}>
            <strong>
              {moment(hook.getValues("membershipEndDate")).format("YYYY-MM-DD")}
            </strong>
          </Typography>
        </Box>
      </Grid>
    </>
  );
};
