import {
  Drawer,
  Box,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import React, { ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import logoRectangle from "../../../assets/images/logo-color_negro.png";
import { EStatusReleasedOpportunity } from "../../../constants";
import { ERolesCode } from "../../../pages/routes/role-catalog";
import { ILoggedUserReducer } from "../../../redux/reducers/LoggedUser";
import { useSelector } from "../../../redux/typedHooks";
import { IStartup } from "../../DetailsStartup/Forms/schema";

import CustomMenuItem from "./CustomMenuItem/CustomMenuItem";
import DrawerHeader from "./DrawerHeader";
import styles from "./styles";

const useStyles = makeStyles(() => {
  return {
    drawer: {
      flexShrink: 0,
      height: "100vh",
      whiteSpace: "nowrap",
      backgroundColor: "#fff",
      position: "static",
      border: "none",
      overflow: "hidden",
    },
    closedDrawer: {
      overflowX: "hidden",
      width: 0,
      transition: "width .7s",
    },
    openedDrawer: {
      width: 256,
      transition: "width .7s",
    },
  };
});

const MyDrawer = styled(Drawer)((props) => ({
  width: props.open ? "256px" : 0,
}));

export interface IOption {
  displayName: string;
  iconComponent?: ReactElement;
  route?: string;
  children?: IOption[];
}

interface IDrawer {
  isOpened: boolean;
  handleDrawer: () => void;
  items: IOption[];
  adminToolsItems: IOption[];
  bottomItems: IOption[];
  setIsOpened: (value: boolean) => void;
}

const DrawerComponent: React.FC<IDrawer> = ({
  isOpened,
  handleDrawer,
  items,
  adminToolsItems,
  bottomItems,
  setIsOpened,
}) => {
  const classes = useStyles();
  const pathname = useLocation().pathname;
  const theme = useTheme();
  const drawerVariant = useMediaQuery(theme.breakpoints.down("sm"));

  const { loggedUser } = useSelector(
    (store) => store.loggedUser as ILoggedUserReducer,
  );

  const [itemsMenu, setItemsMenu] = useState(items);

  useEffect(() => {
    if (loggedUser.user.role?.code === ERolesCode.STARTUP) {
      const startup: IStartup = loggedUser.user?.startup as unknown as IStartup;
      if (startup.status !== EStatusReleasedOpportunity.INVESTED) {
        const index = items.findIndex(
          (object) => object.route === "/oportunidades-aceptadas",
        );
        items.splice(index, index);

        const filteredDirectory = itemsMenu.filter(
          (item) => item.displayName !== "Directorio",
        );
        const indexDirectory = itemsMenu.findIndex(
          (item) => item.displayName === "Directorio",
        );

        const directory = itemsMenu[indexDirectory];

        const filteredChildren = directory.children?.filter(
          (item) => item.displayName !== "Startups",
        );

        const fixedDirectory = {
          ...directory,
          children: filteredChildren,
        };

        setItemsMenu([
          filteredDirectory[0],
          fixedDirectory,
          filteredDirectory[1],
        ]);
      }
    }
  }, [loggedUser.user.role?.code]);

  return (
    <Box sx={styles.drawerMain}>
      <Box sx={styles.drawerWrapper}>
        <MyDrawer
          variant={drawerVariant ? "temporary" : "permanent"}
          open={isOpened}
          onClose={handleDrawer}
          classes={{
            paper: `${classes.drawer} ${
              isOpened ? classes.openedDrawer : classes.closedDrawer
            }`,
          }}
        >
          {drawerVariant ? (
            <Box sx={styles.drawerHeader}>
              <Box
                component="img"
                src={logoRectangle}
                alt="logo"
                sx={styles.toolbarHeaderLogo}
              />
            </Box>
          ) : (
            <Box sx={styles.toolbar} />
          )}
          <DrawerHeader isOpened={drawerVariant || isOpened} />
          <Box sx={{ ...styles.menuItemsContainer, overflow: "scroll" }}>
            <Box sx={styles.topMenuItems}>
              {itemsMenu.map((option, index) => (
                <CustomMenuItem
                  setIsOpened={setIsOpened}
                  key={index}
                  item={option}
                  isSelected={pathname === option.route}
                  isDrawerOpened={isOpened}
                />
              ))}
              {adminToolsItems.length > 0 && (
                <Box sx={styles.adminToolsItems}>
                  <Typography
                    style={{
                      padding: ".2rem 1.2rem",
                      color: "gray",
                      fontWeight: "500",
                    }}
                  >
                    ADMIN TOOLS
                  </Typography>
                  {adminToolsItems.map((option, index) => (
                    <CustomMenuItem
                      setIsOpened={setIsOpened}
                      key={index}
                      item={option}
                      isSelected={pathname === option.route}
                      isDrawerOpened={isOpened}
                    />
                  ))}
                </Box>
              )}
            </Box>
            <Box sx={styles.bottomMenuItems}>
              {bottomItems.map((bottomOption, index) => (
                <CustomMenuItem
                  setIsOpened={setIsOpened}
                  isBottom
                  key={index}
                  item={bottomOption}
                  isSelected={pathname === bottomOption.route}
                  isDrawerOpened={isOpened}
                />
              ))}
            </Box>
          </Box>
        </MyDrawer>
      </Box>
      <Box sx={isOpened ? styles.openedDrawer : styles.closedDrawer} />
    </Box>
  );
};

export default DrawerComponent;
