import { IFieldSchema } from "../Form";

export const loginWithCodeFieldSchema: IFieldSchema[] = [
  {
    name: "code",
    validations: {
      maxLength: {
        max: 6,
        errorMessage: "El código no puede tener más de 6 números",
      },
      minLength: {
        min: 6,
        errorMessage: "El código debe tener al menos 6 números"
      },
      required: {
        errorMessage: "Ingresa tu código",
      },
    },
    value: "",
  }
];