import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import mainStyles from "../../../../styles";
import { styles } from "../../styles";

const ItemReadProperty = (props: {
  Icon: any;
  text: string;
  title: string;
}) => {
  const { Icon, text, title } = props;
  return (
    <Box sx={{ display: "flex", paddingBottom: "14px" }}>
      <Icon sx={{ ...mainStyles.icon }} />
      <Typography sx={{ ...styles.specialText }}>
        {title}
        <Typography sx={styles.label}>{text}</Typography>
      </Typography>
    </Box>
  );
};

export default ItemReadProperty;
