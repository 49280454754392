export const OPTIONS_PROFILE_IDENTIFY = [
  {
    label: "Me gusta analizar y decidir en qué startup invertir",
    value: "Me gusta analizar y decidir en qué startup invertir",
  },
  {
    label: "No tengo tiempo, prefiero me recomienden en cuál startup invertir",
    value: "No tengo tiempo, prefiero me recomienden en cuál startup invertir",
  },
];
