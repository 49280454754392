import { Info } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Divider,
  FormHelperText,
  Grid,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  Box,
} from "@mui/material";
import { FC, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import PhoneInput, { CountryData } from "react-phone-input-2";

import { TextInput } from "../../../../../components/Inputs";
import InputFile from "../../../../../components/Inputs/InputFile/InputFile";
import { TextFieldAutoComplete } from "../../../../../components/Inputs/TextInput/styled";
import { demonyms } from "../../../../../components/MemberForm/FormComponents/demonyms";
import { useLoading } from "../../../../../hooks/useLoading";
import { reloadUser } from "../../../../../redux/actions/LoggedUser";
import {
  openModal,
  PayloadOpenModal,
} from "../../../../../redux/actions/modal";
import { useDispatch, useSelector } from "../../../../../redux/typedHooks";
import { updateMemberMainCardById } from "../../../../../services/member";
import countries from "../../../../../utils/countries.json";
import { OPTIONS_PROFILE_IDENTIFY } from "../../../../MemberFaceliftOnboarding/FormRecapcha/options";
import {
  COMO_TE_ENTERASTE_OPTIONS,
  INDUSTRY_OPTIONS,
} from "../../../../StartupRegister/constants";
import { ERolesCode } from "../../../../routes/role-catalog";
import { styles } from "../styles";
import { FormType } from "../types";

import { YES_NO_OPTIONS } from "./constants";
import editModeStyles from "./styles";

interface IMainCardEditMode {
  hook: UseFormReturn<FormType, any>;
  id: string;
  changeMode: () => void;
  cancelMode: () => void;
}

export const EditMode: FC<IMainCardEditMode> = ({
  changeMode,
  cancelMode,
  hook,
  id,
}) => {
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  const { handleClose, handleOpen } = useLoading();
  const [countryCode, setCountryCode] = useState<string>(
    hook.getValues("phoneCountryCode"),
  );
  const [completeNumber, setCompleteNumber] = useState<string>(
    hook.getValues("phoneCountryCode") + hook.getValues("phoneNumber"),
  );

  const tabsValidForDetailsMember = [
    ERolesCode.SUPER_ADMIN,
    ERolesCode.ADMINISTRATOR,
    ERolesCode.ANALYST,
    ERolesCode.PARTNER,
  ].includes(user.role.code);

  const dispatch = useDispatch();

  const breakpoint = useMediaQuery("(max-width: 960px)");

  async function onSubmit(data: any) {
    const copyData = {
      ...data,
      projectInvesting: data.projectInvesting.toString(),
      phoneCountryCode: countryCode || "",
    };
    copyData.phoneNumber = data.phoneNumber.replace(data.phoneCountryCode, "");

    const memberFormData = new FormData();
    data.avatar && memberFormData.append("avatar", data.avatar);
    data.cv && memberFormData.append("cv", data.cv);

    try {
      handleOpen();
      await updateMemberMainCardById(id, copyData);
      await updateMemberMainCardById(id, memberFormData);
      changeMode();
      handleSuccessModal();
      dispatch(reloadUser());
    } catch (error) {
      handleErrorModal();
    } finally {
      handleClose();
    }
  }

  function handleSuccessModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Correcto",
      description: `Perfil actualizado satisfactoriamente`,
      action: () => changeMode(),
      hideSecondaryButton: true,
      primaryButtonText: "Aceptar",
    };
    dispatch(openModal(modalConfig));
  }
  function handleErrorModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Error",
      description: `Hubo un error al actualizar perfil`,
      hideSecondaryButton: true,
      primaryButtonText: "Aceptar",
    };
    dispatch(openModal(modalConfig));
  }

  return (
    <>
      <Grid item xs={12}>
        <Stack
          direction={breakpoint ? "column" : "row"}
          justifyContent="space-between"
          marginBottom="18px"
          spacing={2}
        >
          <Stack></Stack>
          <Box
            display={"flex"}
            gap={"8px"}
            sx={{ flexDirection: breakpoint ? "column" : "row" }}
          >
            <Button
              variant="outlined"
              sx={{ width: "auto" }}
              onClick={cancelMode}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              sx={{ ...styles.actionsButtons, width: "auto" }}
              onClick={hook.handleSubmit(onSubmit)}
            >
              Guardar
            </Button>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Grid container xs={12} rowGap={2}>
          <Grid
            container
            xs={12}
            md={6}
            xl={4}
            spacing={2}
            rowGap={breakpoint ? 2 : 0}
          >
            <Grid item xs={12} md={6}>
              <Controller
                control={hook.control}
                name="avatar"
                render={({ field, fieldState }) => (
                  <InputFile
                    ref={field.ref}
                    value={field.value}
                    label="Subir foto"
                    onChange={field.onChange}
                    errorMessage={fieldState.error?.message}
                    accept={["image/gif", "image/gif", "image/jpeg"]}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={6} sx={editModeStyles.endGridObject}>
              <Controller
                control={hook.control}
                name="firstName"
                render={({ field, fieldState }) => (
                  <TextInput
                    label="Nombres"
                    required
                    {...field}
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={hook.control}
                name="lastName"
                render={({ field, fieldState }) => (
                  <TextInput
                    label="Apellidos"
                    required
                    {...field}
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid xs={12} md={6} rowGap={2} sx={editModeStyles.endGridObject}>
            <Grid item xs={12} md={6}>
              <Controller
                control={hook.control}
                name="aboutMe"
                render={({ field, fieldState }) => (
                  <TextInput
                    multiline
                    minRows={5}
                    label="Acerca de mi"
                    required
                    {...field}
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={
                      fieldState.error?.message ? (
                        fieldState.error?.message
                      ) : (
                        <Stack direction="row" alignItems="center">
                          <Info fontSize="inherit" />
                          <Typography marginLeft="0.3rem">
                            Puedes escribir hasta 450 caracteres
                          </Typography>
                        </Stack>
                      )
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography sx={{ ...styles.subtitle, marginY: "1rem" }}>
          Contacto
        </Typography>
        <Grid container xs={12}>
          <Grid container xs={12} spacing={2} rowGap={breakpoint ? 2 : 0}>
            <Grid item xs={12} md={3}>
              <Controller
                control={hook.control}
                name="email"
                render={({ field, fieldState }) => (
                  <TextInput
                    label="Correo"
                    required
                    {...field}
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              xl={2}
              sx={editModeStyles.middleGridObject}
            >
              <Controller
                control={hook.control}
                name="phoneNumber"
                render={({ field, fieldState }) => (
                  <>
                    <PhoneInput
                      country="mx"
                      value={completeNumber}
                      onChange={(phone: string, data: CountryData) => {
                        hook.setValue("phoneCountryCode", data.dialCode);
                        setCountryCode(data.dialCode);
                        field.onChange(phone);
                      }}
                      buttonStyle={
                        fieldState.error && { border: "2px solid #f44336" }
                      }
                      inputStyle={
                        fieldState.error
                          ? {
                              border: "2px solid #f44336",
                              height: "48px",
                              width: "100%",
                            }
                          : { height: "48px", width: "100%" }
                      }
                    />
                    {Boolean(fieldState.error) && (
                      <FormHelperText
                        style={{ margin: "3px 14px 0px 14px" }}
                        error={Boolean(fieldState.error?.message)}
                      >
                        {fieldState.error?.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ ...styles.divider, marginTop: "1rem" }} />
        <Typography sx={{ ...styles.subtitle, marginY: "1rem" }}>
          Ubicación
        </Typography>
        <Grid container xs={12}>
          <Grid container xs={12} spacing={2} rowGap={breakpoint ? 2 : 0}>
            <Grid item xs={12} md={3}>
              <Controller
                control={hook.control}
                name="nationalityCountry"
                render={({ field, fieldState }) => (
                  <Autocomplete
                    value={field.value}
                    options={demonyms.map((i) => i.value)}
                    onChange={(_, value) => field.onChange(value)}
                    getOptionLabel={(option) =>
                      demonyms.find((i) => i.value === option)?.label || ""
                    }
                    renderInput={({
                      InputLabelProps,
                      InputProps,
                      inputProps,
                      ...rest
                    }) => (
                      <TextInput
                        {...rest}
                        error={Boolean(fieldState.error)}
                        helperText={
                          Boolean(fieldState.error?.message) &&
                          fieldState.error?.message
                        }
                        InputProps={{
                          ...InputProps,
                          style: { height: 48, paddingTop: "6px" },
                        }}
                        inputProps={inputProps}
                        InputLabelProps={{
                          ...InputLabelProps,
                          style: { top: "-3.5px" },
                          classes: {
                            shrink:
                              "translate(14px, -3px) scale(0.75) !important",
                          },
                        }}
                        label="Nacionalidad"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3} xl={3}>
              <Controller
                control={hook.control}
                name="residenceCountry"
                render={({ field, fieldState }) => (
                  <Autocomplete
                    value={field.value}
                    onChange={(_, value) => field.onChange(value)}
                    options={Array.from(
                      new Set(countries.map((i) => i.name_es)),
                    )}
                    renderInput={({
                      InputLabelProps,
                      InputProps,
                      inputProps,
                      ...rest
                    }) => (
                      <TextInput
                        {...rest}
                        error={Boolean(fieldState.error)}
                        helperText={
                          Boolean(fieldState.error?.message) &&
                          fieldState.error?.message
                        }
                        InputProps={{
                          ...InputProps,
                          style: { height: 48, paddingTop: "6px" },
                        }}
                        inputProps={inputProps}
                        InputLabelProps={{
                          ...InputLabelProps,
                          style: { top: "-3.5px" },
                          classes: {
                            shrink:
                              "translate(14px, -3px) scale(0.75) !important",
                          },
                        }}
                        label="País de Residencia"
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3} xl={3}>
              <Controller
                control={hook.control}
                name="residenceCity"
                render={({ field, fieldState }) => (
                  <TextInput
                    label="Ciudad de Residencia"
                    required
                    {...field}
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ ...styles.divider, marginTop: "1rem" }} />
        <Typography sx={{ ...styles.subtitle, marginY: "1rem" }}>
          Información Profesional
        </Typography>
        <Grid container xs={12}>
          <Grid container xs={12} spacing={2} rowGap={breakpoint ? 2 : 0}>
            <Grid item xs={12} md={3}>
              <Controller
                control={hook.control}
                name="linkedInProfile"
                render={({ field, fieldState }) => (
                  <TextInput
                    label="Liga a tu Perfil de LinkedIn"
                    required
                    {...field}
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={hook.control}
                name="cv"
                render={({ field, fieldState }) => (
                  <InputFile
                    ref={field.ref}
                    value={field.value}
                    label="Subir CV"
                    onChange={field.onChange}
                    errorMessage={fieldState.error?.message}
                    accept={[".pdf"]}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ ...styles.divider, marginTop: "1rem" }} />
        <Typography sx={{ ...styles.subtitle, marginY: "1rem" }}>
          Información Inversionista
        </Typography>
        <Grid container xs={12}>
          <Grid container xs={12} spacing={2} rowGap={breakpoint ? 2 : 0}>
            {tabsValidForDetailsMember && (
              <Grid item xs={12} md={3}>
                <Controller
                  control={hook.control}
                  name="howFindAngelHub"
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      label="¿Cómo llegó a AngelHub?"
                      required
                      select
                      error={Boolean(fieldState.error)}
                      helperText={
                        Boolean(fieldState.error?.message) &&
                        fieldState.error?.message
                      }
                      fullWidth
                    >
                      {COMO_TE_ENTERASTE_OPTIONS.map((i, index) => (
                        <MenuItem value={i} key={index}>
                          {i}
                        </MenuItem>
                      ))}
                    </TextInput>
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <Controller
                control={hook.control}
                name="hasExperienceInvesting"
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    label="¿Has realizado inversiones ángeles?"
                    required
                    select
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                    fullWidth
                  >
                    {YES_NO_OPTIONS.map((i, index) => (
                      <MenuItem value={i.value as any} key={index}>
                        {i.displayName}
                      </MenuItem>
                    ))}
                  </TextInput>
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={hook.control}
                name="investVentureCapital"
                render={({ field, fieldState }) => (
                  <TextInput
                    label="¿Has invertido en fondos de Venture Capital?"
                    required
                    {...field}
                    select
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                    fullWidth
                  >
                    {YES_NO_OPTIONS.map((i, index) => (
                      <MenuItem value={i.value as any} key={index}>
                        {i.displayName}
                      </MenuItem>
                    ))}
                  </TextInput>
                )}
              />
            </Grid>
            {tabsValidForDetailsMember && (
              <Grid item xs={12} md={9}>
                <Controller
                  control={hook.control}
                  name="profileIdentify"
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      required
                      select
                      label="¿Con qué perfil de inversionista me identifico?"
                      error={Boolean(fieldState.error)}
                      helperText={
                        Boolean(fieldState.error?.message) &&
                        fieldState.error?.message
                      }
                      fullWidth
                    >
                      {OPTIONS_PROFILE_IDENTIFY.map((option, index) => (
                        <MenuItem
                          key={index}
                          value={option.value as unknown as string}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextInput>
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Divider sx={{ ...styles.divider, marginTop: "1rem" }} />
        <Typography sx={{ ...styles.subtitle, marginY: "1rem" }}>
          Industrias de Interés
        </Typography>
        <Box>
          <Controller
            control={hook.control}
            name="interestIndustries"
            render={({ field, fieldState }) => (
              <Autocomplete
                multiple
                value={field.value}
                onChange={(_, value) => field.onChange(value)}
                disablePortal
                options={INDUSTRY_OPTIONS}
                renderInput={(params) => (
                  <TextFieldAutoComplete
                    {...params}
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                    label="Industrias de interés"
                  />
                )}
              />
            )}
          />
        </Box>
      </Grid>
    </>
  );
};
