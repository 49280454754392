import { SxProps } from "@mui/material";

export interface StylesObject {
  [key: string]: SxProps;
}

export const styles: StylesObject = {
  linkedinIcon: {
    color: "#4A88C2",
    marginRight: "8px",
  },
  linkedinLink: {
    color: "#4A88C2",
    textDecoration: "none",
    fontSize: "12px",
    fontWeight: "900",
    fontFamily: "Product Sans Black !important",
  },
  starIconBox: {
    borderRadius: "50%",
    width: "24px",
    height: "24px",
    backgroundColor: "#C0DDE7",
    marginRight: "10px",
  },
  container: {
    width: "100%",
    flexWrap: "wrap",
    padding: "40px 24px 40px 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatar: {
    borderRadius: "100px",
    maxHeight: "150px",
    maxWidth: "150px",
    width: "10rem",
    height: "10rem",
    backgroundColor: "green",
    "&:hover": {
      /* filter: props.disable ? "" : "brightness(70%)",
cursor: props.disable ? "" : "pointer", */
    },
    backgroundImage: `url(${"dsa"})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  textStatus: {
    fontFamily: "Product Sans Black",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#515151",
    marginTop: "8px",
  },
  actionsButtons: {
    backgroundColor: "#74C24A",
    width: "162px",
    color: "white",
  },
  actionsMobileButtons: {
    backgroundColor: "#74C24A",
    width: "96px",
    color: "white",
  },
  textOpenInversion: {
    fontFamily: "Product Sans Black",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#F7FCF9",
    marginTop: "12px",
    background: "#35AC65",
    borderRadius: "64px",
    padding: "3px 6px",
  },

  //Info

  textCompany: {
    fontFamily: "Product Sans Black",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#343434",
  },
  text: {
    fontSize: "16px",
  },
  oneliner: {
    fontSize: "16px",
    fontWeight: "300",
  },
  subtitle: {
    marginTop: "10px",
    marginBottom: "8px",
    fontFamily: "Product Sans !important",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1A1A1A",
  },
  divider: {
    borderBottomWidth: "2px",
    marginBottom: "8px",
  },
  industryChip: {
    marginX: "24px",
    backgroundColor: "#C0DDE7",
    border: "solid 1px #4AA5C2",
  },
  womanChip: {
    backgroundColor: "#fde0ff",
    border: "solid 1px #4AA5C2",
  },
  chipText: {
    color: "#397386",
    fontSize: "12px",
    fontWeight: "400",
  },
  timerColor: {
    color: "#9B2231",
  },
  timerText: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#5A1E26",
  },
  timerTimeBox: {
    border: "1px solid #EB7281",
    borderRadius: "5px",
    padding: 0.5,
  },
  timerInnerTimeBox: {
    borderRadius: "5px",
    backgroundColor: "#EB7281",
    padding: 0.2,
  },
  timerTimeText: {
    fontSize: "10px",
    fontWeight: "900",
    color: "#9B2231",
  },
  specialText: {
    display: "inline",
    color: "#515151",
    fontSize: "16px",
    fontWeight: "300 !important",
  },
  label: {
    display: "inline",
    marginLeft: "8px",
    fontFamily: "Product Sans Light !important",
    fontWeight: "300 !important",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#515151",
  },
};
