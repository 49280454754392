import { Box, Divider, Link, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
/* import { useHistory } from "react-router-dom"; */

import HorizontalDivider from "../../../components/Dashboard/HorizontalDivider";

import NoticeCard from "./NoticeCard/NoticeCard";
import { INoticesData } from "./Notices";
import { NoticesItemStyles } from "./style";

interface INoticesItemProps {
  notice: INoticesData;
  hideDivider?: boolean;
}

const NoticesItem: React.FC<INoticesItemProps> = ({ notice, hideDivider }) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  return (
    <>
      <NoticeCard
        isOpen={isOpenModal}
        onChange={setIsOpenModal}
        data={notice}
      />
      <Box sx={NoticesItemStyles}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            sx={{ pb: 1.5 }}
            className="no-wrap-text"
          >
            <Divider orientation="vertical" flexItem />
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
              className="no-wrap-text"
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Typography variant="subtitle1" className="user-name">
                  {notice.Autor}
                </Typography>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Typography variant="subtitle2" className="notice-date">
                  {dayjs(notice.publishedAt).format("DD/MM/YYYY")}
                </Typography>
              </Stack>
              <Typography variant="subtitle1" className="notice-title">
                {notice.Titulo}
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Typography variant="subtitle2" className="notice-description">
                  {notice.Descripcion}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Link
            onClick={() => setIsOpenModal(true)}
            component={"button"}
            underline="none"
            sx={{ paddingBottom: "14px !important" }}
          >
            Ver
          </Link>
        </Stack>
        {!hideDivider && <HorizontalDivider />}
      </Box>
    </>
  );
};

export default NoticesItem;
