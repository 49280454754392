export const styles = {
  mainContainer: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(19, 13, 16, 0.8)",
    position: "absolute",
    top: "0px",
    right: "0px",
    zIndex: "1102",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  subContainer: {
    padding: "40px 56px",
    width: "728px",
    maxHeight: "688px",
    minHeight: "200px",
    background: "#FFFFFF",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
  },
  mainBox: {
    width: "100%",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "18px",
  },
  Info: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
    flexWrap: "no-wrap",
    overflowWrap: "anywhere",
    span: {
      display: "inline-block",
      color: "#528495",
      fontSize: "18px",
      fontWeight: "500",
    },
  },
  title: {
    fontWeight: "400",
    lineHeight: "28px",
    fontSize: "20px",
    "@media (max-width: 600px)": {
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "28px",
    },
    span: {
      display: "inline-block",
      color: "#528495",
      fontSize: "24px",
      fontWeight: "500",
    },
    strong: {
      fontSize: "28px",
      fontWeight: "500",
    },
  },
};
