import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import { EStatusReleasedOpportunity } from "../../constants";
import { useSelector } from "../../redux/typedHooks";
import { ERolesCode } from "../routes/role-catalog";

import MainCard from "./components/MainCard/MainCard";
import { FormType, startupSchema } from "./components/MainCard/types";
import { ParamTabProvider } from "./components/TabList/Context/ContextParamTabs";
import TabList from "./components/TabList/TabList";
import styles from "./styles";
import { VALID_ROLES_TO_SHOW_TABS } from "./validations";

const DetailsStartup: React.FC = () => {
  const { id } = useParams() as any;
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const hook = useForm<FormType>({
    resolver: yupResolver(startupSchema),
    mode: "all",
    defaultValues: {
      status: "",
      startupName: "",
      oneliner: "",
      industry: "",
      stage: "",
      companyType: "",
      ownerName: "",
      ownerLastName: "",
      email: "",
      phoneCountryCode: "",
      phoneNumber: "",
      country: "",
      howFindAngelHub: "",
      website: "",
      deck: null,
      pitch: null,
      solvingProblem: "",
      uniqueValueProposal: "",
      founders: [
        {
          name: "",
          role: "",
          linkedIn: "",
        },
        {
          name: "",
          role: "",
          linkedIn: "",
        },
        {
          name: "",
          role: "",
          linkedIn: "",
        },
      ],
      usersInterested: [],
      usersWantToInvest: [],
      usersNotInterested: [],
    },
  });

  const startupUserId = user?.startup?._id;
  const role = user.role.code;

  const isStartupValidToShow =
    startupUserId === id && role === ERolesCode.STARTUP;
  const validRole = VALID_ROLES_TO_SHOW_TABS.includes(role);
  const isInvested =
    hook.getValues("status") === EStatusReleasedOpportunity.INVESTED;

  return (
    <FormProvider {...hook}>
      <ParamTabProvider>
        <PageTitle title="Detalle de Startup" />
        <MainLayout>
          <Box sx={styles.container}>
            <Box>
              <MainCard id={id} />
            </Box>
            {(isStartupValidToShow || validRole) && (
              <Box sx={styles.tab}>
                <TabList id={id} isInvested={isInvested} />
              </Box>
            )}
          </Box>
        </MainLayout>
      </ParamTabProvider>
    </FormProvider>
  );
};

export default DetailsStartup;
