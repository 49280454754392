import { Add, Cancel, Delete, Edit, Save } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowParams,
  MuiEvent,
  GridColumns,
  GridCellParams,
  GridPreProcessEditCellProps,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import * as React from "react";
import { useFormContext } from "react-hook-form";

import {
  getEditId,
  resetEditMode,
} from "../../../../../../../../../redux/actions/userInvertorEdit";
import {
  useDispatch,
  useSelector,
} from "../../../../../../../../../redux/typedHooks";
import { fetchUserByRole } from "../../../../../../../../../services/users";
import { currencyFormat } from "../../../../../../../../../utils/helpers/currencyFormatter";
import { ERoles } from "../../../../../../../../routes/role-catalog";
import { FormType } from "../../../../../../MainCard/types";

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;
  const dispatch = useDispatch();

  const handleClick = () => {
    const _id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        _id,
        user: { name: "", _id: "" },
        money: 0,
        createdAt: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [_id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
    dispatch(getEditId({ _id }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<Add />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}

export interface investor {
  _id: string;
  user: { name: string; _id: string };
  money: number;
  createdAt: string;
  updatedAt: string;
}

const emptyInvestor = {
  _id: "",
  user: { name: "", _id: "" },
  money: 0,
  createdAt: dayjs().toISOString(),
  updatedAt: "",
};

export default function FullFeaturedCrudGrid({
  safeValues,
}: {
  safeValues: (saveRows: investor[]) => void;
}) {
  const mainCardHook = useFormContext<FormType>();
  const usersInvesting = mainCardHook.watch("usersWantToInvest") || [];
  const editId = useSelector((store) => store.userInvertorEditReducer._id);
  const dispatch = useDispatch();
  const [rows, setRows] = React.useState(usersInvesting);
  const [currentRow, setCurrentRow] = React.useState<investor>(emptyInvestor);
  const [members, setMembers] = React.useState<any[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {},
  );

  React.useEffect(() => {
    safeValues(rows);
  }, [rows, safeValues]);
  React.useMemo(() => {
    fetchUserByRole(ERoles.RL004).then((resp: any) => {
      const data = resp.data;
      const membersData: [] = data?.payload.map(
        ({
          _id,
          firstName,
          lastName,
        }: {
          _id: string;
          firstName: string;
          lastName: string;
        }) => ({ _id, name: `${firstName} ${lastName}` }),
      );
      setMembers(membersData);
    });
  }, []);

  const handleRowEditStart = (
    _params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>,
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    _params,
    event,
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId, params: any) => () => {
    dispatch(getEditId({ _id: id }));
    setCurrentRow(rows.find((e) => e._id === id));
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    dispatch(resetEditMode());
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row._id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    dispatch(resetEditMode());
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...currentRow, _id: newRow._id, isNew: false };
    const newRowsData: investor[] = rows.map((row) => {
      if (row._id === newRow._id) {
        return { ...updatedRow };
      } else {
        return row;
      }
    });
    const updatedRows = JSON.parse(JSON.stringify(newRowsData));
    setRows(updatedRows);
    setCurrentRow(emptyInvestor);
    return updatedRow;
  };

  const handlePreProcessEditCellProps = (
    field: keyof investor,
    params: GridPreProcessEditCellProps,
  ): GridPreProcessEditCellProps["props"] => {
    const { props } = params;
    setCurrentRow({
      ...currentRow,
      [field]: props.value,
    });
    return props;
  };

  const columns: GridColumns = [
    {
      field: "user",
      headerName: "Name",
      width: 250,
      editable: true,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return <Typography>{row?.user?.name || "-"}</Typography>;
      },
      renderEditCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <Autocomplete
            options={members}
            fullWidth
            getOptionLabel={(option) => option.name}
            value={members.find((e) => e._id === row.user._id)}
            onChange={(_, newValue) => {
              setCurrentRow({ ...currentRow, user: newValue });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        );
      },
    },
    {
      field: "money",
      headerName: "Money",
      width: 100,
      align: "left",
      headerAlign: "left",
      editable: true,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return <Typography>{currencyFormat(row.money)}</Typography>;
      },
      preProcessEditCellProps: (params) =>
        handlePreProcessEditCellProps("money", params),
    },
    {
      field: "createdAt",
      headerName: "Investment Date",
      type: "date",
      width: 160,
      editable: true,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <Typography>
            {dayjs(row?.createdAt).format("DD/MM/YYYY") || "-"}
          </Typography>
        );
      },
      renderEditCell: (params: GridCellParams) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              value={
                currentRow.createdAt
                  ? currentRow.createdAt
                  : dayjs().toISOString()
              }
              onChange={(newValue) =>
                setCurrentRow({
                  ...currentRow,
                  createdAt: dayjs(newValue).toISOString(),
                })
              }
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (params) => {
        const { id } = params;
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode && editId === id) {
          return [
            <GridActionsCellItem
              key={id}
              icon={<Save />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
              onResize={null}
              onResizeCapture={null}
            />,
            <GridActionsCellItem
              key={`${id}-cancel`}
              icon={<Cancel />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
              onResize={null}
              onResizeCapture={null}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            key={`${id}-edit`}
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id, params)}
            color="inherit"
            onResize={null}
            onResizeCapture={null}
            disabled={isInEditMode || editId !== null}
          />,
          <GridActionsCellItem
            key={`${id}-delete`}
            icon={<Delete />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            onResize={null}
            onResizeCapture={null}
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <DataGrid
        rows={rows}
        getRowId={(row) => row._id || row.id || randomId()}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        components={{ Toolbar: EditToolbar }}
        componentsProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}
