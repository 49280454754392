import { Link, styled } from "@mui/material";

export const LinkShowMore = styled(Link)(() => ({
  fontFamily: "Product Sans !important",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "160%",
  letterSpacing: "0.15px",
  textDecorationLine: "underline",
  color: "#4D88E1",
  cursor: "pointer",
}));
