import { SxProps, Theme } from "@mui/material";

const NoticesStyles: SxProps<Theme> = (theme: Theme) => ({
  "& .news-card": {
    width: "100%",
    padding: "16px !important",
    [theme.breakpoints.up("xl")]: {
      height: "505px",
    },
    [theme.breakpoints.between("sm", "xl")]: {
      height: "597px",
    },
  },
  "& .card-header": {
    paddingBottom: "4px",
  },
  "& .card-header .MuiSvgIcon-root ": {
    color: "#74C24A",
    fontSize: "28px",
  },
  "& .MuiLink-root": {
    fontSize: "14px",
    color: "#4D88E1",
  },
  "& .noElements": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});

export const NoticesItemStyles: SxProps<Theme> = (theme: Theme) => ({
  "& .MuiDivider-root": {
    borderRightWidth: "2px",
    borderColor: "#c7c7c7",
  },
  "& .MuiDivider-middle": {
    marginTop: "3px",
    height: "12px",
  },
  "& .user-name": {
    lineHeight: "16px",
    pb: "7px",
    fontFamily: "Product Sans Medium !important",
    fontWeight: 700,
    fontSize: "16px !important",
    color: "#515151",
    width: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .notice-date": {
    fontFamily: "Product Sans Light !important",
    fontWeight: 300,
    fontSize: "14px !important",
    color: "#515151",
  },
  "& .notice-title": {
    lineHeight: "16px",
    pb: "3px",
    color: "#515151",
    fontSize: "16px !important",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 400,
    fontStyle: "normal",
    fontFamily: "Product Sans !important",
  },
  "& .notice-description": {
    fontFamily: "Product Sans Light !important",
    fontWeight: 300,
    fontSize: "14px !important",
    color: "#515151",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .MuiLink-root": {
    paddingBottom: "8px",
    paddingRight: "12px",
    fontSize: "14px",
    color: "#4D88E1",
  },
  "& .no-wrap-text": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

export default NoticesStyles;
