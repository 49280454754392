import { Paper, useMediaQuery } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useLocation } from "react-router-dom";

import TabPanel from "../../../../components/TabPanel/TabPanel";
import { useQuery } from "../../../../hooks/useQuery";
import { useSelector } from "../../../../redux/typedHooks";
import { ERolesCode } from "../../../routes/role-catalog";

import Membership from "./Tabs/Membership/Membership";
import MyDocuments from "./Tabs/MyDocuments/MyDocuments";
import MyPortfolio from "./Tabs/MyPortfolio/MyPortfolio";
import Reports from "./Tabs/Reports/Reports";
import { styles } from "./styles";
import { TabParam } from "./types";
import useParamTab from "./useParamTab";

interface IProps {
  id: string;
}

const TabList = ({ id }: IProps) => {
  const query = useQuery();
  const { pathname } = useLocation();
  const isDetailMember = pathname.includes("detalles-miembro");

  const url = query.get("Tab") as TabParam;
  const { value, onChange } = useParamTab<TabParam>(url ?? "MyPortfolio");

  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  const responsiveTab = useMediaQuery("(max-width:980px)");

  const handleChange = (_: React.SyntheticEvent, newValue: TabParam) => {
    onChange(newValue as TabParam);
  };

  const isSupAdmin = user.role.code === ERolesCode.SUPER_ADMIN;
  const isAdmin = user.role.code === ERolesCode.ADMINISTRATOR;
  const isAnalyst = user.role.code === ERolesCode.ANALYST;
  const isPartner = user.role.code === ERolesCode.PARTNER;
  const isMember = user.role.code === ERolesCode.MEMBER;

  const tabsValidForDetailsMember = [
    ERolesCode.SUPER_ADMIN,
    ERolesCode.ADMINISTRATOR,
    ERolesCode.ANALYST,
    ERolesCode.PARTNER,
  ].includes(user.role.code);

  const showTabsOnDetailsMember = isDetailMember
    ? tabsValidForDetailsMember
    : true;

  return (
    <Paper square sx={styles.container}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant={responsiveTab ? "scrollable" : "standard"}
        scrollButtons={responsiveTab}
        allowScrollButtonsMobile={responsiveTab}
        sx={{ marginBottom: "46px" }}
      >
        <Tab label="Mi Portafolio" value={"MyPortfolio"} />
        {showTabsOnDetailsMember && [
          [
            <Tab key={"Membership"} label="Membresía" value={"Membership"} />,
            (isSupAdmin ||
              isAdmin ||
              isAnalyst ||
              isPartner ||
              (isMember && user?.member?._id === id)) && (
              <Tab
                key={"MyDocuments"}
                label="Mis Documentos"
                value={"MyDocuments"}
              />
            ),
            <Tab key={"Reports"} label="Reportes" value={"Reports"} />,
          ],
        ]}
      </Tabs>
      <TabPanel value={value} index={"MyPortfolio"}>
        <MyPortfolio />
      </TabPanel>
      <TabPanel value={value} index={"Membership"}>
        <Membership />
      </TabPanel>
      {(isSupAdmin ||
        isAdmin ||
        isAnalyst ||
        isPartner ||
        (isMember && user?.member?._id === id)) && (
        <TabPanel value={value} index={"MyDocuments"}>
          <MyDocuments />
        </TabPanel>
      )}
      <TabPanel value={value} index={"Reports"}>
        <Reports />
      </TabPanel>
    </Paper>
  );
};
export default TabList;
