import { createTheme } from "@mui/material";
import { esES } from "@mui/x-data-grid";


export const theme = createTheme(
  {
    palette: {
      primary: { main: "#57e612" },
    },
  },
  esES,
);
