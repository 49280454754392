import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import MainCard from "../DetailsStartup/components/MainCard/MainCard";
import {
  FormType,
  startupSchema,
} from "../DetailsStartup/components/MainCard/types";

import styles from "./styles";

const DetailsStartup: React.FC = () => {
  const { id } = useParams() as any;

  const hook = useForm<FormType>({
    resolver: yupResolver(startupSchema),
    mode: "all",
    defaultValues: {
      status: "",
      startupName: "",
      oneliner: "",
      industry: "",
      stage: "",
      companyType: "",
      ownerName: "",
      ownerLastName: "",
      email: "",
      phoneCountryCode: "",
      phoneNumber: "",
      country: "",
      howFindAngelHub: "",
      website: "",
      deck: null,
      pitch: null,
      solvingProblem: "",
      uniqueValueProposal: "",
      founders: [
        {
          name: "",
          role: "",
          linkedIn: "",
        },
        {
          name: "",
          role: "",
          linkedIn: "",
        },
        {
          name: "",
          role: "",
          linkedIn: "",
        },
      ],
      usersInterested: [],
      usersWantToInvest: [],
      usersNotInterested: [],
    },
  });

  return (
    <FormProvider {...hook}>
      <PageTitle title="Detalle de Startup" />
      <MainLayout sx={{ flexWrap: "wrap", display: "wrap" }}>
        <Box sx={styles.container}>
          <Box sx={styles.mainCard}>
            <MainCard id={id} />
          </Box>
        </Box>
      </MainLayout>
    </FormProvider>
  );
};

export default DetailsStartup;
