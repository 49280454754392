import { Box, CircularProgress, Grid, useMediaQuery } from "@mui/material";
import { Chart, ArcElement } from "chart.js";
import { useEffect, useState } from "react";

import Calendar from "../../components/Dashboard/Calendar";
import Notices from "../../components/Dashboard/Notices";
import PlatformActivity from "../../components/Dashboard/PlatformActivity";
import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import { getDashboardAdmin } from "../../services/member";

import FunnelCard from "./FunnelCard/FunnelCard";
import IndicatorCard from "./IndicatorCard";
import InvestCard from "./InvestCard/InvestCard";
import MyActions from "./MyActions";
import OpportunitiesSummary from "./OpportunitiesSummary";
import DashboardAdminStyles from "./style";
import { IDashboardAdmin } from "./types";
Chart.register(ArcElement);

const DashboardAdmin = () => {
  const qry1550px = useMediaQuery("(max-width:1550px)");

  const [dashboardData, setDashboardData] = useState<IDashboardAdmin | null>(
    null,
  );
  const loadingComponent = (
    <Box
      display="flex"
      justifyContent={"center"}
      alignItems="center"
      height={"80vh"}
    >
      <CircularProgress />
    </Box>
  );

  useEffect(() => {
    getDashboardAdmin().then(({ data }) => {
      setDashboardData({ ...data });
    });
  }, []);

  return (
    <Box sx={DashboardAdminStyles}>
      <PageTitle title="Dashboard" />
      <MainLayout
        sx={{
          flexDirection: "column",
          justifyContent: "start",
          padding: "24px !important",
          marginTop: "8rem !important",
        }}
      >
        {!dashboardData && loadingComponent}
        {dashboardData && (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            rowSpacing={3}
            columnSpacing={3}
          >
            <Grid item xs={12} lg={4} xl={2.8}>
              <IndicatorCard
                text="Aplicaciones"
                value={dashboardData.totalApplications}
                url="/oportunidades"
                notConcatQuery
              />
            </Grid>
            <Grid item xs={12} lg={8} xl={6.2}>
              <OpportunitiesSummary data={dashboardData.resumenOpportunities} />
            </Grid>
            <Grid item xs={12} lg={3} xl={3}>
              <IndicatorCard
                text="Inversiones Ejecutadas"
                value={dashboardData.investmentExecuted}
                url="/oportunidades-aceptadas-admin?tab=INVESTED"
              />
            </Grid>
            {/* Vertical Card */}
            <Grid item xs={12} lg={9} xl={2.8}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                rowSpacing={3}
                columnSpacing={{ md: 3, xl: 0 }}
              >
                <Grid item xs={12} md={6} lg={4} xl={12}>
                  <IndicatorCard
                    text="Miembros en Proceso"
                    value={dashboardData.membersOnProcess}
                    hidePeriod
                    notConcatQuery
                    url="/inversionistas?tab=ASSIGNED"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4} xl={12}>
                  <IndicatorCard
                    text="Miembros Registrados"
                    value={dashboardData.membersRegistered}
                    url="/usuarios?tab=RL004"
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Calendar Cart */}
            <Grid item xs={12} lg={4} xl={3}>
              <Calendar data={dashboardData.meetingWithFounders} />
            </Grid>
            {/* Notices Card */}
            <Grid item xs={12} lg={4} xl={3.2}>
              <Notices data={dashboardData.notices} />
            </Grid>
            {/* My Activity Card */}
            <Grid item xs={12} lg={4} xl={3}>
              <MyActions data={dashboardData.actions} />
            </Grid>
            {/* Donuts Chart */}
            <Grid item xs={12} lg={qry1550px ? 12 : 6} xl={5.8}>
              <InvestCard data={dashboardData.investmentRealizedGraph} />
            </Grid>
            {/* Funnel Chart */}
            <Grid item xs={12} lg={qry1550px ? 12 : 6} xl={6.2}>
              <FunnelCard data={dashboardData.funnelGraph} />
            </Grid>
            {/* Users Activity */}
            <Grid item xs={12} xl={5.8}>
              <PlatformActivity
                data={dashboardData.usersActivity}
                maxItems={6}
              />
            </Grid>
          </Grid>
        )}
      </MainLayout>
    </Box>
  );
};

export default DashboardAdmin;
