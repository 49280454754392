export const styles = {
  Info: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
    flexWrap: "no-wrap",
    overflowWrap: "anywhere",
    span: {
      display: "inline-block",
      color: "#528495",
      fontSize: "18px",
      fontWeight: "500",
    },
  },
  title: {
    fontWeight: "400",
    lineHeight: "28px",
    fontSize: "20px",
    "@media (max-width: 600px)": {
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "28px",
    },
    span: {
      display: "inline-block",
      color: "#528495",
      fontSize: "24px",
      fontWeight: "500",
    },
    strong: {
      fontSize: "28px",
      fontWeight: "500",
    },
  },
  downloadButton: { color: "#4A88C2", fontSize: "12px", padding: "0px 15px" },
  Icons: {
    display: "flex",
    alignSelf: "flex-start",
    background: "#C0DDE7",
    borderRadius: "100%",
    width: "32px",
    height: "32px",
    padding: "6px",
    color: "#56686e",
  },
  actionsButtons: {
    backgroundColor: "#74C24A",
    width: "162px",
    color: "white",
  },
}