import { PeriodType } from "../../../components/Toolbars/OpportunityReleasedAdminToolbar/const";

export const translateValuePeriod = (value: string) => {
  const map: Record<string, PeriodType> = {
    today: "DIA",
    month: "MES",
    trimestre: "TRIMESTRE",
    annual: "AÑO",
  };
  return map[value];
};
