import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

import calendarIcon from "../../../assets/images/calendar-icon.png";
import graphicsIcon from "../../../assets/images/graphics-icon.png";
import groupIcon from "../../../assets/images/group-icon.png";
import moneyIcon from "../../../assets/images/money-icon.png";
import InstructionCard from "../InstructionCard/InstructionCard";

import DisclaimerStyles from "./style";

interface IProps {
  handleNext: () => void;
  handleBack: () => void;
}

const Disclaimer: React.FC<IProps> = ({ handleNext, handleBack }) => {
  const [agreeTerms, setAgreeTerms] = useState(false);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="stretch"
      height={"100%"}
    >
      <Box flex={"0"}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={DisclaimerStyles}
          rowSpacing={{ xl: 4, xs: 4 }}
        >
          <Grid item xl={12}>
            <Typography variant="h4">
              Consideraciones sobre las inversiones ángel
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InstructionCard
              instructionStep={1}
              icon={graphicsIcon}
              subtitle="Estoy consciente que las inversiones ángeles de Venture Capital son riesgosas, así como puedo ganar un rendimiento mayor a las de otro tipo de inversiones, también existe la posibilidad de perder todo el capital invertido si la startup invertida quiebra o deja de operar."
            />
            <InstructionCard
              instructionStep={2}
              icon={calendarIcon}
              subtitle="Las inversiones ángeles para ofrecer rendimientos óptimos, tienen un periodo de madurez de 3 a 5 años; en algunos casos hasta 10."
            />
            <InstructionCard
              instructionStep={3}
              icon={groupIcon}
              subtitle="Me quiero unir al club porque tengo el objetivo de aprender a ser ángel inversionista, y de igual forma, realizar inversiones en las startups de alto potencial que me presentan."
            />
            <InstructionCard
              instructionStep={4}
              icon={moneyIcon}
              subtitle="El capital que pienso invertir en startups es proveniente de actividades lícitas."
            />
          </Grid>
          <Grid item xl={12} sx={{ pt: 5, pb: 5 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setAgreeTerms(!agreeTerms)}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  />
                }
                label={
                  <Typography variant="caption">
                    Estoy de acuerdo y deseo continuar con el proceso de
                    aplicación como Ángel Miembro del Club.
                  </Typography>
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
      <Box flex={"1"}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{
            flex: "1",
            alignItems: "flex-end",
            height: "100%",
          }}
        >
          <Button
            onClick={handleBack}
            variant="text"
            startIcon={<ArrowBack fontSize="large" />}
            color="info"
            className="stepper-back-button"
          >
            REGRESAR
          </Button>
          <Button
            onClick={handleNext}
            variant="contained"
            color="primary"
            disabled={!agreeTerms}
          >
            CONTINUAR
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default Disclaimer;
