import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";

import { CustomSort } from "../../components/DataGrid";
import { EStatusReleasedOpportunity } from "../../constants";
import Ellipsis from "../../utils/components/ellipsis";
import { currencyFormat } from "../../utils/helpers/currencyFormatter";
import { limitCurrency } from "../../utils/helpers/limitCurrency";
import { translateStatusStartups } from "../../utils/text/translates";

export const columnsOpportunity = (user: any): GridColDef[] => [
  {
    headerName: "Startup",
    field: "name",
    hideSortIcons: true,
    minWidth: 300,
    flex: 0.7,
    align: "left",
    renderCell: (data) => {
      const id = data.row._id;
      return (
        <Ellipsis
          value={
            <NavLink style={{ color: "#4A88C2" }} to={`/details-startup/${id}`}>
              {data.row.name}
            </NavLink>
          }
        />
      );
    },
  },
  {
    headerName: "Etapa",
    field: "stage",
    align: "left",
    hideSortIcons: true,
    minWidth: 170,
    maxWidth: 170,
    flex: 0.7,
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "Industria o rubro",
    field: "industry",
    align: "left",
    hideSortIcons: true,
    minWidth: 145,
    maxWidth: 145,
    flex: 0.7,
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "País",
    field: "country",
    hideSortIcons: true,
    minWidth: 145,
    maxWidth: 145,
    flex: 0.7,
    align: "left",
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "Estatus",
    field: "status",
    hideSortIcons: true,
    minWidth: 195,
    maxWidth: 195,
    flex: 0.7,
    align: "left",
    valueGetter: (data) => translateStatusStartups(data.row.status),
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "Inversión Req. USD",
    field: "requiredInvestment",
    align: "right",
    headerAlign: "right",
    hideSortIcons: true,
    minWidth: 175,
    maxWidth: 175,
    flex: 0.7,
    valueGetter: (data) => parseFloat(data.row.requiredInvestment),
    renderCell: (data) => {
      return (
        <Ellipsis
          value={limitCurrency(
            currencyFormat(data.row.requiredInvestment) as string,
          )}
        />
      );
    },
  },
  {
    headerName: "Valuación USD",
    field: "valuation",
    hideSortIcons: true,
    minWidth: 175,
    maxWidth: 175,
    flex: 0.7,
    align: "right",
    headerAlign: "right",
    valueGetter: (data) => parseFloat(data.row.valuation),
    renderCell: (data) => {
      return (
        <Ellipsis
          value={limitCurrency(currencyFormat(data.row.valuation) as string)}
        />
      );
    },
  },
  {
    headerName: "Días restantes",
    field: "dateStatus",
    hideSortIcons: true,
    minWidth: 130,
    maxWidth: 130,
    flex: 0.7,
    align: "right",
    headerAlign: "right",
    valueGetter: (data) => {
      const expiration = dayjs(data.row.expirationDate).diff(dayjs(), "days");
      if (data.row.status === EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT) {
        return 0;
      } else if (expiration < 0) {
        return 0;
      }

      return expiration + 1;
    },
    renderCell: ({ value }) => {
      let color = "green";

      if (value <= 10) {
        color = "red";
      }
      if (value >= 11 && value <= 21) {
        color = "orange";
      }

      return <p style={{ color }}>{parseInt(value)}</p>;
    },
  },
  {
    headerName: "Intención",
    field: "intention",
    hideSortIcons: true,
    minWidth: 145,
    maxWidth: 145,
    flex: 0.7,
    align: "left",
    valueGetter: (data) => {
      const values = data.row;

      if (values.usersInterested.find((i: any) => i.user === user._id)) {
        return "Me interesa";
      }

      if (values.usersNotInterested.find((i: any) => i === user._id)) {
        return "No me interesa";
      }

      if (values.usersWantToInvest.find((i: any) => i.user === user._id)) {
        return "Quiero invertir";
      }
      return "Ninguna";
    },
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
];
