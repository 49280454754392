export function currencyFormat(currency: number | undefined) {
  if (currency === undefined) {
    return;
  }

  if (typeof currency === "string") {
    return (
      "$" +
      parseFloat(currency)
        .toFixed(0)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }
  return "$" + currency.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
