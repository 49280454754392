import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Divider,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState, KeyboardEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { resetPassword } from "../../services/resetPassword";
import PasswordFieldEye from "../PasswordFieldEye/PasswordFieldEye";

import { resetPasswordSchema } from "./schema";

const classes = {
  returnToLoginOptions: {
    fontSize: "0.8rem",
    textDecoration: "none",
    fontWeight: "bold",
    cursor: "pointer",
    color: "#68BD43",
  },
  formDivider: {
    fontSize: "0.8rem",
  },
} as const;

interface IParams {
  id: string | string[];
  token: string | string[];
}

interface IRequestPasswordFormProps {
  params: IParams;
}

type Form = {
  password: string;
  confirmPassword: string;
};

const ResetPasswordRequestForm: React.FC<IRequestPasswordFormProps> = (
  props,
) => {
  const isMovil = useMediaQuery("(max-width:321px)");
  const { params } = props;
  const history = useHistory();
  const {
    handleSubmit,
    setError,
    control,
    formState: { isValid },
    setValue,
  } = useForm<Form>({
    resolver: yupResolver(resetPasswordSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const handleResetPassword = async (fields: Form): Promise<void> => {
    const newPassword = fields.confirmPassword;
    try {
      setLoading(true);
      await resetPassword(
        params.token as string,
        newPassword as unknown as string,
        params.id as string,
      );
      setIsSuccess(true);
    } catch (err: any) {
      if (
        err?.data?.message === "New password and current password are equals"
      ) {
        setError("confirmPassword", {
          message: "La nueva contraseña no puede ser igual a una anterior.",
        });
      } else {
        setError("confirmPassword", {
          message: "Ha habido un error al actualizar su contraseña.",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleReturnToLogin = (): void => {
    history.push("/login");
  };
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter" && isValid) {
      handleSubmit(handleResetPassword)();
    }
  };

  if (isSuccess) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          align="center"
          sx={{
            opacity: 1,
            textAlign: "center",
            color: "#5D5D5D",
            fontSize: "24px",
          }}
        >
          ¡Contraseña cambiada con éxito!
        </Typography>
        <br />
        <Button
          onClick={handleReturnToLogin}
          variant="contained"
          color="primary"
        >
          Regresar al Inicio de sesión
        </Button>
      </Grid>
    );
  }

  return (
    <>
      <Box justifyContent="center" alignItems="center">
        <Typography
          sx={{
            opacity: 1,
            textAlign: "center",
            color: "#5D5D5D",
            fontSize: "24px",
            marginBottom: "20px",
          }}
          component="h1"
          variant="h5"
        >
          Cambio de contraseña
        </Typography>
        <Typography
          sx={{
            margin: "auto 0 24px 0",
            fontSize: "16px",
            textAlign: "center",
            letterSpacing: "0.11px",
            color: "#5D5D5D",
          }}
          component="h1"
          variant="h5"
        >
          Su nueva contraseña debe ser diferente de la contraseña utilizada
          anteriormente.
        </Typography>
      </Box>
      <Grid container spacing={4} direction="column">
        <Grid item xs={12} sx={{ minWidth: "328px" }}>
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState }) => (
              <PasswordFieldEye
                {...field}
                onChange={(e) => {
                  setValue("password", e.target.value);
                }}
                disabled={loading}
                label="Contraseña"
                type="password"
                variant="outlined"
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
                onKeyPress={handleKeyPress}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field, fieldState }) => (
              <PasswordFieldEye
                {...field}
                disabled={loading}
                label="Confirmar Contraseña"
                type="password"
                variant="outlined"
                error={!isValid && Boolean(fieldState.error)}
                helperText={
                  !isValid &&
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
                onKeyPress={handleKeyPress}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ paddingTop: "30px !important" }}
          direction="row"
          justifyContent="center"
        >
          <Button
            onClick={handleSubmit(handleResetPassword)}
            variant="contained"
            color="primary"
            sx={{ width: "100%" }}
            disabled={!isValid}
          >
            CAMBIAR CONTRASEÑA
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          justifyContent="center"
          alignContent="center"
          sx={{
            fontSize: "12px",
            paddingTop: "28px !important",
          }}
        >
          <Divider style={classes.formDivider}> O </Divider>
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <Link
            onClick={handleReturnToLogin}
            underline="none"
            sx={classes.returnToLoginOptions}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Volver al Inicio sesión
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPasswordRequestForm;
