import { paletteColors } from "../../styles/theme";

const styles = {
  title: {
    fontWeight: 600,
    textAlign: "center",
    fontSize: "1.15rem",
    color: paletteColors.grayTitle,
    marginBottom: "30px",
    "@media (max-height: 1000px)": {
      marginBottom: "22px",
    },
  },
  containerWrapper: {
    minHeight: "622px",
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: "24px",
    paddingBottom: "24px !important",
  },
  button: {
    width: "100%",
    height: "2.3rem",
    textTransform: "none",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  inputWrapper: {
    maxHeight: "46px",
    marginBottom: "42px",
  },
  forgotAccount: {
    textAlign: "center",
    marginTop: "24px",
    fontWeight: 500,
  },
  signUp: {
    fontSize: "0.75rem",
    cursor: "pointer",
    color: "#69BC41",
    marginLeft: "5px",
  },
} as const;

export default styles;
