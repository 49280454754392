import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { TextInput } from "../../../../../components/Inputs";
import InputFile from "../../../../../components/Inputs/InputFile/InputFile";
import StandardDialog from "../../../../../components/StandardDialog";
import { useLoading } from "../../../../../hooks/useLoading";
import { refreshNotification } from "../../../../../redux/actions/notifications";
import { useDispatch } from "../../../../../redux/typedHooks";
import { updateStartupMainCardById } from "../../../../../services/startup";
import { displayToast } from "../../../../../utils/helpers/displayToast";

import { complementSchema } from "./schema";
import { IComplement, Props } from "./types";

export default function CompleteModal({ id, setReload, modalValues }: Props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { handleOpen, handleClose } = useLoading();
  const [form, setForm] = useState<IComplement>({
    monthlyBurnRate: "",
    runway: "",
    solvingProblem: "",
    uniqueValueProposal: "",
    deck: null,
    pitch: "",
  });

  const hook = useForm<IComplement>({
    resolver: yupResolver(complementSchema),
    mode: "all",
    defaultValues: {
      monthlyBurnRate: "",
      runway: "",
      solvingProblem: "",
      uniqueValueProposal: "",
      deck: null,
      pitch: "",
    },
  });

  useEffect(() => {
    hook.setValue("monthlyBurnRate", modalValues.monthlyBurnRate);

    hook.setValue("runway", modalValues.runway);

    hook.setValue("solvingProblem", modalValues.solvingProblem);

    hook.setValue("uniqueValueProposal", modalValues.uniqueValueProposal);

    hook.setValue("pitch", modalValues.pitch);

    hook.setValue("deck", modalValues.deck);
    setForm({
      monthlyBurnRate: modalValues.monthlyBurnRate,
      runway: modalValues.runway,
      solvingProblem: modalValues.solvingProblem,
      uniqueValueProposal: modalValues.uniqueValueProposal,
      pitch: modalValues.pitch,
      deck: modalValues.deck,
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  async function onSubmit(values: any): Promise<void> {
    try {
      handleOpen();

      const form = new FormData();

      form.append("monthlyBurnRate", values.monthlyBurnRate);
      form.append("runway", values.runway);
      form.append("solvingProblem", values.solvingProblem);
      form.append("uniqueValueProposal", values.uniqueValueProposal);
      form.append("pitch", values.pitch);
      form.append("deck", values.deck);

      await updateStartupMainCardById(id, form);
      displayToast("success", "Se actualizo correctamente");
      dispatch(refreshNotification());
    } catch (err: any) {
      displayToast("error", "Hubo un error al actualizar");
    } finally {
      handleCloseModal();
      handleClose();
      hook.reset();
      setReload();
    }
  }
  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          color: "#1976D2",
          padding: "0",
          marginLeft: "5px",
          border: "none",
          ":hover": {
            border: 0,
          },
        }}
      >
        COMPLETAR DATOS
      </Button>
      <StandardDialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Completa tu información de perfil</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor llena los siguientes campos
          </DialogContentText>
          <Grid container spacing={1}>
            {!form.monthlyBurnRate && (
              <Grid item xs={12} sm={6}>
                <Controller
                  control={hook.control}
                  name="monthlyBurnRate"
                  render={({ field, fieldState }) => (
                    <TextInput
                      type={"numeric"}
                      {...field}
                      label="Burn rate"
                      required
                      error={Boolean(fieldState.error)}
                      helperText={
                        Boolean(fieldState.error?.message) &&
                        fieldState.error?.message
                      }
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}
            {!form.runway && (
              <Grid item xs={12} sm={6}>
                <Controller
                  control={hook.control}
                  name="runway"
                  render={({ field, fieldState }) => (
                    <TextInput
                      type={"numeric"}
                      {...field}
                      label="Runway"
                      required
                      error={Boolean(fieldState.error)}
                      helperText={
                        Boolean(fieldState.error?.message) &&
                        fieldState.error?.message
                      }
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}
            {!form.pitch && (
              <Grid item xs={12} sm={6}>
                <Controller
                  control={hook.control}
                  name="pitch"
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      label="Video Pitch"
                      required
                      error={Boolean(fieldState.error)}
                      helperText={
                        Boolean(fieldState.error?.message) &&
                        fieldState.error?.message
                      }
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}
            {!form.deck && (
              <Grid item xs={12} sm={6}>
                <Controller
                  control={hook.control}
                  name="deck"
                  render={({ field, fieldState }) => (
                    <InputFile
                      ref={field.ref}
                      value={field.value}
                      label="Deck"
                      limitMB={25}
                      onChange={field.onChange}
                      errorMessage={fieldState.error?.message}
                      accept={[".pdf"]}
                    />
                  )}
                />
              </Grid>
            )}
            {!form.solvingProblem && (
              <Grid item xs={12}>
                <Controller
                  control={hook.control}
                  name="solvingProblem"
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      label="¿Qué problema resuelves?"
                      required
                      multiline
                      rows={3}
                      error={Boolean(fieldState.error)}
                      helperText={
                        Boolean(fieldState.error?.message) &&
                        fieldState.error?.message
                      }
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}
            {!form.uniqueValueProposal && (
              <Grid item xs={12}>
                <Controller
                  control={hook.control}
                  name="uniqueValueProposal"
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      multiline
                      rows={3}
                      label="¿Cuál es tu propuesta de valor?"
                      required
                      error={Boolean(fieldState.error)}
                      helperText={
                        Boolean(fieldState.error?.message) &&
                        fieldState.error?.message
                      }
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseModal}>
            CANCELAR
          </Button>
          <Button variant="contained" onClick={hook.handleSubmit(onSubmit)}>
            ACEPTAR
          </Button>
        </DialogActions>
      </StandardDialog>
    </>
  );
}
