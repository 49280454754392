export const getVideoId = (url: string) => {
  const match =
    /(?:\?v=|\/embed\/|\.be\/|\/videos\/|embed\/|youtu.be\/|\/v\/|\/e\/|\/u\/\w+\/|\/embed\/|\/v=|e\/|u\/\w+\/|embed\/)([^/\s]{11})/.exec(
      url,
    );

  if (match && match[1]) {
    return match[1];
  } else {
    return null;
  }
};

export const embebedYouTubeURL = (url: string) => {
  const formatoCorrecto = /^https:\/\/www\.youtube\.com\/embed\/[\w-]+$/;

  if (formatoCorrecto.test(url)) {
    return url;
  } else {
    const videoId = getVideoId(url);
    const urlModificado = "https://www.youtube.com/embed/" + videoId;
    return urlModificado;
  }
};
