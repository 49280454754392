import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";

import ItemResourceLibrary from "../ItemResourceLibrary/ItemResourceLibrary";

interface IProps {
  items: any[];
  loading: boolean;
}

const CarouselResourceLibrary: React.FC<IProps> = (props) => {
  const { items, loading } = props;

  if (loading) {
    return (
      <Box
        minHeight={"1000px"}
        display="flex"
        justifyContent={"center"}
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box minHeight={"60vh"}>
      {items.length > 0 ? (
        <Grid container spacing={2}>
          {items.map((i, index) => (
            <Grid item xs={12} sm={6} xl={4} key={index}>
              <ItemResourceLibrary
                author={i.author}
                categories={i.categoria}
                date={i.publishedAt}
                description={i.descripcion}
                image={i?.images[0]}
                title={i.titulo}
                urlRedirect={`/biblioteca-recursos/${i.id}`}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          display={"flex"}
          height={"100%"}
          minHeight={"60vh"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant="h5">No hay ningún registro</Typography>
        </Box>
      )}
    </Box>
  );
};

export default CarouselResourceLibrary;
