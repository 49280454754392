export const styles = {
  container: {
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "100px",
  },
  exitButton: {
    position: "absolute",
    top: "18px",
    right: "18px",
    color: "white",
    fontSize: "22px",
    cursor: "pointer",
  },
};
