export const styles = {
  textDescription: {
    fontFamily: "Product Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1A1A1A",
    width: "1000px",
  },
  boxDate: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    marginTop: "16px",
  },
  iconDate: {
    color: "#4A7C2F",
    width: "18px",
    marginRight: "4px",
  },
  date: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1A1A1A",
  },
  divider: {
    marginTop: "24px",
    marginBottom: "48px",
  },
  commentEvaluation: {
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "24px",
  },
  evaluation: {
    marginTop: "8px",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#343434",
    marginBottom: "24px",
  },
  generalEvaluation: {
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "28px",
    color: "#343434",
    marginBottom: "14px",
  },
  iconStar: {
    color: "#35AC65",
    fontSize: "32px",
    marginX: "4px",
  },
  starValue: {
    fontWeight: "900",
    fontSize: "32px",
    lineHeight: "48px",
    color: "#74C24A",
  },
  nameText: {
    fontSize: "16px",
    color: "#676767",
  },
  boxEvaluations: {
    display: "flex",
    gap: "12px",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
};
