import { Ballot } from "@mui/icons-material";
import { SxProps } from "@mui/material";
import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useParams } from "react-router-dom";

import SplitButton from "../../../../../../components/SplitButton/SplitButton";
import { OptionButtonSplit } from "../../../../../../components/SplitButton/types";
import { EStatusOpportunity } from "../../../../../../constants";
import { useLoading } from "../../../../../../hooks/useLoading";
import { openModal } from "../../../../../../redux/actions/modal";
import { useDispatch, useSelector } from "../../../../../../redux/typedHooks";
import { updateStatusOpportunity } from "../../../../../../services/opportunitiesApplication2";
import { displayToast } from "../../../../../../utils/helpers/displayToast";
import {
  VALID_STATUS_RESIGNATION_ANALYST,
  VALID_STATUS_RESIGNATION_PARTNER,
} from "../../../../../Opportunities/constants";
import { ERolesCode } from "../../../../../routes/role-catalog";
import { IMainCardForm } from "../../types";
import DialogChangeAnalyst from "../DialogChangeAnalyst/DialogChangeAnalyst";
import DialogChangePartner from "../DialogChangePartner/DialogChangePartner";
import DialogReject from "../DialogReject/DialogReject";

interface IProps {
  xs: SxProps;
  hook: UseFormReturn<IMainCardForm>;
  changeMode: () => void;
  isMobile?: boolean;
}

const ButtonReadMode: React.FC<IProps> = ({
  hook,
  changeMode,
  xs,
  isMobile,
}) => {
  const { id } = useParams() as { id: string };
  const dispatch = useDispatch();
  const { handleClose, handleOpen } = useLoading();
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const [openChangeAnalyst, setOpenChangeAnalyst] = useState(false);
  const [openChangePartner, setOpenChangePartner] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [isReasigned, setisReasigned] = useState(false);

  const status = hook.watch("status");
  const analystAssigned = hook.watch("analistAssigned");
  const partnerAssigned = hook.watch("partnerAssigned");

  const isAdmin = [
    ERolesCode.ADMINISTRATOR,
    ERolesCode.PARTNER,
    ERolesCode.SUPER_ADMIN,
  ].includes(user.role.code);

  const isAdminToScheduleSecondCall = [
    ERolesCode.ADMINISTRATOR,

    ERolesCode.SUPER_ADMIN,
  ].includes(user.role.code);

  const isAnalyst = ERolesCode.ANALYST === user.role.code;

  const isAnalystOwned = user._id === analystAssigned;
  const isPartnerOwned = user._id === partnerAssigned;

  function handleEditar() {
    changeMode();
  }
  function handleChangeAnalyst(isReasigned?: boolean) {
    isReasigned && setisReasigned(true);
    setOpenChangeAnalyst(true);
  }

  function onCancelChangeAnalyst() {
    setOpenChangeAnalyst(false);
  }

  function onCancelChangePartner() {
    setOpenChangePartner(false);
  }

  function handleChangePartner() {
    setOpenChangePartner(true);
  }

  function onCancelReject() {
    setOpenReject(false);
  }
  function handleReject() {
    dispatch(
      openModal({
        title: "Advertencia",
        description: "¿Seguro que desea rechazar esta oportunidad?",
        action: () => {
          setOpenReject(true);
        },
      }),
    );
  }

  // REJECTED
  async function handleRechazar(data: string) {
    dispatch(
      openModal({
        title: "Advertencia",
        description: "¿Seguro que desea rechazar esta oportunidad?",
        action: async () => {
          handleOpen();
          try {
            await updateStatusOpportunity(id, {
              date: new Date(),
              status: EStatusOpportunity.REJECTED,
              comment: data,
            });
            hook.setValue("status", EStatusOpportunity.REJECTED);
            displayToast("success", "Se ha rechazado oportunidad.");
          } catch (error) {
            displayToast("error", "No se pudo rechazar oportunidad.");
          } finally {
            handleClose();
          }
        },
      }),
    );
  }

  // ASSIGNED ANALYST
  async function onAcceptChangeAnalyst(data: any) {
    try {
      handleOpen();

      const status = isReasigned
        ? EStatusOpportunity.RE_ASSIGNED_ANALYST
        : EStatusOpportunity.ASSIGNED_ANALYST;
      await updateStatusOpportunity(id, {
        loggedId: user._id,
        analystId: data._id,
        date: new Date(),
        status,
      });
      onCancelChangeAnalyst();
      hook.setValue("status", status);
      hook.setValue("analistAssigned", data._id);
      displayToast("success", "Se ha asignado correctamente");
    } catch (error) {
      displayToast("success", "No se pudo actualizar estatus");
    } finally {
      handleClose();
    }
  }

  // BOARD_ANALYST
  async function handleProgrammingAnalyst(): Promise<void> {
    dispatch(
      openModal({
        title: "Advertencia",
        description:
          "¿Seguro que desea cambiar el estatus de esta oportunidad?",
        action: async () => {
          handleOpen();
          try {
            await updateStatusOpportunity(id, {
              date: new Date(),
              status: EStatusOpportunity.PROGRAMMING_ANALYST,
              analystId: analystAssigned,
            });
            hook.setValue("status", EStatusOpportunity.PROGRAMMING_ANALYST);
            displayToast("success", "Se ha mandado invitación");
            displayToast("success", "Se actualizo estatus correctamente.");
          } catch (error: any) {
            const message = error.data.message;

            if (
              message ===
              "El usuario tiene mal registrado el evento de calendly"
            ) {
              displayToast(
                "error",
                "La liga de Calendly del usuario que intenta agendar, no es valido. Favor de revisar que sea el correcto.",
              );
            } else {
              displayToast(
                "error",
                "No se pudo actualizar estatus correctamente.",
              );
            }
          } finally {
            handleClose();
          }
        },
      }),
    );
  }

  // ASSIGN PARTNER
  async function handleAssignPartner() {
    //TODO Buscar manera de no mandar el id fijo
    dispatch(
      openModal({
        title: "Advertencia",
        description: `¿Seguro que deseas asignar esta oportunidad a un partner?`,
        primaryButtonText: "Confirmar",
        action: async () => {
          handleOpen();
          try {
            await updateStatusOpportunity(id, {
              status: EStatusOpportunity.ASSIGNED_PARTNER,
              partnerId: process.env.REACT_APP_USER_DEFAULT_PARTNER_ID || "",
              date: new Date(),
              loggedId: user._id,
            });
            hook.setValue("status", EStatusOpportunity.ASSIGNED_PARTNER);
            hook.setValue(
              "partnerAssigned",
              process.env.REACT_APP_USER_DEFAULT_PARTNER_ID || "",
            );
            setOpenChangePartner(false);
            displayToast("success", "Se actualizo estatus correctamente.");
          } catch (error) {
            displayToast(
              "error",
              "No se pudo actualizar estatus correctamente.",
            );
          } finally {
            handleClose();
          }
        },
      }),
    );
  }
  // RE ASSIGN PARTNER
  async function handleReAssignPartner(data: any) {
    //TODO Buscar manera de no mandar el id fijo
    dispatch(
      openModal({
        title: "Advertencia",
        description: `¿Seguro que deseas reasignar esta oportunidad al partner?`,
        primaryButtonText: "Confirmar",
        action: async () => {
          handleOpen();
          try {
            await updateStatusOpportunity(id, {
              status: EStatusOpportunity.RE_ASSIGNED_PARTNER,
              partnerId: data._id,
              date: new Date(),
              loggedId: user._id,
            });
            hook.setValue("status", EStatusOpportunity.RE_ASSIGNED_PARTNER);
            hook.setValue("partnerAssigned", data._id);
            displayToast("success", "Se actualizo estatus correctamente.");
          } catch (error) {
            displayToast(
              "error",
              "No se pudo actualizar estatus correctamente.",
            );
          } finally {
            handleClose();
          }
        },
      }),
    );
  }
  // PROGRAMMING PARTNER
  async function handleProgrammingPartner(): Promise<void> {
    dispatch(
      openModal({
        title: "Información",
        description:
          "Se enviará un correo al emprendedor para agendar la siguiente entrevista. ¿Desea continuar?",
        action: async () => {
          handleOpen();
          try {
            await updateStatusOpportunity(id, {
              date: new Date(),
              status: EStatusOpportunity.PROGRAMMING_PARTNER,
              partnerId: partnerAssigned,
            });
            hook.setValue("status", EStatusOpportunity.PROGRAMMING_PARTNER);
            displayToast("success", "Se actualizo estatus correctamente.");
          } catch (error: any) {
            const message = error.data.message;

            if (
              message ===
              "El usuario tiene mal registrado el evento de calendly"
            ) {
              displayToast(
                "error",
                "La liga de Calendly del usuario que intenta agendar no es valido. Favor de revisar que sea el correcto.",
              );
            } else {
              displayToast(
                "error",
                "No se pudo actualizar estatus correctamente.",
              );
            }
          } finally {
            handleClose();
          }
        },
      }),
    );
  }

  // ASSIGNED COMMITTEE
  async function handleAssignedCommittee(): Promise<void> {
    dispatch(
      openModal({
        title: "Información",
        description:
          "La oportunidad se acaba de evaluar por parte del partner, se asignará a comité la oportunidad.",
        action: async () => {
          handleOpen();
          try {
            await updateStatusOpportunity(id, {
              date: new Date(),
              status: EStatusOpportunity.ASSIGNED_COMMITTEE,
            });
            hook.setValue("status", EStatusOpportunity.ASSIGNED_COMMITTEE);
            displayToast("success", "Se actualizo estatus correctamente.");
          } catch (error) {
            displayToast(
              "error",
              "No se pudo actualizar estatus correctamente. Asegúrate de haber llenado los comentarios finales antes de avanzar.",
            );
          } finally {
            handleClose();
          }
        },
      }),
    );
  }
  // APPROVED COMMITTEE
  async function handleApprovedCommittee(): Promise<void> {
    dispatch(
      openModal({
        title: "Información",
        description:
          "Se terminará de evaluar la oportunidad y se le pedirá completar su perfil. ¿Desea continuar?",
        action: async () => {
          handleOpen();
          try {
            await updateStatusOpportunity(id, {
              date: new Date(),
              status: EStatusOpportunity.APPROVED_COMMITTEE,
            });
            hook.setValue("status", EStatusOpportunity.APPROVED_COMMITTEE);
            displayToast("success", "Se actualizo estatus correctamente.");
          } catch (error) {
            displayToast(
              "error",
              "No se pudo actualizar estatus correctamente.",
            );
          } finally {
            handleClose();
          }
        },
      }),
    );
  }

  // MONITORING
  async function handleMonitoring(): Promise<void> {
    dispatch(
      openModal({
        title: "Información",
        description:
          "Se enviará la oportunidad a monitoreo, pero podrá retomarse su evaluación después. ¿Desea continuar?",
        action: async () => {
          handleOpen();
          try {
            await updateStatusOpportunity(id, {
              date: new Date(),
              status: EStatusOpportunity.IS_MONITORING,
              partnerId: partnerAssigned,
            });
            hook.setValue("status", EStatusOpportunity.IS_MONITORING);
            displayToast("success", "Se actualizo estatus correctamente.");
          } catch (error) {
            displayToast(
              "error",
              "No se pudo actualizar estatus correctamente.",
            );
          } finally {
            handleClose();
          }
        },
      }),
    );
  }

  const optionsButtons: OptionButtonSplit[] = [
    {
      label: "Editar",
      visible: true,
      onClick: handleEditar,
    },
    {
      label: "Asignar a analista",
      visible: status === EStatusOpportunity.RECEIVED,
      onClick: () => handleChangeAnalyst(false),
    },
    {
      label: "Agendar Primera Entrevista",
      visible:
        (status === EStatusOpportunity.ASSIGNED_ANALYST ||
          status === EStatusOpportunity.RE_ASSIGNED_ANALYST) &&
        isAnalystOwned,
      onClick: handleProgrammingAnalyst,
    },
    {
      label: "Reasignar analista",
      visible:
        (VALID_STATUS_RESIGNATION_ANALYST.includes(
          status as EStatusOpportunity,
        ) &&
          isAnalyst &&
          isAnalystOwned) ||
        (VALID_STATUS_RESIGNATION_ANALYST.includes(
          status as EStatusOpportunity,
        ) &&
          isAdmin),
      onClick: () => handleChangeAnalyst(true),
    },
    {
      label: "Asignar a partner",
      visible:
        status ===
        EStatusOpportunity.EVALUATED_ANALYST /* && isAssignPartner */,
      onClick: handleAssignPartner,
    },
    {
      label: "Reasignar partner",
      visible:
        VALID_STATUS_RESIGNATION_PARTNER.includes(
          status as EStatusOpportunity,
        ) && isAdmin,
      onClick: handleChangePartner,
    },
    {
      label: "Agendar Segunda Entrevista",
      visible:
        (status === EStatusOpportunity.ASSIGNED_PARTNER ||
          status === EStatusOpportunity.RE_ASSIGNED_PARTNER) &&
        (isPartnerOwned || isAdminToScheduleSecondCall),
      onClick: handleProgrammingPartner,
    },
    {
      label: "Asignar a comité",
      visible: status === EStatusOpportunity.EVALUATED_PARTNER && isAdmin,
      onClick: handleAssignedCommittee,
    },
    {
      label: "Aprobada comité",
      visible:
        (status === EStatusOpportunity.ASSIGNED_COMMITTEE ||
          status === EStatusOpportunity.IS_MONITORING) &&
        isAdmin,
      onClick: handleApprovedCommittee,
    },
    {
      label: "En monitoreo",
      visible:
        (status === EStatusOpportunity.PROGRAMMED_ANALYST ||
          status === EStatusOpportunity.PROGRAMMED_PARTNER ||
          status === EStatusOpportunity.ASSIGNED_COMMITTEE) &&
        isAdmin,
      onClick: handleMonitoring,
    },
    {
      label: "Rechazar",
      visible: true,
      onClick: handleReject,
    },
  ];

  return (
    <>
      <DialogChangeAnalyst
        onAccept={onAcceptChangeAnalyst}
        onCancel={onCancelChangeAnalyst}
        open={openChangeAnalyst}
      />
      <DialogChangePartner
        onAccept={handleReAssignPartner}
        onCancel={onCancelChangePartner}
        open={openChangePartner}
      />
      <DialogReject
        open={openReject}
        handleClose={onCancelReject}
        handleSubmit={handleRechazar}
      />
      <SplitButton
        isMobile={isMobile}
        icon={<Ballot />}
        options={optionsButtons}
        title="Acciones"
        placement={isMobile ? "left" : "bottom"}
      />
    </>
  );
};

export default ButtonReadMode;
