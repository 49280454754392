import { ENotificationType } from "../../components/DashboardLayout/NotificationCard/constants";
import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  REFRESH_NOTIFICATIONS,
} from "../actions/notifications";

export interface INotificationReducer {
  notifications: ENotificationType[];
  refreshNotifications: boolean;
}

const initialState: INotificationReducer = {
  notifications: [],
  refreshNotifications: false,
};

export const NotificationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (_, index) => index !== action.payload,
        ),
      };
    case REFRESH_NOTIFICATIONS:
      return {
        ...state,
        refreshNotifications: !state.refreshNotifications,
      };
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      };
    default:
      return state;
  }
};
