import { SxProps, Theme } from "@mui/material";

const PlatformActivityStyles: SxProps<Theme> = (theme: Theme) => ({
  "& .activity-card": {
    alignSelf: "flex-start",
    maxWidth: "792px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      height: "455px",
    },
    padding: "0 !important",
  },
  "& .MuiAvatar-root": {
    backgroundColor: "#bdbdbd",
  },
  "& .event-user-name": {
    fontFamily: "Product Sans Medium !important",
    display: "inline-block",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    color: "#515151",
  },
  "& .MuiDivider-root ": {
    marginLeft: "0px !important",
  },
  "& .MuiListItem-root ": {
    paddingBottom: "3px",
    paddingTop: "3px",
  },
  "& .event-log": {
    color: "#00000099",
    fontSize: "14px",
    fontFamily: "Product Sans Medium !important",
  },
  "& .event-time": { color: "#00000099" },
  "& .MuiLink-root": {
    color: "#0085FF",
  },
  "& .noElements": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "455px",
  },
});

export default PlatformActivityStyles;
