import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

import { IFunnelData } from "../types";

interface FunnelTableProps {
  rows: IFunnelData[];
}
const FunnelTable: React.FC<FunnelTableProps> = ({ rows }) => {
  const stylesHeaderCell = {
    fontFamily: "Product Sans Medium !important",
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.87);",
  };
  return (
    <Box sx={{ pt: 6 }}>
      <TableContainer component={Box}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={stylesHeaderCell} align="left">
                Etapa
              </TableCell>
              <TableCell sx={stylesHeaderCell} align="right">
                #
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row._id}
                </TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FunnelTable;
