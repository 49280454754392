import { Box } from "@mui/system";
import { useParams } from "react-router-dom";

import NewsFeedDetail from "../../components/NewsFeedDetail/NewsFeedDetail";

const PreviewNewsFeed = () => {
  const { id } = useParams() as { id: string };
  return (
    <Box
      minWidth={"100vw"}
      minHeight="100vh"
      padding={"40px 20px"}
      sx={{ background: "#F8F8F8" }}
    >
      <NewsFeedDetail id={id} />
    </Box>
  );
};

export default PreviewNewsFeed;
