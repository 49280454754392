/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as yup from "yup";

import {
  CAMPO_REQUERIDO,
  CORREO_NO_VALIDO,
  NUMERO_NO_VALIDO,
} from "../../../utils/text/inputs";
import { URL_VALIDA_FORM } from "../../../utils/text/resetForm";

export const aplicantMemberSchema = yup.object({
  email: yup.string().email(CORREO_NO_VALIDO).required(CAMPO_REQUERIDO),
  firstName: yup.string().required(CAMPO_REQUERIDO),
  lastName: yup.string().required(CAMPO_REQUERIDO),
  phoneCountryCode: yup.string(),
  phoneNumber: yup
    .string()
    .required(CAMPO_REQUERIDO)
    .matches(/^[0-9]{11}|[0-9]{12}|[0-9]{13}$/, NUMERO_NO_VALIDO),
  nationalityCountry: yup.string().required(CAMPO_REQUERIDO),
  residenceCountry: yup.string().required(CAMPO_REQUERIDO),
  residenceCity: yup.string().required(CAMPO_REQUERIDO),
  appointments: yup.string(),
  meetLink: yup.string(),
  status: yup.string().required(CAMPO_REQUERIDO),
  partner: yup.string(),
  linkedInProfile: yup
    .string()
    .url(URL_VALIDA_FORM)
    .when("cv", {
      is: "",
      then: yup.string().required(CAMPO_REQUERIDO),
      otherwise: yup.string().optional(),
    }),
  hasExperienceInvesting: yup.string().required(CAMPO_REQUERIDO),
  interestToEnter: yup.string().required(CAMPO_REQUERIDO),
  partnerComments: yup.string().max(300, "Campo máximo de 300 caracteres"),
  cv: yup.string(),
});

export const rejectedSchema = yup.object({
  comments: yup.string().required(CAMPO_REQUERIDO),
});
