import BallotIcon from "@mui/icons-material/Ballot";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchangeTwoTone";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOnSharp";
import PaymentsIcon from "@mui/icons-material/PaymentsSharp";
import PriceChangeIcon from "@mui/icons-material/PriceChangeOutlined";
import SavingsIcon from "@mui/icons-material/Savings";
import { Button, useMediaQuery, Menu, MenuItem, Grid } from "@mui/material";
import { FC, useState } from "react";
import { UseFormReturn } from "react-hook-form";

import SplitButton from "../../../../../../../components/SplitButton/SplitButton";
import { currencyFormat } from "../../../../../../../utils/helpers/currencyFormatter";
import { RESPONSIVE_BUTTON } from "../../../../../validations";
import Layout from "../../../../Layout/Layout";
import { styles as mainStyles } from "../../../styles";
import { IFormFinancialInfo } from "../../../types";
import ItemFinancialInfo from "../ItemFinancialInfo/ItemFinancialInfo";

interface IFinancialInfoReadMode {
  hook: UseFormReturn<IFormFinancialInfo>;
  changeMode: () => void;
  loading: boolean;
}

export const ReadMode: FC<IFinancialInfoReadMode> = ({
  changeMode,
  hook,
  loading,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const breakpoint = useMediaQuery(RESPONSIVE_BUTTON);

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  const menu = (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
    >
      <MenuItem onClick={changeMode}>Editar</MenuItem>
    </Menu>
  );

  return (
    <Layout>
      <Layout.Button>
        {!breakpoint && (
          <Button
            variant="contained"
            onClick={changeMode}
            sx={{ ...mainStyles.actionsButtons }}
          >
            Editar
          </Button>
        )}
      </Layout.Button>
      <Layout.Panel loading={loading}>
        {menu}
        <Grid container spacing={3}>
          {breakpoint && (
            <Grid
              item
              xs={12}
              p={0}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <SplitButton
                title="Acciones"
                isMobile
                icon={<BallotIcon />}
                options={[
                  {
                    label: "Editar",
                    visible: true,
                    onClick: changeMode,
                  },
                ]}
              />
            </Grid>
          )}
          <ItemFinancialInfo
            Icon={PriceChangeIcon}
            title="Inversión requerida:"
            value={currencyFormat(hook.watch("requiredInvestment")) || ""}
            isUSD
          />
          <ItemFinancialInfo
            Icon={PriceChangeIcon}
            title="Valuación post money:"
            value={currencyFormat(hook.watch("postMoney")) || ""}
            isUSD
          />
          <ItemFinancialInfo
            Icon={PaymentsIcon}
            title="Valuación actual:"
            value={currencyFormat(hook.watch("valuation")) || ""}
            isUSD
          />
          <ItemFinancialInfo
            Icon={CurrencyExchangeIcon}
            title="Revenue mensual:"
            value={currencyFormat(hook.watch("monthlyRevenue")) || ""}
            isUSD
          />
          <ItemFinancialInfo
            Icon={MonetizationOnIcon}
            title="Burn rate:"
            value={currencyFormat(hook.watch("monthlyBurnRate")) || ""}
            isUSD
          />
          <ItemFinancialInfo
            Icon={SavingsIcon}
            title="Runway:"
            value={hook.watch("runway")?.toString() || ""}
            isMonth
          />
        </Grid>
      </Layout.Panel>
    </Layout>
  );
};
