import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { getToken } from "../config";

export async function getPricePaymentMembership(
  id: string,
): Promise<AxiosResponse<any>> {
  const token = getToken();

  const url = `/payment/membership/${id}`;

  const config: AxiosRequestConfig = {
    url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await axios(config);
}
export function postCaptureOrder(
  order: string,
  memberId: string,
): Promise<any> {
  const token = getToken();

  const url = `/payment/capture-order/${memberId}`;

  const config: AxiosRequestConfig = {
    url,
    method: "POST",
    data: {
      order,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
