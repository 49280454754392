import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { TabContext } from "@mui/lab";
import {
  Autocomplete,
  Badge,
  Box,
  Grid,
  Tab,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { GridToolbarDensitySelector } from "@mui/x-data-grid";
import { VFC, useState, ChangeEvent } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  STAGE_OPTIONS,
  INDUSTRY_OPTIONS,
} from "../../../pages/StartupRegister/constants";
import countries from "../../../utils/countries.json";
import DrawerFilter from "../../DrawerFilter/DrawerFilter";
import { TextInput } from "../../Inputs";

import Tabs from "./Tabs";
import { OPTIONS_VENCIDAS } from "./const";
import styles, { useStyles } from "./styles";

interface IOpportunitiesReleasedToolbardProps {
  onSearchChange?: (value: string) => void;
  onSearchClear?: () => void;
  onStageChange?: (value: string) => void;
  onIndustryChange?: (value: string) => void;
  onCountryChange?: (value: string) => void;
  resetPage?: () => void;
  showOptions?: boolean;
  hideIcons?: boolean;
  Tab: string;
  Vencidas: string;
}

const OpportunitiesReleasedToolbard: VFC<
  IOpportunitiesReleasedToolbardProps
> = (props) => {
  const {
    onSearchChange,
    onSearchClear,
    onStageChange,
    onIndustryChange,
    onCountryChange,
    resetPage,
    hideIcons,
    Vencidas,
    Tab,
  } = props;

  const [search, setSearch] = useState<string>("");
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const [Stage, setStage] = useState<string | null>(null);
  const [StageInput, setStageInput] = useState<string>("");

  const [Industry, setIndustry] = useState<string | null>(null);
  const [IndustryInput, setIndustryInput] = useState<string>("");

  const [Country, setCountry] = useState<string | null>(null);
  const [CountryInput, setCountryInput] = useState<string>("");

  const breakpoint = useMediaQuery("(max-width: 1400px)");
  const classes = useStyles();

  const history = useHistory();

  const badgeNumber = (): number => {
    let number = 0;
    if (Stage) {
      number++;
    }
    if (Industry) {
      number++;
    }
    if (Country) {
      number++;
    }
    return number;
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    onSearchChange?.(event.target.value);
  };

  const handleChangeStage = (_: any, newValue: any) => {
    setStage(newValue);
    resetPage && resetPage();
    !openDrawer && onStageChange && onStageChange(newValue);
  };
  const handleChangeIndustry = (_: any, newValue: any) => {
    setIndustry(newValue);
    resetPage && resetPage();
    !openDrawer && onIndustryChange && onIndustryChange(newValue);
  };
  const handleChangeCountry = (_: any, newValue: any) => {
    setCountry(newValue);
    resetPage && resetPage();
    !openDrawer && onCountryChange && onCountryChange(newValue);
  };

  const handleChangeVencidas = (_: any, newValue: any) => {
    resetPage && resetPage();
    let search = "";
    if (Tab) {
      search += `?tab=${Tab}`;
    }
    if (newValue) {
      search += `&vencidas=${newValue}`;
    }
    history.replace({
      pathname: `${history.location.pathname}`,
      search,
    });
  };

  const handleSearchClear = () => {
    setSearch("");
    onSearchClear && onSearchClear();
  };

  const handleFilter = () => {
    resetPage && resetPage();
    onStageChange && onStageChange(Stage || "");
    onIndustryChange && onIndustryChange(Industry || "");
    onCountryChange && onCountryChange(Country || "");
    setOpenDrawer(false);
  };

  const filters = (
    <>
      <Autocomplete
        sx={styles.searchField}
        value={Stage}
        onChange={handleChangeStage}
        options={STAGE_OPTIONS}
        inputValue={StageInput}
        onInputChange={(_, newInputValue) => setStageInput(newInputValue)}
        isOptionEqualToValue={(i) => i === Stage}
        renderInput={({ InputLabelProps, InputProps, inputProps, ...rest }) => (
          <TextInput
            {...rest}
            InputProps={{
              ...InputProps,
              style: { height: 48, paddingTop: "6px" },
            }}
            inputProps={inputProps}
            InputLabelProps={{
              ...InputLabelProps,
              style: { top: "-3.5px" },
              classes: { shrink: classes.inputLabel },
            }}
            label="Etapa"
          />
        )}
      />
      <Autocomplete
        sx={styles.searchField}
        value={Industry}
        onChange={handleChangeIndustry}
        inputValue={IndustryInput}
        onInputChange={(_, newInputValue) => setIndustryInput(newInputValue)}
        options={INDUSTRY_OPTIONS}
        renderInput={({ InputLabelProps, InputProps, inputProps, ...rest }) => (
          <TextInput
            {...rest}
            InputProps={{
              ...InputProps,
              style: { height: 48, paddingTop: "6px" },
            }}
            inputProps={inputProps}
            InputLabelProps={{
              ...InputLabelProps,
              style: { top: "-3.5px" },
              classes: { shrink: classes.inputLabel },
            }}
            label="Industria"
          />
        )}
      />
      <Autocomplete
        sx={styles.searchField}
        value={Country}
        onChange={handleChangeCountry}
        inputValue={CountryInput}
        onInputChange={(_, newInputValue) => setCountryInput(newInputValue)}
        options={countries.map((i) => i.name_es)}
        renderInput={({ InputLabelProps, InputProps, inputProps, ...rest }) => (
          <TextInput
            {...rest}
            InputProps={{
              ...InputProps,
              style: { height: 48, paddingTop: "6px" },
            }}
            inputProps={inputProps}
            InputLabelProps={{
              ...InputLabelProps,
              style: { top: "-3.5px" },
              classes: { shrink: classes.inputLabel },
            }}
            label="País"
          />
        )}
      />
      <Autocomplete
        sx={styles.searchField}
        value={Vencidas}
        onChange={handleChangeVencidas}
        options={OPTIONS_VENCIDAS}
        renderInput={({ InputLabelProps, InputProps, inputProps, ...rest }) => (
          <TextInput
            {...rest}
            InputProps={{
              ...InputProps,
              style: { height: 48, paddingTop: "6px" },
            }}
            inputProps={inputProps}
            InputLabelProps={{
              ...InputLabelProps,
              style: { top: "-3.5px" },
              classes: { shrink: classes.inputLabel },
            }}
            label="Vencimiento"
          />
        )}
      />
    </>
  );

  return (
    <TabContext value={Tab}>
      <Grid container sx={{ ...styles.toolbar, gap: "12px" }}>
        <Grid container item xs={12} spacing={2}>
          <Box
            sx={{
              padding: "15px 0px 25px 0px",
              width: "100%",
              overflow: "hide",
            }}
          >
            <Tabs />
          </Box>
        </Grid>
        <Grid container item xs={12} sx={{ gap: "12px" }}>
          <TextInput
            value={search}
            onChange={handleSearchChange}
            label="Buscar"
            InputProps={{
              sx: { height: "48px" },
              startAdornment: (
                <SearchIcon fontSize="small" sx={styles.searchIcon} />
              ),
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{
                    visibility: search.length > 0 ? "visible" : "hidden",
                  }}
                  onClick={handleSearchClear}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
          <Box sx={styles.iconsWrapper}>
            {breakpoint ? (
              <IconButton onClick={() => setOpenDrawer(true)} color="primary">
                <Badge badgeContent={badgeNumber()} color="primary">
                  <FilterListIcon />
                </Badge>
              </IconButton>
            ) : (
              <Box display={"flex"} alignItems="center" gap="12px">
                {filters}
              </Box>
            )}

            <DrawerFilter
              isOpen={openDrawer}
              onFilter={handleFilter}
              onClose={handleFilter}
            >
              {filters}
            </DrawerFilter>

            <Box display={"flex"} alignItems="center">
              {!hideIcons && (
                <GridToolbarDensitySelector {...({} as any)} sx={styles.icon} />
              )}
              {/*  {showOptions && (
              <PositionedMenu
                handleRejectApplication={handleRejectApplication}
                handleAceptApplication={handleAceptApplication}
                handleChangeAssigned={handleChangeAssigned}
              />
            )} */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </TabContext>
  );
};
export default OpportunitiesReleasedToolbard;
