import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";

import { IItemSideAvatar } from "./IItemSideAvatarProps";

const ItemSideAvatar: React.FC<IItemSideAvatar> = ({ icon, name, title }) => {
  return (
    <Stack flexWrap={"wrap"} width={"100%"}>
      <Box display={"flex"} gap={"4px"} alignItems={"center"}>
        {icon}
        <Typography
          sx={{
            fontFamily: "Product Sans",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#515151",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontFamily: "Product Sans Light !important",
          color: "#4A7C2F",
          fontSize: "16px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          maxWidth: "150px",
          fontWeight: "300",
        }}
      >
        {name}
      </Typography>
    </Stack>
  );
};

export default ItemSideAvatar;
