import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { TabContext } from "@mui/lab";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useState, VFC } from "react";

import { TextInput } from "../../Inputs";
import styles from "../UserToolbar/styles";

import Tabs from "./Tabs";

interface IProps {
  onSearchChange?: (value: string) => void;
  onSearchClear?: () => void;
  onTabChange?: (value: string) => void;
  resetPage?: () => void;
  showOptions?: boolean;
}

const MemberShipToolbar: VFC<IProps> = (props) => {
  const { onTabChange, resetPage, onSearchChange, onSearchClear } = props;
  const [value, setValue] = useState("");
  const [search, setSearch] = useState<string>("");

  const isMobile = useMediaQuery("(max-width:730px)");

  const handleChangeTab = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    resetPage && resetPage();
    onTabChange && onTabChange(newValue);
  };

  const handleSearchClear = () => {
    setSearch("");
    onSearchClear && onSearchClear();
  };

  const handleSearchChange = (event: string) => {
    setSearch(event);
    onSearchChange && onSearchChange(event);
  };

  return (
    <TabContext value={value}>
      <Grid container sx={{ ...styles.toolbar, gap: "12px" }}>
        <Grid container item xs={12} spacing={2}>
          <Box
            sx={{
              padding: "15px 0px 25px 0px",
              width: "100%",
              overflow: "hide",
            }}
          >
            <Tabs handleChangeTab={handleChangeTab} />
          </Box>
        </Grid>
        <TextInput
          value={search}
          fullWidth={isMobile}
          onChange={(e) => {
            handleSearchChange(String(e.target.value));
          }}
          label="Buscar"
          InputProps={{
            sx: { height: "48px" },
            startAdornment: (
              <SearchIcon fontSize="small" sx={styles.searchIcon} />
            ),
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{
                  visibility: search.length > 0 ? "visible" : "hidden",
                }}
                onClick={handleSearchClear}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Grid>
    </TabContext>
  );
};

export default MemberShipToolbar;
