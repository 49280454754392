import { Grid } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";

import { TextInput } from "../../Inputs";
import { FormType } from "../DetailMember";

interface IProps {
  edit: boolean;
  hook: UseFormReturn<FormType>;
}

export default function Evaluations({ edit, hook }: IProps) {
  return (
    <Grid container>
      <Grid container columnSpacing={6} rowSpacing={3} item>
        <Grid item xs={12} sm={8.2}>
          <Controller
            control={hook.control}
            name="partnerComments"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Evaluación partner"
                disabled={edit}
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error?.message
                    ? fieldState.error?.message
                    : "Caracteres " + field.value.length + " /300"
                }
                fullWidth
                multiline
                rows={4}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
