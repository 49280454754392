import { ArrowForward, Search } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import { TextFieldAutoComplete } from "../../components/Inputs/TextInput/styled";
import MainLayout from "../../components/MainLayout";
import NewsFeedDetail from "../../components/NewsFeedDetail/NewsFeedDetail";
import { useDebounce } from "../../hooks/useDebounce";
import { getNewsFeed } from "../../services/newsFeed";
import { INewsFeeds } from "../DashboardMember/types";
import ItemResourceLibrary from "../ResourceLibrary/ItemResourceLibrary/ItemResourceLibrary";

import { NewsFeedStyles } from "./styles";

const TWO_SECONDS_IN_MILLISECONDS = 1000;

const NewsFeed = () => {
  const { id } = useParams() as any;

  const [searchKey, setSearchKey] = useState<string>("");

  const debounceSearch = useDebounce(searchKey, TWO_SECONDS_IN_MILLISECONDS);
  const [newsArray, setNewsArray] = useState<INewsFeeds[]>([]);

  const [limitPage, setLimitPage] = useState<number>(10);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    if (!id) {
      fetchNews();
    }
  }, []);

  useEffect(() => {
    fetchNews();
  }, [page, debounceSearch]);

  const fetchNews = async () => {
    setLoading(true);
    const result = await getNewsFeed(page, limitPage, searchKey);
    if (result.data) {
      const news = result.data.values;
      const meta = result.data.meta;
      setTotalResults(meta.pagination.total);
      setLimitPage(meta.pagination.pageSize);
      setNewsArray(news);
    }
    setLoading(false);
  };

  const handleChangePage = (_: any, value: number) => {
    setCurrentPage(value);
  };
  const handleChangeLimit = (event: any) => {
    const value = event.target.value as number;
    setLimitPage(value);
  };

  const handleSearchKeyChange = () => {
    fetchNews();
  };

  const handleInputKeyDown = (event: any) => {
    if (event.key === "Enter") {
      fetchNews();
    }
  };

  function handleFilterSearch(value: string) {
    setCurrentPage(0);
    setSearchKey(value);
  }

  return (
    <>
      <PageTitle title="Avisos" />
      <MainLayout sx={{ flexWrap: "wrap", display: "wrap" }}>
        <Box sx={NewsFeedStyles}>
          {id ? (
            <NewsFeedDetail id={id} />
          ) : (
            <>
              <Grid
                container
                sx={{
                  padding: {
                    md: "40px 72px 20px 72px",
                    xs: "20px 16px 20px 16px",
                  },
                }}
              >
                <Grid item xs={12} lg={4}>
                  <TextFieldAutoComplete
                    onKeyDown={handleInputKeyDown}
                    value={searchKey}
                    onChange={(event) =>
                      handleFilterSearch(event.currentTarget.value)
                    }
                    label="Buscar"
                    placeholder="Ingresa un título para buscar..."
                    fullWidth
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search sx={{ color: "#8BB3D8" }} fontSize="small" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleSearchKeyChange}>
                            <ArrowForward sx={{ color: "#8BB3D8" }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    spacing={1}
                    direction="row"
                    marginTop={"24px"}
                    marginBottom="24px"
                  >
                    <Typography className="resultsNumber">
                      {totalResults}
                    </Typography>
                    <Typography className="resultsText">Resultados</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Box minHeight={"60vh"}>
                    {loading ? (
                      <Box
                        width={"100%"}
                        display={"flex"}
                        height={"100%"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Grid container spacing={2}>
                        {newsArray.map((i, index) => (
                          <Grid item xs={12} sm={6} lg={4} key={index}>
                            <ItemResourceLibrary
                              key={index}
                              author={i.author}
                              categories={[]}
                              date={i.publishedAt}
                              description={i.descripcion}
                              image={i?.images[0]}
                              title={i.titulo}
                              urlRedirect={`/news-feed/${i.id}`}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                    {newsArray.length <= 0 && !loading && (
                      <Box
                        width={"100%"}
                        display={"flex"}
                        minHeight={"60vh"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Typography variant="h5">
                          No hay ningún registro
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  {newsArray.length > 0 && (
                    <Stack
                      display="flex"
                      direction="row"
                      justifyContent="flex-end"
                    >
                      <TablePagination
                        page={page}
                        count={totalResults}
                        rowsPerPage={limitPage}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeLimit}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}–${to} de ${
                            count !== -1 ? count : `mas de ${to}`
                          }`
                        }
                        labelRowsPerPage="Feeds por página"
                        sx={{ border: "none", padding: "0", margin: "0" }}
                      />
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </MainLayout>
    </>
  );
};

export default NewsFeed;
