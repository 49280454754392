import BallotIcon from "@mui/icons-material/Ballot";
import { Button } from "@mui/material";
import { Dispatch, FC, SetStateAction, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import SplitButton from "../../../../../../../components/SplitButton/SplitButton";
import { useLoading } from "../../../../../../../hooks/useLoading";
import { useSelector } from "../../../../../../../redux/typedHooks";
import { postReportFile } from "../../../../../../../services/startup";
import { displayToast } from "../../../../../../../utils/helpers/displayToast";

interface IProps {
  setReload: Dispatch<SetStateAction<boolean>>;
  reload: boolean;
  periodicity: boolean;
  openPeriodicity: () => void;
  isMobile?: boolean;
}

export const ButtonUploadReport: FC<IProps> = ({
  setReload,
  reload,
  periodicity,
  openPeriodicity,
  isMobile,
}) => {
  const { id } = useParams() as { id: string };
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const { handleClose, handleOpen } = useLoading();

  const ref = useRef<HTMLInputElement>(null);

  async function handleUploadReportFile(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const maxFileSize = 26355054;
    const fileReceived = event.target.files;

    if (fileReceived?.length) {
      const file = fileReceived[0];
      if (file.size > maxFileSize) {
        displayToast("error", `El archivo excede los 25 MB`);
        return;
      }
      try {
        const reportFile = new FormData();
        reportFile.append("startupId", id);
        reportFile.append("file", file);
        reportFile.append("type", "reports");
        reportFile.append("userId", user._id);
        reportFile.append("date", new Date().toString());
        handleOpen();
        await postReportFile(reportFile);
        displayToast("success", `Reporte cargado exitosamente`);
      } catch (error) {
        displayToast("error", `Hubo un error al subir reporte`);
      } finally {
        setReload(!reload);
        handleClose();
      }
    }
  }

  function handleUploadFile() {
    if (!periodicity) {
      openPeriodicity();
      return;
    } else {
      ref.current?.click();
    }
  }

  return (
    <>
      <input
        ref={(e) => ((ref as any).current = e)}
        type="file"
        hidden
        accept={".pdf"}
        onChange={handleUploadReportFile}
      />
      {isMobile ? (
        <SplitButton
          icon={<BallotIcon />}
          title="Subir archivo"
          isMobile={isMobile}
          options={[
            {
              label: "Subir Archivo",
              visible: true,
              onClick: handleUploadFile,
            },
          ]}
        />
      ) : (
        <Button
          sx={{ backgroundColor: "#74C24A", width: "162px", color: "white" }}
          variant="contained"
          onClick={handleUploadFile}
        >
          Subir Archivo
        </Button>
      )}
    </>
  );
};
