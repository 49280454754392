import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
} from "@mui/x-data-grid";
import { NavLink } from "react-router-dom";

import { CustomSort } from "../../components/DataGrid";
import Ellipsis from "../../utils/components/ellipsis";
import { translateStatusStartups } from "../../utils/text/translates";

export const columns = (): GridColDef[] => [
  {
    headerName: "Startup",
    field: "name",
    hideSortIcons: true,
    minWidth: 300,
    flex: 0.7,
    align: "left",
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            columnWidth={200 || colDef?.width}
            description={colDef.description}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
    renderCell: (data) => {
      const { id } = data;
      let url = "";
      id ? (url = `details-startup/${id}`) : (url = "");
      return (
        <Ellipsis
          value={
            <NavLink style={{ color: "#4A88C2" }} to={url}>
              {data.row.name}
            </NavLink>
          }
        />
      );
    },
  },
  {
    headerName: "Industria o rubro",
    field: "industry",
    align: "left",
    hideSortIcons: true,
    minWidth: 145,

    flex: 0.7,
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "País",
    field: "country",
    hideSortIcons: true,
    minWidth: 145,

    flex: 0.7,
    align: "left",
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "Estatus",
    field: "status",
    hideSortIcons: true,
    minWidth: 170,

    flex: 0.7,
    align: "left",
    renderCell: (data) => {
      return translateStatusStartups(data.row.status);
    },
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
];
