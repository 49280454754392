import React from "react";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";

import UsersList from "./UsersList";

const Admin: React.FC = () => {
  return (
    <>
      <PageTitle title="Lista de Usuarios" />
      <MainLayout sx={{ flexDirection: "column", justifyContent: "start" }}>
        <UsersList />
      </MainLayout>
    </>
  );
};

export default Admin;
