import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { InlineWidget } from "react-calendly";
import { useHistory, useLocation } from "react-router-dom";

import stockImage from "../../assets/images/final-screen-image.png";
import logo from "../../assets/images/logo-color_negro.png";
import { Logo } from "../../components/MemberApplicationLayout/styles";
import { clearApplicationValues } from "../../redux/actions/member";
import { openModal } from "../../redux/actions/modal";
import { useDispatch } from "../../redux/typedHooks";

import { styles } from "./styles";

const Calendly = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [eventFinalized, setEventFinalized] = useState<boolean>(false);

  const url = location.search.split("?url=")[1];

  const breakpoint = useMediaQuery("(max-width: 960px)");
  const breakpointMd = useMediaQuery("(max-width: 1920px)");

  const handleFinish = () => {
    dispatch(
      openModal({
        title: "¿Ya agendaste tu cita?",
        description:
          "Si ya has realizado esta acción, puedes finalizar el proceso; de lo contrario agenda tu cita ahora mismo. Sin ella, no podrás continuar con el proceso.",
        secondaryButtonText: "REGRESAR",
        primaryButtonText: "FINALIZAR",
        action: () => {
          //history.push("/login");
          setEventFinalized(true);
          dispatch(clearApplicationValues());
        },
      }),
    );
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleMoreInfo = () => {
    window.location.replace("https://www.angelhub.mx/candidatos");
  };

  const handleGoToHome = () => {
    window.location.replace("https://www.angelhub.mx/");
  };

  if (!url) {
    return null;
  }

  return (
    <Box sx={styles.screen}>
      {eventFinalized ? (
        <>
          <Box sx={styles.finalScreenPadding}>
            <Logo
              src={logo}
              alt="logo"
              sx={{
                marginTop: {
                  xs: "1rem",
                  md: "2rem",
                },
              }}
            />
            <Typography sx={styles.finalTitle}>
              ¡Estás a un paso de ser miembro del club AngelHub!
            </Typography>
            <Typography sx={styles.finalSubtitle}>
              Tu entrevista fue agendada, a continuación te explicamos los
              siguientes pasos en tu proceso:
            </Typography>
            <Typography
              sx={{
                ...styles.finalSectionTitle,
                marginTop: {
                  xs: "40px",
                  md: "37px",
                  xl: "40px",
                },
              }}
            >
              Revisa tu correo
            </Typography>
            <Typography sx={styles.finalSectionText}>
              Revisa el buzón de tu correo, ahí recibirás la confirmación de tu
              entrevista.
            </Typography>
            <Typography sx={styles.finalSectionTitle}>
              Confirma tu entrevista
            </Typography>
            <Typography sx={styles.finalSectionText}>
              Confirma la cita de tu entrevista desde tu correo.
            </Typography>
            <Typography sx={styles.finalSectionTitle}>
              Agrega la entrevista a tu calendario
            </Typography>
            <Typography sx={styles.finalSectionText}>
              Agrega la cita a tu calendario para que te recuerde de tu
              entrevista.
            </Typography>
            <Typography
              sx={{
                ...styles.finalSectionText,
                marginTop: {
                  xs: "24px",
                  md: "15px",
                  xl: "48px",
                },
                paddingRight: {
                  md: "53rem",
                },
              }}
            >
              Si no recibes tu correo en un lapso máximo de 15 minutos, por
              favor contáctanos a soporte@angelhub.mx
            </Typography>
            <Stack
              display="flex"
              justifyContent="space-between"
              maxWidth="23rem"
              spacing={breakpoint ? 2 : 0}
              sx={{
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                marginTop: {
                  xs: "40px",
                  md: "48px",
                  xl: "35px",
                },
              }}
            >
              <Button variant="contained" onClick={handleGoToHome}>
                IR AL HOME
              </Button>
              <Button variant="outlined" onClick={handleMoreInfo} autoFocus>
                MÁS INFORMACIÓN
              </Button>
            </Stack>
          </Box>
          <Box
            position={breakpoint ? "relative" : "absolute"}
            bottom={0}
            sx={{
              marginTop: {
                xs: "80px",
                md: 0,
              },
              marginBottom: {
                xs: "58.6px",
                md: 0,
              },
              right: {
                md: 330,
                xl: 230,
              },
              paddingX: {
                xs: "4.2%",
                md: 0,
              },
            }}
          >
            <img
              alt="stock"
              src={stockImage}
              width={breakpoint ? "100%" : breakpointMd ? "500px" : "843px"}
            />
          </Box>
        </>
      ) : (
        <>
          <Box sx={styles.centeredComponent}>
            <Typography sx={styles.title}>Agenda tu entrevista</Typography>
            <Typography sx={styles.text}>
              Revisa nuestros calendarios para que veas la disponibilidad de
              nuestro equipo para agendar una entrevista.
            </Typography>
          </Box>
          <InlineWidget
            styles={{
              ...styles.calendlyIframe,
              paddingInline: `${breakpoint ? "4.2%" : "0%"}`,
            }}
            url={url}
          />
          <Box sx={{ ...styles.centeredComponent, ...styles.buttonContainer }}>
            <Button
              variant="text"
              startIcon={<ArrowBack />}
              color="info"
              onClick={handleBack}
            >
              REGRESAR
            </Button>
            <Button variant="contained" onClick={handleFinish}>
              Finalizar
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Calendly;
