import { paletteColors } from "../../../../styles/theme";

export const stylesModal = {
  ModalTitle: {
    fontSize: "20px",
    color: `${paletteColors.grayTitle}`,
    margin: "auto",
  },
  Modal: {
    position: "absolute",
    top: "50%",
    left: "51%",
    transform: "translate(-50%, -50%)",
    maxWidth: "704px",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "12px 35px 12px 20px",
    marginTop: "10px",
  },
  ModalCustomHeightForStartup: {
    "@media only screen and (max-width: 700px)": {
      marginTop: "180px",
      width: "100%",
      left: "53.8%",
      padding: "12px 35px 12px 25px",
    },
  },
  ModalHight: {
    "@media only screen and (max-width: 320px)": {
      marginTop: "100px",
    },
  },
  ModalWidth: {
    "@media only screen and (max-width: 700px)": {
      width: "100vw",
      left: "53.8%",
      padding: "12px 35px 12px 25px",
    },
  },
};
