import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { GridSortDirection, useGridApiContext } from "@mui/x-data-grid";
import React from "react";

import styles from "./styles";

interface ICustomSort {
  id: string;
}

/**
 * Componente que organiza los valores de una columna alternando entre ascendente o descendente
 * @param {string} id EL id de la columna que se va a organizar
 * @returns
 */

const CustomSort: React.FC<ICustomSort> = ({ id }) => {
  const apiRef = useGridApiContext();
  const setAscendingSort = () => {
    apiRef.current.setSortModel([
      {
        field: id,
        sort: "asc" as GridSortDirection,
      },
    ]);
  };
  const setDescendingSort = () => {
    apiRef.current.setSortModel([
      {
        field: id,
        sort: "desc" as GridSortDirection,
      },
    ]);
  };

  const currentSorting = apiRef.current.getSortModel()[0];
  const activeSorting = currentSorting?.field === id;
  return (
    <Box sx={styles.CustomSortContainer} key={id}>
      <div className="MuiDataGrid-iconButtonContainer">
        {currentSorting?.sort === "desc" ? (
          <IconButton size="small" onClick={setDescendingSort}>
            <ArrowDropUp
              className="MuiDataGrid-sortIcon"
              style={{ opacity: activeSorting ? 1 : 0.5 }}
            />
          </IconButton>
        ) : (
          <IconButton size="small" onClick={setAscendingSort}>
            <ArrowDropDown
              className="MuiDataGrid-sortIcon"
              style={{ opacity: activeSorting ? 1 : 0.5 }}
            />
          </IconButton>
        )}
      </div>
    </Box>
  );
};

export default CustomSort;
