import axios, { AxiosRequestConfig } from "axios";

import { INewsFeed } from "../components/NewsFeedDetail/type";
import { getToken } from "../config";

export async function getResourceLibraryById(id: string): Promise<any> {
  const token = getToken();

  const url = `/dashboard/resource-library/${id}`;

  const config: AxiosRequestConfig = {
    url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios(config);
}

export async function getNewsFeedById(id: string): Promise<INewsFeed> {
  const token = getToken();

  const url = `/dashboard/news-feed/${id}`;

  const config: AxiosRequestConfig = {
    url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data: respData } = await axios(config);

  const { data } = respData;

  const { firstname: firstNameCreatedBy, lastname: lastNameCreatedBy } =
    data.attributes.createdBy.data.attributes;

  const { firstname: firstNameUpdatedBy, lastname: lastNameUpdatedBy } =
    data.attributes.updatedBy.data.attributes;

  const finalData: INewsFeed = {
    titulo: data.attributes.titulo,
    descripcion: data.attributes.descripcion,
    contenidoPrincipal: data.attributes.contenidoPrincipal,
    urlExterno: data.attributes.urlExterno,
    contenidoSecundario: data.attributes.contenidoSecundario,
    url: data.attributes.url,
    active: data.attributes.active,
    autor: data.attributes.autor,
    tag: data.attributes.tag,
    file: data.attributes.file.data
      ? data.attributes.file.data.map((i: any) => ({
          url: i.attributes.url,
          name: i.attributes.name,
        }))
      : [],
    createdAt: data.attributes.createdAt,
    updatedAt: data.attributes.updatedAt,
    publishedAt: data.attributes.publishedAt,
    createdBy: `${firstNameCreatedBy} ${lastNameCreatedBy}`,
    updatedBy: `${firstNameUpdatedBy} ${lastNameUpdatedBy}`,
  };

  return finalData;
}

export function getResourceLibrary(
  page: number,
  limit: number,
  category?: string[],
  typeContent?: string[],
  title?: string,
): Promise<any> {
  const token = getToken();

  const url = `/dashboard/resource-library?page=${page + 1}&limit=${limit}`;

  const config: AxiosRequestConfig = {
    url,
    method: "POST",
    data: {
      category,
      typeContent,
      title,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
