import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { RibbonContainer, Ribbon } from "react-ribbons";
import { useHistory } from "react-router-dom";

import { filterIconsFromText } from "../../../utils/helpers/filterIcons";
import validMp4 from "../../../utils/validations/mp4";

import { IItemResourceLibraryProps } from "./IItemResourceLibraryProps";
import LibraryImg from "./Image.svg";
import { styles } from "./styles";

/* Este componente es reutilizado en la pantalla de news-feed */
const ItemResourceLibrary: React.FC<IItemResourceLibraryProps> = (props) => {
  const { author, categories, date, description, image, title, urlRedirect } =
    props;

  const [imageToShow, setImageToShow] = useState("");
  const history = useHistory();

  const imageShow = image ?? LibraryImg;
  const isVideo = validMp4(imageShow);

  const showRibbon = dayjs(date).isAfter(dayjs().subtract(12, "days"));
  const publishedDate = `${dayjs(date).format("DD MMM YYYY")}`;

  const MAX_CHARACTERS_DESCRIPTION = 120;
  const MAX_CHARACTERS_TITLE = 40;

  const showDescriptionThreePoints =
    description.length > MAX_CHARACTERS_DESCRIPTION;

  const showTitleThreePoints = title.length > MAX_CHARACTERS_TITLE;

  useEffect(() => {
    if (isVideo) {
      setImageToShow(LibraryImg);
    } else {
      setImageToShow(imageShow);
    }
  }, [isVideo]);

  const showArticle = () => {
    window.open(urlRedirect);
  };

  return (
    <RibbonContainer>
      {showRibbon && (
        <Box sx={styles.ribbonContainer}>
          <Ribbon
            side="right"
            type="edge"
            size="normal"
            backgroundColor="#E87C2E"
            color="#fff"
            fontFamily="Product Sans"
            withStripes={true}
          >
            Nuevo
          </Ribbon>
        </Box>
      )}
      <Card sx={styles.card}>
        <CardMedia
          component={"img"}
          height="220"
          width={"100%"}
          image={imageToShow}
        />
        <CardContent sx={{ paddingBottom: "0", flex: 1 }}>
          <Typography sx={styles.title}>
            {filterIconsFromText(title).slice(0, MAX_CHARACTERS_TITLE)}
            {showTitleThreePoints && "... "}
          </Typography>

          <Stack direction="row">
            <Typography sx={styles.autor}>{author}</Typography>
            <Typography sx={{ ...styles.autor, ...styles.separator }}>
              •
            </Typography>
            <Typography sx={styles.autor}>{publishedDate}</Typography>
          </Stack>
          <Typography sx={styles.text}>
            {filterIconsFromText(description.trim()).slice(
              0,
              MAX_CHARACTERS_DESCRIPTION,
            ) + " "}
            {showDescriptionThreePoints && "... "}
          </Typography>
          {Boolean(categories.length) && (
            <Box display={"flex"} gap="16px" marginTop="16px" flexWrap={"wrap"}>
              {categories.map((category: string) => (
                <Box key={category} sx={styles.chip}>
                  <Typography sx={styles.chipText}>{category}</Typography>
                </Box>
              ))}
            </Box>
          )}
        </CardContent>
        <CardActions
          sx={{ flex: 0, justifyContent: "flex-end", display: "flex" }}
        >
          <Button
            sx={styles.link}
            color="info"
            onClick={showArticle}
            size="small"
          >
            Leer
          </Button>
        </CardActions>
      </Card>
    </RibbonContainer>
  );
};

export default ItemResourceLibrary;
