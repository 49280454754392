import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, FormHelperText, Grid, Modal, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { useHistory } from "react-router-dom";

import { useLoading } from "../../../../hooks/useLoading";
import { ERoles } from "../../../../pages/routes/role-catalog";
import { reloadReducerUser } from "../../../../redux/actions/admin";
import { openModal, PayloadOpenModal } from "../../../../redux/actions/modal";
import { useDispatch } from "../../../../redux/typedHooks";
import { createUser } from "../../../../services/users";
import { TextInput } from "../../../Inputs";

import { createAdminSchema, ICreateAdmin } from "./schemas";
import { stylesModal } from "./stylesModal";

interface IProps {
  handleOpenModal: boolean;
  handleCloseModal: () => void;
}

type FormType = {
  firstName: string;
  lastName: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
  position: string;
  linkedIn: string;
};

export default function CreateAdmin({
  handleOpenModal,
  handleCloseModal,
}: IProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleClose, handleOpen } = useLoading();

  const hook = useForm<FormType>({
    resolver: yupResolver(createAdminSchema),
    mode: "all",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneCountryCode: "",
      phoneNumber: "",
      position: "",
      linkedIn: "",
    },
  });

  function handleSuccessModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Correcto",
      description: `Usuario creado satisfactoriamente`,
      action: () => history.push("/usuarios"),
      hideSecondaryButton: true,
      primaryButtonText: "Aceptar",
    };
    dispatch(openModal(modalConfig));
  }

  const handleCancel = () => {
    handleCloseModal();
    hook.reset();
  };

  async function onSubmit(values: any): Promise<void> {
    try {
      handleOpen();
      const finalValues: ICreateAdmin = {
        ...values,
        phoneCountryCode: values.phoneCountryCode,
        phoneNumber: values.phoneNumber.slice(values.phoneCountryCode.length),
        role: ERoles.RL005,
      };
      await createUser(finalValues);
      hook.reset();
      dispatch(reloadReducerUser());
      handleSuccessModal();
      handleCloseModal();
    } catch (err: any) {
      const message = err?.data?.message;
      if (message === "Ya existe usuario con ese número de teléfono") {
        hook.setError("phoneNumber", { message });
      }
      if (message === "Ya existe usuario con ese correo") {
        hook.setError("email", { message });
      }
    } finally {
      handleClose();
    }
  }

  return (
    <Modal open={handleOpenModal} onClose={handleCancel}>
      <Grid
        container
        sx={{
          ...stylesModal.Modal,
          ...stylesModal.ModalWidth,
          maxWidth: "704px",
        }}
        justifyContent={{ xs: "center", sm: "left" }}
        spacing={2}
      >
        <Grid item xs={12} sm={12}>
          <Typography sx={stylesModal.ModalTitle}>
            Crear Administrador
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={hook.control}
            name="firstName"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Nombres"
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={hook.control}
            name="lastName"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Apellidos"
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={hook.control}
            name="email"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Correo"
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={hook.control}
            name="phoneNumber"
            render={({ field, fieldState }) => (
              <>
                <PhoneInput
                  country="mx"
                  value={field.value}
                  onChange={(number, countryData: CountryData) => {
                    hook.setValue("phoneCountryCode", countryData.dialCode);
                    hook.setValue("phoneNumber", number);
                    field.onChange(number);
                  }}
                  buttonStyle={
                    fieldState.error ? { border: "2px solid #f44336" } : {}
                  }
                  inputStyle={
                    fieldState.error
                      ? {
                          border: "2px solid #f44336",
                          height: "48px",
                          width: "100%",
                        }
                      : { height: "48px", width: "100%" }
                  }
                />
                {Boolean(fieldState.error) && (
                  <FormHelperText error={Boolean(fieldState.error?.message)}>
                    {fieldState.error?.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={hook.control}
            name="position"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Puesto"
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={hook.control}
            name="linkedIn"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="LinkedIn"
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent={{ xs: "center", sm: "right" }}
          sx={{ marginY: "20px" }}
        >
          <Button onClick={handleCancel} variant="outlined">
            Cancelar
          </Button>
          <LoadingButton
            onClick={hook.handleSubmit(onSubmit)}
            variant="contained"
            sx={{ marginLeft: "10px" }}
          >
            Crear
          </LoadingButton>
        </Grid>
      </Grid>
    </Modal>
  );
}
