import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";

import StandardDialog from "../../StandardDialog";

import { IModalSeeAllProps } from "./IModalSeeAllProps";

const ModalSeeAll: React.FC<IModalSeeAllProps> = (props) => {
  const { onClose, open, title, subtitle, children } = props;
  return (
    <StandardDialog open={open} maxWidth="sm" fullWidth>
      {title && (
        <DialogTitle
          sx={{
            fontFamily: "Product Sans Medium !important",
            color: "#515151",
            fontSize: "20px",
            fontWeight: "500",
          }}
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent>
        {subtitle && <DialogContentText>{subtitle}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{ color: "#2196F3", borderColor: "#2196F3 !important" }}
          onClick={() => onClose()}
        >
          Cerrar
        </Button>
      </DialogActions>
    </StandardDialog>
  );
};

export default ModalSeeAll;
