import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
} from "@mui/x-data-grid";
import moment from "moment";
import { NavLink } from "react-router-dom";

import { CustomSort } from "../../components/DataGrid";
import Ellipsis from "../../utils/components/ellipsis";
import { translateStatusApplications } from "../../utils/text/translates";

import { EStatusAppointment } from "./constants";

export const columns: GridColDef[] = [
  {
    headerName: "Nombre completo",
    field: "firstName",
    flex: 0.7,
    hideSortIcons: true,
    minWidth: 300,
    align: "left",
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
    renderCell: (data) => {
      const { id } = data;
      let url = "";
      id ? (url = `details-member/${id}`) : (url = "");

      return (
        <Ellipsis
          value={
            <NavLink style={{ color: "#4A88C2" }} to={url}>
              {data.row.firstName + data.row.lastName}
            </NavLink>
          }
        />
      );
    },
  },
  {
    headerName: "Fecha de Aplicación",
    field: "dateApplication",
    flex: 1,
    align: "left",
    hideSortIcons: true,
    minWidth: 160,
    maxWidth: 160,
    renderCell(params) {
      return params.row.dateApplication
        ? moment(params.row.dateApplication).format("YYYY-MM-DD")
        : "";
    },
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "Estatus",
    field: "status",
    flex: 1,
    align: "left",
    hideSortIcons: true,
    minWidth: 120,
    maxWidth: 120,
    renderCell: (data) => {
      return translateStatusApplications(data.row.status);
    },
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "Asignación",
    field: "partner",
    flex: 1,
    align: "left",
    hideSortIcons: true,
    minWidth: 245,
    maxWidth: 245,
    valueGetter: (params) => {
      return params.row.partner?.firstName && params.row.partner?.lastName
        ? `${params.row.partner?.firstName} ${params.row.partner?.lastName}`
        : "Por asígnar";
    },
    renderCell(params) {
      return params.row.partner?.firstName && params.row.partner?.lastName ? (
        <Ellipsis
          value={`${params.row.partner?.firstName} ${params.row.partner?.lastName}`}
        />
      ) : (
        "Por asígnar"
      );
    },
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "Correo",
    field: "email",
    flex: 1,
    align: "left",
    hideSortIcons: true,
    minWidth: 400,
    maxWidth: 400,
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },

  {
    headerName: "Fecha de Cita",
    field: "dateAppointment",
    flex: 1,
    minWidth: 120,
    maxWidth: 120,
    hideSortIcons: true,
    align: "left",
    renderCell: (data) => {
      const appointments = data.row.appointments as any[];
      if (!appointments || appointments.length <= 0) {
        return "Sin agendar";
      }
      const appointment = appointments.find(
        (i) => i.status === EStatusAppointment.ACCEPTED,
      );
      if (!appointment) {
        return "Sin agendar";
      }
      return moment(appointment.date).format("YYYY-MM-DD");
    },
    valueGetter: (data) => {
      const appointments = data.row.appointments as any[];
      if (!appointments || appointments.length <= 0) {
        return "Sin agendar";
      }
      const appointment = appointments.find(
        (i) => i.status === EStatusAppointment.ACCEPTED,
      );
      if (!appointment) {
        return "Sin agendar";
      }
      return moment(appointment.date).format("YYYY-MM-DD");
    },
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "Nacionalidad",
    field: "nationalityCountry",
    flex: 1,
    hideSortIcons: true,
    minWidth: 170,
    maxWidth: 170,
  },
];
