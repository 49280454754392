import { EStatusReleasedOpportunity } from "../../constants";
import { ERolesCode } from "../routes/role-catalog";

export const VALID_ROLES_TO_SHOW_TABS = [
  ERolesCode.ADMINISTRATOR,
  ERolesCode.ANALYST,
  ERolesCode.SUPER_ADMIN,
  ERolesCode.PARTNER,
  ERolesCode.MEMBER,
];
export const VALID_STATUS_TO_SHOW_MOIC = [
  EStatusReleasedOpportunity.IN_REVIEW,
  EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT,
  EStatusReleasedOpportunity.IN_INVESTMENT_PROCESS,
  EStatusReleasedOpportunity.INVESTED,
];
