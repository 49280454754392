import { SxProps, Theme } from "@mui/material";

const InvestmentOpportunityTableStyles: SxProps<Theme> = (theme: Theme) => ({
  height: "335px",
  "& .MuiTypography-root.MuiTypography-h5": {
    color: "#515151",
    fontFamily: "Product Sans Medium !important",
    letterSpacing: ".5px",
  },
  "& .MuiDataGrid-root": {
    borderRadius: 0,
    border: "none",
    "& .MuiDataGrid-columnHeaderTitle ": {
      fontFamily: "Product Sans Medium !important",
    },
  },
  "& .MuiDataGrid-footerContainer": {
    display: "none",
  },
  "& .MuiLink-root": {
    color: "#0085FF",
  },
});

export default InvestmentOpportunityTableStyles;
