import { Button, Grid, Link, Typography, Divider } from "@mui/material";
import { AxiosError } from "axios";
import { useState, KeyboardEvent, createRef } from "react";
import { RouteChildrenProps } from "react-router";

import Form, { IFields, IFieldSchema } from "../../../components/Form";
import { TextInput } from "../../../components/Inputs";
import { resetPasswordRequest } from "../../../services/resetPassword";
import { displayToast } from "../../../utils/helpers/displayToast";

const classes = {
  titleForward: {
    opacity: 1,
    textAlign: "center",
    color: "#5D5D5D",
    fontSize: "24px",
    marginTop: "40px !important",
  } as const,
  generalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    alignItems: "center",
  } as const,
  textGrid: {
    marginBottom: "1rem",
  } as const,
  containerOr: {
    flex: "1",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  } as const,
  textOr: {
    paddingLeft: "15px",
    paddingRight: "15px",
    color: "#5D5D5D",
    fontSize: "12px",
    opacity: "1",
  } as const,
  description: {
    textAlign: "center",
    overflow: "hidden",
    color: "#5D5D5D",
    fontSize: "16px",
    marginBottom: "24px",
    marginTop: "11px",
  } as const,
  detail: {
    width: "100%",
    whiteSpace: "initial",
    fontWeight: "bold",
    fontSize: "12px",
  } as const,
  sendMailButton: {
    width: "100%",
    height: "2.3rem",
    textTransform: "none",
    marginTop: "42px",
  } as const,
  mailInput: {
    width: "100%",
    padding: "0px",
  } as const,
  returnToLoginOptions: {
    fontSize: "0.8rem",
    textDecoration: "none",
    fontWeight: "bold",
  } as const,
  blueReturnToLoginButton: {
    fontWeight: "bold",
    cursor: "pointer",
    color: "#0052CC",
  } as const,
  grayReturnToLoginButton: {
    cursor: "pointer",
    color: "#5D5D5D",
  } as const,
  divider: {
    height: "1px",
    flex: "1",
    width: "100%",
    color: "#000000",
  } as const,
  containerInstructionSent: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    marginTop: "32px",
    alignItems: "center",
  } as const,
  titleSent: {
    fontWeight: "500",
    opacity: "1",
    textAlign: "center",
    color: "#5D5D5D",
    fontSize: "24px",
    marginBottom: "20px",
  } as const,
  descriptionSent: {
    textAlign: "center",
    overflow: "hidden",
    color: "#5D5D5D",
    fontSize: "16px",
    paddingLeft: "14px",
    marginBottom: "24px",
  } as const,
};

const resetPasswordRequestSchema: IFieldSchema[] = [
  {
    name: "email",
    validations: {
      maxLength: {
        max: 64,
        errorMessage: "El correo no puede tener mas de 64 caracteres",
      },
      emailFormat: {
        errorMessage: "Correo no válido",
      },
      required: {
        errorMessage: "Ingresa tu correo",
      },
    },
    value: "",
  },
];

interface IMessage {
  message: string;
  severity: string;
  email: string;
}

interface IResetPassForm extends RouteChildrenProps {
  returnToLoginClick?: CallableFunction;
}

const ResetPasswordRequestForm: React.FC<IResetPassForm> = (props) => {
  const { history } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<IMessage>({
    message: "",
    severity: "",
    email: "",
  });
  const [fields, setFields] = useState<IFields>({});

  const handleRegister = async (
    fields: IFields,
    isResend?: boolean,
  ): Promise<void> => {
    try {
      setLoading(true);
      const response = await resetPasswordRequest(
        fields.email as unknown as string,
      );

      if (response.data.success) {
        setMessage({
          message:
            "Su solicitud de cambio de contraseña ha sido enviada con éxito.",
          severity: "success",
          email: String(fields.email),
        });
        isResend
          ? displayToast("success", "El email ha sido reenviado exitosamente.")
          : displayToast("success", "El email ha sido enviado exitosamente.");
      } else {
        setMessage({
          message: "Hubo un error en su solicitud",
          severity: "error",
          email: String(fields.email),
        });
        displayToast("error", "Hubo un error en su el email");
      }
      setFields(fields);
    } catch (error: any) {
      const err = error;

      const isFounded = err.data.message === "User Not Found";

      const isConfirmed =
        err?.data?.message ===
        "You need to confirm your email to be able to log in";

      const isActive = err?.data?.message === "Usuario no activo";

      if (
        err?.data?.message ===
        "You need to wait 1 minute to be able to request a new code"
      ) {
        displayToast(
          "error",
          "Necesitas que esperar un minuto antes de poder solicitar otro correo.",
        );
        return;
      }

      if (isActive) {
        setMessage({
          message: "Cuenta esta inactiva.",
          severity: "error",
          email: String(fields.email),
        });
        return;
      }

      if (isFounded) {
        setMessage({
          message: "No se ha podido encontrar tu cuenta.",
          severity: "error",
          email: String(fields.email),
        });
        return;
      }
      if (isConfirmed) {
        setMessage({
          message: "No se ha confirmado este usuario.",
          severity: "error",
          email: String(fields.email),
        });
        displayToast("error", "Necesitas confirmar tu correo electrónico.");
        return;
      }
      setMessage({
        message: "Hubo un error al mandar los correos.",
        severity: "error",
        email: String(fields.email),
      });
    } finally {
      setLoading(false);
    }
  };

  const sendButtonRef = createRef<HTMLButtonElement>();

  return (
    <Grid sx={classes.generalContainer}>
      {message.severity === "success" ? (
        <ContentSuccess message={message} />
      ) : (
        /* Mostrar reset password si no hay mensaje de éxito  */
        <>
          <Grid container sx={classes.generalContainer}>
            <Typography variant="subtitle1" sx={classes.titleForward}>
              ¿Olvidaste tu contraseña?
            </Typography>
            <Typography sx={classes.description}>
              Ingrese su correo electrónico para recibir las instrucciones de
              recuperación de contraseña.
            </Typography>
          </Grid>
          <Form onSubmit={handleRegister} fields={resetPasswordRequestSchema}>
            {({ handleSubmit, handleChange, handleBlur, valid, fields }) => {
              const handleKeyPress = (event: KeyboardEvent) => {
                if (event.key === "Enter" && valid) {
                  handleSubmit();
                }
              };
              return (
                <Grid container direction="column" justifyContent="center">
                  <Grid item sx={{ maxHeight: "56px" }}>
                    <TextInput
                      sx={classes.mailInput}
                      disabled={loading}
                      label="Correo"
                      value={fields.email.value}
                      onChange={handleChange("email")}
                      error={
                        !!fields.email.errors.length ||
                        message.severity === "error"
                      }
                      helperText={
                        message.message !== ""
                          ? message.message
                          : fields.email.errors.join(". ")
                      }
                      fullWidth
                      onKeyPress={handleKeyPress}
                    />
                  </Grid>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    sx={classes.sendMailButton}
                    ref={sendButtonRef}
                  >
                    ENVIAR CORREO
                  </Button>
                </Grid>
              );
            }}
          </Form>
          <Grid
            sx={{ margin: "26px 0 10px 0", width: "100%" }}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid sx={classes.containerOr}>
              <Divider sx={classes.divider} />
              <Typography sx={classes.textOr}> O </Typography>
              <Divider sx={classes.divider} />
            </Grid>
          </Grid>
        </>
      )}
      <Grid
        sx={{ margin: "10px 0" }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Link
          onClick={() => history.push("login")}
          underline="none"
          sx={{
            ...classes.returnToLoginOptions,
            cursor: "pointer",
            color: "#5D5D5D",
          }}
        >
          <Typography
            sx={{
              paddingTop: "0.6rem",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            Regresar al Inicio de sesión
          </Typography>
        </Link>
        {message.severity === "success" && (
          <Grid
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Link
              color="primary"
              underline="none"
              sx={{
                ...classes.returnToLoginOptions,
                cursor: "default",
                marginTop: "0.5rem",
              }}
            >
              <Typography sx={{ color: "#5D5D5D", fontWeight: "bold" }}>
                &nbsp;|&nbsp;{" "}
              </Typography>
            </Link>
            <Link
              onClick={() => handleRegister(fields, true)}
              underline="none"
              sx={{
                ...classes.returnToLoginOptions,
                cursor: "pointer",
                color: "#69BC41",
              }}
            >
              <Typography
                sx={{
                  paddingTop: "0.6rem",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#69BC41",
                }}
              >
                Reenviar correo
              </Typography>
            </Link>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const ContentSuccess: React.FC<any> = ({ message }) => {
  return (
    <Grid sx={classes.containerInstructionSent}>
      <Typography sx={classes.titleSent}>¡Instrucciones enviadas!</Typography>
      <Typography sx={classes.descriptionSent}>
        Le enviamos un correo electrónico con las instrucciones para cambiar su
        contraseña a{" "}
        <b>
          &quot;
          {message.email}&quot;.
        </b>
      </Typography>
      <Typography sx={classes.descriptionSent} style={{ marginTop: 0 }}>
        Si no lo recibe en 5 minutos, revise su bandeja de entrada de correo no
        deseado.
      </Typography>
      <Divider sx={{ ...classes.divider, margin: "10px 0px" }} />
    </Grid>
  );
};

export default ResetPasswordRequestForm;
