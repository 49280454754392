import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
} from "@mui/x-data-grid";

import { CustomSort } from "../DataGrid";

export function standardColumns(columns: GridColDef[]): GridColDef[] {
  return columns.map((i) =>
    i.renderHeader
      ? i
      : {
          ...i,
          renderHeader: (params: GridColumnHeaderParams) => {
            const { colDef } = params;
            return (
              <>
                <GridColumnHeaderTitle
                  label={colDef.headerName || colDef.field}
                  description={colDef.description}
                  columnWidth={200 || colDef?.width}
                />
                <CustomSort id={colDef.field} />
              </>
            );
          },
        },
  );
}
