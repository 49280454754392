/* eslint-disable react-hooks/exhaustive-deps */
import { GridColDef, GridSortItem } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import BaseDataGrid from "../../components/BaseDataGrid/BaseDataGrid";
import { ModalMenu } from "../../components/DataGrid";
import { DEFAULT_ROWS_PAGE_SIZE } from "../../components/DataGrid/constants";
import DialogWantToInvest from "../../components/DialogWantToInvest/DialogWantToInvest";
import OpportunitiesReleasedToolbar from "../../components/Toolbars/OpportunityReleasedInvestorToolbar/OpportunitiesReleasedToolbar";
import { EOptions } from "../../components/Toolbars/OpportunityReleasedInvestorToolbar/Tabs";
import { VencidasType } from "../../components/Toolbars/OpportunityReleasedInvestorToolbar/const";
import { EStatusReleasedOpportunity } from "../../constants";
import { openModal as openModalAction } from "../../redux/actions/modal";
import {
  changeOpportunitiesReleased,
  changeOpportunityReleased,
  fetchOpportunityReleasedPaginated,
} from "../../redux/actions/opportunitiesReleased";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import { opportunitiesReleasedChangeIntention } from "../../services/opportunitiesReleased";
import { displayToast } from "../../utils/helpers/displayToast";
import { VALID_STATUS_TO_INVEST } from "../DetailsStartup/components/TabList/Tabs/InvestRound/validation";
import { ERolesCode } from "../routes/role-catalog";

import { columnsOpportunity } from "./columns";

const OpportunityList: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { opportunities, loading } = useSelector(
    (store) => store.opportunityReleasedReducer,
  );

  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  const [Open, setOpen] = useState<boolean>(false);

  const [Search, setSearch] = useState<string>("");
  const [Stage, setStage] = useState<string>("");
  const [Industry, setIndustry] = useState<string>("");
  const [Country, setCountry] = useState<string>("");
  const Tab = queryParams.get("tab") || EOptions.ALL; //
  const Vencidas: VencidasType =
    (queryParams.get("vencidas") as VencidasType) || ""; //

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_ROWS_PAGE_SIZE);
  // Default sorting
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: "dateStatus",
      sort: "desc",
    },
  ]);

  useEffect(() => {
    dispatch(fetchOpportunityReleasedPaginated(true));
  }, []);

  useEffect(() => {
    setPage(0);
    setPageSize(DEFAULT_ROWS_PAGE_SIZE);
  }, []);

  const renderModalMenu = (data: any) => {
    const userInvestingId = data.row.usersWantToInvest.map((i: any) => i.user);

    //TODOS LOS CASOS
    let detailsOptionsList = [
      {
        name: "Ver detalles",
        action: () => handleDetails(data.id),
      },
    ];

    if (!userInvestingId.includes(String(user._id))) {
      detailsOptionsList.push(
        {
          name: "Me interesa",
          action: () => changeInterested(true, data.row),
        },
        {
          name: "No me interesa",
          action: () => changeInterested(false, data.row),
        },
      );
    }

    const values = data.row;

    const status = values.status;
    const isClosed =
      status === EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT ||
      status === EStatusReleasedOpportunity.INVESTED;

    const validStatusToInvest = VALID_STATUS_TO_INVEST.includes(status);

    // LISTO PARA INVERTIR
    if (status === EStatusReleasedOpportunity.OPEN_TO_INVESTMENT) {
      detailsOptionsList.push({
        name: "Quiero invertir",
        action: () => {
          if (user.member.isActiveMemberShip) {
            dispatch(changeOpportunityReleased(data.id));
            setOpen(true);
          } else
            dispatch(
              openModalAction({
                title: "Advertencia.",
                description:
                  "Su membresía se encuentra vencida, por favor haga el pago correspondiente, adjunte su comprobante de pago a su perfil y contacte a Angel Hub para reactivarse a la brevedad.",
              }),
            );
        },
      });
    }

    if (values.usersInterested.find((i: any) => i.user === user._id)) {
      detailsOptionsList = detailsOptionsList.filter(
        (i) => i.name !== "Me interesa",
      );
    }

    if (values.usersNotInterested.find((i: any) => i === user._id)) {
      detailsOptionsList = detailsOptionsList.filter(
        (i) => i.name !== "No me interesa",
      );
    }

    if (
      values.usersWantToInvest.find((i: any) => i.user === user._id) &&
      validStatusToInvest
    ) {
      detailsOptionsList = detailsOptionsList.filter(
        (i) => i.name !== "Quiero invertir",
      );
    }

    if (isClosed) {
      detailsOptionsList = detailsOptionsList.filter(
        (i) => i.name === "Ver detalles",
      );
    }

    const isMember = user.role.code === ERolesCode.MEMBER;

    const finalOptions = isMember
      ? detailsOptionsList
      : [detailsOptionsList[0]];

    return <ModalMenu menuVert items={finalOptions} />;
  };

  const newColumns = (): GridColDef[] => [
    ...columnsOpportunity(user),
    {
      headerName: "Acciones",
      field: "actions",
      hideSortIcons: true,
      align: "center",
      renderCell: (data: any) => renderModalMenu(data),
    },
  ];

  function handleDetails(id: string) {
    history.push(`details-startup/${id}`);
  }

  async function changeInterested(
    isInterested: boolean,
    data: any,
  ): Promise<void> {
    await opportunitiesReleasedChangeIntention({
      startupId: data._id,
      type: isInterested ? "INTERESTED" : "NOT_INTERESTED",
      user: user._id,
    });
    const copyOpportunity = [...opportunities];
    const index = copyOpportunity.findIndex(
      (i) => String(i._id) === String(data._id),
    );

    if (!isInterested) {
      copyOpportunity[index].usersNotInterested = [
        ...copyOpportunity[index].usersNotInterested,
        user._id,
      ];

      copyOpportunity[index].usersInterested = copyOpportunity[
        index
      ].usersInterested.filter((i: any) => i.user !== user._id);

      copyOpportunity[index].usersWantToInvest = copyOpportunity[
        index
      ].usersWantToInvest?.filter(
        (i: any) => String(i.user) !== String(user._id),
      );
    } else {
      copyOpportunity[index].usersInterested = [
        ...copyOpportunity[index].usersInterested,
        {
          user: user._id,
        },
      ];

      copyOpportunity[index].usersNotInterested = copyOpportunity[
        index
      ].usersNotInterested?.filter((i: any) => String(i) !== String(user._id));

      copyOpportunity[index].usersWantToInvest = copyOpportunity[
        index
      ].usersWantToInvest?.filter(
        (i: any) => String(i.user) !== String(user._id),
      );
    }

    dispatch(changeOpportunitiesReleased(copyOpportunity));
    displayToast("success", "Se a actualizado correctamente tu oportunidad");
  }

  function handleFilter(data: any[], userId: string) {
    let values = data.filter((opportunity: any) => {
      return (
        opportunity.name.toLowerCase().includes(Search.toLowerCase()) || !Search
      );
    });

    if (Stage) {
      values = values.filter((i) => i.stage === Stage);
    }
    if (Industry) {
      values = values.filter((i) => i.industry === Industry);
    }
    if (Country) {
      values = values.filter((i) => i.country === Country);
    }
    if (Tab) {
      if (Tab === EOptions.OPEN) {
        values = values.filter(
          (i) => i.status === EStatusReleasedOpportunity.OPEN_TO_INVESTMENT,
        );
      }

      if (Tab === EOptions.EXPIRED) {
        values = values.filter(
          (i) => i.status === EStatusReleasedOpportunity.EXPIRED,
        );
      }

      if (Tab === EOptions.INTERESTED) {
        values = values.filter((i) => {
          const users = i.usersInterested.map((j: any) => String(j.user));
          return users.includes(userId);
        });
      }
      if (Tab === EOptions.NOT_INTERESTED) {
        values = values.filter((i) => i.usersNotInterested.includes(userId));
      }
    }
    if (Vencidas) {
      const now = dayjs();
      const expirationThresholdExpiredStartups = now.add(7, "days");
      const expirationThresholdNewStartups = now.subtract(7, "days");

      if (Vencidas === "Por Vencer") {
        values = values.filter(
          (i) =>
            expirationThresholdExpiredStartups.isAfter(i.expirationDate) &&
            now.isBefore(i.expirationDate) &&
            i.status === EStatusReleasedOpportunity.OPEN_TO_INVESTMENT,
        );
      }

      if (Vencidas === "Nuevas") {
        values = values.filter(
          (i) =>
            expirationThresholdNewStartups.isBefore(i.createdAt) &&
            now.isAfter(i.createdAt) &&
            i.status === EStatusReleasedOpportunity.OPEN_TO_INVESTMENT,
        );
      }
    }

    return values;
  }

  return (
    <>
      <DialogWantToInvest open={Open} onCancel={() => setOpen(false)} />
      <BaseDataGrid
        page={page}
        pageSize={pageSize}
        onPageChange={(value) => setPage(value)}
        onPageSizeChange={(value) => setPageSize(value)}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        columns={newColumns()}
        loading={loading}
        rows={handleFilter(opportunities, user._id)}
        getRowId={(row) => row._id}
        components={{
          Toolbar: OpportunitiesReleasedToolbar,
        }}
        checkboxSelection
        componentsProps={{
          toolbar: {
            Tab,
            Vencidas,
            onSearchChange: (value: string) => {
              setSearch(value);
            },
            onSearchClear: () => {
              setSearch("");
            },
            onStageChange: (value: string) => {
              setStage(value);
            },
            onIndustryChange: (value: string) => {
              setIndustry(value);
            },
            onCountryChange: (value: string) => {
              setCountry(value);
            },
            resetPage: () => {
              setPageSize(DEFAULT_ROWS_PAGE_SIZE);
              setPage(0);
            },
            hideIcons: true,
          },
        }}
      />
    </>
  );
};

export default OpportunityList;
