import ClearIcon from "@mui/icons-material/Clear";
import { Box, IconButton, SwipeableDrawer } from "@mui/material";

interface IProps {
  onClose: () => void;
  isOpen: boolean;
  children: any;
}

const DrawerNotification: React.FC<IProps> = ({
  onClose,
  isOpen,
  children,
}) => {
  return (
    <>
      {isOpen && (
        <SwipeableDrawer
          anchor={"right"}
          open={isOpen}
          onClose={() => onClose()}
          onOpen={() => null}
        >
          <Box
            sx={{
              width: "380px",
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              backgroundColor: "#F6F8F9 !important",
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "10px",
              }}
            >
              <Box></Box>
              <IconButton onClick={() => onClose()}>
                <ClearIcon />
              </IconButton>
            </Box>
            {children}
          </Box>
        </SwipeableDrawer>
      )}
    </>
  );
};

export default DrawerNotification;
