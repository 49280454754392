interface IStringKey {
  [key: string]: string;
}

export const DATE_FORMAT = "DD/MM/YYYY";
export const PAYROLL_ATTENDANCE_DATE_FORMAT = "YYYY-MM-DD";

export const DRIVER_TYPES = {
  payroll: "nomina",
  route: "ruta",
};

export const DRIVER_TRANSLATION: IStringKey = {
  nomina: "Nómina",
  ruta: "Ruta",
};

export const USER_TYPES: IStringKey = {
  partner: "Socio",
  driver: "Empleado",
};

export const USER_ROLES: IStringKey = {
  RL000: "admin",
  RL001: "coordinator",
};

export const ATTENDANCE_CALENDAR_OPTIONS = {
  ASISTENCIA: { label: "asistencia", color: "#1bc943" },
  FALTA: { label: "falta", color: "red" },
  DESCANSO: { label: "descanso", color: "yellow" },
};

export const DOWNLOAD_TYPES: IStringKey = {
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const ROUTE_STATUS: IStringKey = {
  ACTIVE: "activa",
  CANCELED: "cancelada",
};

export interface ITargetUser {
  _id: string;
  name: string;
  code: string;
}

export enum ECompanyType {
  SAPI = "SAPI",
  SA_DE_CV = "SA DE CV",
  C_CORP = "C-CORP",
  LLC = "LLC",
  CAIMAN_HOLDING = "CAIMAN HOLDING",
  VIRGIN_ISLANDS_HOLDING = "VIRGIN ISLANDS HOLDING",
  NO_CONSTITUIDA = "NO CONSTITUIDA",
  OTRA = "OTRA",
}

export enum EStatusOpportunity {
  //GENERAL STATUS
  RECEIVED = "RECEIVED",

  //ANALYST STATUS
  ASSIGNED_ANALYST = "ASSIGNED_ANALYST",
  RE_ASSIGNED_ANALYST = "RE_ASSIGNED_ANALYST",
  PROGRAMMING_ANALYST = "PROGRAMMING_ANALYST",
  PROGRAMMED_ANALYST = "PROGRAMMED_ANALYST",
  EVALUATED_ANALYST = "EVALUATED_ANALYST",
  //PARTNER STATUS
  ASSIGNED_PARTNER = "ASSIGNED_PARTNER",
  RE_ASSIGNED_PARTNER = "RE_ASSIGNED_PARTNER",
  PROGRAMMING_PARTNER = "PROGRAMMING_PARTNER",
  PROGRAMMED_PARTNER = "PROGRAMMED_PARTNER",
  EVALUATED_PARTNER = "EVALUATED_PARTNER",
  //COMMITTEE STATUS
  ASSIGNED_COMMITTEE = "ASSIGNED_COMMITTEE",
  APPROVED_COMMITTEE = "APPROVED_COMMITTEE",

  // OPTIONAL STATUS
  REJECTED = "REJECTED",
  IS_MONITORING = "IS_MONITORING",
}

export enum EStatusReleasedOpportunity {
  INCOMPLETE = "INCOMPLETE",
  OPEN_TO_INVESTMENT = "OPEN_TO_INVESTMENT",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
  IN_REVIEW = "IN_REVIEW",
  CLOSED_TO_INVESTMENT = "CLOSED_TO_INVESTMENT",
  IN_INVESTMENT_PROCESS = "IN_INVESTMENT_PROCESS",
  INVESTED = "INVESTED",
  PASS = "PASS",
}

export enum EMembershipsPayments {
  AMBASSADOR = "AMBASSADOR",
  ALLIES = "ALLIES",
  LEADANGEL = "LEADANGEL",
  CLASSICAL = "CLASSICAL",
  RE_ACTIVATE_CLASSICAL = "RE_ACTIVATE_CLASSICAL",
}

export enum ETabsMember {
  CLASSICAL = "CLASSICAL",
  AMBASSADOR = "AMBASSADOR",
  ALLIES = "ALLIES",
  LEADANGEL = "LEADANGEL",
  EXPERIENCIA = "EXPERIENCIA",
}
