import { Box, Card, CircularProgress, Grid } from "@mui/material";
import { Chart, ArcElement } from "chart.js";
import { FC, useEffect, useState } from "react";
import { RouteChildrenProps } from "react-router-dom";

import Calendar from "../../components/Dashboard/Calendar";
import Notices from "../../components/Dashboard/Notices";
import PlatformActivity from "../../components/Dashboard/PlatformActivity";
import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import {
  getDashboardMember,
  getDashboardMemberRanking,
} from "../../services/member";

import InvestmentOpportunity from "./InvestmentOpportunity";
import InvestmentOpportunityTable from "./InvestmentOpportunityTable";
import MyPortfolio from "./MyPortfolio/MyPortfolio";
import RankingInvestment from "./RankingInvestment/RankingInvestment";
import DashboardMemberStyles from "./style";
import { IMemberDashboardRanking, IMembersDashboardDataAPI } from "./types";

Chart.register(ArcElement);

const DashboardMember: FC<RouteChildrenProps> = () => {
  const [state, setState] = useState<IMembersDashboardDataAPI | null>(null);
  const [ranking, setRanking] = useState<IMemberDashboardRanking[] | null>(
    null,
  );
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    getDashboardMember().then(({ data }) => {
      setState(data);
    });
    getDashboardMemberRanking().then(({ data }) => {
      setRanking(data);
    });
  }, [reload]);

  const loadingComponent = (
    <Box
      display="flex"
      justifyContent={"center"}
      alignItems="center"
      height={"80vh"}
    >
      <CircularProgress />
    </Box>
  );
  return (
    <Box sx={DashboardMemberStyles}>
      <PageTitle title="Dashboard" />
      <MainLayout>
        {!state && loadingComponent}

        {state && (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            rowSpacing={3}
            columnSpacing={2}
          >
            <Grid item sm={12} lg={6}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                rowSpacing={3}
              >
                <Grid item sm={12}>
                  <InvestmentOpportunity
                    data={state.opportunitiesOfInvestment}
                  />
                </Grid>
                <Grid item sm={12}>
                  <MyPortfolio data={state.myPortfolio} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Calendar data={state.sessions} />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Notices data={state.notices} />
            </Grid>
            <Grid item xs={12}>
              <Card
                className="investment-opportunity-table-card"
                sx={{
                  width: "100%",
                  height: "430px",
                  padding: "0 !important",
                }}
              >
                <InvestmentOpportunityTable
                  setReload={setReload}
                  reload={reload}
                  data={state.opportunitiesOfInvestedTable}
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={6} xl={5}>
              <Card className="ranking-card">
                {ranking ? (
                  <RankingInvestment rankings={ranking} />
                ) : (
                  <Box
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </Card>
            </Grid>
            <Grid item xs={12} md={6} xl={5}>
              <PlatformActivity data={state.activity} />
            </Grid>
          </Grid>
        )}
      </MainLayout>
    </Box>
  );
};

export default DashboardMember;
