const mainStyles = {
  timerIconBox: {
    marginRight: "-0.8rem",
    borderRadius: "200px",
    backgroundColor: "#EB7281",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  actionsButtons: {
    backgroundColor: "#74C24A",
    width: "162px",
    color: "white",
  },
  actionsMobileButtons: {
    backgroundColor: "#74C24A",
    width: "96px",
    color: "white",
  },
  container: {
    minWidth: "250px",
    flexGrow: 1,
    gap: "8px",
    display: "flex",
    flexDirection: "column",
  },
  tab: { marginTop: 2, minHeight: "440px" },
  icon: {
    color: "#4A7C2F",
    width: "18px",
    marginRight: "4px",
  },
  text: {
    fontSize: "16px",
  },
  label: {
    display: "inline",
    marginLeft: "8px",
    fontFamily: "Product Sans Light !important",
    fontWeight: "300 !important",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#515151",
  },
};

export default mainStyles;
