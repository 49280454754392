import {
  AccountBalanceOutlined,
  AddCard,
  Badge,
  Book,
  DashboardOutlined,
  Description,
  Folder,
  GroupOutlined,
  ImportContacts,
  Newspaper,
  Payments,
  Person,
  Rocket,
} from "@mui/icons-material";
import React, { ReactElement } from "react";
import { RouteChildrenProps } from "react-router";

import Admin from "../Admin";
import Applications from "../Applications";
import DashboardAdmin from "../DashboardAdmin";
import DashboardMember from "../DashboardMember/DashboardMember";
import DashboardStartup from "../DashboardStartup";
import DetailsMember from "../DetailsMember";
import DetailsOpportunity from "../DetailsOpportunity";
import DetailsStartup from "../DetailsStartup";
import Directory from "../Directory";
import DirectoryAngelhub from "../DirectoryAngelhub/DirectoryAngelhub";
import DirectoryInvestors from "../DirectoryInvestors/Directory";
import MemberShip from "../MemberShipList/MemberShip";
import NewsFeed from "../NewsFeed";
import Opportunities from "../Opportunities/Opportunities";
import OpportunitiesInvested from "../OpportunitiesInvested/OpportunitiesInvested";
import OpportunitiesOpen from "../OpportunitiesOpen/OpportunitiesOpen";
import OpportunitiesReleasedAdmin from "../OpportunitiesReleasedAdmin";
import OpportunitiesReleasedInvestor from "../OpportunitiesReleasedInvestor";
import Portfolio from "../Portafolio/Portfolio";
import AdminProfile from "../ProfileAdmin";
import ProfileAnalystScreen from "../ProfileAnalyst/ProfileAnalyst";
import ProfileMember from "../ProfileMember";
import ProfilePartner from "../ProfilePartner";
import StartupProfile from "../ProfileStartup";
import ResourceLibrary from "../ResourceLibrary";
import ResourceLibraryDetail from "../ResourceLibraryDetail";
import UpdateAdmin from "../UpdateAdmin";
import UpdateAnalyst from "../UpdateAnalyst";
import UpdatePartner from "../UpdatePartner";
import UpdateStartup from "../UpdateStartup/UpdateStartup";

import { ERoles, ERolesCode } from "./role-catalog";

export interface IPage {
  component?: React.FC<RouteChildrenProps | null>;
  displayName: string;
  route?: string;
  public: boolean;
  exact: boolean;
  roles: Array<ERoles | ERolesCode>;
  iconComponent?: ReactElement;
  isMenuItem?: boolean;
  isBottom?: boolean;
  isAdminTool?: boolean;
  children?: IPage[];
  render?: any;
}

const Routes = (): Array<IPage> => [
  {
    displayName: "Dashboard",
    component: DashboardAdmin,
    isMenuItem: true,
    roles: [
      ERolesCode.ADMINISTRATOR,
      ERolesCode.PARTNER,
      ERolesCode.ANALYST,
      ERolesCode.SUPER_ADMIN,
    ],
    iconComponent: <DashboardOutlined />,
    route: "/dashboard",
    public: false,
    exact: true,
  },
  {
    displayName: "Dashboard",
    component: DashboardMember,
    isMenuItem: true,
    roles: [ERolesCode.MEMBER],
    iconComponent: <DashboardOutlined />,
    route: "/dashboard",
    public: false,
    exact: true,
  },
  {
    displayName: "Dashboard",
    component: DashboardStartup,
    isMenuItem: true,
    roles: [ERolesCode.STARTUP],
    iconComponent: <DashboardOutlined />,
    route: "/dashboard",
    public: false,
    exact: true,
  },
  {
    displayName: "Oportunidades",
    component: OpportunitiesReleasedInvestor,
    isMenuItem: true,
    roles: [ERolesCode.MEMBER, ERolesCode.STARTUP],
    iconComponent: <Rocket />,
    route: "/oportunidades-aceptadas",
    public: false,
    exact: true,
  },
  {
    displayName: "Oportunidades",
    component: OpportunitiesReleasedAdmin,
    isMenuItem: true,
    roles: [
      ERolesCode.ADMINISTRATOR,
      ERolesCode.PARTNER,
      ERolesCode.ANALYST,
      ERolesCode.SUPER_ADMIN,
    ],
    iconComponent: <Rocket />,
    isAdminTool: true,
    route: "/oportunidades-aceptadas-admin",
    public: false,
    exact: true,
  },
  {
    displayName: "Oportunidades invertidas",
    component: Portfolio,
    isMenuItem: true,
    roles: [ERolesCode.MEMBER],
    iconComponent: <Folder />,
    isAdminTool: false,
    route: "/inversionistas/portafolio",
    public: false,
    exact: true,
  },
  {
    displayName: "Usuarios",
    component: Admin,
    isMenuItem: true,
    roles: [ERoles.RL000, ERoles.RL001, ERoles.RL002, ERoles.RL005],
    iconComponent: <GroupOutlined />,
    route: "/usuarios",
    isAdminTool: true,
    public: false,
    exact: true,
  },
  {
    displayName: "Membresías",
    component: MemberShip,
    isMenuItem: true,
    roles: [ERoles.RL000, ERoles.RL001, ERoles.RL002, ERoles.RL005],
    iconComponent: <Badge />,
    route: "/membresias",
    isAdminTool: true,
    public: false,
    exact: true,
  },
  {
    displayName: "Aplicaciones",
    component: Applications,
    isMenuItem: true,
    roles: [
      ERolesCode.ADMINISTRATOR,
      ERolesCode.PARTNER,
      ERolesCode.ANALYST,
      ERolesCode.SUPER_ADMIN,
    ],
    iconComponent: <Description />,
    public: false,
    exact: true,
    children: [
      {
        displayName: "Oportunidades",
        component: Opportunities,
        isMenuItem: true,
        roles: [
          ERolesCode.ADMINISTRATOR,
          ERolesCode.PARTNER,
          ERolesCode.ANALYST,
          ERolesCode.SUPER_ADMIN,
        ],
        route: "/oportunidades",
        public: false,
        exact: true,
      },
      {
        displayName: "Miembros",
        component: Applications,
        isMenuItem: true,
        roles: [
          ERolesCode.ADMINISTRATOR,
          ERolesCode.PARTNER,
          ERolesCode.ANALYST,
          ERolesCode.SUPER_ADMIN,
        ],
        route: "/inversionistas",
        public: false,
        exact: true,
      },
    ],
  },
  {
    displayName: "Directorio",
    isMenuItem: true,
    roles: [
      ERolesCode.ADMINISTRATOR,
      ERolesCode.PARTNER,
      ERolesCode.ANALYST,
      ERolesCode.SUPER_ADMIN,
      ERolesCode.STARTUP,
      ERolesCode.MEMBER,
    ],
    iconComponent: <ImportContacts />,
    public: false,
    exact: true,
    children: [
      {
        displayName: "Miembros",
        component: DirectoryInvestors,
        isMenuItem: true,
        roles: [
          ERoles.RL000,
          ERoles.RL001,
          ERoles.RL002,
          ERoles.RL003,
          ERoles.RL004,
          ERoles.RL005,
        ],
        route: "/directorio-miembros",
        public: true,
        exact: true,
      },
      {
        displayName: "Startups Invertidas",
        component: Directory,
        isMenuItem: true,
        roles: [
          ERoles.RL000,
          ERoles.RL001,
          ERoles.RL002,
          ERoles.RL003,
          ERoles.RL004,
          ERoles.RL005,
        ],
        route: "/directorio-startups",
        public: true,
        exact: true,
      },
      {
        displayName: "Equipo AngelHub",
        component: DirectoryAngelhub,
        isMenuItem: true,
        roles: [
          ERoles.RL000,
          ERoles.RL001,
          ERoles.RL002,
          ERoles.RL003,
          ERoles.RL004,
          ERoles.RL005,
        ],
        route: "/directorio-angelhub",
        public: true,
        exact: true,
      },
    ],
  },
  {
    displayName: "Oportunidades Abiertas",
    component: OpportunitiesOpen,
    isMenuItem: true,
    roles: [
      ERolesCode.ADMINISTRATOR,
      ERolesCode.PARTNER,
      ERolesCode.ANALYST,
      ERolesCode.SUPER_ADMIN,
    ],
    iconComponent: <AddCard />,
    route: "/oportunidades-abiertas",
    public: false,
    exact: true,
  },
  {
    displayName: "Oportunidades Invertidas",
    component: OpportunitiesInvested,
    isMenuItem: true,
    roles: [
      ERolesCode.ADMINISTRATOR,
      ERolesCode.PARTNER,
      ERolesCode.ANALYST,
      ERolesCode.SUPER_ADMIN,
    ],
    iconComponent: <Payments />,
    route: "/oportunidades-invertidas",
    public: false,
    exact: true,
  },
  {
    displayName: "Biblioteca de Recursos",
    component: ResourceLibrary,
    isMenuItem: true,
    roles: [
      ERolesCode.ADMINISTRATOR,
      ERolesCode.PARTNER,
      ERolesCode.ANALYST,
      ERolesCode.SUPER_ADMIN,
      ERolesCode.MEMBER,
    ],
    iconComponent: <Book />,
    route: "/biblioteca-recursos",
    public: false,
    exact: true,
  },
  {
    displayName: "Biblioteca de Recursos",
    component: ResourceLibraryDetail,
    isMenuItem: false,
    roles: [
      ERolesCode.ADMINISTRATOR,
      ERolesCode.PARTNER,
      ERolesCode.ANALYST,
      ERolesCode.SUPER_ADMIN,
      ERolesCode.MEMBER,
      ERolesCode.STARTUP,
    ],
    iconComponent: <Book />,
    route: "/biblioteca-recursos/:id",
    public: false,
    exact: true,
  },
  {
    displayName: "Avisos",
    component: NewsFeed,
    isMenuItem: true,
    roles: [
      ERolesCode.MEMBER,
      ERolesCode.STARTUP,
      ERolesCode.ADMINISTRATOR,
      ERolesCode.SUPER_ADMIN,
      ERolesCode.PARTNER,
      ERolesCode.ANALYST,
    ],
    iconComponent: <Newspaper />,
    route: "/news-feed",
    public: false,
    exact: true,
  },
  {
    displayName: "News Feed Item",
    component: NewsFeed,
    isMenuItem: false,
    roles: [
      ERolesCode.MEMBER,
      ERolesCode.STARTUP,
      ERolesCode.SUPER_ADMIN,
      ERolesCode.ADMINISTRATOR,
      ERolesCode.PARTNER,
      ERolesCode.ANALYST,
    ],
    iconComponent: <Newspaper />,
    route: "/news-feed/:id",
    public: false,
    exact: true,
  },
  {
    displayName: "Actualizar Administrador",
    component: UpdateAdmin,
    isMenuItem: false,
    roles: [
      ERolesCode.ADMINISTRATOR,
      ERolesCode.PARTNER,
      ERolesCode.ANALYST,
      ERolesCode.SUPER_ADMIN,
    ],
    iconComponent: <GroupOutlined />,
    route: "/update-admin/:id",
    public: false,
    exact: true,
  },
  {
    displayName: "Actualizar Miembro",
    component: ProfileMember,
    isMenuItem: false,
    roles: [
      ERolesCode.ADMINISTRATOR,
      ERolesCode.PARTNER,
      ERolesCode.ANALYST,
      ERolesCode.SUPER_ADMIN,
    ],
    iconComponent: <AccountBalanceOutlined />,
    route: "/update-member/:id",
    public: false,
    exact: true,
  },
  {
    displayName: "Detalles de miembro",
    component: ProfileMember,
    isMenuItem: false,
    roles: [
      ERolesCode.ADMINISTRATOR,
      ERolesCode.PARTNER,
      ERolesCode.ANALYST,
      ERolesCode.SUPER_ADMIN,
      ERolesCode.MEMBER,
      ERolesCode.STARTUP,
    ],
    iconComponent: <AccountBalanceOutlined />,
    route: "/detalles-miembro/:id",
    public: false,
    exact: true,
  },
  {
    displayName: "Actualizar Startup",
    component: UpdateStartup,
    isMenuItem: false,
    roles: [
      ERolesCode.ADMINISTRATOR,
      ERolesCode.PARTNER,
      ERolesCode.ANALYST,
      ERolesCode.SUPER_ADMIN,
    ],
    iconComponent: <AccountBalanceOutlined />,
    route: "/update-startup/:id",
    public: false,
    exact: true,
  },
  {
    displayName: "Actualizar Analista",
    component: UpdateAnalyst,
    isMenuItem: false,
    roles: [
      ERolesCode.PARTNER,
      ERolesCode.SUPER_ADMIN,
      ERolesCode.ADMINISTRATOR,
      ERolesCode.ANALYST,
    ],
    iconComponent: <AccountBalanceOutlined />,
    route: "/update-analyst/:id",
    public: false,
    exact: true,
  },
  {
    displayName: "Actualizar Partner",
    component: UpdatePartner,
    isMenuItem: false,
    roles: [
      ERolesCode.PARTNER,
      ERolesCode.SUPER_ADMIN,
      ERolesCode.ADMINISTRATOR,
      ERolesCode.ANALYST,
    ],
    iconComponent: <AccountBalanceOutlined />,
    route: "/update-partner/:id",
    public: false,
    exact: true,
  },
  // PERFILES
  {
    displayName: "Perfil de Administrador",
    component: AdminProfile,
    isMenuItem: false,
    roles: [
      ERolesCode.ADMINISTRATOR,
      ERolesCode.SUPER_ADMIN,
      ERolesCode.PARTNER,
    ],
    iconComponent: <Person />,
    route: "/admin-profile/:id",
    public: false,
    exact: true,
  },
  {
    displayName: "Perfil de Analista",
    component: ProfileAnalystScreen,
    isMenuItem: false,
    roles: [ERolesCode.ANALYST],
    iconComponent: <Person />,
    route: "/analyst-profile/:id",
    public: false,
    exact: true,
  },
  {
    displayName: "Perfil de Partner",
    component: ProfilePartner,
    isMenuItem: false,
    roles: [ERolesCode.PARTNER],
    iconComponent: <Person />,
    route: "/partner-profile/:id",
    public: false,
    exact: true,
  },
  {
    displayName: "Perfil de Startup",
    component: StartupProfile,
    isMenuItem: false,
    roles: [ERolesCode.STARTUP],
    iconComponent: <AccountBalanceOutlined />,
    route: "/startup-profile/:id",
    public: false,
    exact: true,
  },
  {
    displayName: "Dashboard de Startup",
    component: DashboardStartup,
    isMenuItem: false,
    roles: [ERolesCode.STARTUP],
    iconComponent: <AccountBalanceOutlined />,
    route: "/startup-profile/:id",
    public: false,
    exact: true,
  },
  {
    displayName: "Perfil de Miembro",
    component: ProfileMember,
    isMenuItem: false,
    roles: [
      ERolesCode.MEMBER,
      ERolesCode.ADMINISTRATOR,
      ERolesCode.PARTNER,
      ERolesCode.ANALYST,
      ERolesCode.SUPER_ADMIN,
    ],
    iconComponent: <AccountBalanceOutlined />,
    route: "/member-profile/:id",
    public: false,
    exact: true,
  },
  {
    component: DetailsMember,
    displayName: "Detalle de Miembro",
    route: "/details-member/:id",
    public: true,
    exact: true,
    isMenuItem: false,
    isBottom: true,
    roles: [
      ERolesCode.ADMINISTRATOR,
      ERolesCode.PARTNER,
      ERolesCode.ANALYST,
      ERolesCode.SUPER_ADMIN,
    ],
  },
  {
    component: DetailsOpportunity,
    displayName: "Detalle de oportunidad de startup",
    route: "/details-startup-opportunity/:id",
    public: true,
    exact: true,
    isMenuItem: false,
    isBottom: true,
    roles: [
      ERolesCode.ADMINISTRATOR,
      ERolesCode.SUPER_ADMIN,
      ERolesCode.ANALYST,
      ERolesCode.PARTNER,
    ],
  },
  {
    component: DetailsStartup,
    displayName: "Detalle de startup",
    route: "/details-startup/:id",
    public: true,
    exact: true,
    isMenuItem: false,
    isBottom: true,
    roles: [
      ERolesCode.ADMINISTRATOR,
      ERolesCode.SUPER_ADMIN,
      ERolesCode.STARTUP,
      ERolesCode.PARTNER,
      ERolesCode.MEMBER,
      ERolesCode.ANALYST,
    ],
  },
];

export default Routes();
