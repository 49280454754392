/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import React from "react";
import RecaptchaSource from "react-google-recaptcha";

export interface IRecaptchaProps {
  captchaSucceededHandler: () => void;
  captchaFailedHandler: () => void;
}
/**
 * LLama el componente para agregar un captcha a una forma. Utiliza los handlers para captar las respuestas del captcha
 * @param {(e:any)=>void} captchaFailedHandler funcion de manejo de respuesta cuando no es exitosa la evaluacion
 * @param {(e:any)=>void} captchaSucceededHandler funcion de manejo de respuesta cuando si es exitosa la evaluacion
 * @param {string} captchaPublicKey Llave de desarrollador para captcha
 * 
 * The captcha key is obtained at https://www.google.com/recaptcha/admin/create
 For developing, you can register "localhost"

 onChange/onExpired/onErrored props does not have any arguments, you can
 freely use a callback function to make a customizable approach with those props.
 */

const Recaptcha: React.FC<IRecaptchaProps> = ({
  captchaFailedHandler,
  captchaSucceededHandler,
}) => {
  const onCaptchaChangeHandler = React.useCallback(
    (captchaValue: string | null) => {
      if (captchaValue) {
        captchaSucceededHandler();
      }
    },
    [],
  );

  return (
    <>
      {process.env.REACT_APP_CAPTCHA ? (
        <RecaptchaSource
          sitekey={process.env.REACT_APP_CAPTCHA as string}
          onChange={onCaptchaChangeHandler}
          onExpired={captchaFailedHandler}
          onErrored={captchaFailedHandler}
        />
      ) : (
        <Box>Captcha key is missing</Box>
      )}
    </>
  );
};

export default Recaptcha;
