import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import BaseDataGrid from "../../components/BaseDataGrid/BaseDataGrid";
import { ModalMenu } from "../../components/DataGrid";
import { DEFAULT_ROWS_PAGE_SIZE } from "../../components/DataGrid/constants";
import MemberShipToolbar from "../../components/Toolbars/MemberShipToolbar";
import { ETabsMember } from "../../constants";
import { IUser } from "../../models/User";
import { fetchUserByRole } from "../../services/users";
import { ERolesCode } from "../routes/role-catalog";

import { columns } from "./columns";
import ModalMembresia from "./components/ModalMembresia";

const MemberShipList: React.FC = () => {
  const [Members, setMembers] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_ROWS_PAGE_SIZE);
  const [Open, setOpen] = useState<boolean>(false);
  const [Tab, setTab] = useState<string>("");
  const [MemberProp, setMemberProp] = useState<any>([]);
  const [Search, setSearch] = useState<string>("");

  async function getUserByRole() {
    const members = await fetchUserByRole(ERolesCode.MEMBER);
    const membersResult = members.data.payload;

    setMembers(membersResult);
    members && setLoading(false);
  }

  useEffect(() => {
    try {
      getUserByRole();
    } catch (error) {
      setMembers([]);
    }
  }, []);

  function handleDetails(id: string) {
    history.push(`member-profile/${id}?Tab=Membership`);
  }

  const renderModalMenu = (data: any) => {
    const detailsOptionsList = [
      {
        name: "Ver detalles",
        action: () => handleDetails(data.row.member._id),
      },
      {
        name: "Cambiar membresía",
        action: () => {
          setOpen(true);
          setMemberProp(data.row);
        },
      },
    ];

    return <ModalMenu menuVert items={detailsOptionsList} />;
  };

  const newColumns = () => [
    ...columns(),
    {
      headerName: "Acciones",
      field: "actions",
      hideSortIcons: true,
      align: "center",
      renderCell: (data: any) => renderModalMenu(data),
    },
  ];

  function handleFilter(data: any[]) {
    let values = data.filter((user: IUser) => {
      return (
        String(user.firstName)?.toLowerCase().includes(Search.toLowerCase()) ||
        String(user.lastName)?.toLowerCase().includes(Search.toLowerCase()) ||
        !Search
      );
    });

    if (Tab) {
      if (Tab === ETabsMember.CLASSICAL) {
        values = values.filter((i) => {
          return i.member.membershipType === ETabsMember.CLASSICAL;
        });
      }
      if (Tab === ETabsMember.AMBASSADOR) {
        values = values.filter((i) => {
          return i.member.membershipType === ETabsMember.AMBASSADOR;
        });
      }
      if (Tab === ETabsMember.ALLIES) {
        values = values.filter((i) => {
          return i.member.membershipType === ETabsMember.ALLIES;
        });
      }
      if (Tab === ETabsMember.LEADANGEL) {
        values = values.filter((i) => {
          return i.member.membershipType === ETabsMember.LEADANGEL;
        });
      }
      if (Tab === ETabsMember.EXPERIENCIA) {
        values = values.filter((i) => {
          return i.member.membershipType === ETabsMember.CLASSICAL;
        });
      }
    }

    return values;
  }

  return (
    <>
      <ModalMembresia
        open={Open}
        onCancel={() => setOpen(false)}
        memberInfo={MemberProp}
        getUserByRole={getUserByRole}
      />
      <BaseDataGrid
        page={page}
        pageSize={pageSize}
        onPageChange={(value) => setPage(value)}
        onPageSizeChange={(value) => setPageSize(value)}
        columns={newColumns() as any}
        loading={loading}
        rows={handleFilter(Members)}
        getRowId={(row) => row._id}
        components={{
          Toolbar: MemberShipToolbar,
        }}
        checkboxSelection
        componentsProps={{
          toolbar: {
            onTabChange: (value: string) => {
              setTab(value);
            },
            onSearchChange: (value: string) => {
              setSearch(value);
            },
            onSearchClear: () => {
              setSearch("");
            },
          },
        }}
      />
    </>
  );
};

export default MemberShipList;
