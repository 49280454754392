import { EStatusReleasedOpportunity } from "../../../../../../constants";
import { ERolesCode } from "../../../../../routes/role-catalog";

export const VALID_ROLES_TO_INVEST = [ERolesCode.MEMBER];
export const VALID_STATUS_TO_INVEST = [
  EStatusReleasedOpportunity.OPEN_TO_INVESTMENT,
];
export const VALID_STATUS_TO_EDIT_INVESTMENT = [
  EStatusReleasedOpportunity.OPEN_TO_INVESTMENT,
  EStatusReleasedOpportunity.CANCELLED,
  EStatusReleasedOpportunity.EXPIRED,
  EStatusReleasedOpportunity.IN_REVIEW,
  EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT,
  EStatusReleasedOpportunity.IN_INVESTMENT_PROCESS,
  EStatusReleasedOpportunity.PASS,
];

export const VALID_ROLES_TO_USE_EDIT_BUTTON = [
  ERolesCode.PARTNER,
  ERolesCode.ADMINISTRATOR,
  ERolesCode.SUPER_ADMIN,
  ERolesCode.PARTNER,
  ERolesCode.ANALYST,
];
