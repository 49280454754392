import { UseFormReturn } from "react-hook-form";
import { Dispatch } from "redux";

import { FormTypeScoreAndComments } from "../../components/DetailsStartup/Forms/schema";
import {
  getOpportunities,
  getOpportunity,
  IEvaluation,
  updateOpportunityEvaluation,
} from "../../services/opportunitiesApplication";

export const LOADING_OPPORTUNITY = "LOADING_OPPORTUNITY";

export const FETCH_OPPORTUNITIES_PAGINATED = "FETCH_OPPORTUNITIES_PAGINATED";

export const fetchOpportunityPaginated = () => async (dispatch: Dispatch) => {
  dispatch({ type: LOADING_OPPORTUNITY, payload: true });
  try {
    const response = await getOpportunities();
    const data = response.data.payload as any;

    dispatch({
      type: FETCH_OPPORTUNITIES_PAGINATED,
      payload: data,
    });
  } catch {
    dispatch({ type: LOADING_OPPORTUNITY, payload: false });
  } finally {
    dispatch({ type: LOADING_OPPORTUNITY, payload: false });
  }
};

export const CHANGE_PAGE_OPPORTUNITIES = "CHANGE_PAGE_OPPORTUNITIES";
export const changePageOpportunities = (value: number) => {
  return {
    type: CHANGE_PAGE_OPPORTUNITIES,
    payload: value,
  };
};
export const CHANGE_LIMIT_OPPORTUNITIES = "CHANGE_LIMIT_OPPORTUNITIES";
export const changeLimitOpportunities = (value: number) => {
  return {
    type: CHANGE_LIMIT_OPPORTUNITIES,
    payload: value,
  };
};
export const CHANGE_OPPORTUNITY_VALUE = "CHANGE_OPPORTUNITY_VALUE";

export const changeOpportunity = (value: any) => {
  return {
    type: CHANGE_OPPORTUNITY_VALUE,
    payload: value,
  };
};
export const CHANGE_OPPORTUNITIES_VALUE = "CHANGE_OPPORTUNITIES_VALUE";
export const changeOpportunities = (value: any) => {
  return {
    type: CHANGE_OPPORTUNITIES_VALUE,
    payload: value,
  };
};
export const FETCH_OPPORTUNITIES = "FETCH_OPPORTUNITIES";
export const fetchOpportunity = () => async (dispatch: Dispatch) => {
  dispatch({ type: LOADING_OPPORTUNITY, payload: true });
  try {
    const response = await getOpportunity();
    const data = response.data.payload as any;
    dispatch({
      type: FETCH_OPPORTUNITIES,
      payload: data,
    });
  } catch {
    dispatch({ type: LOADING_OPPORTUNITY, payload: false });
  } finally {
    dispatch({ type: LOADING_OPPORTUNITY, payload: false });
  }
};

export const EVALUATE_OPPORTUNITY = "EVALUATE_OPPORTUNITY";
export const post_opportunity_evaluation =
  (
    id: string,
    data: IEvaluation,
    hook: UseFormReturn<FormTypeScoreAndComments>,
  ) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: EVALUATE_OPPORTUNITY, payload: true });
    try {
      const response = await updateOpportunityEvaluation(id, data);
      const comment = response.data.data.comments as any;
      const evaluations = response.data.data.analystEvaluations;
      hook.setValue("additionalComment", comment);
      hook.setValue("scoreEvaluation", evaluations);
    } catch {
      dispatch({ type: LOADING_OPPORTUNITY, payload: false });
    } finally {
      dispatch({ type: LOADING_OPPORTUNITY, payload: false });
    }
  };

export const CLEAR_STATE_LIST_OPPORTUNITIES = "CLEAR_STATE_LIST_OPPORTUNITIES";
export const clearStateListOpportunities = () => {
  return {
    type: CLEAR_STATE_LIST_OPPORTUNITIES,
  };
};
