import { Typography } from "@mui/material";
import { Box, SxProps } from "@mui/system";
import React, { useEffect, useState } from "react";

import { getSignedUrl } from "../../services/files";
import { IFile } from "../../utils/types/common";

import { styles } from "./styles";

interface IProps {
  fileMulter: IFile | null;
  letter: string;
}

const BigAvatar: React.FC<IProps> = ({ fileMulter, letter }) => {
  const [Uri, setUri] = useState<string>("");
  useEffect(() => {
    if (fileMulter) {
      getSignedUrl(fileMulter?.key, fileMulter?.name).then(({ data }) => {
        setUri(data.payload);
      });
    }
  }, [fileMulter?.key, fileMulter?.name]);

  return (
    <Box
      sx={{ ...styles.avatar, backgroundImage: `url(${Uri})` }}
      component="div"
    >
      {!Uri && <Typography sx={styles.text}>{letter}</Typography>}
    </Box>
  );
};

export default BigAvatar;
