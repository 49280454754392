import { SxProps, Theme } from "@mui/material";

const MyActionsStyles: SxProps<Theme> = (theme: Theme) => ({
  "& .my-actions-card": {
    width: "100%",
    padding: "16px !important",
    [theme.breakpoints.up("xl")]: {
      height: "505px",
    },
    [theme.breakpoints.between("sm", "xl")]: {
      height: "597px",
    },
  },
  "& .MuiAvatar-root": {
    bgcolor: "#D3EBC5",
    marginBottom: "10px",
    "& .MuiSvgIcon-root": { color: "#74C24A" },
  },
  "& .MuiDivider-root": {
    borderBottomWidth: "2px",
    borderColor: "#C7C7C7",
  },
  "& .MuiListItem-root": {
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingTop: "4px",
    paddingBottom: "4px",
  },
  "& .MuiTypography-root.MuiTypography-subtitle2": {
    fontSize: "14px",
  },
  "& .MuiTypography-root.MuiTypography-h6": {
    fontFamily: "Product Sans !important",
    fontWeight: 700,
    color: "#343434",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .MuiLink-root": {
    paddingRight: "12px",
    fontSize: "14px",
    color: "#4D88E1",
  },
  "& .my-actions-header .MuiSvgIcon-root ": {
    color: "#74C24A",
    fontSize: "34px",
  },
  "& .noElements": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});

export default MyActionsStyles;
