import { SxProps, Theme } from "@mui/material";

const IndicatorCardStyles: SxProps<Theme> = (theme: Theme) => ({
  "& .indicator-card": {
    width: "100%",
    padding: "16px !important",
    height: "140px",
    "& .MuiTypography-root.MuiTypography-h3": {
      fontFamily: "Product Sans !important",
      fontWeight: 700,
      lineHeight: 1,
      color: "#74C24A",
    },
    "& .MuiTypography-root.MuiTypography-subtitle2": {
      fontSize: "14px",
      color: "#515151",
    },
    "& .action-more-icon": {
      color: "#515151",
      marginLeft: "0 !important",
      padding: 0,
    },
  },
});

export default IndicatorCardStyles;
