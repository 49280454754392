import { SxProps, Theme } from "@mui/material";

export const styles: SxProps<Theme> = (theme: Theme) => ({
  "& .adhesion-contract-header": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "25px",
    flexWrap: "wrap",
    gap: "20px",
    minWidth: "200px",
    "@media (max-width:623px)": {
      justifyContent: "center",
    },
  },
  "& .MuiTypography-root": {
    textAlign: "justify",
  },
  [theme.breakpoints.down(623)]: {
    "& .MuiTypography-root": {
      textAlign: "left",
      fontSize: "12px",
    },
  },
  "& .MuiListItem-root": {
    fontWeight: "bold",
  },
  "& .title-listItem": {},
  "& .header-info-container": {
    width: "300px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .header-info": {
    fontSize: "10px",
    textAlign: "center",
  },
});
