import { SxProps, Theme } from "@mui/system";

export const directoryInvestorsStyles: SxProps<Theme> = (theme: Theme) => ({
  padding: "40px 40px 20px 40px",
  ".totalResult": {
    marginY: "24px",
    fontFamily: "Product Sans Black",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "28px",
  },
  ".noResults": {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: "100px 0 0 0",
    fontWeight: "400",
    fontSize: "1.5rem",
    lineHeight: "1.334",
    letterSpacing: "0em",
    minHeight: "100vh",
  },
});
export const directoryInvestorsCardStyles: SxProps<Theme> = (theme: Theme) => ({
  minHeight: "220px",

  cursor: "pointer",

  [theme.breakpoints.down("sm")]: {
    maxHeight: "auto",
    minHeight: "auto",
    minWidth: "auto",
    maxWidth: "auto",
  },

  ".card": {
    width: "100%",
    paddingX: "16px",
    display: "flex",
    flexDirection: "column",
    minHeight: "332px",
  },
  ".cardMedia": {
    display: "flex",
    justifyContent: "center",
    paddingTop: "30px",
  },
  ".cardContent": {
    paddingX: "0px",
    paddingBottom: "18px",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  ".avatar": {
    fontFamily: "Product Sans Black",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "48px",
    lineHeight: "64px",
    color: "#505050",
  },
  ".name": {
    fontFamily: "Product Sans Black",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "24px",
    lineHeight: "32px",
    whiteSpace: "initial",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#505050",
    minHeight: "64px",
  },
  ".description": {
    maxHeight: "45px",
    minHeight: "45px",
    mt: 1.5,

    color: "#505050",
    fontFamily: "Product Sans Light !important",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "lighter",

    whiteSpace: "initial",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
  },
  ".cityLabel": {
    fontFamily: "Product Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",

    lineHeight: "24px",
    display: "inline",
    gap: "4px",
    alignItems: "baseline",
    color: "#505050",
    minHeight: "48px",
  },
  ".city": {
    fontFamily: "Product Sans Light !important",
    fontStyle: "normal",
    fontWeight: "100",
    fontSize: "16px",
    lineHeight: "24px",
    display: "inline",
    gap: "4px",
    alignItems: "baseline",
    color: "#505050",
  },
  ".industry": {
    fontFamily: "Product Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    color: "#505050",
  },
  ".icon": {
    color: "#4A7C2F",
    height: "21px",
    width: "21px",
  },
  ".chip-container": {
    display: "flex",
    gap: "4px",
    flexDirection: "row",
    flexWrap: "wrap",
    overflowY: "auto",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "60px",
    },
  },
});
