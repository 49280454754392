/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import BaseDataGrid from "../../components/BaseDataGrid/BaseDataGrid";
import { ModalMenu } from "../../components/DataGrid";
import { DEFAULT_ROWS_PAGE_SIZE } from "../../components/DataGrid/constants";
import DialogExtendInvestment from "../../components/DialogExtendInvestment/DialogExtendInvestment";
import OpportunitiesReleasedToolbar from "../../components/Toolbars/OpportunityReleasedAdminToolbar/OpportunitiesReleasedToolbar";
import {
  EStatusReleasedOpportunityTabs,
  PeriodType,
} from "../../components/Toolbars/OpportunityReleasedAdminToolbar/const";
import { EStatusReleasedOpportunity } from "../../constants";
import { openModal } from "../../redux/actions/modal";
import {
  changeOpportunitiesReleased,
  changeOpportunityReleased,
  fetchOpportunityReleasedPaginated,
} from "../../redux/actions/opportunitiesReleasedAdmin";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import { opportunitiesReleasedChangeStatus } from "../../services/opportunitiesReleased";
import { displayToast } from "../../utils/helpers/displayToast";
import { getPeriodValues } from "../../utils/helpers/getPeriodValues";

import { columnsOpportunity } from "./columns";
import {
  VALID_STATUS_TO_CLOSE,
  VALID_STATUS_TO_EXTENDER_INVESTMENT_DATE,
} from "./validations";

const OpportunityList: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { opportunities, loading } = useSelector(
    (store) => store.opportunityReleasedReducerAdmin,
  );

  const [Open, setOpen] = useState<boolean>(false);

  const [Search, setSearch] = useState<string>("");
  const [Stage, setStage] = useState<string>("");
  const [Industry, setIndustry] = useState<string>("");
  const [Country, setCountry] = useState<string>("");
  const Tab = queryParams.get("tab") || EStatusReleasedOpportunityTabs.ALL; //
  const Period: PeriodType = (queryParams.get("period") as PeriodType) || ""; //

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_ROWS_PAGE_SIZE);

  useEffect(() => {
    dispatch(fetchOpportunityReleasedPaginated());
  }, []);

  useEffect(() => {
    setPage(0);
    setPageSize(DEFAULT_ROWS_PAGE_SIZE);
  }, []);

  const renderModalMenu = (data: any) => {
    const detailsOptionsList = [
      {
        name: "Ver detalles",
        action: () => handleDetails(data.id),
      },
    ];

    const closeDetailOption = [
      {
        name: "Cerrar",
        action: () => {
          dispatch(
            openModal({
              title: "Advertencia",
              description:
                "Se va a cerrar oportunidad. ¿Seguro que desea continuar?",
              action: async () => {
                await changeStatus(false, data.row);
              },
            }),
          );
        },
      },
    ];

    const openDetailsOptionsList = [
      {
        name: "Extender",
        action: () => {
          dispatch(
            openModal({
              title: "Extender",
              description: "¿Deseas extender la fecha de cierre?",
              action() {
                setOpen(true);
                dispatch(changeOpportunityReleased(data.id));
              },
            }),
          );
        },
      },
    ];
    const reactiveOption = [
      {
        name: "Reactivar",
        action: () => {
          dispatch(
            openModal({
              title: "Advertencia",
              description:
                "Se va a reactivar oportunidad. ¿Seguro que desea continuar?",
              action: async () => {
                await changeStatus(true, data.row);
              },
            }),
          );
        },
      },
    ];

    if (VALID_STATUS_TO_EXTENDER_INVESTMENT_DATE.includes(data.row.status)) {
      detailsOptionsList.push(...openDetailsOptionsList);
    }

    if (VALID_STATUS_TO_CLOSE.includes(data.row.status)) {
      detailsOptionsList.push(...closeDetailOption);
    }

    if (data.row.status === EStatusReleasedOpportunity.OPEN_TO_INVESTMENT) {
      detailsOptionsList.push(...reactiveOption);
    }

    return <ModalMenu menuVert items={detailsOptionsList} />;
  };
  const newColumns = () => [
    ...columnsOpportunity(),
    {
      headerName: "Acciones",
      field: "actions",
      align: "center",
      hideSortIcons: true,
      renderCell: (data: any) => renderModalMenu(data),
    },
  ];

  function handleDetails(id: string) {
    history.push(`details-startup/${id}`);
  }

  async function changeStatus(Status: boolean, data: any): Promise<void> {
    const startupId = data._id;
    try {
      await opportunitiesReleasedChangeStatus({
        startupId,
        status: Status ? "OPEN_TO_INVESTMENT" : "CLOSED_TO_INVESTMENT",
      });
    } catch (error) {
      displayToast("error", "Ha ocurrido un problema ");
    }

    const copyOpportunity = [...opportunities];
    const index = copyOpportunity.findIndex(
      (i) => String(i._id) === String(startupId),
    );
    copyOpportunity[index].status = Status
      ? EStatusReleasedOpportunity.OPEN_TO_INVESTMENT
      : EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT;
    dispatch(changeOpportunitiesReleased(copyOpportunity));
    displayToast("success", "Se a actualizado correctamente tu oportunidad");
  }

  function handleFilter(data: any[]) {
    let values = data.filter((opportunity: any) => {
      return (
        opportunity.name.toLowerCase().includes(Search.toLowerCase()) || !Search
      );
    });

    if (Stage) {
      values = values.filter((i) => i.stage === Stage);
    }
    if (Industry) {
      values = values.filter((i) => i.industry === Industry);
    }
    if (Country) {
      values = values.filter((i) => i.country === Country);
    }
    if (Tab !== EStatusReleasedOpportunityTabs.ALL) {
      if (Tab === EStatusReleasedOpportunityTabs.TO_CLOSE) {
        const date = dayjs();
        const rangeToClose = date.add(7, "days");
        values = values.filter((i) => {
          return (
            rangeToClose.isAfter(i["expirationDate"]) &&
            date.isBefore(i["expirationDate"]) &&
            i["status"] === EStatusReleasedOpportunity.OPEN_TO_INVESTMENT
          );
        });
      } else {
        values = values.filter((i) => i.status === Tab);
      }
    }

    if (Period) {
      values = values.filter((i) => {
        const { gt, lt } = getPeriodValues(Period);
        return lt.isBefore(i["dateStatus"]) && gt.isAfter(i["dateStatus"]);
      });
    }

    return values;
  }

  return (
    <>
      <DialogExtendInvestment
        open={Open}
        onCancel={() => setOpen(false)}
        opportunities={opportunities}
      />
      <BaseDataGrid
        page={page}
        pageSize={pageSize}
        onPageChange={(value) => setPage(value)}
        onPageSizeChange={(value) => setPageSize(value)}
        columns={newColumns() as any}
        loading={loading}
        rows={handleFilter(opportunities)}
        getRowId={(row) => row._id}
        components={{
          Toolbar: OpportunitiesReleasedToolbar,
        }}
        checkboxSelection
        componentsProps={{
          toolbar: {
            Tab,
            Period,
            onSearchChange: (value: string) => {
              setSearch(value);
            },
            onSearchClear: () => {
              setSearch("");
            },
            onStageChange: (value: string) => {
              setStage(value);
            },
            onIndustryChange: (value: string) => {
              setIndustry(value);
            },
            onCountryChange: (value: string) => {
              setCountry(value);
            },
            resetPage: () => {
              setPageSize(DEFAULT_ROWS_PAGE_SIZE);
              setPage(0);
            },
          },
        }}
      />
    </>
  );
};

export default OpportunityList;
