import { Box, Card, CardContent, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import { updateUser } from "../../services/users";
import ProfileAnalyst from "../ProfileAnalyst/ProfileAnalyst/ProfileAnalyst";

import styles from "./styles";

const UpdateAdmin: React.FC = () => {
  const { id } = useParams() as any;
  const isMobile = useMediaQuery("(max-width:860px)");

  async function handleSubmit(AdminFormdata: any) {
    await updateUser(id, AdminFormdata);
  }

  return (
    <Box>
      <PageTitle title="Editar perfil Analista" />
      <Card
        style={
          isMobile
            ? {
                width: "93vw",
                height: "90%",
                maxWidth: "827px",
                marginTop: "152px",
                marginLeft: "3%",
              }
            : {
                width: "80vw",
                height: "80vh",
                maxWidth: "1592px",
                marginTop: "152px",
                marginLeft: "3%",
              }
        }
      >
        <CardContent sx={styles.cardContent}>
          <ProfileAnalyst id={id} isUpdateScreen handleSubmit={handleSubmit} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default UpdateAdmin;
