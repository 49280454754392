import { Box, Button, Typography } from "@mui/material";

import noscreen from "../../../assets/images/noscreen.png";

const NoScreen = () => {
  const handleRedirect = () => {
    window.open("https://www.angelhub.mx/preguntas-frecuentes");
  };
  return (
    <Box
      sx={{
        maxWidth: "827px",
        minHeight: "202px",
        padding: "40px 35px 77px 35px",
      }}
    >
      <Typography
        sx={{
          fontSize: "24px",
          color: "#495463",
          textAlign: "center",
          height: "29px",
        }}
      >
        No eres tú, somos nosotros
      </Typography>
      <Typography
        sx={{
          marginTop: "19px",
          paddingTop: { xs: "34px" },
          textAlign: "center",
          font: "normal normal normal 16px/19px Product Sans Medium",
          color: "#5D5D5D",
          paddingLeft: { xs: "25px", md: "122px" },
          paddingRight: { xs: "25px", md: "122px" },
        }}
      >
        ¡Lo sentimos! En AngelHub estamos interesados únicamente en proyectos
        con la modalidad de startup y no de empresas tradicionales. Agradecemos
        el interés en unirte a nosotros.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: "20px 0px",
        }}
      >
        <Button
          onClick={handleRedirect}
          variant="outlined"
          sx={{ height: "40px" }}
        >
          Más información
        </Button>
        <Box>
          <img src={noscreen} alt="noscren" width={300} />
        </Box>
      </Box>
    </Box>
  );
};

export default NoScreen;
