import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { getToken } from "../config";

export const getNewsFeed = (
  page: number,
  limit: number,
  searchKey: string,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const doFilterSearch = searchKey && searchKey.trim() !== "";
  const config: AxiosRequestConfig = {
    method: "GET",
    url:
      "/dashboard/news-feed?page=" +
      page +
      "&limit=" +
      limit +
      (doFilterSearch ? "&searchKey=" + searchKey : ""),
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const getNewsItem = (id: string): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    method: "GET",
    url: "/dashboard/news-feed/" + id,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
