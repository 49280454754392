import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useState, KeyboardEvent, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { createStartupPassword } from "../../services/createPassword";
import { getStartupApplicationById } from "../../services/startupApplication";
import PasswordFieldEye from "../PasswordFieldEye/PasswordFieldEye";

import { signInStartupSchema } from "./schema";

interface IParams {
  id: string | string[];
  token: string | string[];
}

interface ISignInStartupFormProps {
  params: IParams;
}

type Form = {
  password: string;
  confirmPassword: string;
  email: string;
};

const ResetPasswordRequestForm: React.FC<ISignInStartupFormProps> = (props) => {
  const isMovil = useMediaQuery("(max-width:395px)");

  const { params } = props;
  const history = useHistory();
  const {
    handleSubmit,
    setError,
    control,
    formState: { isValid },
    setValue,
  } = useForm<Form>({
    resolver: yupResolver(signInStartupSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
    defaultValues: {
      password: "",
      confirmPassword: "",
      email: "",
    },
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [startupName, setStartupName] = useState<string>("");

  useEffect(() => {
    const fetchStartup = async () => {
      getStartupApplicationById(params.id as string)
        .then((response) => {
          setStartupName(response.data.payload.startupName);
        })
        .catch(() => {
          history.push("/login");
        });
    };
    fetchStartup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handleResetPassword = async (fields: Form): Promise<void> => {
    const newPassword = fields.confirmPassword;
    try {
      setLoading(true);
      await createStartupPassword(
        params.token as string,
        newPassword as unknown as string,
        params.id as string,
      );
      setIsSuccess(true);
    } catch (error) {
      setError("confirmPassword", {
        message: "Ha habido un error al crear su contraseña.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleReturnToLogin = (): void => {
    history.push("/login");
  };
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter" && isValid) {
      handleSubmit(handleResetPassword)();
    }
  };

  if (isSuccess) {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ paddingTop: "20px" }}
      >
        <Typography
          align="center"
          component="h1"
          variant="h5"
          sx={{
            paddingY: "16px",
            opacity: 1,
            textAlign: "center",
            color: "#5D5D5D",
            fontSize: "24px",
          }}
        >
          ¡Ya puedes iniciar sesión con tu nueva contraseña!
        </Typography>
        <br />
        <Button
          onClick={handleReturnToLogin}
          variant="contained"
          color="primary"
        >
          Regresar al Inicio de sesión
        </Button>
      </Box>
    );
  }

  return (
    <>
      <Grid container justifyContent="start">
        <Box display="flex" flexDirection="column">
          <Typography
            sx={{
              fontSize: "16px",
              height: "24px",
              fontWeight: "500",
              letterSpacing: "0.17px",
              color: "#5D5D5D",
              marginTop: "42px",
            }}
          >
            ¡Hola {startupName}!
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              letterSpacing: "0.17px",
              color: "#5D5D5D",
              marginTop: "12px",
            }}
          >
            Crea tu contraseña
          </Typography>
        </Box>
        <Box
          sx={{
            fontSize: "16px",
            textAlign: "start",
            letterSpacing: "0.11px",
            color: "#5D5D5D",
            marginTop: {
              xs: "6px",
              xl: "12px",
            },
            marginBottom: {
              xs: "10px",
              xl: "24px",
            },
          }}
        >
          <Typography>
            Crea una contraseña para tu acceso que contenga:
          </Typography>
          <Typography>- Minimo 8 Caracteres</Typography>
          <Typography>- Mayúsculas</Typography>
          <Typography>- Minúsculas</Typography>
          <Typography>- Números</Typography>
          <Typography>- Caracter especial</Typography>
        </Box>
      </Grid>
      <Grid container spacing={4} direction="column">
        <Grid item xs={12} sx={{ minWidth: "328px" }}>
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState }) => (
              <PasswordFieldEye
                {...field}
                onChange={(e) => {
                  setValue("password", e.target.value);
                }}
                disabled={loading}
                label="Nueva Contraseña"
                type="password"
                variant="outlined"
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
                onKeyPress={handleKeyPress}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field, fieldState }) => (
              <PasswordFieldEye
                {...field}
                disabled={loading}
                label="Confirmar Contraseña"
                type="password"
                variant="outlined"
                error={!isValid && Boolean(fieldState.error)}
                helperText={
                  !isValid &&
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
                onKeyPress={handleKeyPress}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ paddingTop: "30px !important" }}
          direction="row"
          justifyContent="center"
        >
          <Button
            onClick={handleSubmit(handleResetPassword)}
            variant="contained"
            color="primary"
            sx={{ width: "100%" }}
            disabled={!isValid}
          >
            CONFIRMAR
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPasswordRequestForm;
