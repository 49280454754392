import { StylesObject } from "../../styles";

export const styles: StylesObject = {
  text: {
    fontFamily: "Product Sans Light !important",
    fontWeight: "300 !important",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#515151",
  },
  label: {
    fontWeight: "300 !important",
    fontSize: "16px",
    color: "#515151",
  },
};
