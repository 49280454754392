export const translateRoles = (key: string): string => {
  const value: any = {
    SUPERADMIN: "Super Admin",
    ADMINISTRATOR: "Administrador",
    ANALYST: "Analista",
    STARTUP: "Start up",
    PARTNER: "Partner",
    MEMBER: "Miembro",
  };
  return value[key] ? value[key] : "No existe";
};

export const translateStatusApplications = (key: string): string => {
  const value: any = {
    PENDING: "Pendiente",
    ACCEPTED: "Aceptado",
    ASSIGNED: "Asignada",
    REJECTED: "Rechazado",
    RE_ASSIGNED: "Reasignado",
  };
  return value[key] ? value[key] : "No existe";
};
export const translateMemberShipType = (key: string): string => {
  const value: any = {
    CLASSICAL: "Clásica",
    TRIAL: "Experiencia",
    AMBASSADOR: "Embajador",
    LEADANGEL: "Lead Angel",
    ALLIES: "Aliado",
  };
  return value[key] ? value[key] : "No existe";
};

export const translateStatusOpportunities = (key: string) => {
  const value: any = {
    //GENERAL STATUS
    RECEIVED: "Recibido",
    REJECTED: "Rechazada",
    IS_MONITORING: "Monitoreando",
    RELEASED_TO_INVESTMENT: "Liberado para inversión",
    INCOMPLETE: "Incompleto",
    CLOSED_TO_INVESTMENT: "Cerrado para inversión",
    OPEN_TO_INVESTMENT: "Abierto a inversión",
    //ANALYST STATUS
    ASSIGNED_ANALYST: "Analista Asignado",
    PROGRAMMED_ANALYST: "Programado Analista",
    EVALUATED_ANALYST: "Evaluado Analista",
    RE_ASSIGNED_ANALYST: "Analista Reasignado",
    PROGRAMMING_ANALYST: "Programación Analista",
    //PARTNER STATUS
    ASSIGNED_PARTNER: "Asignado Partner",
    RE_ASSIGNED_PARTNER: "Reasignado Partner",
    PROGRAMMING_PARTNER: "Programación Partner",
    PROGRAMMED_PARTNER: "Programado Partner",
    EVALUATED_PARTNER: "Evaluación Partner",
    //COMMITTEE STATUS
    EVALUATION_COMMITTEE: "Comité de Evaluación",
    APPROVED_COMMITTEE: "Liberada",
    ASSIGNED_COMMITTEE: "Asignado Comité",
    IN_REVIEW: "En revision",
    CANCELLED: "Cancelado",
    IN_INVESTMENT_PROCESS: "Proceso de inversión",
    PASS: "Pasada",
    EXPIRED: "Expirada",
    INVESTED: "Invertida",
  };
  return value[key] ? value[key] : "No existe";
};
export const translateStatusStartups = (key: string) => {
  const value: any = {
    //GENERAL STATUS
    INCOMPLETE: "Incompleta",
    CANCELLED: "Cancelado",
    OPEN_TO_INVESTMENT: "Abierto a inversión",
    IN_INVESTMENT_PROCESS: "Proceso de inversión",
    CLOSED_TO_INVESTMENT: "Cerrada a inversión",
    PASS: "Pasada",
    IN_REVIEW: "En revision",
    EXPIRED: "Expirada",
    INVESTED: "Invertida",
  };
  return value[key] ? value[key] : "No existe";
};

/* export const translateStatusStartup =(key: string) =>{
  const value:any={
    
  }
} */
