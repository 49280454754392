import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

interface IReturnValue<T> {
  value: T;
  onChange: (value: T) => void;
}

function useParamTab<T>(initialTab: T): IReturnValue<T> {
  const [value, setValue] = useState<T>(initialTab);
  const history = useHistory();

  useEffect(() => {
    history.replace(`${history.location.pathname}?Tab=${value}`);
  }, []);

  const onChange = (value: T) => {
    setValue(value);
    history.replace(`${history.location.pathname}?Tab=${value}`);
  };

  return {
    value,
    onChange,
  };
}

export default useParamTab;
