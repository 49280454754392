import { Dispatch } from "redux";

import { getUsers } from "../../services/users";

export const LOADING_ADMIN = "LOADING_ADMIN";

export const FETCH_ADMINS_PAGINATED = "FETCH_ADMIN_PAGINATED";
export const fetchUsers = () => async (dispatch: Dispatch) => {
  dispatch({ type: LOADING_ADMIN, payload: true });
  try {
    const response = await getUsers();
    const data = response.data.payload;

    dispatch({
      type: FETCH_ADMINS_PAGINATED,
      payload: data,
    });
  } catch {
    dispatch({ type: LOADING_ADMIN, payload: false });
  } finally {
    dispatch({ type: LOADING_ADMIN, payload: false });
  }
};
export const FETCH_ADMINS = "FETCH_ADMINS";
export const fetchAdmins = () => async (dispatch: Dispatch) => {
  dispatch({ type: LOADING_ADMIN, payload: true });
  try {
    const response = await getUsers();
    const data = response.data.payload as any;
    dispatch({
      type: FETCH_ADMINS_PAGINATED,
      payload: data,
    });
  } catch {
    dispatch({ type: LOADING_ADMIN, payload: false });
  } finally {
    dispatch({ type: LOADING_ADMIN, payload: false });
  }
};

export const CHANGE_PAGE_ADMINS = "CHANGE_PAGE_ADMINS";
export const changePageAdmins = (value: number) => {
  return {
    type: CHANGE_PAGE_ADMINS,
    payload: value,
  };
};
export const CHANGE_LIMIT_ADMINS = "CHANGE_LIMIT_ADMINS";

export const changeLimitAdmins = (value: number) => {
  return {
    type: CHANGE_LIMIT_ADMINS,
    payload: value,
  };
};

export const CLEAR_STATE_ADMINS = "CLEAR_STATE_ADMINS";
export const clearStateAdmins = () => {
  return {
    type: CLEAR_STATE_ADMINS,
  };
};

export const CHANGE_ARRAY_USERS = "CHANGE_ARRAY_USERS";
export const changeArrayUsers = (value: any) => {
  return {
    type: CHANGE_ARRAY_USERS,
    payload: value,
  };
};

export const RELOAD_REDUCER_USER = "RELOAD_REDUCER_USER";
export const reloadReducerUser = () => {
  return {
    type: RELOAD_REDUCER_USER,
  };
};
