import { ReactNode } from "react";
import * as yup from "yup";

import { IFileObject } from "../../../../components/CustomInputFile/CustomInputFile";
import {
  CAMPO_REQUERIDO,
  CORREO_NO_VALIDO,
  NUMERO_NO_VALIDO,
} from "../../../../utils/text/inputs";
import { LINKEDIN_URL_VALIDA } from "../../../../utils/text/resetForm";
import { regexpLinkedin } from "../../../../utils/validations/linkedinValidation";

export interface IMainCardProps {
  child?: ReactNode;
  id: string;
}

export type FormType = {
  avatar: IFileObject | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  nationalityCountry: string;
  residenceCountry: string;
  residenceCity: string;
  membershipType: string;
  membershipStartDate: string;
  linkedInProfile: string;
  hasExperienceInvesting: boolean;
  interestToEnter: string;
  projectInvesting: number;
  cv: IFileObject | null;
  aboutMe: string;
  phoneCountryCode: string;
  profession: string;
  investVentureCapital: boolean;
  interestIndustries: string[];
  memberStartedDate: Date;
  howFindAngelHub: string;
  profileIdentify: string;
};

export const memberSchema = yup.object({
  email: yup.string().email(CORREO_NO_VALIDO).required(CAMPO_REQUERIDO),
  firstName: yup.string().required(CAMPO_REQUERIDO),
  lastName: yup.string().required(CAMPO_REQUERIDO),
  phoneNumber: yup
    .string()
    .required(CAMPO_REQUERIDO)
    .matches(/^[0-9]{10}|[0-9]{12}$/, NUMERO_NO_VALIDO),
  phoneCountryCode: yup.string(),
  nationalityCountry: yup.string().nullable().required(CAMPO_REQUERIDO),
  residenceCountry: yup.string().nullable().required(CAMPO_REQUERIDO),
  residenceCity: yup.string().required(CAMPO_REQUERIDO),
  membershipType: yup.string(),
  membershipStartDate: yup.string(),
  linkedInProfile: yup
    .string()
    .matches(regexpLinkedin, LINKEDIN_URL_VALIDA)
    .when("cv", {
      is: null,
      then: yup.string().required(CAMPO_REQUERIDO),
      otherwise: yup.string().optional(),
    }),
  cv: yup.mixed(),
  aboutMe: yup.string().nullable(false).typeError(CAMPO_REQUERIDO).optional(),
  profession: yup.string(),
  interestIndustries: yup.array().of(yup.string()),
  hasExperienceInvesting: yup.boolean(),
  investVentureCapital: yup.boolean(),
});
