export const stylesInfoEvaluations = {
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap-reverse",
  },
  partner: {
    fontSize: "16px",
    color: "#4A88C2",
  },
  subContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    minWidth: "260px",
  },
  wrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
  },
};
