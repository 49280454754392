export const demonyms = [
  {
    value: "AFGANA",
    label: "Afgana",
  },
  {
    value: "ALBANESA",
    label: "Albanesa",
  },
  {
    value: "ALEMANA",
    label: "Alemana",
  },
  {
    value: "ANDORRANA",
    label: "Andorrana",
  },
  {
    value: "ANGOLEÑA",
    label: "Angoleña",
  },
  {
    value: "ANTIGUANA",
    label: "Antiguana",
  },
  {
    value: "SAUDÍ",
    label: "Saudí",
  },
  {
    value: "ARGELINA",
    label: "Argelina",
  },
  {
    value: "ARGENTINA",
    label: "Argentina",
  },
  {
    value: "ARMENIA",
    label: "Armenia",
  },
  {
    value: "ARUBEÑA",
    label: "Arubeña",
  },
  {
    value: "AUSTRALIANA",
    label: "Australiana",
  },
  {
    value: "AUSTRIACA",
    label: "Austriaca",
  },
  {
    value: "AZERBAIYANA",
    label: "Azerbaiyana",
  },
  {
    value: "BAHAMEÑA",
    label: "Bahameña",
  },
  {
    value: "BANGLADESÍ",
    label: "Bangladesí",
  },
  {
    value: "BARBADENSE",
    label: "Barbadense",
  },
  {
    value: "BAREINÍ",
    label: "Bareiní",
  },
  {
    value: "BELGA",
    label: "Belga",
  },
  {
    value: "BELICEÑA",
    label: "Beliceña",
  },
  {
    value: "BENINÉSA",
    label: "Beninésa",
  },
  {
    value: "BIELORRUSA",
    label: "Bielorrusa",
  },
  {
    value: "BIRMANA",
    label: "Birmana",
  },
  {
    value: "BOLIVIANA",
    label: "Boliviana",
  },
  {
    value: "BOSNIA",
    label: "Bosnia",
  },
  {
    value: "BOTSUANA",
    label: "Botsuana",
  },
  {
    value: "BRASILEÑA",
    label: "Brasileña",
  },
  {
    value: "BRUNEANA",
    label: "Bruneana",
  },
  {
    value: "BÚLGARA",
    label: "Búlgara",
  },
  {
    value: "BURKINÉS",
    label: "Burkinés",
  },
  {
    value: "BURUNDÉSA",
    label: "Burundésa",
  },
  {
    value: "BUTANÉSA",
    label: "Butanésa",
  },
  {
    value: "CABOVERDIANA",
    label: "Caboverdiana",
  },
  {
    value: "CAMBOYANA",
    label: "Camboyana",
  },
  {
    value: "CAMERUNESA",
    label: "Camerunesa",
  },
  {
    value: "CANADIENSE",
    label: "Canadiense",
  },
  {
    value: "CATARÍ",
    label: "Catarí",
  },
  {
    value: "CHADIANA",
    label: "Chadiana",
  },
  {
    value: "CHILENA",
    label: "Chilena",
  },
  {
    value: "CHINA",
    label: "China",
  },
  {
    value: "CHIPRIOTA",
    label: "Chipriota",
  },
  {
    value: "VATICANA",
    label: "Vaticana",
  },
  {
    value: "COLOMBIANA",
    label: "Colombiana",
  },
  {
    value: "COMORENSE",
    label: "Comorense",
  },
  {
    value: "NORCOREANA",
    label: "Norcoreana",
  },
  {
    value: "SURCOREANA",
    label: "Surcoreana",
  },
  {
    value: "MARFILEÑA",
    label: "Marfileña",
  },
  {
    value: "COSTARRICENSE",
    label: "Costarricense",
  },
  {
    value: "CROATA",
    label: "Croata",
  },
  {
    value: "CUBANA",
    label: "Cubana",
  },
  {
    value: "DANÉSA",
    label: "Danésa",
  },
  {
    value: "DOMINIQUÉS",
    label: "Dominiqués",
  },
  {
    value: "ECUATORIANA",
    label: "Ecuatoriana",
  },
  {
    value: "EGIPCIA",
    label: "Egipcia",
  },
  {
    value: "SALVADOREÑA",
    label: "Salvadoreña",
  },
  {
    value: "EMIRATÍ",
    label: "Emiratí",
  },
  {
    value: "ERITREA",
    label: "Eritrea",
  },
  {
    value: "ESLOVACA",
    label: "Eslovaca",
  },
  {
    value: "ESLOVENA",
    label: "Eslovena",
  },
  {
    value: "ESPAÑOLA",
    label: "Española",
  },
  {
    value: "ESTADOUNIDENSE",
    label: "Estadounidense",
  },
  {
    value: "ESTONIA",
    label: "Estonia",
  },
  {
    value: "ETÍOPE",
    label: "Etíope",
  },
  {
    value: "FILIPINA",
    label: "Filipina",
  },
  {
    value: "FINLANDÉSA",
    label: "Finlandésa",
  },
  {
    value: "FIYIANA",
    label: "Fiyiana",
  },
  {
    value: "FRANCÉSA",
    label: "Francésa",
  },
  {
    value: "GABONÉSA",
    label: "Gabonésa",
  },
  {
    value: "GAMBIANA",
    label: "Gambiana",
  },
  {
    value: "GEORGIANA",
    label: "Georgiana",
  },
  {
    value: "GIBRALTAREÑA",
    label: "Gibraltareña",
  },
  {
    value: "GHANÉSA",
    label: "Ghanésa",
  },
  {
    value: "GRANADINA",
    label: "Granadina",
  },
  {
    value: "GRIEGA",
    label: "Griega",
  },
  {
    value: "GROENLANDÉSA",
    label: "Groenlandésa",
  },
  {
    value: "GUATEMALTECA",
    label: "Guatemalteca",
  },
  {
    value: "ECUATOGUINEANA",
    label: "Ecuatoguineana",
  },
  {
    value: "GUINEANA",
    label: "Guineana",
  },
  {
    value: "GUYANESA",
    label: "Guyanesa",
  },
  {
    value: "HAITIANA",
    label: "Haitiana",
  },
  {
    value: "HONDUREÑA",
    label: "Hondureña",
  },
  {
    value: "HÚNGARA",
    label: "Húngara",
  },
  {
    value: "HINDÚ",
    label: "Hindú",
  },
  {
    value: "INDONESIA",
    label: "Indonesia",
  },
  {
    value: "IRAQUÍ",
    label: "Iraquí",
  },
  {
    value: "IRANÍ",
    label: "Iraní",
  },
  {
    value: "IRLANDÉSA",
    label: "Irlandésa",
  },
  {
    value: "ISLANDÉSA",
    label: "Islandésa",
  },
  {
    value: "COOKIANA",
    label: "Cookiana",
  },
  {
    value: "MARSHALÉSA",
    label: "Marshalésa",
  },
  {
    value: "SALOMONENSE",
    label: "Salomonense",
  },
  {
    value: "ISRAELÍ",
    label: "Israelí",
  },
  {
    value: "ITALIANA",
    label: "Italiana",
  },
  {
    value: "JAMAIQUINA",
    label: "Jamaiquina",
  },
  {
    value: "JAPONÉSA",
    label: "Japonésa",
  },
  {
    value: "JORDANA",
    label: "Jordana",
  },
  {
    value: "KAZAJA",
    label: "Kazaja",
  },
  {
    value: "KENIATA",
    label: "Keniata",
  },
  {
    value: "KIRGUISA",
    label: "Kirguisa",
  },
  {
    value: "KIRIBATIANA",
    label: "Kiribatiana",
  },
  {
    value: "KUWAITÍ",
    label: "Kuwaití",
  },
  {
    value: "LAOSIANA",
    label: "Laosiana",
  },
  {
    value: "LESOTENSE",
    label: "Lesotense",
  },
  {
    value: "LETÓNA",
    label: "Letóna",
  },
  {
    value: "LIBANÉSA",
    label: "Libanésa",
  },
  {
    value: "LIBERIANA",
    label: "Liberiana",
  },
  {
    value: "LIBIA",
    label: "Libia",
  },
  {
    value: "LIECHTENSTEINIANA",
    label: "Liechtensteiniana",
  },
  {
    value: "LITUANA",
    label: "Lituana",
  },
  {
    value: "LUXEMBURGUÉSA",
    label: "Luxemburguésa",
  },
  {
    value: "MALGACHE",
    label: "Malgache",
  },
  {
    value: "MALASIA",
    label: "Malasia",
  },
  {
    value: "MALAUÍ",
    label: "Malauí",
  },
  {
    value: "MALDIVA",
    label: "Maldiva",
  },
  {
    value: "MALIENSE",
    label: "Maliense",
  },
  {
    value: "MALTÉSA",
    label: "Maltésa",
  },
  {
    value: "MARROQUÍ",
    label: "Marroquí",
  },
  {
    value: "MARTINIQUÉS",
    label: "Martiniqués",
  },
  {
    value: "MAURICIANA",
    label: "Mauriciana",
  },
  {
    value: "MAURITANA",
    label: "Mauritana",
  },
  {
    value: "MEXICANA",
    label: "Mexicana",
  },
  {
    value: "MICRONESIA",
    label: "Micronesia",
  },
  {
    value: "MOLDAVA",
    label: "Moldava",
  },
  {
    value: "MONEGASCA",
    label: "Monegasca",
  },
  {
    value: "MONGOLA",
    label: "Mongola",
  },
  {
    value: "MONTENEGRINA",
    label: "Montenegrina",
  },
  {
    value: "MOZAMBIQUEÑA",
    label: "Mozambiqueña",
  },
  {
    value: "NAMIBIA",
    label: "Namibia",
  },
  {
    value: "NAURUANA",
    label: "Nauruana",
  },
  {
    value: "NEPALÍ",
    label: "Nepalí",
  },
  {
    value: "NICARAGÜENSE",
    label: "Nicaragüense",
  },
  {
    value: "NIGERINA",
    label: "Nigerina",
  },
  {
    value: "NORUEGA",
    label: "Noruega",
  },
  {
    value: "NEOZELANDÉSA",
    label: "Neozelandésa",
  },
  {
    value: "OMANÍ",
    label: "Omaní",
  },
  {
    value: "NEERLANDÉSA",
    label: "Neerlandésa",
  },
  {
    value: "PAKISTANÍ",
    label: "Pakistaní",
  },
  {
    value: "PALAUANA",
    label: "Palauana",
  },
  {
    value: "PALESTINA",
    label: "Palestina",
  },
  {
    value: "PANAMEÑA",
    label: "Panameña",
  },
  {
    value: "PAPÚ",
    label: "Papú",
  },
  {
    value: "PARAGUAYA",
    label: "Paraguaya",
  },
  {
    value: "PERUANA",
    label: "Peruana",
  },
  {
    value: "POLACA",
    label: "Polaca",
  },
  {
    value: "PORTUGUÉSA",
    label: "Portuguésa",
  },
  {
    value: "PUERTORRIQUEÑA",
    label: "Puertorriqueña",
  },
  {
    value: "BRITÁNICA",
    label: "Británica",
  },
  {
    value: "CENTROAFRICANA",
    label: "Centroafricana",
  },
  {
    value: "CHECA",
    label: "Checa",
  },
  {
    value: "MACEDONIA",
    label: "Macedonia",
  },
  {
    value: "CONGOLEÑA",
    label: "Congoleña",
  },
  {
    value: "DOMINICANA",
    label: "Dominicana",
  },
  {
    value: "SUDAFRICANA",
    label: "Sudafricana",
  },
  {
    value: "RUANDÉSA",
    label: "Ruandésa",
  },
  {
    value: "RUMANA",
    label: "Rumana",
  },
  {
    value: "RUSA",
    label: "Rusa",
  },
  {
    value: "SAMOANA",
    label: "Samoana",
  },
  {
    value: "CRISTOBALEÑA",
    label: "Cristobaleña",
  },
  {
    value: "SANVICENTINA",
    label: "Sanvicentina",
  },
  {
    value: "SANTALUCENSE",
    label: "Santalucense",
  },
  {
    value: "SANTOTOMENSE",
    label: "Santotomense",
  },
  {
    value: "SENEGALÉSA",
    label: "Senegalésa",
  },
  {
    value: "SERBIA",
    label: "Serbia",
  },
  {
    value: "SERBSEYCHELLENSE",
    label: "Serbseychellense",
  },
  {
    value: "SIERRALEONÉSA",
    label: "Sierraleonésa",
  },
  {
    value: "SINGAPURENSE",
    label: "Singapurense",
  },
  {
    value: "SIRIA",
    label: "Siria",
  },
  {
    value: "SOMALÍ",
    label: "Somalí",
  },
  {
    value: "CEILANÉSA",
    label: "Ceilanésa",
  },
  {
    value: "SUAZI",
    label: "Suazi",
  },
  {
    value: "SURSUDANÉSA",
    label: "Sursudanésa",
  },
  {
    value: "SUDANÉSA",
    label: "Sudanésa",
  },
  {
    value: "SUECA",
    label: "Sueca",
  },
  {
    value: "SUIZA",
    label: "Suiza",
  },
  {
    value: "SURINAMESA",
    label: "Surinamesa",
  },
  {
    value: "TAILANDÉSA",
    label: "Tailandésa",
  },
  {
    value: "TANZANA",
    label: "Tanzana",
  },
  {
    value: "TAYIKA",
    label: "Tayika",
  },
  {
    value: "TIMORENSE",
    label: "Timorense",
  },
  {
    value: "TOGOLÉSA",
    label: "Togolésa",
  },
  {
    value: "TONGANA",
    label: "Tongana",
  },
  {
    value: "TRINITENSE",
    label: "Trinitense",
  },
  {
    value: "TUNECINA",
    label: "Tunecina",
  },
  {
    value: "TURCOMANA",
    label: "Turcomana",
  },
  {
    value: "TURCA",
    label: "Turca",
  },
  {
    value: "TUVALUANA",
    label: "Tuvaluana",
  },
  {
    value: "UCRANIANA",
    label: "Ucraniana",
  },
  {
    value: "UGANDÉSA",
    label: "Ugandésa",
  },
  {
    value: "URUGUAYA",
    label: "Uruguaya",
  },
  {
    value: "UZBEKA",
    label: "Uzbeka",
  },
  {
    value: "VANUATUENSE",
    label: "Vanuatuense",
  },
  {
    value: "VENEZOLANA",
    label: "Venezolana",
  },
  {
    value: "VIETNAMITA",
    label: "Vietnamita",
  },
  {
    value: "YEMENÍ",
    label: "Yemení",
  },
  {
    value: "YIBUTIANA",
    label: "Yibutiana",
  },
  {
    value: "ZAMBIANA",
    label: "Zambiana",
  },
  {
    value: "ZIMBABUENSE",
    label: "Zimbabuense",
  },
];
