/* eslint-disable react-hooks/exhaustive-deps */
import BallotIcon from "@mui/icons-material/Ballot";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import TodayIcon from "@mui/icons-material/Today";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { UseFormReturn, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import SplitButton from "../../../../../../../components/SplitButton/SplitButton";
import { OptionButtonSplit } from "../../../../../../../components/SplitButton/types";
import {
  DATE_FORMAT,
  EStatusReleasedOpportunity,
} from "../../../../../../../constants";
import { useLoading } from "../../../../../../../hooks/useLoading";
import { openModal as openModalAction } from "../../../../../../../redux/actions/modal";
import {
  useDispatch,
  useSelector,
} from "../../../../../../../redux/typedHooks";
import { opportunitiesReleasedChangeIntention } from "../../../../../../../services/opportunitiesReleased";
import { displayToast } from "../../../../../../../utils/helpers/displayToast";
import { ERolesCode } from "../../../../../../routes/role-catalog";
import { FormType } from "../../../../MainCard/types";
import Layout from "../../../Layout/Layout";
import { styles as mainStyles } from "../../../styles";
import { RESPONSIVE_BUTTON, RESPONSIVE_BUTTON_INVEST } from "../../../types";
import { InterestedCard } from "../InterestedCard/InterestedCard";
import { InvestModal } from "../InvestModal/InvestModal";
import { InvestCard } from "../InvestingCard/InvestingCard";
import { styles } from "../styles";
import { FormTypeInvestRound } from "../types";
import {
  VALID_ROLES_TO_INVEST,
  VALID_ROLES_TO_USE_EDIT_BUTTON,
  VALID_STATUS_TO_EDIT_INVESTMENT,
  VALID_STATUS_TO_INVEST,
} from "../validation";
interface IProps {
  hook: UseFormReturn<FormTypeInvestRound>;
  loading: boolean;
  isInvestMode: boolean;
  changeInvestMode: (v: boolean) => void;
  setReload: (v: boolean) => void;
  reload: boolean;
  isInvested?: boolean;
}

const InvestMode: React.FC<IProps> = ({
  hook,
  loading,
  isInvestMode,
  changeInvestMode,
  reload,
  setReload,
  isInvested,
}) => {
  const { id } = useParams() as { id: string };
  const dispatch = useDispatch();
  const { handleClose: handleCloseLoading, handleOpen } = useLoading();
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const isActiveMemberShip = useSelector(
    (store) => store.loggedUser.loggedUser.user?.member?.isActiveMemberShip,
  );
  const [openModal, setOpenModal] = useState(false);
  const breakpoint = useMediaQuery(RESPONSIVE_BUTTON);
  const breakpointInvest = useMediaQuery(RESPONSIVE_BUTTON_INVEST);
  const mainCardHook = useFormContext<FormType>();

  const status = mainCardHook.watch("status");
  const isMember = user.role.code === ERolesCode.MEMBER;

  const validRolesToEdit = VALID_ROLES_TO_USE_EDIT_BUTTON.includes(
    user.role.code,
  );

  const validRolesToInvest = VALID_ROLES_TO_INVEST.includes(user.role.code);
  const validStatusToInvest = VALID_STATUS_TO_INVEST.includes(
    status as EStatusReleasedOpportunity,
  );

  const validationToMemberRetireInvestment = isMember
    ? VALID_STATUS_TO_EDIT_INVESTMENT.includes(
        status as EStatusReleasedOpportunity,
      )
    : true;

  const investIntention = mainCardHook
    .watch("usersInterested")
    .map((item: any) => item.user._id)
    .includes(user._id);

  const userIsInvesting = mainCardHook
    .watch("usersWantToInvest")
    .map((item: any) => item.user._id)
    .includes(user._id);

  const userIsNotInterest = mainCardHook
    .watch("usersNotInterested")
    .includes(user._id);

  const userInvestMoney = mainCardHook
    .watch("usersWantToInvest")
    .find((i) => i.user._id === user._id)?.money;

  const isClosed =
    mainCardHook.watch("status") ===
    EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT;

  const rawHookValues = hook.watch();

  function openModalHandler() {
    setOpenModal(!openModal);
  }

  const [isEditing, setIsEditing] = useState(false);

  function handleChangeEditMode() {
    setIsEditing(!isEditing);
    changeInvestMode(false);
  }

  async function changeIntention(intention: boolean) {
    await opportunitiesReleasedChangeIntention({
      type: intention ? "INTERESTED" : "NOT_INTERESTED",
      user: user._id,
      startupId: id,
      value: 5000,
    });
    setReload(!reload);
    intention && displayToast("success", "Ahora sigues a esta Startup");
  }

  async function handleRemoveInvestment() {
    dispatch(
      openModalAction({
        title: "Advertencia",
        description: "¿Estas seguro que deseas retirar la inversion?",
        action: async () => {
          handleOpen();
          try {
            await opportunitiesReleasedChangeIntention({
              startupId: id,
              type: "LEAVE_INVESTMENT",
              user: user._id,
            });
            const newValues = mainCardHook
              .watch("usersWantToInvest")
              .filter((i) => i.user._id !== user._id);
            mainCardHook.setValue("usersWantToInvest", newValues);
            setReload(!reload);
          } catch (error) {
            displayToast("error", "No se pudo retirar inversion correctamente");
          } finally {
            handleCloseLoading();
          }
        },
      }),
    );
  }

  async function handleNotLetInvest() {
    dispatch(
      openModalAction({
        title: "Advertencia.",
        hideSecondaryButton: true,
        description:
          "Su membresía se encuentra vencida, por favor haga el pago correspondiente, adjunte su comprobante de pago a su perfil y contacte a Angel Hub para reactivarsela a la brevedad.",
      }),
    );
  }

  const optionsButton: OptionButtonSplit[] = [
    {
      label: "Editar",
      visible: !validRolesToInvest && isInvestMode,
      onClick: handleChangeEditMode,
    },
    {
      label: userIsInvesting ? "Editar inversion" : "Invertir",
      visible: validRolesToInvest,
      onClick: isActiveMemberShip ? openModalHandler : handleNotLetInvest,
    },
    {
      label: "Retirar inversion",
      visible: userIsInvesting,
      onClick: handleRemoveInvestment,
    },
  ];

  return (
    <Layout>
      <Layout.Button>
        {validRolesToEdit ? (
          <Button
            sx={{
              ...mainStyles.actionsButtons,
              display: breakpoint ? "none" : "flex",
            }}
            variant="contained"
            onClick={handleChangeEditMode}
            disabled={isInvested}
          >
            Editar
          </Button>
        ) : userIsInvesting && validationToMemberRetireInvestment ? (
          <>
            {!breakpointInvest && (
              <SplitButton
                title="Acciones"
                icon={<BallotIcon />}
                disabled={isInvested}
                options={optionsButton}
              />
            )}
          </>
        ) : (
          validStatusToInvest &&
          isActiveMemberShip && (
            <Button
              sx={{
                ...mainStyles.actionsButtons,
                display: breakpointInvest ? "none" : "flex",
              }}
              variant="contained"
              onClick={openModalHandler}
              disabled={isInvested}
            >
              Invertir
            </Button>
          )
        )}
      </Layout.Button>
      <Layout.Panel loading={loading}>
        <Grid container spacing={3}>
          {openModal && (
            <InvestModal
              openModal={setOpenModal}
              setReload={() => setReload(!reload)}
              reload={reload}
              defaultValue={userInvestMoney}
            />
          )}
          <Grid item xs={12} pt={0}>
            <Box sx={styles.investButtonContainer}>
              {validRolesToInvest ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ ...styles.interestedContainer.title }}>
                    ¿Me interesa esta Startup?
                  </Typography>
                  <Button
                    disabled={
                      investIntention || userIsInvesting || !validStatusToInvest
                    }
                    onClick={() => changeIntention(true)}
                    sx={{ ...styles.interestedContainer.buttonTextColor }}
                    startIcon={
                      investIntention ? (
                        <ThumbUpIcon />
                      ) : (
                        <ThumbUpOutlinedIcon />
                      )
                    }
                  >
                    Si
                  </Button>
                  <Button
                    disabled={
                      userIsNotInterest ||
                      userIsInvesting ||
                      !validStatusToInvest
                    }
                    onClick={() => changeIntention(false)}
                    sx={{ ...styles.interestedContainer.buttonTextColor }}
                    startIcon={
                      userIsNotInterest ? (
                        <ThumbDownAltIcon />
                      ) : (
                        <ThumbDownOffAltIcon />
                      )
                    }
                  >
                    No
                  </Button>
                </Box>
              ) : (
                <Box sx={{ display: "flex" }} />
              )}
              {breakpoint && !isClosed && validRolesToEdit && (
                <Box>
                  <SplitButton
                    title="Editar"
                    icon={<BallotIcon />}
                    isMobile
                    disabled={isInvested}
                    options={[
                      {
                        label: "Editar",
                        visible: true,
                        onClick: handleChangeEditMode,
                      },
                    ]}
                  />
                </Box>
              )}
              {validStatusToInvest &&
                isActiveMemberShip &&
                breakpointInvest &&
                !validRolesToEdit && (
                  <Box>
                    <SplitButton
                      title="Acciones"
                      icon={<BallotIcon />}
                      isMobile
                      options={optionsButton}
                    />
                  </Box>
                )}
            </Box>
          </Grid>
          <Grid item xs={12} sx={styles.detailsContainer}>
            <Box sx={mainStyles.details}>
              {rawHookValues.expirationDate && (
                <Box
                  sx={{ display: "flex", flexWrap: "wrap", alignItems: "end" }}
                >
                  <Typography
                    sx={{
                      ...mainStyles.detailsText,
                      minWidth: "264px",
                    }}
                  >
                    <TodayIcon sx={{ ...mainStyles.detailsIcons }} />
                    Cierre de la ventana de inversión:
                    <Typography
                      sx={{
                        ...mainStyles.detailsText.detailsCloseDate,
                      }}
                    >
                      {dayjs(rawHookValues.expirationDate).format("DD/MM/YYYY")}
                    </Typography>
                  </Typography>
                </Box>
              )}
              {rawHookValues.dateAma && (
                <Typography sx={{ ...mainStyles.detailsText }}>
                  <TodayIcon sx={{ ...mainStyles.detailsIcons }} />
                  AMA:
                  <Typography sx={{ ...mainStyles.detailsText.detailsDates }}>
                    {dayjs(rawHookValues.dateAma).format(
                      DATE_FORMAT + " - HH:mm:ss",
                    )}
                  </Typography>
                </Typography>
              )}
            </Box>
          </Grid>
          {(rawHookValues.dateAma || rawHookValues.expirationDate) && (
            <Grid item xs={12}>
              <hr />
            </Grid>
          )}
          <Grid item xs={12} sx={{ ...styles.investCardContainer }}>
            <InterestedCard
              hook={mainCardHook}
              requiredInvestment={hook.watch("requiredInvestment")}
            />
            <InvestCard hook={hook} userIsInvesting={userIsInvesting} />
          </Grid>
        </Grid>
      </Layout.Panel>
    </Layout>
  );
};

export default InvestMode;
