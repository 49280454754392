import { Delete, FileDownload } from "@mui/icons-material";
import {
  Grid,
  Card,
  CardMedia,
  Typography,
  Button,
  useMediaQuery,
  Box,
} from "@mui/material";

import pdf from "../../../../../../../assets/images/pdf.png";
import { useLoading } from "../../../../../../../hooks/useLoading";
import { openModal } from "../../../../../../../redux/actions/modal";
import { useDispatch } from "../../../../../../../redux/typedHooks";
import { handleDownloadFile } from "../../../../../../../utils/helpers/downloadFile";
import { IFile } from "../../../../../../../utils/types/common";
import { styles } from "../styles";

interface IProps {
  file: IFile;
  onDelete: (id: string) => Promise<void>;
}

export const FileCard: React.FC<IProps> = ({ file, onDelete }) => {
  const dispatch = useDispatch();
  const { handleClose, handleOpen } = useLoading();
  const isMobile = useMediaQuery("(max-width:730px)");

  function handleDelete() {
    dispatch(
      openModal({
        title: "Eliminar",
        description: "¿Estas seguro que deseas eliminar el archivo?",
        action: async () => {
          handleOpen();
          await onDelete(file._id);
          handleClose();
        },
      }),
    );
  }

  return (
    <Grid item>
      <Card sx={isMobile ? styles.mobileCardContainer : styles.cardContainer}>
        <Box height={"100%"} display={"flex"} flexDirection="column">
          <div style={{ backgroundColor: "#F8F8F8" }}>
            <CardMedia component="img" image={pdf} sx={styles.cardImage} />
          </div>
          <Box flex={"1"} padding="0px 20px">
            <Typography sx={styles.fileName}>{file.name}</Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent="space-between"
            flex={0}
            padding="20px 10px"
          >
            <Button
              onClick={handleDelete}
              startIcon={<Delete />}
              sx={styles.deleteButton}
            >
              Eliminar
            </Button>
            <Button
              onClick={() => handleDownloadFile(file)}
              startIcon={<FileDownload />}
              sx={styles.downloadButton}
            >
              Descargar
            </Button>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};
