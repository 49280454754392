import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";

import { CustomSort } from "../../../components/DataGrid";
import { EStatusReleasedOpportunity } from "../../../constants";
import Ellipsis from "../../../utils/components/ellipsis";
import { currencyFormat } from "../../../utils/helpers/currencyFormatter";
import { limitCurrency } from "../../../utils/helpers/limitCurrency";
import { translateStatusOpportunities } from "../../../utils/text/translates";

export const InvestmentOpportunityTableCols = (
  column: GridColDef,
  user: any,
): GridColDef[] => {
  return [
    {
      headerName: "Startup",
      field: "name",
      hideSortIcons: true,
      minWidth: 300,
      flex: 0.7,
      align: "left",
      headerAlign: "left",
      renderCell: (data) => {
        const startupId = data.row.id;
        let url = "";
        startupId ? (url = `details-startup/${startupId}`) : (url = "");
        return (
          <Ellipsis
            value={
              <NavLink style={{ color: "#4A88C2" }} to={url}>
                {data.row.name}
              </NavLink>
            }
          />
        );
      },
    },
    {
      headerName: "Etapa",
      field: "stage",
      align: "left",
      headerAlign: "left",
      hideSortIcons: true,
      minWidth: 170,
      maxWidth: 170,
      flex: 0.7,
    },
    {
      headerName: "Industria",
      field: "industry",
      align: "left",
      headerAlign: "left",
      hideSortIcons: true,
      minWidth: 145,
      maxWidth: 145,
      flex: 0.7,
    },
    {
      headerName: "País origen",
      field: "country",
      hideSortIcons: true,
      minWidth: 145,
      maxWidth: 145,
      flex: 0.7,
      align: "left",
      headerAlign: "left",
    },
    {
      headerName: "Estatus",
      field: "status",
      hideSortIcons: true,
      flex: 0.5,
      minWidth: 195,
      maxWidth: 195,
      align: "left",
      headerAlign: "left",
      valueGetter: (data) => {
        return `${translateStatusOpportunities(data.row.status)}`;
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Inversión Req.(USD)",
      field: "requiredInvestment",
      hideSortIcons: true,
      minWidth: 170,
      maxWidth: 170,
      flex: 0.7,
      align: "right",
      headerAlign: "right",
      renderCell: (data) => {
        return (
          <Ellipsis
            value={`${limitCurrency(
              currencyFormat(data.row.requiredInvestment) as string,
            )}`}
          />
        );
      },
      valueGetter: (data) => {
        return parseFloat(data.row.requiredInvestment);
      },
    },
    {
      headerName: "Valuación USD",
      field: "valuation",
      hideSortIcons: true,
      minWidth: 170,
      maxWidth: 170,
      align: "right",
      headerAlign: "right",
      flex: 0.7,
      valueGetter: (data) => {
        return parseFloat(data.row.valuation);
      },
      renderCell: (data) => (
        <Ellipsis
          value={`${limitCurrency(
            currencyFormat(data.row.valuation) as string,
          )}`}
        />
      ),
    },
    {
      headerName: "Días restantes",
      field: "remainingDays",
      hideSortIcons: true,
      minWidth: 130,
      maxWidth: 130,
      flex: 0.7,
      align: "right",
      headerAlign: "left",
      valueGetter: (data) => {
        const expiration = dayjs(data.row.expirationDate).diff(dayjs(), "days");
        if (
          data.row.status === EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT
        ) {
          return 0;
        } else if (expiration < 0) {
          return 0;
        }

        return expiration + 1;
      },
      renderCell: ({ value }) => {
        let color = "green";

        if (value <= 10) {
          color = "red";
        }
        if (value >= 11 && value <= 21) {
          color = "orange";
        }

        return <p style={{ color }}>{parseInt(value)}</p>;
      },
    },
    {
      headerName: "Mi postura",
      field: "myPosture",
      hideSortIcons: true,
      minWidth: 150,
      maxWidth: 150,
      flex: 0.7,
      align: "left",
      headerAlign: "left",
      valueGetter: (data) => {
        const values = data.row;
        if (values.usersInterested.find((i: any) => i.user === user._id)) {
          return "Me interesa";
        }

        if (values.usersNotInterested.find((i: any) => i === user._id)) {
          return "No me interesa";
        }

        if (values.usersWantToInvest.find((i: any) => i.user === user._id)) {
          return "Quiero invertir";
        }
        return "Ninguna";
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={200 || colDef?.width}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },

    column,
  ];
};
