import { Close } from "@mui/icons-material";
import { Snackbar, IconButton, Alert, AlertColor } from "@mui/material";
import { useEffect, useState } from "react";

interface IProps {
  message: string;
  openSnack: boolean;
  severity: string;
}

export default function NotifySnackbar(props: IProps) {
  const { message, openSnack, severity } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(openSnack);
  }, [openSnack]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <Close fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={handleClose}
        action={action}
      >
        <Alert
          onClose={handleClose}
          severity={severity as AlertColor}
          sx={{ width: "100%", border: "1px solid" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
