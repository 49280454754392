import { Box, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useHistory } from "react-router-dom";

import { InvestmentIndicatorStyles } from "./style";

interface IInvestmentOpportunityProps {
  indicator: string;
  value: number;
  icon: React.ReactNode;
  url?: string;
}

const InvestmentIndicator: React.FC<IInvestmentOpportunityProps> = ({
  indicator,
  value,
  icon,
  url,
}) => {
  const history = useHistory();

  function handleRedirect() {
    if (url) {
      history.push(url);
    }
  }
  return (
    <Tooltip
      title={
        indicator === "Inversiones Ejecutadas" && "Histórico de inversiones"
      }
    >
      <Box sx={InvestmentIndicatorStyles}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h5">{indicator}</Typography>
          {icon}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography
            sx={{ cursor: "pointer" }}
            onClick={handleRedirect}
            variant="h3"
          >
            {value}
          </Typography>
        </Stack>
      </Box>
    </Tooltip>
  );
};

export default InvestmentIndicator;
