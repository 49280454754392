import { LinkedIn, Person } from "@mui/icons-material";
import { Link, Stack, Typography } from "@mui/material";

import mainStyles from "../../../../styles";

import { styles } from "./styles";

interface IBoardItemProps {
  role: string;
  name: string;
  linkedinUrl?: string;
}

const BoardItem: React.FC<IBoardItemProps> = (props) => {
  const { role, name, linkedinUrl } = props;

  if (!role && !name && !linkedinUrl) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1} marginBottom="0.5rem">
      <Stack direction={"column"} marginTop="0.15rem">
        <Person sx={mainStyles.icon} />
      </Stack>
      <Stack direction="column" gap="2px">
        <Typography sx={styles.label}>{role}</Typography>
        <Typography sx={styles.text}>{name}</Typography>
        <Stack
          direction="row"
          justifyContent={"flex-start"}
          alignItems="center"
        >
          <LinkedIn sx={styles.linkedinIcon} />
          <Link target="_blank" href={linkedinUrl} sx={styles.linkedinLink}>
            LINKEDIN
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default BoardItem;
