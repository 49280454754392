import FilePresentIcon from "@mui/icons-material/FilePresent";
import { Button } from "@mui/material";

interface IProps {
  title: string;
  url: string;
}
const FileAttached: React.FC<IProps> = ({ title, url }) => {
  const handleOpenWindow = () => {
    window.open(url);
  };

  return (
    <>
      <Button
        sx={{ fontSize: "12px", fontWeight: "900", color: "#4A88C2" }}
        startIcon={<FilePresentIcon />}
        onClick={handleOpenWindow}
      >
        {title}
      </Button>
    </>
  );
};

export default FileAttached;
