/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import { clearStateListOpportunities } from "../../redux/actions/opportunities";

import OpportunityList from "./OpportunitiesList";

const Opportunity: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(clearStateListOpportunities());
    };
  }, []);

  return (
    <>
      <PageTitle title="Lista de Aplicaciones de Oportunidad" />
      <MainLayout sx={{ flexDirection: "column", justifyContent: "start" }}>
        <OpportunityList />
      </MainLayout>
    </>
  );
};

export default Opportunity;
