import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { TextInput } from "../../../../../../../components/Inputs";
import { postPeriodicity } from "../../../../../../../services/startup";
import { displayToast } from "../../../../../../../utils/helpers/displayToast";
import { REPORTS_PERIODICITY } from "../../../../../../StartupRegister/constants";
import { styles as mainStyles } from "../../../styles";

interface IProps {
  openModal: Dispatch<SetStateAction<boolean>>;
  setReload: Dispatch<SetStateAction<boolean>>;
  reload: any;
}
type FormType = {
  periodicity: number;
};

export const PeriodicityModal: FC<IProps> = ({
  openModal,
  setReload,
  reload,
}) => {
  const { id } = useParams() as any;

  const hook = useForm<FormType>({
    mode: "all",
    defaultValues: {
      periodicity: 3,
    },
  });

  async function handlerPeriodicity(fields: FormType): Promise<void> {
    hook.setValue("periodicity", fields.periodicity);

    await postPeriodicity(
      {
        value: Number(fields.periodicity),
      },
      id,
    );

    setReload(!reload);
    openModal(false);
    displayToast(
      "success",
      `La periodicidad de tus reportes será cada ${fields.periodicity} meses`,
    );
  }

  return (
    <Grid
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(19, 13, 16, 0.8)",
        position: "absolute",
        top: "0px",
        right: "0px",
        zIndex: "1101",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          padding: "40px 56px",
          width: "528px",
          maxHeight: "688px",
          minHeight: "200px",
          background: "#FFFFFF",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid item xs={12} sm={12} md={12} sx={{ ...mainStyles.Info }}>
          <Box
            style={{
              width: "100%",
              minHeight: "100%",
            }}
          >
            <Typography sx={{ ...mainStyles.title }}>
              Elije la periodicidad de tus reportes
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "30px",
                flexWrap: "wrap",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  width: "50%",
                  gap: "20px",
                  justifyContent: "flex-end",
                }}
              >
                <Grid xs={12} md={12} lg={12}>
                  <Controller
                    control={hook.control}
                    name="periodicity"
                    render={({ field, fieldState }) => (
                      <TextInput
                        label="Selecciona la periodicidad"
                        required
                        {...field}
                        fullWidth
                        error={Boolean(fieldState.error)}
                        helperText={
                          Boolean(fieldState.error?.message) &&
                          fieldState.error?.message
                        }
                        select
                      >
                        {REPORTS_PERIODICITY.map((i, index) => (
                          <MenuItem value={i} key={index}>
                            {i}
                          </MenuItem>
                        ))}
                      </TextInput>
                    )}
                  />
                </Grid>
              </Box>
              <Box sx={{ display: "flex", gap: "20px", marginTop: "20px" }}>
                <Button
                  variant="outlined"
                  sx={{
                    ...mainStyles.actionsButtons,
                    background: "white",
                    color: "#76c34a",
                  }}
                  onClick={() => openModal(false)}
                >
                  CANCELAR
                </Button>
                <Button
                  onClick={hook.handleSubmit(handlerPeriodicity)}
                  variant="contained"
                  sx={{ ...mainStyles.actionsButtons }}
                >
                  Guardar
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};
