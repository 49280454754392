import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const getSignedUrl = (
  key: string,
  fileName: string,
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: "/get-signed-url/" + key + "/" + fileName,
    method: "GET",
  };

  return axios(config);
};

export { getSignedUrl };
