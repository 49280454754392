import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";

import pdf from "../../../assets/images/pdfimage.png";
import { downloadFileApplication } from "../../../services/application";
import { getMemberApplicationById } from "../../../services/memberApplication";
import { displayToast } from "../../../utils/helpers/displayToast";

export default function AttachedFiles({ id }: any) {
  const [files, setFiles] = useState<any[]>([]);
  useEffect(() => {
    getMemberApplicationById(id).then(({ data }) => {
      const user = data.payload as any;
      setFiles(user.cv);
    });
  }, [id]);

  const handleDownload = async (file: any) => {
    try {
      const { data } = await downloadFileApplication(file._id);
      fileDownload(data, `${file.name}`);
    } catch (error) {
      displayToast("error", "Error al descargar archivo");
    }
  };

  return (
    <>
      {files.map((file: any, index) => {
        return (
          <Card
            onClick={() => handleDownload(file)}
            style={{ maxWidth: "300px", cursor: "pointer" }}
            key={index}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Curriculum vitae
              </Typography>
            </CardContent>
            <CardMedia
              component="img"
              image={pdf}
              alt="pdf"
              style={{
                maxHeight: "152px",
                maxWidth: "152px",
                width: "100%",
                margin: "0px auto",
              }}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {`${file.name}`}
              </Typography>
            </CardContent>
          </Card>
        );
      })}
    </>
  );
}
