export const RESOURCE_LIBRARY_CATEGORIES = [
  "Capital",
  "Angel Investing",
  "Legal y Fiscal",
  "Mejores Prácticas",
];

export const RESOURCE_LIBRARY_TYPE_CONTENT = [
  "Reporte",
  "Video",
  "Noticia",
  "Paper",
];
