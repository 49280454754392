import { ReactNode } from "react";

export interface ITabListProps {
  children?: ReactNode;
}

export type TabParam =
  | "AttachedFiles"
  | "Evaluations"
  | "FinancialInfo"
  | "InvestRound"
  | "Reports";

export const RESPONSIVE_BUTTON = "(max-width:1731px)";
export const RESPONSIVE_BUTTON_INVEST = "(max-width:1531px)";
