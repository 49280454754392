import {
  ToggleButtonGroup,
  ToggleButton as MuiToggleButton,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { EvaluationForm, TypeEvaluation } from "../consts";

export const ToggleButton = styled(MuiToggleButton)(() => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "#4A7C2F",
    backgroundColor: "#74C24A",
  },
  border: "solid 1px #74C24A",
  width: "32px",
  color: "#74C24A",
}));

interface Props {
  hook: UseFormReturn<EvaluationForm>;
  typeEvaluation: TypeEvaluation;
  index: number;
  isEditing?: boolean;
}

const ToggleButtonEvaluation: React.FC<Props> = (props) => {
  const { hook, index, typeEvaluation, isEditing } = props;

  return (
    <Controller
      control={hook.control}
      name={`evaluations.${typeEvaluation}.evaluations.${index}.score`}
      render={({ field, fieldState }) => (
        <>
          <ToggleButtonGroup
            {...field}
            size="small"
            exclusive
            value={hook.watch(
              `evaluations.${typeEvaluation}.evaluations.${index}.score`,
            )}
            onChange={(_, value) => field.onChange(value)}
            sx={{ borderColor: fieldState.error?.message ? "red" : undefined }}
            disabled={!isEditing}
          >
            <ToggleButton value={0}>0</ToggleButton>
            <ToggleButton value={1}>1</ToggleButton>
            <ToggleButton value={2}>2</ToggleButton>
            <ToggleButton value={3}>3</ToggleButton>
            <ToggleButton value={4}>4</ToggleButton>
          </ToggleButtonGroup>
          {Boolean(fieldState.error?.message) && (
            <FormHelperText error={Boolean(fieldState.error?.message)}>
              {fieldState.error?.message}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
};

export default ToggleButtonEvaluation;
