import { SxProps, Theme } from "@mui/material";

const InvestmentOpportunityStyles: SxProps<Theme> = (theme: Theme) => ({
  // minWidth: "782px",
  width: "100% !important",
  [theme.breakpoints.up("md")]: {
    height: "324px",
  },
  padding: "16px !important",
  paddingRight: "38px !Important",
});

export const InvestmentIndicatorStyles: SxProps<Theme> = (theme: Theme) => ({
  [theme.breakpoints.down("sm")]: {
    paddingTop: "24px",
  },
  "& .MuiSvgIcon-root ": {
    color: "#74C24A",
    fontSize: "28px",
  },
  "& .MuiStack-root .MuiTypography-h3": {
    color: "#74C24A",
    fontFamily: "Product Sans !important",
    fontWeight: "700 !important",
    letterSpacing: ".5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "36px",
    },
  },
  "& .MuiStack-root .MuiTypography-h5": {
    color: "#515151",
    fontFamily: "Product Sans !important",
    fontWeight: 400,
    letterSpacing: ".5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
});

export default InvestmentOpportunityStyles;
