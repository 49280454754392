import { Download, Report } from "@mui/icons-material";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { Dispatch, FC, SetStateAction } from "react";

import { handleDownloadFile } from "../../../../../../../utils/helpers/downloadFile";
import { IFileObject } from "../../../../../../../utils/types/common";

import { styles } from "./styles";

interface IProps {
  openModal: Dispatch<SetStateAction<boolean>>;
  files: IFileObject[];
}

export const Modal: FC<IProps> = ({ openModal, files }) => {
  const breakpointXS = useMediaQuery("(min-width: 600px)");

  const handleClose = () => {
    openModal(false);
  };

  return (
    <Grid
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(19, 13, 16, 0.8)",
        position: "absolute",
        top: "0px",
        right: "0px",
        zIndex: "9999",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          padding: "40px 56px",
          width: "728px",
          maxHeight: "688px",
          minHeight: "200px",
          background: "#FFFFFF",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid item xs={12} sm={12} md={12} sx={{ ...styles.Info }}>
          <Report sx={{ ...styles.Icons }} />
          <Box
            style={{
              width: "100%",
              minHeight: "100%",
            }}
          >
            <Typography sx={{ ...styles.title }}>Reportes</Typography>
            <Box
              style={{
                overflow: "auto",
                minHeight: "200px",
                maxHeight: "500px",
                marginTop: "10px",
                padding: "9px 0px",
              }}
            >
              {files ? (
                files.map((item: IFileObject, index: number) => {
                  return (
                    <Box key={index} style={{ overflow: "auto" }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          overflow: "auto",
                        }}
                      >
                        <Typography>{item.file.name}</Typography>
                        <Button
                          onClick={() => handleDownloadFile(item.file)}
                          endIcon={
                            <Box display="flex" alignContent="center">
                              <Download />
                            </Box>
                          }
                          sx={{
                            ...styles.downloadButton,
                          }}
                        >
                          {breakpointXS && "Descargar"}
                        </Button>
                      </Box>
                      <hr />
                    </Box>
                  );
                })
              ) : (
                <>
                  <Typography>
                    Aún no existen reportes para esta startup
                  </Typography>
                  <hr />
                </>
              )}
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "30px",
              }}
            >
              <Button
                onClick={handleClose}
                variant="contained"
                sx={{ ...styles.actionsButtons }}
              >
                Cerrar
              </Button>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};
