import { TextField, Typography } from "@mui/material";
import React from "react";
import { UseFormReturn, Controller } from "react-hook-form";

import { EvaluationForm, TypeEvaluation } from "../consts";

interface IProps {
  isEditing?: boolean;
  hook: UseFormReturn<EvaluationForm>;
  typeEvaluation: TypeEvaluation;
  index: number;
}

const TextEvaluation: React.FC<IProps> = (props) => {
  const { hook, index, typeEvaluation, isEditing } = props;

  const comment = hook.watch(
    `evaluations.${typeEvaluation}.evaluations.${index}.comment`,
  );

  return (
    <>
      {isEditing ? (
        <Controller
          control={hook.control}
          rules={{
            required: true,
          }}
          name={`evaluations.${typeEvaluation}.evaluations.${index}.comment`}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              multiline
              rows={3}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error?.message
                  ? fieldState.error?.message
                  : `Caracteres ${field.value.length}/250`
              }
            />
          )}
        />
      ) : (
        <Typography>{comment ? comment : "No tiene comentario."}</Typography>
      )}
    </>
  );
};

export default TextEvaluation;
