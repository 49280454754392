import { CLOSE_MODAL, OPEN_MODAL } from "../actions/modal";

export interface IModalInitialState {
  isOpen: boolean;
  title: string;
  description: string;
  action: () => void;
  actionClose?: () => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  hideSecondaryButton?: boolean;
}

const initialState: IModalInitialState = {
  isOpen: false,
  description: "",
  title: "",
  action: () => null,
  actionClose: () => null,
  primaryButtonText: "Confirmar",
  secondaryButtonText: "Cancelar",
  hideSecondaryButton: false,
};

export const ModalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        ...action.payload,
        isOpen: true,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
