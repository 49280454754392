export const styles = {
  card: {
    padding: "0px !important",
    width: "100% !important",
    height: "468px",
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "160%",
    color: "rgba(0, 0, 0, 0.87)",
    paddingBottom: "8px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  separator: {
    marginX: "8px",
  },
  autor: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "157%",
    color: "#2196F3",
    paddingBottom: "4px",
    textTransform: "capitalize",
  },

  text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "150%",
    color: " rgba(0, 0, 0, 0.6)",
    height: "72px",
  },
  link: {
    fontFamily: "Product Sans Black !important",
    fontStyle: "underlined",
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "24px",
    width: "108px",
    color: "#4A88C2",
    "&:hover": {
      cursor: "pointer",
    },
  },
  chip: {
    background: "#74C24A",
    borderRadius: "64px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 8px",
  },
  chipText: {
    fontFamily: "Product Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
  },
  ribbonContainer: {
    zIndex: 10,
    span: {
      width: "63%",
    },
  },
};
