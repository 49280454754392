import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { TextInput } from "../../../../../../components/Inputs";
import StandardDialog from "../../../../../../components/StandardDialog";
import { fetchUserByRole } from "../../../../../../services/users";
import { ERoles } from "../../../../../routes/role-catalog";

import { analystSchema } from "./schema";

interface IProps {
  open: boolean;
  onCancel: () => void;
  onAccept: (id: any) => void;
}

const DialogChangeAnalyst: React.FC<IProps> = ({
  open,
  onCancel,
  onAccept,
}) => {
  const [Users, setUsers] = useState<any[]>([""]);
  const hook = useForm<{ analyst: string }>({
    resolver: yupResolver(analystSchema),
    defaultValues: {
      analyst: "",
    },
  });
  useEffect(() => {
    fetchUserByRole(ERoles.RL002).then(({ data }) => {
      const users = data.payload;
      setUsers(users);
    });
  }, []);

  const handleUpdate = (form: { analyst: string }) => {
    const analyst = Users.find((i) => i._id === form.analyst);
    onAccept(analyst);
    hook.reset();
    onCancel();
  };
  const handleCancel = () => {
    onCancel();
    hook.reset();
  };

  return (
    <StandardDialog open={open}>
      <DialogTitle>Asignar Analista</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Por favor asigna al analista responsable de esta oportunidad. Puedes
          cambiar esta elección más tarde.
        </DialogContentText>
        <br />
        <Controller
          control={hook.control}
          name="analyst"
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              label="Analista"
              autoFocus
              fullWidth
              required
              select
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error) && fieldState.error?.message
              }
            >
              {Users.map((i, index) => (
                <MenuItem
                  key={index}
                  value={i._id}
                >{`${i.firstName} ${i.lastName}`}</MenuItem>
              ))}
            </TextInput>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={hook.handleSubmit(handleUpdate)}>
          Asignar
        </Button>
      </DialogActions>
    </StandardDialog>
  );
};

export default DialogChangeAnalyst;
