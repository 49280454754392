import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowBack } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  Grid,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import PhoneInput, { CountryData } from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";
import { TextInput } from "../../../components/Inputs/index";
import { KeysValueForm } from "../../../redux/reducers/member";
import { getMailValidateApplication } from "../../../services/memberApplication";
import countries from "../../../utils/countries.json";
import { PHONE_MESSAGE } from "../../../utils/text/resetForm";

import { demonyms } from "./demonyms";
import { EAction, IPreMember } from "./reducer";
import { preMemberSchema } from "./schema";

export const useStyles = makeStyles({
  inputLabel: {
    transform: "translate(14px, -3px) scale(0.75) !important",
  },
});

type FormType = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  nationalityCountry: string;
  residenceCountry: string;
  residenceCity: string;
  email: string;
};

interface IProps {
  catchData: (action: KeysValueForm, newValue: any) => void;
  state: IPreMember;
  handleNext: () => void;
  handleBack: () => void;
}

const FormData = (props: IProps) => {
  const { catchData, state, handleNext, handleBack } = props;
  const classes2 = useStyles();
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isDirty, isValid },
  } = useForm<FormType>({
    resolver: yupResolver(preMemberSchema),
    mode: "onTouched",
    defaultValues: {
      firstName: state.firstName,
      lastName: state.lastName,
      phoneNumber: state.phoneNumber,
      nationalityCountry: state.nationalityCountry,
      residenceCountry: state.residenceCountry,
      residenceCity: state.residenceCity,
      email: state.email,
    },
  });

  const onSubmit = async (data: FormType) => {
    catchData(EAction.FIRSTNAME, data.firstName);
    catchData(EAction.LASTNAME, data.lastName);
    catchData(EAction.PHONENUMBER, data.phoneNumber);
    catchData(EAction.NATIONALITYCOUNTRY, data.nationalityCountry);
    catchData(EAction.RESIDENCECOUNTRY, data.residenceCountry);
    catchData(EAction.RESIDENCECITY, data.residenceCity);
    catchData(EAction.EMAIL, data.email);

    const number = state.phoneCountryCode.length + 10;
    const reg = `^[0-9]{10}$`.replace("10", number.toString());
    const exp = new RegExp(reg);
    const isValid = exp.test(data.phoneNumber);
    const mailValidation = await getMailValidateApplication(
      data.email,
      data.phoneNumber.slice(state.phoneCountryCode.length),
      state.phoneCountryCode,
    );
    if (!isValid) {
      setError("phoneNumber", { message: PHONE_MESSAGE });
    } else {
      clearErrors("phoneNumber");
      if (mailValidation?.data?.isValid) {
        setError("email", {
          message: "Correo ya registrado",
        });
      } else {
        handleNext();
      }
    }
  };
  return (
    <Box
      height="100%"
      overflow="hidden"
      display={"flex"}
      flexDirection="column"
    >
      <Box flex={"0"}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              sx={(theme: Theme) => ({
                fontFamily: "Product Sans Black !important",
                fontStyle: "normal",
                fontWeight: 900,
                color: "#515151",
                [theme.breakpoints.up(1443)]: {
                  fontSize: "40px",
                  lineHeight: "56px",
                  paddingBottom: 3,
                },
                [theme.breakpoints.between(450, 1442)]: {
                  fontSize: "32px",
                  lineHeight: "48px",
                  paddingBottom: 1,
                },
                [theme.breakpoints.down(449)]: {
                  fontSize: "24px",
                  lineHeight: "32px",
                  paddingBottom: 1,
                },
              })}
            >
              Datos Personales
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Controller
              control={control}
              name="firstName"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  required
                  label="Nombres"
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Controller
              control={control}
              name="lastName"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  label="Apellidos "
                  required
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  label="Correo "
                  required
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field, fieldState }) => (
                <>
                  <PhoneInput
                    country="mx"
                    value={field.value}
                    onChange={(phone: string, country: CountryData) => {
                      catchData(EAction.PHONECOUNTRYCODE, country.dialCode);
                      field.onChange(phone);
                    }}
                    buttonStyle={
                      fieldState.error && { border: "2px solid #f44336" }
                    }
                    inputStyle={
                      fieldState.error
                        ? {
                            border: "2px solid #f44336",
                            height: "48px",
                            width: "100%",
                          }
                        : { height: "48px", width: "100%" }
                    }
                  />

                  {Boolean(fieldState.error) && (
                    <FormHelperText
                      style={{ margin: "3px 14px 0px 14px" }}
                      error={Boolean(fieldState.error?.message)}
                    >
                      {fieldState.error?.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Controller
              control={control}
              name="nationalityCountry"
              render={({ field, fieldState }) => (
                <Autocomplete
                  value={field.value}
                  options={demonyms.map((i) => i.value)}
                  onChange={(_, value) => field.onChange(value)}
                  getOptionLabel={(option) =>
                    demonyms.find((i) => i.value === option)?.label || ""
                  }
                  renderInput={({
                    InputLabelProps,
                    InputProps,
                    inputProps,
                    ...rest
                  }) => (
                    <TextInput
                      {...rest}
                      error={Boolean(fieldState.error)}
                      helperText={
                        Boolean(fieldState.error?.message) &&
                        fieldState.error?.message
                      }
                      InputProps={{
                        ...InputProps,
                        style: { height: 48, paddingTop: "6px" },
                      }}
                      inputProps={inputProps}
                      InputLabelProps={{
                        ...InputLabelProps,
                        style: { top: "-3.5px" },
                        classes: {
                          shrink: classes2.inputLabel,
                        },
                      }}
                      label="Nacionalidad"
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Controller
              control={control}
              name="residenceCountry"
              render={({ field, fieldState }) => (
                <Autocomplete
                  value={field.value}
                  onChange={(_, value) => field.onChange(value)}
                  options={Array.from(new Set(countries.map((i) => i.name_es)))}
                  renderInput={({
                    InputLabelProps,
                    InputProps,
                    inputProps,
                    ...rest
                  }) => (
                    <TextInput
                      {...rest}
                      error={Boolean(fieldState.error)}
                      helperText={
                        Boolean(fieldState.error?.message) &&
                        fieldState.error?.message
                      }
                      InputProps={{
                        ...InputProps,
                        style: { height: 48, paddingTop: "6px" },
                      }}
                      inputProps={inputProps}
                      InputLabelProps={{
                        ...InputLabelProps,
                        style: { top: "-3.5px" },
                        classes: {
                          shrink: classes2.inputLabel,
                        },
                      }}
                      label="País de Residencia"
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Controller
              control={control}
              name="residenceCity"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  required
                  label="Ciudad de Residencia"
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"flex-end"}
        sx={{ pt: "48px !important" }}
        flex="1"
      >
        <Button
          onClick={handleBack}
          variant="text"
          startIcon={<ArrowBack />}
          color="info"
          className="stepper-back-button"
        >
          REGRESAR
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
          disabled={!(isDirty && isValid)}
        >
          CONTINUAR
        </Button>
      </Box>
    </Box>
  );
};

export default FormData;
