export interface IPreMember {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneCountryCode: string;
  nationalityCountry: string;
  residenceCountry: string;
  residenceCity: string;
  linkedInProfile: string;
  hasExperienceInvesting?: boolean; //opciones por definir
  interestToEnter: string;
  email: string;
}

export enum EAction {
  FIRSTNAME = "firstName",
  LASTNAME = "lastName",
  PHONENUMBER = "phoneNumber",
  PHONECOUNTRYCODE = "phoneCountryCode",
  NATIONALITYCOUNTRY = "nationalityCountry",
  RESIDENCECOUNTRY = "residenceCountry",
  RESIDENCECITY = "residenceCity",
  LINKEDINPROFILE = "linkedInProfile",
  INVESTMENTQUESTION = "investmentQuestion",
  INTERESTTOENTER = "interestToEnter",
  HASEXPERIENCEINVESTING = "hasExperienceInvesting",
  EMAIL = "email",
  CV = "CV",
}

export const initialState: IPreMember = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  phoneCountryCode: "",
  nationalityCountry: "",
  residenceCountry: "",
  residenceCity: "",
  linkedInProfile: "",
  hasExperienceInvesting: undefined,
  interestToEnter: "",
  email: "",
};

export function reducer(state: IPreMember, action: any): IPreMember {
  const { type, payload } = action;
  switch (type) {
    case EAction.FIRSTNAME:
      return {
        ...state,
        firstName: payload,
      };
    case EAction.LASTNAME:
      return {
        ...state,
        lastName: payload,
      };
    case EAction.PHONENUMBER:
      return {
        ...state,
        phoneNumber: payload,
      };
    case EAction.PHONECOUNTRYCODE:
      return {
        ...state,
        phoneCountryCode: payload,
      };
    case EAction.NATIONALITYCOUNTRY:
      return {
        ...state,
        nationalityCountry: payload,
      };
    case EAction.RESIDENCECOUNTRY:
      return {
        ...state,
        residenceCountry: payload,
      };
    case EAction.RESIDENCECITY:
      return {
        ...state,
        residenceCity: payload,
      };
    case EAction.LINKEDINPROFILE:
      return {
        ...state,
        linkedInProfile: payload,
      };
    case EAction.HASEXPERIENCEINVESTING:
      return {
        ...state,
        hasExperienceInvesting: payload,
      };
    case EAction.INTERESTTOENTER:
      return {
        ...state,
        interestToEnter: payload,
      };
    case EAction.EMAIL:
      return {
        ...state,
        email: payload,
      };
    default:
      return state;
  }
}
