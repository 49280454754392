import {
  CLEAR_MEMBER_APPLICATION_VALUES,
  SET_ACTIVE_STEP,
  SET_VALUES_MEMBER_APPLICATIONS,
  SET_VALUE_CALENDAR,
  SET_VALUE_FORM_APPLICATION,
} from "../actions/member";

export type Steps = 0 | 1 | 2 | 3;

export type KeysValueForm =
  | "firstName"
  | "lastName"
  | "phoneNumber"
  | "phoneCountryCode"
  | "nationalityCountry"
  | "residenceCountry"
  | "residenceCity"
  | "linkedInProfile"
  | "investmentQuestion"
  | "hasExperienceInvesting"
  | "interestToEnter"
  | "email";
export interface IPreMember {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneCountryCode: string;
  nationalityCountry: string;
  residenceCountry: string;
  residenceCity: string;
  linkedInProfile: string;
  hasExperienceInvesting?: boolean;
  investmentQuestion?: string; //opciones por definir
  interestToEnter: string;
  howFindAngelHub: string;
  email: string;
}
export interface IModalInitialState {
  email: string;
  phoneNumber: string;
  name: string;
  _id: string;
  calendar: string;
  activeStep: Steps;
  state: IPreMember;
}

const initialState: IModalInitialState = {
  _id: "",
  name: "",
  email: "",
  phoneNumber: "",
  activeStep: 0,
  calendar: "",
  state: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    phoneCountryCode: "",
    nationalityCountry: "",
    residenceCountry: "",
    residenceCity: "",
    linkedInProfile: "",
    investmentQuestion: "",
    interestToEnter: "",
    howFindAngelHub: "",
    email: "",
  },
};

export const MemberApplicationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_VALUES_MEMBER_APPLICATIONS:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case CLEAR_MEMBER_APPLICATION_VALUES:
      return {
        ...initialState,
      };
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case SET_VALUE_FORM_APPLICATION:
      return {
        ...state,
        state: {
          ...state.state,
          [action.payload.key]: action.payload.value,
        },
      };
    case SET_VALUE_CALENDAR:
      return {
        ...state,
        calendar: action.payload,
      };
    default:
      return state;
  }
};
