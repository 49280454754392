import axios from "axios";

import { ERolesCode } from "../../pages/routes/role-catalog";
import getLocalAccessToken from "../../utils/helpers/get-local-access-token";
import { ACTION_TYPES } from "../reducers/LoggedUser";

import { addNotification, clearNotification } from "./notifications";

// ----- LOGIN -----
export const login = () => ({ type: ACTION_TYPES.LOGIN });
export const loginFailed = (error: any) => ({
  type: ACTION_TYPES.LOGIN_FAILED,
  data: error, //Showing this error instead of the 'error' from backend,
});
export const loginSuccess = (response: any) => ({
  type: ACTION_TYPES.LOGIN_SUCCESS,
  data: response,
});

export const loginUser = (session: any, remindMeSession: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(login());
      const response = await axios.post("/login", { ...session });
      dispatch(loginSuccessActions(response.data.payload, remindMeSession));
    } catch (error: any) {
      if (error.data.message === "User Not Found") {
        dispatch(loginFailed("Contraseña incorrecta"));
      } else {
        dispatch(loginFailed(error.data?.message));
      }
    }
  };
};

// ----- USER SELF -----
export const checkUserSelf = () => {
  return async (dispatch: any) => {
    dispatch(login());
    try {
      if (!getLocalAccessToken()) {
        disableAppLoader()(dispatch);
        return;
      }
      const response = await axios.get("/users/user-self");
      const userData = response.data.payload;

      dispatch(loginSuccess(userData));
    } catch (error) {
      disableAppLoader()(dispatch);
    }
  };
};
// ----- USER SELF -----
export const RELOAD_USER_INFO = "RELOAD_USER_INFO";
export const reloadUser = () => {
  return async (dispatch: any) => {
    try {
      if (!getLocalAccessToken()) {
        return;
      }
      const response = await axios.get("/users/user-self");
      const userData = response.data.payload;

      dispatch(loginSuccess(userData));

      return {
        payload: userData,
        type: RELOAD_USER_INFO,
      };
    } catch (error) {
      disableAppLoader()(dispatch);
    }
  };
};

// ----- DISABLE APP LOADER  -----
export const disableLoader = () => ({
  type: ACTION_TYPES.DISABLE_APP_LOADER,
});

export const disableAppLoader = () => {
  return async (dispatch: any) => {
    dispatch(disableLoader());
  };
};

export const loginSuccessActions = (
  responseData: any,
  remindMeSession = true,
) => {
  return async (dispatch: any) => {
    const { user, refreshToken } = responseData;
    const { accessToken } = user;

    // Save JWT Token
    if (remindMeSession) {
      setLocalStorageUser(accessToken, refreshToken);
    } else {
      setSessionStorageUser(accessToken, refreshToken);
    }
    // Save loggedUser into Redux
    dispatch(loginSuccess({ user }));
  };
};

// ----- LOGOUT -----
export const logout = () => ({
  type: ACTION_TYPES.LOGOUT,
});

export const logoutUser = () => {
  return async (dispatch: any) => {
    try {
      await axios.post("/logout");
    } catch (error) {
      //If the logout request fails, continue with the logout process
    }

    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    dispatch(clearNotification());
    dispatch(logout());
  };
};

export const setLocalStorageUser = (
  accessToken: string,
  refreshToken: string,
) => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);
};

export const setSessionStorageUser = (
  accessToken: string,
  refreshToken: string,
) => {
  sessionStorage.setItem("accessToken", accessToken);
  sessionStorage.setItem("refreshToken", refreshToken);
};

export const clearErrorLogin = () => {
  return {
    type: ACTION_TYPES.CLEAR_ERROR,
  };
};
export const changeEdit = (value: boolean) => {
  return {
    type: ACTION_TYPES.EDIT,
    payload: value,
  };
};
