import ApartmentIcon from "@mui/icons-material/Apartment";
import StarIcon from "@mui/icons-material/Star";
import {
  Avatar,
  Box,
  CardContent,
  CardMedia,
  Chip,
  Paper,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { getSignedUrl } from "../../services/files";

import { directoryInvestorsCardStyles } from "./styles";

interface IProps {
  firstName: string;
  lastName: string;
  text: string;
  city: string;
  industries: string[];
  avatar: any;
  id: string;
}

const InvestorCard: FC<IProps> = ({
  industries,
  firstName,
  lastName,
  text,
  city,
  avatar,
  id,
}) => {
  const [Uri, setUri] = useState<any>("");

  const xd = useHistory();

  const handleRedirect = () => {
    xd.push(`detalles-miembro/${id}`);
  };

  useEffect(() => {
    if (avatar) {
      const getSignedAvatarImage = async () => {
        const response = await getSignedUrl(avatar.key, avatar.name);
        setUri(response.data.payload);
      };
      getSignedAvatarImage();
    }
    setUri("");
  }, [avatar]);

  return (
    <Paper sx={directoryInvestorsCardStyles} onClick={() => handleRedirect()}>
      <Box className="card">
        <CardMedia className="cardMedia">
          <Avatar
            src={Uri}
            sx={{ width: "140px", height: "140px", background: "#A6D88B" }}
          >
            <Typography className="avatar">
              {(firstName[0] + lastName[0]).toUpperCase()}
            </Typography>
          </Avatar>
        </CardMedia>
        <CardContent className="cardContent">
          <Typography variant="h4" className="name">
            {`${firstName + " " + lastName}`}
          </Typography>
          <Typography className="description" component={"h6"}>
            {text}
          </Typography>
          <Box
            sx={{
              mt: 1.5,
              display: "flex",
              gap: "4px",
            }}
          >
            <ApartmentIcon className="icon" />
            <Typography className="cityLabel">
              Ciudad de residencia:
              <Typography className="city"> {city}</Typography>
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1.5,
              display: "flex",
              gap: "4px",
            }}
          >
            <StarIcon className="icon" />
            <Typography className="industry">Industrias de interés</Typography>
          </Box>
          <Box className="chip-container">
            {industries.length !== 0 ? (
              industries.map((industry: any, indx: number) => {
                return (
                  <Chip
                    key={industry + indx}
                    label={industry}
                    size="small"
                    sx={{ background: "#74C24A" }}
                  />
                );
              })
            ) : (
              <Typography
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "16px",
                  fontWeight: "600",
                  fontFamily: "sans-serif",
                  fontStyle: "italic",
                }}
              >
                Aun no ha seleccionado sus industrias de interés
              </Typography>
            )}
          </Box>
        </CardContent>
      </Box>
    </Paper>
  );
};

export default InvestorCard;
