import { PayPalButtons } from "@paypal/react-paypal-js";
import React from "react";

import { postCaptureOrder } from "../../services/payments";
import { displayToast } from "../../utils/helpers/displayToast";

interface Props {
  price: string;
  memberId: string;
  onFinish: () => void;
  onError: () => void;
}

const PaypalButton: React.FC<Props> = (props) => {
  const { onFinish, onError, memberId, price } = props;

  async function handleApprove(data: any) {
    try {
      const { orderID } = data;
      await postCaptureOrder(orderID, memberId);
      onFinish();
      displayToast("success", "Se ha hecho la compra correctamente");
    } catch (error) {
      onError();
      displayToast(
        "error",
        "Lo siento, parece que el servicio al que está intentando acceder no está disponible en este momento. Por favor, inténtelo de nuevo más tarde.",
      );
    }
  }

  return (
    <PayPalButtons
      forceReRender={[price]}
      createOrder={(_, actions) => {
        return actions.order.create({
          intent: "CAPTURE",
          purchase_units: [
            {
              amount: {
                currency_code: "MXN",
                value: `${price}`,
              },
              description: "Suscripción AngelHub",
            },
          ],
          application_context: {
            brand_name: "AngelHub",
            locale: "en-US",
            user_action: "PAY_NOW",
            landing_page: "LOGIN",
          },
        });
      }}
      onApprove={handleApprove}
    />
  );
};

export default PaypalButton;
