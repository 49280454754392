import { ERolesCode } from "../../../../../../routes/role-catalog";

export interface IUpdateFinancialInfo {
  requiredInvestment: string;
  monthlyBurnRate: string;
  preMoney: string;
  postMoney: string;
  runway: string;
  monthlyRevenue: string;
}

export const VALID_USER_TO_EDIT_ALL_FIELDS = [
  ERolesCode.ADMINISTRATOR,
  ERolesCode.SUPER_ADMIN,
  ERolesCode.PARTNER,
  ERolesCode.ANALYST,
];
