import axios, { AxiosRequestConfig } from "axios";

import { getToken } from "../config";
import { IChangeIntention, IChangeStatus } from "../utils/types/common";

export function opportunitiesReleasedPaginated(
  limit: number,
  page: number,
  query: any,
): Promise<any> {
  const token = getToken();

  let url = `/startups/data-grid?limit=${limit}&page=${page + 1}`;

  if (query.Stage) {
    url += `&stage=${query.Stage}`;
  }
  if (query.Industry) {
    url += `&industry=${query.Industry}`;
  }
  if (query.Country) {
    url += `&country=${query.Country}`;
  }

  if (query.Tab && query.User) {
    url += `&tab=${query.Tab}&user=${query.User}`;
  }

  const config: AxiosRequestConfig = {
    url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
export function opportunitiesReleased(): Promise<any> {
  const token = getToken();

  const url = `/startups/`;

  const config: AxiosRequestConfig = {
    url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function opportunitiesReleasedChangeIntention(
  data: IChangeIntention,
): Promise<any> {
  const token = getToken();
  const url = `/startups/change-intention`;

  const config: AxiosRequestConfig = {
    url,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(config);
}

export function opportunitiesReleasedChangeStatus(
  data: IChangeStatus,
): Promise<any> {
  const token = getToken();
  const url = `/startups/change-status`;

  const config: AxiosRequestConfig = {
    url,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(config);
}
