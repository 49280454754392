import DownloadIcon from "@mui/icons-material/Download";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Box, Button, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Dispatch, FC, SetStateAction } from "react";

import { handleDownloadReports } from "../../../../../../../utils/helpers/downloadFile";
import { styles as mainStyles } from "../../../styles";
import { styles } from "../styles";
interface IModalReports {
  openModal: Dispatch<SetStateAction<boolean>>;
  Files: any;
}

export const Modal: FC<IModalReports> = ({ openModal, Files }) => {
  const handleClose = () => {
    openModal(false);
  };

  return (
    <Grid
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(19, 13, 16, 0.8)",
        position: "absolute",
        top: "0px",
        right: "0px",
        zIndex: "9999",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          padding: "40px 56px",
          width: "728px",
          maxHeight: "688px",
          minHeight: "200px",
          background: "#FFFFFF",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid item xs={12} sm={12} md={12} sx={{ ...mainStyles.Info }}>
          <ThumbUpIcon sx={{ ...mainStyles.Icons }} />
          <Box
            style={{
              width: "100%",
              minHeight: "100%",
            }}
          >
            <Typography sx={{ ...mainStyles.title }}>Reportes</Typography>
            <Box
              style={{
                minHeight: "200px",
                maxHeight: "500px",
                overflowY: "scroll",
                marginTop: "10px",
                padding: "9px 0px",
              }}
            >
              {Files ? (
                Files.map((item: any, index: number) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        ...styles.reportContainer.subContainer,
                        overflow: "scroll-y",
                      }}
                    >
                      <Box
                        sx={{
                          ...styles.alignElementsBox,
                          ...styles.borderResponsive,
                        }}
                      >
                        <Typography>{`${dayjs(item.date).format(
                          "LL",
                        )} • Reporte ${dayjs(item.date).format(
                          "MMMM",
                        )}`}</Typography>
                        <DownloadIcon
                          onClick={() => handleDownloadReports(item)}
                          sx={{ ...styles.buttonDownload, cursor: "pointer" }}
                        />
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <>
                  <Typography>Aún no hay inversionistas interesados</Typography>
                  <hr />
                </>
              )}
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "30px",
              }}
            >
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{ ...mainStyles.actionsButtons }}
              >
                Cerrar
              </Button>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};
