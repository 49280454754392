/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Tab, Tabs, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";

import { changeEdit } from "../../../../redux/actions/LoggedUser";
import { openModal } from "../../../../redux/actions/modal";
import { useDispatch, useSelector } from "../../../../redux/typedHooks";
import { useParamTab } from "../Context/useParamTabs";
import { TabParam } from "../TabList/types";

const Layout = ({ children }: any) => {
  const { edit } = useSelector((store) => store.loggedUser);
  const { value, onChange } = useParamTab();
  const [changeTab, setChangeTab] = useState<string>("");
  const dispatch = useDispatch();
  const responsiveTab = useMediaQuery("(max-width:980px)");

  const isTab = ["FinancialInfo", "Evaluations"].includes(value);

  const getChildrenOnDisplayName = (children: any, displayName: string) => {
    return React.Children.map(children, (child) => {
      return child.type.displayName === displayName ? child : null;
    });
  };

  const button = getChildrenOnDisplayName(children, "Button");
  const panel = getChildrenOnDisplayName(children, "Panel");

  function handleChangeTab() {
    dispatch(
      openModal({
        title: "Advertencia",
        description: "¿Seguro que deseas salir sin guardar?",
        action: () => {
          onChange(String(changeTab) as TabParam);
          dispatch(changeEdit(false));
          setChangeTab("");
        },
        actionClose: () => {
          setChangeTab("");
        },
      }),
    );
  }

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    if (isTab && edit) {
      setChangeTab(String(newValue) as TabParam);
    } else {
      onChange(String(newValue) as TabParam);
    }
  };

  useEffect(() => {
    const isEmpty = changeTab === "";
    if (!isEmpty) {
      handleChangeTab();
    }
  }, [changeTab]);

  const widthTab = { width: "220px" };

  return (
    <React.Fragment>
      <Box
        width={"100%"}
        display={"flex"}
        flexWrap={"wrap"}
        alignItems={"center"}
        justifyContent="space-between"
        sx={{ paddingBottom: "18px" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant={responsiveTab ? "scrollable" : "standard"}
          scrollButtons={responsiveTab}
          allowScrollButtonsMobile={responsiveTab}
        >
          <Tab
            label="Información Financiera"
            value={"FinancialInfo"}
            sx={widthTab}
          />
          <Tab
            label="Archivos Adjuntos"
            value={"AttachedFiles"}
            sx={widthTab}
          />
          <Tab label="Evaluaciones" value={"Evaluations"} sx={widthTab} />
        </Tabs>

        {button}
      </Box>
      {panel}
    </React.Fragment>
  );
};

const LayoutButton: React.FC = ({ children }) => {
  return <>{children}</>;
};

const LayoutPanel: React.FC<{ loading?: boolean }> = ({
  children,
  loading,
}) => {
  if (loading) {
    return (
      <Box
        width={"100%"}
        height="300px"
        display={"flex"}
        alignItems={"center"}
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  return <>{children}</>;
};

LayoutButton.displayName = "Button";
Layout.Button = LayoutButton;

LayoutPanel.displayName = "Panel";
Layout.Panel = LayoutPanel;

export default Layout;
