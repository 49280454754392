import * as yup from "yup";

import { CAMPO_REQUERIDO } from "../../../../../../../utils/text/inputs";

export const financialInfoSchema = yup.object({
  valuation: yup.string().required(CAMPO_REQUERIDO),
  requiredInvestment: yup.string().required(CAMPO_REQUERIDO),
  burnRate: yup.string(),
  monthlyRevenue: yup.string(),
  valuationPostMoney: yup.string(),
  runway: yup.string(),
  lastUpdate: yup.date(),
});
