import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { getToken } from "../config";

export const getOpportunityById = (id: string): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: "/opportunities/" + id,
    method: "GET",
  };

  return axios(config);
};

export function getOpportunities(): Promise<any> {
  const token = getToken();
  const url = `/opportunities`;

  const config: AxiosRequestConfig = {
    url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function getOpportunity(): Promise<any> {
  const token = getToken();

  const config: AxiosRequestConfig = {
    url: `/opportunities`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
export function createUsers(): Promise<any> {
  const token = getToken();

  const config: AxiosRequestConfig = {
    url: `/opportunities`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function updateOpportunity(
  id: string,
  data: any,
): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/opportunities/${id}`,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

interface IFilteredComments {
  user: string;
  comment: string;
  date: Date;
}

export interface IEvaluation {
  analystEvaluations?: any[];
  partnerEvaluations?: any[];
  comments: IFilteredComments[];
}
export function updateOpportunityEvaluation(
  id: string,
  data: IEvaluation,
): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/opportunities/evaluation/${id}`,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function updatePartnerOpportunity(
  id: string,
  data: any,
): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `opportunities/status/${id}`,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function getOpportunityApplicationMainCard(
  id: string,
): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/opportunities/main-card/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
export function getOpportunityApplicationFinancialInfo(
  id: string,
): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/opportunities/financial-info/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
export function updateOpportunityApplicationMainCard(
  id: string,
  data: any,
): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/opportunities/main-card/${id}`,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
export function updateOpportunityFinancialInfo(
  id: string,
  data: any,
): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/opportunities/financial-info/${id}`,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
export function getAttachedFilesOpportunity(
  id: string,
): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/opportunities/attached-files/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function deleteFileOpportunity(
  opportunityId: string,
  fileId: string,
): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/opportunities/remove-file/${opportunityId}/${fileId}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
export function addFileOpportunity(
  opportunityId: string,
  data: any,
): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/opportunities/add-file/${opportunityId}`,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
export function getEvaluationAndComments(
  opportunityId: string,
): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/opportunities/evaluation/${opportunityId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
