/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { ContentCopy } from "@mui/icons-material";
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput, { CountryData } from "react-phone-input-2";

import { useLoading } from "../../hooks/useLoading";
import {
  EStatusApplication,
  FILTER_OPTIONS_APPLICATION_STATUS,
} from "../../pages/Applications/constants";
import { OPTIONS_PROFILE_IDENTIFY } from "../../pages/MemberFaceliftOnboarding/FormRecapcha/options";
import { ERoles } from "../../pages/routes/role-catalog";
import { useSelector } from "../../redux/typedHooks";
import {
  getMemberApplicationById,
  updateMemberApplication,
} from "../../services/memberApplication";
import { changeStatusApplicationRequest } from "../../services/rejects";
import { fetchUserByRole } from "../../services/users";
import countries from "../../utils/countries.json";
import { displayToast } from "../../utils/helpers/displayToast";
import { TextInput } from "../Inputs";
import { demonyms } from "../MemberForm/FormComponents/demonyms";
import { currencies } from "../MemberForm/FormComponents/dropdownInfo";

import NotifySnackbar from "./DetailsForm/NotifySnackbar";
import RejectDialog from "./DetailsForm/RejectDialog";
import TabSelector from "./DetailsForm/TabSelector";
import { aplicantMemberSchema } from "./DetailsForm/schema";
import { isValidToChangeAssigned } from "./validations";

export type FormType = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneCountryCode: string;
  nationalityCountry: string;
  residenceCountry: string;
  residenceCity: string;
  email: string;
  appointments: string;
  meetLink: string;
  status: string;
  partner: string;
  linkedInProfile: string;
  hasExperienceInvesting: string;
  interestToEnter: string;
  partnerComments: string;
  profileIdentify: string;
  cv: string;
};

const gridR = { xs: 12, sm: 4, md: 4, lg: 4, xl: 4 };

export default function DetailMember({ id }: any) {
  const { handleClose, handleOpen } = useLoading();
  const [disabledOptions, setDisabledOptions] = useState(false);
  const [drop, setDrop] = useState([]);
  const [partner, setPartner] = useState([]);
  const [edit, setEdit] = useState(true);
  const [rejecD, setRejectD] = useState(false);
  const [reload, setReload] = useState(false);
  const [notify, setNotify] = useState({
    message: "",
    openSnack: false,
    severity: "",
  });
  const { loggedUser } = useSelector((store: any) => store.loggedUser);

  const hook = useForm<FormType>({
    resolver: yupResolver(aplicantMemberSchema),
    mode: "all",
    defaultValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      phoneCountryCode: "",
      nationalityCountry: "",
      residenceCountry: "",
      residenceCity: "",
      email: "",
      appointments: "",
      status: "",
      meetLink: "",
      partner: "",
      linkedInProfile: "",
      hasExperienceInvesting: "",
      profileIdentify: "",
      interestToEnter: "",
      partnerComments: "",
      cv: "",
    },
  });

  const isValidToEditAssignedPartner = isValidToChangeAssigned(
    loggedUser.user.role.code,
  );

  useEffect(() => {
    getMemberApplicationById(id).then(({ data }) => {
      const user = data.payload as any;
      if (
        user.status === EStatusApplication.ACCEPTED ||
        user.status === EStatusApplication.REJECTED
      ) {
        setDisabledOptions(true);
      }

      if (user.cv.length) {
        hook.setValue("cv", user.cv[0]._id);
      }

      hook.setValue("firstName", user.firstName);
      hook.setValue("lastName", user.lastName);
      hook.setValue(
        "phoneNumber",
        user.phoneCountryCode.toString() + user.phoneNumber.toString(),
      );
      hook.setValue("phoneCountryCode", user.phoneCountryCode);
      hook.setValue("nationalityCountry", user.nationalityCountry);
      hook.setValue("residenceCountry", user.residenceCountry);
      hook.setValue("residenceCity", user.residenceCity);
      hook.setValue("email", user.email);
      hook.setValue("status", user.status);
      hook.setValue("partner", user.partner?._id);
      hook.setValue("linkedInProfile", user.linkedInProfile);
      hook.setValue(
        "hasExperienceInvesting",
        user.hasExperienceInvesting ? "Si" : "No",
      );
      hook.setValue("interestToEnter", user.interestToEnter);
      hook.setValue("partnerComments", user.partnerComments);
      hook.setValue("profileIdentify", user.profileIdentify);

      user.appointments.forEach((appointment: any) => {
        const isInterview = appointment.type === "INTERVIEW_1";
        const dateSustract = moment(appointment.date).format(
          "DD-MM-YYYY, h:mm",
        );
        if (isInterview) {
          hook.setValue("appointments", dateSustract);
          hook.setValue("meetLink", appointment.meetLink);
        } else {
          hook.setValue("appointments", "");
          hook.setValue("meetLink", "");
        }
      });
    });
  }, [id, reload]);

  useEffect(() => {
    fetchUserByRole(ERoles.RL001).then((resp: any) => {
      const data = resp.data;
      setPartner(data.payload);
    });
  }, []);

  useEffect(() => {
    (async function () {
      const dropdownInterest = await axios.get(`/interests-to-enter/`);
      const dropdownInfo = dropdownInterest.data.payload;
      return setDrop(dropdownInfo);
    })();
  }, []);

  function handleEdit() {
    setEdit(false);
  }

  function handleDisableEdit() {
    setEdit(true);
    setReload(!reload);
  }

  function handleReject(value?: boolean, desc?: boolean) {
    if (desc) {
      setNotify({
        message: "Se guardo correctamente",
        openSnack: true,
        severity: "success",
      });
    }
    setRejectD(false);
    setEdit(true);
    if (value) {
      setNotify({
        message: "Correo de rechazo enviado.",
        openSnack: true,
        severity: "success",
      });
    }
    setReload(!reload);
  }

  function handleClick() {
    const meetinglink = hook.watch("meetLink");
    const isEmpty = meetinglink.length <= 0;

    isEmpty
      ? displayToast("error", "No hay información que copiar")
      : navigator.clipboard.writeText(meetinglink).then(
          () => {
            displayToast("info", "Se copio correctamente");
          },
          (err) => {
            console.error("Async: Could not copy text: ", err);
          },
        );
  }

  function handleLinkedIn() {
    const linkedInlink = hook.watch("linkedInProfile");
    const isEmpty = linkedInlink.length <= 0;

    isEmpty
      ? displayToast("error", "No hay información que copiar")
      : navigator.clipboard.writeText(linkedInlink).then(
          () => {
            displayToast("info", "Se copio correctamente");
          },
          (err) => {
            console.error("Async: Could not copy text: ", err);
          },
        );
  }

  async function onSubmit(data: any) {
    data.hasExperienceInvesting = data.hasExperienceInvesting === "Si";

    const status: string = data.status;
    const OnlyPhone = data.phoneNumber.slice(data.phoneCountryCode?.length);

    const finalvalues = {
      ...data,
      phoneNumber: OnlyPhone,
      phoneCountryCode: data.phoneCountryCode,
    };

    try {
      handleOpen();
      setNotify({
        message: "",
        openSnack: false,
        severity: "",
      });
      delete finalvalues["appointments"];

      const isReject = data.status === EStatusApplication.REJECTED;
      const isReasigned = data.status === EStatusApplication.RE_ASSIGNED;
      const isAccepted = data.status === EStatusApplication.ACCEPTED;
      const isAssigned = data.status === EStatusApplication.ASSIGNED;

      if (isReject) {
        delete finalvalues["status"];

        await updateMemberApplication(id, finalvalues);
        setRejectD(true);
      }

      if (isAccepted) {
        delete finalvalues["status"];
        await updateMemberApplication(id, finalvalues);

        await changeStatusApplicationRequest(id, {
          status,
        });
        handleDisableEdit();
        setNotify({
          message: "Guardado exitosamente.",
          openSnack: true,
          severity: "success",
        });
      }
      if (isReasigned) {
        delete finalvalues["status"];
        await updateMemberApplication(id, finalvalues);
        await changeStatusApplicationRequest(id, {
          status,
          partnerId: data.partner,
        });
        handleDisableEdit();
        setNotify({
          message: "Guardado exitosamente.",
          openSnack: true,
          severity: "success",
        });
      }
      if (isAssigned) {
        delete data["status"];
        await updateMemberApplication(id, finalvalues);
        await changeStatusApplicationRequest(id, {
          status,
        });
      }
    } catch (error) {
      setNotify({
        message: "Error al Guardar.",
        openSnack: true,
        severity: "error",
      });
    } finally {
      handleClose();
      handleDisableEdit();
    }
  }

  return (
    <Grid container>
      <Grid
        container
        item
        columnSpacing={6}
        rowSpacing={4}
        xs={12}
        sm={8}
        order={{ xs: 2, sm: 2, md: 2, lg: 1 }}
      >
        <Grid item {...gridR}>
          <Controller
            control={hook.control}
            name="firstName"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Nombres"
                disabled={edit}
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item {...gridR}>
          <Controller
            control={hook.control}
            name="lastName"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Apellidos"
                disabled={edit}
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item {...gridR}>
          <Controller
            control={hook.control}
            name="status"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Estatus"
                disabled={edit}
                required
                select
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              >
                {FILTER_OPTIONS_APPLICATION_STATUS.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextInput>
            )}
          />
        </Grid>
        <Grid item {...gridR}>
          <Controller
            control={hook.control}
            name="phoneNumber"
            render={({ field, fieldState }) => (
              <div>
                <PhoneInput
                  value={field.value}
                  disabled={edit}
                  onChange={(number, countryData: CountryData) => {
                    hook.setValue("phoneCountryCode", countryData.dialCode);
                    hook.setValue("phoneNumber", number);
                    field.onChange(number);
                  }}
                  buttonStyle={
                    fieldState.error ? { border: "2px solid #f44336" } : {}
                  }
                  inputStyle={
                    fieldState.error
                      ? {
                          border: "2px solid #f44336",
                          height: "48px",
                          width: "100%",
                        }
                      : { height: "48px", width: "100%" }
                  }
                />
                {Boolean(fieldState.error) && (
                  <FormHelperText error={Boolean(fieldState.error?.message)}>
                    {fieldState.error?.message}
                  </FormHelperText>
                )}
              </div>
            )}
          />
        </Grid>
        <Grid item {...gridR}>
          <Controller
            control={hook.control}
            name="email"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Correo Electrónico"
                disabled={edit}
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item {...gridR}>
          <Controller
            control={hook.control}
            name="partner"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Asignar a"
                disabled={!isValidToEditAssignedPartner ? true : edit}
                required
                select
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              >
                {partner.map((option: any, index: number) => (
                  <MenuItem key={index} value={option._id}>
                    {option.firstName} {option.lastName}
                  </MenuItem>
                ))}
              </TextInput>
            )}
          />
        </Grid>
        <Grid item {...gridR}>
          <Controller
            control={hook.control}
            name="nationalityCountry"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Nacionalidad"
                disabled={edit}
                required
                select
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              >
                {demonyms.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextInput>
            )}
          />
        </Grid>
        <Grid item {...gridR}>
          <Controller
            control={hook.control}
            name="residenceCountry"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="País"
                disabled={edit}
                required
                select
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              >
                {countries.map((option, index) => (
                  <MenuItem key={index} value={option.name_es}>
                    {option.name_es}
                  </MenuItem>
                ))}
              </TextInput>
            )}
          />
        </Grid>
        <Grid item {...gridR}>
          <Controller
            control={hook.control}
            name="residenceCity"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Ciudad"
                disabled={edit}
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item {...gridR}>
          <Controller
            control={hook.control}
            name="appointments"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Fecha de Cita"
                disabled={true}
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item {...gridR}>
          <Controller
            control={hook.control}
            name="meetLink"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Link de la llamada"
                disabled={true}
                required
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
                InputProps={{
                  sx: { height: "48px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ height: "24px", width: "24px" }}
                        onClick={handleClick}
                      >
                        <ContentCopy fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item {...gridR}></Grid>
        <Grid item xs={12}>
          <Controller
            control={hook.control}
            name="profileIdentify"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                required
                disabled={edit}
                select
                label="¿Con qué perfil de inversionista me identifico?"
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              >
                {OPTIONS_PROFILE_IDENTIFY.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.value as unknown as string}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextInput>
            )}
          />
        </Grid>
        <Grid item {...gridR}>
          <Controller
            control={hook.control}
            name="hasExperienceInvesting"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Inversiones previas"
                disabled={edit}
                required
                select
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              >
                {currencies.map((option, index) => (
                  <MenuItem key={index} value={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextInput>
            )}
          />
        </Grid>
        <Grid item {...gridR}>
          <Controller
            control={hook.control}
            name="interestToEnter"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Interesado en"
                disabled={edit}
                select
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              >
                {drop.map((option: any, index) => (
                  <MenuItem key={index} value={option.text}>
                    {option.text}
                  </MenuItem>
                ))}
              </TextInput>
            )}
          />
        </Grid>
        <Grid item {...gridR}>
          <Controller
            control={hook.control}
            name="linkedInProfile"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Perfil de LinkedIn"
                disabled={edit}
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
                InputProps={{
                  sx: { height: "48px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ height: "24px", width: "24px" }}
                        onClick={handleLinkedIn}
                      >
                        <ContentCopy fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        {...gridR}
        justifyContent={{ xs: "center", sm: "right" }}
        order={{ xs: 1, sm: 2, md: 2, lg: 2 }}
        style={{ marginBottom: "24px" }}
      >
        {edit ? null : (
          <Button
            style={{ height: "36px", width: "162px", margin: "5px 10px" }}
            variant="outlined"
            onClick={handleDisableEdit}
          >
            Cancelar
          </Button>
        )}
        {edit ? (
          <Button
            style={{ height: "36px", width: "162px", margin: "5px 10px" }}
            variant="contained"
            disabled={disabledOptions}
            onClick={handleEdit}
          >
            Editar
          </Button>
        ) : (
          <>
            <Button
              style={{ height: "36px", width: "162px", margin: "5px 10px" }}
              variant="contained"
              disabled={disabledOptions}
              onClick={hook.handleSubmit(onSubmit)}
            >
              Guardar
            </Button>
          </>
        )}
      </Grid>
      <RejectDialog id={id} showDialog={rejecD} handleReject={handleReject} />
      <NotifySnackbar
        message={notify.message}
        openSnack={notify.openSnack}
        severity={notify.severity}
      />
      <Grid order={{ xs: 3, lg: 3 }} style={{ marginTop: "1%", width: "100%" }}>
        <TabSelector id={id} edit={edit} hook={hook} />
      </Grid>
    </Grid>
  );
}
