/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, Grid, Paper } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { getOpportunityApplicationMainCard } from "../../../../services/opportunitiesApplication";

import { EditMode } from "./EditMode/EditMode";
import { ReadMode } from "./ReadMode/ReadMode";
import { styles } from "./styles";
import { IMainCardForm, IMainCardProps } from "./types";

const MainCard: FC<IMainCardProps> = (props) => {
  const { id } = props;
  const [enableEditMode, setEnableEditMode] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(true);

  const hook = useFormContext<IMainCardForm>();

  useEffect(() => {
    setLoading(true);
    getOpportunityApplicationMainCard(id)
      .then(({ data }) => {
        const values = data.info as IMainCardForm;
        hook.setValue("industry", values.industry);
        hook.setValue("startupName", values.startupName);
        hook.setValue("status", values.status);
        hook.setValue("oneliner", values.oneliner);
        hook.setValue("stage", values.stage);
        hook.setValue("ownerName", values.ownerName);
        hook.setValue("ownerLastName", values.ownerLastName);
        hook.setValue("email", values.email);
        hook.setValue(
          "phoneNumber",
          values.phoneCountryCode + values.phoneNumber,
        );
        hook.setValue("phoneCountryCode", values.phoneCountryCode);
        hook.setValue("companyType", values.companyType);
        hook.setValue("howFindAngelHub", values.howFindAngelHub);
        hook.setValue("country", values.country);
        hook.setValue("website", values.website);
        hook.setValue("uniqueValueProposal", values.uniqueValueProposal);
        hook.setValue("solvingProblem", values.solvingProblem);
        hook.setValue("founders", values.founders);
        hook.setValue("pitch", values.pitch);
        hook.setValue("deck", values.deck);
        hook.setValue("logo", values.logo);

        hook.setValue("analistAssigned", values.analistAssigned);
        hook.setValue("partnerAssigned", values.partnerAssigned);
        hook.setValue("womanFounding", values.womanFounding);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, reload]);

  return (
    <Paper square sx={styles.container}>
      {loading && (
        <Box
          height="756px"
          width={"100%"}
          display={"flex"}
          justifyContent="center"
          alignItems={"center"}
        >
          <CircularProgress />
        </Box>
      )}
      {enableEditMode && !loading && (
        <ReadMode
          hook={hook}
          changeMode={() => setEnableEditMode(!enableEditMode)}
        />
      )}
      {!enableEditMode && !loading && (
        <Grid container>
          <EditMode
            id={id}
            hook={hook}
            onReload={() => setReload(!reload)}
            changeMode={() => setEnableEditMode(!enableEditMode)}
          />
        </Grid>
      )}
    </Paper>
  );
};

export default MainCard;
