import TablePagination from "@mui/material/TablePagination";
import * as React from "react";

interface IProps {
  count: number;
  page: any;
  setPage: any;
  setCardsPerPage: any;
  cardsPerPage: any;
}

const DirectoryPagination: React.FC<IProps> = ({
  count,
  page,
  setPage,
  setCardsPerPage,
  cardsPerPage,
}) => {
  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setCardsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TablePagination
      sx={{ marginTop: "39px" }}
      component="div"
      count={count}
      page={page}
      rowsPerPageOptions={[10, 25, 50, 100]}
      onPageChange={handleChangePage}
      rowsPerPage={cardsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default DirectoryPagination;
