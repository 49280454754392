import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { History } from "history";
import { useState } from "react";
import PinInput from "react-pin-input";

import { loginSuccessActions } from "../../redux/actions/LoggedUser";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import { sendOtpCode, sendOtpMail, validateCode } from "../../services/login";
import { displayToast } from "../../utils/helpers/displayToast";
import Form, { IFields } from "../Form";

import { loginWithCodeFieldSchema } from "./constants";
import styles from "./styles";

interface ILoginWithCodeForm {
  history?: History;
}

const LoginWithCodeForm: React.VFC<ILoginWithCodeForm> = ({ history }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const isMobile = useMediaQuery("(max-width:355px)");
  const { error } = useSelector((store) => store.loggedUser);
  const dispatch = useDispatch();

  const handleLogin = async (fields: IFields) => {
    setLoading(true);
    const data = JSON.parse(sessionStorage.getItem("userCodeData") || "");
    try {
      const response = await validateCode(
        data?.mail,
        fields.code as unknown as number,
      );
      sessionStorage.removeItem("userCodeData");
      dispatch(loginSuccessActions(response.data.payload, false));
    } catch (error: any) {
      const message = error?.data?.message === "The code isn't correct ";

      if (!message)
        displayToast(
          "error",
          "El código no es correcto, revísalo e intenta de nuevo",
        );
      else
        displayToast(
          "error",
          "Ocurrió un error validando el código, revísalo e intenta de nuevo.",
        );
    } finally {
      setLoading(false);
    }
  };

  const handleRequestCodeAgain = async () => {
    const data = JSON.parse(sessionStorage.getItem("userCodeData") || "");
    try {
      await sendOtpCode(data?.mail);
      displayToast("success", "El código se ha enviado con éxito");
    } catch {
      displayToast(
        "error",
        "Necesitas esperar 1 minuto para poder solicitar un nuevo código",
      );
    }
  };

  const handleSendCodeByEmail = async () => {
    const data = JSON.parse(sessionStorage.getItem("userCodeData") || "");
    try {
      await sendOtpMail(data?.mail);
      displayToast("success", "El correo se ha enviado con éxito");
    } catch {
      displayToast(
        "error",
        "Necesitas esperar 1 minuto para poder solicitar un nuevo código",
      );
    }
  };

  return (
    <Form onSubmit={handleLogin} fields={loginWithCodeFieldSchema}>
      {({ handleSubmit, handleChange, handleBlur, valid, fields }) => {
        return (
          <Grid container direction="column">
            <Typography variant="subtitle1" sx={styles.title}>
              Codigo de verificacion
            </Typography>
            <Typography variant="subtitle2" sx={styles.subtitle}>
              Introduce el código que hemos enviado al número:{" "}
              {JSON.parse(sessionStorage.getItem("userCodeData") || "").phone ||
                "phone"}{" "}
            </Typography>
            <Grid item xs={12} sx={styles.topInputWrapper}>
              <Box display="flex" justifyContent="center">
                <PinInput
                  length={6}
                  style={{ flexWrap: "nowrap" }}
                  initialValue=""
                  onChange={(value, index) => handleChange("code")(value)}
                  type="numeric"
                  inputMode="number"
                  inputStyle={{
                    fontSize: "1rem",
                    borderRadius: "5px",
                    width: isMobile ? "1.8rem" : "2.1rem",
                    marginInline: isMobile ? ".2rem" : "0.4rem",
                  }}
                  inputFocusStyle={{ borderColor: "blue" }}
                  onComplete={(value, index) => handleChange("code")(value)}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </Box>
              <Box paddingLeft="1.5rem">
                <Typography
                  sx={{
                    paddingTop: "0.3rem",
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  {fields.code.errors.join(". ")}{" "}
                </Typography>
              </Box>
              {error && (
                <Box sx={styles.errorWrapper}>
                  <small>{error}</small>
                </Box>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ ...styles.buttonWrapper, marginTop: "-1rem" }}
            >
              <Typography
                sx={{
                  paddingTop: "0.6rem",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                ¿No has recibido el código?
              </Typography>
              <Box width="0.2rem"></Box>
              <Link onClick={() => handleRequestCodeAgain()} underline="none">
                <Typography
                  sx={{
                    paddingTop: "0.6rem",
                    fontSize: "12px",
                    fontWeight: "bold",
                    "&:hover": {
                      cursor: "pointer",
                    },
                    color: "#69BC41",
                  }}
                >
                  Solicitar de nuevo.
                </Typography>
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ ...styles.buttonWrapper, marginTop: "1.5rem" }}
            >
              {loading ? (
                <CircularProgress data-testid="loading-indicator" size={36} />
              ) : (
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  sx={styles.sendCodeButton}
                >
                  INICIAR SESIÓN
                </Button>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                ...styles.buttonWrapper,
                marginTop: "1.5rem",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                sx={{
                  paddingTop: "0.6rem",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                Enviar código por
              </Typography>
              <Box width="0.2rem"></Box>
              <Link onClick={() => handleSendCodeByEmail()} underline="none">
                <Typography
                  sx={{
                    paddingTop: "0.6rem",
                    fontSize: "12px",
                    fontWeight: "bold",
                    "&:hover": {
                      cursor: "pointer",
                    },
                    color: "#69BC41",
                  }}
                >
                  Correo.
                </Typography>
              </Link>
            </Grid>
          </Grid>
        );
      }}
    </Form>
  );
};

export default LoginWithCodeForm;
