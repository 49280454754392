import { yupResolver } from "@hookform/resolvers/yup";
import InfoIcon from "@mui/icons-material/Info";
import PaymentsIcon from "@mui/icons-material/Payments";
import { Box, Button, Grid, InputAdornment, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import { styles as mainStyles } from "../../pages/DetailsStartup/components/TabList/styles";
import { changeOpportunitiesReleased } from "../../redux/actions/opportunitiesReleased";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import { opportunitiesReleasedChangeIntention } from "../../services/opportunitiesReleased";
import { displayToast } from "../../utils/helpers/displayToast";
import { TextInput } from "../Inputs";

import { wantToInvestSchema } from "./schema";

interface IProps {
  open: boolean;
  onCancel: () => void;
  isMultiple?: boolean;
}

interface IForm {
  value: number;
}

const DialogWantToInvest: React.FC<IProps> = ({ open, onCancel }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const { opportunities, opportunity } = useSelector(
    (store) => store.opportunityReleasedReducer,
  );
  const hook = useForm<IForm>({
    resolver: yupResolver(wantToInvestSchema),
    defaultValues: {
      value: 5000,
    },
  });

  const handleUpdate = async ({ value }: IForm) => {
    const startupId = opportunity;
    const isMultiplo = value % 500 === 0;
    if (!isMultiplo) {
      hook.setError("value", {
        message: "Solo se aceptan valores múltiplo de 500.",
      });
      return;
    }

    await opportunitiesReleasedChangeIntention({
      type: "WANT_TO_INVEST",
      user: user._id,
      startupId,
      value,
    });
    const copyOpportunity = [...opportunities];
    const index = copyOpportunity.findIndex(
      (i) => String(i._id) === String(startupId),
    );

    copyOpportunity[index].usersWantToInvest = [
      ...copyOpportunity[index].usersWantToInvest,
      {
        money: value,
        user: user._id,
      },
    ];

    copyOpportunity[index].usersNotInterested = copyOpportunity[
      index
    ].usersNotInterested?.filter((i: any) => String(i) !== String(user._id));

    copyOpportunity[index].usersInterested = copyOpportunity[
      index
    ].usersInterested?.filter((i: any) => String(i.user) !== String(user._id));

    dispatch(changeOpportunitiesReleased(copyOpportunity));
    onCancel();
    hook.reset();
    displayToast("success", "Se a actualizado correctamente tu oportunidad");
  };
  const handleCancel = () => {
    onCancel();
    hook.reset();
  };

  return (
    <>
      {open && (
        <Grid
          style={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(19, 13, 16, 0.8)",
            position: "absolute",
            top: "0px",
            right: "0px",
            zIndex: "9999",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              padding: "40px 56px",
              width: "728px",
              maxHeight: "688px",
              minHeight: "200px",
              background: "#FFFFFF",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid item xs={12} sm={12} md={12} sx={{ ...mainStyles.Info }}>
              <Box
                style={{
                  width: "100%",
                  minHeight: "100%",
                }}
              >
                <Typography sx={{ ...mainStyles.title }}>
                  Abrir ticket de inversión
                </Typography>
                <Typography sx={{ fontSize: "16px" }}>
                  Por favor indícanos la cantidad de dinero que te gustaría
                  invertir en esta oportunidad, recuerda que aún no haremos el
                  cobro de nada, este valor solo es indicativo.
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "30px",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      width: "60%",
                      gap: "20px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Grid xs={12} md={12} lg={12}>
                      <Controller
                        control={hook.control}
                        name="value"
                        render={({ field, fieldState }) => {
                          return (
                            <TextInput
                              {...field}
                              type="number"
                              InputProps={{
                                inputProps: { min: 5000, step: 500 },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PaymentsIcon sx={{ color: "#8BB3D8" }} />
                                  </InputAdornment>
                                ),
                              }}
                              label="Monto a invertir"
                              required
                              autoFocus
                              fullWidth
                              error={Boolean(fieldState.error)}
                              helperText={
                                <Typography
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <InfoIcon
                                    sx={{ fontSize: "15px", color: "#8BB3D8" }}
                                  />
                                  Monto mínimo a partir de $5,000, en múltiplos
                                  de $500
                                </Typography>
                              }
                            />
                          );
                        }}
                      />
                    </Grid>
                  </Box>
                  <Box sx={{ display: "flex", gap: "20px", marginTop: "20px" }}>
                    <Button
                      variant="outlined"
                      sx={{
                        ...mainStyles.actionsButtons,
                        background: "white",
                        color: "#76c34a",
                      }}
                      onClick={handleCancel}
                    >
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      onClick={hook.handleSubmit(handleUpdate)}
                      sx={{ ...mainStyles.actionsButtons }}
                    >
                      Guardar
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default DialogWantToInvest;
