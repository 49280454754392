import { SxProps, Theme } from "@mui/material";

const ScheduleStyles: SxProps<Theme> = (theme: Theme) => ({
  "& .MuiTypography-root.MuiTypography-h2": {
    fontFamily: "Product Sans Black !important",
    fontStyle: "normal",
    fontWeight: 900,
    color: "#515151",
    [theme.breakpoints.up(1443)]: {
      fontSize: "40px",
      lineHeight: "56px",
    },
    [theme.breakpoints.down(1443)]: {
      fontSize: "32px",
      lineHeight: "48px",
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  "& .MuiTypography-root.MuiTypography-h4": {
    fontFamily: "Product Sans !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#909090",
    [theme.breakpoints.up(1443)]: {
      fontSize: "24px",
      lineHeight: "32px",
    },
    [theme.breakpoints.between(530, 1443)]: {
      fontSize: "20px",
      lineHeight: "28px",
    },
    [theme.breakpoints.down(530)]: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
});

export default ScheduleStyles;
