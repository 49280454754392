export const INDUSTRY_OPTIONS = [
  "Agrotech",
  "BioTech",
  "Ciberseguridad",
  "Cleantech",
  "Climate Innovation / Cleantech",
  "Creator Economy",
  "Deep Tech",
  "Delivery",
  "E-Commerce",
  "EdTech",
  "Energía",
  "Enterprise SaaS",
  "Entertainment and Gambling",
  "FemTech",
  "Fintech",
  "Foodtech",
  "Gaming",
  "HealthTech",
  "HR Tech",
  "Insurtech",
  "LegalTech",
  "Logtech",
  "MediaTech",
  "Movilidad",
  "Proptech",
  "Retail Tech",
  "Social commerce",
  "TravelTech",
  "Web 3.0",
  "Otra",
];

export const STAGE_OPTIONS = [
  "Pre-Seed",
  "Seed",
  "Bridge / Late Seed",
  "Serie A en adelante",
];
export const WOMAN_OPTIONS = [
  {
    value: "Si",
    name: "Sí",
  },
  {
    value: "No",
    name: "No",
  },
];

export const REPORTS_PERIODICITY = [3, 6];

export const COMPANY_TYPE_OPTIONS = [
  "SAPI",
  "SA de CV",
  "C-Corp",
  "LLC",
  "Caimán Holding",
  "Virgin Islands Holding",
  "No constituida aún",
  "Otra",
];

export const COMO_TE_ENTERASTE_OPTIONS = [
  "Referido Inversionista",
  "Referido Emprendedor",
  "LinkedIn",
  "Base de datos de inversionistas",
  "Búsqueda en Google",
  "Evento del ecosistema",
  "Instagram",
  "Podcast",
  "Otro.",
];
