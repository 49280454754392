import { EStatusOpportunity } from "../../constants";

export const VALID_STATUS_TO_EVALUATE_ANALYST = [
  EStatusOpportunity.RE_ASSIGNED_ANALYST,
  EStatusOpportunity.PROGRAMMED_ANALYST,
  EStatusOpportunity.EVALUATED_ANALYST,
  EStatusOpportunity.ASSIGNED_PARTNER,
  EStatusOpportunity.RE_ASSIGNED_PARTNER,
  EStatusOpportunity.PROGRAMMED_PARTNER,
  EStatusOpportunity.PROGRAMMING_PARTNER,
  EStatusOpportunity.EVALUATED_PARTNER,
  EStatusOpportunity.ASSIGNED_COMMITTEE,
  EStatusOpportunity.APPROVED_COMMITTEE,
  EStatusOpportunity.REJECTED,
  EStatusOpportunity.IS_MONITORING,
];
export const VALID_STATUS_TO_EVALUATE_PARTNER = [
  EStatusOpportunity.PROGRAMMED_PARTNER,
  EStatusOpportunity.EVALUATED_PARTNER,
  EStatusOpportunity.ASSIGNED_COMMITTEE,
  EStatusOpportunity.APPROVED_COMMITTEE,
  EStatusOpportunity.REJECTED,
  EStatusOpportunity.IS_MONITORING,
];

export const VALID_STATUS_TO_SHOW_EVALUATIONS_ANALYST = [
  EStatusOpportunity.PROGRAMMED_ANALYST,
  EStatusOpportunity.EVALUATED_ANALYST,
  EStatusOpportunity.ASSIGNED_PARTNER,
  EStatusOpportunity.RE_ASSIGNED_PARTNER,
  EStatusOpportunity.PROGRAMMED_PARTNER,
  EStatusOpportunity.PROGRAMMING_PARTNER,
  EStatusOpportunity.EVALUATED_PARTNER,
  EStatusOpportunity.ASSIGNED_COMMITTEE,
  EStatusOpportunity.APPROVED_COMMITTEE,
  EStatusOpportunity.REJECTED,
  EStatusOpportunity.IS_MONITORING,
];
export const VALID_STATUS_TO_SHOW_EVALUATIONS_PARTNER = [
  EStatusOpportunity.ASSIGNED_PARTNER,
  EStatusOpportunity.RE_ASSIGNED_PARTNER,
  EStatusOpportunity.PROGRAMMING_PARTNER,
  EStatusOpportunity.PROGRAMMED_PARTNER,
  EStatusOpportunity.EVALUATED_PARTNER,
  EStatusOpportunity.ASSIGNED_COMMITTEE,
  EStatusOpportunity.APPROVED_COMMITTEE,
  EStatusOpportunity.REJECTED,
  EStatusOpportunity.IS_MONITORING,
];

export const RESPONSIVE_BUTTON = "(max-width: 1274px)";
