import { Delete, FileDownload } from "@mui/icons-material";
import {
  Grid,
  Card,
  CardMedia,
  Typography,
  Button,
  useMediaQuery,
  Box,
} from "@mui/material";
import moment from "moment";

import pdf from "../../../../../../../assets/images/pdf.png";
import { useLoading } from "../../../../../../../hooks/useLoading";
import { openModal } from "../../../../../../../redux/actions/modal";
import { useDispatch } from "../../../../../../../redux/typedHooks";
import { displayToast } from "../../../../../../../utils/helpers/displayToast";
import { handleDownloadFile } from "../../../../../../../utils/helpers/downloadFile";
import {
  IFileObject,
  IFileObjectMember,
} from "../../../../../../../utils/types/common";
import { styles } from "../styles";
import {
  EFileTypes,
  VALID_TYPE_FILE_TO_UPLOAD_WITH_USER,
} from "../validations";

import { BuildFileTypeTextAll } from "./types";

interface IProps {
  fileObject: IFileObject | IFileObjectMember;
  onDelete?: (
    id: string,
    fileType: EFileTypes,
    startupId: string,
  ) => Promise<void>;
  isRoleToHideData?: boolean;
  onlyShowDownload?: boolean;
}

export const FileCard: React.FC<IProps> = ({
  fileObject,
  onDelete,
  isRoleToHideData,
  onlyShowDownload,
}) => {
  const dispatch = useDispatch();
  const { handleClose, handleOpen } = useLoading();
  const isMobile = useMediaQuery("(max-width:730px)");

  function handleDelete() {
    dispatch(
      openModal({
        title: "Eliminar",
        description: "¿Estas seguro que deseas eliminar el archivo?",
        action: async () => {
          if (onDelete) {
            handleOpen();
            try {
              await onDelete(
                fileObject.file._id,
                fileObject.fileType as EFileTypes,
                (fileObject?.startupId as string) ??
                  (fileObject?.memberId as string),
              );
              displayToast("success", "Se elimino correctamente");
            } catch (error) {
              displayToast(
                "error",
                "No de pudo eliminar correctamente el archivo",
              );
            } finally {
              handleClose();
            }
          }
        },
      }),
    );
  }

  return (
    <Grid item>
      <Card sx={isMobile ? styles.mobileCardContainer : styles.cardContainer}>
        <Box height={"100%"} display={"flex"} flexDirection="column">
          <div style={{ backgroundColor: "#F8F8F8" }}>
            <CardMedia component="img" image={pdf} sx={styles.cardImage} />
          </div>

          <Box flex={"1"} padding="20px 20px">
            <Typography sx={styles.fileType}>
              {BuildFileTypeTextAll(fileObject.fileType as any)}
            </Typography>
          </Box>

          {VALID_TYPE_FILE_TO_UPLOAD_WITH_USER.includes(
            fileObject.fileType as EFileTypes,
          ) && (
            <Box flex={"1"} padding="0px 20px">
              <Typography
                sx={styles.inversionista}
              >{`Inversionista: ${fileObject.user.firstName} ${fileObject.user.lastName}`}</Typography>
            </Box>
          )}
          <Box flex={"1"} padding="5px 20px">
            <Typography sx={styles.fileName}>{fileObject.file.name}</Typography>
          </Box>
          <Box flex={"1"} padding="5px 20px">
            <Typography sx={styles.fileName}>{`${
              "Subido el " + moment(fileObject.date).format("DD/MM/YYYY")
            }`}</Typography>
          </Box>

          {!isRoleToHideData && (
            <Box
              display={"flex"}
              justifyContent="space-between"
              flex={0}
              padding="20px 10px"
            >
              {!onlyShowDownload && (
                <Button
                  onClick={handleDelete}
                  startIcon={<Delete />}
                  sx={styles.deleteButton}
                >
                  Eliminar
                </Button>
              )}
              <Button
                onClick={() => handleDownloadFile(fileObject.file)}
                startIcon={<FileDownload />}
                sx={styles.downloadButton}
              >
                Descargar
              </Button>
            </Box>
          )}
        </Box>
      </Card>
    </Grid>
  );
};
