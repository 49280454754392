import { SxProps } from "@mui/material";

export interface StylesObject {
  [key: string]: SxProps;
}

export const styles: StylesObject = {
  container: {
    width: "100%",
    flexWrap: "wrap",
    padding: "40px 24px 40px 24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textStatus: {
    fontFamily: "Product Sans Black",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#515151",
    marginTop: "8px",
  },
  textOpenInversion: {
    fontFamily: "Product Sans Black",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#F7FCF9",
    marginTop: "12px",
    background: "#35AC65",
    borderRadius: "64px",
    padding: "3px 6px",
  },

  //Info

  textCompany: {
    fontFamily: "Product Sans Black",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#343434",
    width: "auto",
    maxWidth: "400px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  text: {
    fontSize: "16px",
  },
  oneliner: {
    fontFamily: "Product Sans Light !important",
    fontStyle: "normal",
    fontWeight: "300 !important",
    fontSize: "16px",
    lineHeight: "24px",
    marginTop: "20px",
    marginBottom: "16px",
    overflowWrap: "anywhere",
    color: "#515151",
  },
  subtitle: {
    fontWeight: "400",
    fontSize: "16px",
    marginBottom: "8px",
    color: "#1A1A1A",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  divider: {
    borderBottomWidth: "2px",
    marginTop: "16px",
    marginBottom: "16px",
  },
  industryChip: {
    marginX: "24px",
    backgroundColor: "#C0DDE7",
    border: "solid 1px #4AA5C2",
  },
  chipText: {
    color: "#397386",
    fontSize: "12px",
    fontWeight: "400",
  },
  womanChip: {
    backgroundColor: "#fde0ff",
    border: "solid 1px #4AA5C2",
  },
  timerColor: {
    color: "#9B2231",
  },
  timerText: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#5A1E26",
  },
  timerTimeBox: {
    border: "1px solid #EB7281",
    borderRadius: "5px",
    padding: 0.5,
  },
  timerInnerTimeBox: {
    borderRadius: "5px",
    backgroundColor: "#EB7281",
    padding: 0.2,
  },
  timerTimeText: {
    fontSize: "10px",
    fontWeight: "900",
    color: "#9B2231",
  },
  linkItemProperty: {
    color: "#4A88C2",
    cursor: "pointer",
    whiteSpace: "nowrap",
    width: "200px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontFamily: "Product Sans Light !important",
    fontStyle: "normal",
    fontWeight: "300 !important",
    fontSize: "16px",
  },
};
