import { Delete, FileDownload } from "@mui/icons-material";
import {
  Grid,
  Card,
  CardMedia,
  Typography,
  Button,
  useMediaQuery,
  Box,
} from "@mui/material";
import moment from "moment";

import pdf from "../../../../../../../assets/images/pdf.png";
import { useLoading } from "../../../../../../../hooks/useLoading";
import { openModal } from "../../../../../../../redux/actions/modal";
import { useDispatch } from "../../../../../../../redux/typedHooks";
import { displayToast } from "../../../../../../../utils/helpers/displayToast";
import { handleDownloadImage } from "../../../../../../../utils/helpers/downloadFile";
import { IFileObject } from "../../../../../../../utils/types/common";
import { styles } from "../styles";
import {
  EFileTypes,
  VALID_TYPE_FILE_TO_UPLOAD_WITH_USER,
} from "../validations";

interface IProps {
  fileObject: IFileObject;
  onDelete: (id: string, fileType: EFileTypes) => Promise<void>;
  isRoleToDownload: boolean;
  isRoleToDelete: boolean;
}

export type Extensions =
  | ".pdf"
  | ".png"
  | ".jpeg"
  | ".jpg"
  | ".gif"
  | ".xlsx"
  | ".csv";

export const extensiosImage: Extensions[] = [
  ".jpeg",
  ".png",
  ".gif",
  ".jpg",
  ".pdf",
  ".xlsx",
  ".csv",
];

export function BuildFileTypeText(type: EFileTypes) {
  if (type === EFileTypes.sisa) return "SISA";
  if (type === EFileTypes.dueDiligence) return "Due Dilligence";
  if (type === EFileTypes.safe) return "SAFE";
  if (type === EFileTypes.depositSlip) return "Comprobantes de depósito";
  if (type === EFileTypes.reports) return "Reporte";
  if (type === EFileTypes.depositStartup)
    return "Comprobantes de depósito de Startup";
}

export const FileCard: React.FC<IProps> = ({
  fileObject,
  onDelete,
  isRoleToDelete,
  isRoleToDownload,
}) => {
  const dispatch = useDispatch();

  const { handleClose, handleOpen } = useLoading();
  const isMobile = useMediaQuery("(max-width:730px)");

  function handleDelete() {
    dispatch(
      openModal({
        title: "Eliminar",
        description: "¿Estas seguro que deseas eliminar el archivo?",
        action: async () => {
          handleOpen();
          try {
            await onDelete(fileObject.file._id, fileObject.fileType);
            displayToast("success", "Se elimino correctamente");
          } catch (error) {
            displayToast(
              "error",
              "No de pudo eliminar correctamente el archivo",
            );
          } finally {
            handleClose();
          }
        },
      }),
    );
  }

  function handleDownload() {
    const extension = ("." +
      fileObject?.file?.name.split(".")[1].toString()) as Extensions;

    if (extensiosImage.includes(extension)) {
      handleDownloadImage(fileObject.file, extension);
    }
  }

  return (
    <Grid item>
      <Card sx={isMobile ? styles.mobileCardContainer : styles.cardContainer}>
        <Box height={"100%"} display={"flex"} flexDirection="column">
          <div style={{ backgroundColor: "#F8F8F8" }}>
            <CardMedia component="img" image={pdf} sx={styles.cardImage} />
          </div>

          <Box flex={"1"} padding="20px 20px">
            <Typography sx={styles.fileType}>
              {BuildFileTypeText(fileObject.fileType)}
            </Typography>
          </Box>

          {VALID_TYPE_FILE_TO_UPLOAD_WITH_USER.includes(
            fileObject.fileType,
          ) && (
            <Box flex={"1"} padding="0px 20px">
              <Typography
                sx={styles.inversionista}
              >{`Inversionista: ${fileObject.user.firstName} ${fileObject.user.lastName}`}</Typography>
            </Box>
          )}
          <Box flex={"1"} padding="5px 20px">
            <Typography sx={styles.fileName}>
              {fileObject?.file?.name}
            </Typography>
          </Box>
          <Box flex={"1"} padding="5px 20px">
            <Typography sx={styles.fileName}>{`${
              "Subido el " + moment(fileObject.date).format("DD/MM/YYYY")
            }`}</Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent="space-between"
            flex={0}
            padding="20px 10px"
          >
            {isRoleToDelete && (
              <Button
                onClick={handleDelete}
                startIcon={<Delete />}
                sx={styles.deleteButton}
              >
                Eliminar
              </Button>
            )}

            {isRoleToDownload && (
              <Button
                onClick={handleDownload}
                startIcon={<FileDownload />}
                sx={styles.downloadButton}
              >
                Descargar
              </Button>
            )}
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};
