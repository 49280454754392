import React from "react";

import { ContextParamTabs } from "./ContextParamTabs";

export const useParamTab = () => {
  const context = React.useContext(ContextParamTabs);
  if (!context) {
    throw new Error("No esta dentro de ParamTabProvider");
  }
  return context;
};
