const styles = {
  instructionBoxStyles: {
    width: "100%",
    maxWidth: {
      xs: "328px",
      sm: "540px",
      lg: "627px",
    },
    height: {
      xs: "128px",
      sm: "108px",
      lg: "112px",
    },
    border: "0.8px solid #A6D88B",
    boxShadow: "2px 2px 0px #4A7C2F",
    borderRadius: "8px",
    backgroundColor: "#F8F8F8",
    marginBottom: "24px",
  },
  instructionsWithoutTitleStyles: {
    padding: {
      xs: "16px",
    },
    width: "100%",
    border: "0.8px solid #A6D88B",
    boxShadow: "2px 2px 0px #4A7C2F",
    borderRadius: "8px",
    backgroundColor: "#F8F8F8",
    marginBottom: "24px",
  },
  numberBoxStyles: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    fontFamily: "Product Sans Bold !important",
    color: "#4A7C2F",
    padding: {
      xs: "3px 7.5px",
      md: "3.5px 9px",
      xl: "4px 10px",
    },
    gap: {
      xs: "7.5px",
      md: "8.75px",
      xl: "10px",
    },
    width: "32px",
    height: "32px",

    backgroundColor: "#D3EBC5",
    border: "1px solid #74C24A",
    borderRadius: "100px",
  },
  cardTitleStyles: {
    fontWeight: "900",
    fontFamily: "Product Sans Bold !important",
    fontSize: {
      xs: "16px",
      lg: "20px",
      xl: "24px",
    },
    color: "#515151",
  },
  cardSubtitleStyles: {
    fontWeight: "300",
    fontFamily: "Product Sans Light !important",
    width: "100%",
    maxWidth: {
      xs: "224px",
      lg: "428px",
      xl: "515px",
    },
    fontSize: {
      xs: "14px",
      lg: "16px",
      xl: "20px",
    },
    color: "#909090",
  },
  cardSubtitleWithoutTitleStyles: {
    fontWeight: "300",
    fontFamily: "Product Sans Light !important",
    width: "100%",
    fontSize: {
      xs: "12px !important",
      lg: "14px !important",
      xl: "18px !important",
    },
    lineHeight: "22px !important",
    color: "#909090",
  },
};

export default styles;
