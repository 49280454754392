import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Grid, MenuItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { TextInput } from "../../../components/Inputs";
import { IUser } from "../../../models/User";
import { setValueCalendar } from "../../../redux/actions/member";
import { useDispatch, useSelector } from "../../../redux/typedHooks";
import { fetchUserByRole, updatePartner } from "../../../services/users";
import { CAMPO_REQUERIDO } from "../../../utils/text/inputs";
import { ERoles } from "../../routes/role-catalog";

import ScheduleStyles from "./styles";

type FormType = {
  partner: string;
};

interface IScheduleProps {
  handleNext?: () => void;
  handleBack: () => void;
}

const Schedule: React.FC<IScheduleProps> = ({ handleNext, handleBack }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { calendar } = useSelector((store) => store.memberApplication);

  const [Partners, setPartners] = useState<IUser[]>([]);
  const { control, handleSubmit } = useForm<FormType>({
    mode: "all",
    defaultValues: {
      partner: calendar,
    },
  });
  const application = useSelector((store) => store.memberApplication);

  useEffect(() => {
    (async () => {
      const { data } = await fetchUserByRole(ERoles.RL001);
      setPartners(data.payload as any as IUser[]);
    })();
  }, []);

  async function onSubmit({ partner }: FormType) {
    dispatch(setValueCalendar(partner));
    await updatePartner({ _id: application._id, partner });
    const data = Partners.find((i) => i._id === partner);
    if (data) {
      const url = `/calendly?url=${data.calendlyEvent}?utm_source=${application._id}&email=${application.email}&name=${application.name}`;
      history.push(url);
    }
  }

  return (
    <Box
      height="100%"
      overflow="hidden"
      display={"flex"}
      flexDirection="column"
      sx={ScheduleStyles}
    >
      <Grid container justifyContent={"center"}>
        <Grid item xs={12}>
          <Typography variant="h2">Agenda tu entrevista</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">
            Selecciona una cita con nuestro equipo para conocernos
          </Typography>
        </Grid>
        <Grid
          container
          item
          sx={{ marginBottom: "48px", marginTop: "48px" }}
          xs={12}
        >
          <Controller
            control={control}
            name="partner"
            rules={{
              required: CAMPO_REQUERIDO,
            }}
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                sx={{ width: "302px" }}
                select
                label="Seleccionar horario"
                error={Boolean(fieldState.error)}
                helperText={
                  Boolean(fieldState.error?.message) &&
                  fieldState.error?.message
                }
                fullWidth
              >
                {Partners.map((value, index) => (
                  <MenuItem key={index} value={value._id}>
                    {`Calendario ${index + 1}`}
                  </MenuItem>
                ))}
              </TextInput>
            )}
          />
        </Grid>
      </Grid>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"flex-end"}
        sx={{ pt: "48px !important" }}
        flex="1"
      >
        <Button
          onClick={handleBack}
          variant="text"
          startIcon={<ArrowBack />}
          color="info"
          className="stepper-back-button"
        >
          REGRESAR
        </Button>

        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
        >
          CONTINUAR
        </Button>
      </Box>
    </Box>
  );
};

export default Schedule;
