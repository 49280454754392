export const CleanUrlString = (url?: string | null) => {
  if (!url) return "";

  const stringsToRemove = ["http://www.", "https://www."];

  let replacedUrl = url;

  stringsToRemove.every((option) => {
    replacedUrl = replacedUrl.replace(option, "www.");
  });

  return replacedUrl;
};

export const FormatPhoneNumberSection = (number: string) => {
  const dividingLada = (
    number.substring(0, 2) +
    " " +
    number.substring(2)
  ).split(" ");
  dividingLada[1] =
    dividingLada[1].substring(0, 4) + " " + dividingLada[1].substring(4);

  return dividingLada[0] + " " + dividingLada[1];
};
