import { UploadFile } from "@mui/icons-material";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import SplitButton from "../../../../../../components/SplitButton/SplitButton";
import { useLoading } from "../../../../../../hooks/useLoading";
import {
  addFileOpportunity,
  deleteFileOpportunity,
  getAttachedFilesOpportunity,
} from "../../../../../../services/opportunitiesApplication";
import { displayToast } from "../../../../../../utils/helpers/displayToast";
import { IFile } from "../../../../../../utils/types/common";
import ButtonResponsive from "../../../../../DetailsStartup/components/TabList/ButtonResponsive/ButtonResponsive";
import { RESPONSIVE_BUTTON } from "../../../../validations";
import Layout from "../../../Layout/Layout";

import { FileCard } from "./FileCard/FileCard";

const AttachedFiles = () => {
  const { id } = useParams() as { id: string };
  const ref = useRef<HTMLInputElement>(null);

  const breakpoint = useMediaQuery(RESPONSIVE_BUTTON);
  const { handleClose, handleOpen } = useLoading();
  const [Files, setFiles] = useState<IFile[]>([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const existFiles = Files.length;

  useEffect(() => {
    getAttachedFilesOpportunity(id)
      .then(({ data }) => {
        setFiles(data.info.addFile);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, reload]);

  function handleOpenMenu(event: any) {
    setAnchorEl(event?.currentTarget);
  }
  function handleCloseMenu() {
    setAnchorEl(null);
  }

  async function handleDeleteFile(fileId: string) {
    await deleteFileOpportunity(id, fileId);
    const copyFile = [...Files];
    const newFiles = copyFile.filter((i) => i._id !== fileId);
    setFiles(newFiles);
  }

  async function handleChangeFile(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length) {
      const file = event.target.files[0];
      const maxFileSize = 26355054;
      if (file.size > maxFileSize) {
        displayToast("error", `El archivo excede los 25 MB`);
        return;
      }

      const form = new FormData();
      form.append("addFile", file);

      handleOpen();
      await addFileOpportunity(id, form);
      setReload(!reload);
      handleClose();
      handleCloseMenu();
    }
  }
  const optionsButton = [
    {
      label: "Subir archivo",
      visible: true,
      onClick: () => ref.current?.click(),
    },
  ];

  return (
    <Layout>
      <Layout.Button>
        {!breakpoint && (
          <SplitButton options={optionsButton} title="Acciones" />
        )}
      </Layout.Button>
      <Layout.Panel loading={loading}>
        <input
          ref={(e) => ((ref as any).current = e)}
          type="file"
          hidden
          accept={".pdf"}
          onChange={handleChangeFile}
        />
        <Grid container>
          {breakpoint && (
            <Grid
              container
              item
              justifyContent={{ xs: "right", sm: "space-between" }}
              xs={12}
              pb={"16px"}
            >
              <Grid item />
              <Grid item order={{ xs: "1", sm: "2" }}>
                <SplitButton
                  options={optionsButton}
                  isMobile
                  title="Acciones"
                  icon={<UploadFile />}
                />
              </Grid>
            </Grid>
          )}
          <Grid
            container
            item
            justifyContent={{ xs: "center", sm: "left" }}
            gap={"16px"}
          >
            {existFiles ? (
              Files.map((file, index) => (
                <FileCard onDelete={handleDeleteFile} file={file} key={index} />
              ))
            ) : (
              <Box display={"flex"} justifyContent="center" width={"100%"}>
                <Typography component={"h4"} variant="h4">
                  No hay archivos disponibles
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Layout.Panel>
    </Layout>
  );
};

export default AttachedFiles;
