import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
} from "@mui/x-data-grid";
import moment from "moment";
import { NavLink } from "react-router-dom";

import { CustomSort } from "../../components/DataGrid";
import Ellipsis from "../../utils/components/ellipsis";
import { currencyFormat } from "../../utils/helpers/currencyFormatter";
import { limitCurrency } from "../../utils/helpers/limitCurrency";
import { translateStatusOpportunities } from "../../utils/text/translates";

export const columnsOpportunity: GridColDef[] = [
  {
    headerName: "Oportunidad",
    field: "startupName",
    hideSortIcons: true,
    flex: 0.7,
    minWidth: 300,
    align: "left",
    headerAlign: "left",
    valueGetter: (data) => `${data.row.startupName}`,
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
    renderCell: (data) => {
      const { startupId, _id } = data.row;
      let url = "";
      if (startupId) {
        url = `/details-startup/${startupId}`;
      } else {
        url = `/details-startup-opportunity/${_id}`;
      }
      return (
        <Ellipsis
          value={
            <NavLink style={{ color: "#4A88C2" }} to={url}>
              {data.row.startupName}
            </NavLink>
          }
        />
      );
    },
  },
  {
    headerName: "Fecha de aplicación",
    field: "applicationDate",
    flex: 0.7,
    align: "left",
    headerAlign: "left",
    hideSortIcons: true,
    minWidth: 170,
    maxWidth: 170,
    renderCell(params) {
      return params.row.createdAt
        ? moment(params.row.createdAt).format("YYYY-MM-DD")
        : "";
    },
    valueGetter(params) {
      return params.row.createdAt;
    },
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "Etapa",
    field: "stage",
    flex: 0.3,
    align: "left",
    headerAlign: "left",
    hideSortIcons: true,
    minWidth: 170,
    maxWidth: 170,
    valueGetter(params) {
      return params.row?.stage;
    },
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "Industria",
    field: "industry",
    hideSortIcons: true,
    flex: 0.5,
    minWidth: 160,
    maxWidth: 160,
    align: "left",
    headerAlign: "left",
    valueGetter: (data) => `${data.row.industry}`,
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "País",
    field: "country",
    hideSortIcons: true,
    flex: 0.5,
    minWidth: 145,
    maxWidth: 145,
    align: "left",
    headerAlign: "left",
    valueGetter: (data) => `${data.row.country}`,
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "Estatus",
    field: "status",
    hideSortIcons: true,
    flex: 0.5,
    minWidth: 195,
    maxWidth: 195,
    align: "left",
    headerAlign: "left",
    valueGetter: (data) => `${translateStatusOpportunities(data.row.status)}`,
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "Asignación",
    field: "analistAssigned",
    hideSortIcons: true,
    minWidth: 190,
    maxWidth: 190,
    flex: 0.5,
    align: "left",
    headerAlign: "left",
    valueGetter: (data) => {
      if (data.row.partnerAssigned) {
        return `${data.row.partnerAssigned.firstName} ${data.row.partnerAssigned.lastName}`;
      }
      if (data.row.analistAssigned) {
        return `${data.row.analistAssigned.firstName} ${data.row.analistAssigned.lastName}`;
      }
      if (!data.row.partnerAssigned) {
        return `Sin asignar`;
      }
    },
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
  {
    headerName: "Inversión Req. USD",
    field: "requiredInvestment",
    flex: 0.8,
    hideSortIcons: true,
    minWidth: 175,
    maxWidth: 175,
    align: "right",
    headerAlign: "right",
    renderCell: (data) =>
      limitCurrency(currencyFormat(data.row.requiredInvestment) as string),
    valueGetter: (data) => {
      return parseFloat(data.row.requiredInvestment);
    },
  },
  {
    headerName: "Valuación USD",
    field: "valuation",
    hideSortIcons: true,
    minWidth: 130,
    maxWidth: 130,
    flex: 0.8,
    align: "right",
    headerAlign: "right",
    valueGetter: (data) => {
      return parseFloat(data.row.valuation);
    },
    renderCell: (data) =>
      `${limitCurrency(currencyFormat(data.row.valuation ?? 0) as string)}`,
  },

  {
    headerName: "Días en el estatus",
    field: "dateStatus",
    hideSortIcons: true,
    flex: 0.7,
    minWidth: 150,
    maxWidth: 150,
    align: "right",
    headerAlign: "right",
    valueGetter: (data) => {
      const creationDay = new Date(data.row.dateStatus);
      const currentDay = moment();
      return currentDay.diff(creationDay, "days");
    },
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  },
];
