import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

import { ModalMenu } from "../../../components/DataGrid";
import { IMemberDashboardRanking } from "../types";

import RankingInvestmentStyles from "./style";

interface ModalMenuProps {
  _id: string;
  name: string;
}

interface RankingInvestmentProps {
  rankings: IMemberDashboardRanking[];
}

const RankingInvestment: React.FC<RankingInvestmentProps> = ({ rankings }) => {
  const history = useHistory();

  const renderModalMenu = (row: ModalMenuProps[]) => {
    const detailsOptionsList = row.map((i: any) => ({
      name: i.name,
      action: () => {
        history.push(`/details-startup/${i._id}`);
      },
    }));
    return <ModalMenu menuVert items={detailsOptionsList} />;
  };

  return (
    <Box sx={RankingInvestmentStyles}>
      <Box sx={{ px: 2, pt: 2, pb: 3 }}>
        <Typography variant="h5">Ranking de miembros</Typography>
      </Box>
      <TableContainer component={Box}>
        <Table sx={{ minWidth: 657 }} size="small" aria-label="members ranking">
          <TableHead>
            <TableRow>
              <TableCell width={"50px"} align="center">
                Lugar
              </TableCell>
              <TableCell align="left">Nombre de Inversionista</TableCell>
              <TableCell align="center">Inversiones</TableCell>
              <TableCell align="center">Startup</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rankings.map((item, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  width={"100px"}
                >
                  {index + 1}
                </TableCell>
                <TableCell align="left">{item.nameUser}</TableCell>
                <TableCell align="right" style={{ width: "100px" }}>
                  {item.numberStartups}
                </TableCell>
                <TableCell align="center" style={{ minWidth: "120px" }}>
                  {renderModalMenu(item.startupsDetails)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RankingInvestment;
