import { EStatusReleasedOpportunity } from "../../constants";

export const VALID_STATUS_TO_CLOSE = [
  EStatusReleasedOpportunity.IN_REVIEW,
  EStatusReleasedOpportunity.OPEN_TO_INVESTMENT,
];

export const VALID_STATUS_TO_EXTENDER_INVESTMENT_DATE = [
  EStatusReleasedOpportunity.CANCELLED,
  EStatusReleasedOpportunity.EXPIRED,
  EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT,
  EStatusReleasedOpportunity.IN_INVESTMENT_PROCESS,
];
