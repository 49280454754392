import { ReactElement } from "react";

import { EStatusOpportunity } from "../../constants";

export enum EStatusAppointment {
  CREATED = "CREATED",
  ACCEPTED = "ACCEPTED",
  CANCELED = "CANCELED",
}

export const VALID_STATUS_RESIGNATION_PARTNER = [
  EStatusOpportunity.PROGRAMMED_PARTNER,
  EStatusOpportunity.PROGRAMMING_PARTNER,
  EStatusOpportunity.ASSIGNED_PARTNER,
  EStatusOpportunity.RE_ASSIGNED_PARTNER,
];
export const VALID_STATUS_RESIGNATION_ANALYST = [
  EStatusOpportunity.PROGRAMMED_ANALYST,
  EStatusOpportunity.PROGRAMMING_ANALYST,
  EStatusOpportunity.ASSIGNED_ANALYST,
  EStatusOpportunity.RE_ASSIGNED_ANALYST,
];

export const VALID_STATUS_TO_REJECT = [
  EStatusOpportunity.PROGRAMMED_ANALYST,
  EStatusOpportunity.RE_ASSIGNED_ANALYST,
  EStatusOpportunity.PROGRAMMED_PARTNER,
  EStatusOpportunity.RE_ASSIGNED_PARTNER,
  EStatusOpportunity.APPROVED_COMMITTEE,
  EStatusOpportunity.IS_MONITORING,
];

export const RE_ASSIGNED_OPPORTUNITY_STATUS = [
  {
    name: "Analista reasignado",
    value: EStatusOpportunity.RE_ASSIGNED_ANALYST,
  },
  {
    name: "Partner reasignado",
    value: EStatusOpportunity.RE_ASSIGNED_PARTNER,
  },
];

export enum EStatusOpportunityAssignation {
  ASSIGNED_ANALYST = "ASSIGNED_ANALYST",
  ASSIGNED_PARTNER = "ASSIGNED_PARTNER",
  ASSIGNED_COMMITTEE = "ASSIGNED_COMMITTEE",
}
export const FILTER_OPTIONS_OPPORTUNITY_ASSIGNED = [
  {
    name: "Analista Asignado",
    value: EStatusOpportunityAssignation.ASSIGNED_ANALYST,
  },
  {
    name: "Partner Asignado",
    value: EStatusOpportunityAssignation.ASSIGNED_PARTNER,
  },
  {
    name: "Comité Asignado",
    value: EStatusOpportunityAssignation.ASSIGNED_COMMITTEE,
  },
];

export enum EStatusOpportunityEvaluation {
  EVALUATED_ANALYST = "EVALUATED_ANALYST",
  EVALUATED_PARTNER = "EVALUATED_PARTNER",
}

export const FILTER_OPTIONS_OPPORTUNITY_EVALUATED = [
  {
    name: "Evaluado Analista",
    value: EStatusOpportunityEvaluation.EVALUATED_ANALYST,
  },
  {
    name: "Evaluado Partner",
    value: EStatusOpportunityEvaluation.EVALUATED_PARTNER,
  },
];

export interface IBaseToolbarProps {
  onSearchChange?: (value: string) => void;
  onIsActiveChange?: (value: string | null) => void;
  onStatusChange?: (value: string) => void;
  onDateChange?: (value: string) => void;
  onTabStatusChange?: (value: string) => void;
  handleChangeAssigned: () => void;
  onAsignedChange?: (value: string, roleCheck: string) => void;
  onStageChange?: (value: string) => void;
  onSearchClear?: () => void;
  onCountryChange?: (value: string) => void;
  onIndustryChange?: (value: string) => void;
  buttonAction?: () => void;
  onTabChange?: (value: string) => void;
  buttonText: string;
  hideButton?: boolean;
  hideIcons?: boolean;
  children: ReactElement;
}

export interface IStatus {
  name: string;
  value: string;
}

export interface ICountries {
  name_en?: string;
  name_es?: string;
  continent_en?: string;
  continent_es?: string;
  capital_en?: string;
  capital_es?: string;
  dial_code?: string;
  code_2?: string;
  code_3?: string;
  tld?: string;
}

export enum AsignationType {
  isPartner = "isPartner",
  isAnalyst = "isAnalyst",
}

export const VALID_STATES_TO_UPDATE_PARTNER = [
  EStatusOpportunity.ASSIGNED_ANALYST,
  EStatusOpportunity.RE_ASSIGNED_ANALYST,
  EStatusOpportunity.ASSIGNED_ANALYST,
  EStatusOpportunity.ASSIGNED_ANALYST,
];
