import { Dispatch } from "redux";

import { IEditInitialState } from "../reducers/userInvertorEdit";

export const EDIT_MODE = "EDIT_MODE";
export const RESET_EDIT_MODE = "RESET_EDIT_MODE";
export const getEditId =
  (payload: IEditInitialState) => (dispatch: Dispatch) => {
    return dispatch({
      type: EDIT_MODE,
      payload,
    });
  };

export const resetEditMode = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: RESET_EDIT_MODE,
    });
  };
};
