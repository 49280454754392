import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@mui/styles";
import { RouteChildrenProps } from "react-router";

import LoginLayout from "../../components/LoginLayout";

import ResetPasswordRequestForm from "./ResetPasswordRequestForm";

export const useStyles = makeStyles({
  card: {
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContent: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    marginLeft: 37,
    marginRight: 38,
    padding: 0,
  },
});

const ForgotPassword: React.FC<RouteChildrenProps> = ({
  history,
  location,
  match,
}) => {
  const styles = useStyles();
  return (
    <LoginLayout showBanner={false}>
      <Card className={styles.card}>
        <CardContent className={styles.cardContent}>
          <ResetPasswordRequestForm
            history={history}
            location={location}
            match={match}
          />
        </CardContent>
      </Card>
    </LoginLayout>
  );
};
export default ForgotPassword;
