import {
  Box,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import React from "react";

import { CategoryGraphNumberInvestmentAdmin } from "../InvestCard";

import { IDataGridInvestmentProps } from "./DataGridInvestmentIProps";

const DataGridInvestment: React.FC<IDataGridInvestmentProps> = (props) => {
  const { rows, onTabChange, tab } = props;

  const value = {
    numberByCountry: "País",
    numberByIndustry: "Industria",
    numberByStage: "Etapa",
  };

  const handleChange = (
    _: React.SyntheticEvent,
    newValue: CategoryGraphNumberInvestmentAdmin,
  ) => {
    onTabChange(newValue);
  };

  const stylesHeaderCell = {
    fontFamily: "Product Sans Medium !important",
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.87);",
  };

  return (
    <Box
      display="flex"
      justifyContent={"center"}
      flexDirection="column"
      alignItems={"center"}
      gap={"20px"}
      width={"100%"}
    >
      <Tabs value={tab} onChange={handleChange} sx={{ border: "none" }}>
        <Tab
          label="Industria"
          value={"numberByIndustry" as CategoryGraphNumberInvestmentAdmin}
        />
        <Tab
          label="País"
          value={"numberByCountry" as CategoryGraphNumberInvestmentAdmin}
        />
        <Tab
          label="Etapa"
          value={"numberByStage" as CategoryGraphNumberInvestmentAdmin}
        />
      </Tabs>
      <Box width={"100%"} height={"100%"}>
        <TableContainer component={Box}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell sx={stylesHeaderCell} align="left">
                  {value[tab]}
                </TableCell>
                <TableCell sx={stylesHeaderCell} align="right">
                  #
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row._id}
                  </TableCell>
                  <TableCell align="right">{row.count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DataGridInvestment;
