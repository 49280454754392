export const YES_NO_OPTIONS = [
  {
    value: true,
    displayName: "Sí",
  },
  {
    value: false,
    displayName: "No",
  },
];
