import * as yup from "yup";

import {
  CAMPO_REQUERIDO,
  CORREO_NO_VALIDO,
} from "../../../../../utils/text/inputs";
import {
  LINKEDIN_URL_VALIDA,
  URL_VALIDA_FORM,
} from "../../../../../utils/text/resetForm";
import { VALIDATE_LINKEDIN_PROFILE_REGEX } from "../../../../MemberFaceliftOnboarding/FormRecapcha/schema";

export const VALIDATE_WEB_REGEX =
  /^(https?:\/\/)?([A-Za-z0-9-]+\.)+[A-Za-z]{2,}(\S*)?$/;

export const editOpportunityStartup = yup.object({
  companyType: yup.string().required(CAMPO_REQUERIDO),
  country: yup.string().required(CAMPO_REQUERIDO),
  email: yup.string().email(CORREO_NO_VALIDO).required(CAMPO_REQUERIDO),
  howFindAngelHub: yup.string().required(CAMPO_REQUERIDO),
  industry: yup.string().required(CAMPO_REQUERIDO),
  oneliner: yup
    .string()
    .max(450, "Campo máximo de 450 caracteres.")
    .required(CAMPO_REQUERIDO),
  ownerLastName: yup.string().required(CAMPO_REQUERIDO),
  ownerName: yup.string().required(CAMPO_REQUERIDO),
  phoneCountryCode: yup.string(),
  phoneNumber: yup.string().required(CAMPO_REQUERIDO),
  stage: yup.string().required(CAMPO_REQUERIDO),
  startupName: yup.string().required(CAMPO_REQUERIDO),
  status: yup.string().required(CAMPO_REQUERIDO),
  founders: yup.array().of(
    yup.object({
      name: yup.string(),
      role: yup.string(),
      linkedIn: yup.string().matches(VALIDATE_LINKEDIN_PROFILE_REGEX, {
        message: LINKEDIN_URL_VALIDA,
        excludeEmptyString: true,
      }),
    }),
  ),
  pitch: yup.string().matches(VALIDATE_WEB_REGEX, {
    message: URL_VALIDA_FORM,
    excludeEmptyString: true,
  }),
  solvingProblem: yup.string().max(450, "Campo máximo de 450 caracteres."),
  uniqueValueProposal: yup.string().max(450, "Campo máximo de 450 caracteres."),
  website: yup
    .string()
    .required(CAMPO_REQUERIDO)
    .matches(VALIDATE_WEB_REGEX, URL_VALIDA_FORM),
});
