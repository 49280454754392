export const DIRECTORY_INVESTORS_INDUSTRY_OPTIONS = [
  "Fintech",
  "HR Tech",
  "Agrotech",
  "Deep Tech",
  "Logtech",
  "E-Commerce",
  "Enterprise SaaS",
  "Proptech",
  "Foodtech",
  "Web 3.0",
  "Social commerce",
  "HealthTech",
  "Cleantech",
  "Creator Economy",
  "TravelTech",
  "Insurtech",
  "EdTech",
  "Ciberseguridad",
  "LegalTech",
  "Gaming",
  "Energía",
  "Delivery",
  "FemTech",
  "MediaTech",
  "Movilidad",
  "Otra",
];
