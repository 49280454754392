import React, { useEffect, useState } from "react";

import { getUserById } from "../../../../services/users";

export const useNameUser = (id: string) => {
  const [Name, setName] = useState<string>("");

  useEffect(() => {
    if (id) {
      getUserById(id).then(({ data }) => {
        setName(`${data.payload.firstName} ${data.payload.lastName}`);
      });
    }
  }, [id]);

  return Name;
};
