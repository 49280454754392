import { Dispatch } from "redux";

import { ENotificationType } from "../../components/DashboardLayout/NotificationCard/constants";

export const ADD_NOTIFICATION = "[NOTIFICATION]:ADD_NOTIFICATION";
export const addNotification =
  (data: ENotificationType) => (dispatch: Dispatch) => {
    return dispatch({
      type: ADD_NOTIFICATION,
      payload: data,
    });
  };

export const REMOVE_NOTIFICATION = "[NOTIFICATION]:REMOVE_NOTIFICATION";
export const removeNotification = (index: number) => (dispatch: Dispatch) => {
  return dispatch({
    type: REMOVE_NOTIFICATION,
    payload: index,
  });
};

export const CLEAR_NOTIFICATIONS = "[NOTIFICATION]:CLEAR_NOTIFICATIONS";
export const clearNotification = () => (dispatch: Dispatch) => {
  return dispatch({
    type: CLEAR_NOTIFICATIONS,
  });
};
export const REFRESH_NOTIFICATIONS = "[NOTIFICATION]:REFRESH_NOTIFICATIONS";
export const refreshNotification = () => (dispatch: Dispatch) => {
  return dispatch({
    type: REFRESH_NOTIFICATIONS,
  });
};
