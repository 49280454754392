import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { ReactElement, useState } from "react";

import boardIcon from "../../assets/images/board-icon.png";
import calendarIcon from "../../assets/images/calendar-icon.png";
import letterIcon from "../../assets/images/letter-icon.png";
import logo from "../../assets/images/logo-color_negro.png";
import greenAreaImage from "../../assets/images/pre-register-green-area-image.png";
import {
  setActiveStep,
  setValueFormApplication,
} from "../../redux/actions/member";
import { KeysValueForm, Steps } from "../../redux/reducers/member";
import { useDispatch, useSelector } from "../../redux/typedHooks";

import Disclaimer from "./Disclaimer/Disclaimer";
import FormData from "./FormData/FormData";
import FormRecapcha from "./FormRecapcha/FormRecapcha";
import InstructionCard from "./InstructionCard/InstructionCard";
import Schedule from "./Schedule/Schedule";
import { MemberFaceliftOnboardingStyle } from "./styles";

export interface ISteps {
  0: ReactElement;
  1: ReactElement;
  2: ReactElement;
  3: ReactElement;
}

const MemberFaceliftOnboarding: React.FC = () => {
  const dispatch = useDispatch();
  const [showStepper, setShowStepper] = useState<boolean>(false);
  const [secondStepForm, setSecondStepForm] = useState(false);
  const { state, activeStep } = useSelector((store) => store.memberApplication);

  const data = (key: KeysValueForm, newValue: any) => {
    dispatch(setValueFormApplication(key, newValue));
  };

  const startProcess = () => {
    setShowStepper(true);
  };

  function handleReturnToStart(): void {
    setShowStepper(false);
  }

  function handleChangeStep(value: Steps) {
    dispatch(setActiveStep(value));
  }

  function getStepContent(step: Steps) {
    const value = {
      0: (
        <Disclaimer
          handleBack={() => handleReturnToStart()}
          handleNext={() => handleChangeStep(1)}
        />
      ),
      1: !secondStepForm ? (
        <FormData
          handleBack={() => handleChangeStep(0)}
          handleNext={() => setSecondStepForm(true)}
          catchData={data}
          state={state}
        />
      ) : (
        <FormRecapcha
          handleBack={() => setSecondStepForm(false)}
          handleNext={() => handleChangeStep(2)}
          catchData={data}
          state={state}
        />
      ),
      2: <Schedule handleBack={() => handleChangeStep(2)} />,
    } as ISteps;
    return value[step];
  }

  return (
    <Grid
      container
      direction={{ xs: "column", lg: "row" }}
      justifyContent="flex-start"
      alignItems="stretch"
      sx={MemberFaceliftOnboardingStyle}
      columns={10}
    >
      {/* LEFT GRID */}
      <Grid
        item
        lg={5.05729}
        md={5}
        className="member-facelift-onboarding__left"
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          flexWrap={"nowrap"}
          height={"100%"}
        >
          <Grid item xs={8} className={"greenTextGrid"}>
            <Typography className={"greenTextStyles"}>
              En
              <span className={"angelhubTextStyle"}> AngelHub </span>
              somos el club de ángeles inversionistas más importante de
              Latinoamérica.
            </Typography>
            <Typography className={"greenTextStyles"}>
              Ofrecemos oportunidades de alto potencial, seguridad legal y
              relacionamiento con otros inversionistas.
            </Typography>
            <Box className="imgMargin" sx={{ textAlign: "center" }}>
              <img
                alt="angelhub-welcome"
                src={greenAreaImage}
                className={"angelhubImg"}
              />
            </Box>
          </Grid>
          <Grid item xs={9}></Grid>
        </Grid>
      </Grid>
      {/* RIGHT GRID */}
      <Grid
        item
        lg={4.9427}
        md={7}
        className="member-facelift-onboarding__right"
        display="flex"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Grid container direction="column" sx={{ height: "85%" }}>
          {showStepper ? (
            <Box
              display={"flex"}
              flexDirection="column"
              sx={{ height: "100%" }}
            >
              <Box>
                <img src={logo} alt="logo" className="logo" />
              </Box>
              <Box className="stepper-container">
                <Stepper activeStep={activeStep} alternativeLabel>
                  {new Array(3).fill("").map((label, index) => (
                    <Step
                      key={index}
                      className={`${
                        index === 0
                          ? "left-step"
                          : index === 2
                          ? "right-step"
                          : ""
                      }`}
                    >
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <Box height={"100%"}>{getStepContent(activeStep)}</Box>
            </Box>
          ) : (
            <>
              <Grid
                container
                direction="column"
                spacing={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Grid
                  item
                  display="flex"
                  justifyContent="space-around"
                  flexWrap="wrap"
                  flexDirection="column"
                  alignContent="space-around"
                  sx={{ flex: "0" }}
                >
                  <Box>
                    <img src={logo} alt="logo" className="logo" />
                  </Box>
                  <Typography className={"titleTextStyle"}>
                    Únete al Club
                  </Typography>
                  <Typography className={"grayTextStyle"}>
                    Haz tu registro en 3 sencillos pasos y sé parte del mejor
                    club de ángeles inversionistas de Latinoamérica.
                  </Typography>
                  <InstructionCard
                    instructionStep={1}
                    icon={letterIcon}
                    title="Consideraciones sobre Inversiones Ángel"
                    subtitle="Conoce los beneficios y riesgos de ser ángel"
                  />
                  <InstructionCard
                    instructionStep={2}
                    icon={boardIcon}
                    title="Ingresa tus datos"
                    subtitle="Llena un formulario con tu información personal"
                  />
                  <InstructionCard
                    instructionStep={3}
                    icon={calendarIcon}
                    title="Agenda tu entrevista"
                    subtitle="Queremos conocer tu perfil e intereses"
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  justifyContent="space-around"
                  alignItems={"flex-end"}
                  sx={{ flex: "1" }}
                  alignContent="space-around"
                >
                  <Box className="continueButton-style">
                    <Button
                      onClick={() => startProcess()}
                      variant="contained"
                      color="primary"
                    >
                      CONTINUAR
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MemberFaceliftOnboarding;
