/* eslint-disable react-hooks/exhaustive-deps */
import { GridColDef, GridSortItem } from "@mui/x-data-grid";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import BaseDataGrid from "../../components/BaseDataGrid/BaseDataGrid";
import { ModalMenu } from "../../components/DataGrid";
import { DEFAULT_ROWS_PAGE_SIZE } from "../../components/DataGrid/constants";
import OpportunityToolbar from "../../components/Toolbars/OpportunityToolbar";
import { ETabValues } from "../../components/Toolbars/OpportunityToolbar/Tabs";
import { EStatusOpportunity } from "../../constants";
import { useLoading } from "../../hooks/useLoading";
import { openModal } from "../../redux/actions/modal";
import {
  changeOpportunities,
  changeOpportunity,
  fetchOpportunityPaginated,
} from "../../redux/actions/opportunities";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import { updatePartnerOpportunity } from "../../services/opportunitiesApplication";
import { updateStatusOpportunity } from "../../services/opportunitiesApplication2";
import { displayToast } from "../../utils/helpers/displayToast";
import DialogReject from "../DetailsOpportunity/components/MainCard/ReadMode/DialogReject/DialogReject";
import { ERolesCode } from "../routes/role-catalog";

import DialogChangePartnerOpportunity from "./DialogChangePartnerOpportunity/DialogChangePartner";
import { columnsOpportunity } from "./columnsOpportunity";
import {
  VALID_STATUS_RESIGNATION_ANALYST,
  VALID_STATUS_RESIGNATION_PARTNER,
  VALID_STATUS_TO_REJECT,
} from "./constants";

const OpportunityList: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleClose, handleOpen } = useLoading();
  const { opportunities, loading, opportunity } = useSelector(
    (store) => store.opportunityReducer,
  );
  const { loggedUser } = useSelector((store) => store.loggedUser);

  const [idReject, setIdReject] = useState<string>("");
  const [dialog, setDialog] = useState<boolean>(false);
  const [Open, setOpen] = useState<boolean>(false);
  const [RowsChecked, setRowsChecked] = useState<string[]>([]);

  const [Search, setSearch] = useState<string>("");
  const [Status, setStatus] = useState<string>("");
  const [TabStatus, setTabStatus] = useState<string>("");
  const [DateT, setDate] = useState<string>("");
  const [Asigned, setAsigned] = useState<any>({});
  const [Stage, setStage] = useState<string>("");
  const [Industry, setIndustry] = useState<string>("");
  const [Country, setCountry] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_ROWS_PAGE_SIZE);
  // Default sorting
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: "applicationDate",
      sort: "desc",
    },
  ]);

  useEffect(() => {
    setPage(0);
    setPageSize(DEFAULT_ROWS_PAGE_SIZE);
  }, [Search, Status, TabStatus, DateT, Asigned, Stage, Industry, Country]);

  useEffect(() => {
    dispatch(fetchOpportunityPaginated());
  }, []);

  const handleDetails = (data: any) => {
    const { startupId, _id } = data.row;
    if (startupId) {
      history.push(`/details-startup/${startupId}`);
    } else {
      history.push(`/details-startup-opportunity/${_id}`);
    }
  };

  function handleCloseDialog() {
    setDialog(false);
    setRowsChecked([]);
    const copyApplication = [...opportunities];
    copyApplication.map((i) => {
      if (RowsChecked.includes(i._id)) {
        i.status = EStatusOpportunity.REJECTED;
      }
      return i;
    });
  }

  const renderModalMenu = (data: any) => {
    const status = data.row.status;

    let detailsOptionsList = [
      {
        name: "Ver detalles",
        action: () => handleDetails(data),
      },
      {
        name: "Rechazar",
        action: () => handleRejectOpportunity(data.id),
      },
    ];
    const detailsOptionsListCopy = {
      name: "Reasignar",
      action: () => {
        dispatch(
          openModal({
            title: "Reasignar",
            description: "¿Estás seguro de que quieres reasignar?",
            action() {
              setOpen(true);
              dispatch(changeOpportunity(data.row));
            },
          }),
        );
      },
    };

    if (!VALID_STATUS_TO_REJECT.includes(status)) {
      detailsOptionsList = detailsOptionsList.filter(
        (i) => i.name !== "Rechazar",
      );
    }

    const roleCode = loggedUser.user.role.code;

    const isPartnerValid = roleCode === ERolesCode.PARTNER;

    const isValidStatusPartner = VALID_STATUS_RESIGNATION_PARTNER.includes(
      data.row.status,
    );

    if (isPartnerValid && isValidStatusPartner) {
      detailsOptionsList.push(detailsOptionsListCopy);
    }

    const isValidRole = roleCode === ERolesCode.ANALYST;

    const isAnalystValid =
      data.row.analistAssigned?._id === loggedUser.user._id;

    const isValidStatusAnalyst = VALID_STATUS_RESIGNATION_ANALYST.includes(
      data.row.status,
    );

    if (
      (isValidRole && isAnalystValid && isValidStatusAnalyst) ||
      (isPartnerValid && isValidStatusAnalyst)
    ) {
      detailsOptionsList.push(detailsOptionsListCopy);
    }

    return <ModalMenu menuVert items={detailsOptionsList} />;
  };

  const newColumns = (): GridColDef[] => [
    ...columnsOpportunity,
    {
      headerName: "Acciones",
      field: "name",
      hideSortIcons: true,
      align: "center",
      headerAlign: "center",
      renderCell: (data: any) => renderModalMenu(data),
    },
  ];

  async function handleUpdatePartner(data: any, isPartner: boolean) {
    handleOpen();

    const body = {
      status: isPartner
        ? EStatusOpportunity.RE_ASSIGNED_PARTNER
        : EStatusOpportunity.RE_ASSIGNED_ANALYST,
      date: moment().toDate(),
    } as any;

    isPartner ? (body.partnerId = data._id) : (body.analystId = data._id);

    await updatePartnerOpportunity(opportunity._id, body);
    displayToast("success", "Se actualizó la oportunidad correctamente");

    const copyOpportunity = [...opportunities];
    const opportunityIndex = copyOpportunity.findIndex(
      (i) => String(i._id) === String(opportunity._id),
    );

    copyOpportunity[opportunityIndex].partnerAssigned = data;
    copyOpportunity[opportunityIndex].status =
      EStatusOpportunity.RE_ASSIGNED_PARTNER;

    dispatch(changeOpportunities(copyOpportunity));
    setOpen(false);
    handleClose();
  }

  function handleRejectOpportunity(id: string) {
    setIdReject(id);
    setDialog(true);
  }
  // REJECTED
  async function handleRechazar(data: string) {
    dispatch(
      openModal({
        title: "Advertencia",
        description: "¿Seguro que desea rechazar esta oportunidad?",
        action: async () => {
          handleOpen();
          try {
            await updateStatusOpportunity(idReject, {
              date: new Date(),
              status: EStatusOpportunity.REJECTED,
              comment: data,
            });
            displayToast("success", "Se ha rechazado oportunidad.");
          } catch (error) {
            displayToast("error", "No se pudo rechazar oportunidad.");
          } finally {
            handleClose();
          }
        },
      }),
    );
  }

  function handleFilter(data: any[]) {
    let values = data.filter((opportunity: any) => {
      return (
        opportunity.startupName.toLowerCase().includes(Search.toLowerCase()) ||
        !Search
      );
    });

    if (Status) {
      values = values.filter((i) => i.status === Status);
    }
    if (Stage) {
      values = values.filter((i) => i.stage === Stage);
    }
    if (Industry) {
      values = values.filter((i) => i.industry === Industry);
    }
    if (Country) {
      values = values.filter((i) => i.country === Country);
    }
    if (TabStatus) {
      if (ETabValues.ASSIGNED === TabStatus) {
        values = values.filter((i) => i.isAssigned);
      } else if (ETabValues.EVALUATED === TabStatus) {
        values = values.filter((i) => i.isEvaluated);
      } else {
        values = values.filter((i) => i.status === TabStatus);
      }
    }
    if (DateT) {
      values = values.filter((i) => {
        const end = dayjs();
        const start = dayjs(end).subtract(parseInt(DateT), "month");
        const mainDate = dayjs(new Date(i.applicationDate));
        return mainDate.isAfter(start) && mainDate.isBefore(end);
      });
    }
    if (Asigned) {
      if (Asigned.check === "isAnalyst" && Asigned.value) {
        values = values.filter(
          (i) => i?.analistAssigned?._id === Asigned.value,
        );
      }
      if (Asigned.check === "isPartner" && Asigned.value) {
        values = values.filter(
          (i) => i?.partnerAssigned?._id === Asigned.value,
        );
      }
    }

    values = values.filter(
      (i: any) => i.status !== EStatusOpportunity.APPROVED_COMMITTEE,
    );

    return values;
  }

  return (
    <>
      <DialogChangePartnerOpportunity
        onAccept={handleUpdatePartner}
        onCancel={() => {
          setOpen(false);
        }}
        open={Open}
      />
      <BaseDataGrid
        page={page}
        loading={loading}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        pageSize={pageSize}
        columns={newColumns()}
        getRowId={(row) => row._id}
        selectionModel={RowsChecked}
        rows={handleFilter(opportunities)}
        onPageChange={(value) => setPage(value)}
        onPageSizeChange={(value) => setPageSize(value)}
        onSelectionModelChange={(ids) => setRowsChecked(ids as string[])}
        components={{
          Toolbar: OpportunityToolbar,
        }}
        componentsProps={{
          toolbar: {
            showOptions: Boolean(RowsChecked.length),
            onSearchChange: (value: string) => {
              setSearch(value);
            },
            onStatusChange: (value: string) => {
              setStatus(value);
            },
            onTabStatusChange: (value: string) => {
              setTabStatus(value);
            },
            onAsignedChange: (value: string, check: string) => {
              const assignedValues = { value, check };
              setAsigned(assignedValues);
            },
            onDateChange: (value: string) => {
              setDate(value);
            },
            handleChangeAssigned: () => {
              setOpen(true);
            },
            onStageChange: (value: string) => {
              setStage(value);
            },
            onIndustryChange: (value: string) => {
              setIndustry(value);
            },
            onCountryChange: (value: string) => {
              setCountry(value);
            },
            onSearchClear: () => {
              setSearch("");
            },
            hideIcons: true,
          },
        }}
      />
      <DialogReject
        open={dialog}
        handleClose={handleCloseDialog}
        handleSubmit={handleRechazar}
      />
    </>
  );
};

export default OpportunityList;
