import { Box, Card, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import { useSelector } from "../../../redux/typedHooks";
import { currencyFormat } from "../../../utils/helpers/currencyFormatter";
import MemberDashboardCardHeader from "../components/MemberDashboardCardHeader";
import { IMemberPortfolio } from "../types";

import MyPortfolioIndicator from "./MyPortfolioIndicator";
import MyPortfolioStyles from "./style";

interface IMyPortfolioProps {
  data: IMemberPortfolio;
}

const MyPortfolio: React.FC<IMyPortfolioProps> = ({ data }) => {
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  const memberId = user?.member?._id;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const urlRedirect = `/member-profile/${memberId}?Tab=MyPortfolio`;

  return (
    <Box sx={MyPortfolioStyles}>
      <Card className="my-portfolio-card">
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          columnSpacing={2}
          rowSpacing={2.5}
        >
          <Grid item xs={12}>
            <MemberDashboardCardHeader variant="h5">
              Mi portafolio
            </MemberDashboardCardHeader>
          </Grid>
          <Grid item xs={12} sm={6} xl={2.5}>
            <MyPortfolioIndicator
              indicator="Mis inversiones"
              value={data.myInvestments.toString()}
              useDivider={!isMobile}
              useMobileDivider={isMobile}
              url={`${urlRedirect}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} xl={3.5}>
            <MyPortfolioIndicator
              indicator="Capital invertido"
              value={currencyFormat(data.totalCashInvested) || ""}
              useDivider={isDesktop}
              useMobileDivider={isMobile}
              url={`${urlRedirect}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} xl={3.5}>
            <MyPortfolioIndicator
              indicator="Valor de portafolio"
              value={currencyFormat(data.totalPortfolioValue) || ""}
              useDivider={!isMobile}
              useMobileDivider={isMobile}
              url={`${urlRedirect}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} xl={2.5}>
            <MyPortfolioIndicator
              indicator="Rendimiento"
              value={data.performanceMoic.toString() + "x"}
              useDivider={false}
              url={`${urlRedirect}`}
            />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default MyPortfolio;
