import { Box, Card, CardContent, useMediaQuery } from "@mui/material";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import { reloadUser } from "../../redux/actions/LoggedUser";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import { updateUser } from "../../services/users";

import ProfileAnalyst from "./ProfileAnalyst/ProfileAnalyst";
import styles from "./styles";

const ProfileAnalystScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { loggedUser } = useSelector((store) => store.loggedUser);
  const isMobile = useMediaQuery("(max-width:860px)");

  async function handleSubmit(AdminFormdata: any) {
    await updateUser(loggedUser.user._id, AdminFormdata);
    dispatch(reloadUser());
  }

  return (
    <Box>
      <PageTitle title="Perfil de Analista" />
      <Card
        style={
          isMobile
            ? {
                width: "93vw",
                height: "90%",
                maxWidth: "827px",
                marginTop: "152px",
                marginLeft: "3%",
              }
            : {
                width: "80vw",
                height: "80vh",
                maxWidth: "1592px",
                marginTop: "152px",
                marginLeft: "3%",
              }
        }
      >
        <CardContent sx={styles.cardContent}>
          <ProfileAnalyst
            id={loggedUser.user._id}
            handleSubmit={handleSubmit}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProfileAnalystScreen;
