import {
  CHANGE_OPPORTUNITY_RELEASED_VALUE,
  CHANGE_OPPORTUNITIES_RELEASED_VALUE,
  CHANGE_LIMIT_OPPORTUNITIES_RELEASED,
  CHANGE_PAGE_OPPORTUNITIES_RELEASED,
  FETCH_OPPORTUNITIES_RELEASED_PAGINATED,
  LOADING_OPPORTUNITY_RELEASED,
} from "../actions/opportunitiesReleased";

export interface ILoggedApplicationReducer {
  opportunities: any[];
  page: number;
  limit: number;
  loading: boolean;
  totalCount: number;
  opportunity: string;
}

const initialState: ILoggedApplicationReducer = {
  opportunities: [],
  page: 0,
  limit: 10,
  loading: true,
  totalCount: 100,
  opportunity: "",
};

export default function OpportuniyReleasedReducer(
  state = initialState,
  action: any,
) {
  switch (action.type) {
    case FETCH_OPPORTUNITIES_RELEASED_PAGINATED:
      return {
        ...state,
        opportunities: action.payload,
      };
    case CHANGE_PAGE_OPPORTUNITIES_RELEASED:
      return {
        ...state,
        page: action.payload,
      };
    case CHANGE_LIMIT_OPPORTUNITIES_RELEASED:
      return {
        ...state,
        limit: action.payload,
        page: 0,
      };
    case LOADING_OPPORTUNITY_RELEASED:
      return {
        ...state,
        loading: action.payload,
      };
    case CHANGE_OPPORTUNITIES_RELEASED_VALUE:
      return {
        ...state,
        opportunity: action.payload,
      };
    case CHANGE_OPPORTUNITY_RELEASED_VALUE:
      return {
        ...state,
        opportunities: action.payload,
      };
    default:
      return state;
  }
}
