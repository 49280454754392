import { MenuItem } from "@mui/material";
import { withStyles } from "@mui/styles";

import { paletteColors } from "../../../styles/theme";

export const MyMenuItem = withStyles({
  root: {
    "&:hover": {
      backgroundColor: `${paletteColors.primary} !important`,
    },
  },
})(MenuItem);
