import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";

import { CustomSort } from "../../components/DataGrid";
import Ellipsis from "../../utils/components/ellipsis";
import { translateMemberShipType } from "../../utils/text/translates";

export const columns = (): GridColDef[] => [
  {
    headerName: "Nombre de inversionista",
    field: "firstName",
    hideSortIcons: true,
    minWidth: 255,
    flex: 0.7,
    align: "left",
    renderCell: (data) => {
      const memberId = data.row.member._id;
      let url = "";
      memberId ? (url = `member-profile/${memberId}`) : (url = "");
      return (
        <Ellipsis
          value={
            <NavLink style={{ color: "#4A88C2" }} to={url}>
              {data.row.firstName + " " + data.row.lastName}
            </NavLink>
          }
        />
      );
    },
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            columnWidth={200 || colDef?.width}
            description={colDef.description}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
    valueGetter: (data) => {
      return `${data.row.firstName} ${data.row.lastName}`;
    },
  },
  {
    headerName: "Tipo de membresía",
    field: "membershipType",
    align: "left",
    hideSortIcons: true,
    minWidth: 160,

    flex: 0.7,
    renderCell: (data) => {
      return translateMemberShipType(data.row.member.membershipType);
    },
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
    valueGetter: (data) => {
      return translateMemberShipType(data.row.member.membershipType);
    },
  },
  {
    headerName: "Estatus de la membresía",
    field: "isActiveMemberShip",
    align: "left",
    hideSortIcons: true,
    minWidth: 200,

    flex: 0.7,
    renderCell: (data) =>
      `${data.row.member.isActiveMemberShip ? "Activa" : "Vencida"}`,
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
    valueGetter: (data) => {
      return `${data.row.member.isActiveMemberShip ? "Activa" : "Vencida"}`;
    },
  },
  {
    headerName: "Fecha de vencimiento",
    field: "membershipEndDate",
    hideSortIcons: true,
    minWidth: 170,

    flex: 0.7,
    renderCell: (data) =>
      `${dayjs(data.row.member.membershipEndDate).format("YYYY-MM-DD")}`,
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
    valueGetter: (data) => {
      return `${dayjs(data.row.member.membershipEndDate).format("YYYY-MM-DD")}`;
    },
  },
];
