import { Box, Card, Grid, Link, List, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import CardHeader from "../CardHeader";
import ModalSeeAll from "../ModalSeeAll/ModalSeeAll";

import PlatformActivityItem from "./PlatformActivityItem";
import PlatformActivityStyles from "./style";

export interface IPlatformActivity {
  date: string;
  id: string;
  image: {
    createdAt: string;
    key: string;
    name: string;
    updatedAt: string;
    uri: string;
  };
  startup: string;
  type: string;
  user: string;
}

interface IPlatformActivityProps {
  data: IPlatformActivity[];
  maxItems?: number;
}

const PlatformActivity: React.FC<IPlatformActivityProps> = ({
  data,
  maxItems = 5,
}) => {
  const [OpenModalListItems, setOpenModalListItems] = useState<boolean>(false);
  const truncatedData = [...data].slice(0, maxItems - 1);

  const truncatedDataNotNull = truncatedData.filter((item, index) => {
    return item !== null;
  });

  return (
    <>
      <ModalSeeAll
        open={OpenModalListItems}
        onClose={() => setOpenModalListItems(false)}
      >
        {data.map((activity, index) => (
          <PlatformActivityItem hideDivider key={index} activity={activity} />
        ))}
      </ModalSeeAll>
      <Box sx={PlatformActivityStyles}>
        <Card className="activity-card">
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              rowSpacing={2}
            >
              <Grid item xs={12} className="card-header">
                <CardHeader
                  variant="h5"
                  sx={{
                    px: 2,
                    pt: 2,
                    pb: 0,
                  }}
                >
                  Actividad de Miembros
                </CardHeader>
              </Grid>
              {truncatedDataNotNull.length > 0 && (
                <Grid item xs={12}>
                  <List>
                    {truncatedDataNotNull.map((activity, index) => (
                      <PlatformActivityItem
                        key={activity.id + index}
                        activity={activity}
                      />
                    ))}
                  </List>
                </Grid>
              )}
            </Grid>
            {truncatedDataNotNull.length === 0 && (
              <Box className="noElements">
                <Typography
                  fontSize={"20px"}
                  textAlign={"center"}
                  color={"#515151"}
                >
                  No se ha encontrado actividad de miembros
                </Typography>
              </Box>
            )}
            {data.length > maxItems && (
              <Link
                underline="none"
                sx={{ pb: 2 }}
                component={"button"}
                onClick={() => setOpenModalListItems(true)}
              >
                Ver toda la actividad
              </Link>
            )}
          </Stack>
        </Card>
      </Box>
    </>
  );
};

export default PlatformActivity;
