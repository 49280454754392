import { Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { styles as mainStyles } from "../../../../styles";
import { EvaluationForm } from "../consts";

interface IProps {
  isEditing?: boolean;
  hook: UseFormReturn<EvaluationForm>;
  index: number;
}

const TextFinalComments: React.FC<IProps> = (props) => {
  const { hook, isEditing, index } = props;

  const isCommentEmpty = !hook.watch(`comments.${index}.comment`);

  if (isCommentEmpty && !isEditing) {
    return null;
  }

  return (
    <Grid item xs={12} md={6}>
      <Typography sx={mainStyles.title}>Comentario final</Typography>
      <br />
      {isEditing ? (
        <Controller
          control={hook.control}
          name={`comments.${index}.comment`}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              multiline
              rows={3}
              fullWidth
              error={Boolean(fieldState.error?.message)}
            />
          )}
        />
      ) : (
        <Typography
          sx={{
            textOverflow: "ellipsis",
            wordBreak: "break-all",
            overflow: "hidden",
          }}
        >
          {hook.watch(`comments.${index}.comment`)}
        </Typography>
      )}
    </Grid>
  );
};

export default TextFinalComments;
