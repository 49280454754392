import { Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { evaluations } from "../../../../../../../components/DetailsStartup/constants";
import { useSelector } from "../../../../../../../redux/typedHooks";
import { ERolesCode } from "../../../../../../routes/role-catalog";

import InfoEvaluations from "./InfoEvaluations/InfoEvaluations";
import ItemForm from "./ItemForm/ItemForm";
import TextFinalComments from "./TextFinalComments/TextFinalComments";
import { EvaluationForm, IEvaluation, TypeEvaluation } from "./consts";

interface IProps {
  hook: UseFormReturn<EvaluationForm>;
  typeEvaluation: TypeEvaluation;
  setTypeEvaluation: (value: TypeEvaluation) => void;
}

const FormEvaluations: React.FC<IProps> = ({
  hook,
  setTypeEvaluation,
  typeEvaluation,
}) => {
  const [evaluations, setEvaluations] = useState<IEvaluation[]>([]);
  const { loggedUser, edit } = useSelector((store) => store.loggedUser);
  const isAdminOrAnalyst =
    loggedUser.user.role.code !== ERolesCode.STARTUP &&
    loggedUser.user.role.code !== ERolesCode.MEMBER;

  useEffect(() => {
    const conditionalEvaluations = hook.watch(
      `evaluations.${typeEvaluation}.evaluations`,
    );
    setEvaluations(conditionalEvaluations);
  }, [typeEvaluation]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <InfoEvaluations
          edit={edit}
          hook={hook}
          typeEvaluation={typeEvaluation}
          changeEvaluation={setTypeEvaluation}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ marginY: "7px" }} />
      </Grid>
      <Grid container item xs={12} spacing={4}>
        {evaluations.map((i, index) => (
          <ItemForm
            key={index}
            index={index}
            hook={hook}
            typeEvaluation={typeEvaluation}
            isEditing={edit}
            text={i.key}
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ marginY: "7px" }} />
      </Grid>
      <Grid container item xs={12}>
        {isAdminOrAnalyst &&
          hook
            .watch("comments")
            .map((_, index) => (
              <TextFinalComments
                key={index}
                hook={hook}
                index={index}
                isEditing={edit}
              />
            ))}
      </Grid>
    </Grid>
  );
};
export default FormEvaluations;
