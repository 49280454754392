import { SvgIconComponent } from "@mui/icons-material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CachedIcon from "@mui/icons-material/Cached";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

export interface INotification {
  icon: SvgIconComponent;
  text: string;
  title: string;
}

export enum ENotificationType {
  MEMBER_MEMBERSHIP_NOTIFICATION = "MEMBER_MEMBERSHIP_NOTIFICATION",
  STARTUP_FILL_OUT_YOUR_INFORMATION_NOTIFICATION = "STARTUP_FILL_OUT_YOUR_INFORMATION_NOTIFICATION",
  STARTUP_REVIEW_YOUR_INFORMATION_NOTIFICATION = "STARTUP_REVIEW_YOUR_INFORMATION_NOTIFICATION",
  ADMIN_REVIEW_YOUR_INFORMATION_NOTIFICATION_ADMIN = "ADMIN_REVIEW_YOUR_INFORMATION_NOTIFICATION_ADMIN",
  ALL_USERS_NEW_NOTICE = "ALL_USERS_NEW_NOTICE",
}

export interface INotificationCardProps {
  type: ENotificationType;
  handleCloseDrawerNotification: () => void;
}

export const MEMBER_MEMBERSHIP_NOTIFICATION = {
  icon: CachedIcon,
  title: "Membresía",
  text: "¡Hola!, Es hora de renovar tu membresía. ",
};

export const STARTUP_FILL_OUT_YOUR_INFORMATION_NOTIFICATION = {
  icon: BorderColorIcon,
  title: "Startup",
  text: "¡Hola!, Es hora de llenar tus datos faltantes.",
};
export const STARTUP_REVIEW_YOUR_INFORMATION_NOTIFICATION = {
  icon: BorderColorIcon,
  title: "Startup",
  text: "¡Hola!, Actualmente un administrador estará revisando tus datos.",
};
export const ADMIN_REVIEW_YOUR_INFORMATION_NOTIFICATION_ADMIN = {
  icon: BorderColorIcon,
  title: "Acción necesaria",
  text: "¡Hola!, En tu dashboard, puedes encontrar las secciones de acciones donde se asigna fecha AMA y Fecha de Expiración a algunas startups. ¡Descúbrelas allí!",
};
export const ALL_USERS_NEW_NOTICE = {
  icon: NotificationsActiveIcon,
  title: "Nuevo aviso",
  text: "¡Hola! Parece que tienes un nuevo aviso. Te invitamos a revisarlo en la sección de avisos de tu dashboard para mantenerte al tanto. ¡No te lo pierdas!",
};

export const notifications: Record<ENotificationType, INotification> = {
  [ENotificationType.MEMBER_MEMBERSHIP_NOTIFICATION]:
    MEMBER_MEMBERSHIP_NOTIFICATION,
  [ENotificationType.STARTUP_FILL_OUT_YOUR_INFORMATION_NOTIFICATION]:
    STARTUP_FILL_OUT_YOUR_INFORMATION_NOTIFICATION,
  [ENotificationType.STARTUP_REVIEW_YOUR_INFORMATION_NOTIFICATION]:
    STARTUP_REVIEW_YOUR_INFORMATION_NOTIFICATION,
  [ENotificationType.ADMIN_REVIEW_YOUR_INFORMATION_NOTIFICATION_ADMIN]:
    ADMIN_REVIEW_YOUR_INFORMATION_NOTIFICATION_ADMIN,
  [ENotificationType.ALL_USERS_NEW_NOTICE]: ALL_USERS_NEW_NOTICE,
};
