import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FileUpload from "react-material-file-upload";

import Recaptcha from "../../../components/Captcha/Captcha";
import { TextInput } from "../../../components/Inputs";
import { useLoading } from "../../../hooks/useLoading";
import { setValueMemberApplication } from "../../../redux/actions/member";
import { IPreMember, KeysValueForm } from "../../../redux/reducers/member";
import { useDispatch } from "../../../redux/typedHooks";
import { displayToast } from "../../../utils/helpers/displayToast";
import { CAMPO_REQUERIDO } from "../../../utils/text/inputs";
import { COMO_TE_ENTERASTE_OPTIONS } from "../../StartupRegister/constants";

import { currencies } from "./dropdownInfo";
import { OPTIONS_PROFILE_IDENTIFY } from "./options";
import { EAction } from "./reducer";
import { preMemberSchema2 } from "./schema";

interface IProps {
  catchData: (action: KeysValueForm, newValue: any) => void;
  state: IPreMember;
  handleNext: () => void;
  handleBack: () => void;
}

type FormType = {
  linkedInProfile: string;
  hasExperienceInvesting: boolean; //opciones por definir
  interestToEnter: string;
  howFindAngelHub: string;
  cv: File[];
  profileIdentify: string;
};

const FormRecapcha = (props: IProps) => {
  const { state, handleBack, handleNext, catchData } = props;
  const { handleClose, handleOpen } = useLoading();
  const [drop, setDrop] = useState([]);
  const [files, setFiles] = useState<File[]>([]);
  const [errorFile, setErrorFile] = useState<string>("");
  const isMobile = useMediaQuery("(max-width:700px)");
  const [ValidRepcha, setValidRepcha] = useState(false);

  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
  } = useForm<FormType>({
    resolver: yupResolver(preMemberSchema2),
    mode: "all",
    defaultValues: {
      linkedInProfile: state.linkedInProfile,
      hasExperienceInvesting: state.hasExperienceInvesting, //opciones por definir
      interestToEnter: state.interestToEnter,
      howFindAngelHub: state.howFindAngelHub,
      profileIdentify: "",
      cv: files,
    },
  });

  useEffect(() => {
    (async function () {
      const dropdownInterest = await axios.get(`/interests-to-enter/`);
      const dropdownInfo = dropdownInterest.data.payload;
      return setDrop(dropdownInfo);
    })();
  }, []);

  function handleSuccessCaptcha() {
    setValidRepcha(true);
    displayToast("success", "Captcha exitoso");
  }

  function handleFailCaptcha() {
    setValidRepcha(false);
    displayToast("error", "Ocurrió un error al validar el captcha");
  }

  function handleChangeFile(data: File[]) {
    if (data.length > 0) {
      if (data.length > 1) {
        setErrorFile("Solo puede subirse un archivo a la vez");
        return false;
      }

      if (data[0].size > 25 * 1024 * 1024) {
        setErrorFile(
          "El archivo es demasiado pesado. El peso límite es de 25MB.",
        );
        return false;
      }
    }

    setErrorFile("");
    setFiles(data);
    setValue("cv", data, { shouldValidate: true });
  }

  async function onSubmit({
    linkedInProfile,
    hasExperienceInvesting,
    interestToEnter,
    howFindAngelHub,
    profileIdentify,
  }: FormType) {
    if (!ValidRepcha) {
      displayToast("error", "Ocurrio un error al validar el captcha");
      return;
    }
    if (files.length <= 0 && linkedInProfile === "") {
      setErrorFile(CAMPO_REQUERIDO);
      return;
    }
    if (files.length > 1) {
      setErrorFile("Solo puedes subir un archivo");
      return;
    }

    catchData(EAction.INTERESTTOENTER, state.interestToEnter);
    catchData(EAction.LINKEDINPROFILE, state.linkedInProfile);
    catchData(EAction.INVESTMENTQUESTION, state.investmentQuestion);

    const formdata = new FormData();
    const OnlyPhone = state.phoneNumber.slice(state.phoneCountryCode.length);

    formdata.append("firstName", state.firstName);
    formdata.append("lastName", state.lastName);
    formdata.append("phoneNumber", OnlyPhone);
    formdata.append("nationalityCountry", state.nationalityCountry);
    formdata.append("residenceCountry", state.residenceCountry);
    formdata.append("residenceCity", state.residenceCity);
    let finalLinkedInValue = linkedInProfile.replaceAll("http://", "");
    finalLinkedInValue = finalLinkedInValue.replaceAll("https://", "");
    formdata.append("linkedInProfile", finalLinkedInValue);
    formdata.append(
      "hasExperienceInvesting",
      hasExperienceInvesting as unknown as string,
    );
    formdata.append("interestToEnter", interestToEnter);
    formdata.append("howFindAngelHub", howFindAngelHub);
    formdata.append("cv", files[0]);
    formdata.append("email", state.email);
    formdata.append("phoneCountryCode", state.phoneCountryCode);
    formdata.append("dateApplication", new Date() as any);
    formdata.append("profileIdentify", profileIdentify);

    const config = {
      method: "post",
      url: "/member-applications",
      data: formdata,
    };

    try {
      handleOpen();
      const { data } = await axios.request(config as any);
      dispatch(
        setValueMemberApplication({ key: "email", value: data.payload.email }),
      );
      dispatch(
        setValueMemberApplication({ key: "_id", value: data.payload._id }),
      );
      dispatch(
        setValueMemberApplication({
          key: "name",
          value: `${state.firstName} ${state.lastName}`,
        }),
      );
      dispatch(
        setValueMemberApplication({
          key: "phoneNumber",
          value: data.payload.phoneNumber,
        }),
      );
      handleNext();
    } catch (error: any) {
      if (error?.data?.message) {
        displayToast("error", error?.data?.message);
      } else {
        displayToast("error", "Ocurrió un error al enviar la solicitud");
      }
    } finally {
      handleClose();
    }
  }

  return (
    <Box
      height="100%"
      overflow="hidden"
      display={"flex"}
      flexDirection="column"
    >
      <Box flex="0">
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ pb: 2 }}>
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Product Sans Black !important",
                fontStyle: "normal",
                fontWeight: 900,
                fontSize: "32px",
                color: "#515151",
              }}
            >
              Perfil de inversionista
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Controller
              control={control}
              name="linkedInProfile"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  required
                  label="Liga a tu perfil de LinkedIn"
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Controller
              control={control}
              name="hasExperienceInvesting"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  required
                  select
                  label="¿Has invertido en Startups?"
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                >
                  {currencies.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.value as unknown as string}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextInput>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Controller
              control={control}
              name="interestToEnter"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  required
                  select
                  label="¿Cual es tu interés en AngelHub?"
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                >
                  {drop.map((option: any, index) => (
                    <MenuItem key={index} value={option.text}>
                      {option.text}
                    </MenuItem>
                  ))}
                </TextInput>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Controller
              control={control}
              name="howFindAngelHub"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  label="¿Cómo llegó a AngelHub?"
                  required
                  select
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                >
                  {COMO_TE_ENTERASTE_OPTIONS.map((i, index) => (
                    <MenuItem value={i} key={index}>
                      {i}
                    </MenuItem>
                  ))}
                </TextInput>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="profileIdentify"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  required
                  select
                  label="¿Con qué perfil de inversionista me identifico?"
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                >
                  {OPTIONS_PROFILE_IDENTIFY.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.value as unknown as string}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextInput>
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ pt: "40px !important", pb: 5 }}>
            <Controller
              control={control}
              name="cv"
              render={({ field }) => (
                <FileUpload
                  {...field}
                  value={files}
                  accept="application/pdf"
                  onChange={handleChangeFile}
                  buttonText="Agregar CV"
                  title="Agrega tu CV arrastrándolo o dando click."
                  sx={{
                    border: "1px dashed rgba(0, 0, 0, 0.23)",
                    borderRadius: "4px",
                    height: "160px",
                  }}
                />
              )}
            />
            {Boolean(errorFile) && (
              <FormHelperText error={Boolean(errorFile)}>
                {errorFile}
              </FormHelperText>
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent={isMobile ? "center" : "flex-end"}
            sx={{ pt: "0 !important" }}
          >
            <Recaptcha
              captchaSucceededHandler={handleSuccessCaptcha}
              captchaFailedHandler={handleFailCaptcha}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={"flex-end"}
        sx={{ pt: "24px !important" }}
        flex="1"
      >
        <Button
          onClick={handleBack}
          variant="text"
          startIcon={<ArrowBack />}
          color="info"
          className="stepper-back-button"
        >
          REGRESAR
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          color="primary"
          disabled={!(isDirty && isValid)}
        >
          CONTINUAR
        </Button>
      </Box>
    </Box>
  );
};

export default FormRecapcha;
