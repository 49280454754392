import { StylesObject } from "../../pages/DetailsOpportunity/components/MainCard/styles";

export const styles: StylesObject = {
  avatar: {
    borderRadius: "100px",
    maxHeight: "160px",
    maxWidth: "160px",
    width: "10rem",
    height: "10rem",
    backgroundColor: "#A6D88B",
    backgroundPosition: "center",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontFamily: "Product Sans Black",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "48px",
    lineHeight: "64px",
    color: "#515151",
  },
};
