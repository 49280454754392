import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { TextInput } from "../../../components/Inputs";
import StandardDialog from "../../../components/StandardDialog";
import { useSelector } from "../../../redux/typedHooks";
import { fetchUserByRole } from "../../../services/users";
import { ERolesCode } from "../../routes/role-catalog";

interface IProps {
  open: boolean;
  onCancel: () => void;
  onAccept: (id: any, isPartner: boolean) => void;
  isMultiple?: boolean;
}

const DialogChangePartnerOpportunity: React.FC<IProps> = ({
  open,
  onCancel,
  onAccept,
}) => {
  const { opportunity } = useSelector((store) => store.opportunityReducer);

  const [Users, setUsers] = useState<any[]>([]);
  const [Partner, setPartner] = useState<string>("");

  useEffect(() => {
    if (opportunity) {
      let role = ERolesCode.ANALYST;

      if (opportunity.partnerAssigned) {
        role = ERolesCode.PARTNER;
      } else {
        role = ERolesCode.ANALYST;
      }

      fetchUserByRole(role).then(({ data }) => {
        const users = data.payload;
        setUsers(users);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity?.analistAssigned, opportunity.partnerAssigned]);

  const handleUpdate = () => {
    const partner = Users.find((i) => i._id === Partner);

    onAccept(partner, Boolean(opportunity.partnerAssigned));
  };
  const handleCancel = () => {
    onCancel();
  };

  return (
    <StandardDialog open={open}>
      <DialogTitle>Reasignar</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Selecciona el socio al que deseas reasignar esta aplicación de
          oportunidad.
        </DialogContentText>
        <TextInput
          onChange={(e) => setPartner(e.target.value)}
          label={opportunity?.partnerAssigned ? "Partner" : "Analista"}
          autoFocus
          fullWidth
          required
          select
        >
          {Users.map((i, index) => (
            <MenuItem
              key={index}
              value={i._id}
            >{`${i.firstName} ${i.lastName}`}</MenuItem>
          ))}
        </TextInput>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={handleUpdate}>
          Reasignar
        </Button>
      </DialogActions>
    </StandardDialog>
  );
};

export default DialogChangePartnerOpportunity;
