import { ERolesCode } from "../../../../../routes/role-catalog";

export enum EFileTypes {
  safe = "safe",
  sisa = "sisa",
  dueDiligence = "dueDiligence",
  depositSlip = "depositSlip",
  reports = "reports",
  depositStartup = "depositStartup",
}
export const VALID_TYPE_FILE_TO_UPLOAD_WITH_USER = [
  EFileTypes.depositSlip,
  EFileTypes.sisa,
];

export const ROLES_TO_HIDE_DATA = [ERolesCode.MEMBER, ERolesCode.STARTUP];

export const ROLES_TO_DELETE = [
  ERolesCode.ADMINISTRATOR,
  ERolesCode.SUPER_ADMIN,
  ERolesCode.PARTNER,
  ERolesCode.ANALYST,
];
export const ROLES_TO_DOWNLOAD = [
  ERolesCode.MEMBER,
  ERolesCode.STARTUP,
  ERolesCode.ADMINISTRATOR,
  ERolesCode.SUPER_ADMIN,
  ERolesCode.PARTNER,
  ERolesCode.ANALYST,
];

export const ITEM_VALUE_STARTUP = {
  name: "Startup",
  value: "Startup",
};
export interface IValue {
  name: string;
  value: string;
}
