import { Box, Link, Typography } from "@mui/material";
import { GridSortItem } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import BaseDataGrid from "../../../components/BaseDataGrid/BaseDataGrid";
import { LinkShowMore } from "../../../components/Dashboard/LinkShowMore";
import { ModalMenu } from "../../../components/DataGrid";
import { EStatusReleasedOpportunity } from "../../../constants";
import { openModal as openModalAction } from "../../../redux/actions/modal";
import {
  changeOpportunitiesReleased,
  changeOpportunityReleased,
} from "../../../redux/actions/opportunitiesReleased";
import { useDispatch, useSelector } from "../../../redux/typedHooks";
import { opportunitiesReleasedChangeIntention } from "../../../services/opportunitiesReleased";
import { displayToast } from "../../../utils/helpers/displayToast";
import { VALID_STATUS_TO_INVEST } from "../../DetailsStartup/components/TabList/Tabs/InvestRound/validation";
import { ERolesCode } from "../../routes/role-catalog";
import { IInvestmentOpportunities } from "../types";

import { InvestmentOpportunityTableCols } from "./InvestmentOpportunityTableCols";
import InvestmentOpportunityTableStyles from "./style";

const InvestmentOpportunityTable: React.FC<any> = ({
  data,
  setReload,
  reload,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  const { opportunities } = useSelector(
    (store) => store.opportunityReleasedReducer,
  );

  function handleGoToFullList() {
    history.push(`/oportunidades-aceptadas`);
  }
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: "remainingDays",
      sort: "desc",
    },
  ]);

  const clearData = data.map((item: IInvestmentOpportunities) => {
    return {
      ...item,
      id: item._id,
      name: item.name,
      stage: item.stage,
      industry: item.industry,
      country: item.country,
      requiredInvestment: item.requiredInvestment,
      valuation: item.valuation,
      status: item.status,
      remainingDays: dayjs(item.expirationDate).diff(dayjs(), "day"),
      usersInterested: item.usersInterested,
      usersNotInterested: item.usersNotInterested,
      usersWantToInvest: item.usersWantToInvest,
    };
  });

  function handleDetails(id: string) {
    history.push(`details-startup/${id}`);
  }

  async function changeInterested(
    isInterested: boolean,
    data: any,
  ): Promise<void> {
    try {
      await opportunitiesReleasedChangeIntention({
        startupId: data.id,
        type: isInterested ? "INTERESTED" : "NOT_INTERESTED",
        user: user._id,
      });
      const copyOpportunity = [...opportunities];

      dispatch(changeOpportunitiesReleased(copyOpportunity));
      displayToast("success", "Se a actualizado correctamente tu oportunidad");
    } catch (error) {
      return;
    } finally {
      setReload(!reload);
    }
  }

  const renderModalMenu = (data: any) => {
    const userInvestingId = data.row.usersWantToInvest.map((i: any) => i.user);

    let detailsOptionsList = [
      {
        name: "Ver detalles",
        action: () => handleDetails(data.id),
      },
    ];

    if (!userInvestingId.includes(String(user._id))) {
      detailsOptionsList.push(
        {
          name: "Me interesa",

          action: () => changeInterested(true, data.row),
        },
        {
          name: "No me interesa",
          action: () => changeInterested(false, data.row),
        },
      );
    }

    const values = data.row;

    const status = values.status;
    const isClosed =
      status === EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT ||
      status === EStatusReleasedOpportunity.INVESTED;

    const validStatusToInvest = VALID_STATUS_TO_INVEST.includes(status);

    if (status === EStatusReleasedOpportunity.OPEN_TO_INVESTMENT) {
      detailsOptionsList.push({
        name: "Quiero invertir",
        action: () => {
          if (user.member.isActiveMemberShip) {
            dispatch(changeOpportunityReleased(data.id));
          } else
            dispatch(
              openModalAction({
                title: "Advertencia.",
                description:
                  "Su membresía se encuentra vencida, por favor haga el pago correspondiente, adjunte su comprobante de pago a su perfil y contacte a Angel Hub para reactivarse a la brevedad.",
              }),
            );
        },
      });
    }

    if (
      values.usersInterested.find((i: any) => i.user === user._id) &&
      validStatusToInvest
    ) {
      detailsOptionsList = detailsOptionsList.filter(
        (i) => i.name !== "Me interesa",
      );
    }

    if (
      values.usersNotInterested.find((i: any) => i === user._id) &&
      validStatusToInvest
    ) {
      detailsOptionsList = detailsOptionsList.filter(
        (i) => i.name !== "No me interesa",
      );
    }

    if (
      values.usersWantToInvest.find((i: any) => i.user === user._id) &&
      validStatusToInvest
    ) {
      detailsOptionsList = detailsOptionsList.filter(
        (i) => i.name !== "Quiero invertir",
      );
    }

    if (isClosed) {
      detailsOptionsList = detailsOptionsList.filter(
        (i) => i.name === "Ver detalles",
      );
    }

    const isMember = user.role.code === ERolesCode.MEMBER;

    const finalOptions = isMember
      ? detailsOptionsList
      : [detailsOptionsList[0]];

    return <ModalMenu menuVert items={finalOptions} />;
  };

  const newColumns = InvestmentOpportunityTableCols(
    {
      headerName: "Acciones",
      field: "x",
      hideSortIcons: true,
      align: "center",
      headerAlign: "center",
      renderCell: (data: any) => renderModalMenu(data),
    },
    user,
  );

  return (
    <Box sx={InvestmentOpportunityTableStyles}>
      <Box sx={{ px: 2, pt: 2, pb: 1.5 }}>
        <Typography variant="h5">Oportunidades de Inversión</Typography>
      </Box>
      <BaseDataGrid
        // loading={loading}
        disableSelectionOnClick
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        columns={newColumns}
        components={{
          Pagination: () => <Box />,
        }}
        hidePagination
        rows={clearData}
      />
      {clearData.length > 5 && (
        <Box sx={{ textAlign: "center", paddingTop: "6px" }}>
          <LinkShowMore
            underline="none"
            sx={{ cursor: "pointer" }}
            onClick={handleGoToFullList}
          >
            Ver más
          </LinkShowMore>
        </Box>
      )}
    </Box>
  );
};

export default InvestmentOpportunityTable;
