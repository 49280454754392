import * as yup from "yup";

import { CAMPO_REQUERIDO } from "../../utils/text/inputs";
import {
  CONTRASEÑA_VALIDA_MENSAJE,
  CONTRASEÑA_NO_COINCIDEN,
} from "../../utils/text/resetForm";
import { strongPasswordValidator } from "../../utils/validations/password";

export const signInStartupSchema = yup.object({
  email: yup
    .string(),
  password: yup
    .string()
    .required(CAMPO_REQUERIDO)
    .matches(strongPasswordValidator, CONTRASEÑA_VALIDA_MENSAJE),
  confirmPassword: yup
    .string()
    .required(CAMPO_REQUERIDO)
    .oneOf([yup.ref("password"), null], CONTRASEÑA_NO_COINCIDEN),
});