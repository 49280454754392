import { GridColDef } from "@mui/x-data-grid";
import { NavLink } from "react-router-dom";

import Ellipsis from "../../utils/components/ellipsis";
import { currencyFormat } from "../../utils/helpers/currencyFormatter";
import { limitCurrency } from "../../utils/helpers/limitCurrency";
import { translateStatusStartups } from "../../utils/text/translates";

export const columnsOpportunity = (column: GridColDef): GridColDef[] => {
  return [
    {
      headerName: "Startup",
      field: "name",
      hideSortIcons: true,
      minWidth: 300,
      flex: 0.7,
      align: "left",
      headerAlign: "left",
      renderCell: (data) => {
        const id = data.row._id;
        return (
          <Ellipsis
            value={
              <NavLink
                style={{ color: "#4A88C2" }}
                to={`/details-startup/${id}`}
              >
                {data.row.name}
              </NavLink>
            }
          />
        );
      },
    },
    {
      headerName: "Etapa",
      field: "stage",
      align: "left",
      headerAlign: "left",
      hideSortIcons: true,
      minWidth: 170,
      maxWidth: 170,
      flex: 0.7,
    },
    {
      headerName: "Industria o rubro",
      field: "industry",
      align: "left",
      headerAlign: "left",
      hideSortIcons: true,
      minWidth: 160,
      maxWidth: 160,
      flex: 0.7,
    },
    {
      headerName: "País",
      field: "country",
      hideSortIcons: true,
      minWidth: 160,
      maxWidth: 160,
      flex: 0.7,
      align: "left",
      headerAlign: "left",
    },
    {
      headerName: "Estatus",
      field: "status",
      hideSortIcons: true,
      minWidth: 170,
      maxWidth: 170,
      flex: 0.7,
      align: "left",
      headerAlign: "left",
      valueGetter: (data) => translateStatusStartups(data.row.status),
    },
    {
      headerName: "Inversión Req. USD",
      field: "requiredInvestment",
      hideSortIcons: true,
      minWidth: 175,
      maxWidth: 175,
      flex: 0.7,
      align: "right",
      headerAlign: "right",
      renderCell: (data) => (
        <Ellipsis
          value={`${limitCurrency(
            currencyFormat(data.row.requiredInvestment) as string,
          )}`}
        />
      ),
      valueGetter: (data) => {
        return parseFloat(data.row.requiredInvestment);
      },
    },
    {
      headerName: "Monto invertido USD",
      field: "usersWantToInvest",
      hideSortIcons: true,
      minWidth: 175,
      maxWidth: 175,
      flex: 0.7,
      align: "right",
      headerAlign: "right",
      renderCell: (data) => {
        const values = data.row.usersWantToInvest;
        const interestAmount = values.reduce(
          (acc: number, m: any) => (acc += m.money),
          0,
        );
        return (
          <Ellipsis
            value={limitCurrency(currencyFormat(interestAmount || 0) as string)}
          />
        );
      },
      valueGetter: (data) => {
        const values = data.row.usersWantToInvest;
        const interestAmount = values.reduce(
          (acc: number, m: any) => (acc += m.money),
          0,
        );
        return interestAmount;
      },
    },
    {
      headerName: "Valuación USD",
      field: "valuation",
      hideSortIcons: true,
      minWidth: 175,
      maxWidth: 175,
      align: "right",
      headerAlign: "right",
      flex: 0.7,
      valueGetter: (data) => {
        return parseFloat(data.row.valuation);
      },
      renderCell: (data) => (
        <Ellipsis
          value={`${limitCurrency(
            currencyFormat(data.row.valuation) as string,
          )}`}
        />
      ),
    },

    {
      headerName: "Rendimiento",
      field: "performance",
      hideSortIcons: true,
      minWidth: 120,
      maxWidth: 120,
      flex: 0.7,
      align: "right",
      headerAlign: "right",
      renderCell: (data) => {
        const performance = data.row.valuation / data.row.postMoney;
        const performanceFormat = isFinite(performance)
          ? performance.toFixed(1) + "x"
          : "0.0x";
        return performanceFormat;
      },
      valueGetter: (data) => {
        const performance =
          parseFloat(data.row.valuation) / parseFloat(data.row.postMoney);
        const performanceValue = isFinite(performance)
          ? performance.toFixed(1).toString()
          : 0;

        return parseFloat(performanceValue.toString());
      },
    },
    column,
  ];
};
