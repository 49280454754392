import { ArrowBack } from "@mui/icons-material";
import { Box, Button, FormHelperText, Grid, MenuItem } from "@mui/material";
import React, { useRef } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import PhoneInput, { CountryData } from "react-phone-input-2";

import Recaptcha from "../../../components/Captcha/Captcha";
import { TextInput } from "../../../components/Inputs";
import InputFile from "../../../components/Inputs/InputFile/InputFile";
import countries from "../../../utils/countries.json";
import { PHONE_MESSAGE } from "../../../utils/text/resetForm";
import {
  COMO_TE_ENTERASTE_OPTIONS,
  COMPANY_TYPE_OPTIONS,
  INDUSTRY_OPTIONS,
  STAGE_OPTIONS,
  WOMAN_OPTIONS,
} from "../constants";
import { IForm } from "../screens/YesScreen";

interface Props {
  hook: UseFormReturn<IForm>;
  handleSubmit: () => void;
  handleCancel: () => void;
  handleSuccessCaptcha: () => void;
  handleFailCaptcha: () => void;
}

const RegisterStartupForm: React.FC<Props> = ({
  hook,
  handleCancel,
  handleSubmit,
  handleSuccessCaptcha,
  handleFailCaptcha,
}) => {
  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      <Grid item xs={12} md={6}>
        <Controller
          control={hook.control}
          name="startupName"
          render={({ field, fieldState }) => (
            <TextInput
              label="Nombre de la startup"
              required
              {...field}
              fullWidth
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error?.message) && fieldState.error?.message
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={hook.control}
          name="oneliner"
          render={({ field, fieldState }) => (
            <TextInput
              label="One liner"
              required
              {...field}
              fullWidth
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error?.message
                  ? fieldState.error?.message
                  : `Caracteres ${field.value.length}/200`
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={hook.control}
          name="industry"
          render={({ field, fieldState }) => (
            <TextInput
              label="Industria"
              required
              {...field}
              fullWidth
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error?.message) && fieldState.error?.message
              }
              select
            >
              {INDUSTRY_OPTIONS.map((i, index) => (
                <MenuItem value={i} key={index}>
                  {i}
                </MenuItem>
              ))}
            </TextInput>
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={hook.control}
          name="stage"
          render={({ field, fieldState }) => (
            <TextInput
              label="Etapa"
              required
              {...field}
              fullWidth
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error?.message) && fieldState.error?.message
              }
              select
            >
              {STAGE_OPTIONS.map((i, index) => (
                <MenuItem value={i} key={index}>
                  {i}
                </MenuItem>
              ))}
            </TextInput>
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={hook.control}
          name="companyType"
          render={({ field, fieldState }) => (
            <TextInput
              label="Tipo de empresa"
              required
              {...field}
              fullWidth
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error?.message) && fieldState.error?.message
              }
              select
            >
              {COMPANY_TYPE_OPTIONS.map((i, index) => (
                <MenuItem value={i} key={index}>
                  {i}
                </MenuItem>
              ))}
            </TextInput>
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={hook.control}
          name="requiredInvestment"
          render={({ field, fieldState }) => (
            <TextInput
              label="Inversión requerida (USD)"
              type={"numeric"}
              required
              {...field}
              fullWidth
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error?.message) && fieldState.error?.message
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={hook.control}
          name="valuation"
          render={({ field, fieldState }) => (
            <TextInput
              label="Valuación PostMoney de startup (USD)"
              type={"numeric"}
              required
              {...field}
              fullWidth
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error?.message) && fieldState.error?.message
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={hook.control}
          name="howFindAngelHub"
          render={({ field, fieldState }) => (
            <TextInput
              label="¿Cómo escuchaste de AngelHub?"
              required
              {...field}
              fullWidth
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error?.message) && fieldState.error?.message
              }
              select
            >
              {COMO_TE_ENTERASTE_OPTIONS.map((i, index) => (
                <MenuItem value={i} key={index}>
                  {i}
                </MenuItem>
              ))}
            </TextInput>
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={hook.control}
          name="country"
          render={({ field, fieldState }) => (
            <TextInput
              label="País"
              required
              {...field}
              fullWidth
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error?.message) && fieldState.error?.message
              }
              select
            >
              {countries.map((i, index) => (
                <MenuItem value={i.name_es} key={index}>
                  {i.name_es}
                </MenuItem>
              ))}
            </TextInput>
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={hook.control}
          name="womanFounding"
          render={({ field, fieldState }) => (
            <TextInput
              label="Mujer en el founding team"
              required
              {...field}
              fullWidth
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error?.message) && fieldState.error?.message
              }
              select
            >
              {WOMAN_OPTIONS.map((i, index) => (
                <MenuItem value={i.value} key={index}>
                  {i.name}
                </MenuItem>
              ))}
            </TextInput>
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={hook.control}
          name="website"
          render={({ field, fieldState }) => (
            <TextInput
              label="Sitio web"
              required
              {...field}
              fullWidth
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error?.message) && fieldState.error?.message
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={hook.control}
          name="file"
          render={({ field, fieldState }) => (
            <InputFile
              ref={field.ref}
              value={field.value}
              label="Adjuntar Deck*"
              onChange={field.onChange}
              errorMessage={fieldState.error?.message}
              accept={[".pdf"]}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ marginTop: "18px" }}>
        <Controller
          control={hook.control}
          name="ownerName"
          render={({ field, fieldState }) => (
            <TextInput
              label="Nombre"
              required
              {...field}
              fullWidth
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error?.message) && fieldState.error?.message
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ marginTop: "18px" }}>
        <Controller
          control={hook.control}
          name="ownerLastName"
          render={({ field, fieldState }) => (
            <TextInput
              label="Apellidos"
              required
              {...field}
              fullWidth
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error?.message) && fieldState.error?.message
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={hook.control}
          name="email"
          render={({ field, fieldState }) => (
            <TextInput
              label="Email de contacto"
              required
              {...field}
              fullWidth
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error?.message) && fieldState.error?.message
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          control={hook.control}
          name="phoneNumber"
          render={({ field, fieldState, formState }) => (
            <>
              <PhoneInput
                country="mx"
                value={field.value}
                onChange={(phone: string, data: CountryData) => {
                  const number = phone.replace(data.dialCode, "");
                  const reg = `^[0-9]{10}$`;
                  const exp = new RegExp(reg);
                  const isValid = exp.test(number);
                  if (!isValid) {
                    hook.setError("phoneNumber", { message: PHONE_MESSAGE });
                  } else {
                    hook.setValue("phoneCountryCode", data.dialCode);
                    field.onChange(phone);
                  }
                }}
                buttonStyle={
                  fieldState.error && { border: "2px solid #f44336" }
                }
                inputStyle={
                  fieldState.error
                    ? {
                        border: "2px solid #f44336",
                        height: "48px",
                        width: "100%",
                      }
                    : { height: "48px", width: "100%" }
                }
              />
              {Boolean(formState.errors?.phoneNumber) && (
                <FormHelperText
                  style={{ margin: "3px 14px 0px 14px" }}
                  error={Boolean(formState.errors?.phoneNumber)}
                >
                  {formState.errors?.phoneNumber?.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>

      <Grid item xs={12} md={6} />
      <Grid item xs={12} md={5}>
        <Recaptcha
          captchaSucceededHandler={handleSuccessCaptcha}
          captchaFailedHandler={handleFailCaptcha}
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            justifyContent: "space-between",
            display: "flex",
            paddingBottom: "50px",
          }}
        >
          <Button
            onClick={() => handleCancel()}
            style={{
              fontFamily: "Product Sans Black",
              fontStyle: "normal",
              fontWeight: 900,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#4A88C2",
            }}
            variant="text"
            startIcon={<ArrowBack />}
            color="info"
            className="stepper-back-button"
          >
            REGRESAR
          </Button>

          <Button variant="contained" onClick={() => handleSubmit()}>
            ENVIAR RESPUESTAS
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RegisterStartupForm;
