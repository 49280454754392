/* eslint-disable react-hooks/exhaustive-deps */
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import BaseDataGrid from "../../components/BaseDataGrid/BaseDataGrid";
import { ModalMenu } from "../../components/DataGrid";
import { DEFAULT_ROWS_PAGE_SIZE } from "../../components/DataGrid/constants";
import UserToolbar from "../../components/Toolbars/UserToolbar";
import {
  EUsersTabs,
  PeriodType,
} from "../../components/Toolbars/UserToolbar/const";
import { IUser } from "../../models/User";
import { changeArrayUsers, fetchUsers } from "../../redux/actions/admin";
import { openModal } from "../../redux/actions/modal";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import { changeValueActivateUser } from "../../services/users";
import { getPeriodValues } from "../../utils/helpers/getPeriodValues";
import { ERolesCode } from "../routes/role-catalog";

import { columnsAdmin } from "./columns";

const UsersList: React.FC = () => {
  const { users, loading, reload } = useSelector((store) => store.adminReducer);

  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [Search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_ROWS_PAGE_SIZE);
  const [Role, setRoles] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean | null>(null);

  const Tab = queryParams.get("tab") || EUsersTabs.ALL; //
  const Period: PeriodType = (queryParams.get("period") as PeriodType) || ""; //

  useEffect(() => {
    dispatch(fetchUsers());
  }, [reload]);

  useEffect(() => {
    setPage(0);
    setPageSize(DEFAULT_ROWS_PAGE_SIZE);
  }, [Role, isActive]);

  const renderModalMenu = (data: any) => {
    const activeText = data.isActive ? "Desactivar" : "Activar";
    const code = data.role?.code;

    //Ids
    const userId = data._id;
    const startupId = data?.startup?._id;
    const memberId = data?.member?._id;

    const url = {
      [ERolesCode.ADMINISTRATOR]: `/update-admin/${userId}`,
      [ERolesCode.SUPER_ADMIN]: `/update-admin/${userId}`,
      [ERolesCode.ANALYST]: `/update-analyst/${userId}`,
      [ERolesCode.MEMBER]: `/update-member/${memberId}`,
      [ERolesCode.PARTNER]: `/update-partner/${userId}`,
      [ERolesCode.STARTUP]: `/update-startup/${startupId}`,
    } as any;

    const detailsOptionsList = [
      {
        name: "Ver",
        action: () => history.push(url[code]),
      },
      {
        name: activeText,
        action: () => {
          dispatch(
            openModal({
              description: `¿Estás seguro de que quieres ${activeText.toLowerCase()} la cuenta?`,
              title: "Advertencia",
              action: async () => {
                handleChangeStatusUser(data);
              },
            }),
          );
        },
      },
    ];

    return <ModalMenu menuVert items={detailsOptionsList} />;
  };

  const customColumn: GridColDef = {
    headerName: "Acciones",
    field: "name",
    align: "center",
    hideSortIcons: true,
    renderCell: (data) => renderModalMenu(data.row),
  };

  async function handleChangeStatusUser(data: any) {
    await changeValueActivateUser(!data.isActive, data._id);
    const copyUsers = [...users];
    const indexUser = copyUsers.findIndex((i) => i._id === data._id);
    copyUsers[indexUser].isActive = !data.isActive;
    dispatch(changeArrayUsers(copyUsers));
  }

  const handleFilter = (data: any[]) => {
    let values = data.filter((user: IUser) => {
      return (
        String(user.firstName)?.toLowerCase().includes(Search.toLowerCase()) ||
        String(user.lastName)?.toLowerCase().includes(Search.toLowerCase()) ||
        String(user.email)?.toLowerCase().includes(Search.toLowerCase()) ||
        !Search
      );
    });

    if (Role) {
      values = values.filter((i) => i.role.code === Role);
    }
    if (isActive !== null) {
      values = values.filter((i) => i.isActive === isActive);
    }

    if (Tab !== EUsersTabs.ALL) {
      values = values.filter((i) => i.role.code === Tab);
    }

    if (Period) {
      values = values.filter((i) => {
        const { gt, lt } = getPeriodValues(Period);
        return lt.isBefore(i["createdAt"]) && gt.isAfter(i["createdAt"]);
      });
    }

    return values;
  };

  return (
    <BaseDataGrid
      page={page}
      pageSize={pageSize}
      onPageChange={(value) => setPage(value)}
      onPageSizeChange={(value) => setPageSize(value)}
      columns={columnsAdmin(customColumn)}
      rows={handleFilter(users)}
      loading={loading}
      getRowId={(row) => row._id}
      checkboxSelection
      components={{
        Toolbar: UserToolbar,
      }}
      componentsProps={{
        toolbar: {
          Tab,
          Period,
          onSearchChange: (value: string) => {
            setSearch(value);
          },
          onIsActiveChange: (value: string) => {
            if (value === null) {
              setIsActive(null);
            } else {
              setIsActive(value === "Activo");
            }
          },
          onTabChange: (value: string) => {
            setRoles(value);
          },
          onSearchClear: () => {
            setSearch("");
          },
          buttonText: "Crear Usuario",
          hideButton: false,
          hideIcons: true,
        },
      }}
    />
  );
};

export default UsersList;
