import { ERolesCode } from "../../../../../routes/role-catalog";

export const VALID_ROLES_TO_INVEST = [ERolesCode.MEMBER];

export enum EMemberships {
  AMBASSADOR = "AMBASSADOR",
  ALLIES = "ALLIES",
  LEADANGEL = "LEADANGEL",
  CLASSICAL = "CLASSICAL",
  TRIAL = "TRIAL",
}

export const VALID_ROLES_TO_EDIT_MEMBERSHIP = [
  ERolesCode.ADMINISTRATOR,
  ERolesCode.ANALYST,
  ERolesCode.PARTNER,
  ERolesCode.SUPER_ADMIN,
];
