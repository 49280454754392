import { Stack, Typography } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";

import { TextInput } from "../../../../../../components/Inputs";
import { IMainCardForm } from "../../types";

interface IEditBoardItemProps {
  title: string;
  hook: UseFormReturn<IMainCardForm>;
  index: number;
}

const EditBoardItem: React.FC<IEditBoardItemProps> = ({
  title,
  hook,
  index,
}) => {
  return (
    <Stack direction="column" spacing={2}>
      <Typography sx={{ fontWeight: 400, fontSize: "16px", color: "#1A1A1A" }}>
        {title}
      </Typography>
      <Controller
        control={hook.control}
        name={`founders.${index}.role`}
        render={({ field, fieldState }) => (
          <TextInput
            label="Puesto"
            required
            {...field}
            fullWidth
            error={Boolean(fieldState.error)}
            helperText={
              Boolean(fieldState.error?.message) && fieldState.error?.message
            }
          />
        )}
      />
      <Controller
        control={hook.control}
        name={`founders.${index}.name`}
        render={({ field, fieldState }) => (
          <TextInput
            label="Nombre del Founder"
            required
            {...field}
            fullWidth
            error={Boolean(fieldState.error)}
            helperText={
              Boolean(fieldState.error?.message) && fieldState.error?.message
            }
          />
        )}
      />

      <Controller
        control={hook.control}
        name={`founders.${index}.linkedIn`}
        render={({ field, fieldState }) => (
          <TextInput
            label="Perfil de LinkedIn"
            {...field}
            fullWidth
            error={Boolean(fieldState.error)}
            helperText={
              Boolean(fieldState.error?.message) && fieldState.error?.message
            }
          />
        )}
      />
    </Stack>
  );
};

export default EditBoardItem;
