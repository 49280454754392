import { Dispatch } from "redux";

import { KeysValueForm, Steps } from "../reducers/member";

type MemberReducerKeys = "email" | "phoneNumber" | "_id" | "name";

export const SET_VALUES_MEMBER_APPLICATIONS = "SET_VALUES_MEMBER_APPLICATIONS";
export const setValueMemberApplication =
  (payload: { key: MemberReducerKeys; value: any }) => (dispatch: Dispatch) => {
    return dispatch({
      type: SET_VALUES_MEMBER_APPLICATIONS,
      payload,
    });
  };

export const CLEAR_MEMBER_APPLICATION_VALUES =
  "CLEAR_MEMBER_APPLICATION_VALUES";
export const clearApplicationValues = () => {
  return {
    type: CLEAR_MEMBER_APPLICATION_VALUES,
  };
};
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";
export const setActiveStep = (value: Steps) => {
  return {
    type: SET_ACTIVE_STEP,
    payload: value,
  };
};

export const SET_VALUE_FORM_APPLICATION = "SET_VALUE_FORM_APPLICATION";
export const setValueFormApplication = (key: KeysValueForm, value: any) => {
  return {
    type: SET_VALUE_FORM_APPLICATION,
    payload: { key, value },
  };
};

export const SET_VALUE_CALENDAR = "SET_VALUE_CALENDAR";
export const setValueCalendar = (value: string) => {
  return {
    type: SET_VALUE_CALENDAR,
    payload: value,
  };
};
