import { TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

import { EUsersTabs } from "./const";

const Tabs: React.FC = () => {
  const history = useHistory();
  const stylesTabs = {
    color: "#0000005D",
    minWidth: "150px",
    minHeight: "48px",
  } as const;

  const handleChangeTab = (_: any, newTab: string) => {
    history.replace(`?tab=${newTab}`);
  };

  return (
    <TabList
      sx={{ gap: "8px" }}
      scrollButtons
      variant="scrollable"
      allowScrollButtonsMobile
      onChange={handleChangeTab}
    >
      <Tab sx={stylesTabs} label="TODAS" value={EUsersTabs.ALL} />
      <Tab sx={stylesTabs} label="MIEMBROS" value={EUsersTabs.MEMBERS} />
      <Tab sx={stylesTabs} label="STARTUPS" value={EUsersTabs.STARTUPS} />
      <Tab sx={stylesTabs} label="ANALISTAS" value={EUsersTabs.ANALYSTS} />
      <Tab sx={stylesTabs} label="PARTNERS" value={EUsersTabs.PARTNERS} />
      <Tab sx={stylesTabs} label="ADMINISTRADORES" value={EUsersTabs.ADMINS} />
    </TabList>
  );
};

export default Tabs;
