import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";

import Ellipsis from "../../utils/components/ellipsis";
import { currencyFormat } from "../../utils/helpers/currencyFormatter";
import { limitCurrency } from "../../utils/helpers/limitCurrency";
import { translateStatusStartups } from "../../utils/text/translates";

export const columnsOpportunity = (): GridColDef[] => [
  {
    headerName: "Startup",
    field: "name",
    hideSortIcons: true,
    minWidth: 300,
    flex: 1,
    align: "left",
    headerAlign: "left",
    renderCell: (data) => {
      const id = data.row._id;
      return (
        <Ellipsis
          value={
            <NavLink style={{ color: "#4A88C2" }} to={`/details-startup/${id}`}>
              {data.row.name}
            </NavLink>
          }
        />
      );
    },
  },
  {
    headerName: "Etapa",
    field: "stage",
    hideSortIcons: true,
    minWidth: 170,
    maxWidth: 170,
    flex: 0.7,
    align: "left",
    headerAlign: "left",
  },
  {
    headerName: "Industria o rubro",
    field: "industry",
    hideSortIcons: true,
    minWidth: 165,
    maxWidth: 165,
    flex: 0.7,
    align: "left",
    headerAlign: "left",
  },
  {
    headerName: "País",
    field: "country",
    hideSortIcons: true,
    minWidth: 145,
    maxWidth: 145,
    flex: 0.7,
    align: "left",
    headerAlign: "left",
  },
  {
    headerName: "Estatus",
    field: "status",
    hideSortIcons: true,
    minWidth: 195,
    maxWidth: 195,
    flex: 0.7,
    align: "left",
    valueGetter: (data) => translateStatusStartups(data.row.status),
  },
  {
    headerName: "Fecha de estatus",
    field: "dateStatus",
    hideSortIcons: true,
    minWidth: 165,
    maxWidth: 165,
    flex: 0.7,
    align: "left",
    valueGetter: (data) => {
      const date = data.row.dateStatus;
      return dayjs(date).format("YYYY-MM-DD");
    },
  },
  {
    headerName: "Inversión Req. USD",
    field: "requiredInvestment",
    hideSortIcons: true,
    minWidth: 175,
    maxWidth: 175,
    flex: 0.7,
    align: "right",
    headerAlign: "right",
    valueGetter: (data) => parseFloat(data.row.requiredInvestment),
    renderCell: (data) => {
      return (
        <Ellipsis
          value={limitCurrency(
            currencyFormat(data.row.requiredInvestment) as string,
          )}
        />
      );
    },
  },
  {
    headerName: "Monto invertido USD",
    field: "usersWantToInvest",
    hideSortIcons: true,
    minWidth: 175,
    maxWidth: 175,
    flex: 0.7,
    align: "right",
    headerAlign: "right",
    valueGetter: (data) => {
      const values = data.row.usersWantToInvest;
      const interestAmount = values.reduce(
        (acc: number, m: any) => (acc += m.money),
        0,
      );
      return parseInt(interestAmount);
    },
    renderCell: (data) => {
      const values = data.row.usersWantToInvest;
      const interestAmount = values.reduce(
        (acc: number, m: any) => (acc += m.money),
        0,
      );

      return limitCurrency(currencyFormat(interestAmount) as string);
    },
  },
  {
    headerName: "Valuación USD",
    field: "valuation",
    hideSortIcons: true,
    minWidth: 175,
    maxWidth: 175,
    align: "right",
    headerAlign: "right",
    flex: 0.7,
    valueGetter: (data) => parseFloat(data.row.valuation),
    renderCell: (data) => {
      return limitCurrency(currencyFormat(data.row.valuation) as string);
    },
  },

  {
    headerName: "Rendimiento",
    field: "performance",
    hideSortIcons: true,
    minWidth: 120,
    maxWidth: 120,
    flex: 0.4,
    align: "right",
    headerAlign: "right",
    renderCell: (data) => {
      const performance = data.row.valuation / data.row.postMoney;
      const performanceFormat = isFinite(performance)
        ? performance.toFixed(1) + "x"
        : "0.0x";
      return performanceFormat;
    },
    valueGetter: (data) => {
      const performance =
        parseFloat(data.row.valuation) / parseFloat(data.row.postMoney);
      const performanceValue = isFinite(performance)
        ? performance.toFixed(1).toString()
        : 0;
      return parseFloat(limitCurrency(performanceValue.toString()));
    },
  },
];
