import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Card, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import CardHeader from "../../../components/Dashboard/CardHeader";
import { PERIODS } from "../const";
import useFilter from "../useFilter";

import { translateValuePeriod } from "./const";
import IndicatorCardStyles from "./style";

export interface IValueIndicatorCard {
  today: number;
  month: number;
  trimestre: number;
  annual: number;
}

interface IIndicatorCardProps {
  text: string;
  value: IValueIndicatorCard | number;
  hidePeriod?: boolean;
  notConcatQuery?: boolean;
  url?: string;
}

const IndicatorCard: React.FC<IIndicatorCardProps> = ({
  url,
  text,
  value,
  hidePeriod,
  notConcatQuery,
}) => {
  const history = useHistory();
  const [FinalValue, setFinalValue] = useState(0);
  const [AnchoEl, setAnchorEl] = useState(null);

  const {
    options,
    value: filterValue,
    handleOpenMenu,
  } = useFilter(AnchoEl, setAnchorEl);

  const period = PERIODS[filterValue];

  useEffect(() => {
    if (typeof value === "number") {
      setFinalValue(value);
    } else {
      const formattedValues = value[filterValue];
      setFinalValue(formattedValues);
    }
  }, [filterValue, value]);

  function handleRedirect() {
    let newUrl = url;

    if (!notConcatQuery) {
      newUrl += `&period=${translateValuePeriod(filterValue)}`;
    }
    if (newUrl) {
      history.push(`${newUrl}`);
    }
  }

  return (
    <Box sx={IndicatorCardStyles}>
      {options}
      <Card className="indicator-card">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item sx={{ width: "calc(100% - 60px)" }}>
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <CardHeader variant="h5">{text}</CardHeader>
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={handleRedirect}
                variant="h3"
              >
                {FinalValue}
              </Typography>
            </Stack>
          </Grid>
          <Grid item sx={{ width: "60px" }}>
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="flex-end"
              spacing={2}
              sx={{ height: "105px" }}
            >
              {typeof value !== "number" && (
                <IconButton
                  onClick={handleOpenMenu}
                  className="action-more-icon"
                >
                  <MoreVertIcon />
                </IconButton>
              )}
              {!hidePeriod && period && (
                <Typography variant="subtitle2" sx={{ textAlign: "right" }}>
                  {period}
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default IndicatorCard;
