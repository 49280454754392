import { TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

export enum EOptions {
  ALL = "ALL",
  OPEN = "OPEN",
  EXPIRED = "EXPIRED",
  INTERESTED = "INTERESTED",
  NOT_INTERESTED = "NOT_INTERESTED",
}

const Tabs: React.FC = () => {
  const history = useHistory();
  const stylesTabs = {
    color: "#0000005D",
    minWidth: "150px",
    minHeight: "48px",
  } as const;

  const handleChangeTab = (_: any, newTab: string) => {
    history.replace(`?tab=${newTab}`);
  };

  return (
    <TabList
      sx={{ gap: "8px" }}
      scrollButtons
      variant="scrollable"
      allowScrollButtonsMobile
      onChange={handleChangeTab}
    >
      <Tab sx={stylesTabs} label="TODAS" value={EOptions.ALL} />
      <Tab sx={stylesTabs} label="ABIERTAS" value={EOptions.OPEN} />
      <Tab sx={stylesTabs} label="EXPIRADAS" value={EOptions.EXPIRED} />
      <Tab sx={stylesTabs} label="ME INTERESAN" value={EOptions.INTERESTED} />
      <Tab
        sx={stylesTabs}
        label="NO ME INTERESAN"
        value={EOptions.NOT_INTERESTED}
      />
    </TabList>
  );
};

export default Tabs;
