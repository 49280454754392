import * as yup from "yup";

import { CAMPO_REQUERIDO } from "../../../../../../../utils/text/inputs";

export const updateFinancialInfoSchema = yup.object({
  valuation: yup.string().required(CAMPO_REQUERIDO),
  requiredInvestment: yup.string().required(CAMPO_REQUERIDO),
  monthlyBurnRate: yup.string(),
  monthlyRevenue: yup.string(),
  postMoney: yup.string(),
  runway: yup.string(),
});
