import { GridSortItem } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import BaseDataGrid from "../../components/BaseDataGrid/BaseDataGrid";
import PageTitle from "../../components/DashboardLayout/PageTitle";
import { ModalMenu } from "../../components/DataGrid";
import { DEFAULT_ROWS_PAGE_SIZE } from "../../components/DataGrid/constants";
import MainLayout from "../../components/MainLayout";
import { EStatusReleasedOpportunity } from "../../constants";
import { getStartupsByStatus } from "../../services/startup";

import { columnsInvested } from "./columns";

const OpportunitiesInvested = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  const [Opportunities, setOpportunities] = useState<Array<any>>([]);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_ROWS_PAGE_SIZE);

  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: "applicationDate",
      sort: "desc",
    },
  ]);

  useEffect(() => {
    getStartupsByStatus(EStatusReleasedOpportunity.INVESTED)
      .then(({ data }) => {
        setOpportunities(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const newColumns = columnsInvested({
    headerName: "Acciones",
    field: "x",
    hideSortIcons: true,
    align: "center",
    headerAlign: "center",
    renderCell: (data: any) => {
      const id = data.row._id;

      const items = [
        {
          action: () => {
            history.push(`/details-startup/${id}`);
          },
          name: "Ver Detalles",
        },
      ];

      return <ModalMenu menuVert items={items} />;
    },
  });

  return (
    <>
      <PageTitle title="Oportunidades Invertidas" />
      <MainLayout sx={{ flexDirection: "column", justifyContent: "start" }}>
        <BaseDataGrid
          page={page}
          loading={loading}
          disableSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          pageSize={pageSize}
          columns={newColumns}
          getRowId={(row) => row._id}
          rows={Opportunities}
          onPageChange={(value) => setPage(value)}
          onPageSizeChange={(value) => setPageSize(value)}
        />
      </MainLayout>
    </>
  );
};

export default OpportunitiesInvested;
