/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import { clearStateApplications } from "../../redux/actions/applications";
import { useDispatch } from "../../redux/typedHooks";

import ApplicationList from "./ApplicationList";

const Applications: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearStateApplications());
    };
  }, []);

  return (
    <>
      <PageTitle title="Lista de Aplicaciones de Miembro" />
      <MainLayout sx={{ flexDirection: "column", justifyContent: "start" }}>
        <ApplicationList />
      </MainLayout>
    </>
  );
};

export default Applications;
