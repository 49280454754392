import { Box } from "@mui/system";
import React from "react";
import { useParams } from "react-router-dom";

import ResourceLibraryDetail from "../../components/ResourceLibraryDetail/ResourceLibraryDetail";

const PreviewResourceLibrary = () => {
  const { id } = useParams() as { id: string };

  return (
    <Box
      minWidth={"100vw"}
      minHeight="100vh"
      padding={"40px 20px"}
      sx={{ background: "#F8F8F8" }}
    >
      <ResourceLibraryDetail id={id} />
    </Box>
  );
};

export default PreviewResourceLibrary;
