import { Typography, ToggleButtonGroup, Stack, Box } from "@mui/material";
import { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { TextInput } from "../../../../../../../components/Inputs";
import { Score } from "../Scores/Scores";
import { FormType } from "../schemas";
import { styles } from "../styles";

interface IScoreInfo {
  title: string;
  score: number;
  comment: string;
  editable: boolean;
  indicator: number;
  hook: UseFormReturn<FormType>;
  refresh: boolean;
}

export const ScoreCard: React.FC<IScoreInfo> = ({
  title,
  score,
  comment,
  editable,
  hook,
  indicator,
  refresh,
}) => {
  useEffect(() => {
    hook.setValue(`scoreEvaluation.${indicator}.comment`, comment);
  }, [comment, hook, indicator, refresh]);

  return (
    <Stack spacing={2}>
      <Typography sx={styles.scoreTitle}>{title}</Typography>
      <ToggleButtonGroup size="small" aria-label="Small sizes">
        <Score
          score={score}
          indicator={indicator}
          hook={hook}
          editable={editable}
          refresh={refresh}
        />
      </ToggleButtonGroup>
      {!editable ? (
        <Typography sx={styles.textCommets}>
          {comment ? comment : "No tiene comentario."}
        </Typography>
      ) : (
        <Controller
          control={hook.control}
          name={`scoreEvaluation.${indicator}.comment`}
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              required
              multiline
              rows={3}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error?.message
                  ? fieldState.error?.message
                  : "Caracteres " + field.value.length + "/250"
              }
              fullWidth
            />
          )}
        />
      )}
    </Stack>
  );
};
