import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Dayjs } from "dayjs";

import { getToken } from "../config";
import { EStatusOpportunity } from "../constants";

export interface IUpdateStatusOpportunity {
  status: EStatusOpportunity;
  comment?: string;
  analystId?: string;
  partnerId?: string;
  loggedId?: string;
  date: Date;
  expirationDate?: Dayjs;
}

const getOpportunityApplicationById = (
  id: string,
): Promise<AxiosResponse<any>> => {
  const token = getToken();

  const config: AxiosRequestConfig = {
    url: "/opportunities/" + id,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

const updateOpportunityApplicationById = (
  id: string,
  data: any,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/opportunities/" + id,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

const updateAssignedAnalyst = (
  id: string,
  analystId: string,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const date = new Date();
  const config: AxiosRequestConfig = {
    url: "/opportunities/status/" + id,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { analystId, status: EStatusOpportunity.ASSIGNED_ANALYST, date },
  };

  return axios(config);
};

const updateStatusOpportunity = (
  id: string,
  data: IUpdateStatusOpportunity,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const date = new Date();
  const config: AxiosRequestConfig = {
    url: "/opportunities/status/" + id,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { ...data, date },
  };

  return axios(config);
};

const rejectOportunity = (
  id: string,
  comment: string,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/opportunities/status/" + id,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { comment, status: EStatusOpportunity.REJECTED, date: new Date() },
  };

  return axios(config);
};

const acceptOportunity = (
  id: string,
  partnerId: string,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/opportunities/status/" + id,
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      status: EStatusOpportunity.ASSIGNED_PARTNER,
      id,
      partnerId,
      date: new Date(),
    },
  };
  return axios(config);
};

export {
  getOpportunityApplicationById,
  updateOpportunityApplicationById,
  updateStatusOpportunity,
  updateAssignedAnalyst,
  rejectOportunity,
  acceptOportunity,
};
