import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

import stockImage from "../../../assets/images/final-screen-image.png";
import logo from "../../../assets/images/logo-color_negro.png";
import { endScreen } from "../../MemberFaceliftOnboarding/styles";
import { Screens } from "../StartupWizards";

interface Props {
  step: Screens;
}

const EndScren: React.FC<Props> = ({ step }) => {
  const breakpoint = useMediaQuery("(max-width: 960px)");
  const handleRedirectHome = () => {
    window.open("https://www.angelhub.mx/");
  };
  const handleRedirect = () => {
    window.open("https://www.angelhub.mx/preguntas-frecuentes");
  };

  return (
    <Grid sx={endScreen}>
      <Grid item xs={9}>
        <Box
          sx={{
            textAlign: "left",
            padding: breakpoint ? "15px 5px 0 30px" : "40px 20px 0 90px",
          }}
        >
          <img alt="angelhub-welcome" src={logo} className={"angelhubImg"} />
        </Box>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: breakpoint ? "15px 5px 0 30px" : "40px 20px 0 90px",
        }}
      >
        <Typography
          sx={{
            paddingTop: "10px",
            maxWidth: "400px",
            fontSize: "24px",
            color: "#495463",
          }}
        >
          ¡Es todo por ahora!
        </Typography>
        <Typography
          className="text"
          sx={{
            paddingTop: "80px",
            maxWidth: "600px",
            fontSize: "24px",
            color: "#495463",
          }}
        >
          Muchas gracias, recibimos tus respuestas y pronto nuestros analistas
          se pondrán en contacto para dar seguimiento a tu solicitud, recuerda
          estar pendiente de tu correo electrónico para recibir actualizaciones.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          flexWrap: "wrap",
          padding: breakpoint ? "15px 5px 0 30px" : "40px 20px 0 90px",
          order: 1,
        }}
      >
        <Box
          sx={{
            padding: "50px 0px",
            display: "flex",

            alignItems: "center",
            gap: "20px",
          }}
        >
          <Button
            onClick={handleRedirectHome}
            variant="contained"
            sx={{
              height: breakpoint ? "30px" : "40px",
              fontSize: breakpoint ? "10px" : "",
              padding: "10px",
            }}
          >
            Ir al Home
          </Button>
          <Button
            onClick={handleRedirect}
            variant="outlined"
            sx={{
              height: breakpoint ? "30px" : "40px",
              fontSize: breakpoint ? "10px" : "",
              padding: "10px",
            }}
          >
            Más información
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            marginTop: "20px",
          }}
        >
          <img
            src={stockImage}
            alt="final-screen"
            width={breakpoint ? "300px" : "800px"}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default EndScren;
