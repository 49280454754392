import { Card, CardContent } from "@mui/material";
import { RouteChildrenProps } from "react-router-dom";

import LoginLayout from "../../components/LoginLayout";
import LoginWithCodeForm from "../../components/LoginWithCodeForm/LoginWithCodeForm";

import styles from "./styles";

const LoginWithCode: React.VFC<RouteChildrenProps> = ({ history }) => {
  return (
    <LoginLayout showBanner>
      <Card>
        <CardContent sx={styles.cardContent}>
          <LoginWithCodeForm history={history} />
        </CardContent>
      </Card>
    </LoginLayout>
  );
};

export default LoginWithCode;
