import {
  Apartment,
  FolderOpen,
  LocationOn,
  Phone,
  Email,
  Star,
  Flag,
  LinkedIn,
  Error,
  AccountBalance,
  InsertInvitation,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";

import BigAvatar from "../../../../../components/BigAvatar/BigAvatar";
import { useSelector } from "../../../../../redux/typedHooks";
import { handleDownloadFile } from "../../../../../utils/helpers/downloadFile";
import ItemProperty from "../../../../DetailsOpportunity/components/MainCard/ReadMode/ItemProperty/ItemProperty";
import LinkCustom from "../../../../DetailsOpportunity/components/MainCard/ReadMode/LinkCustom/LinkCustom";
import { ERolesCode } from "../../../../routes/role-catalog";
import mainStyles from "../../../styles";
import { styles } from "../styles";
import { FormType } from "../types";

import ButtonReadMode from "./ButtonReadMode/ButtonReadMode";
import ButtonReadModeMobile from "./ButtonReadModeMobile/ButtonReadModeMobile";
import { Chip as CustomChip } from "./Chip";
import ItemReadProperty from "./ItemReadProperty/ItemReadProperty";

interface IMainCardReadMode {
  hook: UseFormReturn<FormType>;
  changeMode: () => void;
}

export const ReadMode: FC<IMainCardReadMode> = ({ changeMode, hook }) => {
  const { pathname } = useLocation();
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  const isDetailMember = pathname.includes("detalles-miembro");
  const { id } = useParams() as { id: string };
  const isSelfMember =
    user.role.code === ERolesCode.MEMBER && user.member._id === id;

  const tabsValidForDetailsMember = [
    ERolesCode.SUPER_ADMIN,
    ERolesCode.ADMINISTRATOR,
    ERolesCode.ANALYST,
    ERolesCode.PARTNER,
  ].includes(user.role.code);

  const validToEditOnRoutDetailMember = isDetailMember
    ? tabsValidForDetailsMember || isSelfMember
    : true;

  const breakpoint = useMediaQuery("(max-width: 850px)");
  const mobileButton = useMediaQuery("(max-width: 740px)");

  const cvFileName = hook.watch("cv.name");

  function BuildPhoneFormat(phoneNumber: string) {
    if (phoneNumber !== "") {
      return phoneNumber
        .toString()
        .split("")
        .reverse()
        .join("")
        .match(/.{2,4}/g)
        ?.join(" ")
        .split("")
        ?.reverse()
        .join("");
    } else {
      return "";
    }
  }

  function handleDownload() {
    if (hook.getValues("cv")) {
      handleDownloadFile(hook.getValues("cv"));
    }
  }

  return (
    <Grid container>
      <Grid item xs={10}></Grid>
      <Grid item xs={2}>
        <Stack
          direction={"row"}
          justifyContent="flex-end"
          alignItems="center"
          mb={"16px"}
        >
          {!mobileButton && validToEditOnRoutDetailMember && (
            <ButtonReadMode
              xs={mainStyles.actionsButtons}
              changeMode={changeMode}
            >
              Editar
            </ButtonReadMode>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Box
          display={"flex"}
          flexDirection={breakpoint ? "column" : "row"}
          width="100%"
          flexWrap={"wrap"}
        >
          <Box
            paddingRight={mobileButton ? "0px" : "56px"}
            flex={mobileButton ? 1 : 0}
          >
            {mobileButton && validToEditOnRoutDetailMember && (
              <Stack direction="row" justifyContent="end">
                <ButtonReadModeMobile
                  xs={{ ...mainStyles.actionsMobileButtons }}
                  changeMode={changeMode}
                />
              </Stack>
            )}
            <Stack
              alignItems="center"
              paddingTop={mobileButton ? "24px" : "0px"}
            >
              <BigAvatar
                fileMulter={hook.watch("avatar") as any}
                letter={hook.watch("firstName")[0]}
              />
            </Stack>
          </Box>
          <Box flex={1} paddingTop={breakpoint ? "28px" : "0px"}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
              flexWrap={"wrap"}
            >
              <Stack direction={"row"} alignItems="center">
                <Typography sx={styles.textCompany}>
                  {hook.getValues("firstName") +
                    " " +
                    hook.getValues("lastName")}
                </Typography>
              </Stack>
            </Stack>
            <Typography
              sx={{
                marginTop: "8px",
                marginBottom: "9px",
                fontFamily: "Product Sans Light !important",
                fontStyle: "normal",
                fontWeight: "300",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#515151",
              }}
            >
              {hook.watch("aboutMe")}
            </Typography>
            <Stack
              columnGap={"20px"}
              direction="row"
              flexWrap={"wrap"}
              marginBottom="17px"
            >
              <ItemProperty
                icon={
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={styles.starIconBox}
                  >
                    <Star sx={{ width: "12px", color: "#397386" }} />
                  </Box>
                }
                label="Startups invertidas"
                text={hook.getValues("projectInvesting").toString()}
                doesHasSpace
              />
              <ItemProperty
                icon={
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={styles.starIconBox}
                  >
                    <InsertInvitation
                      sx={{ width: "12px", color: "#397386" }}
                    />
                  </Box>
                }
                label="Miembro desde"
                text={moment(hook.watch("memberStartedDate")).format("LL")}
                doesHasSpace
              />
            </Stack>
            <Typography sx={styles.subtitle}>Contacto</Typography>

            <Stack
              direction={"row"}
              display="flex"
              flexWrap={"wrap"}
              columnGap={"20px"}
            >
              <ItemProperty
                icon={<Email sx={mainStyles.icon} />}
                label="Correo"
                text={<LinkCustom value={hook.watch("email")} />}
                doesHasSpace
              />
              <ItemProperty
                icon={<Phone sx={mainStyles.icon} />}
                label="Teléfono"
                text={`${
                  hook.getValues("phoneCountryCode") === undefined
                    ? " "
                    : "+" + hook.getValues("phoneCountryCode")
                } ${BuildPhoneFormat(hook.getValues("phoneNumber"))}`}
                doesHasSpace
              />
            </Stack>
          </Box>
        </Box>
        <Box width="100%" flexWrap={"wrap"}>
          <Typography sx={styles.subtitle}>Ubicación</Typography>
          <Grid container flexWrap="wrap">
            <Grid item xs={12} lg={4}>
              <ItemReadProperty
                Icon={Flag}
                title="Nacionalidad"
                text={hook.getValues("nationalityCountry")}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <ItemReadProperty
                Icon={LocationOn}
                title="País de Residencia"
                text={hook.getValues("residenceCountry")}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <ItemReadProperty
                Icon={Apartment}
                title="Ciudad de Residencia"
                text={hook.getValues("residenceCity")}
              />
            </Grid>
          </Grid>

          <Divider sx={{ ...styles.divider, width: "100%" }} />
          {(hook.getValues("linkedInProfile") || hook.getValues("cv")) && (
            <>
              <Typography sx={styles.subtitle}>Información Personal</Typography>
              <Grid container flexWrap="wrap">
                {hook.getValues("linkedInProfile") && (
                  <Grid item xs={12} lg={4}>
                    <Link
                      href={hook.getValues("linkedInProfile")}
                      sx={styles.linkedinLink}
                      target="_blank"
                    >
                      <Stack direction="row" alignItems="center">
                        <LinkedIn sx={styles.linkedinIcon} />
                        <Link
                          href={hook.getValues("linkedInProfile")}
                          sx={styles.linkedinLink}
                          target="_blank"
                        >
                          LINKEDIN
                        </Link>
                      </Stack>
                    </Link>
                  </Grid>
                )}
                <Grid item xs={12} lg={4}>
                  <ItemProperty
                    icon={<FolderOpen sx={mainStyles.icon} />}
                    label="CV"
                    text={
                      <LinkCustom onClick={handleDownload} value={cvFileName} />
                    }
                    doesHasSpace
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Divider sx={{ ...styles.divider, width: "100%" }} />
          {(hook.getValues("linkedInProfile") ||
            hook.getValues("cv") ||
            hook.getValues("howFindAngelHub")) && (
            <>
              <Typography sx={styles.subtitle}>
                Información Inversionista
              </Typography>
              <Grid container flexWrap="wrap">
                <Grid direction="column" item xs={12} lg={4}>
                  <ItemReadProperty
                    Icon={AccountBalance}
                    title="¿Has realizado inversiones ángeles?:"
                    text={hook.watch("hasExperienceInvesting") ? "Si" : "No"}
                  />
                </Grid>
                <Grid direction="column" item xs={12} lg={4}>
                  <ItemReadProperty
                    Icon={AccountBalance}
                    title="¿Has invertido en fondos de Venture Capital?:"
                    text={
                      hook.watch("investVentureCapital") === undefined
                        ? ""
                        : hook.watch("investVentureCapital")
                        ? "Si"
                        : "No"
                    }
                  />
                </Grid>
                {tabsValidForDetailsMember && (
                  <Grid direction="column" item xs={12} lg={4}>
                    <ItemReadProperty
                      Icon={AccountBalance}
                      title="¿Con qué perfil de inversionista me identifico más?:"
                      text={hook.watch("profileIdentify")}
                    />
                  </Grid>
                )}
                {tabsValidForDetailsMember && (
                  <Grid direction="column" item xs={12} lg={4}>
                    <ItemReadProperty
                      Icon={Error}
                      title="¿Como llegaste al club?"
                      text={hook.watch("howFindAngelHub")}
                    />
                  </Grid>
                )}
              </Grid>
            </>
          )}
          {hook.watch("interestIndustries").length > 0 && (
            <>
              <Divider sx={{ ...styles.divider, width: "100%" }} />
              <Typography sx={styles.subtitle}>
                Industrias de interés
              </Typography>
              <Box display={"flex"} gap={"16px"} flexWrap={"wrap"}>
                {hook.watch("interestIndustries").map((i, index) => (
                  <CustomChip key={index} text={i} />
                ))}
              </Box>
            </>
          )}
        </Box>
      </Grid>
      <Grid item sm={2} />
    </Grid>
  );
};
