/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { changeEdit } from "../../../../../../redux/actions/LoggedUser";
import { useDispatch } from "../../../../../../redux/typedHooks";
import { getOpportunityApplicationFinancialInfo } from "../../../../../../services/opportunitiesApplication";
import { IFormFinancialInfo } from "../../types";

import { EditMode } from "./EditMode/EditMode";
import { updateFinancialInfoSchema } from "./EditMode/schema";
import { ReadMode } from "./ReadMode/ReadMode";

const FinancialInfo: React.FC = () => {
  const { id } = useParams() as { id: string };
  const dispatch = useDispatch();

  const [enableEditMode, setEnableEditMode] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const hook = useForm<IFormFinancialInfo>({
    mode: "all",
    resolver: yupResolver(updateFinancialInfoSchema),
    defaultValues: {
      requiredInvestment: 0,
      valuation: 0,
    },
  });

  useEffect(() => {
    dispatch(changeEdit(enableEditMode));
  }, [enableEditMode]);

  useEffect(() => {
    getOpportunityApplicationFinancialInfo(id)
      .then(({ data }) => {
        const values = data.info;
        hook.setValue("monthlyBurnRate", values.monthlyBurnRate);
        hook.setValue("monthlyRevenue", values.monthlyRevenue);
        hook.setValue("postMoney", values.postMoney);
        hook.setValue("requiredInvestment", values.requiredInvestment);
        hook.setValue("runway", values.runway);
        hook.setValue("valuation", values.valuation);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, reload]);

  return (
    <>
      {enableEditMode ? (
        <EditMode
          id={id}
          hook={hook}
          onReload={() => setReload(!reload)}
          changeMode={() => setEnableEditMode(!enableEditMode)}
        />
      ) : (
        <ReadMode
          loading={loading}
          hook={hook}
          changeMode={() => setEnableEditMode(!enableEditMode)}
        />
      )}
    </>
  );
};

export default FinancialInfo;
