import { IFieldSchema } from "../../components/Form";

export const registerFieldsSchema: IFieldSchema[] = [
  {
    name: "email",
    validations: {
      maxLength: {
        max: 64,
        errorMessage: "El correo no puede tener mas de 64 caracteres",
      },
      emailFormat: {
        errorMessage: "Email no es válido",
      },
      required: {
        errorMessage: "Ingresa tu correo",
      },
    },
    value: "",
  },
  {
    name: "password",
    validations: {
      minLength: {
        errorMessage: "La contraseña debe tener al menos 8 caracteres",
        min: 8,
      },
      required: {
        errorMessage: "La contraseña es requerida",
      },
    },
    value: "",
    watch: ["confirmPassword"],
  },
  {
    name: "confirmPassword",
    validations: {
      match: {
        match: "password",
        errorMessage: "Las contraseñas deben coincidir",
      },
      required: {
        errorMessage: "La contraseña es requerida",
      },
    },
    value: "",
    watch: ["password"],
  },
  {
    name: "firstName",
    validations: {
      maxLength: {
        max: 50,
        errorMessage: "El nombre no puede tener mas de 50 caracteres",
      },
      minLength: {
        errorMessage: "El nombre debe tener al menos 2 caracteres",
        min: 2,
      },
      required: {
        errorMessage: "Ingresa tu(s) nombre(s)",
      },
    },
    value: "",
  },
  {
    name: "lastName",
    validations: {
      maxLength: {
        max: 50,
        errorMessage: "El primer apellido no puede tener mas de 50 caracteres",
      },
      minLength: {
        errorMessage: "El primer apellido debe tener al menos 2 caracteres",
        min: 2,
      },
      required: {
        errorMessage: "Ingresa tu primer apellido",
      },
    },
    value: "",
  },
];