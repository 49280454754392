import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { getToken } from "../config";
import {
  IMemberDashboardRanking,
  IMembersDashboardDataAPI,
} from "../pages/DashboardMember/types";
import { EFileTypes } from "../pages/DetailsStartup/components/TabList/Tabs/AttachedFiles/validations";
import {
  EFileTypesMember,
  EFileTypesMemberAdmin,
} from "../pages/ProfileMember/components/TabList/Tabs/MyDocuments/validations";
import { IUpdateMembership } from "../utils/types/common";

const getMemberById = (id: string): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/members/" + id,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

const getUserByMemberId = (memberId: string): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/users/byMember/" + memberId,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

const getMemberMainCard = (id: string): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/members/mainCard/" + id,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

const getMemberPortafolio = (id: string): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `members/portafolio/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getMemberReports = (id: string): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "members/reports/" + id,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};
const getMemberDocuments = (id: string): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "members/document/" + id,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const updateMemberById = (
  id: string,
  data: any,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    method: "patch",
    url: "/members/" + id,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

const updateMemberMainCardById = (
  id: string,
  data: any,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    method: "patch",
    url: "/members/mainCard/" + id,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};
function deleteMemberFiles(
  memberId: string,
  fileId: string,
  fileType: EFileTypes | EFileTypesMember | EFileTypesMemberAdmin,
): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/members/remove-file-member/`,
    method: "DELETE",
    data: {
      memberId,
      fileId,
      type: fileType,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
function addPaymentFile(data: any): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/members/upload-payment/`,
    method: "POST",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
function addFilesMember(data: any): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/members/update-member-files/`,
    method: "POST",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

function getMembership(id: string): Promise<AxiosResponse<any>> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/members/memberSubscription/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export const updateMembershipById = (
  id: string,
  data: IUpdateMembership,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    method: "POST",
    url: "/members/update-membership/" + id,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const getDashboardMember = (): Promise<
  AxiosResponse<IMembersDashboardDataAPI>
> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    method: "GET",
    url: "/dashboard/members",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const getDashboardMemberRanking = (): Promise<
  AxiosResponse<IMemberDashboardRanking[]>
> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    method: "GET",
    url: "/dashboard/members/Ranking",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

const getDashboardAdmin = (): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    method: "GET",
    url: "/dashboard/admin",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export {
  getMemberDocuments,
  getMemberById,
  getMemberPortafolio,
  getMemberReports,
  getMemberMainCard,
  updateMemberMainCardById,
  getUserByMemberId,
  addPaymentFile,
  deleteMemberFiles,
  getMembership,
  getDashboardAdmin,
  addFilesMember,
};
