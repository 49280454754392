import { Link } from "@mui/material";
import React from "react";

import { CleanUrlString } from "../../../../../DetailsStartup/components/MainCard/utils";
import { styles } from "../../styles";

interface Props {
  value?: string;
  isRedirect?: boolean;
  onClick?: () => void;
}

const LinkCustom: React.FC<Props> = ({ value, isRedirect, onClick }) => {
  return (
    <Link
      sx={{ ...styles.linkItemProperty }}
      underline={"hover"}
      target="_blank"
      href={isRedirect ? value || undefined : undefined}
      onClick={onClick ? onClick : undefined}
    >
      {CleanUrlString(value || "No existe")}
    </Link>
  );
};

export default LinkCustom;
