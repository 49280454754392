import { Dispatch } from "redux";

import { EStatusReleasedOpportunity } from "../../constants";
import { opportunitiesReleased } from "../../services/opportunitiesReleased";

export const LOADING_OPPORTUNITY_RELEASED = "LOADING_OPPORTUNITY_RELEASED";

export const FETCH_OPPORTUNITIES_RELEASED_PAGINATED =
  "FETCH_OPPORTUNITIES_RELEASED_PAGINATED";

export const fetchOpportunityReleasedPaginated =
  (filterIncomplete?: boolean) => async (dispatch: Dispatch) => {
    dispatch({ type: LOADING_OPPORTUNITY_RELEASED, payload: true });
    try {
      const response = await opportunitiesReleased();
      const data = response.data as any;
      dispatch({
        type: FETCH_OPPORTUNITIES_RELEASED_PAGINATED,
        payload: data.filter((i: any) =>
          filterIncomplete
            ? i.status !== EStatusReleasedOpportunity.INCOMPLETE
            : i,
        ),
      });
    } catch {
      dispatch({ type: LOADING_OPPORTUNITY_RELEASED, payload: false });
    } finally {
      dispatch({ type: LOADING_OPPORTUNITY_RELEASED, payload: false });
    }
  };

export const CHANGE_PAGE_OPPORTUNITIES_RELEASED =
  "CHANGE_PAGE_OPPORTUNITIES_RELEASED";
export const changePageOpportunitiesReleased = (value: number) => {
  return {
    type: CHANGE_PAGE_OPPORTUNITIES_RELEASED,
    payload: value,
  };
};
export const CHANGE_LIMIT_OPPORTUNITIES_RELEASED =
  "CHANGE_LIMIT_OPPORTUNITIES_RELEASED";
export const changeLimitOpportunitiesReleased = (value: number) => {
  return {
    type: CHANGE_LIMIT_OPPORTUNITIES_RELEASED,
    payload: value,
  };
};
export const CHANGE_OPPORTUNITY_RELEASED_VALUE =
  "CHANGE_OPPORTUNITIES_RELEASED_VALUE";
export const changeOpportunityReleased = (value: any) => {
  return {
    type: CHANGE_OPPORTUNITIES_RELEASED_VALUE,
    payload: value,
  };
};
export const CHANGE_OPPORTUNITIES_RELEASED_VALUE =
  "CHANGE_OPPORTUNITIES_RELEASED_VALUE";
export const changeOpportunitiesReleased = (value: any) => {
  return {
    type: CHANGE_OPPORTUNITIES_RELEASED_VALUE,
    payload: value,
  };
};
