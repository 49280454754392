export const currencies = [
  {
    value: true,
    label: "Si",
  },
  {
    value: false,
    label: "No",
  },
];

export const countries = [
  {
    value: "MEXICO",
    label: "México",
  },
  {
    value: "EU",
    label: "EU",
  },
  {
    value: "COLOMBIA",
    label: "Colombia",
  },
  {
    value: "CHILE",
    label: "Chile",
  },
  {
    value: "PERU",
    label: "Perú",
  },
  {
    value: "ARGENTINA",
    label: "Argentina",
  },
  {
    value: "URUGUAY",
    label: "Uruguay",
  },
  {
    value: "CARIBE",
    label: "Caribe",
  },
  {
    value: "CENTROAMERICA",
    label: "Centroamérica",
  },
  {
    value: "OTROS",
    label: "Otros",
  },
];
