import { SxProps, Theme } from "@mui/material";

const styles = {
  container: {
    display: "flex",
    minHeight: "100vh",
    // backgroundColor: "#74C24A",
    flexDirection: {
      xs: "column",
      md: "row",
    },
  },
  textContainer: {
    backgroundColor: "#74C24A",
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    maxHeight: "100vh",
    paddingLeft: {
      xs: "28px",
      md: "0px",
    },
    paddingRight: {
      xs: "28px",
      md: "0px",
    },
    "@media (max-width: 365px)": {
      paddingLeft: "12px",
      paddingRight: "12px",
    },
    "@media only screen and (min-width: 500px) and (max-width: 700px)": {
      justifyContent: "center",
      alignItems: "center",
    },
    "@media only screen and (max-height: 650px) and (max-width: 500)": {
      height: "unset",
    },
  },
  formContainer: {
    backgroundColor: "#FFFFFF",
    borderRadius: {
      xs: "6% 6% 0% 0%",
      md: "10% 0% 0% 10%",
    },
    display: "flex",
    justifyContent: "center",
    width: {
      xs: "100vw",
      md: "50vw",
    },
    marginTop: {
      xs: "2rem",
      md: 0,
    },
  },
  titleTextStyle: {
    fontWeight: "900",
    fontSize: "32px",
    color: "#515151",
  },
  grayTextStyle: {
    fontWeight: "400",
    fontSize: "20px",
    color: "#909090",
    marginBottom: {
      xl: "5rem",
    },
  },
  // instructionBoxStyles: {
  //   width: "100%",
  //   height: "5rem",
  //   border: "0.8px solid #A6D88B",
  //   boxShadow: "2px 2px 0px #4A7C2F",
  //   borderRadius: "8px",
  //   backgroundColor: "#F8F8F8",
  // },
  numberBoxStyles: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: {
      xs: "3px 7.5px",
      xl: "4px 10px",
    },
    gap: {
      xs: "7.5px",
      xl: "10px",
    },
    width: {
      xs: "1.5rem",
      xl: "2rem",
    },
    height: {
      xs: "1.5rem",
      xl: "2rem",
    },
    backgroundColor: "#D3EBC5",
    border: "1px solid #74C24A",
    borderRadius: "100px",
  },
  cardTitleStyles: {
    fontWeight: "900",
    fontSize: {
      xs: "16px",
      xl: "20px",
    },
    color: "#515151",
  },
  cardSubtitleStyles: {
    fontWeight: "300",
    fontSize: "16px",
    color: "#909090",
  },
  step: {
    "& .MuiStepLabel-root .Mui-completed": {
      color: "#69BC41", // circle color (COMPLETED)
    },
    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
      color: "red", // Just text label (COMPLETED)
    },
    "& .MuiStepLabel-root .Mui-active": {
      color: "#69BC41", // circle color (ACTIVE)
    },
    "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
      color: "red", // Just text label (ACTIVE)
    },
    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
      fill: "white", // circle's number (ACTIVE)
    },
  },
  buttonContainerStyles: {
    //   display: "flex",
    //   position: "absolute",
    //   justifyContent: "center",
    //   flexDirection: "row",
    //   marginY: {
    //     xs: "3rem",
    //     md: 0,
    //   },
    //   paddingX: {
    //     lg: "6.15%",
    //     xl: "9.7%"
    //   },
    //   bottom: {
    //     lg: "1.6rem",
    //     xl: "5.1rem",
    //   }
    // },
    // buttonContainerInStepper: {
    //   display: "flex",
    //   position: "absolute",
    //   flexDirection: "row",
    //   left: 0
  },
};

export const MemberFaceliftOnboardingStyle: SxProps<Theme> = (
  theme: Theme,
) => ({
  backgroundColor: "#74C24A",
  minHeight: "100vh",
  justifyContent: "space-around",
  "& .member-facelift-onboarding__left": {
    backgroundColor: "#74C24A",
    [theme.breakpoints.down("lg")]: {
      flexGrow: "1",
    },
  },
  "& .member-facelift-onboarding__right": {
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.up("lg")]: {
      borderRadius: "90px 0 0 90px",
    },
    [theme.breakpoints.down("lg")]: {
      borderRadius: "30px 30px 0 0",
    },
    "&>.MuiGrid-root.MuiGrid-container": {
      maxWidth: "726px",
      [theme.breakpoints.up(1740)]: {
        mx: theme.spacing(16),
        my: 10,
      },
      [theme.breakpoints.between(1600, 1740)]: {
        mx: "auto",
        my: 6,
      },
      [theme.breakpoints.between("lg", 1600)]: {
        mx: theme.spacing(6.8125),
        my: 6,
      },
      [theme.breakpoints.between(636, "lg")]: {
        mx: "auto",
        my: 4,
      },
      [theme.breakpoints.down(636)]: {
        mx: 2,
        my: 3,
      },
    },
  },
  ".imgMargin": {
    [theme.breakpoints.down("lg")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "140px",
    },
  },
  "& .MuiStepper-root": {
    [theme.breakpoints.up(450)]: {
      width: "216px",
      marginLeft: "-23px",
    },
    [theme.breakpoints.down(449)]: {
      width: "100%",
      "& .MuiStep-root:nth-child(2) .MuiStepConnector-root": {
        left: "calc(-40%)",
        right: "calc(100%)",
      },
      "& .MuiStep-root:nth-child(3) .MuiStepConnector-root": {
        left: "calc(0%)",
        right: "calc(60%)",
      },
    },
    "& .MuiStep-root": {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      [theme.breakpoints.down(450)]: {
        paddingX: 0,
      },
    },
    "& .MuiSvgIcon-root": {
      [theme.breakpoints.up(1443)]: {
        width: "1.667em",
        height: "1.667em",
      },
      [theme.breakpoints.down(1443)]: {
        width: "1.3335em",
        height: "1.3335em",
      },
      color: "#D3EBC5",
      "& .MuiStepIcon-text": {
        fill: "#74C24A",
        fontFamily: "Product Sans Black !important",
      },
    },
    "& .MuiSvgIcon-root.Mui-active": {
      color: "#74C24A",
      "& .MuiStepIcon-text": {
        fill: theme.palette.common.white,
      },
    },
    "& .MuiStepConnector-root": {
      [theme.breakpoints.up(1443)]: {
        top: theme.spacing(2.5),
      },
      [theme.breakpoints.down(1443)]: {
        top: theme.spacing(2),
      },
      left: "calc(-50% + 27px)",
      right: "calc(50% + 27px)",
    },
  },
  "& .stepper-back-button": {
    fontFamily: "Product Sans Black",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#4A88C2",
  },
  "& .logo": {
    [theme.breakpoints.up(1443)]: {
      width: "351px",
    },
    [theme.breakpoints.between(450, 1443)]: {
      width: "312px",
    },
    [theme.breakpoints.down(450)]: {
      width: "241px",
    },
  },
  "& .stepper-container": {
    [theme.breakpoints.up(1443)]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    [theme.breakpoints.down(1443)]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down(450)]: {
      width: "100%",
    },
  },
  "& .left-step": {
    [theme.breakpoints.down(450)]: {
      display: "flex",
      justifyContent: "flex-start !important",
    },
  },
  "& .right-step": {
    [theme.breakpoints.down(450)]: {
      display: "flex",
      justifyContent: "flex-end !important",
    },
  },
  "& .greenTextStyles": {
    fontWeight: "400",
    [theme.breakpoints.up(1501)]: {
      fontSize: "32px",
      marginBottom: "3rem",
    },
    [theme.breakpoints.down(1500)]: {
      fontSize: "24px",
    },
    color: "#4A7C2F",
    [theme.breakpoints.down(600)]: {
      marginBottom: "2rem",
    },
    letterSpacing: 0,
    fontFamily: "Product Sans !important",
  },
  "& .angelhubTextStyle": {
    fontWeight: "400",
    [theme.breakpoints.up(1500)]: {
      fontSize: "40px !important",
    },
    [theme.breakpoints.down(1500)]: {
      fontSize: "32px !important",
    },
    color: "#FFFFFF",
    lineHeight: "48px",
    fontFamily: "Product Sans Bold !important",
  },
  "& .angelhubImg": {
    [theme.breakpoints.up(1280)]: {
      maxWidth: "607px",
    },
    [theme.breakpoints.between(600, 1280)]: {
      maxWidth: "450px",
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: "258.66px",
      marginBottom: "2rem",
    },
  },
  "& .greenTextGrid": {
    [theme.breakpoints.up(1280)]: {
      width: "607px",
      mt: "150px",
    },
    [theme.breakpoints.between(600, 1280)]: {
      width: "450px",
      mt: "40px",
    },
    [theme.breakpoints.down(600)]: {
      width: "327",
      mt: "40px",
      padding: "0px 16px",
    },
  },
  "& .titleTextStyle": {
    [theme.breakpoints.up(1500)]: {
      fontSize: "40px !important",
      mt: "59px",
    },
    [theme.breakpoints.between(700, 1500)]: {
      fontSize: "32px !important",
      mt: "42px",
    },
    [theme.breakpoints.down(700)]: {
      fontSize: "24px !important",
      mt: "40px",
    },
    fontWeight: "900",
    color: "#515151",
    fontFamily: "Product Sans Bold !important",
  },
  "& .grayTextStyle": {
    [theme.breakpoints.up(1500)]: {
      fontSize: "24px !important",
      width: "504px",
      mb: "48px",
    },
    [theme.breakpoints.between(700, 1500)]: {
      fontSize: "20px !important",
      width: "504px",
      mb: "32px",
    },
    [theme.breakpoints.down(700)]: {
      fontSize: "16px !important",
      width: "328px",
      mb: "32px",
    },
    fontWeight: "400",
    color: "#909090",
    fontFamily: "Product Sans !important",
  },
  "& .continueButton-style": {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up(1280)]: {
      maxWidth: "635px !important",
    },
    [theme.breakpoints.between(700, 1280)]: {
      maxWidth: "540px !important",
    },
    [theme.breakpoints.down(700)]: {
      maxWidth: "328px !important",
    },
  },
  "& .paddingStartupFaceLift": {
    [theme.breakpoints.up(1740)]: {
      padding: "75px 120px",
      justifyContent: "flex-start",
    },
    [theme.breakpoints.up(1280)]: {
      padding: "48px 35px 0px 53px",
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down(1280)]: {
      padding: "24px 5px 30px 16px",
      justifyContent: "center",
    },
  },
});

export const endScreen: SxProps<Theme> = (theme: Theme) => ({
  "& .end-screen": {
    height: "80vh",
    display: "flex",
    flexDirection: "column",
  },
  "& .text": {
    padding: "20px 0px",
    font: "normal normal normal 16px/19px Product Sans Medium",
    color: "#5D5D5D",
  },
  "& .angelhubImg": {
    [theme.breakpoints.up(1280)]: {
      maxWidth: "607px",
    },
    [theme.breakpoints.between(600, 1280)]: {
      maxWidth: "450px",
    },
    [theme.breakpoints.down(600)]: {
      maxWidth: "258.66px",
      marginBottom: "2rem",
    },
  },
});

export default styles;
