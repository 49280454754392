import { Ballot } from "@mui/icons-material";
import { SxProps } from "@mui/material";
import { useParams } from "react-router-dom";

import SplitButton from "../../../../../../components/SplitButton/SplitButton";
import { useSelector } from "../../../../../../redux/typedHooks";
import { ERolesCode } from "../../../../../routes/role-catalog";

interface IProps {
  xs: SxProps;
  changeMode: () => void;
}

const ButtonReadModeMobile: React.FC<IProps> = ({ changeMode, xs }) => {
  const { id } = useParams() as { id: string };
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  function handleEditar() {
    changeMode();
  }

  return (
    <>
      {((user.role.code === ERolesCode.MEMBER && user.member._id === id) ||
        (user.role.code !== ERolesCode.MEMBER &&
          user.role.code !== ERolesCode.STARTUP)) && (
        <SplitButton
          isMobile
          title="Acciones"
          options={[
            {
              label: "Editar",
              visible: true,
              onClick: handleEditar,
            },
          ]}
          icon={<Ballot />}
        />
      )}
    </>
  );
};

export default ButtonReadModeMobile;
