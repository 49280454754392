import { Box } from "@mui/material";
import { useState } from "react";

import StartUpApplicationLayout from "../../components/StartupApplicationLayout/StartupApplicationLayout";

import EndScreen from "./screens/EndScreen";
import NoScreen from "./screens/NoScreen";
import StartScreen from "./screens/StartScreen";
import YesScreen from "./screens/YesScreen";

export type Screens = "Start" | "No" | "Si" | "End";

const StartupWizards = () => {
  const [screen, setScreen] = useState<Screens>("Start");

  const handleChange = (value: Screens) => {
    setScreen(value);
  };

  const conditionalRender = {
    Start: <StartScreen changeOption={handleChange} />,
    No: <NoScreen />,
    Si: <YesScreen changeOption={handleChange} />,
    End: <EndScreen step={screen} />,
  };

  if (screen === "End") {
    return <Box>{conditionalRender[screen]}</Box>;
  }

  return (
    <StartUpApplicationLayout>
      <Box>{conditionalRender[screen]}</Box>
    </StartUpApplicationLayout>
  );
};

export default StartupWizards;
