/**
 *
 * @param amount The value of currency to format
 * @param type The currency type eg: USD or MXN
 * @returns a string formatted as currency
 */
export const currencyFormat = (amount: number, type: string) => {
  const options = { style: "currency", currency: type };
  const numberFormat = new Intl.NumberFormat("es-MX", options);
  return numberFormat.format(amount).toString();
};
/**
 *
 * @param name The name or string separated by spaces eg : victor daniel
 * @returns string with first letter capitalized
 */
export const capitalizeName = (name: string) => {
  const nameSplit = name.split(" ");
  for (let i = 0; i < nameSplit.length; i++) {
    if (nameSplit[i].length > 0) {
      nameSplit[i] = nameSplit[i][0].toUpperCase() + nameSplit[i].substr(1);
    }
  }
  return nameSplit.join(" ");
};
