import axios, { AxiosResponse, AxiosRequestConfig } from "axios";

const resendConfirmationEmail = (
  email: string,
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/resend-confirm/${email}`,
    method: "GET",
  };

  return axios(config);
};

const confirmEmail = (
  token: string,
  id: string,
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/confirm/${id}/${token}`,
    method: "GET",
  };

  return axios(config);
};

const sendOtpCode = (
  email: string
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/request-opt`,
    method: "POST",
    data: {
      email
    }
  };

  return axios(config);
};

const sendOtpMail = (
  email: string
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/request-opt-email`,
    method: "POST",
    data: {
      email
    }
  };

  return axios(config);
};

const validateCode = (
  email: string,
  code: number
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/validate-opt`,
    method: "POST",
    data: {
      email,
      otpToken: code
    }
  };

  return axios(config);
};

export { resendConfirmationEmail, confirmEmail, sendOtpCode, sendOtpMail, validateCode };
