import * as yup from "yup";

import { CAMPO_REQUERIDO } from "../../../utils/text/inputs";
import { LINKEDIN_URL_VALIDA } from "../../../utils/text/resetForm";

export const VALIDATE_LINKEDIN_PROFILE_REGEX =
  /^(http(s)?:\/\/)?(www\.)?linkedin\.com\/(pub|in|profile)/gm;

export const preMemberSchema2 = yup.object({
  cv: yup.array(),
  linkedInProfile: yup
    .string()
    .matches(VALIDATE_LINKEDIN_PROFILE_REGEX, LINKEDIN_URL_VALIDA)
    .when("cv", {
      is: (cv: File[]) => cv.length <= 0,
      then: yup.string().required(CAMPO_REQUERIDO),
    }),
  hasExperienceInvesting: yup.string().required(CAMPO_REQUERIDO),
  interestToEnter: yup.string().required(CAMPO_REQUERIDO),
  howFindAngelHub: yup.string().required(CAMPO_REQUERIDO),
  profileIdentify: yup.string().required(CAMPO_REQUERIDO),
});
