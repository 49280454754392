import { StylesObject } from "../styles";

const styles: StylesObject = {
  startGridObject: {
    paddingRight: {
      xs: 0,
      md: "0.5rem"
    }
  },
  middleGridObject: {
    paddingX: {
      xs: 0,
      md: "0.5rem"
    }
  },
  endGridObject: {
    paddingLeft: {
      xs: 0,
      md: "0.5rem"
    }
  },
  textArea: {
    height: "200px"
  },
  phoneInput: {
    height: 48
  }
}

export default styles;