import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { getToken } from "../config";

export interface IDataReject {
  status: string;
  partnerId?: string;
  comment?: string;
}

export const changeStatusApplicationRequest = (
  id: string,
  data: IDataReject,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/member-applications/status/` + id,
    method: "PATCH",
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};
