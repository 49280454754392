import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Avatar, Divider, Grid, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

import HorizontalDivider from "../../../../components/Dashboard/HorizontalDivider";
import { CalendarItemStyles } from "../style";

interface ICalendarItemProps {
  id: string;
  startup: string;
  date: string;
}

const CalendarItem: React.FC<ICalendarItemProps> = ({ id, startup, date }) => {
  const history = useHistory();

  function handleGoToDetails() {
    history.push(`/details-startup/${id}`);
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-end"
        columnSpacing={1}
        sx={CalendarItemStyles}
      >
        <Grid item xs="auto">
          <Avatar>
            <CalendarMonthIcon />
          </Avatar>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          variant="middle"
          sx={{ pl: 1 }}
        />
        <Grid item xs className="startup-name-date-time-container">
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography variant="h6" className="startup-name">
              {startup}
            </Typography>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              className="appointment-date-time"
              sx={{ width: "100%" }}
            >
              <Typography className="appointment-date">{date}</Typography>
              <Divider orientation="vertical" flexItem variant="middle" />
              <Typography className="appointment-time">18:00</Typography>
              <Link
                onClick={() => handleGoToDetails()}
                component={"button"}
                underline="none"
              >
                Ver
              </Link>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs="auto"></Grid>
      </Grid>
      <HorizontalDivider />
    </>
  );
};

export default CalendarItem;
