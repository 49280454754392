import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";

import InvestmentList from "./InvestmentList";

const MyPortfolio: React.FC = () => {
  const { id } = useParams() as { id: string };
  return (
    <Box sx={{ height: "500px" }}>
      <InvestmentList id={id} />
    </Box>
  );
};

export default MyPortfolio;
