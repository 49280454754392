import { TablePagination } from "@mui/material";
import React, { FC } from "react";

import { DEFAULT_ROWS_PER_PAGE } from "../constants";

export interface IPaginationProps {
  limit: number;
  page: number;
  totalCount: number;
  onChangePage: (value: number) => void;
  onChangePageLimit: (value: number) => void;
}

const CustomPagination: FC<IPaginationProps> = ({
  limit,
  page,
  totalCount,
  onChangePage,
  onChangePageLimit,
}) => {
  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    onChangePage(newPage);
  };

  const handleChangeLimit = (event: any) => {
    const value = event.target.value as number;
    onChangePageLimit(value);
  };

  return (
    <TablePagination
      component={"div"}
      page={page}
      count={totalCount}
      rowsPerPage={limit}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}–${to} de ${count !== -1 ? count : `mas de ${to}`}`
      }
      labelRowsPerPage="Filas por página"
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeLimit}
      rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE}
    />
  );
};

export default CustomPagination;
