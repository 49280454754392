import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Card, Grid, IconButton, Stack } from "@mui/material";
import { useState } from "react";

import CardHeader from "../../../components/Dashboard/CardHeader";
import { PERIODS } from "../const";
import { FunnelGraph } from "../types";
import useFilter from "../useFilter";

import FunnelChart from "./FunnelChart";
import FunnelTable from "./FunnelTable";
import FunnelCardStyles from "./style";

export interface IFunnel {
  data: FunnelGraph;
}

const FunnelCard: React.FC<IFunnel> = ({ data }) => {
  const [AnchoEl, setAnchorEl] = useState(null);

  const { options, value, handleOpenMenu } = useFilter(AnchoEl, setAnchorEl);

  const values = data[value];

  const period = PERIODS[value];

  return (
    <Box sx={FunnelCardStyles}>
      {options}
      <Card className="funnel-card">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid item xs={12} className="my-actions-header">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <CardHeader variant="h5">
                {`Funnel De Aplicaciones - ${period}`}
              </CardHeader>
              <IconButton onClick={handleOpenMenu} className="action-more-icon">
                <MoreVertIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ paddingLeft: "18px", pt: 6 }}>
            <FunnelChart values={values} />
          </Grid>
          <Grid item xs={12} sm={5}>
            <FunnelTable rows={values} />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default FunnelCard;
