import {
  Search,
  ArrowForward,
  FilterAlt,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  InputAdornment,
  IconButton,
  Autocomplete,
  Badge,
  Button,
  Box,
  useMediaQuery,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";

import DrawerFilter from "../../../components/DrawerFilter/DrawerFilter";
import { TextFieldAutoComplete } from "../../../components/Inputs/TextInput/styled";
import styles from "../styles";

import {
  RESOURCE_LIBRARY_CATEGORIES,
  RESOURCE_LIBRARY_TYPE_CONTENT,
} from "./options";

interface IFiltersResourceLibraryProps {
  category: string[];
  setCategory: (value: string[]) => void;
  typeContent: string[];
  setTypeContent: (value: string[]) => void;
  searchText: string;
  setSearchText: (value: string) => void;
}

const FiltersResourceLibrary: React.FC<IFiltersResourceLibraryProps> = (
  props,
) => {
  const { typeContent, setTypeContent } = props;
  const { category, setCategory } = props;
  const { searchText, setSearchText } = props;

  const mobile = useMediaQuery("(max-width: 1200px)");

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const handleFilter = () => {
    setOpenDrawer(false);
  };

  const badgeNumber = (): number => {
    let number = 0;
    if (category.length) number++;
    if (typeContent.length) number++;
    return number;
  };

  const handleChangeCategory = (_: any, newValue: any) => {
    setCategory(newValue);
  };
  const handleChangeTypeContent = (_: any, newValue: any) => {
    setTypeContent(newValue);
  };

  const filters = (
    <>
      <Autocomplete
        options={RESOURCE_LIBRARY_CATEGORIES}
        onChange={handleChangeCategory}
        value={category}
        multiple
        sx={{ minWidth: 200 }}
        renderInput={(rest) => (
          <TextFieldAutoComplete {...rest} label="Categoría" />
        )}
      />
      <Autocomplete
        multiple
        options={RESOURCE_LIBRARY_TYPE_CONTENT}
        value={typeContent}
        onChange={handleChangeTypeContent}
        sx={{ minWidth: 200 }}
        renderInput={(rest) => (
          <TextFieldAutoComplete {...rest} label="Tipo de contenido" />
        )}
      />
    </>
  );

  return (
    <>
      <DrawerFilter
        isOpen={openDrawer}
        onFilter={handleFilter}
        onClose={handleFilter}
      >
        {filters}
      </DrawerFilter>
      <Box
        sx={{
          ...styles.iconsWrapper,
          flexDirection: mobile ? "row-reverse" : "row",
        }}
      >
        {mobile ? (
          <IconButton onClick={() => setOpenDrawer(true)}>
            <Badge badgeContent={badgeNumber()} color="primary">
              <Button endIcon={<KeyboardArrowDown />} variant="contained">
                <FilterAlt />
              </Button>
            </Badge>
          </IconButton>
        ) : (
          <Stack direction={"row"} alignItems="center" gap="12px">
            {filters}
          </Stack>
        )}
        <TextFieldAutoComplete
          value={searchText}
          sx={{ maxWidth: "540px" }}
          onChange={(event) => setSearchText(event.currentTarget.value)}
          label="Buscar"
          placeholder="Ingresa un título para buscar..."
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: "#8BB3D8" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <ArrowForward sx={{ color: "#8BB3D8" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default FiltersResourceLibrary;
