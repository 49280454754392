import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import Calendly from "../Calendly/Calendly";
import ConfirmEmail from "../ConfirmEmail";
import CreatePassword from "../CreatePassword";
import ForgotPassword from "../ForgotPassword";
import Login from "../Login";
import LoginWithCode from "../LoginWithCode";
import MemberFaceliftOnboarding from "../MemberFaceliftOnboarding";
import PreviewNewsFeed from "../PreviewNewsFeed/PreviewNewsFeed";
import PreviewResourceLibrary from "../PreviewResourceLibrary/PreviewResourceLibrary";
import Register from "../Register";
import RequestCode from "../RequestCode/requestCode";
import ResetPassword from "../ResetPassword";
import SignInMember from "../SignInMember";
import SignInStartup from "../SignInStartup";
import StartupRegister from "../StartupRegister/StartupRegister";

const NoSessionRoutes = () => {
  return (
    <Router>
      <Switch>
        {/* System Routes */}
        <Route exact path="/login" component={Login} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route exact path="/register" component={Register} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/confirm-email" component={ConfirmEmail} />
        <Route path="/request-code" component={RequestCode} />
        <Route path="/login-code" component={LoginWithCode} />
        <Route path="/member-sign-up" component={SignInMember} />
        <Route path="/create-password" component={CreatePassword} />
        <Route path="/startup-sign-up" component={SignInStartup} />
        <Route
          path="/pre-register-member"
          component={MemberFaceliftOnboarding}
        />
        <Route path="/pre-register-startup" component={StartupRegister} />
        <Route path="/calendly" component={Calendly} />
        <Route path="/preview/news-feed/:id" component={PreviewNewsFeed} />
        <Route
          path="/preview/resource-library/:id"
          component={PreviewResourceLibrary}
        />
        {/* Default Route */}
        <Route render={() => <Redirect to="/login" />} />
      </Switch>
    </Router>
  );
};

export default NoSessionRoutes;
