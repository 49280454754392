import {
  CHANGE_OPPORTUNITY_RELEASED_VALUE_ADMIN,
  CHANGE_OPPORTUNITIES_RELEASED_VALUE_ADMIN,
  CHANGE_LIMIT_OPPORTUNITIES_RELEASED_ADMIN,
  CHANGE_PAGE_OPPORTUNITIES_RELEASED_ADMIN,
  FETCH_OPPORTUNITIES_RELEASED_PAGINATED_ADMIN,
  LOADING_OPPORTUNITY_RELEASED_ADMIN,
} from "../actions/opportunitiesReleasedAdmin";

export interface ILoggedApplicationReducer {
  opportunities: any[];
  page: number;
  limit: number;
  loading: boolean;
  totalCount: number;
  opportunity: string;
}

const initialState: ILoggedApplicationReducer = {
  opportunities: [],
  page: 0,
  limit: 10,
  loading: true,
  totalCount: 100,
  opportunity: "",
};

export default function OpportunityReleasedReducerAdmin(
  state = initialState,
  action: any,
) {
  switch (action.type) {
    case FETCH_OPPORTUNITIES_RELEASED_PAGINATED_ADMIN:
      return {
        ...state,
        opportunities: action.payload,
      };
    case CHANGE_PAGE_OPPORTUNITIES_RELEASED_ADMIN:
      return {
        ...state,
        page: action.payload,
      };
    case CHANGE_LIMIT_OPPORTUNITIES_RELEASED_ADMIN:
      return {
        ...state,
        limit: action.payload,
        page: 0,
      };
    case LOADING_OPPORTUNITY_RELEASED_ADMIN:
      return {
        ...state,
        loading: action.payload,
      };
    case CHANGE_OPPORTUNITIES_RELEASED_VALUE_ADMIN:
      return {
        ...state,
        opportunity: action.payload,
      };
    case CHANGE_OPPORTUNITY_RELEASED_VALUE_ADMIN:
      return {
        ...state,
        opportunities: action.payload,
      };
    default:
      return state;
  }
}
