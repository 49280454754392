import ApartmentIcon from "@mui/icons-material/Apartment";
import {
  Avatar,
  Box,
  CardContent,
  CardMedia,
  Paper,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { getSignedUrl } from "../../../services/files";
import { ERolesCode } from "../../routes/role-catalog";
import { directoryInvestorsCardStyles } from "../styles";

interface IProps {
  firstName: string;
  lastName: string;
  role: ERolesCode;
  position: string;
  avatar: any;
  id: string;
}

const InvestorCard: FC<IProps> = (props) => {
  const { firstName, lastName, position, avatar, id, role } = props;
  const [Uri, setUri] = useState<any>("");

  const history = useHistory();

  const handleRedirect = () => {
    const map = {
      [ERolesCode.ADMINISTRATOR]: `/update-admin/${id}`,
      [ERolesCode.SUPER_ADMIN]: `/update-admin/${id}`,
      [ERolesCode.ANALYST]: `/update-analyst/${id}`,
      [ERolesCode.MEMBER]: `/update-member/${id}`,
      [ERolesCode.PARTNER]: `/update-partner/${id}`,
      [ERolesCode.STARTUP]: `/update-startup/${id}`,
    };
    const url = map[role];
    history.push(url);
  };

  useEffect(() => {
    if (avatar) {
      const getSignedAvatarImage = async () => {
        const response = await getSignedUrl(avatar.key, avatar.name);
        setUri(response.data.payload);
      };
      getSignedAvatarImage();
    }
    setUri("");
  }, [avatar]);

  return (
    <Paper sx={directoryInvestorsCardStyles} onClick={() => handleRedirect()}>
      <Box className="card">
        <CardMedia className="cardMedia">
          <Avatar
            src={Uri}
            sx={{ width: "160px", height: "160px", background: "#A6D88B" }}
          >
            <Typography className="avatar">
              {(firstName[0] + lastName[0]).toUpperCase()}
            </Typography>
          </Avatar>
        </CardMedia>
        <CardContent className="cardContent">
          <Typography variant="h4" className="name">
            {`${firstName} ${lastName}`}
          </Typography>
          <Box sx={{ mt: 1.5, display: "flex", gap: "4px" }}>
            <ApartmentIcon className="icon" />
            <Typography className="cityLabel">
              Posición:
              <Typography className="city"> {position}</Typography>
            </Typography>
          </Box>
        </CardContent>
      </Box>
    </Paper>
  );
};

export default InvestorCard;
