import { EDIT_MODE, RESET_EDIT_MODE } from "../actions/userInvertorEdit";

export interface IEditInitialState {
  _id: string | number | null;
}

const initialState: IEditInitialState = {
  _id: null,
};

interface EditModeAction {
  type: typeof EDIT_MODE;
  payload: IEditInitialState;
}

interface ResetEditModeAction {
  type: typeof RESET_EDIT_MODE;
}

type Action = EditModeAction | ResetEditModeAction;

export const UserInvertorEditReducer = (
  state = initialState,
  action: Action,
) => {
  switch (action.type) {
    case EDIT_MODE:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_EDIT_MODE:
      return initialState;
    default:
      return state;
  }
};
