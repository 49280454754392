import UploadFileIcon from "@mui/icons-material/UploadFile";
import { InputAdornment, IconButton } from "@mui/material";
import React, { useRef } from "react";

import { displayToast } from "../../../utils/helpers/displayToast";
import TextInput from "../TextInput";

interface IInputFile {
  onChange: (value: any[] | any) => void;
  label: string;
  value: any;
  accept: ("image/png" | "image/gif" | "image/jpeg" | ".pdf")[];
  errorMessage?: string;
  ref?: any;
  limitMB?: number;
}

const InputFile: React.FC<IInputFile> = React.forwardRef<
  HTMLInputElement,
  IInputFile
>((props, ref) => {
  const { label, onChange, accept, errorMessage, value, limitMB } = props;
  const accepts = accept.join(", ");

  function handleChangeFile(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length) {
      if (limitMB && event.target.files[0].size / 1024 / 1024 > limitMB) {
        displayToast(
          "error",
          `El archivo es demasiado pesado. El peso límite es de ${limitMB}MB.`,
        );
        return;
      }
      onChange(event.target.files[0]);
    }
  }

  const localRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <input
        ref={ref ? (e) => ((ref as any).current = e) : localRef}
        type="file"
        hidden
        accept={accepts}
        onChange={handleChangeFile}
      />
      <TextInput
        value={value?.name}
        ref={ref}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          sx: {
            height: "48px",
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() =>
                  ref
                    ? (ref as any).current.click()
                    : (localRef as any).current.click()
                }
                edge="end"
              >
                <UploadFileIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        label={label}
        fullWidth
        error={Boolean(errorMessage)}
        helperText={errorMessage && errorMessage}
      />
    </>
  );
});

export default InputFile;
