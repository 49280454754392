import { ERolesCode } from "../../../../../routes/role-catalog";

export enum EFileTypes {
  safe = "safe",
  sisa = "sisa",
  dueDiligence = "dueDiligence",
  depositSlip = "depositSlip",
  reports = "reports",
}
export enum EFileTypesMember {
  payments = "payments",
  accessionAgreement = "accessionAgreement",
  proofOfAddress = "proofOfAddress",
  officialIdentification = "officialIdentification",
  others = "others",
}

export enum EFileTypesMemberAdmin {
  payments = "payments",
  accountStatements = "accountStatements",
  accessionAgreement = "accessionAgreement",
  proofOfAddress = "proofOfAddress",
  officialIdentification = "officialIdentification",
  others = "others",
}
export const VALID_TYPE_FILE_TO_UPLOAD_WITH_USER_MEMBER = [
  EFileTypesMember.accessionAgreement,
  EFileTypesMemberAdmin.accountStatements,
  EFileTypesMember.officialIdentification,
  EFileTypesMember.proofOfAddress,
  EFileTypesMember.others,
];
export const VALID_TYPE_FILE_TO_UPLOAD_WITH_USER = [
  EFileTypes.depositSlip,
  EFileTypes.sisa,
];

export const VALID_TYPE_TO_UPLOAD_STATEMENS = [
  ERolesCode.ADMINISTRATOR,
  ERolesCode.SUPER_ADMIN,
];

export const ROLES_TO_HIDE_DATA = [ERolesCode.STARTUP];

export const ROLES_ONLY_TO_DOWNLOAD_FILES = [
  ERolesCode.MEMBER,
  ERolesCode.STARTUP,
];

export const ITEM_VALUE_STARTUP = {
  name: "Startup",
  value: "",
};
