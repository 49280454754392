export const styles = {
  cardContainer: {
    height: "396px",
    maxWidth: "296px",
    borderRadius: "4px",
    padding: "0px !important",
    margin: "10px",
  },
  cardTitle: {
    fontWeight: "900",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#515151",
    margin: "40px auto",
  },
  cardTitleComp: {
    fontWeight: "900",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#515151",
    margin: "10px auto",
  },
  cardTitleSisa: {
    fontWeight: "900",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#515151",
    margin: "24px auto",
  },
  fileName: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#909090",
    margin: "auto",
  },
  fileType: {
    fontWeight: "900",
    fontSize: "24px",
    color: "#515151",
  },
  updateDate: {
    fontWeight: "300",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#515151",
    margin: "10px auto",
  },
  cardImage: { height: "120px", maxWidth: "41%", margin: "0 auto" },

  deleteButton: { color: "#E3263E", fontSize: "12px", padding: "0px 15px" },
  downloadButton: { color: "#4A88C2", fontSize: "12px", padding: "0px 15px" },
  mobileCardContainer: {
    maxWidth: "296px",
    padding: "0px !important",
  },
  mobileImageContainer: {
    height: "98px",
    width: "96px",
    backgroundColor: "#F8F8F8",
  },
  mobileCardImage: {
    height: "68px",
    width: "66px",
    margin: "20px auto",
  },
  mobileCardContent: { padding: "0px !important" },
  mobileCardTitle: {
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "24px",
    margin: "15px 10px",
  },
  mobileCardTitleComp: {
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "24px",
    margin: "5px 10px",
  },
  mobileFileName: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    margin: "5px 10px",
  },
  mobileUpdateDate: {
    fontWeight: "300",
    fontSize: "10px",
    lineHeight: "14px",
    margin: "5px 10px",
  },
  inversionista: {
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#909090",
  },
};
