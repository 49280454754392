import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

const getNewAccessToken = async (
  refreshToken: string,
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: '/refresh-token',
    method: 'POST',
    data: { refreshToken },
  };

  return axios(config);
};

export { getNewAccessToken };
