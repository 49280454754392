import { Info } from "@mui/icons-material";
import {
  Button,
  Divider,
  FormHelperText,
  Grid,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import PhoneInput, { CountryData } from "react-phone-input-2";

import { TextInput } from "../../../../../components/Inputs";
import InputFile from "../../../../../components/Inputs/InputFile/InputFile";
import { useLoading } from "../../../../../hooks/useLoading";
import {
  openModal,
  PayloadOpenModal,
} from "../../../../../redux/actions/modal";
import { useDispatch, useSelector } from "../../../../../redux/typedHooks";
import { updateOpportunityApplicationMainCard } from "../../../../../services/opportunitiesApplication";
import countries from "../../../../../utils/countries.json";
import { PHONE_MESSAGE } from "../../../../../utils/text/resetForm";
import { translateStatusOpportunities } from "../../../../../utils/text/translates";
import {
  COMO_TE_ENTERASTE_OPTIONS,
  COMPANY_TYPE_OPTIONS,
  INDUSTRY_OPTIONS,
  STAGE_OPTIONS,
  WOMAN_OPTIONS,
} from "../../../../StartupRegister/constants";
import { ERolesCode } from "../../../../routes/role-catalog";
import mainStyles from "../../../styles";
import { styles } from "../styles";
import { IMainCardForm } from "../types";

import EditBoardItem from "./EditBoardItem/EditBoardItem";
import editModeStyles from "./styles";

interface IMainCardEditMode {
  hook: UseFormReturn<IMainCardForm>;
  changeMode: () => void;
  onReload: () => void;
  id: string;
}

export const EditMode: FC<IMainCardEditMode> = ({
  id,
  hook,
  onReload,
  changeMode,
}) => {
  const { handleClose, handleOpen } = useLoading();
  const dispatch = useDispatch();
  const breakpoint = useMediaQuery("(max-width: 960px)");
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  function handleCancel() {
    onReload();
    hook.reset();
    changeMode();
  }

  const isAdmin = [
    ERolesCode.ADMINISTRATOR,
    ERolesCode.PARTNER,
    ERolesCode.SUPER_ADMIN,
    ERolesCode.ANALYST,
  ].includes(user.role.code);

  async function onSubmit(data: IMainCardForm) {
    handleOpen();
    const copyData = { ...data };
    copyData.phoneNumber = data.phoneNumber.replace(data.phoneCountryCode, "");

    const formdata = new FormData();
    copyData.deck && formdata.append("deck", copyData.deck as any);
    copyData.logo && formdata.append("logo", copyData.logo as any);

    try {
      const promises = [updateOpportunityApplicationMainCard(id, copyData)];
      if (copyData.deck || copyData.logo) {
        promises.push(updateOpportunityApplicationMainCard(id, formdata));
      }
      await Promise.all(promises);
      handleSuccessModal();
      hook.reset();
    } catch (error) {
      handleErrorModal();
    } finally {
      changeMode();
      onReload();
      handleClose();
    }
  }
  function handleSuccessModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Correcto",
      description: `Startup actualizada satisfactoriamente`,
      action: () => changeMode(),
      hideSecondaryButton: true,
      primaryButtonText: "Aceptar",
    };
    dispatch(openModal(modalConfig));
  }
  function handleErrorModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Error",
      description: `Hubo un error al actualizar startup`,
      hideSecondaryButton: true,
      primaryButtonText: "Aceptar",
    };
    dispatch(openModal(modalConfig));
  }

  return (
    <>
      <Grid item xs={12}>
        <Stack
          direction={breakpoint ? "column" : "row"}
          justifyContent="space-between"
          marginBottom="18px"
          spacing={2}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Typography sx={{ ...styles.textStatus, marginTop: 0 }}>
              Estatus:
            </Typography>
            <Typography sx={styles.textOpenInversion} textAlign={"center"}>
              {translateStatusOpportunities(hook.watch("status"))}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Button variant="outlined" onClick={handleCancel}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              sx={{ ...mainStyles.actionsButtons, width: "auto" }}
              onClick={hook.handleSubmit(onSubmit)}
            >
              Guardar
            </Button>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Grid container xs={12} rowGap={2}>
          <Grid
            container
            item
            xs={12}
            md={4}
            sx={{ ...editModeStyles.startGridObject }}
            rowGap={breakpoint ? 2 : 0}
          >
            <Grid item xs={12} md={6} sx={editModeStyles.startGridObject}>
              <Controller
                control={hook.control}
                name="logo"
                render={({ field, fieldState }) => (
                  <InputFile
                    ref={field.ref}
                    value={field.value}
                    label="Subir foto"
                    onChange={field.onChange}
                    errorMessage={fieldState.error?.message}
                    accept={["image/gif", "image/gif", "image/jpeg"]}
                  />
                )}
              />
            </Grid>
            {isAdmin && (
              <Grid item xs={12} md={6} sx={editModeStyles.endGridObject}>
                <Controller
                  control={hook.control}
                  name="womanFounding"
                  render={({ field, fieldState }) => (
                    <TextInput
                      label="Mujer en el founding team"
                      required
                      {...field}
                      fullWidth
                      error={Boolean(fieldState.error)}
                      helperText={
                        Boolean(fieldState.error?.message) &&
                        fieldState.error?.message
                      }
                      select
                    >
                      {WOMAN_OPTIONS.map((i, index) => (
                        <MenuItem value={i.value} key={index}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </TextInput>
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6} sx={editModeStyles.startGridObject}>
              <Controller
                control={hook.control}
                name="startupName"
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    label="Nombre de la startup"
                    required
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} sx={editModeStyles.endGridObject}>
              <Controller
                control={hook.control}
                name="industry"
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    label="Industria"
                    required
                    select
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                    fullWidth
                  >
                    {INDUSTRY_OPTIONS.map((i, index) => (
                      <MenuItem value={i} key={index}>
                        {i}
                      </MenuItem>
                    ))}
                  </TextInput>
                )}
              />
            </Grid>
          </Grid>
          <Grid xs={12} md={6} rowGap={2} sx={editModeStyles.endGridObject}>
            <Grid item xs={12} md={8} sx={editModeStyles.startGridObject}>
              <Controller
                control={hook.control}
                name="oneliner"
                render={({ field, fieldState }) => (
                  <TextInput
                    multiline
                    minRows={5}
                    label="One liner"
                    required
                    {...field}
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={
                      fieldState.error?.message ? (
                        fieldState.error?.message
                      ) : (
                        <Stack direction="row" alignItems="center">
                          <Info fontSize="inherit" />
                          <Typography marginLeft="0.3rem">
                            Puedes escribir hasta 450 caracteres
                          </Typography>
                        </Stack>
                      )
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography sx={{ ...styles.subtitle }}>
          Acerca de la Startup
        </Typography>
        <Grid container xs={12} columnGap={2} spacing={{ xs: 2 }}>
          <Grid container item xs={12} md={6} columnSpacing={2} rowSpacing={1}>
            <Grid item xs={12} md={4}>
              <Controller
                control={hook.control}
                name="stage"
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    label="Etapa de inversión"
                    required
                    select
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                    fullWidth
                  >
                    {STAGE_OPTIONS.map((i, index) => (
                      <MenuItem value={i} key={index}>
                        {i}
                      </MenuItem>
                    ))}
                  </TextInput>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={hook.control}
                name="website"
                render={({ field, fieldState }) => (
                  <TextInput
                    label="Sitio web"
                    required
                    {...field}
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={hook.control}
                name="companyType"
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    label="Tipo de empresa"
                    required
                    select
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                    fullWidth
                  >
                    {COMPANY_TYPE_OPTIONS.map((i, index) => (
                      <MenuItem value={i} key={index}>
                        {i}
                      </MenuItem>
                    ))}
                  </TextInput>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={hook.control}
                name="country"
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    label="País"
                    required
                    select
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                    fullWidth
                  >
                    {countries.map((i, index) => (
                      <MenuItem value={i.name_es} key={index}>
                        {i.name_es}
                      </MenuItem>
                    ))}
                  </TextInput>
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={hook.control}
                name="howFindAngelHub"
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    label="¿Cómo llegó a AngelHub?"
                    required
                    select
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                    fullWidth
                  >
                    {COMO_TE_ENTERASTE_OPTIONS.map((i, index) => (
                      <MenuItem value={i} key={index}>
                        {i}
                      </MenuItem>
                    ))}
                  </TextInput>
                )}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={5} spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                control={hook.control}
                name="solvingProblem"
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    multiline
                    minRows={7}
                    label="¿Qué problema resuelves?"
                    required
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={
                      fieldState.error?.message ? (
                        fieldState.error?.message
                      ) : (
                        <Stack direction="row" alignItems="center">
                          <Info fontSize="inherit" />
                          <Typography marginLeft="0.3rem">
                            Puedes escribir hasta 450 caracteres
                          </Typography>
                        </Stack>
                      )
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={hook.control}
                name="uniqueValueProposal"
                render={({ field, fieldState }) => (
                  <TextInput
                    multiline
                    minRows={7}
                    label="¿Cuál es tu propuesta de valor?"
                    required
                    {...field}
                    fullWidth
                    error={Boolean(fieldState.error)}
                    helperText={
                      fieldState.error?.message ? (
                        fieldState.error?.message
                      ) : (
                        <Stack direction="row" alignItems="center">
                          <Info fontSize="inherit" />
                          <Typography marginLeft="0.3rem">
                            Puedes escribir hasta 450 caracteres
                          </Typography>
                        </Stack>
                      )
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ ...styles.divider }} />
        <Typography sx={{ ...styles.subtitle, marginY: "1rem" }}>
          Contacto
        </Typography>
        <Grid
          container
          xs={12}
          rowGap={breakpoint ? 2 : 0}
          sx={editModeStyles.startGridObject}
        >
          <Grid item xs={12} md={3} sx={editModeStyles.startGridObject}>
            <Controller
              control={hook.control}
              name="ownerName"
              render={({ field, fieldState }) => (
                <TextInput
                  label="Nombre"
                  required
                  {...field}
                  fullWidth
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={editModeStyles.startGridObject}>
            <Controller
              control={hook.control}
              name="ownerLastName"
              render={({ field, fieldState }) => (
                <TextInput
                  label="Apellido"
                  required
                  {...field}
                  fullWidth
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={editModeStyles.startGridObject}>
            <Controller
              control={hook.control}
              name="email"
              render={({ field, fieldState }) => (
                <TextInput
                  label="Email"
                  type="email"
                  required
                  {...field}
                  fullWidth
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={editModeStyles.middleGridObject}>
            <>
              <Controller
                control={hook.control}
                name="phoneNumber"
                render={({ field, fieldState, formState }) => (
                  <>
                    <PhoneInput
                      country="mx"
                      value={field.value}
                      onChange={(phone: string, data: CountryData) => {
                        const number = phone.replace(data.dialCode, "");
                        const reg = `^[0-9]{10}$`;
                        const exp = new RegExp(reg);
                        const isValid = exp.test(number);
                        if (!isValid) {
                          hook.setError("phoneNumber", {
                            message: PHONE_MESSAGE,
                          });
                        } else {
                          hook.setValue("phoneCountryCode", data.dialCode);
                          field.onChange(phone);
                        }
                      }}
                      buttonStyle={
                        fieldState.error && { border: "2px solid #f44336" }
                      }
                      inputStyle={
                        fieldState.error
                          ? {
                              border: "2px solid #f44336",
                              height: "48px",
                              width: "100%",
                            }
                          : { height: "48px", width: "100%" }
                      }
                    />
                    {Boolean(formState.errors?.phoneNumber) && (
                      <FormHelperText
                        style={{ margin: "3px 14px 0px 14px" }}
                        error={Boolean(formState.errors?.phoneNumber)}
                      >
                        {formState.errors?.phoneNumber?.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </>
          </Grid>
        </Grid>
        <Divider sx={{ ...styles.divider }} />
        <Typography sx={{ ...styles.subtitle, marginY: "1rem" }}>
          Equipo Fundador
        </Typography>
        <Grid container xs={12} spacing={breakpoint ? 0 : 2}>
          {hook.watch("founders").map((_, index) => (
            <Grid key={index} item xs={12} md={3}>
              <EditBoardItem
                index={index}
                hook={hook}
                title={`Usuario ${index + 1}`}
              />
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ ...styles.divider }} />
        <Typography sx={{ ...styles.subtitle, marginY: "1rem" }}>
          Deck y pitch
        </Typography>
        <Grid container xs={12} spacing={breakpoint ? 0 : 2}>
          <Grid item xs={12} md={3} sx={editModeStyles.startGridObject}>
            <Controller
              control={hook.control}
              name="deck"
              render={({ field, fieldState }) => (
                <InputFile
                  ref={field.ref}
                  value={field.value}
                  limitMB={25}
                  label="Deck"
                  onChange={field.onChange}
                  errorMessage={fieldState.error?.message}
                  accept={[".pdf"]}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={editModeStyles.startGridObject}>
            <Controller
              control={hook.control}
              name="pitch"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  label="Video pitch"
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
