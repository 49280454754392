import * as yup from "yup";

export const itemFormSchema = yup.object({
  comments: yup.array().of(
    yup.object({
      comment: yup.string(),
    }),
  ),
  evaluations: yup.object({
    analyst: yup.object({
      evaluations: yup.array().of(
        yup.object({
          score: yup.string().nullable(true),
          comment: yup.string().max(250, "Campo máximo de 250 caracteres."),
        }),
      ),
    }),
    partner: yup.object({
      evaluations: yup.array().of(
        yup.object({
          score: yup
            .string()
            .nullable(false)
            .typeError("Selecciona algún valor"),
          comment: yup.string().max(250, "Campo máximo de 250 caracteres."),
        }),
      ),
    }),
  }),
});
