import { TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import React from "react";

import { ETabsMember } from "../../../constants";

interface IProps {
  handleChangeTab: (_: React.SyntheticEvent, newValue: string) => void;
}

const Tabs: React.FC<IProps> = ({ handleChangeTab }) => {
  const stylesTabs = {
    color: "#0000005D",
    minWidth: "150px",
    minHeight: "48px",
  } as const;

  return (
    <TabList
      sx={{ gap: "8px" }}
      scrollButtons
      variant="scrollable"
      allowScrollButtonsMobile
      onChange={handleChangeTab}
    >
      <Tab sx={stylesTabs} label="TODAS" value="" />
      <Tab sx={stylesTabs} label="Clásica" value={ETabsMember.CLASSICAL} />
      <Tab sx={stylesTabs} label="Embajador" value={ETabsMember.AMBASSADOR} />
      <Tab sx={stylesTabs} label="Aliado" value={ETabsMember.ALLIES} />
      <Tab sx={stylesTabs} label="Lead Angel" value={ETabsMember.LEADANGEL} />
    </TabList>
  );
};

export default Tabs;
