import { StylesObject } from "../../styles";

export const styles: StylesObject = {
  linkedinIcon: {
    color: "#4A88C2",
  },
  linkedinLink: {
    color: "#4A88C2",
    textDecoration: "none",
    fontSize: "12px",
    fontWeight: "900",
  },
  text: {
    fontFamily: "Product Sans Light !important",
    fontWeight: "300 !important",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#515151",
    marginLeft: "3px",
  },
  label: {
    fontWeight: "400",
    marginTop: "0.2rem",
    marginRight: "0.3rem",
    fontSize: "16px",
    marginLeft: "3px",
  },
};
