import React from "react";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";

import OpportunitiesReleasedList from "./OpportunitiesReleasedList";

const OpportunityReleased: React.FC = () => {
  return (
    <>
      <PageTitle title="Lista de Oportunidades" />
      <MainLayout sx={{ flexDirection: "column", justifyContent: "start" }}>
        <OpportunitiesReleasedList />
      </MainLayout>
    </>
  );
};

export default OpportunityReleased;
