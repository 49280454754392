import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

import LibraryImg from "../../assets/images/library-resource-image.svg";
import { useSelector } from "../../redux/typedHooks";
import { getResourceLibraryById } from "../../services/dashboard";
import {
  changeStatusResourceLibraryLikes,
  getResourceLibraryLikes,
} from "../../services/resourceLibraryLikes";

import FileAttached from "./Components/FileAttached";
import Tags from "./Components/Tags";
import { ResourceLibraryStyles } from "./styles";
import { IResourceLibrary, IResourceLibraryDetailProps } from "./type";

const ResourceLibraryDetail: React.FC<IResourceLibraryDetailProps> = ({
  id,
}) => {
  const [state, setState] = useState<IResourceLibrary | null>(null);
  const [isLiked, setIsLiked] = useState<boolean | null>(null);
  const [reload, setReload] = useState<boolean | null>(null);
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  const userId = user?._id;

  useEffect(() => {
    getResourceLibraryById(id).then(({ data }) => {
      setState(data);
    });
  }, [id]);

  useEffect(() => {
    if (userId) {
      getResourceLibraryLikes(userId).then(({ data }) => {
        setIsLiked(data.value);
      });
    }
  }, [userId, reload]);

  const handleChangeStatusLiked = (value: boolean) => async () => {
    if (userId) {
      changeStatusResourceLibraryLikes({
        resourceLibraryId: id,
        userId,
        value,
      }).then(() => {
        setReload(!reload);
      });
    }
  };

  if (!state) {
    return (
      <Box
        width="100%"
        minHeight={"100vh"}
        display="flex"
        alignItems={"center"}
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }

  let categories: any = [];
  state.categoria.length ? (categories = state.categoria) : (categories = []);

  const isLikedIcon =
    isLiked === false && isLiked !== null ? (
      <ThumbUpOffAltIcon />
    ) : (
      <ThumbUpAltIcon />
    );

  const isDislikedIcon =
    isLiked === false && isLiked !== null ? (
      <ThumbDownAltIcon />
    ) : (
      <ThumbDownOffAltOutlinedIcon />
    );

  return (
    <Paper sx={ResourceLibraryStyles}>
      <Box className="main-container">
        <Grid container item xs={12}>
          <Grid
            item
            xs={12}
            md={7}
            className="title-secction"
            order={{ xs: 1, md: 0 }}
          >
            <Typography className="title">{state?.titulo}</Typography>
            <Box className="sub-title">
              <Typography>{state.createdBy}</Typography>
              <Box sx={{ color: "#74C24A", fontSize: "19px" }}>•</Box>
              <Typography className="date">{`Publicado: ${dayjs(
                state.publishedAt || new Date(),
              ).format("D MMMM YYYY")}`}</Typography>
            </Box>
            <Box className="chip-container">
              {categories.map((i: any) => (
                <Tags key={i} title={i} />
              ))}
            </Box>
            {state.file.length > 0 ? (
              <Box className="sub-title_2">
                <Typography>Archivos adjuntos</Typography>
                {state.file.map((i, key) => (
                  <FileAttached key={key} title={i.name} url={i.url} />
                ))}
              </Box>
            ) : (
              <Box className="sub-title_2">
                <Typography sx={{ textDecoration: "none" }}>
                  Sin archivos adjuntos
                </Typography>
              </Box>
            )}
            <Box className="sub-title_2">
              <Typography>¿Te gusta este posteo?</Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  sx={{ color: "#4A88C2" }}
                  startIcon={isLikedIcon}
                  onClick={handleChangeStatusLiked(true)}
                >
                  Si
                </Button>
                <Button
                  sx={{ color: "#4A88C2" }}
                  startIcon={isDislikedIcon}
                  onClick={handleChangeStatusLiked(false)}
                >
                  No
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item md={5} xs={12} order={{ xs: 0, md: 1 }}>
            <img
              src={state.foto.url || LibraryImg}
              alt={state.foto.name}
              className="img-header"
            />
          </Grid>
        </Grid>
        <Box
          className="styledContent"
          dangerouslySetInnerHTML={{ __html: state.publicacion }}
          style={{
            fontSize: "16px",
            color: "#909090",
          }}
        ></Box>
      </Box>
    </Paper>
  );
};

export default ResourceLibraryDetail;
