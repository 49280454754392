import {
  CHANGE_APPLICATIONS_VALUE,
  CHANGE_APPLICATION_VALUE,
  CHANGE_LIMIT_APPLICATIONS,
  CHANGE_PAGE_APPLICATIONS,
  CLEAR_REDUCER_APPLICATIONS,
  FETCH_APPLICATIONS_PAGINATED,
  LOADING_APPLICATION,
} from "../actions/applications";

export interface ILoggedApplicationReducer {
  applications: any[];
  page: number;
  limit: number;
  loading: boolean;
  totalCount: number;
  application: string;
}

const initialState: ILoggedApplicationReducer = {
  applications: [],
  page: 0,
  limit: 10,
  loading: true,
  totalCount: 100,
  application: "",
};

export default function ApplicationReducer(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_APPLICATIONS_PAGINATED:
      return {
        ...state,
        applications: action.payload,
      };
    case CHANGE_PAGE_APPLICATIONS:
      return {
        ...state,
        page: action.payload,
      };
    case CHANGE_LIMIT_APPLICATIONS:
      return {
        ...state,
        limit: action.payload,
        page: 0,
      };
    case LOADING_APPLICATION:
      return {
        ...state,
        loading: action.payload,
      };
    case CHANGE_APPLICATION_VALUE:
      return {
        ...state,
        application: action.payload,
      };
    case CHANGE_APPLICATIONS_VALUE:
      return {
        ...state,
        applications: action.payload,
      };
    case CLEAR_REDUCER_APPLICATIONS:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
