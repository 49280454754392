import { Paper, Typography, Chip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";

import { translateStatusStartups } from "../../../utils/text/translates";

interface IWelcome {
  startupId: string;
  startupName: string;
  startupStatus: string;
}

const Welcome: React.FC<IWelcome> = ({
  startupId,
  startupName,
  startupStatus,
}) => {
  return (
    <Paper className="YSpace welcomeContainer">
      <Box
        display={"flex"}
        sx={{
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
        }}
      >
        <Typography className="welcomeTitle">Bienvenido de Nuevo</Typography>
        <Typography className="welcomeStartupName">
          <Link to={`/details-startup/${startupId}`}>{startupName}</Link>
        </Typography>
      </Box>
      <Box
        display={"flex"}
        sx={{
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            color: "#757575",
            fontFamily: "Product Sans Light !important",
            fontStyle: "normal",
            fontWeight: "300",
            lineHeight: "28px",
          }}
        >
          Estatus
        </Typography>
        <Chip
          sx={{ height: "24px", background: "#74C24A" }}
          label={translateStatusStartups(startupStatus)}
        />
      </Box>
    </Paper>
  );
};

export default Welcome;
