import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { UseFormReturn } from "react-hook-form";

import TextEvaluation from "../TextEvaluation/TextEvaluation";
import ToggleButtonEvaluation from "../ToggleButtonEvaluation/ToggleButtonEvaluation";
import { EvaluationForm, TypeEvaluation } from "../consts";

import { styles } from "./styles";

interface IProps {
  text: string;
  isEditing?: boolean;
  hook: UseFormReturn<EvaluationForm>;
  typeEvaluation: TypeEvaluation;
  index: number;
}

const ItemForm: React.FC<IProps> = (props) => {
  const { text, isEditing, hook, index, typeEvaluation } = props;

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Stack spacing={2}>
        <Typography sx={styles.typography}>{text}</Typography>
        <ToggleButtonEvaluation
          hook={hook}
          index={index}
          typeEvaluation={typeEvaluation}
          isEditing={isEditing}
        />
        <Box
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            wordBreak: "break-all",
          }}
        >
          <TextEvaluation
            hook={hook}
            index={index}
            typeEvaluation={typeEvaluation}
            isEditing={isEditing}
          />
        </Box>
      </Stack>
    </Grid>
  );
};

export default ItemForm;
