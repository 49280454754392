import * as yup from "yup";

import { CAMPO_REQUERIDO, CORREO_NO_VALIDO } from "../../../utils/text/inputs";

const MIN_LENGTH_NAME = "Debe tener al menos 2 caracteres";
const ONLY_ALPHABETICAL_NAME = "Solo debe contener letras";
export const preMemberSchema = yup.object({
  email: yup.string().email(CORREO_NO_VALIDO).required(CAMPO_REQUERIDO),
  firstName: yup
    .string()
    .trim()
    .min(2, MIN_LENGTH_NAME)
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, ONLY_ALPHABETICAL_NAME)
    .required(CAMPO_REQUERIDO),
  lastName: yup
    .string()
    .trim()
    .min(2, MIN_LENGTH_NAME)
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/, ONLY_ALPHABETICAL_NAME)
    .required(CAMPO_REQUERIDO),
  phoneNumber: yup.string().required(CAMPO_REQUERIDO),
  nationalityCountry: yup
    .string()
    .nullable(false)
    .typeError(CAMPO_REQUERIDO)
    .required(CAMPO_REQUERIDO),
  residenceCountry: yup
    .string()
    .nullable(false)
    .typeError(CAMPO_REQUERIDO)
    .required(CAMPO_REQUERIDO),
  residenceCity: yup
    .string()
    .trim()
    .min(2, MIN_LENGTH_NAME)
    .required(CAMPO_REQUERIDO),
});
