import { SxProps } from "@mui/material";
import moment from "moment";
import { useRef, useState } from "react";

import SplitButton from "../../../../../../../components/SplitButton/SplitButton";
import { useLoading } from "../../../../../../../hooks/useLoading";
import { addFileStartup } from "../../../../../../../services/startup";
import { displayToast } from "../../../../../../../utils/helpers/displayToast";
import DialogUser from "../DialogUser/DialogUser";
import { BuildFileTypeText, extensiosImage } from "../FileCard/FileCard";
import {
  EFileTypes,
  VALID_TYPE_FILE_TO_UPLOAD_WITH_USER,
} from "../validations";

interface IProps {
  xs: SxProps;
  setReload: () => void;
  users: any[];
  id: string;
  isMobile?: boolean;
  icon?: any;
}

const ButtonAddFile: React.FC<IProps> = ({
  setReload,
  xs,
  children,
  users,
  id,
  icon,
  isMobile,
}) => {
  const [selectedType, setSelectedType] = useState<EFileTypes | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  const [fileUploadDialog, setFileUploadDialog] = useState<any>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { handleClose: handleCloseLoading, handleOpen } = useLoading();

  const handleCloseModal = () => {
    (ref.current as any).value = "";
    setOpenModal(false);
    setSelectedType(null);
    setFileUploadDialog(null);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  function handleSelection(fileType: EFileTypes) {
    setSelectedType(fileType);
    ref.current?.click();
  }

  async function handleAddFile(
    file: File,
    userId?: string,
    isStartupSlip?: boolean,
  ) {
    handleOpen();
    const form = new FormData();
    if (userId) {
      form.append("userId", userId);
    }
    form.append("file", file);
    form.append("date", moment().toString());
    form.append("startupId", id);
    form.append(
      "type",
      isStartupSlip ? EFileTypes.depositStartup : (selectedType as EFileTypes),
    );
    try {
      await addFileStartup(form);
      displayToast("success", "Se subió correctamente");
    } catch (error) {
      displayToast("success", "Hubo un error al subir el archivo.");
    } finally {
      setSelectedType(null);
      setFileUploadDialog(null);
      setReload();
      (ref.current as any).value = "";
      handleCloseLoading();
    }
  }

  async function handleChangeFile(event: React.ChangeEvent<HTMLInputElement>) {
    if (
      event.target.files?.length &&
      event.target.files[0].size > 25 * 1024 * 1024
    ) {
      displayToast(
        "error",
        "El archivo es demasiado pesado. El peso límite es de 25MB.",
      );
      return;
    }

    if (
      event.target.files?.length &&
      !VALID_TYPE_FILE_TO_UPLOAD_WITH_USER.includes(selectedType as EFileTypes)
    ) {
      const file = event.target.files[0];
      handleAddFile(file);
      setSelectedType(null);
    }
    if (
      event.target.files?.length &&
      VALID_TYPE_FILE_TO_UPLOAD_WITH_USER.includes(selectedType as EFileTypes)
    ) {
      const file = event.target.files[0];
      handleOpenModal();
      setFileUploadDialog(file);
    }
  }

  return (
    <>
      <input
        hidden
        ref={ref}
        type="file"
        accept={extensiosImage.join(",")}
        multiple
        onChange={handleChangeFile}
      />
      <SplitButton
        placement="left-start"
        title="Subir Archivo"
        isMobile={isMobile}
        icon={icon}
        options={Object.values(EFileTypes)
          .filter((i) => i !== EFileTypes.reports)
          .map((value) => ({
            label: BuildFileTypeText(value) || "Archivo desconocido",
            visible: true,
            onClick: () => handleSelection(value),
          }))}
      />
      <DialogUser
        open={openModal}
        onAccept={handleAddFile}
        onCancel={handleCloseModal}
        users={users}
        file={fileUploadDialog}
      />
    </>
  );
};

export default ButtonAddFile;
