import { List, ListItem, Typography } from "@material-ui/core";
import { Box, useMediaQuery } from "@mui/material";

import { styles } from "./styles";

export const AdhesionContract = () => {
  const isMovil = useMediaQuery("(max-width:623px)");

  return (
    <Box sx={styles}>
      <Box className="adhesion-contract-header">
        <img
          src={`https://user-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_630,w_1200,f_auto,q_auto/1375226/605640_618378.png`}
          alt={"xd"}
          loading="lazy"
          style={{ height: "30px", objectFit: "contain", marginTop: "10px" }}
        />
        <Box>
          <Box className="header-info">
            <span>Angel Hub Ventures SAPI de CV</span> <br />
            <span style={{ margin: "0 0 10px 0" }}>angelhub.mx</span> <br />
            <span>club@angelhub.mx</span>
          </Box>
        </Box>
      </Box>
      <Typography>
        CONVENIO DE ADHESIÓN (EL <u>“CONVENIO”</u>) QUE CELEBRAN POR UNA PARTE
        ANGEL HUB VENTURES,LLC (“AHV”), REPRESENTADA EN ESTE ACTO POR MARIO
        ALBERTO GARCÍA DÁVILA, Y POR LA OTRA EL INVERSIONISTA, POR SUS PROPIOS
        DERECHOS (EN LO SUCESIVO AHV Y (<u>EL ÁNGEL INVERSIONISTA</u>) REFERIDOS
        INDIVIDUALMENTE COMO UNA “PARTE” Y CONJUNTAMENTE COMO LAS “PARTES”),
        CONFORME A LAS SIGUIENTES DECLARACIONES Y CLÁUSULAS:
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        <p>DECLARACIONES:</p>
      </div>
      <List>
        <ListItem
          style={{
            fontWeight: "bold",
          }}
        >
          <Typography>
            I. Declara AHV, a través de su representante legal, que:
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            i. Es una Limited Liability Company (LLC), debidamente constituida y
            válidamente existente conforme a las leyes de Delaware, Estados
            Unidos;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            ii. Su objeto social contempla las operaciones dispuestas en el
            presente Convenio, y se encuentra facultada para cumplir con sus
            obligaciones derivadas del presente instrumento;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            iii. Su representante legal cuenta con las facultades legales
            suficientes para la celebración de este Convenio en su nombre y
            representación, mismas que no le han sido revocadas, modificadas o
            limitadas en forma alguna;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            iv. Cuenta con los recursos técnicos propios y el conocimiento
            especializado sobre el ecosistema emprendedor de América Latina en
            actividades relacionadas con aceleradoras, organismos
            pro-emprendimientos y en fondos de inversión, así como como sobre
            las distintas fuentes de financiación para inversiones en negocios
            en etapa temprana, incluyendo familiares y amigos del emprendedor
            (FFF), ángeles inversionistas, capital semilla y fondos de capital
            privado; y v. Es su intención celebrar el presente Convenio con el
            Ángel Inversionista, a efecto de formalizar la incorporación del
            Ángel Inversionista al Club de Inversionistas de AHV, conforme a los
            términos y condiciones dispuestos en el presente instrumento.
          </Typography>
        </ListItem>
        <ListItem
          className="title-listItem"
          style={{
            fontWeight: "bold",
          }}
        >
          <Typography>
            II. Declara el Ángel Inversionista, por sus propios derechos, que:
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            i. Es una persona física, mayor de edad, y cuenta con las facultades
            necesarias para celebrar este Convenio y obligarse en los términos
            del mismo;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            ii. Tiene la capacidad financiera para cumplir con lo previsto en
            este Convenio y realizar inversiones según lo establecido en el
            presente instrumento;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            iii. Reúne los requisitos para ser considerado como un Inversionista
            Calificado conforme al Artículo 2 fracción XVI de la Ley del Mercado
            de Valores. Además, tiene los conocimientos necesarios en materia
            financiera, así como la disposición para aceptar los altos riesgos y
            la falta de liquidez que conllevan las inversiones de esta
            naturaleza, y está preparado para asumir dichos riesgos por un
            periodo de tiempo prolongado;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            iv. Entiende y acepta que AHV no garantiza el retorno de los montos
            que aportará para las inversiones ni algún tipo de rendimiento o
            retorno con respecto a dichas aportaciones;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            v. Se basa en su propia evaluación sobre el objeto de este Convenio,
            cualquier prospecto de información que AHV le proporcione con
            relación al mismo, y los riesgos asociados;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            vi. Ha consultado con sus propios asesores sobre las consecuencias
            legales, fiscales, regulatorias, financieras y contables, así como
            de cualesquiera consecuencias relacionadas con la celebración de
            este Convenio y cualquier otro documento que sea necesario para
            celebrar en el futuro en relación con el objeto de este Convenio;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            vii. Es su intención celebrar el presente Convenio con AHV, a efecto
            de formalizar su incorporación al Club de Inversionistas de AHV,
            conforme a los términos y condiciones dispuestos en el presente
            instrumento.
          </Typography>
        </ListItem>
      </List>
      <ListItem>
        <Typography>
          En virtud de lo anterior, las Partes acuerdan lo siguiente:
        </Typography>
      </ListItem>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        <p>CLÁUSULAS:</p>
      </div>
      <List>
        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              PRIMERA. <u>OBJETO.</u>
            </strong>{" "}
            <Typography>
              Mediante la celebración del presente Convenio el Ángel
              Inversionista se adhiere al club de inversionistas de AHV (el
              “Club de Inversionistas de AHV”), conforme a las reglas y
              criterios establecidos en el presente instrumento para realizar
              inversiones en los proyectos presentados por AHV al Ángel
              Inversionista.
            </Typography>
          </p>
        </ListItem>
        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              SEGUNDA. <u>PROPÓSITO DEL CLUB DE INVERSIONISTAS DE AHV.</u>
            </strong>{" "}
            <Typography>
              El Ángel Inversionista acepta y reconoce que la finalidad del Club
              de Inversionistas de AHV es acelerar y fortalecer el ecosistema de
              emprendimiento en México, fomentando una cultura de inversión en
              empresas de etapa temprana, y procurando la conexión entre
              empresas de alto impacto con posibles ángeles inversionistas que
              estén dispuestos a aportarles recursos.
            </Typography>
          </p>
        </ListItem>
        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              TERCERA. <u>MEMBRESÍA.</u>
            </strong>
            <Typography>
              El Ángel Inversionista acepta que el costo anual de la membresía
              en el Club de Inversionistas de AHV es la cantidad total de
              $750.00 USD más el Impuesto al Valor Agregado correspondiente
              (Setecientos cincuenta, moneda nacional de los Estados Unidos).
              Dicha membresía anual será pagadera en una sola exhibición, y el
              Ángel Inversionista deberá pagarla mediante transferencia
              electrónica de fondos dentro de los 5 (cinco) días hábiles
              siguientes a la fecha de la celebración del presente Convenio.
              Dichos pagos se deberán realizar a la cuenta número 1091669545,
              aperturada a nombre de Angel Hub Ventures SAPI de CV en banco
              Grupo Financiero Banorte, clabe 072580010916695458.
            </Typography>
          </p>
        </ListItem>
        <ListItem>
          <Typography>
            Mediante el pago de la membresía otorgará al Ángel Inversionista
            acceso a todos los eventos organizados por AHV. Así mismo, dicho
            pago le permite conocer oportunidades de inversión en empresas de
            etapa temprana de alto impacto (“Startups”), y recibirá
            acompañamiento de parte de AHV en el proceso de formalizar
            inversiones en dichas Startups.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            El Ángel Inversionista acepta y reconoce que como miembro del Club
            de Inversionistas de AHV, deberá realizar por lo menos una inversión
            en Empresas Promovidas dicho término se define más adelante, por un
            monto mínimo de $10,000.00 (Diez mil dólares americanos 00/100) o
            dos inversiones en Empresas Promovidas por un mínimo monto de $5,000
            (Cinco mil dólares americanos) , durante cada periodo de 12 (doce)
            meses calendario, a partir de haberse adherido al Club de
            Inversionistas de AHV. En caso de no cumplir con dicho requerimiento
            mínimo, AHV se reserva el derecho de cancelar la membresía del Ángel
            Inversionista en el Club de Inversionistas AHV. Como consecuencia,
            el Ángel Inversionista perderá los derechos inherentes a la misma
            previstos en el presente Convenio, y además no podrá renovarla por
            un periodo de tiempo de al menos 2 (dos) años.
          </Typography>
        </ListItem>
        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              {" "}
              CUARTA. <u>MONTO DE INVERSIÓN.</u>{" "}
            </strong>
            <Typography>
              Las Partes acuerdan que el monto de inversión total en un proyecto
              presentado por AHV al Ángel Inversionista (“Empresa Promovida”)
              oscilará entre las cantidades de $25,000.00 USD (Veinticinco mil,
              moneda de los Estados Unidos ) y $250,000.00 USD(Doscientos
              cincuenta mil, moneda de los Estados Unidos).
            </Typography>
          </p>
        </ListItem>
        <ListItem>
          <Typography>
            No obstante, en el supuesto de que el Ángel Inversionista desee
            realizar una inversión superior al monto de los $25,000.00 USD
            (Veinticinco mil, moneda de los Estados Unidos ) en una Empresa
            Promovida, podrá hacerlo a su propio criterio y riesgo, y conforme a
            los términos de este Convenio.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            No obstante lo previsto, el Ángel Inversionista acepta y reconoce
            que AHV no garantiza ningún tipo de rendimiento y/o el retorno del
            capital aportado a las Empresas Promovidas por el Ángel
            Inversionista.
          </Typography>
        </ListItem>
        <ListItem>
          <strong
            style={{
              fontSize: isMovil ? "12px" : "",
            }}
          >
            QUINTA. <u>DERECHOS DEL ÁNGEL INVERSIONISTA.</u>
          </strong>
        </ListItem>
        <ListItem>
          <Typography>
            El Ángel Inversionista podrá decidir lo siguiente:
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            i. Los proyectos en los que desee invertir, entre aquellos proyectos
            que le fuesen presentados por AHV; y
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            ii. El monto de la inversión que desee aportar en cada proyecto.
          </Typography>
        </ListItem>
        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              SEXTA. <u>OBLIGACIONES DE AHV.</u>
            </strong>
            <Typography>
              Para dar cumplimiento al propósito del Club de Inversionistas, AHV
              deberá identificará Startups nacionales y/o extranjeras con alto
              potencial de crecimiento y de innovación con necesidades de
              capital, proporcionará una plataforma operativa con mejores
              prácticas para sus miembros para que de forma individual o
              sindicada, puedan realizar sus inversiones con mayor análisis y
              seguridad.
            </Typography>
          </p>
        </ListItem>
        <ListItem>
          <Typography>
            El Club de Inversionistas de AHV deberá llevará cabo las siguientes
            actividades (las <u> “Actividades del Club”</u>):
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            i. Reuniones mensuales en espacios físicos o en forma remota
            mediante el uso de tecnología;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            ii. Eventos mensuales de oportunidades de inversión (los “Members
            Meetings”);
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            iii. Crear y mantener un grupo de comunicación exclusivo para
            miembros;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            iv. Crear y mantener una plataforma digital con información
            detallada sobre las oportunidades de inversión;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            v. Contenido adecuado y suficiente para que los miembros del se
            puedan convertir en inversionistas ángeles profesionales;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            vi. Proveer el soporte adecuado para la ejecución de las
            inversiones, incluyendo sin limitar, evaluaciones de proyectos,
            valuaciones, procesos de revisión legal, vehículos de inversión,
            trámites legales, etc.;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            vii. AHV llevará a cabo los procesos de descubrimiento y filtrado de
            oportunidades de alto potencial, procurando que haya un flujo
            constante de oportunidades de inversión.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            Posterior a cada Members Meeting, AHV se obliga a apoyar tanto a sus
            miembros como a los emprendedores de Startups para formalizar
            inversiones.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            AHV será el responsable por la coordinación y ejecución de las
            Actividades del Club de Inversionistas AHV, así como por su
            operación general. Como tal, estará facultado para realizar las
            variaciones que considere convenientes a las Actividades del Club,
            ya sea en frecuencia, medios de comunicación, estructura o
            contenido, para procurar la mejor gestión del Club de Inversionistas
            AHV. Adicionalmente, AHV tendrá las siguientes responsabilidades:
          </Typography>{" "}
        </ListItem>
        <ListItem>
          <Typography>
            i. Operación general de las Actividades del Club;
          </Typography>{" "}
        </ListItem>
        <ListItem>
          <Typography>
            ii. Conseguir pipeline de oportunidades de inversión en Startups de
            calidad;
          </Typography>{" "}
        </ListItem>

        <ListItem>
          <Typography>
            iii. Operar la plataforma digital con la cual se gestionan las
            oportunidades de inversión;
          </Typography>{" "}
        </ListItem>
        <ListItem>
          <Typography>
            iv. Búsqueda, filtrado y selección de proyectos de alto impacto;
          </Typography>{" "}
        </ListItem>
        <ListItem>
          <Typography>
            v. Evaluación de oportunidades, incluyendo sin limitar, evaluación
            de proyecto, valuación financiera y revisión legal;
          </Typography>{" "}
        </ListItem>
        <ListItem>
          <Typography>
            vi. Negociar las inversiones y ejecutar los trámites y procesos
            legales para cerrar las inversiones; y
          </Typography>{" "}
        </ListItem>
        <ListItem>
          <Typography>
            vii. Dar seguimiento y acompañamiento a los proyectos en los que se
            realicen inversiones;
          </Typography>{" "}
        </ListItem>
        <ListItem>
          <Typography>
            viii. Vinculación de emprendimientos con mentores y expertos;
          </Typography>{" "}
        </ListItem>
        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              SÉPTIMA.<u> OBLIGACIONES ADICIONALES.</u>
            </strong>
            <Typography>
              Cada una de las Partes se obliga a llevar a cabo los actos que
              fueren necesarios o convenientes, incluyendo la celebración de
              contratos y otros instrumentos, para dar cumplimiento a este
              Convenio y cualquier otro documento necesario.
            </Typography>
          </p>
        </ListItem>
        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              OCTAVA. <u> EXCLUSIVIDAD. </u>
            </strong>
            <Typography>
              El Ángel Inversionista acepta y reconoce que la identificación,
              evaluación y vinculación de proyectos para realizar inversiones
              implica tiempo y recursos de AHV, por tal razón, todos los
              proyectos presentados por AHV al Ángel Inversionista son
              exclusivos y pertenecen a AHV. En virtud de esta exclusividad, el
              Ángel Inversionista se obliga a realizar inversiones en los
              proyectos presentados por AHV únicamente conforme a los términos y
              condiciones del presente Convenio y se obliga a abstenerse de
              ofrecer y/o realizar inversiones directas en dichos proyectos
              presentados.
            </Typography>
          </p>
        </ListItem>
        <ListItem>
          <Typography>
            El incumplimiento de parte del Ángel Inversionista de lo previsto en
            el párrafo anterior tendrá como consecuencia la exclusión inmediata
            del Ángel Inversionista del Club de Inversionista de AHV, y por
            consecuencia, la pérdida de su membresía. La presente cláusula
            sobrevivirá la terminación del Convenio.
          </Typography>
        </ListItem>

        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              NOVENA. <u> ESTRUCTURA DE INVERSIÓN. </u>
            </strong>
            <Typography>
              Las Partes acuerdan que AHV organizará y administrará cada una de
              las inversiones en las Empresas Promovidas de manera independiente
              y las inversiones podrán llevarse a cabo de la siguiente forma:
            </Typography>
          </p>
        </ListItem>
        <ListItem>
          <strong
            style={{
              fontSize: isMovil ? "12px" : "",
            }}
          >
            1. Financiamiento a través de capital (Equity).
          </strong>
        </ListItem>
        <ListItem>
          <Typography>
            a. La inversión a través de un financiamiento de capital se llevará
            a cabo mediante la participación del o de los Ángel(es)
            Inversionista(s) (que decida(n) financiar un proyecto, y AHV en una
            Limited Partnership (cada una de ellas el “Vehículo de Inversión”).
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            b. La inversión se realizará mediante la firma de un Simple
            Agreement for Future Equity (en adelante, el “Vehículo de Inversión”
            o "SAFE"), a través del cual el Ángel Inversionista podrá invertir
            en las Empresas Promovidas.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            c. De acuerdo con, y sujeto a, los términos, disposiciones y
            condiciones establecidos en el presente y en el LP Operating
            Agreement, el Ángel Inversionista acuerda firmar un Single
            Investment Subscription Agreement (el "Subscription Agreement”), con
            un Compromiso igual al Compromiso del Inversionista como se indica
            en la página de firma del inversionista (el "Compromiso del
            inversionista") única y exclusivamente con respecto a la Empresa o
            Empresas Promovidas.El Compromiso mínimo del Ángel Inversionista, no
            será inferior a US [ ] USD. AHV se reserva el derecho, a su
            exclusivo criterio, de aceptar compromisos más pequeños caso por
            caso.
          </Typography>
        </ListItem>

        <ListItem>
          <Typography>
            d. AHV se obliga a realizar, previo a una inversión en una Empresa
            Promovida, una valuación y un due diligence necesario y asegurará
            que los estatutos sociales de la Empresa Promovida garanticen los
            derechos corporativos y económicos preferentes, contemplados en el
            presente Convenio en favor de el/los Ángel(es) Inversionista(s) y
            AVH.
          </Typography>
        </ListItem>

        <ListItem>
          <Typography>
            e. El Vehículo de Inversión, a su vez, se convierte en accionista de
            la Empresa Promovida. La participación del Vehículo de Inversión en
            el capital social de la Empresa Promovida, así como los demás
            términos de la inversión deberán acordarse por escrito entre el/los
            Ángel(es) Inversionista(s), AHV y los emprendedores, con excepción
            de aquellos términos y condiciones obligatorios y previstos en este
            Convenio.
          </Typography>
        </ListItem>

        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              DÉCIMA. <u> DISTRIBUCIONES. </u>
            </strong>
            <Typography>
              Las Partes aceptan que el periodo de desinversión no tendrá fecha
              de inicio para ofrecer flexibilidad a las salidas de inversión que
              se requerirá autorización por escrito del consejo de
              administración del Vehículo de Inversión de forma unánime, tomando
              en consideración un análisis financiero y de oportunidad que
              genera mayores expectativas de rentabilidad para los Ángeles
              Inversionistas.
            </Typography>
          </p>
        </ListItem>
        <ListItem>
          <Typography>
            Una vez aprobada la desinversión (recompra de las acciones por los
            fundadores-emprendedores, venta a un fondo de capital privado,
            compra por terceros (persona física o moral), oferta pública inicial
            (OPI) o socio estratégico), la cascada de distribución, así como el
            orden de prelación de dichas distribuciones será como sigue, lo
            anterior sujeto a lo previsto en el párrafo segundo de la cláusula
            quinta de este Convenio y en la inteligencia de que el monto
            derivado de la desinversión permite cubrir los conceptos siguientes:
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            1. El total de los recursos derivados de la desinversión se
            reembolsará al Vehículo de Inversión que a su vez reembolsará a sus
            inversionistas mediante el siguiente esquema de distribución:
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            a. Se destina a reintegrar el total del monto aportado por el/los
            Ángel(es) Inversionista(s) (capital o deuda en el supuesto que se
            hayan convertido en acciones);
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            b. Una vez cubierto el monto anterior, y en el supuesto de existir
            remanente, se pagará el rendimiento preferente 8% (ocho por ciento)
            anual compuesto en favor al/los Ángel(es) Inversionista(s) sobre su
            capital aportado. Una vez cubiertos los conceptos anteriores, y en
            el supuesto de existir remanente, el monto remanente de los
            rendimientos derivados de la desinversión se distribuirá de la
            siguiente manera:
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            c. 80% (ochenta y cinco por ciento) de los rendimientos para el/los
            Ángel(es) Inversionista(s) y el 20% (veinte por ciento) para AHV, lo
            anterior, independientemente de la participación accionaria del/ de
            los Ángel(es) Inversionista(s) y AHV en el Vehículo de Inversión.
          </Typography>
        </ListItem>
        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              DÉCIMA PRIMERA. <u> ADMINISTRACIÓN. </u>
            </strong>
            <Typography>
              AHV, como administrador del Club de Inversionistas de AHV, así
              como las inversiones en las Empresas Promovidas, cumplirá con las
              siguientes funciones: i) aumentar el registro de miembros del Club
              de Inversionistas, ii) encontrar proyectos de inversión atractivos
              que agreguen valor a los miembros del Club de Inversionistas,
              seguimiento a las inversiones realizadas por los miembros,
              análisis y evaluación de las oportunidades de inversión, iii) due
              dilligence de las oportunidades, iv) En caso de ser necesario
              crear y mantener alianzas estratégicas para operar con éxito el
              Club de Inversionistas de AHV, entre otros.
            </Typography>
          </p>
        </ListItem>
        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              DÉCIMA SEGUNDA. <u> CONTRAPRESTACIÓN POR ADMINISTRACIÓN. </u>
            </strong>
            <Typography>
              El Ángel Inversionista acepta que AHV tendrá el derecho de cobrar
              a cada Empresa Promovida en la que invierta el Cub de Ángeles
              Inversionistas de AHV por concepto de contraprestación por
              administración el 5% (cinco por ciento) del monto de su aportación
              (financiamiento), por cada inversión realizada. Dicha
              contraprestación será utilizada por AHV para cubrir los gastos
              jurídicos ordinarios de los vehículos de inversión, gastos de due
              diligence, valuación, así como las tareas de naturaleza
              administrativa de AHV.
            </Typography>
          </p>
        </ListItem>
        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              DÉCIMA TERCERA. <u> CONFIDENCIALIDAD. </u>
            </strong>
            <Typography>
              Las Partes se obligan a mantener de manera confidencial toda la
              información relacionada con los proyectos, excepto por aquella
              información que (i) las Partes convengan por escrito que puede ser
              divulgada; (ii) sea de carácter pública; y/o (iii) cualquiera de
              las Partes tenga la obligación de divulgar, revelar, hacer
              disponible a, o compartir con alguna Autoridad Gubernamental, ya
              sea administrativa o judicial de conformidad con la Legislación
              Aplicable o en virtud de alguna orden o resolución judicial o
              administrativa, en cuyo caso deberá informar por escrito a la otra
              Parte respecto a su obligación de divulgar información.
            </Typography>
          </p>
        </ListItem>
        <ListItem>
          <Typography>
            Las obligaciones de confidencialidad previstas en la presente
            cláusula sobrevivirán el plazo de 3 años después de la terminación
            del presente Convenio.
          </Typography>
        </ListItem>
        <ListItem>
          <strong
            style={{
              fontSize: isMovil ? "12px" : "",
            }}
          >
            DÉCIMA CUARTA. <u> NOTIFICACIONES Y AVISOS. </u>
          </strong>
        </ListItem>
        <ListItem>
          <Typography>
            Cualquier aviso, notificación, solicitud, reclamación, demanda o
            cualquier otro comunicado requerido o permitido de conformidad con
            el presente Convenio deberá realizarse por escrito y deberá
            entregarse a la otra Parte ya sea (i) personalmente, o (ii) por
            medio de servicio de mensajería reconocido con acuse de recibo por
            el destinatario. Todas aquellas notificaciones, avisos, solicitudes,
            reclamaciones, demandas u otras comunicaciones se enviarán a los
            domicilios siguientes:
          </Typography>
        </ListItem>
        {/* <div style={{ display: "flex", justifyContent: "center" }}>
          <table
            style={{
              width: "97%",
              border: "1px solid black",
            }}
          >
            <tr
              style={{
                border: "1px solid black",
              }}
            >
              <th
                style={{
                  border: "1px solid black",
                  width: "50%",
                }}
              >
                Ángel Inversionista
              </th>
              <th style={{ border: "1px solid black" }}>AHV</th>
            </tr>
            <tr>
              <td>Domicilio:</td>
              <td>
                Domicilio: Colina Blanca 425, Colinas de San Gerardo, <br />
                Monterrey 64638
              </td>
            </tr>
            <tr>
              <td>Correo electrónico</td>
              <td>Correo electrónico: mgarcia@angelhub.mx</td>
            </tr>
            <tr>
              <td>Teléfono:</td>
              <td>Teléfono: 8115775828</td>
            </tr>
            <tr>
              <td>Con atención a:</td>
              <td>Con atención a: Mario A. García Dávila </td>
            </tr>
          </table>
        </div> */}
        <ListItem>
          <Typography>
            Cualquiera de las Partes podrá designar otra dirección, teléfono y/o
            e-mail para recibir notificaciones de conformidad con este Convenio,
            mediante una notificación por escrito entregada a la otra Parte, de
            conformidad con esta Cláusula.
          </Typography>
        </ListItem>

        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              DÉCIMA QUINTA. <u>MODIFICACIONES. </u>
            </strong>
            <Typography>
              Este Convenio sólo podrá ser modificado mediante convenio por
              escrito entre las Partes. Este Convenio será obligatorio para las
              Partes contratantes y sus respectivos sucesores, cesionarios,
              herederos y legatarios, y las Partes no podrán ceder sus derechos
              u obligaciones derivados del mismo sin el consentimiento previo y
              por escrito de la otra Parte.
            </Typography>
          </p>
        </ListItem>

        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              DÉCIMA SEXTA. <u>VIGENCIA.</u>
            </strong>{" "}
            <Typography>
              El presente Convenio se encontrará en plena fuerza y vigor hasta
              su terminación. El presente Convenio terminará:
            </Typography>
          </p>
        </ListItem>

        <ListItem>
          <Typography>
            {`a)`} Mediante el acuerdo por escrito de las Partes;
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            {`b)`} En el momento en que el Ángel Inversionista deja de ser
            miembro del Club de Inversionistas de AHV por la razón que sea; y,
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            {`c)`} En el momento en que el Vehículo de Inversión se disuelva y
            entre en estado de liquidación.
          </Typography>
        </ListItem>
        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              {" "}
              DÉCIMA SÉPTIMA. <u>ANEXOS.</u>{" "}
            </strong>
            <Typography>
              Los anexos y documentos adjuntos son parte integral de este
              Convenio tal y como si estuviesen aquí reproducidos, y se
              considerará que toda referencia que se haga a este Convenio
              incluye todos sus Anexos y demás documentos adjuntos.
            </Typography>
          </p>
        </ListItem>
        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              {" "}
              DÉCIMA OCTAVA. <u>GASTOS.</u>{" "}
            </strong>
            <Typography>
              Cada una de las Partes se hará cargo de, y cubrirá todos los
              costos y gastos, incluyendo los de sus respectivos asesores y
              representantes, en que incurran cada una de ellas,
              respectivamente, durante la negociación de, o en relación con la
              presente Convenio y aquellos gastos expresamente mencionados como
              a cargo de las Partes en las diferentes cláusulas del presente
              Convenio.
            </Typography>
          </p>
        </ListItem>
        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              {" "}
              DÉCIMA NOVENA. <u>LEGISLACIÓN APLICABLE Y JURISDICCIÓN.</u>{" "}
            </strong>
            <Typography
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              Para todo lo relativo a la interpretación y cumplimiento de este
              Convenio, en este acto las Partes se someten, de manera expresa e
              irrevocable a la legislación mexicana. Las Partes se obligan
              expresa e irrevocablemente a someterse a la jurisdicción de los
              tribunales de la Ciudad de Monterrey, Nuevo León, y renuncian
              expresamente en este acto a cualquier otra jurisdicción que les
              corresponda o pudiera corresponder con motivo de su domicilio
              actual o futuro o por cualquier otra razón.
            </Typography>
          </p>
        </ListItem>
        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              {" "}
              VIGÉSIMA. <u>DIVISIBILIDAD.</u>{" "}
            </strong>
            <Typography>
              Si cualquier disposición de este Convenio (o cualquier parte del
              mismo) o la aplicación de dicha disposición (o cualquier parte de
              la misma) a cualquier persona o circunstancia es considerada
              inválida, ilegal o inejecutable en cualquier aspecto por una
              autoridad gubernamental competente (a) dicha invalidez, ilegalidad
              o inejecutabilidad no afectará ninguna otra disposición del
              presente Convenio (o la parte restante de dicha disposición) o la
              aplicación de dicha disposición a cualesquiera otras personas o
              circunstancias, y (b) las Partes, mediante la modificación del
              presente Convenio, deberán remplazar dicha disposición (o
              cualquier parte de la misma) con una disposición que sea válida,
              legal y ejecutable y que sea lo más cercano a expresar la
              intención de la Partes con respecto a la disposición declarada
              inválida, ilegal o inejecutable.
            </Typography>
          </p>
        </ListItem>

        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              {" "}
              VIGÉSIMA PRIMERA. <u>AUSENCIA DE VICIOS.</u>{" "}
            </strong>
            <Typography>
              Las Partes manifiestan que en la celebración del presente
              instrumento no existió dolo, mala fe o vicio alguno del
              consentimiento que pueda invalidarlo en todo o en parte, y que
              están perfectamente enteradas del contenido y obligatoriedad de
              todo su clausulado, siendo su libre voluntad celebrarlo en los
              términos antes expuestos.
            </Typography>
          </p>
        </ListItem>
        <ListItem>
          <p>
            <strong
              style={{
                fontSize: isMovil ? "12px" : "",
              }}
            >
              {" "}
              VIGÉSIMA SEGUNDA. <u>EJEMPLARES.</u>{" "}
            </strong>
            <Typography>
              Este Convenio se firma en 2 (dos) ejemplares, los cuales se
              considerarán y constituirán en conjunto un único instrumento,
              firmándose en la Ciudad de Monterrey, el día y mes acordado del
              año 2023, previa lectura ante las Partes, y perfectamente
              enteradas de su contenido rubrican este Convenio de conformidad al
              margen de todas y cada una de las hojas que contiene y la última
              la firman al calce:
            </Typography>
          </p>
        </ListItem>
      </List>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          textAlign: isMovil ? "center" : "start",
        }}
      >
        <div>
          <p style={{ marginBottom: "50px" }}>Nombre</p>
          <p style={{ textAlign: "center" }}>
            Por sus propios derechos <br /> Angel Inversionista
          </p>
        </div>
        <div>
          <p style={{ marginBottom: "50px" }}>Mario A. Garcia Dávila</p>
          <p style={{ textAlign: "center" }}>
            Represente Legal <br />
            AHV
          </p>
        </div>
      </div>

      <div style={{ marginTop: "100px" }}>
        <h6>Dirección</h6>
        <p
          style={{
            fontSize: "12px",
            width: "240px",
          }}
        >
          Ricardo Margain Zozaya 575 Corporativo Santa Engracia Torre C y D,
          <br />
          San Pedro Garza García, N.L.
          <br />
          C.P 66267
        </p>
        <p
          style={{
            marginTop: "100px",
            fontSize: "12px",
            textAlign: "center",
          }}
        >
          2023 Derechos Reservados. Angel Hub Ventures LLC. Documento
          Confidencial
          <hr />
        </p>
      </div>
    </Box>
  );
};
