const mainStyles = {
  timerIconBox: {
    marginRight: "-0.8rem",
    borderRadius: "200px",
    backgroundColor: "#EB7281",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },
  actionsButtons: {
    backgroundColor: "#74C24A",
    width: "162px",
    color: "white",
  },
  actionsMobileButtons: {
    backgroundColor: "#74C24A",
    width: "96px",
    color: "white",
    fontSize: "10px",
  },
  container: {
    minWidth: "250px",
    overflow: "hidden",
    flexGrow: 1,
    gap: "8px",
    display: "flex",
    flexDirection: "column",
  },
  mainCard: {
    minHeight: "100%",
  },
  tab: { marginTop: 2, minHeight: "440px" },
  icon: {
    color: "#4A7C2F",
    width: "18px",
    marginRight: "4px",
  },
};

export default mainStyles;
