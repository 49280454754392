import { ILoggedUser } from "../../models/User";
import { RELOAD_USER_INFO } from "../actions/LoggedUser";

export enum ACTION_TYPES {
  LOGIN = "LOGIN",
  LOGIN_FAILED = "LOGIN_FAILED",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGOUT = "LOGOUT",
  RESET = "RESET",
  DISABLE_APP_LOADER = "DISABLE_APP_LOADER",
  CLEAR_ERROR = "CLEAR_ERROR_LOGIN",
  EDIT = "EDIT",
}

export interface ILoggedUserReducer {
  loggedUser: ILoggedUser;
  edit: boolean;
  initialAppLoading: boolean;
  loading: boolean;
  error: boolean;
  loginSuccess: boolean;
}

const initialState: ILoggedUserReducer = {
  loggedUser: {
    user: {
      _id: "",
      firstName: "",
      lastName: "",
      role: {
        code: "",
        name: "",
        resource: [],
      },
    },
  },
  edit: false,
  initialAppLoading: true,
  loading: false,
  error: false,
  loginSuccess: false,
};

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTION_TYPES.LOGIN:
      return {
        ...state,
        loading: true,
        error: false,
        loginSuccess: false,
      };
    case ACTION_TYPES.LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        initialAppLoading: false,
        error: action.data,
        loginSuccess: false,
      };
    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        initialAppLoading: false,
        error: false,
        loggedUser: action.data,
        loginSuccess: true,
      };
    case ACTION_TYPES.LOGOUT:
      return {
        ...state,
        loggedUser: null,
        loginSuccess: false,
        loading: false,
        initialAppLoading: false,
      };
    case ACTION_TYPES.DISABLE_APP_LOADER:
      return {
        ...state,
        loading: false,
        initialAppLoading: false,
      };
    case ACTION_TYPES.CLEAR_ERROR:
      return {
        ...state,
        error: "",
      };
    case ACTION_TYPES.RESET:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case ACTION_TYPES.EDIT:
      return {
        ...state,
        edit: action.payload,
      };
    case RELOAD_USER_INFO:
      return {
        ...state,
        loggedUser: action.data,
      };
    default:
      return state;
  }
}
