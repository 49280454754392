import { Button, SxProps } from "@mui/material";
import { useParams } from "react-router-dom";

import { useSelector } from "../../../../../../redux/typedHooks";
import { ERolesCode } from "../../../../../routes/role-catalog";

interface IProps {
  xs: SxProps;
  changeMode: () => void;
}

const ButtonReadMode: React.FC<IProps> = ({ changeMode, xs, children }) => {
  const { id } = useParams() as { id: string };
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  function handleEditar() {
    changeMode();
  }

  return (
    <>
      {((user.role.code === ERolesCode.MEMBER && user.member._id === id) ||
        (user.role.code !== ERolesCode.MEMBER &&
          user.role.code !== ERolesCode.STARTUP)) && (
        <Button onClick={handleEditar} variant="contained" sx={xs}>
          {children}
        </Button>
      )}
    </>
  );
};

export default ButtonReadMode;
