/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import BaseDataGrid from "../../components/BaseDataGrid/BaseDataGrid";
import { ModalMenu } from "../../components/DataGrid";
import { DEFAULT_ROWS_PAGE_SIZE } from "../../components/DataGrid/constants";
import DirectoryToolbar from "../../components/Toolbars/DirectoryToolbar/DirectoryToolbar";
import { EStatusReleasedOpportunity } from "../../constants";
import { opportunitiesReleased } from "../../services/opportunitiesReleased";

import { columns } from "./columns";
import { VALID_STATUS_TO_SHOW_IN_DIRECTORY } from "./validations";

const DirectoryList: React.FC = () => {
  const history = useHistory();

  const [opportunities, setOpportunities] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_ROWS_PAGE_SIZE);

  const [Search, setSearch] = useState<string>("");
  const [Stage, setStage] = useState<string>("");
  const [Industry, setIndustry] = useState<string>("");
  const [Country, setCountry] = useState<string>("");

  const newColumns = () => {
    const columnas = [...columns()];
    columnas.push({
      headerName: "Acciones",
      field: "actions",
      hideSortIcons: true,
      align: "center",
      renderCell: (data: any) => renderModalMenu(data),
    });

    return columnas;
  };

  function handleDetails(id: string) {
    history.push(`details-startup/${id}`);
  }

  const renderModalMenu = (data: any) => {
    const detailsOptionsList = [
      {
        name: "Ver detalles",
        action: () => handleDetails(data.id),
      },
    ];

    return <ModalMenu menuVert items={detailsOptionsList} />;
  };

  function handleFilter(data: any[]) {
    let values = data.filter((opportunity: any) => {
      return (
        opportunity.name.toLowerCase().includes(Search.toLowerCase()) || !Search
      );
    });

    values = values.filter((opportunity: any) => {
      return opportunity.status !== "CLOSED";
    });

    if (Stage) {
      values = values.filter((i) => i.stage === Stage);
    }
    if (Industry) {
      values = values.filter((i) => i.industry === Industry);
    }
    if (Country) {
      values = values.filter((i) => i.country === Country);
    }

    values = values.filter(
      (i) => i.status === EStatusReleasedOpportunity.INVESTED,
    );

    return values;
  }

  useEffect(() => {
    opportunitiesReleased()
      .then(({ data }) => {
        const opportunities = data.filter((i: any) =>
          VALID_STATUS_TO_SHOW_IN_DIRECTORY.includes(i.status),
        );
        setOpportunities(opportunities);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <BaseDataGrid
        page={page}
        pageSize={pageSize}
        onPageChange={(value) => setPage(value)}
        onPageSizeChange={(value) => setPageSize(value)}
        columns={newColumns()}
        loading={loading}
        rows={handleFilter(opportunities)}
        getRowId={(row) => row._id}
        components={{
          Toolbar: DirectoryToolbar,
        }}
        checkboxSelection
        componentsProps={{
          toolbar: {
            onSearchChange: (value: string) => {
              setSearch(value);
            },
            onSearchClear: () => {
              setSearch("");
            },
            onStageChange: (value: string) => {
              setStage(value);
            },
            onIndustryChange: (value: string) => {
              setIndustry(value);
            },
            onCountryChange: (value: string) => {
              setCountry(value);
            },
            resetPage: () => {
              setPageSize(DEFAULT_ROWS_PAGE_SIZE);
              setPage(0);
            },
          },
        }}
      />
    </>
  );
};

export default DirectoryList;
