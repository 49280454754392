export const ROLES = {
  RL000: "SUPERADMIN",
  RL001: "ADMINISTRATOR",
  RL002: "ANALYST",
  RL003: "STARTUP",
  RL004: "MEMBER",
};

export enum ERoles {
  RL000 = "RL000",
  RL001 = "RL001",
  RL002 = "RL002",
  RL003 = "RL003",
  RL004 = "RL004",
  RL005 = "RL005",
}

export enum ERolesCode {
  SUPER_ADMIN = "RL000",
  PARTNER = "RL001",
  ANALYST = "RL002",
  STARTUP = "RL003",
  MEMBER = "RL004",
  ADMINISTRATOR = "RL005",
}
