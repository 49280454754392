import { SxProps, Theme } from "@mui/material";

export const dashboardStartupStyles: SxProps<Theme> = (theme: Theme) => ({
  ".marginxs": {
    [theme.breakpoints.down("sm")]: {
      marginTop: "8px",
    },
  },
  ".mobilePadding": {
    [theme.breakpoints.down("sm")]: {
      paddingX: "24px",
    },
  },
  ".calendarPadding": {
    marginBottom: "40px",
    paddingRight: "40px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "40px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "24px",
    },
  },
  ".YSpace": {
    [theme.breakpoints.down("md")]: {
      marginBottom: "24px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "56px",
    },
  },
  ".welcomeContainer": {
    padding: "24px 10px",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    gap: "9px",
    justifyContent: "space-between",
    background: "#F8F8F8",
  },
  ".welcomeTitle": {
    fontSize: "24px",
  },
  ".welcomeStartupName": {
    fontSize: "24px",
    fontWeight: "600",
    color: "#74C24A",
  },
  ".newsFeedContainer": {
    maxHeight: "1000px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    maxWidth: "300px",
    [theme.breakpoints.up("sm")]: {
      minWidth: "300px",
    },
  },
  ".newsFeedItemTitle": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "15px",
    width: "95%",
    whiteSpace: "break-spaces",
    color: "#343434",
    fontSize: "14px",
    fontWeight: "400",
    marginBottom: "8px",
    minHeight: "47px",
    maxHeight: "47px",
  },
  ".itemContainer": {
    color: "#515151",
    fontSize: "12px",
    fontWeight: "300",
    display: "flex",
    alignItems: "flex-end",
    minHeight: "48px",
    maxHeight: "48px",
  },
  ".itemDescription": {
    marginTop: "8px",
    cursor: "pointer",
    fontSize: "12px",
    marginLeft: "6px",
    fontWeight: "600",
    textTransform: "none",
    height: "48px",
    textOverflow: "ellipsis",
    overFlow: "hidden",
    whiteSpace: "initial",
  },
  ".container-see-all": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingY: "30px",
  },
  ".button-see-all": {
    borderBottom: "1px solid #4D88E1",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Product Sans !important",
    color: "#4D88E1",
    cursor: "pointer",
  },

  ".container-notice": {
    [theme.breakpoints.up("md")]: {
      marginLeft: "16px",
    },
  },
});
