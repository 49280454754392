import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const getStartupApplicationById = (id: string): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: "/opportunities/" + id,
    method: "GET",
  };

  return axios(config);
};

export { getStartupApplicationById };