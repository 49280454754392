import { Typography, styled } from "@mui/material";

export default styled(Typography)(({ theme }) => ({
  color: "#515151",
  fontFamily: "Product Sans Medium !important",
  fontWeight: 500,
  letterSpacing: ".5px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px !important",
  },
}));
