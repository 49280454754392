export const styles = {
  reportContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "4px",

    subContainer: {
      maxHeight: "300ppx",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      borderBottom: "solid 1px  #C7C7C7",
    },
  },
  downloadButton: { color: "#4A88C2", fontSize: "12px", padding: "0px 15px" },
  alignElementsBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "28px",
  },
  buttonDownload: {
    color: "#4A7C2F",
    marginBottom: "2px",
    fontSize: "20px",
  },
  seeAll: {
    alignSelf: "flex-end",
    color: "#4A88C2",
    fontSize: "12px",
    fontWeight: "600",
    textAlign: "right",
    paddingRight: "0px",
  },
  monthsResponsive: {
    "@media (max-width: 450px)": {
      width: "100%",
      textAlign: "center",
      fontSize: "17px",
    },
  },
  reportTitle: {
    "@media (max-width: 450px)": {
      fontSize: "17px",
      fontWeight: "500",
    },
  },
  periodTitle: {
    display: "flex",
    gap: "3px",
    flexWrap: "wrap",
  },
  borderResponsive: {
    "@media (max-width: 380px)": {
      marginBottom: "15px",
    },
  },
};
