import { yupResolver } from "@hookform/resolvers/yup";
import { ImageOutlined } from "@mui/icons-material";
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { useHistory } from "react-router-dom";

import CustomInputFile from "../../../components/CustomInputFile";
import { IFileObject } from "../../../components/CustomInputFile/CustomInputFile";
import { TextInput } from "../../../components/Inputs";
import { useLoading } from "../../../hooks/useLoading";
import { IRole } from "../../../models/User";
import { openModal, PayloadOpenModal } from "../../../redux/actions/modal";
import { useDispatch, useSelector } from "../../../redux/typedHooks";
import { getRoles, getUserById } from "../../../services/users";
import { displayToast } from "../../../utils/helpers/displayToast";
import { translateRoles } from "../../../utils/text/translates";
import { data } from "../../DashboardMember/NumberInvestment/DataGridInvestment/data";
import { ERoles } from "../../routes/role-catalog";

import { FormType, profilePartnerSchema } from "./schemas";

const gridR = { xs: 12, sm: 4, md: 4, lg: 4, xl: 4 };

interface IProps {
  id: string;
  isUpdateScreen?: boolean;
  handleSubmit: (data: any) => Promise<void>;
}

export default function ProfilePartner({
  id,
  isUpdateScreen,
  handleSubmit,
}: IProps) {
  const [isEdited, setIsEdited] = useState(true);
  const [edit, setEdit] = useState(true);
  const [reload, setReload] = useState(true);
  const [Roles, setRoles] = useState<IRole[]>([]);
  const [file, setFile] = useState<File | null>();
  const [originalValues, setOriginalValues] = useState<string>();
  const [registrationDate, setRegistrationDate] = useState<string>();
  const { handleClose, handleOpen } = useLoading();
  const { loggedUser } = useSelector((store) => store.loggedUser);

  const dispatch = useDispatch();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const hook = useForm<FormType>({
    resolver: yupResolver(profilePartnerSchema),
    mode: "all",
    defaultValues: {
      avatar: null,
      firstName: "",
      lastName: "",
      email: "",
      phoneCountryCode: "",
      phoneNumber: "",
      position: "",
      role: "",
      calendlyEvent: "",
      linkedIn: "",
    },
  });

  function handleReload() {
    setReload(!reload);
  }

  function handleEdit() {
    setEdit(false);
  }

  function handleDisableEdit() {
    setEdit(true);
    resetFormValues(originalValues);
  }

  useEffect(() => {
    getRoles().then((resp: any) => {
      const data = resp.data;
      setRoles(data.payload);
    });
  }, []);

  useEffect(() => {
    getUserById(id ? id : loggedUser.user._id)
      .then(({ data }) => {
        const user = data.payload as any;
        setRegistrationDate(moment(user.createdAt).format("DD/MM/YYYY"));
        setOriginalValues(user);
        resetFormValues(user);
        const isPartner =
          loggedUser.user.role.code === ERoles.RL001 ||
          loggedUser.user.role.code === ERoles.RL000;
        const userLogged = id === loggedUser.user.id;
        if (isPartner || userLogged) {
          setIsEdited(false);
        }
      })
      .catch(() => {
        displayToast(
          "error",
          "Ocurrió un error al cargar usuario. Intenta cargar la página de nuevo",
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser.user._id, reload, id]);

  function resetFormValues(userData: any) {
    if (userData.avatar) hook.setValue("avatar", userData.avatar);
    hook.setValue("firstName", userData.firstName);
    hook.setValue("lastName", userData.lastName);
    hook.setValue(
      "phoneNumber",
      userData.phoneCountryCode.toString() + userData.phoneNumber.toString(),
    );
    hook.setValue("phoneCountryCode", userData.phoneCountryCode);
    hook.setValue("position", userData.position);
    hook.setValue("role", userData.role._id);
    hook.setValue("email", userData.email);
    hook.setValue("calendlyEvent", userData.calendlyEvent);
    hook.setValue("linkedIn", userData.linkedIn);
    hook.clearErrors();
    setFile(null);
  }

  async function onSubmit(data: FormType) {
    const AdminFormdata = new FormData();
    const OnlyPhone = data.phoneNumber.slice(data.phoneCountryCode?.length);

    if (file) AdminFormdata.append("avatar", file);
    AdminFormdata.append("firstName", data.firstName);
    AdminFormdata.append("lastName", data.lastName);
    AdminFormdata.append("phoneCountryCode", data.phoneCountryCode);
    AdminFormdata.append("phoneNumber", OnlyPhone);
    AdminFormdata.append("email", data.email);
    AdminFormdata.append("position", data.position);
    AdminFormdata.append("calendlyEvent", data.calendlyEvent);
    AdminFormdata.append("linkedIn", data.linkedIn);
    AdminFormdata.append("role", data.role);

    try {
      handleOpen();
      await handleSubmit(AdminFormdata);
      handleSuccessModal();
      handleReload();
    } catch (error: any) {
      const message = error?.data?.message;
      if (message === "Ya existe usuario con ese correo") {
        hook.setError("email", {
          message: "Ya existe un usuario con este correo",
        });
      } else if (message === "Ya existe usuario con ese numero") {
        hook.setError("email", {
          message: "Ya existe un usuario con este numero",
        });
      } else {
        handleErrorModal();
      }
    } finally {
      handleClose();
    }
  }

  function handleSuccessModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Correcto",
      description: `Usuario actualizado satisfactoriamente`,
      action: () => setEdit(!edit),
      hideSecondaryButton: true,
      primaryButtonText: "Aceptar",
    };
    dispatch(openModal(modalConfig));
  }

  function handleErrorModal() {
    const modalConfig: PayloadOpenModal = {
      title: "Error",
      description: `Hubo un error al actualizar usuario`,
      hideSecondaryButton: true,
      primaryButtonText: "Aceptar",
    };
    dispatch(openModal(modalConfig));
  }

  async function handleChangeFile(data: File[]) {
    if (data.length > 0) {
      if (data[0].size > 2 * 1024 * 1024) {
        hook.setError("avatar", {
          type: "custom",
          message: "El archivo es demasiado pesado. El peso límite es de 2MB.",
        });
        return false;
      }
      hook.clearErrors("avatar");
      hook.setValue("avatar", data[0] as unknown as IFileObject);
      setFile(data[0]);
    }
  }

  return (
    <>
      <Grid container>
        <Grid
          container
          item
          columnSpacing={6}
          rowSpacing={4}
          xs={12}
          sm={8}
          md={8}
          lg={8}
          xl={8}
          order={{ xs: 2, sm: 2, md: 2, lg: 1 }}
        >
          {hook.watch("avatar") && edit ? (
            <Grid
              container
              xs={12}
              justifyContent={{ xs: "center", sm: "left" }}
            >
              <Grid item marginLeft="3rem" marginTop="3rem">
                <Controller
                  control={hook.control}
                  name="avatar"
                  render={({ field, fieldState }) => (
                    <>
                      <CustomInputFile
                        fileObject={field.value as IFileObject}
                        disable={edit}
                        onFileChanged={(file) => setFile(file)}
                      />
                      <Typography
                        sx={{
                          marginLeft: "0.2rem",
                          marginTop: "24px",
                          fontWeight: "500",
                          marginBottom: "2%",
                          fontSize: "16px",
                        }}
                      >
                        {"Registrado " + registrationDate}
                      </Typography>
                    </>
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid item {...gridR}>
              <Controller
                control={hook.control}
                name="avatar"
                render={({ field, fieldState }) => (
                  <>
                    <input
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      hidden
                      ref={inputFileRef}
                      onChange={(event) => {
                        if (event.target.files)
                          handleChangeFile(Array.from(event.target.files));
                      }}
                    />
                    <TextInput
                      value={field?.value?.name}
                      disabled={edit}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={edit}
                              onClick={() => inputFileRef.current?.click()}
                              edge="end"
                            >
                              <ImageOutlined />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label="Subir foto"
                      error={Boolean(fieldState.error)}
                      helperText={
                        Boolean(fieldState.error?.message) &&
                        fieldState.error?.message
                      }
                      fullWidth
                    />
                  </>
                )}
              />
            </Grid>
          )}
          <Grid item {...gridR}>
            <Controller
              control={hook.control}
              name="firstName"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  label="Nombres"
                  disabled={edit}
                  required
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item {...gridR}>
            <Controller
              control={hook.control}
              name="lastName"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  label="Apellidos"
                  disabled={edit}
                  required
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item {...gridR}>
            <Controller
              control={hook.control}
              name="phoneNumber"
              render={({ field, fieldState }) => (
                <div>
                  <PhoneInput
                    value={field.value}
                    disabled={edit}
                    onChange={(number, countryData: CountryData) => {
                      hook.setValue("phoneCountryCode", countryData.dialCode);
                      hook.setValue("phoneNumber", number);
                      field.onChange(number);
                    }}
                    buttonStyle={
                      fieldState.error ? { border: "2px solid #f44336" } : {}
                    }
                    inputStyle={
                      fieldState.error
                        ? {
                            border: "2px solid #f44336",
                            height: "48px",
                            width: "100%",
                          }
                        : { height: "48px", width: "100%" }
                    }
                  />
                  {Boolean(fieldState.error) && (
                    <FormHelperText error={Boolean(fieldState.error?.message)}>
                      {fieldState.error?.message}
                    </FormHelperText>
                  )}
                </div>
              )}
            />
          </Grid>
          <Grid item {...gridR}>
            <Controller
              control={hook.control}
              name="email"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  label="Correo Electrónico"
                  disabled={edit}
                  required
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item {...gridR}>
            <Controller
              control={hook.control}
              name="position"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  label="Puesto"
                  disabled={edit}
                  required
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item {...gridR}>
            <Controller
              control={hook.control}
              name="calendlyEvent"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  label="URL Calendly"
                  disabled={edit}
                  required
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item {...gridR}>
            <Controller
              control={hook.control}
              name="linkedIn"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  label="LinkedIn"
                  disabled={edit}
                  required
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item {...gridR}>
            <Controller
              control={hook.control}
              name="role"
              render={({ field, fieldState }) => (
                <TextInput
                  {...field}
                  label="Rol"
                  disabled
                  required
                  select
                  error={Boolean(fieldState.error)}
                  helperText={
                    Boolean(fieldState.error?.message) &&
                    fieldState.error?.message
                  }
                  fullWidth
                >
                  {Roles.map((i, index) => (
                    <MenuItem key={index} value={i._id}>
                      {translateRoles(i.name)}
                    </MenuItem>
                  ))}
                </TextInput>
              )}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          {...gridR}
          justifyContent={{ xs: "center", sm: "right" }}
          order={{ xs: 1, sm: 2, md: 2, lg: 2 }}
          style={{ marginBottom: "24px" }}
        >
          {!edit && (
            <Button
              style={{ height: "36px", margin: "0 10px", width: "130px" }}
              variant="outlined"
              onClick={handleDisableEdit}
            >
              Cancelar
            </Button>
          )}
          {edit ? (
            <Button
              style={{ height: "36px", margin: "0 10px", width: "130px" }}
              variant="contained"
              disabled={isEdited}
              onClick={handleEdit}
            >
              Editar
            </Button>
          ) : (
            <Button
              style={{ height: "36px", margin: "0 10px", width: "130px" }}
              variant="contained"
              onClick={hook.handleSubmit(onSubmit)}
            >
              Guardar
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
}
