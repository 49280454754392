import { Box, Divider, Grid, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

import HorizontalDivider from "../../../components/Dashboard/HorizontalDivider";

import { OpportunitiesSummaryItemStyles } from "./style";

interface IMyPortfolioProps {
  indicator: string;
  value: string;
  useDivider?: boolean;
  useMobileDivider?: boolean;
  url?: string;
}

const OpportunitiesSummaryItem: React.FC<IMyPortfolioProps> = ({
  indicator,
  value,
  useDivider = true,
  useMobileDivider = false,
  url,
}) => {
  const history = useHistory();

  function handleRedirect() {
    if (url) {
      history.push(url);
    }
  }

  return (
    <Box sx={OpportunitiesSummaryItemStyles}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              sx={{ cursor: "pointer" }}
              onClick={handleRedirect}
              variant="h4"
            >
              {value}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {indicator === "Rendimiento" ? (
              <Tooltip title="Rendimiento de Inversiones">
                <Typography variant="subtitle1">Rendimiento</Typography>
              </Tooltip>
            ) : (
              <Typography variant="subtitle1">{indicator}</Typography>
            )}
          </Grid>
        </Grid>
        {useDivider && (
          <Divider orientation="vertical" variant="middle" flexItem />
        )}
      </Stack>
      {useMobileDivider && <HorizontalDivider sx={{ pb: 2 }} />}
    </Box>
  );
};

export default OpportunitiesSummaryItem;
