import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Card, Grid, IconButton, Stack } from "@mui/material";
import { useState } from "react";

import CardHeader from "../../../components/Dashboard/CardHeader";
import { PERIODS } from "../const";
import useFilter from "../useFilter";

import ChartDoughnut from "./ChartDoughnut";
import DataGridInvestment from "./DataGridInvestment/DataGridInvestment";
import InvestCardStyles from "./style";
import { IProps } from "./types";

export type CategoryGraphNumberInvestmentAdmin =
  | "numberByCountry"
  | "numberByIndustry"
  | "numberByStage";

const InvestCard: React.FC<IProps> = ({ data }) => {
  const [AnchoEl, setAnchorEl] = useState(null);

  const { options, value, handleOpenMenu } = useFilter(AnchoEl, setAnchorEl);

  const [stateTab, setStateTab] =
    useState<CategoryGraphNumberInvestmentAdmin>("numberByIndustry");

  const handleChangeTab = (value: CategoryGraphNumberInvestmentAdmin) => {
    setStateTab(value);
  };

  const values = data[value][stateTab];
  const period = PERIODS[value];

  return (
    <Box sx={InvestCardStyles}>
      {options}
      <Card className="invest-card">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} className="invest-card-header">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <CardHeader variant="h5">
                {`Inversiones Realizadas - ${period}`}
              </CardHeader>
              <IconButton onClick={handleOpenMenu} className="action-more-icon">
                <MoreVertIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ paddingLeft: "18px", pt: 3 }}>
            <ChartDoughnut values={values} />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ pr: 2 }}>
            <DataGridInvestment
              rows={values}
              onTabChange={handleChangeTab}
              tab={stateTab}
            />
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default InvestCard;
