import { yupResolver } from "@hookform/resolvers/yup";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  MenuItem,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { TextInput } from "../../../../../../../components/Inputs";
import PaypalButton from "../../../../../../../components/PaypalButton/PaypalButton";
import StandardDialog from "../../../../../../../components/StandardDialog";
import { EMembershipsPayments } from "../../../../../../../constants";
import { reloadUser } from "../../../../../../../redux/actions/LoggedUser";
import { refreshNotification } from "../../../../../../../redux/actions/notifications";
import { useDispatch } from "../../../../../../../redux/typedHooks";
import { getPricePaymentMembership } from "../../../../../../../services/payments";
import { currencyFormat } from "../../../../../../../utils/helpers/currencyFormatter";

import { schemaPayment } from "./schema";
import { IOptions } from "./types";

interface IProps {
  onReload: () => void;
  onCancel: () => void;
  open: boolean;
  memberId: string;
}

const DialogPayment: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const { onCancel, open, memberId, onReload } = props;
  const [options, setOptions] = useState<IOptions[]>([]);

  const hook = useForm<{ value: string }>({
    resolver: yupResolver(schemaPayment),
    defaultValues: {
      value: "",
    },
  });

  useEffect(() => {
    if (memberId) {
      getPricePaymentMembership(memberId).then(({ data }) => {
        setOptions(data);
      });
    }
  }, [memberId]);

  function onFinish() {
    onReload();
    handleOnCancel();
    dispatch(reloadUser());
    dispatch(refreshNotification());
  }

  function onError() {
    onReload();
    handleOnCancel();
  }

  function handleOnCancel() {
    onCancel();
    hook.reset();
  }

  const renderMenuItem = (values: IOptions) => {
    return (
      <MenuItem key={values._id} value={values.price}>
        <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
          <Typography>
            {values.name === EMembershipsPayments.CLASSICAL
              ? "Activar"
              : "Reactivar"}
          </Typography>
          <Typography>{currencyFormat(parseFloat(values.price))}</Typography>
        </Box>
      </MenuItem>
    );
  };

  return (
    <StandardDialog open={open}>
      <DialogTitle>Pago de membresía</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Por favor coloque la opción que le corresponde.
        </DialogContentText>
        <Stack spacing={2}>
          <Controller
            control={hook.control}
            name="value"
            render={({ field, fieldState }) => (
              <TextInput
                {...field}
                label="Selecciona la acción"
                required
                fullWidth
                select
                error={Boolean(fieldState.error)}
                helperText={
                  fieldState.error?.message && fieldState.error?.message
                }
              >
                {options.map((i) => renderMenuItem(i))}
              </TextInput>
            )}
          />

          {hook.watch("value") && (
            <PaypalButton
              price={hook.watch("value")}
              onFinish={onFinish}
              onError={onError}
              memberId={memberId}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleOnCancel}>
          Cancelar
        </Button>
      </DialogActions>
    </StandardDialog>
  );
};

export default DialogPayment;
