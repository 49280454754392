/* eslint-disable react-hooks/exhaustive-deps */
import {
  Apartment,
  AvTimer,
  Email,
  FolderOpen,
  Info,
  Language,
  LocationOn,
  Person,
  Phone,
  ShowChart,
  StackedLineChart,
  TipsAndUpdates,
  Videocam,
} from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Box,
  Chip,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { UseFormReturn, useFormContext } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";

import BigAvatar from "../../../../../components/BigAvatar/BigAvatar";
import ModalVideo from "../../../../../components/ModalVideo/ModalVideo";
import { EStatusReleasedOpportunity } from "../../../../../constants";
import { useSelector } from "../../../../../redux/typedHooks";
import { handleDownloadFile } from "../../../../../utils/helpers/downloadFile";
import { translateStatusStartups } from "../../../../../utils/text/translates";
import BoardItem from "../../../../DetailsOpportunity/components/MainCard/ReadMode/BoardItem/BoardItem";
import ItemProperty from "../../../../DetailsOpportunity/components/MainCard/ReadMode/ItemProperty/ItemProperty";
import ItemSideAvatar from "../../../../DetailsOpportunity/components/MainCard/ReadMode/ItemSideAvatar/ItemSideAvatar";
import LinkCustom from "../../../../DetailsOpportunity/components/MainCard/ReadMode/LinkCustom/LinkCustom";
import { ERolesCode } from "../../../../routes/role-catalog";
import mainStyles from "../../../styles";
import { styles } from "../styles";
import { FormType } from "../types";
import { FormatPhoneNumberSection } from "../utils";
import {
  VALID_ROLES_TO_TO_EDIT_MAIN_CARD,
  VALID_STATUS_TO_SHOW_DECK_AND_PITCH,
} from "../validations";

import ButtonReadMode from "./ButtonReadMode/ButtonReadMode";

interface IMainCardReadMode {
  hook: UseFormReturn<FormType>;
  changeMode: () => void;
  id: string;
}

export const ReadMode: FC<IMainCardReadMode> = ({ changeMode, hook, id }) => {
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const [isInvest, setIsInvest] = useState<boolean>(false);
  const mainCardHook = useFormContext<FormType>();
  const usersInvesting = mainCardHook.watch("usersWantToInvest") || [];
  useEffect(() => {
    //Check if user looged is on the investing list
    usersInvesting.map((item) => {
      const isId = item.user._id === user._id;
      if (isId) {
        setIsInvest(true);
      }
      return null;
    });
  }, []);

  const location = useLocation();
  const history = useHistory();
  const [OpenVideoPitchModal, setOpenVideoPitchModal] =
    useState<boolean>(false);

  // VALIDATIONS
  const breakpoint = useMediaQuery("(max-width: 850px)");
  const mobileButton = useMediaQuery("(max-width: 450px)");
  const pathLocation = location.pathname.split("/")[1];

  const isOpenToInvest =
    hook.watch("status") === EStatusReleasedOpportunity.OPEN_TO_INVESTMENT;

  const isRouteProfile = pathLocation.includes("startup-profile");
  const isAdminRole = VALID_ROLES_TO_TO_EDIT_MAIN_CARD.includes(user.role.code);
  const isStartupValidToEdit =
    user.role.code === ERolesCode.STARTUP && user.startup._id === id;

  const isValidToShowDeckAndPitch =
    (user.role.code === ERolesCode.STARTUP
      ? user?.startup?._id === id
      : true) && VALID_STATUS_TO_SHOW_DECK_AND_PITCH.includes(user.role.code);

  // FORMAT VALUES
  const expirationDays =
    moment(moment(hook.getValues("expirationDate"))).diff(moment(), "days") ||
    0;

  const expirationHours =
    moment(hook.getValues("expirationDate")).diff(moment(), "hours") %
      expirationDays || 0;

  const expirationMinutes =
    moment(hook.getValues("expirationDate")).diff(moment(), "minutes") %
      expirationHours || 0;

  const exist1 = hook.watch("founders").some((i) => i.linkedIn);
  const exist2 = hook.watch("founders").some((i) => i.name);
  const exist3 = hook.watch("founders").some((i) => i.role);

  function handleDownload() {
    if (hook.getValues("deck")) {
      handleDownloadFile(hook.getValues("deck"));
    }
  }

  return (
    <>
      <ModalVideo
        open={OpenVideoPitchModal}
        url={hook.watch("pitch") ?? ""}
        handleClose={() => setOpenVideoPitchModal(false)}
      />
      <Box display={"flex"} width="100%" flexWrap={"wrap"}>
        <Box
          paddingRight={mobileButton ? "0px" : "56px"}
          flex={mobileButton ? 1 : 0}
        >
          {mobileButton && (isAdminRole || isStartupValidToEdit) && (
            <Stack direction="row" justifyContent="end">
              <ButtonReadMode isMobile changeMode={changeMode} />
            </Stack>
          )}
          <Stack alignItems="center" paddingTop={mobileButton ? "24px" : "0px"}>
            <BigAvatar
              fileMulter={hook.watch("logo") as any}
              letter={hook.watch("startupName")[0]}
            />
            <Stack width={"100%"} spacing={2} marginTop="16px">
              <ItemSideAvatar
                icon={
                  <ShowChart
                    fontSize="large"
                    sx={{ color: "#4A7C2F", width: "22px", marginRight: "4px" }}
                  />
                }
                title="Estatus:"
                name={translateStatusStartups(hook.watch("status"))}
              />
            </Stack>
          </Stack>
        </Box>
        <Box flex={1} paddingTop={breakpoint ? "28px" : "0px"}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={1}
          >
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"center"}
              flexWrap={"wrap"}
              gap={1}
            >
              <Typography sx={styles.textCompany}>
                {hook.watch("startupName")}
              </Typography>
              <Stack
                direction={"row"}
                alignItems="center"
                flexWrap={"wrap"}
                justifyContent={"center"}
                rowGap={1}
              >
                <Chip
                  sx={styles.industryChip}
                  size="small"
                  label={
                    <Typography sx={styles.chipText}>
                      {hook.getValues("industry")}
                    </Typography>
                  }
                />
                {isAdminRole && hook.watch("womanFounding") === "Si" && (
                  <Chip
                    sx={styles.womanChip}
                    size="small"
                    label={
                      <Typography sx={styles.chipText}>
                        Mujer en el founding team
                      </Typography>
                    }
                  />
                )}
              </Stack>
            </Stack>

            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{ marginTop: mobileButton ? "1rem" : "" }}
            >
              {hook.watch("expirationDate") &&
                !isRouteProfile &&
                isOpenToInvest &&
                expirationDays > 0 && (
                  <>
                    <Box sx={mainStyles.timerIconBox}>
                      <AvTimer sx={styles.timerColor} />
                    </Box>
                    <Typography sx={styles.timerText}>
                      Abierta durante:
                    </Typography>
                    <Box sx={styles.timerTimeBox}>
                      <Box sx={styles.timerInnerTimeBox}>
                        <Typography sx={styles.timerTimeText}>
                          {expirationDays +
                            " días, " +
                            expirationHours +
                            " hrs, " +
                            expirationMinutes +
                            " min"}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}
              {!mobileButton && (isAdminRole || isStartupValidToEdit) && (
                <ButtonReadMode changeMode={changeMode}>
                  Acciones
                </ButtonReadMode>
              )}
            </Stack>
          </Stack>

          <Typography sx={styles.oneliner}>{hook.watch("oneliner")}</Typography>
          <Typography sx={styles.subtitle}>Acerca de la Startup</Typography>
          <Grid container>
            <Grid container direction="column" item xs={12} md={5} xl={3}>
              <ItemProperty
                icon={<StackedLineChart sx={mainStyles.icon} />}
                label="Etapa de Inversión"
                text={hook.getValues("stage")}
                doesHasSpace
              />
              <ItemProperty
                icon={<Apartment sx={mainStyles.icon} />}
                label="Tipo de Empresa"
                text={hook.getValues("companyType")}
                doesHasSpace
              />
            </Grid>
            <Grid container direction="column" item xs={12} md={6} xl={3}>
              <ItemProperty
                icon={<Language sx={mainStyles.icon} />}
                label="Sitio Web"
                text={<LinkCustom value={hook.watch("website")} isRedirect />}
                doesHasSpace
              />
              <ItemProperty
                icon={<LocationOn sx={mainStyles.icon} />}
                label="País"
                text={hook.getValues("country")}
                doesHasSpace
              />
            </Grid>
          </Grid>
          {hook.watch("solvingProblem") && (
            <Box sx={{ display: "flex", marginBottom: "8px" }}>
              <Info sx={mainStyles.icon} />
              <Typography sx={mainStyles.text}>
                ¿Qué problema resuelves?
                <Typography sx={mainStyles.label}>
                  {hook.getValues("solvingProblem")}
                </Typography>
              </Typography>
            </Box>
          )}
          {hook.watch("uniqueValueProposal") && (
            <Box sx={{ display: "flex", marginBottom: "8px" }}>
              <Info sx={mainStyles.icon} />
              <Typography sx={mainStyles.text}>
                ¿Cuál es tu propuesta de valor?
                <Typography sx={mainStyles.label}>
                  {hook.getValues("uniqueValueProposal")}
                </Typography>
              </Typography>
            </Box>
          )}
          <Grid container xs={12}>
            <ItemProperty
              icon={<TipsAndUpdates sx={mainStyles.icon} />}
              label="¿Cómo llegó a AngelHub?"
              text={hook.getValues("howFindAngelHub")}
              doesHasSpace
              enableParagraphStyle={breakpoint}
            />
          </Grid>
          {(isInvest || isAdminRole) && (
            <>
              <Divider sx={styles.divider} />
              <Typography sx={styles.subtitle}>Contacto</Typography>
              <Grid container>
                <Grid container direction="column" item xs={12} md={5} xl={3}>
                  <ItemProperty
                    icon={<Person sx={mainStyles.icon} />}
                    label="Nombre"
                    doesHasSpace
                    text={
                      hook.getValues("ownerName") +
                      " " +
                      hook.getValues("ownerLastName")
                    }
                    indentText
                  />

                  <ItemProperty
                    icon={<Phone sx={mainStyles.icon} />}
                    label="Teléfono"
                    text={
                      "+" +
                      hook.getValues("phoneCountryCode") +
                      " " +
                      FormatPhoneNumberSection(hook.getValues("phoneNumber"))
                    }
                    doesHasSpace
                  />
                </Grid>
                <Box>
                  <ItemProperty
                    icon={<Email sx={mainStyles.icon} />}
                    text={<LinkCustom value={hook.getValues("email")} />}
                    label="Email"
                    doesHasSpace
                  />
                </Box>
              </Grid>
            </>
          )}
          {exist1 && exist2 && exist3 && (
            <>
              <Divider sx={styles.divider} />
              <Typography sx={styles.subtitle}>Equipo Fundador</Typography>
            </>
          )}

          <Grid container direction={mobileButton ? "column" : "row"}>
            {hook.getValues("founders").map((founder) => (
              <Grid key={founder.linkedIn} item xs={12} md={3} xl={2}>
                <BoardItem
                  role={founder.role}
                  name={founder.name}
                  linkedinUrl={founder.linkedIn}
                />
              </Grid>
            ))}
          </Grid>
          {isValidToShowDeckAndPitch && (
            <>
              <Divider sx={styles.divider} />
              <Typography sx={styles.subtitle}>Deck y pitch</Typography>
              <Grid container>
                <Grid container direction="column" item xs={12} md={5} xl={3}>
                  <ItemProperty
                    icon={<FolderOpen sx={mainStyles.icon} />}
                    label="Deck"
                    text={
                      <LinkCustom
                        value={hook.getValues("deck.name") ?? ""}
                        onClick={handleDownload}
                      />
                    }
                    doesHasSpace
                  />
                </Grid>
                <Grid container direction="column" item xs={12} md={6} xl={3}>
                  <ItemProperty
                    icon={<Videocam sx={mainStyles.icon} />}
                    label="Video Pitch"
                    text={
                      <LinkCustom
                        isRedirect
                        value={hook.getValues("pitch") ?? ""}
                      />
                    }
                    doesHasSpace
                  />
                </Grid>
              </Grid>
            </>
          )}
          {isRouteProfile && (
            <>
              <Divider sx={{ ...styles.divider, width: "100%" }} />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography sx={{ fontSize: "14px", fontWeight: "400" }}>
                  ¿Necesitas más información?
                </Typography>
                <Link
                  onClick={() => history.push(`/details-startup/${id}`)}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "900",
                    color: "#4A88C2",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  underline={"none"}
                >
                  IR A DETALLE DE LA STARTUP
                  <ArrowForwardIcon />
                </Link>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
