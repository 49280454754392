import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";

import { ParamTabProvider } from "./components/Context/ContextParamTabs";
import { editOpportunityStartup } from "./components/MainCard/EditMode/schema";
import MainCard from "./components/MainCard/MainCard";
import { IMainCardForm } from "./components/MainCard/types";
import TabList from "./components/TabList/TabList";
import styles from "./styles";

const DetailsOpportunity: React.FC = () => {
  const { id } = useParams() as { id: string };

  const hook = useForm<IMainCardForm>({
    mode: "all",
    resolver: yupResolver(editOpportunityStartup),
    defaultValues: {
      companyType: "",
      country: "",
      email: "",
      howFindAngelHub: "",
      industry: "",
      oneliner: "",
      ownerLastName: "",
      ownerName: "",
      phoneCountryCode: "",
      phoneNumber: "",
      stage: "",
      startupName: "",
      status: "",
      deck: "",
      founders: [],
      logo: "",
      pitch: "",
      solvingProblem: "",
      uniqueValueProposal: "",
      website: "",
    },
  });

  return (
    <FormProvider {...hook}>
      <ParamTabProvider>
        <PageTitle title="Detalle de Oportunidad" />
        <MainLayout>
          <Box sx={styles.container}>
            <Box>
              <MainCard id={id} />
            </Box>
            <Box sx={styles.tab}>
              <TabList />
            </Box>
          </Box>
        </MainLayout>
      </ParamTabProvider>
    </FormProvider>
  );
};

export default DetailsOpportunity;
