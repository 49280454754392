import { SxProps, Theme } from "@mui/material";

const FunnelCardStyles: SxProps<Theme> = (theme: Theme) => ({
  "& .funnel-card": {
    padding: "16px !important",
    [theme.breakpoints.up("sm")]: {
      height: "386px !important",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "615px !important",
    },
    width: "100%",
  },
  "& .MuiTableCell-root ": {
    fontSize: "16px !important",
  },
  "& .MuiTableCell-head": {
    fontFamily: "Product Sans Medium !important",
    fontWeight: 500,
  },
});

export default FunnelCardStyles;
