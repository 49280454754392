import * as yup from "yup";

import { CAMPO_REQUERIDO } from "../../../../../../../utils/text/inputs";

export const dateAndExpiration = yup.object({
  expirationDate: yup
    .date()
    .required(CAMPO_REQUERIDO)
    .nullable(false)
    .typeError(CAMPO_REQUERIDO),
  dateAma: yup
    .date()
    .required(CAMPO_REQUERIDO)
    .nullable(false)
    .typeError(CAMPO_REQUERIDO),
});
