import { Menu, MenuItem } from "@mui/material";
import React, { ReactElement, useState } from "react";

import { OptionsDateFormat } from "./types";

const useFilter = (
  anchorEl: any,
  setAnchorEl: (value: any) => void,
): {
  value: OptionsDateFormat;
  handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void;
  options: ReactElement;
} => {
  const [value, setValue] = useState<OptionsDateFormat>("month");

  const handleChange = (value: OptionsDateFormat) => {
    setValue(value);
    setAnchorEl(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    value,
    handleOpenMenu,
    options: (
      <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem onClick={() => handleChange("today")}>Hoy</MenuItem>
        <MenuItem onClick={() => handleChange("month")}>Mes</MenuItem>
        <MenuItem onClick={() => handleChange("trimestre")}>Trimestre</MenuItem>
        <MenuItem onClick={() => handleChange("annual")}>Año</MenuItem>
      </Menu>
    ),
  };
};

export default useFilter;
