import { SxProps, Theme } from "@mui/material";

const MyPortfolioStyles: SxProps<Theme> = (theme: Theme) => ({
  "& .my-portfolio-card": {
    width: "100%",
    padding: "16px !important",
    [theme.breakpoints.up("xl")]: {
      height: "157px",
    },
    [theme.breakpoints.between("sm", "xl")]: {
      height: "250px",
    },
  },
});

export const MyPortfolioIndicatorStyles: SxProps<Theme> = (theme: Theme) => ({
  "& .MuiTypography-root.MuiTypography-h4": {
    fontFamily: "Product Sans !important",
    fontWeight: 700,
    color: "#74C24A",
    fontSize: "28px",
  },
  "& .MuiTypography-root.MuiTypography-subtitle1": {
    fontSize: "18px",
    fontWeight: 400,
  },
  "& .MuiDivider-root": {
    borderColor: "#D1D0D0",
  },
});

export default MyPortfolioStyles;
