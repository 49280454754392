import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import { updateUsersInvesting } from "../../../../../../../../services/startup";
import { displayToast } from "../../../../../../../../utils/helpers/displayToast";
import { FormType } from "../../../../../MainCard/types";

import FullFeaturedCrudGrid, { investor } from "./components/addInvestor";

export default function AddInvestorsModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) {
  const { id } = useParams() as { id: string };
  const [rows, setRows] = useState<investor[]>();
  const mainCardHook = useFormContext<FormType>();

  const handleSaveValues = (saveRows: investor[]) => {
    setRows(saveRows);
  };

  const onSubmit = async () => {
    try {
      const response = await updateUsersInvesting(rows as any[], id);
      mainCardHook.setValue("usersWantToInvest", rows as any[]);
      displayToast("success", response?.data?.message);
      handleClose();
    } catch (error: any) {
      displayToast("error", error?.data?.message);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="add-investors-modal-title"
        aria-describedby="add-investors-modal-description"
      >
        <DialogTitle id="add-investors-modal-title">
          {"Agregar Inversionista"}
        </DialogTitle>
        <DialogContent>
          <FullFeaturedCrudGrid safeValues={handleSaveValues} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            onClick={() => {
              onSubmit();
            }}
            autoFocus
          >
            Agregar listado
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
