import { Typography } from "@mui/material";
import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnHeaderTitle,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import BaseDataGrid from "../../../../../../components/BaseDataGrid/BaseDataGrid";
import { CustomSort, ModalMenu } from "../../../../../../components/DataGrid";
import { DEFAULT_ROWS_PAGE_SIZE } from "../../../../../../components/DataGrid/constants";
import PortfolioToolbar from "../../../../../../components/Toolbars/PortfolioToolbar/PortfolioToolbar";
import { EStatusReleasedOpportunity } from "../../../../../../constants";
import { useSelector } from "../../../../../../redux/typedHooks";
import { getMemberPortafolio } from "../../../../../../services/member";
import { ERolesCode } from "../../../../../routes/role-catalog";

import { columns, otherMembercolumns } from "./columns";

const InvestmentList: React.FC<{ id: string }> = ({ id }) => {
  const [toolbar, setToolbar] = useState(false);
  const history = useHistory();

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_ROWS_PAGE_SIZE);
  const [Search, setSearch] = useState<string>("");
  const [Stage, setStage] = useState<string>("");

  const [portafolioInfo, setPortafolioInfo] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const location = useLocation();

  useEffect(() => {
    setToolbar(
      location.pathname.split("/")[1] === "inversionistas" ? false : true,
    );
  }, []);

  useEffect(() => {
    async function getInvestList() {
      const result = await getMemberPortafolio(id);
      setPortafolioInfo(result.data);
      setLoading(false);
    }
    getInvestList();
  }, [id]);

  useEffect(() => {
    setPage(0);
    setPageSize(DEFAULT_ROWS_PAGE_SIZE);
  }, []);

  const renderModalMenu = (data: any) => {
    let detailsOptionsList = [
      {
        name: "Ver estados de cuenta",
        action: () => handleDetails(data.id),
      },
      {
        name: "Ver reportes",
        action: () => handleReports(data.id),
      },
      {
        name: "Adjuntos",
        action: () =>
          history.push(
            `/details-startup/${data.row.startupId}/?Tab=AttachedFiles`,
          ),
      },
    ];

    const values = data.row;

    const status = values.status;
    const isClosed = status === EStatusReleasedOpportunity.CLOSED_TO_INVESTMENT;

    if (isClosed) {
      detailsOptionsList = detailsOptionsList.filter(
        (i) => i.name === "Ver detalles",
      );
    }

    return <ModalMenu menuVert items={detailsOptionsList} />;
  };

  const { loggedUser } = useSelector((store: any) => store.loggedUser);

  const isOtherMember =
    loggedUser.user.role.code !== ERolesCode.MEMBER &&
    loggedUser.user?.member?._id !== id;

  const otherMemberColumn: GridColDef = {
    headerName: "Detalle de oportunidad",
    field: "algo",
    hideSortIcons: true,
    minWidth: 250,
    flex: 0.7,
    align: "left",
    renderCell: (data: any) => (
      <Typography
        onClick={() => {
          history.push(`/details-startup/${data.row.startupId}/`);
        }}
        color={"#4A88C2"}
        fontWeight={"900"}
        sx={{ cursor: "pointer", marginRight: "15px" }}
      >
        VER DETALLE
      </Typography>
    ),
    renderHeader: (params: GridColumnHeaderParams) => {
      const { colDef } = params;
      return (
        <>
          <GridColumnHeaderTitle
            label={colDef.headerName || colDef.field}
            description={colDef.description}
            columnWidth={200 || colDef?.width}
          />
          <CustomSort id={colDef.field} />
        </>
      );
    },
  };

  const isAdminOrMemberColumn = () => {
    const column = [];

    if (!toolbar) {
      column.push({
        headerName: "Etapa",
        field: "stage",
        hideSortIcons: true,
        minWidth: 170,
        maxWidth: 170,
        flex: 0.7,
      });
    }
    return column;
  };

  const newColumns = (): GridColDef[] =>
    isOtherMember
      ? [...otherMembercolumns(otherMemberColumn)]
      : [
          ...columns(),
          ...isAdminOrMemberColumn(),
          {
            headerName: "Acciones",
            field: "actions",
            flex: 0.4,
            minWidth: 100,
            maxWidth: 100,
            hideSortIcons: true,
            align: "center",
            renderCell: (data: any) => renderModalMenu(data),
          },
        ];

  function handleDetails(id: string) {
    history.push(`/details-startup/${id}?Tab=AttachedFiles`);
  }

  function handleReports(id: string) {
    history.push(`/details-startup/${id}?Tab=Reports`);
  }

  function handleFilter(data: any[]) {
    let values = data.filter((opportunity: any) => {
      return (
        opportunity.name.toLowerCase().includes(Search?.toLowerCase()) ||
        !Search
      );
    });
    if (Stage) {
      values = values.filter((i) => i.stage === Stage);
    }
    return values;
  }

  return (
    <>
      <BaseDataGrid
        page={page}
        pageSize={pageSize}
        onPageChange={(value) => setPage(value)}
        onPageSizeChange={(value) => setPageSize(value)}
        columns={newColumns()}
        loading={loading}
        rows={handleFilter(portafolioInfo)}
        getRowId={(row) => row.startupId}
        components={toolbar ? { Toolbar: null } : { Toolbar: PortfolioToolbar }}
        componentsProps={{
          toolbar: {
            onSearchChange: (value: string) => {
              setSearch(value);
            },
            onStageChange: (value: string) => {
              setStage(value);
            },
            resetPage: () => {
              setPageSize(DEFAULT_ROWS_PAGE_SIZE);
              setPage(0);
            },
            onSearchClear: () => {
              setSearch("");
            },
            hideIcons: true,
          },
        }}
      />
    </>
  );
};

export default InvestmentList;
