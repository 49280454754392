import { Box, Grid, Typography } from "@mui/material";

import logo from "../../assets/images/logo-color_negro.png";
import greenAreaImage from "../../assets/images/pre-register-green-area-image.png";
import { MemberFaceliftOnboardingStyle } from "../../pages/MemberFaceliftOnboarding/styles";

interface ILoginLayout {
  path?: string;
  withOutMarginBottom?: boolean;
}

const StartUpApplicationLayout: React.FC<ILoginLayout> = ({
  children,
  withOutMarginBottom,
}) => {
  return (
    <Grid
      container
      direction={{ xs: "column", lg: "row" }}
      justifyContent="flex-start"
      alignItems="stretch"
      sx={MemberFaceliftOnboardingStyle}
      columns={10}
    >
      {/* LEFT GRID */}
      <Grid
        item
        lg={5.05729}
        md={5}
        className="member-facelift-onboarding__left"
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          flexWrap={"nowrap"}
          height={"100%"}
        >
          <Grid item xs={8} className={"greenTextGrid"}>
            <Typography className={"greenTextStyles"}>
              En
              <span className={"angelhubTextStyle"}> AngelHub </span>
              somos el club de ángeles inversionistas más importante de
              Latinoamérica.
            </Typography>
            <Typography className={"greenTextStyles"}>
              Ofrecemos oportunidades de alto potencial, seguridad legal y
              relacionamiento con otros inversionistas.
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Box sx={{ textAlign: "center" }}>
              <img
                alt="angelhub-welcome"
                src={greenAreaImage}
                className={"angelhubImg"}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {/* RIGHT GRID */}
      <Grid
        item
        lg={4.9427}
        md={7}
        className="member-facelift-onboarding__right paddingStartupFaceLift"
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box>
            <img src={logo} alt="logo" className="logo" />
          </Box>
          <Grid
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100px",
              justifyContent: "center",
            }}
          >
            <Box>{children}</Box>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default StartUpApplicationLayout;
