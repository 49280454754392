import { Box, MenuItem, TextField, Typography } from "@mui/material";
import React from "react";

import { Screens } from "../StartupWizards";

interface IProps {
  changeOption: (value: Screens) => void;
}

const StartScreen: React.FC<IProps> = ({ changeOption }) => {
  const options: Screens[] = ["Si", "No"];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        maxWidth: "800px",
        height: "300px",
      }}
    >
      <Typography sx={{ fontSize: "24px", color: "#495463" }}>
        Bienvenido a AngelHub
      </Typography>
      <Typography
        sx={{
          marginTop: "24px",
          marginBottom: "29px",
          font: "normal normal normal 16px/19px Product Sans Medium",
          letterSpacing: "0px",
          color: "#5D5D5D",
        }}
      >
        Por favor responde estas preguntas para continuar, recuerda ser lo más
        honesto y preciso posible con tus respuestas ya que esto nos ayuda a
        asignar de mejor manera tu proyecto
      </Typography>
      <TextField
        variant="outlined"
        sx={{ maxWidth: "301px", width: "100%" }}
        label="¿Tu proyecto es una startup?"
        onChange={(e) => changeOption(e.target.value as Screens)}
        select
      >
        {options.map((i, index) => (
          <MenuItem key={index} value={i}>
            {i}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default StartScreen;
