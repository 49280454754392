import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Notices from "../../components/Dashboard/Notices";
import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";
import { getDashboardStartup } from "../../services/startup";
import ItemResourceLibrary from "../ResourceLibrary/ItemResourceLibrary/ItemResourceLibrary";

import Welcome from "./Welcome/Welcome";
import { dashboardStartupStyles } from "./styles";

const DashboardStartup = () => {
  const [DataDashboardStartup, setDataDashboardStartup] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const history = useHistory();
  const handleRedirect = () => {
    history.push("/news-feed");
  };

  useEffect(() => {
    getDashboardStartup().then(({ data }) => {
      setDataDashboardStartup(data);

      setIsLoading(false);
    });
  }, []);

  const loadingComponent = (
    <Box display="flex" justifyContent={"center"} alignItems="center">
      <CircularProgress />
    </Box>
  );

  let firstThree = [];
  firstThree =
    DataDashboardStartup.newsFeed && DataDashboardStartup.newsFeed.slice(0, 3);

  return (
    <>
      <PageTitle title="Dashboard" />
      <MainLayout
        sx={{
          flexDirection: "column",
          justifyContent: "start",
          padding: "24px !important",
          marginTop: "8rem !important",
        }}
      >
        {isLoading ? (
          loadingComponent
        ) : (
          <Box sx={dashboardStartupStyles} className="xd">
            <Grid container>
              <Grid item sm={12} md={8} lg={9}>
                <Paper sx={{ padding: "16px" }}>
                  <Welcome
                    startupId={DataDashboardStartup.startup._id}
                    startupName={DataDashboardStartup.startup.name}
                    startupStatus={DataDashboardStartup.startup.status}
                  />

                  {!isLoading ? (
                    <Grid container spacing={2}>
                      {firstThree.map((i: any, index: number) => (
                        <Grid item xs={12} sm={6} lg={4} key={index}>
                          <ItemResourceLibrary
                            key={index}
                            author={i.author}
                            categories={[]}
                            date={i.publishedAt}
                            description={i.descripcion}
                            image={i?.images[0]}
                            title={i.titulo}
                            urlRedirect={`/news-feed/${i.id}`}
                          />
                        </Grid>
                      ))}
                      <Box className="container-see-all">
                        <Typography
                          className="button-see-all"
                          onClick={handleRedirect}
                        >
                          Ver más
                        </Typography>
                      </Box>
                    </Grid>
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      No se ha encontrado información para mostrarte
                    </Box>
                  )}
                </Paper>
              </Grid>
              <Grid xs={12} md={4} lg={3} className="marginxs">
                <Box className="container-notice">
                  <Notices data={DataDashboardStartup.notice} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </MainLayout>
    </>
  );
};

export default DashboardStartup;
