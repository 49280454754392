import { styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";

export default styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    "& .MuiDialogTitleText-root": {
      fontSize: "24px",
      fontWeight: "400",
    },
  },
  "& .MuiDialogContent-root": {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    "& .MuiDialogContentText-root": {
      paddingBottom: theme.spacing(2),
      fontSize: "16px",
      fontWeight: "300",
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
  },
}));
