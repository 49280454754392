import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@mui/styles";
import { parse } from "query-string";
import { RouteChildrenProps } from "react-router";
import { Redirect } from "react-router-dom";

import LoginLayout from "../../components/LoginLayout";
import SignInMemberForm from "../../components/SignInMemberForm";

export const useStyles = makeStyles({
  card: {
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContent: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    marginLeft: 37,
    marginRight: 38,
    padding: 0,
  },
});

const SignInMember: React.FC<RouteChildrenProps> = () => {
  const { token, id } = parse(window.location.search);
  const styles = useStyles();

  if (!token || !id) {
    return <Redirect to="/login" />;
  }

  return (
    <LoginLayout showBanner={false}>
      <Card className={styles.card}>
        <CardContent className={styles.cardContent}>
          <SignInMemberForm params={{ token, id }} />
        </CardContent>
      </Card>
    </LoginLayout>
  );
};
export default SignInMember;
