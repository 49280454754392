import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { extendInvestmentDateSchema } from "../../../components/DialogExtendInvestment/schema";
import { TextInput } from "../../../components/Inputs";
import { TextFieldAutoComplete } from "../../../components/Inputs/TextInput/styled";
import { openModal } from "../../../redux/actions/modal";
import { useDispatch } from "../../../redux/typedHooks";
import { updateMemberById } from "../../../services/member";
import { displayToast } from "../../../utils/helpers/displayToast";
import { translateMemberShipType } from "../../../utils/text/translates";

import { IForm, IProps, memberShipTypes } from "./constants";
import { styles } from "./styles";

const ModalMembresia: React.FC<IProps> = ({
  open,
  onCancel,
  memberInfo,
  getUserByRole,
}) => {
  const dispatch = useDispatch();
  const hook = useForm<IForm>({
    resolver: yupResolver(extendInvestmentDateSchema),
    defaultValues: {
      memberShipType: "",
      date: new Date(),
    },
  });

  useEffect(() => {
    hook.setValue("date", memberInfo.member?.membershipEndDate);
    hook.setValue("memberShipType", memberInfo.member?.membershipType);
  }, [memberInfo, hook]);

  const handleCancel = () => {
    onCancel();
    hook.reset();
  };

  const handleUpdate = async ({ memberShipType, date }: IForm) => {
    dispatch(
      openModal({
        title: "Advertencia",
        description: "Estás seguro que deseas cambiar esta membresía?",
        action: async () => {
          try {
            await updateMemberById(memberInfo.member?._id, {
              membershipType: memberShipType,
              membershipEndDate: date,
              isActiveMemberShip: dayjs(date).isAfter(dayjs()) ? true : false,
            });
            hook.reset();
            displayToast(
              "success",
              "Se a actualizado correctamente la membresía",
            );
          } catch (error) {
            displayToast("error", "No se pudo actualizar la membresía.");
          } finally {
            getUserByRole();
            handleCancel();
          }
        },
      }),
    );
  };

  return (
    <>
      {open && (
        <Grid sx={{ ...styles.mainContainer }}>
          <Box sx={{ ...styles.subContainer }}>
            <Grid item xs={12} sm={12} md={12} sx={{ ...styles.Info }}>
              <Box sx={{ ...styles.mainBox }}>
                <Typography sx={{ ...styles.title }}>
                  Actualizar membresía
                </Typography>

                <Typography>
                  Tipo de membresía actual:{" "}
                  {translateMemberShipType(memberInfo.member?.membershipType)}
                </Typography>

                <Typography>{`Fecha de vencimiento ${dayjs(
                  memberInfo.member?.membershipEndDate,
                ).format("YYYY-MM-DD")}`}</Typography>

                <Controller
                  control={hook.control}
                  name="memberShipType"
                  render={({ field, fieldState }) => (
                    <TextInput
                      {...field}
                      fullWidth
                      label="Selecciona el tipo de membresía"
                      select
                      required
                      error={Boolean(fieldState.error)}
                      helperText={
                        Boolean(fieldState.error?.message) &&
                        fieldState.error?.message
                      }
                    >
                      {memberShipTypes.map((option, index) => (
                        <MenuItem key={index} value={option.type}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextInput>
                  )}
                />
                <Controller
                  control={hook.control}
                  name="date"
                  render={({ field, fieldState }) => {
                    return (
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="es"
                      >
                        <DatePicker
                          label="Fecha"
                          onChange={field.onChange}
                          value={field.value}
                          renderInput={(params) => {
                            return (
                              <TextFieldAutoComplete
                                {...params}
                                required
                                fullWidth
                                error={Boolean(fieldState.error)}
                                helperText={fieldState?.error?.message}
                              />
                            );
                          }}
                        />
                      </LocalizationProvider>
                    );
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    marginTop: "20px",
                    alignSelf: "flex-end",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      background: "white",
                      color: "#76c34a",
                    }}
                    onClick={handleCancel}
                  >
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={hook.handleSubmit(handleUpdate)}
                  >
                    Guardar
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default ModalMembresia;
