import { Box, Button, Grid, useMediaQuery } from "@mui/material";
import { FC, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { TextInput } from "../../../../../../../components/Inputs";
import { useLoading } from "../../../../../../../hooks/useLoading";
import { updateOpportunityFinancialInfo } from "../../../../../../../services/opportunitiesApplication";
import ButtonResponsive from "../../../../../../DetailsStartup/components/TabList/ButtonResponsive/ButtonResponsive";
import { RESPONSIVE_BUTTON } from "../../../../../validations";
import Layout from "../../../../Layout/Layout";
import { styles as mainStyles } from "../../../styles";
import { IFormFinancialInfo } from "../../../types";

interface IFinancialInfoEditMode {
  hook: UseFormReturn<IFormFinancialInfo>;
  id: string;
  changeMode: () => void;
  onReload: () => void;
}

export const EditMode: FC<IFinancialInfoEditMode> = ({
  changeMode,
  onReload,
  hook,
  id,
}) => {
  const breakpoint = useMediaQuery(RESPONSIVE_BUTTON);

  const [showResponsiveButton, setShowResponsiveButton] = useState(true);

  const { handleClose, handleOpen } = useLoading();

  const handleClick = (event: any) => {
    setShowResponsiveButton(!showResponsiveButton);
  };

  async function onSubmit(values: IFormFinancialInfo) {
    handleOpen();
    await updateOpportunityFinancialInfo(id, values);
    changeMode();
    onReload();
    handleClose();
  }

  const menu = (
    <Box display={"flex"} gap={"8px"}>
      <Button variant={"outlined"} onClick={changeMode}>
        Cancelar
      </Button>
      <Button variant={"contained"} onClick={hook.handleSubmit(onSubmit)}>
        Guardar
      </Button>
    </Box>
  );

  return (
    <Layout>
      <Layout.Button>{!breakpoint && menu}</Layout.Button>
      <Layout.Panel>
        <Grid container spacing={3}>
          {breakpoint && (
            <Grid item p={0} xs={12} sx={{ ...mainStyles.buttonsContainer }}>
              {!showResponsiveButton ? (
                <ButtonResponsive handleClick={handleClick} />
              ) : (
                menu
              )}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sx={{ display: "flex", gap: "40px", flexWrap: "wrap" }}
          >
            <Grid item xs={12} md={4} lg={3}>
              <Controller
                control={hook.control}
                name="requiredInvestment"
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    label="Inversión requerida"
                    fullWidth
                    type="numeric"
                    error={Boolean(fieldState.error?.message)}
                    helperText={
                      fieldState.error?.message && fieldState.error?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Controller
                control={hook.control}
                name="postMoney"
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    label="Valuación post money"
                    fullWidth
                    type="numeric"
                    error={Boolean(fieldState.error?.message)}
                    helperText={
                      fieldState.error?.message && fieldState.error?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Controller
                control={hook.control}
                name="valuation"
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    label="Valuación actual"
                    fullWidth
                    type="numeric"
                    error={Boolean(fieldState.error?.message)}
                    helperText={
                      fieldState.error?.message && fieldState.error?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Controller
                control={hook.control}
                name="monthlyRevenue"
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    label="Revenue mensual"
                    fullWidth
                    type="numeric"
                    error={Boolean(fieldState.error?.message)}
                    helperText={
                      fieldState.error?.message && fieldState.error?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Controller
                control={hook.control}
                name="monthlyBurnRate"
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    label="Burn rate"
                    fullWidth
                    type="numeric"
                    error={Boolean(fieldState.error?.message)}
                    helperText={
                      fieldState.error?.message && fieldState.error?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Controller
                control={hook.control}
                name="runway"
                render={({ field, fieldState }) => (
                  <TextInput
                    {...field}
                    label="Runway"
                    fullWidth
                    type="numeric"
                    error={Boolean(fieldState.error?.message)}
                    helperText={
                      fieldState.error?.message && fieldState.error?.message
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Layout.Panel>
    </Layout>
  );
};
