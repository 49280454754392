import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";

import DirectoryList from "./DirectoryList";

const Directory: React.FC = () => {
  return (
    <>
      <PageTitle title="Directorio de Startups Invertidas" />
      <MainLayout sx={{ flexDirection: "column", justifyContent: "start" }}>
        <DirectoryList />
      </MainLayout>
    </>
  );
};

export default Directory;
