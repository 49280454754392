import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { History } from "history";
import { useState } from "react";

import { useSelector } from "../../redux/typedHooks";
import { sendOtpCode } from "../../services/login";
import { displayToast } from "../../utils/helpers/displayToast";
import Form, { IFields } from "../Form";
import { TextInput } from "../Inputs";

import { requestCodeFieldSchema } from "./constants";
import styles from "./styles";

interface IRequestCodeForm {
  history?: History;
}

const RequestCodeForm: React.VFC<IRequestCodeForm> = ({ history }) => {
  const [loading, setloading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const { error } = useSelector((store) => store.loggedUser);

  const handleReturnToLogin = () => {
    history?.push("login");
  };

  const handleConfirm = (isConfirmed: boolean) => {
    displayToast("error", "Necesitas confirmar tu correo electrónico.");
    setShowError(isConfirmed);
  };

  const handleAnyError = (message: string) => {
    if (
      message === "You need to wait 1 minute to be able to request a new code"
    ) {
      displayToast(
        "error",
        "Necesitas esperar un minuto para poder solicitar un nuevo código",
      );
    } else {
      displayToast(
        "error",
        "Ocurrió un error enviando el código, revisa tu correo e intenta de nuevo",
      );
    }

    setShowError(false);
  };

  const handleSendCode = async (fields: IFields) => {
    setloading(true);
    try {
      const response = await sendOtpCode(fields.email as unknown as string);
      sessionStorage.setItem(
        "userCodeData",
        JSON.stringify({
          phone: response.data.message,
          mail: fields.email,
        }),
      );
      displayToast("success", "El código ha sido enviado correctamente");
      history?.push("login-code");
    } catch (error) {
      const err = error as any;
      const isConfirmed =
        err?.data?.message ===
        "You need to confirm your email to be able to log in";
      isConfirmed
        ? handleConfirm(isConfirmed)
        : handleAnyError(err?.data?.message);
    } finally {
      setloading(false);
    }
  };

  return (
    <Form onSubmit={handleSendCode} fields={requestCodeFieldSchema}>
      {({ handleSubmit, handleChange, fields }) => {
        return (
          <Grid container direction="column">
            <Typography variant="subtitle1" sx={styles.title}>
              Iniciar sesión con código de acceso
            </Typography>
            <Typography variant="subtitle2" sx={styles.subtitle}>
              Enviaremos un código de acceso a su teléfono registrado
            </Typography>
            <Grid item xs={12} sx={styles.topInputWrapper}>
              <TextInput
                required
                disabled={loading}
                label="Correo"
                value={fields.email.value}
                onChange={handleChange("email")}
                error={!!fields.email.errors.length}
                helperText={fields.email.errors.join(". ")}
                fullWidth
              />
              {(error || showError) && fields.email.errors.length <= 0 && (
                <Box sx={styles.errorWrapper}>
                  <small>
                    {showError
                      ? "Necesitas confirmar tu correo electrónico."
                      : error}
                  </small>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sx={styles.buttonWrapper}>
              {loading ? (
                <CircularProgress data-testid="loading-indicator" size={36} />
              ) : (
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  sx={styles.sendCodeButton}
                >
                  ENVIAR CÓDIGO
                </Button>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ ...styles.buttonWrapper, marginTop: "1.5rem" }}
            >
              <Typography
                sx={{
                  paddingTop: "0.6rem",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#5D5D5D",
                }}
              >
                Regresar al
              </Typography>
              <Box width="0.2rem"></Box>
              <Link onClick={() => handleReturnToLogin()} underline="none">
                <Typography
                  sx={{
                    paddingTop: "0.6rem",
                    fontSize: "16px",
                    fontWeight: "bold",
                    "&:hover": {
                      cursor: "pointer",
                    },
                    color: "#69BC41",
                  }}
                >
                  inicio de sesión
                </Typography>
              </Link>
            </Grid>
          </Grid>
        );
      }}
    </Form>
  );
};

export default RequestCodeForm;
