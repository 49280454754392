import TaskIcon from "@mui/icons-material/Task";
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Divider,
  Link,
} from "@mui/material";
import { Stack, fontStyle } from "@mui/system";
import React from "react";
import { useHistory } from "react-router-dom";

import { Action } from "../types";

interface IActionItem {
  data: Action;
}

const ActionItem: React.FC<IActionItem> = ({ data }) => {
  const history = useHistory();

  const handleSeeDetails = (id: string) => () => {
    const url = {
      OPPORTUNITY: `/details-startup-opportunity/${id}?Tab=FinancialInfo`,
      APPLICATION: `/details-member/${id}`,
      STARTUP: `/details-startup/${id}?Tab=FinancialInfo`,
      STARTUP_INVEST_ROUND: `/details-startup/${id}?Tab=InvestRound`,
    };

    history.push(url[data.type]);
  };
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <TaskIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              sx={{
                fontFamily: "Product Sans !important",
                fontStyle: "normal",
                fontWeight: "400 !important",
                fontSize: "14px",
                lineHeight: "22px",
                color: "#515151",
              }}
            >
              {data.text}
            </Typography>
          }
          secondary={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Typography
                sx={{
                  fontFamily: "Product Sans Medium !important",
                  fontStyle: "normal",
                  fontWeight: "700 ",
                  fontSize: "20px",
                  lineHeight: "22px",
                  color: "#515151",
                }}
              >
                {data.name}
              </Typography>
              <Link
                component={"button"}
                onClick={handleSeeDetails(data.id)}
                underline="none"
              >
                Ver
              </Link>
            </Stack>
          }
        />
      </ListItem>
      <Divider />
    </>
  );
};

export default ActionItem;
