export const styles = {
  textCommets: {
    fontWeight: "400",
    lineHeight: "22px",
    fontSize: "14px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxWidth: "550px",
    wordBreak: "break-all",
  },
  scoreTitle: {
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "24px",
  },
  textDescription: {
    fontFamily: "Product Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1A1A1A",
    width: "1000px",
  },
  boxDate: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    marginTop: "16px",
  },
  iconDate: {
    color: "#4A7C2F",
    width: "18px",
    marginRight: "4px",
  },
  date: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1A1A1A",
  },
  divider: {
    marginTop: "24px",
    marginBottom: "48px",
  },
  commentEvaluation: {
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "24px",
  },
  evaluation: {
    marginTop: "8px",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#343434",
    marginBottom: "24px",
  },
  generalEvaluation: {
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "28px",
    color: "#343434",
    marginBottom: "14px",
  },
  iconStar: {
    color: "#35AC65",
    fontSize: "32px",
    marginX: "4px",
  },
  starValue: {
    fontWeight: "900",
    fontSize: "32px",
    color: "#74C24A",
  },
  averageValue: {
    fontSize: "32px",
    color: "#74C24A",
  },
  nameText: {
    fontSize: "16px",
    color: "#676767",
  },
  evaluationsButtons: {
    border: "solid 1px #74C24A",
    color: "#74C24A",
    width: "32px",
    height: "32px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    "&.Mui-selected": {
      background: "#74C24A",
      color: "#4A7C2F !important",
    },
    "&.Mui-selected:hover": {
      background: "#74C24A",
      color: "#4A7C2F !important",
    },
    "&.Mui-disabled": {
      border: "solid 1px #74C24A",
      color: "#74C24A",
    },
  },
};
