import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";

import calendarIcon from "../../../assets/images/calendar-day-31.png";
import CardHeader from "../CardHeader";
import { LinkShowMore } from "../LinkShowMore";
import ModalSeeAll from "../ModalSeeAll/ModalSeeAll";

import CalendarItem from "./CalendarItem";
import MemberCalendarStyles from "./style";

export interface ICalendarData {
  date: string;
  id: string;
  nameStartup: string;
}

interface IProps {
  data: ICalendarData[];
}

const Calendar: React.FC<IProps> = ({ data }) => {
  const [OpenModalSeeAll, setOpenModalSeeAll] = useState<boolean>(false);

  const isMoreThanCero = data.length > 0;
  const principalFour = isMoreThanCero ? data.slice(0, 4) : [];

  return (
    <>
      <ModalSeeAll
        title="Juntas con Oportunidades"
        open={OpenModalSeeAll}
        onClose={() => setOpenModalSeeAll(false)}
      >
        {data.map(({ id, nameStartup, date }) => (
          <CalendarItem
            key={id}
            id={id}
            startup={nameStartup}
            date={dayjs(date).format("DD/MM/YYYY")}
          />
        ))}
      </ModalSeeAll>
      <Box sx={MemberCalendarStyles}>
        <Card className="appointments-card">
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ height: "100%" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              rowSpacing={1}
            >
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <CardHeader variant="h5">Juntas con Oportunidades</CardHeader>
                  <img
                    src={calendarIcon}
                    alt="calendar"
                    style={{ marginRight: "4px" }}
                  />
                </Stack>
              </Grid>
              {principalFour.length > 0 &&
                principalFour.map(({ id, nameStartup, date }) => (
                  <Grid item xs={12} key={id}>
                    <CalendarItem
                      id={id}
                      startup={nameStartup}
                      date={dayjs(date).format("DD/MM/YYYY")}
                    />
                  </Grid>
                ))}
            </Grid>
            {principalFour.length === 0 && (
              <Box className="noElements">
                <Typography
                  fontSize={"20px"}
                  textAlign={"center"}
                  color={"#515151"}
                >
                  No se han encontrado juntas con oportunidades
                </Typography>
              </Box>
            )}
            {data.length > 5 && (
              <LinkShowMore
                onClick={() => setOpenModalSeeAll(true)}
                underline="none"
              >
                Ver más
              </LinkShowMore>
            )}
          </Stack>
        </Card>
      </Box>
    </>
  );
};

export default Calendar;
