import { Button, Grid, MenuItem } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useParams } from "react-router-dom";

import { TextInput } from "../../../../../../../components/Inputs";
import { TextFieldAutoComplete } from "../../../../../../../components/Inputs/TextInput/styled";
import { useLoading } from "../../../../../../../hooks/useLoading";
import { useSelector } from "../../../../../../../redux/typedHooks";
import { updateMembershipById } from "../../../../../../../services/member";
import { displayToast } from "../../../../../../../utils/helpers/displayToast";
import { memberShipTypes } from "../../../../../../MemberShipList/components/constants";
import { styles as mainStyles } from "../../../styles";
import { FormMembership } from "../Membership";
import { EMemberships, VALID_ROLES_TO_EDIT_MEMBERSHIP } from "../validation";

import { styles } from "./styles";
interface IFinancialInfoEditMode {
  hook: UseFormReturn<FormMembership>;
  changeMode: () => void;
  onReload: () => void;
}

export const ACTIVE_OPTIONS = [
  {
    name: "Activa",
    value: true,
  },
  {
    name: "Inactiva",
    value: false,
  },
];

export const EditMode: FC<IFinancialInfoEditMode> = ({
  changeMode,
  hook,
  onReload,
}) => {
  const { handleClose, handleOpen } = useLoading();
  const { id } = useParams() as { id: string };

  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  const validRolesToChangeMembership = VALID_ROLES_TO_EDIT_MEMBERSHIP.includes(
    user.role.code,
  );

  async function handleOnSubmit(fields: FormMembership): Promise<void> {
    handleOpen();

    try {
      const config = {
        date: fields.membershipEndDate,
        isActive: dayjs(fields.membershipEndDate).isAfter(dayjs()),
        membershipType: fields.membershipType as EMemberships,
      };

      await updateMembershipById(id, config as any);
      displayToast("success", "Se actualizó correctamente");
    } catch (error) {
      displayToast("error", "Hubo un error al actualizar la membresía.");
    } finally {
      handleClose();
      onReload();
      changeMode();
    }
  }

  return (
    <>
      <Grid xs={12} sx={{ ...mainStyles.buttonsContainer, gap: "8px" }}>
        <Button
          variant="outlined"
          sx={styles.buttonResponsive}
          onClick={changeMode}
        >
          CANCELAR
        </Button>
        <Button
          variant="contained"
          sx={{
            ...styles.buttonResponsive,
          }}
          onClick={hook.handleSubmit(handleOnSubmit)}
        >
          GUARDAR
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          gap: "40px",
          flexWrap: "wrap",
        }}
      >
        {/* <Grid xs={12} md={4} lg={3}>
          <Controller
            control={hook.control}
            name={"isActive"}
            render={({ field }) => (
              <TextInput
                {...field}
                disabled={!validRolesToChangeMembership}
                label="Estatus de membresía"
                select
                fullWidth
              >
                {ACTIVE_OPTIONS.map((i, index) => (
                  <MenuItem value={i.value as any} key={index}>
                    {i.name}
                  </MenuItem>
                ))}
              </TextInput>
            )}
          />
        </Grid> */}
        <Grid xs={12} md={4} lg={3}>
          <Controller
            control={hook.control}
            name={"membershipType"}
            render={({ field }) => (
              <TextInput
                {...field}
                label="Tipo de membresía"
                required
                select
                disabled={!validRolesToChangeMembership}
                fullWidth
              >
                {memberShipTypes.map((i) => (
                  <MenuItem value={i.type} key={i.type}>
                    {i.name}
                  </MenuItem>
                ))}
              </TextInput>
            )}
          />
        </Grid>
        <Grid xs={12} md={4} lg={3}>
          <Controller
            control={hook.control}
            name={"membershipEndDate"}
            render={({ field, fieldState }) => (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="es"
              >
                <DatePicker
                  {...field}
                  label="Fecha"
                  renderInput={(params) => {
                    return (
                      <TextFieldAutoComplete
                        {...params}
                        required
                        fullWidth
                        disabled={!validRolesToChangeMembership}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState?.error?.message}
                      />
                    );
                  }}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
