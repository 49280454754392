import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  SxProps,
} from "@mui/material";
import { Dispatch, SetStateAction, useRef, useState } from "react";

import { TextInput } from "../../../../../../../components/Inputs";
import InputFile from "../../../../../../../components/Inputs/InputFile/InputFile";
import SplitButton from "../../../../../../../components/SplitButton/SplitButton";
import StandardDialog from "../../../../../../../components/StandardDialog";
import { useLoading } from "../../../../../../../hooks/useLoading";
import { addFilesMember } from "../../../../../../../services/member";
import { displayToast } from "../../../../../../../utils/helpers/displayToast";
import { extensiosImage } from "../../../../../../DetailsStartup/components/TabList/Tabs/AttachedFiles/FileCard/FileCard";
import { IValue } from "../../../../../../DetailsStartup/components/TabList/Tabs/AttachedFiles/validations";
import mainStyles from "../../../../../styles";
import {
  EFileTypesMember,
  EFileTypesMemberAdmin,
  VALID_TYPE_FILE_TO_UPLOAD_WITH_USER_MEMBER,
  VALID_TYPE_TO_UPLOAD_STATEMENS,
} from "../validations";

import { BuildFileTypeTextAll } from "./types";

interface IProps {
  xs: SxProps;
  setReload: () => void;
  users: any[];
  id: string;
  isMobile?: boolean;
  icon?: any;
  openAdminFileModal: boolean;
  handleCloseModal: () => void;
  startupSelected: IValue | null;
  startupSelectedLabel: string;
  setStartupSelected: Dispatch<SetStateAction<IValue | null>>;
  setStartupSelectedLabel: Dispatch<SetStateAction<string>>;
  startups: IValue[];
  adminFile: File | null;
  adminFileError: string;
  handleAdminUploadFile: () => void;
  openMemberFileModal: boolean;
  memberFile: File | null;
  memberFileError: string;
  handleMemberUploadFile: () => void;
  handleUploadButton: () => void;
  handleChangeFilePayment: any;
}

const ButtonAddFileMember: React.FC<IProps> = ({
  setReload,
  xs,
  children,
  users,
  id,
  icon,
  isMobile,
  openAdminFileModal,
  handleCloseModal,
  startupSelected,
  startupSelectedLabel,
  setStartupSelected,
  setStartupSelectedLabel,
  startups,
  adminFile,
  adminFileError,
  handleAdminUploadFile,
  openMemberFileModal,
  memberFile,
  memberFileError,
  handleMemberUploadFile,
  handleUploadButton,
  handleChangeFilePayment,
}) => {
  const [selectedType, setSelectedType] = useState<EFileTypesMember | null>(
    null,
  );
  const ref = useRef<HTMLInputElement>(null);

  const { handleClose: handleCloseLoading, handleOpen } = useLoading();

  function handleSelection(fileType: EFileTypesMember) {
    setSelectedType(fileType);
    if (fileType === "payments") {
      handleUploadButton();
    } else {
      ref.current?.click();
    }
  }

  async function handleAddFile(file: File, userId?: string) {
    handleOpen();
    const form = new FormData();
    form.append("memberId", id);
    form.append("file", file);
    form.append(
      "type",
      selectedType as EFileTypesMember | EFileTypesMemberAdmin,
    );
    try {
      await addFilesMember(form);
      displayToast("success", "Se subió correctamente");
    } catch (error: any) {
      displayToast(
        "error",
        `Hubo un error al subir el archivo. ${error.data.message}`,
      );
    } finally {
      setSelectedType(null);
      setReload();
      (ref.current as any).value = "";
      handleCloseLoading();
    }
  }

  async function handleChangeFile(event: React.ChangeEvent<HTMLInputElement>) {
    if (
      event.target.files?.length &&
      event.target.files[0].size > 25 * 1024 * 1024
    ) {
      displayToast(
        "error",
        "El archivo es demasiado pesado. El peso límite es de 25MB.",
      );
      return;
    }
    if (
      event.target.files?.length &&
      VALID_TYPE_FILE_TO_UPLOAD_WITH_USER_MEMBER.includes(
        selectedType as EFileTypesMember | EFileTypesMemberAdmin,
      )
    ) {
      const file = event.target.files[0];
      handleAddFile(file);
      setSelectedType(null);
    }
  }

  const isRoleToUpdateStatemens = VALID_TYPE_TO_UPLOAD_STATEMENS.includes(
    users[0]?.user?.role?.code,
  );

  return (
    <>
      <input
        hidden
        ref={ref}
        type="file"
        accept={extensiosImage.join(",")}
        multiple
        onChange={handleChangeFile}
      />
      <SplitButton
        placement="left-start"
        title="Subir Archivo"
        isMobile={isMobile}
        icon={icon}
        options={Object.values(
          isRoleToUpdateStatemens ? EFileTypesMemberAdmin : EFileTypesMember,
        ).map((value) => ({
          label: BuildFileTypeTextAll(value) || "Archivo desconocido",
          visible: true,
          onClick: () => handleSelection(value),
        }))}
      />
      <StandardDialog open={openAdminFileModal} onClose={handleCloseModal}>
        <DialogTitle>Archivo</DialogTitle>
        <DialogContent>
          <DialogContentText fontSize="16px" fontWeight="300">
            Por favor carga el archivo que desees subir en el campo a
            continuación:
          </DialogContentText>
          <Grid
            container
            xs={12}
            paddingTop="17px"
            display="flex"
            sx={{
              flexDirection: {
                xs: "column",
                md: "row",
              },
            }}
            justifyContent="space-between"
          >
            <Grid item xs={12} md={5.5}>
              <Autocomplete
                value={startupSelected}
                inputValue={startupSelectedLabel}
                onChange={(_, data: any) => {
                  setStartupSelected(data);
                }}
                onInputChange={(_, value) => {
                  if (value) setStartupSelectedLabel(value);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                fullWidth
                options={startups}
                getOptionLabel={(i) => i.name}
                renderInput={({
                  InputLabelProps,
                  InputProps,
                  inputProps,
                  ...rest
                }) => (
                  <TextInput
                    {...rest}
                    InputProps={{
                      ...InputProps,
                      style: { height: 48, paddingTop: "6px" },
                    }}
                    inputProps={inputProps}
                    InputLabelProps={{
                      ...InputLabelProps,
                      style: { top: "-3.5px" },
                    }}
                    label="Inversionista seleccionado"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={5.5}>
              <InputFile
                value={adminFile}
                label="Subir comprobante de pago"
                onChange={handleChangeFilePayment}
                errorMessage={adminFileError}
                limitMB={5}
                accept={[".pdf"]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingX: "24px",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              marginBottom: {
                xs: "15px",
                sm: 0,
              },
              width: {
                xs: "100%",
                sm: "auto",
              },
            }}
            onClick={handleCloseModal}
          >
            Cancelar
          </Button>
          <Button
            sx={{
              ...mainStyles.actionsButtons,
              marginLeft: {
                xs: "0px !important",
                sm: "auto",
              },
            }}
            variant="contained"
            onClick={handleAdminUploadFile}
          >
            Subir Archivo
          </Button>
        </DialogActions>
      </StandardDialog>
      <StandardDialog open={openMemberFileModal} onClose={handleCloseModal}>
        <DialogTitle>Subir comprobante de pago</DialogTitle>
        <DialogContent>
          <DialogContentText fontSize="16px" fontWeight="300">
            Por favor carga el comprobante de tu pago en el campo a
            continuación:
          </DialogContentText>
          <Grid
            container
            xs={12}
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
            paddingTop="17px"
            display="flex"
            justifyContent="flex-left"
          >
            <Grid item xs={12} sm={5.5}>
              <InputFile
                value={memberFile}
                label="Subir comprobante de pago"
                onChange={handleChangeFilePayment}
                errorMessage={memberFileError}
                limitMB={5}
                accept={[".pdf"]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleMemberUploadFile}>
            Subir Archivo
          </Button>
        </DialogActions>
      </StandardDialog>
    </>
  );
};

export default ButtonAddFileMember;
