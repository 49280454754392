const styles = {
  cardContent: {
    padding: {
      xs: "40px 0 36px 0 !important",
      sm: "40px 0 36px 0 !important",
    },
  },
};

export default styles;
