import { NotificationImportant } from "@mui/icons-material";
import { Badge, Typography, useMediaQuery } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { FC, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useScroll } from "../../hooks/useScroll";
import { ERoles } from "../../pages/routes/role-catalog";
import routes from "../../pages/routes/routes";
import { ILoggedUserReducer } from "../../redux/reducers/LoggedUser";
import { useSelector } from "../../redux/typedHooks";
import { displayToast } from "../../utils/helpers/displayToast";

import Drawer from "./Drawer/Drawer";
import DrawerNotification from "./DrawerNotification/DrawerNotification";
import NotificationCard from "./NotificationCard/NotificationCard";
import Toolbar from "./Toolbar";

const useStyles = makeStyles((theme: Theme) => ({
  layoutWrapper: {
    /*  textAlign: "center", */
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  drawerContainer: {
    display: "flex",
    flex: 1,
  },
  childrenWrapper: {
    flex: 1,
    display: "flex",
    background: "#F6F8F9 !important",
    flexDirection: "column",
    minHeight: "100vh",
    maxHeight: "100vh",
    maxWidth: "100%",
    overflowX: "hidden",
  },
  childrenHeaderContainer: {
    backgroundColor: "#FFF",
    display: "flex",
    flexDirection: "row",
    marginTop: 64,
    paddingLeft: 30,
    height: 48,
    // boxShadow: "0px 10px 13px 2px rgba(0,0,0,0.2)", //TODO: Remove this comment in case shadow will be used
  },
  childrenHeaderIconContainer: {
    marginLeft: -10,
  },
  childrenHeaderIcon: {
    color: "#000",
    fontSize: "2rem",
  },
}));

/**
 * El componente que genera todos los elementos básicos para el layout
 *
 */
const DashboardLayout: FC = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const callbackPage = queryParams.get("callbackPage");
  const { ref } = useScroll();

  const drawerClose = useMediaQuery(theme.breakpoints.down("md"));
  const {
    loggedUser: { user },
  } = useSelector((store) => store.loggedUser as ILoggedUserReducer);

  const { notifications } = useSelector((store) => store.notificationReducer);

  const [isOpened, setIsOpened] = useState(
    window.screen.width > theme.breakpoints.values["md"],
  );

  const [isOpenedNotifications, setIsOpenedNotifications] = useState(false);

  useEffect(() => {
    setIsOpened(!drawerClose);
  }, [drawerClose]);

  useEffect(() => {
    if (!notifications.length) {
      setIsOpenedNotifications(false);
    }
  }, [notifications.length]);

  useEffect(() => {
    if (ref) {
      (ref as any)?.current.scrollTo(0, 0);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (callbackPage) {
      history.push(callbackPage);
    }
  }, [callbackPage]);

  const handleDrawer = useCallback(() => {
    setIsOpened(!isOpened);
  }, [setIsOpened, isOpened]);

  if (location.pathname === "/select-account") {
    return <main className={classes.childrenWrapper}>{children}</main>;
  }

  const navbarItems = [
    {
      Option: "NotificationImportant",
      Icon: (
        <Badge badgeContent={notifications.length} color="primary">
          <NotificationImportant sx={{ color: "gray" }} />
        </Badge>
      ),
      action: () => {
        const existNotifications = notifications.length;
        if (existNotifications) {
          setIsOpenedNotifications(true);
        } else {
          displayToast(
            "success",
            "No existe ninguna notificación pendiente por el momento.",
            "uniqueId",
          );
        }
      },
    },
  ];

  const topItems = routes.filter(
    (route) =>
      route.isMenuItem &&
      !route.isBottom &&
      !route.isAdminTool &&
      route.roles.includes(user?.role?.code as ERoles),
  );

  const bottomItems = routes.filter(
    (route) =>
      route.isMenuItem &&
      route.isBottom &&
      route.roles.includes(user?.role?.code as ERoles),
  );

  const adminToolItems = routes.filter(
    (route) =>
      route.isMenuItem &&
      route.isAdminTool &&
      route.roles.includes(user?.role?.code as ERoles),
  );

  return (
    <>
      <Toolbar
        isOpened={isOpened}
        handleDrawer={handleDrawer}
        options={navbarItems}
      />

      <div className={classes.drawerContainer}>
        <Drawer
          setIsOpened={setIsOpened}
          isOpened={isOpened}
          handleDrawer={handleDrawer}
          items={topItems}
          adminToolsItems={adminToolItems}
          bottomItems={bottomItems}
        />

        <DrawerNotification
          isOpen={isOpenedNotifications}
          onClose={() => setIsOpenedNotifications(false)}
        >
          <Typography variant="h4" pb="22px">
            Notificaciones
          </Typography>
          {notifications.map((i, index) => (
            <NotificationCard
              handleCloseDrawerNotification={() =>
                setIsOpenedNotifications(false)
              }
              key={index}
              type={i}
            />
          ))}
        </DrawerNotification>

        <main ref={ref} className={classes.childrenWrapper}>
          {children}
        </main>
      </div>
    </>
  );
};

export default DashboardLayout;
