import * as React from "react";

interface IContext {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
}

const Context = React.createContext<IContext>({
  open: false,
  handleClose: () => null,
  handleOpen: () => null,
});

export const ProviderLoading: React.FC = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(!open);
  };

  const value = React.useMemo(() => {
    return {
      open,
      handleClose,
      handleOpen,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useLoading = () => {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error("Debe ir dentro del provider Loading");
  }
  return context;
};
