import axios from "axios";

import { getNewAccessToken } from "../services/refreshToken";
import getLocalAccessToken from "../utils/helpers/get-local-access-token";
import getLocalRefreshToken from "../utils/helpers/get-local-refresh-token";

import { EEvents, emitter } from "./emitter";

export function initAxios() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  axios.interceptors.request.use(
    async (config) => {
      const accessToken = getLocalAccessToken();

      if (accessToken) {
        config.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      } else {
        delete config.headers.Authorization;
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401) {
        const refreshToken = getLocalRefreshToken();

        if (!refreshToken) {
          emitter.emit(EEvents.LOGOUT);
          return;
        }

        if (refreshToken) {
          originalRequest._retry = true;

          try {
            const {
              data: {
                payload: { accessToken },
              },
            } = await getNewAccessToken(refreshToken);

            sessionStorage.setItem("accessToken", accessToken);

            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${accessToken}`;
            return axios(originalRequest);
          } catch (error) {
            emitter.emit(EEvents.LOGOUT);
            return Promise.reject(error);
          }
        }
      }
      return Promise.reject(error.response);
    },
  );
}
export const getToken = (): string | null => {
  return (
    sessionStorage.getItem("accessToken") || localStorage.getItem("accessToken")
  );
};
