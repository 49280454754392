import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BallotIcon from "@mui/icons-material/Ballot";
import { Button } from "@mui/material";
import React from "react";

interface Props {
  handleClick: (event: any) => void;
  isInvested?: boolean;
}

const ButtonResponsive: React.FC<Props> = ({ handleClick, isInvested }) => {
  return (
    <Button
      variant="contained"
      disabled={isInvested}
      startIcon={<BallotIcon />}
      onClick={handleClick}
      endIcon={<ArrowDropDownIcon />}
      sx={{ backgroundColor: "#74C24A", width: "96px", color: "white" }}
    />
  );
};

export default ButtonResponsive;
