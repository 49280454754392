import { ArrowForward } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { TextInput } from "../../../../../../components/Inputs";
import { getMemberReports } from "../../../../../../services/member";
import { IFileObject } from "../../../../../../utils/types/common";
import { styles as mainStyles } from "../../styles";
import { FileCard } from "../MyDocuments/FileCard/FileCard";
import { EFileTypes } from "../MyDocuments/validations";

import { Modal } from "./Modal/Modal";
import { styles } from "./styles";

export interface IOpportunityId {
  id: string;
}

interface IReportObject {
  startupId: string;
  reports: IFileObject[];
}

interface IStartupObject {
  name: string;
  _id: string;
}

export const VALID_TYPE_FILE_REPORTS = [EFileTypes.reports];

const Reports = () => {
  const breakpointXS = useMediaQuery("(max-width: 600px)");
  const breakpointLG = useMediaQuery("(max-width: 1400px)");
  const { id } = useParams() as IOpportunityId;
  const [Files, setFiles] = useState<IReportObject[]>([]);
  const [startups, setStartups] = useState<IStartupObject[]>([]);
  const [startupSelected, setStartupSelected] = useState<IStartupObject | null>(
    null,
  );
  const [openAllModal, setOpenAllModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [filesToShow, setFilesToShow] = useState<IFileObject[]>([]);
  const [startupSelectedLabel, setStartupSelectedLabel] = useState<string>("");

  useEffect(() => {
    const fetchReportsInfo = async () => {
      const reportsInfo = await getMemberReports(id);
      setFiles(reportsInfo.data.reports);
      setStartups(reportsInfo.data.startups);
    };
    setLoading(true);
    fetchReportsInfo().finally(() => {
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    if (startupSelected)
      setFilesToShow(filterFiles(Files, startupSelected._id));
    else setFilesToShow([]);
  }, [startupSelected]);

  function filterFiles(data: IReportObject[], startup?: string) {
    const files: IFileObject[] = data.filter(
      (item) => item.startupId === startup,
    )[0].reports;

    return files;
  }

  const cardDisplayLimit = breakpointXS ? 2 : breakpointLG ? 3 : 4;

  if (loading) {
    return (
      <Box
        minHeight={"200px"}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container>
      {openAllModal && (
        <Modal openModal={setOpenAllModal} files={filesToShow} />
      )}
      <Grid
        container
        justifyContent={{ xs: "right", sm: "space-between" }}
        gap={2}
        xs={12}
      >
        <Autocomplete
          value={startupSelected}
          inputValue={startupSelectedLabel}
          onChange={(_, data: any) => {
            setStartupSelected(data);
          }}
          onInputChange={(_, value) => {
            setStartupSelectedLabel(value ?? "");
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          fullWidth
          sx={{ maxWidth: "200px" }}
          options={startups}
          getOptionLabel={(i) => i.name}
          renderInput={({
            InputLabelProps,
            InputProps,
            inputProps,
            ...rest
          }) => (
            <TextInput
              {...rest}
              InputProps={{
                ...InputProps,
                style: { height: 48, paddingTop: "6px" },
              }}
              inputProps={inputProps}
              InputLabelProps={{
                ...InputLabelProps,
                style: { top: "-3.5px" },
              }}
              label="Startup seleccionada"
            />
          )}
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          ...mainStyles.Info,
          alignItems: "center",
          marginBottom: "15px",
          marginTop: "15px",
          width: "100%",
        }}
      >
        {filesToShow.length > 0 ? (
          <Stack direction="column">
            <Grid container gap={"10px"}>
              {filesToShow.slice(0, cardDisplayLimit).map((file, index) => (
                <Box key={index} display={"flex"} flexDirection={"column"}>
                  <FileCard
                    onlyShowDownload
                    fileObject={{ ...file, fileType: EFileTypes.reports }}
                  />
                  {index === cardDisplayLimit - 1 && (
                    <Stack
                      direction="row"
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        onClick={() => setOpenAllModal(true)}
                        endIcon={
                          <Box display="flex" alignContent="center">
                            <ArrowForward />
                          </Box>
                        }
                        sx={styles.downloadButton}
                      >
                        Ver Todo
                      </Button>
                    </Stack>
                  )}
                </Box>
              ))}
            </Grid>
          </Stack>
        ) : (
          <Box display={"flex"} justifyContent="center" width={"100%"}>
            <Typography
              component={"h4"}
              variant="h4"
              mt={"20px"}
              textAlign={"center"}
            >
              No hay archivos disponibles
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default Reports;
