import * as yup from "yup";

import { CAMPO_REQUERIDO } from "../../../../../utils/text/inputs";
import { URL_VALIDA_FORM } from "../../../../../utils/text/resetForm";
import { VALIDATE_WEB_REGEX } from "../../../../DetailsOpportunity/components/MainCard/EditMode/schema";

export const complementSchema = yup.object({
  monthlyBurnRate: yup.string().required(CAMPO_REQUERIDO),
  runway: yup.string().required(CAMPO_REQUERIDO),
  solvingProblem: yup.string().required(CAMPO_REQUERIDO),
  uniqueValueProposal: yup.string().required(CAMPO_REQUERIDO),
  pitch: yup
    .string()
    .matches(VALIDATE_WEB_REGEX, {
      message: URL_VALIDA_FORM,
      excludeEmptyString: true,
    })
    .required(CAMPO_REQUERIDO),
});
