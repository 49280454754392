import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const createMemberPassword = (
  token: string,
  password: string,
  memberApplicationId: string,
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: "/members",
    method: "POST",
    data: { token, password, memberApplicationId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

const createStartupPassword = (
  token: string,
  password: string,
  startupApplicationId: string,
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: "/startups",
    method: "POST",
    data: { token, password, startupApplicationId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

export { createMemberPassword, createStartupPassword };
