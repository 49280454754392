import { Paper, Typography, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useHistory } from "react-router-dom";

import { useSelector } from "../../../redux/typedHooks";

import {
  ENotificationType,
  INotificationCardProps,
  notifications,
} from "./constants";
import { styles } from "./styles";

const NotificationCard: React.FC<INotificationCardProps> = (props) => {
  const history = useHistory();
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const { type, handleCloseDrawerNotification } = props;

  const { icon: Icon, text, title } = notifications[type];

  const redirectNotification: Record<ENotificationType, string> = {
    [ENotificationType.MEMBER_MEMBERSHIP_NOTIFICATION]: `/member-profile/${
      (user?.member as any)?._id
    }?Tab=Membership`,
    [ENotificationType.STARTUP_FILL_OUT_YOUR_INFORMATION_NOTIFICATION]: `/startup-profile/${
      (user?.startup as any)?._id
    }`,
    [ENotificationType.STARTUP_REVIEW_YOUR_INFORMATION_NOTIFICATION]: "",
    [ENotificationType.ADMIN_REVIEW_YOUR_INFORMATION_NOTIFICATION_ADMIN]: "",
    [ENotificationType.ALL_USERS_NEW_NOTICE]: "/dashboard",
  };

  function handleRedirect() {
    const url = redirectNotification[type];
    if (url) {
      history.push(url);
      handleCloseDrawerNotification();
    }
  }

  return (
    <Paper
      component={"button"}
      elevation={0}
      sx={{ borderRadius: "18px", cursor: "pointer", border: "none" }}
      onClick={handleRedirect}
    >
      <Box
        display={"flex"}
        gap={"14px"}
        p={2}
        position="relative"
        alignItems={"center"}
      >
        <Box>
          <IconButton size="large">
            <Icon sx={{ fontSize: "24px" }} />
          </IconButton>
        </Box>
        <Box display={"flex"} flexDirection="column" textAlign={"left"}>
          <Typography sx={styles.title}>{title}</Typography>
          <Typography sx={{ fontSize: "14px" }}>{text}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default NotificationCard;
