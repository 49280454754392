import { paletteColors } from "../../styles/theme";

const styles = {
  title: {
    color: "#5D5D5D",
    alignSelf: "start",
    fontSize: "24px",
  },
  subtitle: {
    color: "#5D5D5D",
    alignSelf: "start",
    fontSize: "16px",
    marginBottom: "28px",
    "@media (max-height: 700px)": {
      marginBottom: "18px",
    },
  },
  topInputWrapper: {
    maxHeight: "56px",
    marginBottom: "42px",
    "@media (max-height: 700px)": {
      marginBottom: "35px",
    },
  },
  errorWrapper: {
    color: paletteColors.error,
    textAlign: "left",
  } as const,
  sendCodeButton: {
    width: "100%",
    height: "2.3rem",
    textTransform: "none",
  } as const,
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
};

export default styles;
