export const currencies = [
  {
    value: true,
    label: "Si",
  },
  {
    value: false,
    label: "No",
  },
];
