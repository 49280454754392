import { Box, Button, Grid, useMediaQuery } from "@mui/material";
import { FC, useState } from "react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";

import { TextInput } from "../../../../../../../components/Inputs";
import { refreshNotification } from "../../../../../../../redux/actions/notifications";
import {
  useDispatch,
  useSelector,
} from "../../../../../../../redux/typedHooks";
import { updateFinancialInfo } from "../../../../../../../services/startup";
import { displayToast } from "../../../../../../../utils/helpers/displayToast";
import mainStyles from "../../../../../styles";
import ButtonResponsive from "../../../ButtonResponsive/ButtonResponsive";
import Layout from "../../../Layout/Layout";
import { RESPONSIVE_BUTTON } from "../../../types";
import { FormType } from "../FinancialInfo";

import { VALID_USER_TO_EDIT_ALL_FIELDS } from "./types";

interface IFinancialInfoEditMode {
  hook?: any;
  changeMode: () => void;
}

export const EditMode: FC<IFinancialInfoEditMode> = ({ changeMode, hook }) => {
  const { id } = useParams() as any;
  const breakpoint = useMediaQuery(RESPONSIVE_BUTTON);
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const [showResponsiveButton, setShowResponsiveButton] = useState(true);

  const validRolesToEditAllFields = VALID_USER_TO_EDIT_ALL_FIELDS.includes(
    user.role.code,
  );

  const handleClick = (event: any) => {
    setShowResponsiveButton(!showResponsiveButton);
  };

  async function handleOnSubmit(fields: FormType): Promise<void> {
    const {
      requiredInvestment,
      valuation,
      valuationPostMoney,
      monthlyRevenue,
      burnRate,
      runway,
    } = fields;
    const data = {
      requiredInvestment,
      valuation,
      postMoney: valuationPostMoney,
      monthlyRevenue,
      monthlyBurnRate: burnRate,
      runway: String(runway),
    };

    try {
      await updateFinancialInfo(id, data);
      dispatch(refreshNotification());
      displayToast("success", "Se a actualizado correctamente");
    } catch (error) {
      displayToast("error", "Hubo un error al actualizar");
    } finally {
      changeMode();
    }
  }

  const menu = (
    <Box display={"flex"} gap={"8px"}>
      <Button variant={"outlined"} onClick={changeMode}>
        Cancelar
      </Button>
      <Button variant={"contained"} onClick={hook.handleSubmit(handleOnSubmit)}>
        Guardar
      </Button>
    </Box>
  );

  return (
    <Layout>
      <Layout.Button>{!breakpoint && menu}</Layout.Button>
      <Layout.Panel>
        <Grid container spacing={3}>
          {breakpoint && (
            <Grid item xs={12} sx={mainStyles.buttonContainer}>
              {!showResponsiveButton ? (
                <ButtonResponsive handleClick={handleClick} />
              ) : (
                menu
              )}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sx={{ display: "flex", gap: "40px", flexWrap: "wrap" }}
          >
            {validRolesToEditAllFields && (
              <>
                <Grid xs={12} md={4} lg={3}>
                  <Controller
                    control={hook.control}
                    name={"requiredInvestment"}
                    render={({ field, fieldState }) => (
                      <TextInput
                        type="numeric"
                        {...field}
                        fullWidth
                        label={"Inversión requerida"}
                        required
                        error={Boolean(fieldState.error)}
                        helperText={
                          Boolean(fieldState.error?.message) &&
                          fieldState.error?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12} md={4} lg={3}>
                  <Controller
                    control={hook.control}
                    name={"valuationPostMoney"}
                    render={({ field, fieldState }) => (
                      <TextInput
                        type="numeric"
                        {...field}
                        fullWidth
                        label={"Valuación post money"}
                        required
                        error={Boolean(fieldState.error)}
                        helperText={
                          Boolean(fieldState.error?.message) &&
                          fieldState.error?.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid xs={12} md={4} lg={3}>
                  <Controller
                    control={hook.control}
                    name={"valuation"}
                    render={({ field, fieldState }) => (
                      <TextInput
                        type="numeric"
                        {...field}
                        fullWidth
                        label={"Valuación actual"}
                        required
                        error={Boolean(fieldState.error)}
                        helperText={
                          Boolean(fieldState.error?.message) &&
                          fieldState.error?.message
                        }
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid xs={12} md={4} lg={3}>
              <Controller
                control={hook.control}
                name={"monthlyRevenue"}
                render={({ field, fieldState }) => (
                  <TextInput
                    type="numeric"
                    {...field}
                    fullWidth
                    label={"Revenue mensual"}
                    required
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid xs={12} md={4} lg={3}>
              <Controller
                control={hook.control}
                name={"burnRate"}
                render={({ field, fieldState }) => (
                  <TextInput
                    type="numeric"
                    {...field}
                    fullWidth
                    label={"Burn rate"}
                    required
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid xs={12} md={4} lg={3}>
              <Controller
                control={hook.control}
                name={"runway"}
                render={({ field, fieldState }) => (
                  <TextInput
                    type="numeric"
                    {...field}
                    fullWidth
                    label={"Runway"}
                    required
                    error={Boolean(fieldState.error)}
                    helperText={
                      Boolean(fieldState.error?.message) &&
                      fieldState.error?.message
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Layout.Panel>
    </Layout>
  );
};
