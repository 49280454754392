import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Doughnut } from "react-chartjs-2";

import { GraphPropertiesV2 } from "./types";

interface DonutChartProps {
  values: GraphPropertiesV2[];
}

const ChartDoughnut: React.FC<DonutChartProps> = (props) => {
  const { values } = props;

  const dataSet = {
    labels: values.map((i) => i._id).slice(0, 5),
    datasets: [
      {
        data: values.map((i) => i.count).slice(0, 5),
        backgroundColor: [
          "#4AA5C2",
          "#74C24A",
          "#E87C2E",
          "#E3C526",
          "#35AC65",
          "#e887d9",
          "#87e6e8",
          "#8a16f0",
        ],
      },
    ],
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      position="relative"
      sx={{ width: "250px", height: "250px", margin: "0 auto" }}
    >
      <Box
        sx={{
          fontFamily: "Product Sans Black",
          fontStyle: "normal",
          fontWeight: "900",
          fontSize: "48px",
          lineHeight: "64px",
          transform: "translate(-50%,-50%)",
        }}
        position={"absolute"}
        top="48%"
        left={"50%"}
      >
        <Typography textAlign={"center"} fontWeight={"900"} fontSize={"40px"}>
          {values.reduce((acc, next) => (acc += next.count), 0)}
        </Typography>
        <Typography textAlign={"center"} fontSize={"20px"}>
          Inversiones
        </Typography>
      </Box>
      <Doughnut
        data={dataSet}
        width={"250px"}
        height={"250px"}
        options={{
          cutout: "70%",
          responsive: true,
          plugins: { legend: { display: false } },
        }}
      />
    </Box>
  );
};

export default ChartDoughnut;
