import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import { TextInput } from "../../../../../../../components/Inputs";
import StandardDialog from "../../../../../../../components/StandardDialog";

import { userSchema } from "./schema";

interface IProps {
  open: boolean;
  onCancel: () => void;
  onAccept: (file: File, userId?: string) => void;
  users: any[];
  file: File;
}

const DialogUser: React.FC<IProps> = ({
  open,
  onCancel,
  onAccept,
  users,
  file,
}) => {
  const hook = useForm<{ user: string }>({
    resolver: yupResolver(userSchema),
    defaultValues: {
      user: "",
    },
  });

  const handleUpdate = (form: { user: string }) => {
    const user = users.find((i) => i.value === form.user);
    onAccept(file, user.value);
    onCancel();
    hook.reset();
  };
  const handleCancel = () => {
    onCancel();
    hook.reset();
  };

  return (
    <StandardDialog open={open}>
      <DialogTitle>Selecciona</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Por favor selecciona al usuario que desea subir su documento.
        </DialogContentText>
        <Controller
          control={hook.control}
          name="user"
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              label="Inversor"
              autoFocus
              fullWidth
              required
              select
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error) && fieldState.error?.message
              }
            >
              {users
                .filter((i) => i.value !== "Startup")
                .map((i, index) => (
                  <MenuItem key={index} value={i.value}>{`${i.name}`}</MenuItem>
                ))}
            </TextInput>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button variant="contained" onClick={hook.handleSubmit(handleUpdate)}>
          Seleccionar
        </Button>
      </DialogActions>
    </StandardDialog>
  );
};

export default DialogUser;
