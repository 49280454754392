import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { ReactElement } from "react";

import { styles } from "./styles";

interface IItemPropertyProps {
  icon: ReactElement;
  label: string;
  text?: string | ReactElement;
  doesHasSpace?: boolean;
  enableParagraphStyle?: boolean;
  indentText?: boolean;
  enableBigTextStyle?: boolean;
}

const ItemProperty: React.FC<IItemPropertyProps> = ({
  icon,
  label,
  text,
  doesHasSpace,
  enableParagraphStyle,
  enableBigTextStyle,
  indentText,
}) => {
  return (
    <Stack
      direction={enableParagraphStyle ? "column" : "row"}
      marginBottom="0.5rem"
      spacing={1}
      alignItems={"center"}
    >
      <Stack direction={"row"}>
        {icon}
        {doesHasSpace && <br />}
        <Typography sx={styles.label}>{label + ":"}</Typography>
      </Stack>
      {typeof text === "string" ? (
        <Typography sx={styles.text}>{text}</Typography>
      ) : (
        <Box
          marginLeft={indentText ? "20px" : "0px"}
          display="flex"
          alignItems="center"
        >
          {text}
        </Box>
      )}
    </Stack>
  );
};

export default ItemProperty;
