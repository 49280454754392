import { Dispatch } from "redux";

export interface PayloadOpenModal {
  title: string;
  description: string;
  action?: () => void;
  actionClose?: () => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  hideSecondaryButton?: boolean;
}

export const OPEN_MODAL = "[MODAL]:OPEN_MODAL";
export const openModal =
  (payload: PayloadOpenModal) => (dispatch: Dispatch) => {
    return dispatch({
      type: OPEN_MODAL,
      payload,
    });
  };

export const CLOSE_MODAL = "[MODAL]:CLOSE_MODAL";
export const closeModal = (dispatch: Dispatch) => {
  return dispatch({
    type: CLOSE_MODAL,
  });
};
