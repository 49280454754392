import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { getToken } from "../config";

export const getResourceLibraryLikes = (
  id: string,
): Promise<AxiosResponse<any>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/resourceLibraryLikes/" + id,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return axios(config);
};

export const changeStatusResourceLibraryLikes = async (data: {
  userId: string;
  resourceLibraryId: string;
  value: boolean;
}): Promise<void> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/resourceLibraryLikes/change-status/",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };

  await axios(config);
};
