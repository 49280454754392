import { Card } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { RouteChildrenProps } from "react-router-dom";

import LoginForm from "../../components/LoginForm";
import LoginLayout from "../../components/LoginLayout";

import styles from "./styles";

const Login: React.VFC<RouteChildrenProps> = ({ history }) => {
  return (
    <LoginLayout showBanner>
      <Card>
        <CardContent sx={styles.cardContent}>
          <LoginForm />
        </CardContent>
      </Card>
    </LoginLayout>
  );
};

export default Login;
