import { Typography, Grid } from "@mui/material";
import { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { TextInput } from "../../../../../../../components/Inputs";
import { styles as mainStyles } from "../../../styles";
import { FormType } from "../schemas";
import { styles } from "../styles";

interface IFinalCommentInfo {
  comment: string;
  editable: boolean;
  indicator: number;
  hook: UseFormReturn<FormType>;
  refresh: boolean;
}

export const FinalComment: React.FC<IFinalCommentInfo> = ({
  comment,
  editable,
  hook,
  indicator,
  refresh,
}) => {
  useEffect(() => {
    hook.setValue(`finalComment.${indicator}.comment`, comment);
  }, [comment, hook, indicator, refresh]);
  return (
    <Grid item xs={12}>
      <Typography sx={{ ...mainStyles.title }}>Comentario final</Typography>
      <br />
      {editable ? (
        <Typography sx={styles.textCommets}>{comment}</Typography>
      ) : (
        <Controller
          control={hook.control}
          name={`finalComment.${indicator}.comment`}
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              required
              multiline
              sx={{ maxWidth: "680px" }}
              rows={3}
              error={Boolean(fieldState.error)}
              helperText={
                fieldState.error?.message
                  ? fieldState.error?.message
                  : "Caracteres " + field.value.length + "/250"
              }
              fullWidth
            />
          )}
        />
      )}
    </Grid>
  );
};
