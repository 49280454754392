import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles({
  inputLabel: {
    transform: "translate(14px, -3px) scale(0.75) !important",
  },
});

const styles = {
  toolbar: {
    display: "flex",
    padding: "24px 22px 30px 16px",
    "@media (min-width: 500px) and (max-width: 700px)": {
      flexDirection: "row",
    },
    flexDirection: {
      sm: "row",
      xs: "column",
    },
  },
  title: {
    color: "#515151",
    fontSize: "16px",
    paddingBottom: "40px",
  },
  searchField: {
    minWidth: "212px",
    height: "48px",
  },
  iconsWrapper: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "space-between",
    flexWrap: {
      lg: "wrap",
    },
    gap: "20px",
  },
  numberText: {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "900",
    fontFamily: "Product Sans Black !important",
    color: "#515151",
  },
  resultsText: {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: "400",
    fontFamily: "Product Sans !important",
    color: "#515151",
  },
} as const;

export default styles;
