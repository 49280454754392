import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import ModalSeeAll from "../../../components/Dashboard/ModalSeeAll/ModalSeeAll";
import CardHeader from "../CardHeader";
import { LinkShowMore } from "../LinkShowMore";

import NoticesItem from "./NoticeItem";
import NoticesStyles from "./style";

export interface INoticesData {
  Autor: string;
  Descripcion: string;
  Titulo: string;
  createdAt: string;
  id: number;
  publishedAt: string;
  updatedAt: string;
}

interface INoticesProps {
  data: INoticesData[];
}

const Notices: React.FC<INoticesProps> = ({ data }) => {
  const [OpenModalSeAll, setOpenModalSeAll] = useState<boolean>(false);

  const truncatedData = [...data].splice(0, 4);

  return (
    <>
      <ModalSeeAll
        title="Avisos"
        open={OpenModalSeAll}
        onClose={() => setOpenModalSeAll(false)}
      >
        {data.map((notice) => (
          <Grid item xs={12} key={notice.id}>
            <NoticesItem notice={notice} />
            <br />
          </Grid>
        ))}
      </ModalSeeAll>
      <Box sx={NoticesStyles}>
        <Card className="news-card">
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ height: "100%" }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              rowSpacing={2}
            >
              <Grid item xs={12} className="card-header">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <CardHeader variant="h5">Avisos</CardHeader>
                  <ChatOutlinedIcon />
                </Stack>
              </Grid>
              {truncatedData.length > 0 &&
                truncatedData.map((notice) => (
                  <Grid item xs={12} key={notice.id}>
                    <NoticesItem notice={notice} />
                  </Grid>
                ))}
            </Grid>
            {truncatedData.length === 0 && (
              <Box className="noElements">
                <Typography
                  fontSize={"20px"}
                  textAlign={"center"}
                  color={"#515151"}
                >
                  No se han encontrado avisos
                </Typography>
              </Box>
            )}
            {data.length > 5 && (
              <LinkShowMore
                onClick={() => setOpenModalSeAll(true)}
                underline="none"
              >
                Ver más
              </LinkShowMore>
            )}
          </Stack>
        </Card>
      </Box>
    </>
  );
};

export default Notices;
