import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

import styles from "./styles";

interface IInstructionCard {
  icon: string;
  title?: string;
  subtitle: string;
  instructionStep: number;
}

const InstructionCard: React.FC<IInstructionCard> = ({
  icon,
  title,
  subtitle,
  instructionStep,
}) => {
  const isMobile = useMediaQuery("(max-width:1280px)");

  return (
    <Box
      sx={
        title
          ? styles.instructionBoxStyles
          : styles.instructionsWithoutTitleStyles
      }
    >
      <Grid
        container
        display="flex"
        sx={{
          flexDirection: {
            xs: `${title ? "row" : "column"}`,
            lg: "row",
          },
        }}
        height="100%"
      >
        <Grid
          item
          xs={title ? 3 : 12}
          md={title ? 2 : 12}
          lg={1.5}
          sx={{
            padding: 0,
            display: "flex",
            flexDirection: "row-reverse",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!title && isMobile ? (
            <>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                <img alt="letter-icon" src={icon} width="30px" />
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-start">
                <Box sx={styles.numberBoxStyles}>{instructionStep}</Box>
              </Grid>
            </>
          ) : (
            <img alt="letter-icon" src={icon} width="48px" />
          )}
        </Grid>
        <Grid
          item
          xs={title ? 9 : 12}
          md={title ? 10 : 12}
          lg={10.5}
          display="flex"
          flexDirection={title ? "column" : "row"}
          paddingY="3%"
        >
          {(title || (!title && !isMobile)) && (
            <Grid
              item
              xs={title ? 12 : 1.5}
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              {
                <Grid
                  item
                  xs={2}
                  sm={1}
                  sx={{
                    paddingLeft: {
                      xs: 0,
                      lg: "0px",
                    },
                  }}
                  display="flex"
                  alignItems="center"
                >
                  <Box sx={styles.numberBoxStyles}>{instructionStep}</Box>
                </Grid>
              }
              {title && (
                <Grid
                  item
                  xs={11}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: {
                      xs: "0.8rem",
                      md: "0.2rem",
                    },
                  }}
                >
                  <Typography sx={styles.cardTitleStyles}>{title}</Typography>
                </Grid>
              )}
            </Grid>
          )}
          <Grid
            item
            xs={title ? 12 : isMobile ? 12 : 10.5}
            display="flex"
            alignItems="flex-start"
          >
            <Typography
              sx={
                title
                  ? styles.cardSubtitleStyles
                  : styles.cardSubtitleWithoutTitleStyles
              }
            >
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InstructionCard;
