import { SxProps, Theme } from "@mui/material";

const OpportunitiesSummaryStyles: SxProps<Theme> = (theme: Theme) => ({
  "& .opportunities-card": {
    width: "100%",
    padding: "16px !important",
    [theme.breakpoints.up("lg")]: {
      height: "140px",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      height: "300px",
    },
  },
});

export const OpportunitiesSummaryItemStyles: SxProps<Theme> = (
  theme: Theme,
) => ({
  "& .MuiTypography-root.MuiTypography-h4": {
    fontFamily: "Product Sans !important",
    fontWeight: 700,
    color: "#74C24A",
    fontSize: "32px",
  },
  "& .MuiTypography-root.MuiTypography-subtitle1": {
    fontFamily: "Product Sans !important",
    fontWeight: 400,
    fontSize: "18px",
    color: "#515151",
  },
  "& .MuiDivider-root": {
    borderColor: "#D1D0D0",
  },
  "& .MuiDivider-vertical": {
    borderRightWidth: "2px",
    marginTop: "10px",
    marginBottom: "10px",
  },
});

export default OpportunitiesSummaryStyles;
