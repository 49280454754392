import { EStatusOpportunity } from "../../../constants";

export const FILTER_OPTIONS_OPPORTUNITY_STATUS = [
  {
    name: "Recibido",
    value: EStatusOpportunity.RECEIVED,
  },
  {
    name: "Analista Asignado",
    value: EStatusOpportunity.ASSIGNED_ANALYST,
  },
  {
    name: "Programación Analista",
    value: EStatusOpportunity.PROGRAMMING_ANALYST,
  },
  {
    name: "Programado Analista",
    value: EStatusOpportunity.PROGRAMMED_ANALYST,
  },
  {
    name: "Evaluado Analista",
    value: EStatusOpportunity.EVALUATED_ANALYST,
  },
  {
    name: "Partner Asignado",
    value: EStatusOpportunity.ASSIGNED_PARTNER,
  },
  {
    name: "Programacion Partner",
    value: EStatusOpportunity.PROGRAMMING_PARTNER,
  },
  {
    name: "Programado Partner",
    value: EStatusOpportunity.PROGRAMMED_PARTNER,
  },
  {
    name: "Evaluacion Partner",
    value: EStatusOpportunity.EVALUATED_PARTNER,
  },
  /*   {
    name: "Liberada",
    value: EStatusOpportunity.APPROVED_COMMITTEE,
  }, */
  {
    name: "Asignada comité",
    value: EStatusOpportunity.ASSIGNED_COMMITTEE,
  },
  {
    name: "Monitoreando",
    value: EStatusOpportunity.IS_MONITORING,
  },
  {
    name: "Rechazada",
    value: EStatusOpportunity.REJECTED,
  },
];
