/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

import { EStatusOpportunity } from "../../../../../../constants";
import { changeEdit } from "../../../../../../redux/actions/LoggedUser";
import { useDispatch, useSelector } from "../../../../../../redux/typedHooks";
import {
  getEvaluationAndComments,
  updateOpportunityEvaluation,
} from "../../../../../../services/opportunitiesApplication";
import { displayToast } from "../../../../../../utils/helpers/displayToast";
import ButtonResponsive from "../../../../../DetailsStartup/components/TabList/ButtonResponsive/ButtonResponsive";
import { ERolesCode } from "../../../../../routes/role-catalog";
import mainStyles from "../../../../styles";
import {
  RESPONSIVE_BUTTON,
  VALID_STATUS_TO_EVALUATE_ANALYST,
  VALID_STATUS_TO_EVALUATE_PARTNER,
  VALID_STATUS_TO_SHOW_EVALUATIONS_ANALYST,
  VALID_STATUS_TO_SHOW_EVALUATIONS_PARTNER,
} from "../../../../validations";
import Layout from "../../../Layout/Layout";
import { IMainCardForm } from "../../../MainCard/types";

import FormEvaluations from "./FormEvaluations/FormEvaluations";
import { EvaluationForm, TypeEvaluation } from "./FormEvaluations/consts";
import { itemFormSchema } from "./FormEvaluations/schema";
import { styles } from "./styles";

const EvaluationsStartup = () => {
  const { id } = useParams() as { id: string };

  const breakpoint = useMediaQuery(RESPONSIVE_BUTTON);
  const [reload, setReload] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [typeEvaluation, setTypeEvaluation] =
    useState<TypeEvaluation>("analyst");

  const { user } = useSelector((store) => store.loggedUser.loggedUser);
  const { edit } = useSelector((store) => store.loggedUser);
  const dispatch = useDispatch();

  const mainHook = useFormContext<IMainCardForm>();
  const hook = useForm<EvaluationForm>({
    mode: "all",
    resolver: yupResolver(itemFormSchema),
    defaultValues: {
      comments: [],
      evaluations: {
        analyst: {
          createdAt: null,
          updatedAt: null,
          name: null,
          evaluations: [],
        },
        partner: {
          createdAt: null,
          updatedAt: null,
          evaluations: [],
        },
      },
      score: {
        analyst: 0,
        partner: 0,
      },
    },
  });

  const status = mainHook.watch("status");

  const validStatusAnalystToEvaluate =
    VALID_STATUS_TO_EVALUATE_ANALYST.includes(status as EStatusOpportunity);

  const validStatusPartnerToEvaluate =
    VALID_STATUS_TO_EVALUATE_PARTNER.includes(status as EStatusOpportunity);

  const validShowEvaluationsAnalyst =
    VALID_STATUS_TO_SHOW_EVALUATIONS_ANALYST.includes(
      status as EStatusOpportunity,
    );
  const validShowEvaluationsPartner =
    VALID_STATUS_TO_SHOW_EVALUATIONS_PARTNER.includes(
      status as EStatusOpportunity,
    );

  const isValid =
    user.role.code === ERolesCode.PARTNER ||
    (user.role.code === ERolesCode.ANALYST && typeEvaluation === "analyst") ||
    user.role.code === ERolesCode.SUPER_ADMIN;

  const analystAssigned = mainHook.watch("analistAssigned");
  const partnerAssigned = mainHook.watch("partnerAssigned");

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isSuperAdmin = user.role.code === ERolesCode.SUPER_ADMIN;

  const isPartner = user.role.code === ERolesCode.PARTNER;
  const isValidPartner = partnerAssigned === user._id;

  const isAnalyst = user.role.code === ERolesCode.ANALYST;
  const isValidAnalyst = analystAssigned === user._id;

  const isValidToEditAnalystToCommentsPartner =
    typeEvaluation === "partner" && isPartner && validStatusPartnerToEvaluate;

  const isValidToEditCommentsAnalyst =
    typeEvaluation === "analyst" && isAnalyst && validShowEvaluationsAnalyst;

  const condition =
    ((isSuperAdmin || (isPartner && isValidPartner)) &&
      validStatusPartnerToEvaluate) ||
    ((isSuperAdmin || (isAnalyst && isValidAnalyst)) &&
      validStatusAnalystToEvaluate);

  const conditionToShowEvaluations =
    isSuperAdmin ||
    (isPartner && validShowEvaluationsPartner) ||
    (isAnalyst && validShowEvaluationsAnalyst);

  useEffect(() => {
    getEvaluationAndComments(id)
      .then(({ data }) => {
        const { comments, evaluations, score } = data.info;
        hook.setValue("comments", comments);
        hook.setValue(
          "evaluations.analyst.evaluations",
          evaluations.analyst.evaluations,
        );
        hook.setValue(
          "evaluations.analyst.createdAt",
          evaluations.analyst.createdAt,
        );
        hook.setValue(
          "evaluations.analyst.updatedAt",
          evaluations.analyst.updatedAt,
        );
        hook.setValue("evaluations.analyst.name", evaluations.analyst.name);
        hook.setValue(
          "evaluations.partner.evaluations",
          evaluations.partner.evaluations,
        );
        hook.setValue(
          "evaluations.partner.createdAt",
          evaluations.partner.createdAt,
        );
        hook.setValue(
          "evaluations.partner.updatedAt",
          evaluations.partner.updatedAt,
        );
        hook.setValue("evaluations.partner.name", evaluations.partner.name);
        hook.setValue("score.analyst", score.analyst);
        hook.setValue("score.partner", score.partner);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, reload]);

  useEffect(() => {
    setAnchorEl(null);
  }, [breakpoint]);

  function handleCloseMenu() {
    setAnchorEl(null);
  }
  function handleOpenMenu(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleReload() {
    setReload(!reload);
  }

  function handleOpenEdit() {
    setIsOpen(true);
    dispatch(changeEdit(true));
    handleCloseMenu();
  }

  function handleCancelEdit() {
    setIsOpen(false);
    handleCloseMenu();
    handleReload();
    dispatch(changeEdit(false));
  }

  async function handleEvaluate() {
    const body = {} as any;

    if (typeEvaluation === "analyst") {
      body.analystEvaluations = hook.getValues(
        "evaluations.analyst.evaluations",
      );
    }
    if (typeEvaluation === "partner") {
      body.partnerEvaluations = hook.getValues(
        "evaluations.partner.evaluations",
      );
    }

    const newBody = {
      ...body,
      comments: undefined,
      date: new Date(),
      type: typeEvaluation === "analyst" ? "ANALYST" : "PARTNER",
    };

    newBody.comments = hook.getValues("comments").map((i) => {
      if (!i.date) {
        i.date = new Date();
      }
      return i;
    });

    try {
      const { data } = await updateOpportunityEvaluation(id, newBody);
      if (data.data?.status) mainHook.setValue("status", data.data?.status);

      displayToast("success", "Se ha actualizado correctamente.");
      handleCancelEdit();
    } catch {
      displayToast(
        "error",
        "Favor de llenar la evaluación de manera correcta.",
      );
    }
  }

  const menu = (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseMenu}
    >
      {!edit && <MenuItem onClick={handleOpenEdit}>Editar</MenuItem>}
      {edit && <MenuItem onClick={handleCancelEdit}>Cancelar</MenuItem>}
      {edit && (
        <MenuItem disabled={!isValid} onClick={handleEvaluate}>
          Guardar
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <Layout>
      <Layout.Button>
        {!breakpoint &&
          ((isAnalyst
            ? isValidToEditCommentsAnalyst
            : isValidToEditAnalystToCommentsPartner) ||
            isSuperAdmin) && (
            <Box>
              {!isOpen ? (
                <Button
                  onClick={handleOpenEdit}
                  variant="contained"
                  sx={{ ...mainStyles.actionsButtons }}
                >
                  Editar
                </Button>
              ) : (
                <Box display="flex" gap="8px">
                  <Button onClick={handleCancelEdit} variant="outlined">
                    Cancelar
                  </Button>
                  <Button onClick={handleEvaluate} variant="contained">
                    Guardar
                  </Button>
                </Box>
              )}
            </Box>
          )}
      </Layout.Button>
      <Layout.Panel loading={loading}>
        {menu}
        {breakpoint &&
          (isAnalyst
            ? isValidToEditCommentsAnalyst
            : isValidToEditAnalystToCommentsPartner) && (
            <Box display={"flex"} justifyContent="flex-end">
              <ButtonResponsive handleClick={handleOpenMenu} />
            </Box>
          )}
        {conditionToShowEvaluations || show ? (
          <FormEvaluations
            setTypeEvaluation={setTypeEvaluation}
            typeEvaluation={typeEvaluation}
            hook={hook}
          />
        ) : (
          <Box sx={styles.boxEvaluations}>
            <Typography textAlign={"center"} component={"p"} variant="h4">
              Aún no se ha dejado evaluaciones para esta oportunidad
            </Typography>
            <Typography textAlign={"center"} component={"p"} variant="h5">
              Si deseas dejar una evaluación para esta oportunidad
            </Typography>
            <Typography textAlign={"center"} component={"p"} variant="h6">
              Haz click en el siguiente botón
            </Typography>
            <Button
              disabled={!condition}
              onClick={() => setShow(!show)}
              variant="contained"
            >
              Evaluar oportunidad
            </Button>
          </Box>
        )}
      </Layout.Panel>
    </Layout>
  );
};

export default EvaluationsStartup;
