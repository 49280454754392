import { Box, Button, Grid, useMediaQuery } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import * as React from "react";
import { Controller, UseFormReturn, useFormContext } from "react-hook-form";

import { TextFieldAutoComplete } from "../../../../../../../components/Inputs/TextInput/styled";
import { EStatusReleasedOpportunity } from "../../../../../../../constants";
import { getUpdateDetailAmaAndExpirationDate } from "../../../../../../../services/startup";
import { displayToast } from "../../../../../../../utils/helpers/displayToast";
import Layout from "../../../Layout/Layout";
import { RESPONSIVE_BUTTON } from "../../../types";
import { FormTypeInvestRound } from "../types";

interface IProps {
  hook: UseFormReturn<FormTypeInvestRound>;
  loading: boolean;
  changeInvestMode: (value: boolean) => void;
  handleReload: () => void;
  id: string;
}

const EditMode: React.FC<IProps> = ({
  changeInvestMode,
  hook,
  loading,
  handleReload,
  id,
}) => {
  const breakpoint = useMediaQuery(RESPONSIVE_BUTTON);
  const [expirationDate, setExpirationDate] = React.useState<Dayjs>(
    dayjs(new Date()),
  );
  const [dateAma, setDateAma] = React.useState<Dayjs>(dayjs(new Date()));

  const hookMain = useFormContext<any>();

  React.useEffect(() => {
    const expirationDate = hook.getValues("expirationDate");
    expirationDate &&
      setExpirationDate(dayjs(hook.getValues("expirationDate")));
    const dateAma = hook.getValues("dateAma");
    dateAma && setDateAma(dayjs(hook.getValues("dateAma")));
  }, []);

  function handleCancelEdit() {
    changeInvestMode(true);
    handleReload();
  }

  async function onSubmit(params: FormTypeInvestRound) {
    try {
      const newParams = {
        expirationDate: params.expirationDate,
        dateAma: dayjs(params.dateAma)?.startOf("D").add(18, "hours").toDate(),
        requiredInvestment: params.requiredInvestment,
        moic: params.moic,
      };

      const { data } = await getUpdateDetailAmaAndExpirationDate(id, newParams);

      if (data.value) {
        hookMain.setValue(
          "status",
          EStatusReleasedOpportunity.OPEN_TO_INVESTMENT,
        );
      }
      handleReload();
      changeInvestMode(true);
      displayToast("success", "Se actualizo correctamente");
    } catch (error) {
      displayToast("error", "Hubo un error al actualizar los valores");
    }
  }

  return (
    <Layout>
      <Layout.Button>
        {!breakpoint && (
          <Box display={"flex"} gap={"8px"}>
            <Button variant="outlined" onClick={handleCancelEdit}>
              Cancelar
            </Button>
            <Button variant="contained" onClick={hook.handleSubmit(onSubmit)}>
              Guardar
            </Button>
          </Box>
        )}
      </Layout.Button>
      <Layout.Panel loading={loading}>
        <Grid container spacing={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {breakpoint && (
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent={"flex-end"}
                p={0}
              >
                <Box display={"flex"} gap={"8px"}>
                  <Button variant="outlined" onClick={handleCancelEdit}>
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    onClick={hook.handleSubmit(onSubmit)}
                  >
                    Guardar
                  </Button>
                </Box>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="expirationDate"
                control={hook.control}
                render={({ field, fieldState }) => (
                  <DesktopDatePicker
                    label="Fecha de expiración"
                    inputFormat="DD/MM/YYYY"
                    minDate={expirationDate}
                    value={field.value}
                    onChange={field.onChange}
                    renderInput={(params) => (
                      <TextFieldAutoComplete
                        {...params}
                        fullWidth
                        error={Boolean(fieldState.error)}
                        helperText={fieldState?.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Controller
                name="dateAma"
                control={hook.control}
                render={({ field, fieldState }) => (
                  <DesktopDatePicker
                    label="Fecha AMA"
                    minDate={dateAma}
                    inputFormat="DD/MM/YYYY"
                    value={field.value}
                    onChange={field.onChange}
                    renderInput={(params) => (
                      <TextFieldAutoComplete
                        {...params}
                        fullWidth
                        error={Boolean(fieldState.error)}
                        helperText={fieldState?.error?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </LocalizationProvider>
        </Grid>
      </Layout.Panel>
    </Layout>
  );
};
export default EditMode;
