import { Box, FormHelperText } from "@mui/material";
import { useEffect, useRef, useState, VFC } from "react";

import { getSignedUrl } from "../../services/files";

export interface IFileObject {
  _id?: string;
  key: string;
  name: string;
  uri: string;
}

interface ICustomInputFile {
  fileObject: IFileObject;
  disable?: boolean;
  onFileChanged: (file: File) => void;
}

const CustomInputFile: VFC<ICustomInputFile> = (props) => {
  const [imageUri, setImageUri] = useState<string>("");
  const [errorFile, setErrorFile] = useState<string>("");

  const inputFileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.fileObject) {
      getSignedUrl(props.fileObject.key, props.fileObject.name).then(
        ({ data }) => {
          const uri = data.payload;
          setImageUri(uri);
        },
      );
    }
  }, [props.fileObject]);

  function handleInputFileClick() {
    inputFileRef.current?.click();
  }

  async function handleChangeFile(data: File[]) {
    if (data) {
      if (data[0].size > 2 * 1024 * 1024) {
        setErrorFile(
          "El archivo es demasiado pesado. El peso límite es de 2MB.",
        );
        return false;
      }

      setErrorFile("");
      props.onFileChanged(data[0]);

      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result && typeof reader.result === "string") {
          const base64String = reader.result;

          setImageUri(base64String);
        }
      };
      reader.readAsDataURL(data[0]);
    }
  }

  return (
    <>
      <Box
        sx={{
          borderRadius: "100px",
          maxHeight: "150px",
          maxWidth: "150px",
          width: "10rem",
          height: "10rem",
          backgroundColor: "gray",
          "&:hover": {
            filter: props.disable ? "" : "brightness(70%)",
            cursor: props.disable ? "" : "pointer",
          },
          backgroundImage: `url(${imageUri})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
        component="div"
        onClick={() => handleInputFileClick()}
      >
        <input
          disabled={props.disable}
          type="file"
          accept="image/png, image/gif, image/jpeg"
          hidden
          ref={inputFileRef}
          onChange={(event) => {
            if (event.target.files)
              handleChangeFile(Array.from(event.target.files));
          }}
        />
      </Box>
      {Boolean(errorFile) && (
        <FormHelperText error={Boolean(errorFile)}>{errorFile}</FormHelperText>
      )}
    </>
  );
};

export default CustomInputFile;
