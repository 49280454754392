import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { TextInput } from "../../../../../../components/Inputs";
import StandardDialog from "../../../../../../components/StandardDialog";

import { IRejectForm, rejectSchema } from "./schema";

interface IProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (value: string) => Promise<void>;
}

export default function DialogReject(props: IProps) {
  const { handleClose, handleSubmit, open } = props;

  const hook = useForm<IRejectForm>({
    resolver: yupResolver(rejectSchema),
    defaultValues: {
      comment: "",
    },
  });

  const handleCancel = () => {
    hook.reset();
    handleClose();
  };

  const handleSubmitForm = async (data: IRejectForm) => {
    await handleSubmit(data.comment);
    handleClose();
    hook.reset();
  };

  return (
    <StandardDialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>¿Deseas rechazar esta solicitud?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Por favor detalla el motivo del rechazo.
        </DialogContentText>
        <Controller
          control={hook.control}
          name="comment"
          render={({ field, fieldState }) => (
            <TextInput
              {...field}
              autoFocus
              margin="dense"
              label="Motivo"
              fullWidth
              multiline
              required
              rows={2}
              error={Boolean(fieldState.error)}
              helperText={
                Boolean(fieldState.error) && fieldState.error?.message
              }
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={hook.handleSubmit(handleSubmitForm)}
        >
          Rechazar
        </Button>
      </DialogActions>
    </StandardDialog>
  );
}
