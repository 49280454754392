import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { TabContext } from "@mui/lab";
import { Autocomplete, Badge, Box, Grid, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { GridToolbarDensitySelector } from "@mui/x-data-grid";
import { VFC, useState, ChangeEvent, useEffect } from "react";

import { IUser } from "../../../models/User";
import { FILTER_OPTIONS_APPLICATION_STATUS } from "../../../pages/Applications/constants";
import { ERoles } from "../../../pages/routes/role-catalog";
import { fetchUserByRole } from "../../../services/users";
import DrawerFilter from "../../DrawerFilter/DrawerFilter";
import { TextInput } from "../../Inputs";
import { demonyms } from "../../MemberForm/FormComponents/demonyms";
import PositionedMenu from "../../MenuPositioned/MenuPositioned";

import Tabs from "./Tabs";
import styles, { useStyles } from "./styles";

interface IApplicationToolbarProps {
  onSearchChange?: (value: string) => void;
  openRejectApplication?: (value: string) => void;
  onSearchClear?: () => void;
  onStatusChange?: (value: string) => void;
  onAsignedChange?: (value: string) => void;
  onDateChange?: (value: string) => void;
  onNacionaltyChange?: (value: string) => void;
  handleAceptApplication: () => void;
  handleRejectApplication: () => void;
  handleChangeAssigned: () => void;
  Tab: string;
  showOptions?: boolean;
  hideIcons?: boolean;
}
interface IStatus {
  name: string;
  value: string;
}
interface INacionalties {
  value: string;
  label: string;
}

const ApplicationToolbar: VFC<IApplicationToolbarProps> = (props) => {
  const {
    onSearchChange,
    onSearchClear,
    onDateChange,
    onStatusChange,
    onAsignedChange,
    onNacionaltyChange,
    handleAceptApplication,
    handleRejectApplication,
    handleChangeAssigned,
    showOptions,
    hideIcons,
    Tab,
  } = props;

  const [search, setSearch] = useState<string>("");
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [Users, setUsers] = useState<IUser[]>([]);

  const [Status, setStatus] = useState<IStatus | null>(null);
  const [StatusInput, setStatusInput] = useState<string>("");

  const [Asigned, setAsigned] = useState<any | null>(null);
  const [AsignedInput, setAsignedInput] = useState<string>("");

  const [Date, setDate] = useState<number | null>(null);
  const [DateInput, setDateInput] = useState<string>("");

  const [Nacionalty, setNacionalty] = useState<INacionalties | null>(null);
  const [NacionaltyInput, setNacionaltyInput] = useState<string>("");

  const breakpoint = useMediaQuery("(max-width: 1657px)");
  const isMobile = useMediaQuery("(max-width:730px)");

  const classes = useStyles();

  const badgeNumber = (): number => {
    let number = 0;
    if (Status) number++;
    if (Date) number++;
    if (Asigned) number++;
    if (Nacionalty) number++;
    return number;
  };

  useEffect(() => {
    (async () => {
      const { data } = await fetchUserByRole(ERoles.RL001);
      setUsers(data.payload as any as IUser[]);
    })();
  }, []);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    onSearchChange?.(event.target.value);
  };

  const handleChangeStatus = (_: any, newValue: any) => {
    setStatus(newValue);
    !openDrawer && onStatusChange && onStatusChange(newValue?.value);
  };
  const handleChangeAsigned = (_: any, newValue: any) => {
    setAsigned(newValue);
    !openDrawer && onAsignedChange && onAsignedChange(newValue?._id);
  };
  const handleChangeDate = (_: any, newValue: any) => {
    setDate(newValue);
    !openDrawer && onDateChange && onDateChange(newValue);
  };
  const handleChangeNacionalty = (_: any, newValue: any) => {
    setNacionalty(newValue);
    !openDrawer && onNacionaltyChange && onNacionaltyChange(newValue?.value);
  };

  const handleSearchClear = () => {
    setSearch("");
    onSearchClear && onSearchClear();
  };

  const handleFilter = () => {
    badgeNumber();
    onStatusChange && onStatusChange(Status?.value || "");
    onAsignedChange && onAsignedChange(Asigned?._id || "");
    onDateChange && onDateChange(Date?.toString() || "");
    onNacionaltyChange && onNacionaltyChange(Nacionalty?.value || "");
    setOpenDrawer(false);
  };

  const filters = (
    <>
      <Autocomplete
        sx={styles.searchField}
        value={Status}
        onChange={handleChangeStatus}
        options={FILTER_OPTIONS_APPLICATION_STATUS}
        inputValue={StatusInput}
        onInputChange={(_, newInputValue) => {
          setStatusInput(newInputValue);
        }}
        isOptionEqualToValue={(i) => i.value === Status?.value}
        getOptionLabel={(option) => option.name}
        renderInput={({ InputLabelProps, InputProps, inputProps, ...rest }) => (
          <TextInput
            {...rest}
            InputProps={{
              ...InputProps,
              style: { height: 48, paddingTop: "6px" },
            }}
            inputProps={inputProps}
            InputLabelProps={{
              ...InputLabelProps,
              style: { top: "-3.5px" },
              classes: { shrink: classes.inputLabel },
            }}
            label="Estatus"
          />
        )}
      />
      <Autocomplete
        sx={styles.searchField}
        value={Asigned}
        onChange={handleChangeAsigned}
        inputValue={AsignedInput}
        onInputChange={(_, newInputValue) => {
          setAsignedInput(newInputValue);
        }}
        options={Users}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        renderInput={({ InputLabelProps, InputProps, inputProps, ...rest }) => (
          <TextInput
            {...rest}
            InputProps={{
              ...InputProps,
              style: { height: 48, paddingTop: "6px" },
            }}
            inputProps={inputProps}
            InputLabelProps={{
              ...InputLabelProps,
              style: { top: "-3.5px" },
              classes: { shrink: classes.inputLabel },
            }}
            label="Asignación"
          />
        )}
      />
      <Autocomplete
        sx={styles.searchField}
        value={Date}
        onChange={handleChangeDate}
        inputValue={DateInput}
        onInputChange={(_, newInputValue) => {
          setDateInput(newInputValue);
        }}
        options={[6, 3, 1]}
        getOptionLabel={(i) => `${i} ${i === 1 ? "Mes" : "Meses"}`}
        renderInput={({ InputLabelProps, InputProps, inputProps, ...rest }) => (
          <TextInput
            {...rest}
            InputProps={{
              ...InputProps,
              style: { height: 48, paddingTop: "6px" },
            }}
            inputProps={inputProps}
            InputLabelProps={{
              ...InputLabelProps,
              style: { top: "-3.5px" },
              classes: { shrink: classes.inputLabel },
            }}
            label="Periodo"
          />
        )}
      />
      <Autocomplete
        sx={styles.searchField}
        value={Nacionalty}
        onChange={handleChangeNacionalty}
        inputValue={NacionaltyInput}
        onInputChange={(_, newInputValue) => {
          setNacionaltyInput(newInputValue);
        }}
        options={demonyms}
        renderInput={({ InputLabelProps, InputProps, inputProps, ...rest }) => (
          <TextInput
            {...rest}
            InputProps={{
              ...InputProps,
              style: { height: 48, paddingTop: "6px" },
            }}
            inputProps={inputProps}
            InputLabelProps={{
              ...InputLabelProps,
              style: { top: "-3.5px" },
              classes: { shrink: classes.inputLabel },
            }}
            label="Nacionalidad"
          />
        )}
      />
    </>
  );

  return (
    <TabContext value={Tab}>
      <Grid container sx={{ ...styles.toolbar, gap: "12px" }}>
        <Grid container item xs={12} spacing={2}>
          <Box
            sx={{
              padding: "15px 0px 25px 0px",
              width: "100%",
              overflow: "hide",
            }}
          >
            <Tabs />
          </Box>
        </Grid>
        <Grid container item xs={12} sx={{ gap: "12px" }}>
          <TextInput
            value={search}
            fullWidth={isMobile}
            onChange={handleSearchChange}
            label="Buscar"
            InputProps={{
              sx: { height: "48px" },
              startAdornment: (
                <SearchIcon fontSize="small" sx={styles.searchIcon} />
              ),
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{
                    visibility: search.length > 0 ? "visible" : "hidden",
                  }}
                  onClick={handleSearchClear}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />

          <Box sx={styles.iconsWrapper}>
            {breakpoint ? (
              <IconButton onClick={() => setOpenDrawer(true)}>
                <Badge badgeContent={badgeNumber()} color="primary">
                  <FilterListIcon />
                </Badge>
              </IconButton>
            ) : (
              <Box display={"flex"} alignItems="center" gap="12px">
                {filters}
              </Box>
            )}

            <DrawerFilter
              isOpen={openDrawer}
              onFilter={handleFilter}
              onClose={handleFilter}
            >
              {filters}
            </DrawerFilter>
            <Box display={"flex"} alignItems="center">
              {!hideIcons && (
                <GridToolbarDensitySelector {...({} as any)} sx={styles.icon} />
              )}
              {showOptions && (
                <PositionedMenu
                  handleRejectApplication={handleRejectApplication}
                  handleAceptApplication={handleAceptApplication}
                  handleChangeAssigned={handleChangeAssigned}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </TabContext>
  );
};
export default ApplicationToolbar;
