import { SxProps, Theme } from "@mui/material";

const MemberCalendarStyles: SxProps<Theme> = (theme: Theme) => ({
  "& .appointments-card": {
    width: "100%",
    padding: "16px !important",
    [theme.breakpoints.up("xl")]: {
      height: "505px",
    },
    [theme.breakpoints.between("sm", "xl")]: {
      height: "597px",
    },
  },
  "& .MuiLink-root": {
    fontSize: "14px",
    color: "#4D88E1",
  },
  "& .noElements": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});

export const CalendarItemStyles: SxProps<Theme> = (theme: Theme) => ({
  paddingBottom: "8px",
  "& .MuiAvatar-root": {
    bgcolor: "#D3EBC5",
    marginBottom: "10px",
    "& .MuiSvgIcon-root": { color: "#74C24A" },
  },
  "& .MuiDivider-root": {
    borderRightWidth: "2px",
    borderColor: "#C7C7C7",
  },
  "& .startup-name": {
    color: "#515151",
    fontFamily: "Product Sans Medium !important",
    fontWeight: 500,
    marginBottom: "2px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  "& .appointment-date": {
    fontFamily: "Product Sans Light !important",
    color: "#515151",
    fontWeight: 300,
    fontSize: "1rem",
    marginRight: "4px",
  },
  "& .appointment-time": {
    fontFamily: "Product Sans Medium !important",
    fontSize: "16px",
    color: "#515151",
    fontWeight: "500 ",
  },
  "& .appointment-date-time": {
    height: "16px",
    marginBottom: "8px",
  },
  "& .MuiLink-root": {
    paddingRight: "12px",
    fontSize: "14px",
    color: "#4D88E1",
    marginLeft: "auto",
  },
  "& .startup-name-date-time-container": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});
export default MemberCalendarStyles;
