import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import moment from "moment";
import { FC } from "react";

import StandardDialog from "../../../StandardDialog";

import { INoticeCard } from "./type";

const NoticeCard: FC<INoticeCard> = (props) => {
  const {
    data: { Autor, Descripcion, Titulo, publishedAt },
    isOpen,
    onChange,
  } = props;

  const handleClose = () => {
    onChange(false);
  };

  return (
    <StandardDialog open={isOpen}>
      <DialogContent>
        <Typography
          sx={{
            fontFamily: "Roboto !important",
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          {Titulo}
        </Typography>
        <Typography
          sx={{ color: "#2196F3", fontSize: "16px", fontWeight: 500 }}
          mt={"4px"}
        >
          {`${Autor} • 
          ${moment(publishedAt).format("D MMM YYYY")}`}
        </Typography>
        <Typography
          mt={"8px"}
          sx={{
            fontFamily: "Roboto !important",
            fontSize: "16px",
            fontWeight: 400,
            marginBottom: "40px",
          }}
        >
          {Descripcion}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            alignSelf: "end",
            color: "#2196F3",
            borderColor: "#2196F3 !important",
          }}
        >
          CERRAR
        </Button>
      </DialogActions>
    </StandardDialog>
  );
};

export default NoticeCard;
