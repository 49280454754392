/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Dayjs } from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { getMembership } from "../../../../../../services/member";

import { EditMode } from "./EditMode/EditMode";
import { ReadMode } from "./ReadMode/ReadMode";
import { EMemberships } from "./validation";

export type FormMembership = {
  membershipEndDate: Dayjs;
  membershipType: string;
  isActive: boolean;
};

const Membership = () => {
  const { id } = useParams() as { id: string };
  const [enableEditMode, setEnableEditMode] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const hook = useForm<FormMembership>({
    mode: "all",
    defaultValues: {
      membershipEndDate: moment().toDate(),
      membershipType: EMemberships.CLASSICAL,
      isActive: false,
    },
  });

  useEffect(() => {
    setLoading(true);
    getMembership(id)
      .then(({ data }) => {
        hook.setValue("membershipEndDate", data.membershipEndDate);
        hook.setValue("membershipType", data.membershipType);
        hook.setValue("isActive", data.isActive);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, reload]);

  return (
    <Grid container spacing={3}>
      {loading && (
        <Box
          minHeight={"200px"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
        >
          <CircularProgress />
        </Box>
      )}
      {enableEditMode && !loading && (
        <EditMode
          hook={hook}
          changeMode={() => setEnableEditMode(!enableEditMode)}
          onReload={() => setReload(!reload)}
        />
      )}
      {!enableEditMode && !loading && (
        <ReadMode
          onReload={() => setReload(!reload)}
          hook={hook}
          changeMode={() => setEnableEditMode(!enableEditMode)}
        />
      )}
    </Grid>
  );
};

export default Membership;
