import { yupResolver } from "@hookform/resolvers/yup";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, Grid, Typography } from "@mui/material";
import { FC } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { wantToInvestSchema } from "../../../../../../components/DialogWantToInvest/schema";
import { TextInput } from "../../../../../../components/Inputs";
import { useSelector } from "../../../../../../redux/typedHooks";
import { opportunitiesReleasedChangeIntention } from "../../../../../../services/opportunitiesReleased";
import { displayToast } from "../../../../../../utils/helpers/displayToast";

import { mainStyles } from "./styles";

interface IProps {
  onSubmit: (value: number) => void;
  onClose: () => void;
}
type FormType = {
  value: number;
};

export const InvestModalMainCard: FC<IProps> = ({ onSubmit, onClose }) => {
  const hook = useForm<FormType>({
    mode: "all",
    resolver: yupResolver(wantToInvestSchema),
    defaultValues: {
      value: 5000,
    },
  });
  const { id } = useParams() as { id: string };

  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  async function investHandler(fields: FormType): Promise<void> {
    hook.setValue("value", fields.value);

    const isMultiplo = fields.value % 500 === 0;
    if (!isMultiplo) {
      hook.setError("value", {
        message: "Solo se aceptan valores múltiplo de 500.",
      });
      return;
    }

    await opportunitiesReleasedChangeIntention({
      type: "WANT_TO_INVEST",
      user: user._id,
      startupId: id,
      value: fields.value,
    });
    onSubmit(parseInt(String(fields.value)));
    displayToast("success", `Invertirás  $${fields.value}`);
  }

  return (
    <Grid
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(19, 13, 16, 0.8)",
        position: "absolute",
        top: "0px",
        right: "0px",
        zIndex: "9999",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          padding: "40px 56px",
          width: "728px",
          maxHeight: "688px",
          minHeight: "200px",
          background: "#FFFFFF",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid item xs={12} sm={12} md={12} sx={{ ...mainStyles.Info }}>
          <Box
            style={{
              width: "100%",
              minHeight: "100%",
            }}
          >
            <Typography sx={{ ...mainStyles.title }}>
              Abrir ticket de inversión
            </Typography>
            <Typography sx={{ fontSize: "12px" }}>
              Por favor indícanos la cantidad de dinero que te gustaría invertir
              en esta oportunidad, recuerda que aún no haremos el cobro de nada,
              este valor solo es indicativo.
            </Typography>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "30px",
                flexWrap: "wrap",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  width: "60%",
                  gap: "20px",
                  justifyContent: "flex-end",
                }}
              >
                <Grid xs={12} md={12} lg={12}>
                  <Controller
                    control={hook.control}
                    name={"value"}
                    render={({ field, fieldState }) => (
                      <TextInput
                        type="number"
                        InputProps={{ inputProps: { min: 5000, step: 500 } }}
                        {...field}
                        label={"Monto a invertir"}
                        required
                        error={Boolean(fieldState.error)}
                        helperText={
                          <Typography
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "12px",
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            <InfoIcon
                              sx={{ fontSize: "15px", color: "#8BB3D8" }}
                            />
                            Monto mínimo a partir de $5,000, en múltiplos de
                            $500
                          </Typography>
                        }
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Box>
              <Box sx={{ display: "flex", gap: "20px", marginTop: "20px" }}>
                <Button
                  variant="outlined"
                  sx={{
                    ...mainStyles.actionsButtons,
                    background: "white",
                    color: "#76c34a",
                  }}
                  onClick={() => onClose()}
                >
                  CANCELAR
                </Button>
                <Button
                  onClick={hook.handleSubmit(investHandler)}
                  variant="contained"
                  sx={{ ...mainStyles.actionsButtons }}
                >
                  AGREGAR TICKET
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};
