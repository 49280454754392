import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import { Box, Card, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import HorizontalDivider from "../../../components/Dashboard/HorizontalDivider";
import MemberDashboardCardHeader from "../components/MemberDashboardCardHeader";
import { IInvestmentOpportunitiesSummary } from "../types";

import InvestmentIndicator from "./InvestmentIndicator";
import InvestmentOpportunityStyles from "./style";

interface IInvestmentOpportunityProps {
  data: IInvestmentOpportunitiesSummary;
}

const InvestmentOpportunity: React.FC<IInvestmentOpportunityProps> = ({
  data,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Card sx={InvestmentOpportunityStyles}>
      <Box sx={{ pb: 2 }}>
        <MemberDashboardCardHeader variant="h5">
          Oportunidades de Inversión{isMobile && " - Resumen"}
        </MemberDashboardCardHeader>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        columnSpacing={4}
        rowSpacing={{ xs: 2, sm: 6 }}
      >
        <Grid item xs={12} sm={6} lg={5} xl={6}>
          <InvestmentIndicator
            indicator="Nuevas"
            value={data.new}
            icon={<DescriptionOutlinedIcon />}
            url="/oportunidades-aceptadas?tab=ALL&vencidas=Nuevas"
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={7} xl={6}>
          <InvestmentIndicator
            indicator="Abiertas"
            value={data.open}
            icon={<FolderOpenOutlinedIcon />}
            url="/oportunidades-aceptadas?tab=OPEN"
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={5} xl={6}>
          <InvestmentIndicator
            indicator="Por vencer"
            value={data.expired}
            icon={<AccessTimeOutlinedIcon />}
            url="/oportunidades-aceptadas?tab=ALL&vencidas=Por Vencer"
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={7} xl={6}>
          {isMobile && <HorizontalDivider sx={{ pt: 1 }} />}
          <InvestmentIndicator
            indicator="Inversiones Ejecutadas"
            value={data.executed}
            icon={<RocketLaunchOutlinedIcon />}
            url="/inversionistas/portafolio"
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default InvestmentOpportunity;
