import { Box, Card, CardContent, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import { reloadUser } from "../../redux/actions/LoggedUser";
import { useDispatch } from "../../redux/typedHooks";
import { updateUser } from "../../services/users";

import ProfilePartner from "./ProfilePartner/ProfilePartner";
import styles from "./styles";

const ProfilePartnerScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams() as any;
  const isMobile = useMediaQuery("(max-width:860px)");

  async function handleSubmit(AdminFormdata: any) {
    await updateUser(id, AdminFormdata);
    dispatch(reloadUser());
  }

  return (
    <Box>
      <PageTitle title="Perfil de Partner" />
      <Card
        style={
          isMobile
            ? {
                width: "93vw",
                height: "90%",
                maxWidth: "827px",
                marginTop: "152px",
                marginLeft: "3%",
              }
            : {
                width: "80vw",
                height: "80vh",
                maxWidth: "1592px",
                marginTop: "152px",
                marginLeft: "3%",
              }
        }
      >
        <CardContent sx={styles.cardContent}>
          <ProfilePartner id={id} handleSubmit={handleSubmit} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ProfilePartnerScreen;
