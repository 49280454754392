export const validPasswordRegExp =
  /^(?=.*[A-Za-z])[A-Za-z\d#"%/()=¡¿+[,.@$!%*?&_-]{8,}$/;

export const strongPasswordValidator =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#"%/()=¡¿+[,.@$!%*?&_-])[A-Za-z\d#"%/()=¡¿+[,.@$!%*?&_-]{8,}$/;

const validPassword = (password: string): boolean =>
  validPasswordRegExp.test(password);

export default validPassword;
